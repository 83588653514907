import { getEmployeesExpenseReportAPI } from "../../services/admin-service";

export const FETCH_EMPLOYEES_EXPENSE_REPORT_ADMIN = 'FETCH_EMPLOYEES_EXPENSE_REPORT_ADMIN';

export const fetchEmployeesExpenseReport = (payload) => {

    return async (dispatch) => {
        const response = await getEmployeesExpenseReportAPI(payload);
        if (response.success) {
            dispatch({
                type: FETCH_EMPLOYEES_EXPENSE_REPORT_ADMIN,
                expenseReport: response.data.length > 0 ? [...response.data] : []
            });
            return { success: true, data: response.data };
        }
        else {
            return { success: false };
        }
    }
}