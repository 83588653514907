import React from "react";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const LoadingIndicator = (props) => { 
    return (
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',flex:1, marginBottom:50}}>
      <div className="sweet-loading">
        <PropagateLoader
          css={override}
          size={15}
          color={"#00dbcf"}
          loading={props.loading}
        />
      </div>
      </div>
    );
}
export default LoadingIndicator;