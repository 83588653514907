import { getAllClaimCategoriesAPI } from '../../services/claims-service';
export const FETCH_ALL_CLAIM_CATEGORIES = 'FETCH_ALL_CLAIM_CATEGORIES';

export const getAllClaimCategories = () => {
    return async (dispatch) => {
        const response = await getAllClaimCategoriesAPI();
        if (response.data) {
            dispatch({
                type: FETCH_ALL_CLAIM_CATEGORIES,
                claimCategories: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}