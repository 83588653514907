import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
    UncontrolledTooltip
} from "reactstrap";
import ProjectModal from "./projectModal";
import NotificationAlert from "react-notification-alert";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAllProjects } from '../../../redux/actions/projectActions';
import { fetchClients } from "../../../redux/actions/clientActions";
import moment from 'moment';
import { addUpdateProject } from "../../../services/project-service";
import { NEW_PROJECT_SUCCESS_MESSAGE, NEW_PROJECT_ERROR_MESSAGE, UPDATE_PROJECT_ERROR_MESSAGE, dateFormatList } from "../../../variables/constants"
import Pagination from "../../../components/common/pagination";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
import { getEmployeesWithManagerRole } from "../../../redux/actions/managersAction";
import { getAllCurrencies } from "../../../redux/actions/currenciesAction";
import { getAllProjectTypes } from "../../../redux/actions/projectTypesAction";
import { AppContext } from '../../../hoc/AppContext';
import { getAllEmployeesWithRole } from "../../../redux/actions/employeeRoleAction";
import { getInitialProjectEmployees } from "../../../redux/actions/projectEmployeesAction";
class ProjectMaster extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            formType: 'Add',
            project: {
                id: 0,
                cid: this.props.cid,
                project: '',
                startDate: '',
                endDate: '',
                status: '',
                isActive: 'Y',
                clientId: null
            },
            data: [],
        };
    }

    async UNSAFE_componentWillMount() {
        this.setState({
            isDataLoading: true
        })
        await this.props.getInitialProjectEmployees();
        const response = await this.props.getAllProjects(this.props.cid);
        if (response) {
            this.setState({
                isDataLoading: false
            })
        }

        await this.props.getAllClients(this.props.cid);
        await this.props.getEmployeesWithManagerRole(this.props.cid);
        await this.props.getAllCurrencies();
        await this.props.getAllProjectTypes(this.props.cid);
        await this.props.getAllEmployeesWithRole(this.props.cid);


        this.setState({
            ...this.state,
            data: this.props.projects.map((prop, key) => {

                return {
                    id: prop.id,
                    cid: prop.cid,
                    project: prop.project,
                    startDate: prop.startDate === null ? prop.startDate : moment(prop.startDate).format(dateFormatList[2]),
                    endDate: prop.endDate === null ? prop.endDate : moment(prop.endDate).format(dateFormatList[2]),
                    status: prop.status,
                    clientId: prop.clientId,
                    pid: prop.pid,
                    isActive: prop.isActive,
                    hoursEstimated: prop.hoursEstimated,
                    percentCompleted: prop.percentCompleted,
                    description: prop.description,
                    active: (
                        <div className="actions-right">
                            <Button
                                onClick={() => { }}
                                size="md"
                                style={{ cursor: 'context-menu' }}
                                className={classNames("btn-icon btn-link like", {
                                    "btn-neutral": key < 5
                                })}
                            >
                                {prop.isActive === 'Y' ? <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span> :
                                    <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
                                }
                            </Button>{" "}
                        </div>
                    ),

                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <Button
                                id="tooltip209846"
                                onClick={() => {
                                    let obj = this.state.data.find(o => o.id === prop.id);

                                    let { id,
                                    } = obj;
                                    let passProps = {
                                        pathname: 'add-update-project',
                                        state: {
                                            pid: id,
                                            formType: 'Update'
                                        }
                                    }
                                    this.props.history.push(passProps);
                                }}
                                // color="warning"
                                size="sm"
                                className={classNames("btn-icon btn-link like", {
                                    "btn-neutral": key < 5
                                })}
                            >
                                <i className="tim-icons icon-pencil" />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip209846"
                                placement="left"
                            >
                                Edit
                            </UncontrolledTooltip>


                        </div>
                    ),
                    details: (
                        // we've added some custom button actions
                        <div className="">
                            <Button
                                id="tooltip209845"
                                onClick={() => {
                                    let obj = this.state.data.find(o => o.id === prop.id);

                                    let { id,
                                        project,
                                        startDate,
                                        endDate,
                                        status, clientId, cid, isActive, hoursEstimated, percentCompleted, description } = obj;
                                    let projectItem = { id, project, startDate, endDate, status, clientId, cid, isActive, hoursEstimated, percentCompleted, description }
                                    this.showProjectModal(projectItem)
                                }}
                                // color=""
                                className={classNames("btn-icon btn-link like", {
                                    "btn-neutral": key < 5
                                })}
                                size="sm"
                            >
                                <i className="tim-icons icon-zoom-split" />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip209845"
                                placement="left"
                            >
                                View
                            </UncontrolledTooltip>

                        </div>
                    )
                };
            })
        });

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.state.data !== nextProps.projects) {
            this.setState({
                ...this.state,
                data: nextProps.projects.map((prop, key) => {

                    return {
                        id: prop.id,
                        cid: prop.cid,
                        project: prop.project,
                        startDate: prop.startDate === null ? prop.startDate : moment(prop.startDate).format(dateFormatList[2]),
                        endDate: prop.endDate === null ? prop.endDate : moment(prop.endDate).format(dateFormatList[2]),
                        status: prop.status,
                        clientId: prop.clientId,
                        pid: prop.pid,
                        isActive: prop.isActive,
                        description: prop.description,

                        active: (
                            <div className="actions-right">
                                <Button
                                    onClick={() => { }}
                                    size="md"
                                    style={{ cursor: 'context-menu' }}
                                    className={classNames("btn-icon btn-link like", {
                                        "btn-neutral": key < 5
                                    })}
                                >
                                    {prop.isActive === 'Y' ? <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span> :
                                        <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
                                    }
                                </Button>{" "}
                            </div>
                        ),

                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                <Button
                                    id="tooltip209846"
                                    onClick={() => {
                                        let obj = this.state.data.find(o => o.id === prop.id);

                                        let { id, } = obj;

                                        let passProps = {
                                            pathname: 'add-update-project',
                                            state: {
                                                pid: id,
                                                formType: 'Update'
                                            }
                                        }
                                        this.props.history.push(passProps);
                                    }}
                                    // color=""
                                    size="sm"
                                    className={classNames("btn-icon btn-link btn-neutral like", {
                                        // "btn-neutral": key < 5
                                    })}
                                >
                                    <i className="tim-icons icon-pencil" />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip209846"
                                    placement="left"
                                >
                                    Edit
                                </UncontrolledTooltip>
                            </div>
                        ),
                        details: (
                            // we've added some custom button actions
                            <div className="">
                                <Button
                                    id="tooltip209845"
                                    onClick={() => {
                                        let obj = this.state.data.find(o => o.id === prop.id);

                                        let { id,
                                            project,
                                            startDate,
                                            endDate,
                                            status, clientId, cid, isActive, description } = obj;
                                        let projectItem = { id, project, startDate, endDate, status, clientId, cid, isActive, description }
                                        this.showProjectModal(projectItem)
                                    }}
                                    // color=""
                                    size="sm"
                                    className={classNames("btn-icon btn-link like", {
                                        "btn-neutral": key < 5
                                    })}
                                >
                                    <i className="tim-icons icon-zoom-split" />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip209845"
                                    placement="left"
                                >
                                    View
                                </UncontrolledTooltip>
                            </div>
                        )
                    };
                })
            });
        }
    }

    notify = (place, type, message) => {

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };

    showProjectModal = (project) => {
        this.setState({
            formType: 'Update',
            showModal: true,
            project: { ...project }
        });
    }

    toggleShowModal = () => {

        this.setState({
            formType: 'Add',
            showModal: !this.state.showModal,
            project: {},

        });
    }

    onClose = () => {

        this.setState({
            showModal: false,
        });
    }

    handleSubmit = async (project, formType) => {

        if (formType === "Add") {

            let result = await addUpdateProject(project);

            if (result.success) {
                await this.props.getAllProjects(this.props.cid);
                this.notify("tr", "success", NEW_PROJECT_SUCCESS_MESSAGE);
            } else {

                this.notify("tr", "warning", NEW_PROJECT_ERROR_MESSAGE);
            }
        } else {
            let result = await addUpdateProject(project);

            if (result.success) {
                await this.props.getAllProjects(this.props.cid);
                this.notify("tr", "success", "Updated " + project.project + " project successfully.");
            } else {
                this.notify("tr", "warning", UPDATE_PROJECT_ERROR_MESSAGE);
            }
        }
        this.setState({
            formType: 'Add',
            showModal: false,
            project: {},
        });
    }


    handleNewProject = () => {
        let passProps = {
            pathname: 'add-update-project',
            state: {
                newProject: this.state.project,
                formType: 'Add'
            }
        }
        this.props.history.push(passProps);
    }


    render() {
        const { config } = this.context;

        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    {this.state.showModal && <ProjectModal cid={this.props.cid} modal={this.state.showModal} clients={this.props.clients} toggle={this.toggleShowModal} onSubmit={this.handleSubmit} formType={this.state.formType} project={this.state.project} close={this.onClose} />}
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CardTitle tag="h2">Project Master</CardTitle>
                                    <div style={{}}>
                                        <Button onClick={this.handleNewProject} size="sm" color="info">New</Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={this.state.data}
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "",
                                                accessor: "active",
                                                sortable: false,
                                                filterable: false,
                                                width: 50,
                                            },

                                            {
                                                Header: "Project Name",
                                                accessor: "project",
                                                minWidth: 250
                                            },
                                            {
                                                Header: "Start Date",
                                                accessor: "startDate"
                                            },
                                            {
                                                Header: "End Date ",
                                                accessor: "endDate"
                                            },
                                            {
                                                Header: "Status",
                                                accessor: "status"
                                            },
                                            {
                                                Header: "",
                                                accessor: "actions",
                                                width: 50,
                                                sortable: false,
                                                filterable: false
                                            },
                                            {
                                                Header: "",
                                                accessor: "details",
                                                sortable: false,
                                                filterable: false,
                                                width: 50
                                            },

                                        ]}
                                        LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                                        NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                                        pageSize={this.props.projects.length <= config.paginationSize ? this.props.projects.length : config.paginationSize}
                                        className="-striped -highlight project-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export const mapDispatchToProps = dispatch => ({
    getAllProjects: bindActionCreators(getAllProjects, dispatch),
    getAllClients: bindActionCreators(fetchClients, dispatch),
    getEmployeesWithManagerRole: bindActionCreators(getEmployeesWithManagerRole, dispatch),
    getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch),
    getAllProjectTypes: bindActionCreators(getAllProjectTypes, dispatch),
    getAllEmployeesWithRole: bindActionCreators(getAllEmployeesWithRole, dispatch),
    getInitialProjectEmployees: bindActionCreators(getInitialProjectEmployees, dispatch)
});


export const mapStateToProps = ({ auth, projects, client, companies, managers, currencies, projectTypes, employeesWithRole }) => {


    return {
        token: auth.token,
        projects: projects,
        clients: client,
        cid: auth.user.cid,
        managers: managers,
        currencies: currencies,
        projectTypes: projectTypes,
        employeesWithRole: employeesWithRole
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectMaster);
