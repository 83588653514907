import { FETCH_ALL_CURRENCIES } from "../actions/currenciesAction";
const currenciesReducer = function (state = [], action) {
    switch (action.type) {
        case FETCH_ALL_CURRENCIES:
            return action.currencies;
        default:
            return state;
    }
};

export default currenciesReducer;