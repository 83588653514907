import {addNewEmployeeAPI,updateEmployeeBasicInfoAPI,fetchEmployeeBasicInfoAPI} from "../../services/employees-service";

export const ADD_EMPLOYEE_BASIC_INFO = 'ADD_EMPLOYEE_BASIC_INFO';
export const UPDATE_EMPLOYEE_BASIC_INFO = 'UPDATE_EMPLOYEE_BASIC_INFO';
export const GET_EMPLOYEE_BASIC_INFO = 'GET_EMPLOYEE_BASIC_INFO';

export const addNewEmployee = (employee) =>  {
    return async (dispatch) => {
      let data = {};
        const response = await addNewEmployeeAPI(employee);
            if (response.data){
                dispatch({
                    type: ADD_EMPLOYEE_BASIC_INFO,
                    employee: response.data
                  });
                return { success: true,data:response.data };
            }
            else{
                return { success: false};
            }    
        }
}

export const updateEmployeeBasicInfo = (employee,extraInfo,payRollInfo) =>  {
  return async (dispatch) => {
      const response = await updateEmployeeBasicInfoAPI(employee);
          if (response.data){
              dispatch({
                  type: UPDATE_EMPLOYEE_BASIC_INFO,
                  payload: {
                    id:employee.id,
                    employee:response.data ,                   
                }
                });
              return { success: true,data:response.data };
          }
          else{
              return { success: false};
          }    
      }
}

export const getAuthenticatedUserDetails = () => {
  return async (dispatch, getState) => {
    const {auth} = getState();
    const response = await fetchEmployeeBasicInfoAPI(auth.user.cid, auth.user.eid);
    if (response && response.data) {
      dispatch({
        type: GET_EMPLOYEE_BASIC_INFO,
        employee: response.data,
      });
      return {success: true, data:response.data};
    } else {
      return {success: false};
    }
  };
};