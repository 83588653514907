import moment from "moment";
import classNames from "classnames";
import React, { Component } from "react";
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Button, Input, UncontrolledTooltip } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { fetchHolidayList } from "redux/actions/holidayListActions";
import { getAllCountries } from "redux/actions/countryAction";
import HolidayModal from "./holidayModal";
import { addUpdateHoliday, fetchHolidayListAPI, deleteHoliday } from "../../services/holiday-service";
import Pagination from "../../components/common/pagination";
import { getCompanyLocations } from "redux/actions/companyLocationAction";
import { getHolidaysByLocation } from "redux/actions/holidaysByLocationAction";
import LoadingIndicator from "../../components/common/LoadingIndicator";
import NoData from "../../components/common/NoData";
import { AppContext } from '../../hoc/AppContext';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { dateFormatList } from "variables/constants";

class HolidayList extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            alert: false,
            data: [],
            holiday: {},
            formType: 'add',
            year: "",
            companyLocation: 0,
            isOpen: false,
            isDataLoading: false,
            nextYear: false,
            prevYear: false
        };
    }

    handeOpenModal = () => {
        this.setState({
            isOpen: true,
            formType: 'add',
        })
    };

    handleCountrySelect = async (e) => {

        this.setState({
            companyLocation: e.target.value
        })

        await this.props.getHolidaysByLocation(this.props.cid, e.target.value);

        let data = this.props.holidaysByLocation.map((prop, key) => {
            let isActive = true;
            prop.isActive === "Y" ? isActive = true : isActive = false
            return {
                id: key,
                index: key + 1 + ".",
                SrNo: prop.id,
                date: moment(prop.holidayDate).format("YYYY-MM-DD"),
                day: prop.day,
                occassion: prop.holiday,
                isActive: (
                    <div className="actions-right">
                        <Button
                            onClick={() => { }}
                            size="md"
                            style={{ cursor: 'context-menu' }}
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            {prop.isActive === 'Y' ? <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span>
                                : <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
                            }
                        </Button>{" "}
                    </div>
                ),
                country: prop.companyLocationId,
                actions: (
                    // we've added some custom button actions
                    <div className="actions-center">
                        <Button
                            id="tooltip209883"
                            onClick={() => {
                                let obj = this.state.data.find(o => o.id === key);
                                this.showHolidayModal(prop)
                            }}
                            // color="warning"
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-pencil" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209883"
                            placement="left"
                        >
                            Edit
                        </UncontrolledTooltip>
                        <Button
                            size="sm"
                            id="tooltip209881"
                            onClick={() => { this.handleDelete(prop.id) }}
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-trash-simple" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209881"
                            placement="left"
                        >
                            Delete
                        </UncontrolledTooltip>
                    </div>
                )
            };
        })

        this.setState({
            data: data
        })

    }

    toggle = () => {

        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }
    //notificationAlert = React.createRef();

    notify = (place, color, message) => {
        // var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };


    showNotifications = (color, message) => {
        this.notify("tr", color, message);
    }

    showHolidayModal = (holiday) => {
        this.setState({
            formType: 'update',
            isOpen: true,
            holiday: { ...holiday }
        });
    }

    handleNextYear = async () => {
        let year = moment().year() + 1;
        let result = await fetchHolidayListAPI(this.props.cid, year);
        if (result.data && result.data.length > 0) {
            this.setState({ year: year })
            this.getAllHolidays(year);
        } else {
            this.handleRefreshClick();
        }

    }

    handlePrevYear = async () => {
        let year = moment().year() - 1;
        let result = await fetchHolidayListAPI(this.props.cid, year);

        if (result.data.length > 0) {
            this.setState({ year: year })
            await this.getAllHolidays(year);
        } else {
            this.handleRefreshClick();
        }
    }

    addUpdateHoliday = async (holiday, formtype) => {
        this.toggle();
        let message = '';
        let message2 = '';
        if (formtype === 'add') {
            message = 'Holiday has been Added successfully.';
            message2 = 'Failed to add holiday!'
        } else {
            message = 'Holiday has been updated successfully.'
            message2 = 'Failed to update holiday!'
        }

        let result = await addUpdateHoliday(holiday);

        if (result.success) {
            await this.checkDataExistsForButtons();
            await this.checkDataExistsForButtons();
            this.showNotifications(2, message);
            this.getAllHolidays(moment().year());
            //this.toggle()
        } else {
            this.showNotifications(3, message2);
            //this.toggle();
        }
    }

    handleDelete = (holidayId) => {

        this.warningWithConfirmMessage(holidayId)
    }

    deleteHoliday = async (holidayId) => {
        let response = await deleteHoliday(holidayId);
        if (response.success) {
            this.successAlert();
            await this.checkDataExistsForButtons();
            this.getAllHolidays(moment().year());
        }
        else {
            this.showNotifications(3, 'Error occured while deleting holiday!');
        }
    }

    warningWithConfirmMessage = (holidayId) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure about deleting the holiday?"
                    onConfirm={() => this.successDelete(holidayId)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, do it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize="sm"
                >
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        let options = {};
        options = {
            place: "tr",
            message: "Holiday has been deleted successfully.",
            type: "success",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 2
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    successDelete = (holidayId) => {

        this.deleteHoliday(holidayId);
        this.hideAlert();
    };

    getAllHolidays = async (year) => {
        this.setState({
            isDataLoading: true
        })
        await this.props.fetchHolidayList(this.props.cid, year);

        //if(response){
        this.setState({
            isDataLoading: false
        })
        // }

        let data = this.props.holidays.map((prop, key) => {
            let isActive = true;
            prop.isActive === "Y" ? isActive = true : isActive = false
            return {
                id: key,
                index: key + 1 + ".",
                SrNo: prop.id,
                date: moment(prop.holidayDate).format(dateFormatList[2]),
                day: prop.day,
                occassion: prop.holiday,
                isActive: (
                    <div className="actions-right">
                        <Button
                            onClick={() => { }}
                            size="md"
                            style={{ cursor: 'context-menu' }}
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            {prop.isActive === 'Y' ? <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span>
                                : <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
                            }
                        </Button>{" "}
                    </div>
                ),
                country: prop.companyLocationId,
                actions: (
                    // we've added some custom button actions
                    <div className="actions-center">
                        <Button
                            id="tooltip209883"
                            onClick={() => {
                                let obj = this.state.data.find(o => o.id === key);
                                this.showHolidayModal(prop)
                            }}
                            // color="warning"
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-pencil" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209883"
                            placement="left"
                        >
                            Edit
                        </UncontrolledTooltip>
                        <Button
                            size="sm"
                            id="tooltip209881"
                            onClick={() => { this.handleDelete(prop.id) }}
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-trash-simple" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209881"
                            placement="left"
                        >
                            Delete
                        </UncontrolledTooltip>
                    </div>
                )
            };
        })

        this.setState({
            data: data
        })
        // }else{
        //     this.handleRefreshClick();
        // }

    }

    handleRefreshClick = () => {
        let year = moment().year();
        this.getAllHolidays(year);

        this.setState({
            year
        })
    }

    checkDataExistsForButtons = async () => {

        let prevYear = moment().year() - 1;
        let nextYear = moment().year() + 1;

        let result = await fetchHolidayListAPI(this.props.cid, prevYear);

        if (result.data && result.data.length <= 0) {
            this.setState({
                prevYear: true
            })
        } else {
            this.setState({
                prevYear: false
            })
        }

        let response = await fetchHolidayListAPI(this.props.cid, nextYear);

        if (response && response.data && response.data.length <= 0) {
            this.setState({
                nextYear: true
            })
        } else {
            this.setState({
                nextYear: false
            })
        }

    }

    UNSAFE_componentWillMount() {
        let year = moment().year();
        this.checkDataExistsForButtons();
        this.setState({ year: year })
        this.getAllHolidays(year);
        this.props.getAllCountries();
        this.props.getCompanyLocations(this.props.cid)

    }

    render() {

        let showEdit = false;
        this.props.user.roles[0] === 'Company Administrator' ? showEdit = true : showEdit = false;
        let countriesOptions = this.props.companyLocations.map((location, index) => {
            return (<option key={index} value={location.id}>{location.branchName}</option>)
        });

        const { isOpen } = this.state;
        const { config } = this.context;
        return (
            <>
                {this.state.alert}
                <NotificationAlert ref="notificationAlert" />
                {isOpen && <HolidayModal cid={this.props.cid} holiday={this.state.holiday} countries={this.props.companyLocations} addUpdateHoliday={this.addUpdateHoliday} toggle={this.toggle} isOpen={isOpen} formtype={this.state.formType} showNotifications={this.showNotifications} />}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                            <Card>
                                <Card>
                                    <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex' }}>
                                            <Input type="select" name="country" defaultValue={this.state.country} onChange={this.handleCountrySelect} id="exampleSelect">
                                                <option value="-1">Select location</option>
                                                {countriesOptions}
                                            </Input>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            {/* <div className="">
                                            <Button
                                            size="sm"
                                            id="tooltip209882"
                                            className={classNames("btn-icon btn-link like")}
                                            onClick={this.handleRefreshClick}
                                            >
                                                <i className="tim-icons icon-refresh-02" />
                                                </Button>
                                                <UncontrolledTooltip
                                                            delay={0}
                                                            target="tooltip209882"
                                                            placement="right"
                                                        >
                                                            Refresh
                                                        </UncontrolledTooltip>
                                        </div> */}
                                            <Button color="info" size="sm" onClick={this.handleRefreshClick}>Current Year</Button>
                                            {this.state.nextYear ? <Button disabled="true" color="info" size="sm" onClick={this.handleNextYear}>Next Year</Button> : <Button color="info" size="sm" onClick={this.handleNextYear}>Next Year</Button>}
                                            {this.state.prevYear ? <Button disabled="true" color="info" size="sm" onClick={this.handlePrevYear}>Previous Year</Button> : <Button color="info" size="sm" onClick={this.handlePrevYear}>Previous Year</Button>}
                                        </div>
                                    </CardHeader>
                                </Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CardTitle tag="h2">Holiday List {this.state.year} </CardTitle>
                                    {
                                        this.props.user.roles[0] === 'Company Administrator' ? (
                                            <div style={{ display: 'flex' }}>
                                                <Button color="info" size="sm" onClick={this.handeOpenModal}>New</Button>
                                            </div>
                                        ) : null
                                    }

                                </CardHeader>
                                <CardBody>

                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={this.state.data}
                                        resizable={true}
                                        columns={[
                                            {
                                                Header: "",
                                                accessor: "isActive",
                                                sortable: false,
                                                filterable: false,
                                                width: 80,
                                                resizable: false
                                            },
                                            {
                                                Header: "No. ",
                                                accessor: "index",
                                                sortable: false,
                                                filterable: false,
                                                width: 100,
                                                resizable: false

                                            },
                                            {
                                                Header: "Date",
                                                accessor: "date",
                                                width: 200,
                                                resizable: false
                                            },
                                            {
                                                Header: "Day",
                                                accessor: "day",
                                                width: 220,
                                                resizable: false
                                            },
                                            {
                                                Header: "Occasion",
                                                accessor: "occassion",
                                                width: 300
                                            },
                                            {
                                                Header: "",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                className: "align-center",
                                                resizable: false,
                                                show: showEdit,
                                                // width: 50
                                            },

                                        ]}
                                        LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                                        NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                                        pageSize={this.props.holidays.length <= config.paginationSize ? this.props.holidays.length : config.paginationSize}
                                        showPaginationBottom={true}
                                        className="-striped -highlight project-table leave-table expense-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {

    return {
        holidays: state.holidays,
        countries: state.countries,
        companyLocations: state.companyLocations,
        cid: state.auth.user.cid,
        user: state.auth.user,
        holidaysByLocation: state.holidaysByLocation,
    }

};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchHolidayList: bindActionCreators(fetchHolidayList, dispatch),
        getAllCountries: bindActionCreators(getAllCountries, dispatch),
        getCompanyLocations: bindActionCreators(getCompanyLocations, dispatch),
        getHolidaysByLocation: bindActionCreators(getHolidaysByLocation, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HolidayList);
