/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button, Row, UncontrolledTooltip, Badge } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    };
  }

  render() {
    return (
      <div className="fileinput text-center">
        <input type="file" multiple onChange={this.props.handleImageChange} accept={this.props.accept}  
        onClick={(event)=> { event.target.value=null}} ref="fileInput" />
        <div className={"thumbnail" + (this.props.avatar ?
           " img-circle" : "")}>

        </div>
        <div>
          {this.props.comp === 'expense' ?
            this.props.file === null ? (
              <div>
                <Button
                  style={{ marginLeft: '15px', marginTop: '-15px' }}
                  onClick={() => this.props.handleClick(this.refs)}
                  color=""
                  size="sm"
                  id="tooltip209551"
                  className="btn-icon btn-link like"
                >
                  <i className="tim-icons icon-upload" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="tooltip209551"
                  placement="top"
                >
                  Upload
                  </UncontrolledTooltip>
              </div>
            ) : (
                <Row>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-15px' }}>
                    <div style={{ flex: 1 }}>
                      <Button
                        onClick={() => this.props.handleClick(this.refs)}
                        style={{ marginLeft: '30px' }}
                        color=""
                        size="sm"
                        id="tooltip209551"
                        className="btn-icon btn-link like"
                      >
                        <i className="tim-icons icon-upload" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="tooltip209551"
                        placement="top"
                      >
                        Upload
                  </UncontrolledTooltip>
                    </div>
                    <div style={{ flex: 1 }}>
                      <span>{this.props.attachmentsCount}</span>
                    </div>
                  </div>
                </Row>
              ) :
            this.props.comp === 'projectDocument' ? (
              (!this.props.file || this.props.file === null || "") ? (
                <div className="upload-section">
                  <Row style={{ alignItems: 'center' }}>
                    <Button color="success" className="animation-on-hover"
                      onClick={() => this.props.handleClick(this.refs)}>
                      <i className="tim-icons icon-upload" style={{ marginRight: '5px', marginBottom: '5px' }} />
                      Upload Document
                </Button>
                  </Row>
                  {this.props.selectedFile !== null ? (<div className="custom-badge-section" >
                    <Badge style={{ marginLeft: '0px' }} color="primary">
                      <span>
                        {this.props.selectedFile}
                      </span>
                      <i onClick={() => { this.props.handleRemove() }} className="tim-icons icon-simple-remove" style={{ justifyContent: 'flex-end', marginLeft: '10px' }}></i></Badge>
                  </div>) : null
                  }
                </div>

              ) : (
                  <div className="upload-section">
                    <Row style={{ alignItems: 'center' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button color="success" className="animation-on-hover"
                          onClick={() => this.props.handleClick(this.refs)}>
                          <i className="tim-icons icon-upload" style={{ marginRight: '5px', marginBottom: '5px' }} />
                          Upload Document
                      </Button>

                      </div>
                      <br />
                    </Row>
                    <div className="custom-badge-section" >
                      <Badge style={{ marginLeft: '0px' }} color="primary">
                        <a style={{ color: 'white' }} href={this.props.file} target="_blank">See Uploaded Document</a>
                        <i onClick={() => { this.props.handleRemove() }} className="tim-icons icon-simple-remove" style={{ justifyContent: 'flex-end', marginLeft: '10px' }}></i></Badge>
                    </div>
                  </div>
                )
            ) : (
                !this.props.file || this.props.file === null || "" ? (
                  <Button color="success" className="animation-on-hover"
                    onClick={() => this.props.handleClick(this.refs)}>
                    <i className="tim-icons icon-upload" style={{ marginRight: '5px', marginBottom: '5px' }} />
                    Upload Resume
                  </Button>
                ) : (
                    <Row>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button color="success" className="animation-on-hover"
                          onClick={() => this.props.handleClick(this.refs)}>
                          <i className="tim-icons icon-upload" style={{ marginRight: '5px', marginBottom: '5px' }} />
                          Upload Resume
                        </Button>

                      </div>
                      <br />
                      <div >
                        <Badge style={{ marginLeft: '50px' }} color="primary">
                        {this.props.file === 'newResume' ?
                        <a style={{ color: 'white' }} target="_blank">New File</a>
                      :
                      <a style={{ color: 'white' }} href={this.props.file[0]} target="_blank">Uploaded File</a>
                      }
                          <i onClick={this.props.handleRemove} className="tim-icons icon-simple-remove" style={{ justifyContent: 'flex-end', marginLeft: '10px' }}></i></Badge>
                        {/* <Button className="btn-link" color="primary">
                           <a href={this.props.file}>Resume</a>
                          </Button> */}
                        {/* <Button className="btn-round btn-icon" color="primary">
                            <i className="tim-icons icon-heart-2" />
                          </Button> */}
                      </div>
                    </Row>
                  )
              )
          }

        </div>
      </div>
    );
  }
}

FileUpload.defaultProps = {
  avatar: false,
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary"
};

FileUpload.propTypes = {
  avatar: PropTypes.bool,
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ])
};

export default FileUpload;