import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Label,
  Button,
  FormGroup,
  Input,
  Spinner
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { getAllRoles } from "../../../redux/actions/rolesAction";
import { getAllModules } from "../../../redux/actions/moduleAction";
import { getAllRoleModuleAccess, addUpdateRoleModuleAccess } from "../../../redux/actions/roleModuleAccessAction";
import Pagination from "../../../components/common/pagination";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
class RolesModuleAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      roleid: "",
      formType: 'add',
      roleModuleData: [],
      alert: null,
      isDataLoading: false,
      isLoading: false

    };
  }


  UNSAFE_componentWillMount() {
    this.props.getAllRoles(this.props.cid);
    this.props.getAllModules(this.props.cid);
    this.setState({
      roleModuleData: this.props.roleModuleAccess
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.roleModuleAccess != nextProps.roleModuleAccess) {
      this.setState({
        roleModuleData: nextProps.roleModuleAccess
      })
    }
  }

  handleSelect = async (e) => {
    let roleId = parseInt(e.target.value)
    this.setState({
      roleid: roleId
    })
    this.setState({
      isDataLoading: true,
      roleModuleData: []
    })
    const response = await this.props.getAllRoleModuleAccess(this.props.cid, roleId);
    if (response) {
      this.setState({
        roleModuleData: this.props.roleModuleAccess,
        isDataLoading: false
      })
    }
  }

  handleSubmit = async () => {
    if (this.state.isLoading === false) {
      this.setState({
        isLoading: true
      })
      let data = [...this.state.roleModuleData]
      let roleAccess = [];
      for (let i = 0; i < data.length; i++) {
        const dataObj = data[i];
        dataObj.access = JSON.stringify(dataObj.access)
        roleAccess.push(dataObj)
      }

      const response = await this.props.addUpdateRoleModuleAccess(data);
      if (response.success) {
        let options = {};
        options = {
          place: "tr",
          message: "Role module access added successfully.",
          type: "success",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3
        };
        if (this.refs.notificationAlert) {
          this.refs.notificationAlert.notificationAlert(options);
        } setTimeout(() => {
          this.setState({
            isLoading: false
          })
        }, 3000);
      } else {
        let options = {};
        options = {
          place: "tr",
          message: "Error occurred while adding role module access!",
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3
        };
        if (this.refs.notificationAlert) {
          this.refs.notificationAlert.notificationAlert(options);
        } setTimeout(() => {
          this.setState({
            isLoading: false
          })
        }, 3000);
      }
    }
  }

  handleOnChange = (e, index) => {
    let data = [...this.state.roleModuleData];
    let object = { ...data[index] }
    let access = { ...object.access }
    access[e.target.name] = e.target.checked
    object.access = access;
    data[index] = { ...object }
    this.setState({
      roleModuleData: data
    })
  }

  render() {
    let data = this.state.roleModuleData.map((prop, key) => {
      let moduleId = this.props.modules.find(module => module.id === prop.moduleId);
      return {
        id: key,
        module: moduleId.module,
        view: (<div className="actions-left" style={{ paddingTop: '0px', paddingBottom: '20px' }}>
          <FormGroup check inline>
            <Label check>
              <Input type="checkbox" name="view" value={prop.access.view} checked={prop.access.view} onChange={e => this.handleOnChange(e, key)} />
              <span className="form-check-sign" />
            </Label>
          </FormGroup>
        </div>),
        add: (<div className="actions-left" style={{ paddingTop: '0px', paddingBottom: '20px' }}>
          <FormGroup check inline>
            <Label check>
              <Input type="checkbox" name="add" value={prop.access.add} checked={prop.access.add} onChange={e => this.handleOnChange(e, key)} />
              <span className="form-check-sign" />
            </Label>
          </FormGroup>
        </div>),
        edit: (<div className="actions-left" style={{ paddingTop: '0px', paddingBottom: '20px' }}>
          <FormGroup check inline>
            <Label check>
              <Input type="checkbox" name="edit" value={prop.access.edit} checked={prop.access.edit} onChange={e => this.handleOnChange(e, key)} />
              <span className="form-check-sign" />
            </Label>
          </FormGroup>
        </div>),
        delete: (<div className="actions-left" style={{ paddingTop: '0px', paddingBottom: '20px' }}>
          <FormGroup check inline>
            <Label check>
              <Input type="checkbox" name="delete" value={prop.access.delete} checked={prop.access.delete} onChange={e => this.handleOnChange(e, key)} />
              <span className="form-check-sign" />
            </Label>
          </FormGroup>
        </div>),
        manage: (<div className="actions-left" style={{ paddingTop: '0px', paddingBottom: '20px' }}>
          <FormGroup check inline>
            <Label check>
              <Input type="checkbox" name="manage" value={prop.access.manage} checked={prop.access.manage} onChange={e => this.handleOnChange(e, key)} />
              <span className="form-check-sign" />
            </Label>
          </FormGroup>
        </div>)
      };
    })
    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        <div className="content">
          {this.state.alert}
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">Roles Module Access</CardTitle>
                  <div style={{}}>
                    <Col sm="3" style={{}}>
                      <Label>Select Role</Label>
                      <FormGroup className={`has-label ${this.state.role} `} >
                        <Input
                          name="claimType"
                          placeholder="Claim Type..."
                          type="select"
                          defaultValue={this.state.roleid}
                          value={this.state.roleid}
                          onChange={e => this.handleSelect(e)}
                        ><option value="" disabled>Select type</option>
                          {this.props.roles.map((prop, key) => {
                            return (<option value={prop.id}>{prop.role}</option>)
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    PaginationComponent={Pagination}
                    data={data}
                    //filterable
                    // nextText={myCustomNextText}
                    resizable={true}
                    columns={[
                      {
                        Header: "Module",
                        accessor: "module",
                        sortable: false,
                        filterable: false,
                        width: 250
                      },
                      {
                        Header: "View",
                        accessor: "view",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Add",
                        accessor: "add",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Edit",
                        accessor: "edit",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Delete",
                        accessor: "delete",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Manager",
                        accessor: "manage",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                    NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                    pageSize={this.props.modules.length <= 10 ? this.props.modules.length : 10}
                    className="-striped -highlight project-table leave-table expense-table"
                  />
                </CardBody>
                <Col sm="12" >
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px' }} >
                    <div >
                      <Button size="sm" color="info" onClick={this.handleSubmit} size="sm">{this.state.isLoading ?
                        <Spinner as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true" />

                        : 'Save'
                      }</Button>
                    </div>
                    <div >
                      <Button size="sm" color="default" size="sm" >Cancel</Button>
                    </div>
                  </div>
                  <Label></Label>
                </Col>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.roles,
  cid: state.auth.user.cid,
  modules: state.modules,
  roleModuleAccess: state.roleModuleAccess
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllRoles: bindActionCreators(getAllRoles, dispatch),
    getAllModules: bindActionCreators(getAllModules, dispatch),
    getAllRoleModuleAccess: bindActionCreators(getAllRoleModuleAccess, dispatch),
    addUpdateRoleModuleAccess: bindActionCreators(addUpdateRoleModuleAccess, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RolesModuleAccess);
