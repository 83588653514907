import React from "react";
import { Card, CardTitle } from "reactstrap";


const DashboardCount = (props) => {
    return (
        <Card className="styled-card count-card">
            <CardTitle tag="h6" className="dash-header">

                {props.title}

                <p tag="h3" className="dash-count">{props.count}</p>
            </CardTitle>
        </Card>
    )
}

export default DashboardCount;