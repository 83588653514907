import React, { Component } from "react";
import { Card, CardBody, CardTitle, Table, Input, Button, CardHeader, Col, Row, FormGroup, Label, Spinner } from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from 'moment';
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getWeeklyTimesheet, deleteTimesheetTask, addUpdateWeeklyTimesheet } from "../../redux/actions/timesheetActions";
import { addUpdateTimesheet } from "../../services/timesheets-service";
import { getAllProjects } from "../../redux/actions/projectActions";
import NotificationAlert from "react-notification-alert";
import { getEmployeeAllProjects } from '../../redux/actions/employeeProjectsAction';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { getHolidaysByLocation } from "redux/actions/holidaysByLocationAction";
import { fetchEmployeeLeaves } from "redux/actions/leaveManagementActions";
import { dateFormatList } from "variables/constants";

const newTask = {
    id: 0,
    eid: 0,
    cid: 0,
    pid: 0,
    twid: 0,
    workDate: '',
    totalHours: 0,
    task: '',
};


const projectWeekTimesheet = {
    id: 0,
    eid: null,
    cid: 0,
    pid: 0,
    startDate: null,
    endDate: null,
    totalHours: 0,
    managerId: null,
    dateSubmitted: null,
    dateStatusUpdated: null,
    tids: null,
    status: 'New',
    rejectionNote: null,
    timesheetDailyList: [],
};


class EnterTimesheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf('week').format('YYYY-MM-DD'),
            endDate: moment().endOf('week').format('YYYY-MM-DD'),
            selectedDate: moment().startOf('week').format('YYYY-MM-DD'),
            timesheet: {},
            timesheetWeekly: [],
            dayTasks: [],
            isUpdating: false,
            loggedHours: 0,
            isTimesheetLoaded: false,
            alert: null,
            disable: false,
            isSaveLoading: false,
            isSubmitLoading: false,
            isNewTimesheet: true,
            currentWeekTimesheet: [],
            previousWeekTimesheet: [],
            isTimesheetCopied: false,
            overallStatus: 'NA',
            isConfirmSubmitLoading: false,
        }
    }
    notify = (place, type, message) => {

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 2
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }

        setTimeout(() => {
            this.setState({
                isSaveLoading: false,
                isSubmitLoading: false
            })
        }, 2000);
    };



    handleDates = (timesheet) => {
        const dateString = moment(this.state.selectedDate).format('YYYY-MM-DD');
        let dailyTimesheets = [];
        let timesheetWeekly = [];
        var timesheetData = JSON.parse(JSON.stringify(timesheet));
        var weeklyTimesheet = [];
        var newDailyTimesheets = [];

        for (let i = 0; i < 7; i++) {
            let dailyData = {
                id: 0,
                eid: this.props.user.eid,
                cid: this.props.user.cid,
                pid: 0,
                twid: 0,
                workDate: '',
                totalHours: 0,
                task: '',
            };
            let workDate = moment(this.state.selectedDate).add(i, 'day').format('YYYY-MM-DD').toString();
            dailyData.workDate = workDate;
            weeklyTimesheet.push(dailyData);
        }


        const arrayData = weeklyTimesheet.map((el, index) => ({ ...el, twid: 0, 'shouldDisable': false }));
        newDailyTimesheets.push(...arrayData);
        let filteredNewDailyTimesheets = newDailyTimesheets.filter(dailyTimesheetItem => dailyTimesheetItem.twid === 0);
        const weeklyTimesheetsByDate = _.groupBy(filteredNewDailyTimesheets, 'workDate');

        timesheetData.map((timesheetItem) => {
            delete timesheetItem.timesheetDailyList;
            timesheetWeekly.push(timesheetItem);
        });
        var shouldDisable = false;
        timesheet.map((ts => {

            // if (ts.status === "Approved" || ts.status === "Submitted" || ts.status === "Resubmitted") {
            //     shouldDisable = true;
            // } else {
            //     shouldDisable = false;

            // }           

            if (this.state.overallStatus === "Approved" || this.state.overallStatus === "Submitted" || this.state.overallStatus === "Resubmitted") {
                shouldDisable = true;
            } else {
                shouldDisable = false;
            }


            const array = ts.timesheetDailyList.map((el) => ({ ...el, twid: ts.id, 'shouldDisable': shouldDisable, 'status': ts.status }));

            dailyTimesheets.push(...array);
        }));
        const dailyTimesheetsByDate = _.groupBy(dailyTimesheets, 'workDate');
        const finalDailyTimesheetsByDate = Object.assign(weeklyTimesheetsByDate, dailyTimesheetsByDate);

        var isNewTimesheet = dailyTimesheets.findIndex(dailyTimesheetsItem => dailyTimesheetsItem.pid !== 0);

        this.setState({
            ...this.state,
            timesheetWeekly: [...timesheetWeekly],

            timesheet: { ...finalDailyTimesheetsByDate },
            isNewTimesheet: isNewTimesheet === -1 ? true : false,
            dayTasks: dailyTimesheetsByDate[dateString]
                ? [...dailyTimesheetsByDate[dateString]]
                : [
                    {
                        ...newTask,
                        workDate: dateString,
                    },
                ],
            isTimesheetLoaded: true
        }, async () => {
            await this.handleLoggedHours(this.props.timesheet);
            this.setTimesheetStatusAndPermission(timesheet);
        });

    }

    handleLoggedHours = (timesheet) => {
        let loggedHours = 0;
        timesheet.map((timesheetItem, index) => {
            loggedHours = loggedHours + timesheetItem.totalHours;
        });
        var isDecimal = loggedHours % 1 !== 0;
        var isLoaded = this.state.timesheetWeekly.length > 0 ? true : false;
        var shouldDisable = false;
        if (isLoaded === true) {

            var disableCount = 0;
            this.state.timesheetWeekly.map((timesheetItem) => {
                if (timesheetItem.status === "Rejected" || timesheetItem.status === "New") {
                    disableCount = disableCount + 1;
                }
            });
            shouldDisable = disableCount > 0 ? false : true;
        }

        this.setState({
            ...this.state,
            loggedHours: isDecimal === true ? loggedHours.toFixed(2) : loggedHours,
            isTimesheetLoaded: isLoaded === true ? true : false,
            disable: shouldDisable === true ? true : false
        });
    }
    async UNSAFE_componentWillMount() {
        this.setState({
            ...this.state.timesheetWeekly,
            cid: this.props.cid
        })
        await this.props.getAllProjects(this.props.cid);
        await this.props.getWeeklyTimesheet(this.props.user.cid, moment().startOf('week').format('YYYY-MM-DD'), this.props.user.eid);

        await this.props.getEmployeeAllProjects(this.props.user.cid, this.props.user.eid);

        this.handleDates(this.props.timesheet);
        if (this.props.holidays.length == 0) {
            this.props.getHolidaysByLocation(this.props.cid, this.props.baseLocation)
        }

        if (this.props.leaves.length === 0) {
            const response = await this.props.fetchEmployeeLeaves(this.props.user.cid, this.props.user.eid);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps && nextProps.timesheet) {
            if (!this.state.isTimesheetCopied) {
                this.handleDates(nextProps.timesheet);
            } else {
                this.handleMergeTimesheet(nextProps.timesheet);

            }

        }
    }

    handleSelectedDate = async (date) => {

        if (moment(this.state.selectedDate).year() !== moment(date).year()) {
            this.props.getHolidaysByLocation(this.props.cid, this.props.baseLocation)
        }
        await this.props.getWeeklyTimesheet(this.props.user.cid, moment(date).startOf('week').format('YYYY-MM-DD'), this.props.user.eid);
        this.setState({
            ...this.state,
            timesheet: {},
            startDate: moment(date).startOf('week').format('YYYY-MM-DD'),
            endDate: moment(date).endOf('week').format('YYYY-MM-DD'),
            selectedDate: moment(date).startOf('week').format('YYYY-MM-DD'),
        });
        this.handleDates(this.props.timesheet);

    }

    handleCopyTimesheet = async () => {
        let currentWeekTimesheet = JSON.parse(JSON.stringify(this.state.timesheet));
        let previousWeekTimesheet = JSON.parse(JSON.stringify(this.props.timesheet));

        this.setState({
            ...this.state,
            isTimesheetCopied: true,
            currentWeekTimesheet: currentWeekTimesheet,
            previousWeekTimesheet: [...previousWeekTimesheet]
        }, async () => {
            let startDateOfPreviousWeek = moment(this.state.startDate).subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
            await this.props.getWeeklyTimesheet(this.props.user.cid, moment(startDateOfPreviousWeek).startOf('week').format('YYYY-MM-DD'), this.props.user.eid);

        })

    }
    handleMergeTimesheet = (timesheet) => {


        let dailyTimesheets = [];

        var weeklyTimesheet = [];
        var newDailyTimesheets = [];


        for (let i = 0; i < 7; i++) {
            let dailyData = {
                id: 0,
                eid: this.props.user.eid,
                cid: this.props.user.cid,
                pid: 0,
                twid: 0,
                workDate: '',
                totalHours: 0,
                task: '',
            };
            let workDate = moment(this.state.selectedDate).add(i, 'day').format('YYYY-MM-DD').toString();
            dailyData.workDate = workDate;
            weeklyTimesheet.push(dailyData);
        }


        const arrayData = weeklyTimesheet.map((el, index) => ({ ...el, twid: 0 }));
        newDailyTimesheets.push(...arrayData);
        let filteredNewDailyTimesheets = newDailyTimesheets.filter(dailyTimesheetItem => dailyTimesheetItem.twid === 0);
        const weeklyTimesheetsByDate = _.groupBy(filteredNewDailyTimesheets, 'workDate');

        timesheet.map((ts => {
            const array = ts.timesheetDailyList.map((el) => ({ ...el, twid: ts.id }));
            dailyTimesheets.push(...array);
        }));


        let clonedDailyTimesheet = [];
        var previousTimesheet = JSON.parse(JSON.stringify(timesheet));

        previousTimesheet.map((timehseetItem, index) => {
            timehseetItem.timesheetDailyList.map((item, i) => {

                let day = moment(item.workDate).day();
                var currentWeekDate = moment(this.state.startDate).weekday(day).format('YYYY-MM-DD');

                let clonedItem = item;
                clonedItem.workDate = currentWeekDate;
                clonedItem.id = 0;
                clonedItem.twid = 0;
                clonedDailyTimesheet.push(clonedItem);

            });

        });
        let sortedClonedDailyTimesheet = _.sortBy(clonedDailyTimesheet, function (o) { return new moment(o.workDate); });

        var activeprojects = []

        this.props.projects && this.props.projects.map(project => {
            sortedClonedDailyTimesheet.map((copiedProject, index) => {
                if (project.id === copiedProject.pid) {
                    if (project.isActive === "Y") {
                        activeprojects.push(copiedProject);
                    }
                }
            })
        });

        // const clonedWeeklyTimesheetsByDate = _.groupBy(sortedClonedDailyTimesheet, 'workDate');
        const clonedWeeklyTimesheetsByDate = _.groupBy(activeprojects, 'workDate');

        const finalDailyTimesheetsByDate = Object.assign(weeklyTimesheetsByDate, clonedWeeklyTimesheetsByDate);

        this.setState({
            ...this.state,
            timesheet: { ...finalDailyTimesheetsByDate },
        }, async () => {
            // await this.handleLoggedHours(this.props.timesheet);
            await this.handleLoggedHours(activeprojects);
        });

    }
    handleSubmit = async () => {

        let timesheetDailyListData = [];
        Object.entries(this.state.timesheet).map((day, i) => {
            const data = day[1];
            data && data.map((dataItem, index) => {
                timesheetDailyListData.push(dataItem);
            })
        });

        let timesheetWeekly = { ...this.state.timesheetWeekly };
        timesheetWeekly.status = "Submitted";
        let timesheet = {
            ...timesheetWeekly,
            timesheetDailyList: [...timesheetDailyListData]
        }


        let result = await addUpdateTimesheet(timesheet);
        if (result.success) {
            await this.props.addUpdateWeeklyTimesheet(result.data);
            this.notify("tr", "success", "Timesheet submitted successfully.");
        } else {
            this.notify("tr", "warning", "Failed to submit timesheet!");
        }

    }

    handleSave = async () => {
        let timesheetDailyListData = [];

        Object.entries(this.state.timesheet).map((day, i) => {
            const data = day[1];
            data && data.map((dataItem, index) => {
                timesheetDailyListData.push(dataItem);
            })
        });


        let timesheet = {
            ...this.state.timesheetWeekly,
            timesheetDailyList: [...timesheetDailyListData]
        }

        let result = await addUpdateTimesheet(timesheet);
        if (result.success) {
            await this.props.addUpdateWeeklyTimesheet(result.data);

            this.notify("tr", "success", "Saved timesheet successfully.");
        } else {
            this.notify("tr", "warning", "Failed to update timesheet!");
        }

    }
    handleSaveTimesheet = async (status) => {


        // var validTimesheet = true;
        // Object.entries(this.state.timesheet).map((day, i) => {
        //     const data = [...day[1]];

        //     if (data !== undefined) {
        //         for (let i = 0; i < data.length; i++) {

        //             if (data && data[i].pid === 0 || data[i].task === null || data[i].task.trim() === "" || isNaN(data[i].totalHours) === true) {
        //                 validTimesheet = false;
        //                 break;
        //             }
        //         }
        //     }

        // })


        // if (!validTimesheet) {
        //     this.notify("tr", "warning", "Please enter valid timesheet!");
        //     return;
        // }

        var validDailyHours = true;
        var invalidDailyHoursDate = null;
        if (status === 'New') {
            this.setState({
                isSaveLoading: true,
                isSubmitLoading: false,
            })
        } else {
            this.setState({
                isSaveLoading: false,
                isSubmitLoading: true
            })
        }
        await this.updateTaskInTimesheet();

        let timesheetList = [];
        Object.keys(this.state.timesheet).map((key) => {
            let enteredDailyHours = 0;
            let dailytimesheet = [...this.state.timesheet[key]];
            dailytimesheet.map((dailytimesheetItem, index) => {
                enteredDailyHours = enteredDailyHours + dailytimesheetItem.totalHours;
            });

            if (enteredDailyHours > 12) {
                validDailyHours = false;
                invalidDailyHoursDate = key;
            }

            timesheetList.push(...this.state.timesheet[key]);
        });

        if (!validDailyHours) {
            this.notify("tr", "warning", "Entered timesheet hours on " + invalidDailyHoursDate + " exceeds 12hrs!");
            return null;
        }

        let index = timesheetList.findIndex(list => ((list.pid === 0 && (list.task !== "") && list.pid === 0 && (list.task !== null)) || (list.pid === 0 && list.totalHours !== 0)));

        if (index > -1) {
            if (status === "New") {
                this.notify("tr", "warning", "Please select project to save timesheet!");
            } else {
                this.notify("tr", "warning", "Please select project to submit timesheet!");
            }
        } else {
            const { timesheet } = this.props;
            var newTimesheet = [];
            let existingTimeshetWeeklyPids = [];

            if (this.state.isTimesheetCopied === true) {

                this.state.timesheetWeekly.forEach((timesheetWeekly) => {

                    if (timesheetWeekly.pid > 0) {
                        existingTimeshetWeeklyPids.push(timesheetWeekly.pid);
                        const timesheetDailyList = timesheetList.filter(
                            (timesheetDaily) => timesheetDaily.pid === timesheetWeekly.pid,
                        );


                        if (timesheetWeekly.status === "Rejected") {

                            newTimesheet.push({
                                ...timesheetWeekly,
                                status: 'Resubmitted',
                                timesheetDailyList: [...timesheetDailyList],
                            });
                        } else if (status === 'Submitted') {

                            newTimesheet.push({
                                ...timesheetWeekly,
                                status: "Submitted",
                                timesheetDailyList: [...timesheetDailyList],
                            });
                        } else {
                            newTimesheet.push({
                                ...timesheetWeekly,
                                // status,
                                timesheetDailyList: [...timesheetDailyList],
                            });

                        }

                    }
                });
            } else {

                timesheet.forEach((timesheetWeekly) => {

                    if (timesheetWeekly.pid > 0) {
                        existingTimeshetWeeklyPids.push(timesheetWeekly.pid);
                        const timesheetDailyList = timesheetList.filter(
                            (timesheetDaily) => timesheetDaily.pid === timesheetWeekly.pid,
                        );
                        if (timesheetWeekly.status === "Rejected") {

                            newTimesheet.push({
                                ...timesheetWeekly,
                                status: 'Resubmitted',
                                timesheetDailyList: [...timesheetDailyList],
                            });
                        } else if (status === 'Submitted') {

                            newTimesheet.push({
                                ...timesheetWeekly,
                                status: "Submitted",
                                timesheetDailyList: [...timesheetDailyList],
                            });
                        } else {

                            newTimesheet.push({
                                ...timesheetWeekly,
                                timesheetDailyList: [...timesheetDailyList],
                            });

                        }
                    }
                });
            }
            const newProjectsDailyTasks = _.filter(
                timesheetList,
                (v) => _.indexOf(existingTimeshetWeeklyPids, v.pid) === -1,
            );
            const newTimesheetDailyGroupByPid = {
                ..._.groupBy(newProjectsDailyTasks, 'pid'),
            };
            delete newTimesheetDailyGroupByPid['0'];

            if (this.state.isTimesheetCopied === true) {
                var startDate = this.state.timesheetWeekly[0].startDate;
                var endDate = this.state.timesheetWeekly[0].endDate;


                Object.keys(newTimesheetDailyGroupByPid).map((key) => {
                    const projectTasks = [...newTimesheetDailyGroupByPid[key]];

                    newTimesheet.push({
                        ...projectWeekTimesheet,
                        eid: this.state.timesheetWeekly[0].eid,
                        cid: this.state.timesheetWeekly[0].cid,
                        startDate: startDate,
                        endDate: endDate,
                        pid: key,
                        status,
                        timesheetDailyList: [...projectTasks],
                    });

                });

            } else {

                Object.keys(newTimesheetDailyGroupByPid).map((key) => {
                    const projectTasks = [...newTimesheetDailyGroupByPid[key]];

                    newTimesheet.push({
                        ...projectWeekTimesheet,
                        eid: timesheet[0].eid,
                        cid: timesheet[0].cid,
                        startDate: timesheet[0].startDate,
                        endDate: timesheet[0].endDate,
                        pid: key,
                        status,
                        timesheetDailyList: [...projectTasks],
                    });

                });
            }

            var isValid = true;
            var isValidTotalHours = true;
            newTimesheet.map((timesheetItem, index) => {
                timesheetItem.timesheetDailyList.map((item, i) => {
                    if (item.task !== null) {
                        if (item.task.trim() === '') {
                            isValid = false;
                        }
                    } else {
                        isValid = false;
                    }
                })
                if (status === "Submitted") {
                    let result = timesheetItem.timesheetDailyList.findIndex(list => ((list.pid !== 0 && list.totalHours === null) || (list.pid !== 0 && isNaN(list.totalHours) === true)));
                    if (result > -1) {
                        isValidTotalHours = false;
                    }
                }
            });
            if (isValid === false) {
                this.notify("tr", "warning", "Please enter task for project!");
            }

            if (isValidTotalHours === false) {
                this.notify("tr", "warning", "Please enter total hours for project!");
                isValid = false;
            }

            if (isValid) {
                var totalHours = 0.0;

                newTimesheet.map((timesheet, index) => {
                    timesheet.timesheetDailyList.map((listItem, i) => {
                        totalHours = totalHours + listItem.totalHours;
                    });
                });


                if (totalHours < 40 && status === "Submitted") {
                    this.setState({
                        ...this.state,
                    }, () => {
                        this.notify("tr", "warning", "Timesheet should log atleast 40 hours for the week!");
                    })

                } else if (totalHours > 40 && status === "Submitted") {
                    this.setState({
                        ...this.state,
                        isUpdating: true,
                        isSubmitLoading: false,
                        alert: (
                            <ReactBSAlert
                                warning
                                style={{ display: "block", marginTop: "-100px" }}
                                title=""
                                onConfirm={() => this.onConfirmSubmit(newTimesheet)}
                                onCancel={() => this.hideAlert()}
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                confirmBtnText="Yes, Submit!"
                                cancelBtnText="Cancel"
                                showCancel
                                btnSize="sm"
                            >
                                Logged hours are more than 40 hours
                            </ReactBSAlert>
                        )
                    });

                } else if (status === "Rejected") {

                    const response = await addUpdateTimesheet(newTimesheet);
                    if (response.success) {
                        if (status === 'New') {
                            this.notify("tr", "success", "Timesheet saved successfully.");
                        } else {
                            this.notify("tr", "success", "Timesheet submitted successfully.");
                        }
                        this.setState({
                            ...this.state,
                            isUpdating: false,
                            isNewTimesheet: false,
                            isTimesheetCopied: false,
                        }, async () => {
                            await this.props.addUpdateWeeklyTimesheet(response.data);
                        });
                    } else {
                        if (status === 'New') {
                            this.notify("tr", "warning", "Failed to save timesheet!");
                        } else {
                            this.notify("tr", "warning", "Failed to submit timesheet!");
                        }
                    }

                } else {

                    this.setState(
                        {
                            isUpdating: true,
                        },
                        async () => {

                            const response = await addUpdateTimesheet(newTimesheet);
                            if (response.success) {
                                if (status === 'New') {
                                    this.notify("tr", "success", "Timesheet saved successfully.");
                                } else {
                                    this.notify("tr", "success", "Timesheet submitted successfully.");
                                }
                                this.setState({
                                    ...this.state,
                                    isUpdating: false,
                                    isNewTimesheet: false,
                                    isTimesheetCopied: false,
                                }, async () => {
                                    await this.props.addUpdateWeeklyTimesheet(response.data);
                                });
                            } else {
                                if (status === 'New') {
                                    this.notify("tr", "warning", "Failed to save timesheet!");
                                } else {
                                    this.notify("tr", "warning", "Failed to submit timesheet!");
                                }
                            }
                        },
                    );
                }
            }
        }
    };

    onConfirmSubmit = async (newTimesheet) => {

        if (this.state.isConfirmSubmitLoading) {
            return;
        }

        this.setState({
            ...this.state,
            isConfirmSubmitLoading: true,

        }, async () => {
            const response = await addUpdateTimesheet(newTimesheet);
            if (response.success) {

                this.setState({
                    ...this.state,
                    isUpdating: false,
                    isSubmitLoading: false,
                    isNewTimesheet: false,
                    isTimesheetCopied: false,
                    alert: null,
                    isConfirmSubmitLoading: false
                }, async () => {
                    await this.props.addUpdateWeeklyTimesheet(response.data);
                    let options = {};
                    options = {
                        place: "tr",
                        message: "Your timesheet submitted successfully.",
                        type: "success",
                        icon: "tim-icons icon-bell-55",
                        autoDismiss: 2
                    };
                    if (this.refs.notificationAlert) {
                        this.refs.notificationAlert.notificationAlert(options);
                    }
                });
            } else {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    isSubmitLoading: false,
                    isNewTimesheet: false,
                    isTimesheetCopied: false,
                    alert: null,
                    isConfirmSubmitLoading: false
                }, () => {
                    this.notify("tr", "warning", "Failed to submit timesheet!");
                })
            }
        })
    }


    hideAlert = () => {
        this.setState({
            ...this.state,
            isUpdating: false,
            alert: null
        });
    };

    updateTaskInTimesheet = () => {
        let timesheet = { ...this.state.timesheet };
        this.setState({
            timesheet: { ...timesheet },
        });
    };



    renderTableHeader = () => {

        return (
            <tr>
                <th>Day</th>
                <th className="timesheet-date" style={{}}>Date</th>
                <th className="timesheet-project" style={{}}>Project Name</th>
                <th className="timesheet-task" style={{}}>Task</th>
                <th className="timesheet-totalHours" style={{}}>Total Hours</th>
                <th className="timesheet-actions"></th>
            </tr>
        )

    }

    handleOnChange = (e, index, workDate) => {
        let weekTimesheet = { ...this.state.timesheet };
        let dayTimesheet = [...weekTimesheet[workDate]];
        let projectTask = { ...dayTimesheet[index] };
        if (e.target.name === "pid") {
            let project = _.find(dayTimesheet, { pid: parseInt(e.target.value) });
            if (project && project.pid > 0) {
                this.notify("tr", "warning", "Please select unique project!");
                return;
            } else {
                projectTask[e.target.name] = parseInt(e.target.value);
            }
        } else if (e.target.name === "totalHours") {
            projectTask[e.target.name] = (parseFloat(e.target.value) > 24 || parseFloat(e.target.value) < 0) ? 0 : parseFloat(e.target.value);
        } else {
            projectTask[e.target.name] = e.target.value;
        }

        dayTimesheet[index] = { ...projectTask };
        weekTimesheet[workDate] = [...dayTimesheet];
        this.setState({
            timesheet: {
                ...weekTimesheet
            }
        });
    }


    handleNewTimesheet = (e, workDate) => {
        let weekTimesheet = { ...this.state.timesheet };
        let dayTimesheet = [...weekTimesheet[workDate]];

        dayTimesheet.push(
            {
                id: 0,
                eid: this.props.user.eid,
                cid: this.props.user.cid,
                workDate: workDate,
                totalHours: 0,
                task: "",
                pid: 0,
                twid: 0
            }
        );

        weekTimesheet[workDate] = [...dayTimesheet];
        this.setState({
            timesheet: {
                ...weekTimesheet
            }
        });
    }

    handleDeleteTimesheet = async (e, workDate, timesheetItem, data, index) => {
        let weekTimesheet = { ...this.state.timesheet };

        let dayTimesheet = [...weekTimesheet[workDate]];

        if (timesheetItem.id === 0) {
            dayTimesheet.splice(index, 1);
            weekTimesheet[workDate] = [...dayTimesheet];
            this.setState({
                timesheet: {
                    ...weekTimesheet
                }
            });
        } else {
            let currentWeeklyTimesheet = this.state.timesheetWeekly.filter(timesheetWeeklyItem => timesheetWeeklyItem.pid === timesheetItem.pid);

            let result = await this.props.deleteTimesheetTask(timesheetItem, currentWeeklyTimesheet[0].id);
            if (result.success) {
                dayTimesheet.splice(index, 1);
                weekTimesheet[workDate] = [...dayTimesheet];
                this.setState({
                    ...this.state,
                    timesheet: {
                        ...weekTimesheet,
                    }
                });
                this.notify("tr", "success", "Timesheet task deleted successfully.");
            } else {
                this.notify("tr", "warning", "Failed to delete timesheet!");
            }
        }
    }


    setTimesheetStatusAndPermission = (timesheet) => {

        let newTimesheetsCount = 0;
        let submittedTimesheetsCount = 0;
        let approvedTimesheetsCount = 0;
        let rejectedTimesheetsCount = 0;
        let resubmittedTimesheetsCount = 0;
        timesheet.map((ts) => {
            switch (ts.status) {
                case 'New':
                    newTimesheetsCount = newTimesheetsCount + 1;
                    break;
                case 'Submitted':
                    submittedTimesheetsCount = submittedTimesheetsCount + 1;
                    break;
                case 'Approved':
                    approvedTimesheetsCount = approvedTimesheetsCount + 1;
                    break;
                case 'Rejected':
                    rejectedTimesheetsCount = rejectedTimesheetsCount + 1;
                    break;
                case 'Resubmitted':
                    resubmittedTimesheetsCount = resubmittedTimesheetsCount + 1;
                    break;
                default:
                    break;
            }
        });
        let overallStatus = 'NA';
        if (newTimesheetsCount === timesheet.length) {
            overallStatus = 'New';
        } else if (submittedTimesheetsCount === timesheet.length) {
            overallStatus = 'Submitted';
        } else if (approvedTimesheetsCount === timesheet.length) {
            overallStatus = 'Approved';
        } else if (rejectedTimesheetsCount > 0) {
            overallStatus = 'Rejected';
        } else if (resubmittedTimesheetsCount > 0) {
            overallStatus = 'Resubmitted';
        } else {
            overallStatus = 'Submitted';
        }
        this.setState({
            ...this.state,
            overallStatus
        });
    };


    renderTableData = () => {
        var { employeeBasicInfo } = this.props;
        var { disable } = this.state;
        let options = this.props.employeeProjects.map((option, index) => {
            return (<option key={index} value={option.id} disabled={option.isActive === "Y" ? false : true}>{option.project}</option>)
        });

        const { timesheet } = this.state;
        return Object.entries(timesheet).map((day, i) => {
            const workDate = day[0];
            const data = [...day[1]];

            return (

                <tr key={workDate} style={{
                    borderTop: 0, backgroundColor: moment(workDate).isSame(moment(workDate).weekday(0)) || moment(workDate).isSame(moment(workDate).weekday(6)) ? '##f3f1f1dd' : this.props.holidays.length > 0 && this.props.holidays.find((holiday) => {
                        return moment(holiday.holidayDate, 'YYYY-MM-DD').isSame(moment(workDate, 'YYYY-MM-DD'))
                    }) ? 'wheat' : this.props.leaves.length > 0 && this.props.leaves.find((leave) => {
                        return moment(workDate, 'YYYY-MM-DD').isBetween(moment(leave.startDate, 'YYYY-MM-DD'), moment(leave.endDate, 'YYYY-MM-DD'), '', []) && leave.leaveStatus === "Approved"
                    }) ? 'wheat' : 'transparent'
                }
                }>
                    <td>
                        {moment(workDate).format('dddd')}
                    </td>
                    <td>
                        {moment(workDate).format(dateFormatList[2])}
                    </td>
                    <td colSpan="4">
                        <table className="custom-inner-tables" id="timesheet-inner-table" style={{ width: "100%" }}>
                            <tbody >
                                <tr className="inner-row" style={{ borderTop: 0, backgroundColor: 'transparent' }}>
                                    {data.map((timesheetItem, index) => {
                                        return (
                                            <div className='row' key={i}>
                                                <td className="inner-project-name" key={'project' + index} >
                                                    <Input type="select" name="pid" value={timesheetItem.pid} onChange={(e) => this.handleOnChange(e, index, workDate)} id="empid"
                                                        disabled={disable === true ? true : employeeBasicInfo !== undefined ? moment(workDate).isBefore(employeeBasicInfo.doj) === true ? true : timesheetItem.shouldDisable === true ? true : false : false}
                                                    >
                                                        <option value={0}>Select Project</option>
                                                        {options}
                                                    </Input>
                                                </td>
                                                <td key={'task' + index} className="inner-task-name" >
                                                    <Input
                                                        name="task"
                                                        type="textarea"
                                                        placeholder="Task"
                                                        value={timesheetItem.task === null ? '' : timesheetItem.task}
                                                        onChange={e => this.handleOnChange(e, index, workDate)}
                                                        disabled={disable === true ? true : employeeBasicInfo !== undefined ? moment(workDate).isBefore(employeeBasicInfo.doj) === true ? true : timesheetItem.shouldDisable === true ? true :  this.props.holidays.find((holiday) => {
                                                            return moment(holiday.holidayDate, 'YYYY-MM-DD').isSame(moment(workDate, 'YYYY-MM-DD'))
                                                        }) ? true : false : this.props.holidays.find((holiday) => {
                                                            return moment(holiday.holidayDate, 'YYYY-MM-DD').isSame(moment(workDate, 'YYYY-MM-DD'))
                                                        }) ? true : false}
                                                        rows={1}
                                                        maxLength={255}
                                                    />
                                                </td>


                                                <td style={{ borderTop: 0 }} key={'hours' + index}>
                                                    <Input
                                                        style={{ width: 100, margin: 'auto' }}
                                                        className="totalHours"
                                                        name="totalHours"
                                                        type="number"
                                                        placeholder="Total Hours"
                                                        value={this.props.holidays.find((holiday) => {
                                                            return moment(holiday.holidayDate, 'YYYY-MM-DD').isSame(moment(workDate, 'YYYY-MM-DD'))
                                                        }) ? 8 : timesheetItem.totalHours}
                                                        onChange={e => this.handleOnChange(e, index, workDate)}
                                                        min={0}
                                                        max={24}
                                                        // oninput="validity.valid||(value='');"
                                                        disabled={disable === true ? true : employeeBasicInfo !== undefined ? moment(workDate).isBefore(employeeBasicInfo.doj) === true ? true : timesheetItem.shouldDisable === true ? true : this.props.holidays.find((holiday) => {
                                                            return moment(holiday.holidayDate, 'YYYY-MM-DD').isSame(moment(workDate, 'YYYY-MM-DD'))
                                                        }) ? true : false : this.props.holidays.find((holiday) => {
                                                            return moment(holiday.holidayDate, 'YYYY-MM-DD').isSame(moment(workDate, 'YYYY-MM-DD'))
                                                        }) ? true : false}
                                                    /></td>
                                                <td style={{ borderTop: 0 }} key={'btn' + index}>
                                                    <Button
                                                        onClick={e => { this.handleNewTimesheet(e, workDate) }}
                                                        color=""
                                                        size="sm"
                                                        className="btn-icon btn-link like"
                                                        disabled={disable === true ? true : employeeBasicInfo !== undefined ? moment(workDate).isBefore(employeeBasicInfo.doj) === true ? true : timesheetItem.shouldDisable === true ? true : false : false}
                                                    >
                                                        <i className="tim-icons icon-simple-add" />
                                                    </Button>
                                                    {data.length > 1 ? (
                                                        <Button
                                                            onClick={e => { this.handleDeleteTimesheet(e, workDate, timesheetItem, data, index) }}
                                                            color=""
                                                            size="sm"
                                                            className="btn-icon btn-link like"
                                                            disabled={disable === true ? true : employeeBasicInfo !== undefined ? moment(workDate).isBefore(employeeBasicInfo.doj) === true ? true : timesheetItem.status === "Rejected" ? true : timesheetItem.shouldDisable === true ? true : false : false}

                                                        >
                                                            <i className="tim-icons icon-trash-simple" />
                                                        </Button>
                                                    ) : (null)
                                                    }

                                                </td>

                                            </div>

                                        )
                                    })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

            )
        })
    }

    valid = (current) => {
        return current.day() !== 0;
    };


    rejectionNote = () => {
        // let projects = [];
        var selectedProject = '';
        return this.props.timesheet.map((ts) => {

            selectedProject = this.props.employeeProjects.find(projectItem => projectItem.id === ts.pid)

            return ts.rejectionNote !== null && selectedProject !== undefined && ts.status === "Rejected" ? (
                <li style={{ color: 'red' }}>
                    <span className="project-name">{selectedProject.project + ' - '} </span> <span className="rejection-description">{ts.rejectionNote} </span>
                </li>
            ) : (
                null
            )

        })

    }


    render() {
        return (
            <>
                <div className="content">
                    {this.state.alert}

                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Card>
                        <CardHeader style={{}}>
                            <Row className="timesheet-title">
                                <CardTitle tag="h2" style={{ marginBottom: 8, display: 'inline', paddingLeft: 15 }}>Enter New Timesheet </CardTitle>
                                {
                                    this.state.overallStatus !== "New" ? (
                                        <h6 className="logged-hours-section timesheet-status" >status: <span style={{ color: this.state.overallStatus === "Approved" ? "#008000" : this.state.overallStatus === "Rejected" ? "#FF0000" : "#000" }} >{this.state.overallStatus === "Resubmitted" ? "Submitted" : this.state.overallStatus}</span></h6>
                                    ) : (null)
                                }
                            </Row>
                            <Row className="timesheet-panel">

                                <Col md="6" lg="2">
                                    <FormGroup className={`has-label week-start-date`}>
                                        <Label>Week Start Date </Label>
                                        <ReactDatetime
                                            timeFormat={false}
                                            inputProps={{ placeholder: "Start Date", className: "form-control timesheet-start-date", value: moment(this.state.selectedDate).format(dateFormatList[2]) }}
                                            onChange={this.handleSelectedDate}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md="6" className="week-section" lg="4">
                                    <Label style={{ marginTop: 15 }}>Week: <span style={{ paddingRight: 20, }}>{moment(this.state.startDate).format(dateFormatList[2])}</span> {moment(this.state.endDate).format(dateFormatList[2])} </Label>
                                </Col>
                                <Col md="12" className="timesheet-actions-section" lg="6" >
                                    <Button
                                        onClick={() => this.state.isSubmitLoading === true ? null : this.state.overallStatus === "Rejected" ? this.handleSaveTimesheet('Rejected') : this.handleSaveTimesheet('Submitted')} size="sm" className="timesheet-btn btn-submit" color="info"
                                        disabled={this.state.disable === true ? true : this.props.employeeBasicInfo !== undefined ? moment(this.state.selectedDate).isBefore(moment(this.props.employeeBasicInfo.doj).startOf('week').format('YYYY-MM-DD')) === true ? true : false : false}

                                    >
                                        {this.state.isSubmitLoading === true ?
                                            <Spinner as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true" /> :
                                            'Submit'}</Button>
                                    <Button
                                        onClick={() => this.state.isSaveLoading === true ? null : this.handleSaveTimesheet('New')} size="sm" className="timesheet-btn btn-save"
                                        disabled={this.state.disable === true ? true : this.props.employeeBasicInfo !== undefined ? moment(this.state.selectedDate).isBefore(moment(this.props.employeeBasicInfo.doj).startOf('week').format('YYYY-MM-DD')) === true ? true : this.state.overallStatus === "Rejected" ? true : false : false}
                                        color="secondary">
                                        {this.state.isSaveLoading === true ?
                                            <Spinner as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true" /> :
                                            'Save'}</Button>
                                    <Button size="sm" onClick={this.handleCopyTimesheet}
                                        disabled={this.state.disable === true ? true : this.props.employeeBasicInfo !== undefined ? moment(this.state.selectedDate).isBefore(moment(this.props.employeeBasicInfo.doj).startOf('week').format('YYYY-MM-DD')) === true ? true : false : false}
                                        className={`timesheet-btn btn-copy ${this.state.isNewTimesheet === true ? "display-btn" : "hide-btn"}`} color="info" >Copy Timesheet</Button>
                                    <h4 className="logged-hours-section" >Logged hours: {this.state.loggedHours}</h4>
                                </Col>
                            </Row>
                            {this.state.overallStatus === 'Rejected' ? (
                                <div className="rejection-note">
                                    <h4>Rejection Note</h4>
                                    <ul>
                                        {this.rejectionNote()}
                                    </ul>

                                </div>
                            ) : (
                                null
                            )

                            }

                        </CardHeader>
                        <CardBody>
                            <Table className="custom-timesheet-table striped" striped>
                                <thead style={{ backgroundColor: '#736d6d' }}>
                                    {this.renderTableHeader()}
                                </thead>
                                <tbody>
                                    {this.renderTableData()}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </>
        );
    }
}

export const mapDispatchToProps = dispatch => ({
    getWeeklyTimesheet: bindActionCreators(getWeeklyTimesheet, dispatch),
    getAllProjects: bindActionCreators(getAllProjects, dispatch),
    deleteTimesheetTask: bindActionCreators(deleteTimesheetTask, dispatch),
    addUpdateWeeklyTimesheet: bindActionCreators(addUpdateWeeklyTimesheet, dispatch),
    getEmployeeAllProjects: bindActionCreators(getEmployeeAllProjects, dispatch),
    getHolidaysByLocation: bindActionCreators(getHolidaysByLocation, dispatch),
    fetchEmployeeLeaves: bindActionCreators(fetchEmployeeLeaves, dispatch),
});


export const mapStateToProps = ({ auth, projects, client, companies, timesheet, employeeProjects, employeeBasicInfo, holidaysByLocation, leaves }) => {

    return {
        token: auth.user.token,
        projects: projects,
        clients: client,
        companies: companies,
        timesheet: timesheet,
        cid: auth.user.cid,
        baseLocation: auth.user.baseLocation,
        user: auth.user,
        employeeProjects: employeeProjects,
        employeeBasicInfo: employeeBasicInfo == null ? undefined : employeeBasicInfo,
        holidays: holidaysByLocation,
        leaves: leaves,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterTimesheet);