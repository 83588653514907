import classNames from "classnames";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import ReactDatetime from "react-datetime";
import { FaRegFileAlt, FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";
import { getAllDesignations } from "../../../redux/actions/designationAction";
import { getAllEmployees } from "../../../redux/actions/employeeAction";
import { getEmployeeExtraInfo } from "../../../redux/actions/employeeExtraInfoAction";
import { getAllEmployeesByManager } from "../../../redux/actions/managerEmployeesAction";
import { getAdminQuarterlyInvoices, getAdminMonthlyInvoices, sendProjectInvoice } from "../../../redux/actions/adminInvoicesAction";
import { getAdminTimesheetMonthlyReport, getAdminTimesheetQuarterlyReport, getAdminTimesheetWeeklyReport, getInitialReportData } from '../../../redux/actions/adminTimesheetReportAction';
import { getAllProjects } from "../../../redux/actions/projectActions";
import { getAllRoles } from "../../../redux/actions/rolesAction";
import MonthlyReport from "./adminMonthlyReport";
import QuarterlyReport from './adminQuarterlyReport';
import WeeklyReport from "./adminWeeklyReport";
import { AppContext } from '../../../hoc/AppContext';
import { dateFormatList } from "variables/constants";
const quarterHeaders = [
    { label: 'QUARTER', key: 'quarter' },
    { label: 'EMPLOYEE NAME', key: 'employee' },
    { label: 'PROJECT', key: 'project' },
    { label: 'TOTAL HOURS', key: 'totalHours' },
    { label: 'STATUS', key: 'status' },
    { label: 'COMMENT', key: 'comment' }
];
const monthlyExcelHeaders = [
    { label: 'MONTH', key: 'month' },
    { label: 'EMPLOYEE NAME', key: 'employee' },
    { label: 'PROJECT', key: 'project' },
    { label: 'TOTAL HOURS', key: 'totalHours' },
    { label: 'STATUS', key: 'status' },
    { label: 'COMMENT', key: 'comment' }
];
const WeeklyExcelHeaders = [
    { label: 'WEEKLY START DATE', key: 'week' },
    { label: 'EMPLOYEE NAME', key: 'employee' },
    { label: 'PROJECT', key: 'project' },
    { label: 'TOTAL HOURS', key: 'totalHours' },
    { label: 'STATUS', key: 'status' },
    { label: 'COMMENT', key: 'comment' }
]


class AdminTimesheetReport extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            monthlySelectedDate: moment().startOf('month').format('YYYY-MM-DD'),
            monthlyStatus: 'All',
            timesheetMonthlyReport: [],
            timesheetWeeklyReport: [],
            timesheetQuarterlyReport: [],
            reportType: "Quarterly",
            weeklySelectedDate: moment().startOf('week').format('YYYY-MM-DD'),
            weeklyStatus: 'All',
            selectedYear: moment().format('YYYY-MM-DD'),
            quarterlyStatus: 'All',
            selectedQuarter: moment().quarter(),
            isQuarterlyReportLoading: false,
            isMonthlyReportLoading: false,
            isWeeklyReportLoading: false,
            isCreateInvoiceLoading: false,
            selectedQuarterlyEmployee: 'All',
            selectedMonthlyEmployee: 'All',
            selectedWeeklyEmployee: 'All',
            QuarterlyExcelData: [],
            MonthlyExcelData: [],
            WeeklyExcelData: [],
            shouldDisplayQuarterInvoice: false,
            shouldDisplayMonthlyInvoice: false,
            isDataMonthlyLoading: false,
            isDataQuarterlyLoading: false,
            isDataWeeklyLoading: false,
            selectedQuarterlyProject: 0,
            selectedMonthlyProject: 0,
            selectedWeeklyProject: 0,
            showQuarterlyInvoiceErrorMessages: false,
            showMonthlyInvoiceErrorMessages: false,
            quarterlyTimesheetCSV: 'Quarterly-Timesheet-Report.csv',
            monthlyTimesheetCSV: 'Monthly-Timesheet-Report.csv',
            weeklyTimesheetCSV: 'Weekly-Timesheet-Report.csv',
            isLoading: [],
            isMonthlyLoading: []

        };
    }

    async UNSAFE_componentWillMount() {
        await this.props.getInitialReportData();
        await this.props.getAllProjects(this.props.user.cid);
        await this.props.getAllEmployees(this.props.user.cid);
        await this.props.getEmployeeExtraInfo(this.props.user.cid, this.props.user.eid);
        await this.props.getAllDesignations(this.props.user.cid);
        await this.props.getAllRoles(this.props.user.cid);
        await this.props.getAllEmployeesByManager(this.props.user.eid);
    }
    async UNSAFE_componentWillReceiveProps(nextProps) {

        this.setState({
            ...this.state,
            timesheetMonthlyReport: this.props.timesheetMonthlyReport !== nextProps.timesheetMonthlyReport ? nextProps.timesheetMonthlyReport : this.props.timesheetMonthlyReport,
            timesheetQuarterlyReport: this.props.timesheetQuarterlyReport !== nextProps.timesheetQuarterlyReport ? nextProps.timesheetQuarterlyReport : this.props.timesheetQuarterlyReport,
            timesheetWeeklyReport: this.props.timesheetWeeklyReport !== nextProps.timesheetWeeklyReport ? nextProps.timesheetWeeklyReport : this.props.timesheetWeeklyReport
        })
    }


    handleReportType = (e) => {
        this.setState({
            ...this.state,
            reportType: e.target.value
        });

    }

    handleMonthlySelectedDate = (date) => {
        this.setState({
            ...this.state,
            monthlySelectedDate: moment(date).startOf('month').format('YYYY-MM-DD')
        });
    }


    handleWeeklySelectedDate = (date) => {
        this.setState({
            ...this.state,
            // weeklySelectedDate: moment(date).startOf('week').format('YYYY-MM-DD')
            weeklySelectedDate: moment(date).format('YYYY-MM-DD')

        });
    }
    handleSelectedYear = (date) => {
        this.setState({
            ...this.state,
            selectedYear: moment(date).format('YYYY-MM-DD')
        });
    }


    handleStatus = (e) => {
        let monthlyStatus = e.target.value;
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            // monthlyStatus: monthlyStatus
        });
    }

    handleSelectedQuarter = (e) => {

        this.setState({
            ...this.state,
            [e.target.name]: parseInt(e.target.value),
        });
    }

    handleSelectEmployee = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });
    }

    handleSelectProject = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });
    }



    notify = (place, type, message) => {

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 2
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
        setTimeout(() => {
            this.setState({
                isQuarterlyReportLoading: false,
                isMonthlyReportLoading: false,
                isWeeklyReportLoading: false,
                isCreateInvoiceLoading: false
                // isSubmitLoading: false
            })
        }, 2000);
    };



    handleFind = async () => {
        this.setState({
            ...this.state,
            isMonthlyReportLoading: true,
            isDataMonthlyLoading: true,
            showMonthlyInvoiceErrorMessages: false
        })
        var quarter = moment(this.state.monthlySelectedDate).quarter();
        var month = moment(this.state.monthlySelectedDate).format('M');
        var year = moment(this.state.monthlySelectedDate).format('YYYY');
        var managerId = this.props.user.eid;

        var pid = this.state.selectedMonthlyProject === 0 ? 'All' : this.state.selectedMonthlyProject.toString();
        var selectedMonthlyEmployee = this.state.selectedMonthlyEmployee === null ? 'All' : this.state.selectedMonthlyEmployee;


        let result = await this.props.getAdminTimesheetMonthlyReport(this.props.user.cid, selectedMonthlyEmployee, moment(this.state.monthlySelectedDate).startOf('month').format('YYYY-MM-DD'), moment(this.state.monthlySelectedDate).endOf('month').format('YYYY-MM-DD'), this.state.monthlyStatus, quarter, month, year, pid);
        this.setState({
            ...this.state,
            shouldDisplayMonthlyInvoice: false,
            isDataMonthlyLoading: false
        })
        if (result.success) {
            if (this.props.timesheetMonthlyReport.length > 0) {
                this.notify("tr", "success", "Fetched timesheet report successfully.");
            } else {
                this.notify("tr", "success", "No data found.");
            }
            if (this.state.selectedMonthlyEmployee !== null && this.state.selectedMonthlyEmployee !== 'All') {
                let response = await this.props.getEmployeeExtraInfo(this.props.user.cid, this.state.selectedMonthlyEmployee);
            }
        } else {
            this.setState({
                ...this.state,
                timesheetMonthlyReport: []
            }, () => {
                this.notify("tr", "warning", "Failed to fetch timesheet report!");
            });
        }
    }

    handleWeeklyFind = async () => {
        this.setState({
            isWeeklyReportLoading: true,
            isDataWeeklyLoading: true

        });
        var quarter = moment(this.state.weeklySelectedDate).quarter();
        var month = moment(this.state.weeklySelectedDate).format('M');
        var year = moment(this.state.weeklySelectedDate).format('YYYY');
        var managerId = this.props.user.eid;
        var pid = this.state.selectedWeeklyProject === 0 ? 'All' : this.state.selectedWeeklyProject.toString();
        var selectedWeeklyEmployee = this.state.selectedWeeklyEmployee === null ? 'All' : this.state.selectedWeeklyEmployee;

        let result = await this.props.getAdminTimesheetWeeklyReport(this.props.user.cid, selectedWeeklyEmployee, moment(this.state.weeklySelectedDate).startOf('week').format('YYYY-MM-DD'), moment(this.state.weeklySelectedDate).endOf('week').format('YYYY-MM-DD'), this.state.weeklyStatus, quarter, month, year, pid);

        if (result.success) {
            if (this.props.timesheetWeeklyReport.length > 0) {
                this.notify("tr", "success", "Fetched timesheet report successfully.");
            } else {
                this.notify("tr", "success", "No data found.");
            }
            if (this.state.selectedWeeklyEmployee !== null && this.state.selectedWeeklyEmployee !== 'All') {
                let response = await this.props.getEmployeeExtraInfo(this.props.user.cid, this.state.selectedWeeklyEmployee);
            }
            this.setState({
                ...this.state,
                isDataWeeklyLoading: false
            });
        } else {
            this.setState({
                ...this.state,
                timesheetWeeklyReport: [],
                isDataWeeklyLoading: false
            }, () => {
                this.notify("tr", "warning", "Failed to fetch timesheet report!");
            });
        }
    }

    handleQuarterlyFind = async () => {
        this.setState({
            ...this.state,
            isQuarterlyReportLoading: true,
            isDataQuarterlyLoading: true,
            showQuarterlyInvoiceErrorMessages: false
        })
        var quarter = this.state.selectedQuarter;
        var month = moment(this.state.selectedYear).format('M');
        var year = moment(this.state.selectedYear).format('YYYY');
        var managerId = this.props.user.eid;
        var pid = this.state.selectedQuarterlyProject === 0 ? 'All' : this.state.selectedQuarterlyProject.toString();
        var selectedQuarterlyEmployee = this.state.selectedQuarterlyEmployee === null ? 'All' : this.state.selectedQuarterlyEmployee;

        let result = await this.props.getAdminTimesheetQuarterlyReport(this.props.user.cid, selectedQuarterlyEmployee, moment(this.state.selectedYear).startOf('month').format('YYYY-MM-DD'), moment(this.state.selectedYear).endOf('month').format('YYYY-MM-DD'), this.state.quarterlyStatus, quarter, month, year, pid);
        // let result = await this.props.getAdminTimesheetMonthlyReport(this.props.user.cid, this.props.user.eid, moment(this.state.monthlySelectedDate).startOf('month').format('YYYY-MM-DD'), moment(this.state.monthlySelectedDate).endOf('month').format('YYYY-MM-DD'), this.state.monthlyStatus, quarter, month, year);
        this.setState({
            ...this.state,
            shouldDisplayQuarterInvoice: false,
            isDataQuarterlyLoading: false
        });
        if (result.success) {

            if (this.props.timesheetQuarterlyReport.length > 0) {

                this.notify("tr", "success", "Fetched timesheet report successfully.");
            } else {

                this.notify("tr", "success", "No data found.");
            }
            if (this.state.selectedQuarterlyEmployee !== null && this.state.selectedQuarterlyEmployee !== 'All') {
                let response = await this.props.getEmployeeExtraInfo(this.props.user.cid, this.state.selectedQuarterlyEmployee);
            }
        } else {
            this.setState({
                ...this.state,
                timesheetQuarterlyReport: []
            }, () => {
                this.notify("tr", "warning", "Failed to fetch timesheet report!");
            });
        }
    }

    handleExportPDF = async (Type) => {

        if (this.props.employee !== undefined && this.props.designations !== undefined && this.props.roles !== undefined && this.props.employee.extraInfo !== undefined) {

            // var employeeDesignation = this.props.designations.find(designation => designation.id === this.props.employee.extraInfo.designationId);
            // var employeeRole = this.props.roles.find(role => role.id === this.props.employee.extraInfo.roleId);
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape

            const marginLeft = 40;
            const marginBottom = 20;

            const doc = new jsPDF(orientation, unit, size);
            // doc.setFont("arial");
            const title = Type === "Monthly" ? "Timesheet Monthy Report" : Type === "Weekly" ? "Timesheet Weekly Report" : "Timesheet Quarterly Report";

            doc.setFontSize(15);
            var managerName = null;
            if (Type === "Monthly") {

                let selectedEmployee = this.props.employees.find(employee => employee.eid === this.state.selectedMonthlyEmployee);

                if (this.state.selectedMonthlyEmployee !== 'All' || selectedEmployee !== undefined) {

                    let employeeName = selectedEmployee !== undefined ? selectedEmployee.fname + ' ' + selectedEmployee.lname : null;
                    let filteredDesignation = this.props.designations.find(designation => designation.id === selectedEmployee.extraInfo.designationId);
                    let fileteredRole = this.props.roles.find(role => role.id === selectedEmployee.extraInfo.roleId);
                    let employeeDesignation = filteredDesignation !== undefined ? filteredDesignation.designation : null;
                    let employeeRole = fileteredRole !== undefined ? fileteredRole.role : null;
                    let employeeEmail = selectedEmployee.emailOfficial;
                    let approvedBy = this.props.employee.fname + ' ' + this.props.employee.lname;

                    let employeeData = [
                        ['Employee Name:', employeeName, 'Designation:', employeeDesignation],
                        ['Employee No:', selectedEmployee.eid, 'Role:', employeeRole],
                        ['Email:', employeeEmail,],

                    ];

                    let employeeContent = {
                        startY: 80,
                        head: null,
                        body: employeeData,
                        tableWidth: 550,
                        theme: 'plain',
                        styles: {
                            cellPadding: 0,
                            minCellHeight: 18,
                            halign: 'left',
                            valign: 'middle',
                            fontSize: 12,
                        },
                        columnStyles: {
                            0: { cellWidth: 100 },
                            1: { cellWidth: 160 },
                            2: { cellWidth: 80 }
                        },

                    };

                    doc.autoTable(employeeContent);

                } else {
                    let selectedProject = this.props.projects.find(projectItem => parseInt(projectItem.id) === parseInt(this.state.selectedMonthlyProject));
                    let employeeData = [
                        ['Employee:', 'All', 'Year:', moment(this.state.monthlySelectedDate).year(this.state.monthlySelectedDate).format("YYYY")],
                        ['Project:', this.state.selectedMonthlyProject === 'All' || this.state.selectedMonthlyProject === 0 ? 'All' : selectedProject !== undefined ? selectedProject.project : null, 'Month:', moment(this.state.monthlySelectedDate).subtract(0, "month").startOf("month").format('MMMM')],
                        ['Status:', this.state.monthlyStatus === "New" ? "Saved" : this.state.monthlyStatus,],

                    ];

                    let employeeContent = {
                        startY: 80,
                        head: null,
                        body: employeeData,
                        tableWidth: 350,
                        theme: 'plain',
                        styles: {
                            cellPadding: 0,
                            minCellHeight: 18,
                            halign: 'left',
                            valign: 'middle',
                            fontSize: 12,
                        },
                        columnStyles: {
                            0: { cellWidth: 65 },
                            1: { cellWidth: 160 },
                            2: { cellWidth: 50 }
                        },

                    };

                    doc.autoTable(employeeContent);

                }

                if (this.state.selectedMonthlyEmployee !== 'All') {
                    let monthlyHeaders = [["Month", "Project", "Total Hours", "Status",]];

                    let monthlyData = this.state.timesheetMonthlyReport.map(reportItem => [moment(this.state.monthlySelectedDate).month(reportItem.month).format("MMM YYYY"), reportItem.project, reportItem.totalHours, reportItem.status === "New" ? "Saved" : reportItem.status]);
                    let monthlyContent = {
                        startY: 160,
                        head: monthlyHeaders,
                        body: monthlyData
                    };

                    doc.setFontSize(20);
                    doc.text(title, marginLeft, 40);
                    doc.autoTable(monthlyContent);
                } else {
                    let monthlyHeaders = [["Month", "Employee", "Project", "Total Hours", "Status",]];

                    let monthlyData = this.state.timesheetMonthlyReport.map(reportItem => [moment(this.state.monthlySelectedDate).month(reportItem.month).format("MMM YYYY"), reportItem.employeeName, reportItem.project, reportItem.totalHours, reportItem.status === "New" ? "Saved" : reportItem.status]);
                    let monthlyContent = {
                        startY: 160,
                        head: monthlyHeaders,
                        body: monthlyData
                    };

                    doc.setFontSize(20);
                    doc.text(title, marginLeft, 40);
                    doc.autoTable(monthlyContent);
                }

                let selectedMonth = moment(this.state.monthlySelectedDate).subtract(0, "month").startOf("month").format('MMMM');
                let selectedYear = moment(this.state.monthlySelectedDate).year(this.state.monthlySelectedDate).format("YYYY");
                let selectedStatus = this.state.monthlyStatus === "New" ? 'Saved' : this.state.monthlyStatus;

                doc.save("Timesheet" + "-" + selectedYear + "-" + selectedMonth + "-" + selectedStatus + ".pdf");


            } else if (Type === "Quarterly") {
                let filteredManager = this.props.managerEmployees.find(manager => manager.eid === this.state.selectedQuarterlyEmployee);
                managerName = filteredManager !== undefined ? filteredManager.fname + ' ' + filteredManager.lname : null;
                if (this.state.selectedQuarterlyEmployee !== null) {

                    let selectedEmployee = this.props.employees.find(employee => employee.eid === this.state.selectedQuarterlyEmployee);
                    if (this.state.selectedQuarterlyEmployee !== 'All' || selectedEmployee !== undefined) {

                        let employeeName = selectedEmployee !== undefined ? selectedEmployee.fname + ' ' + selectedEmployee.lname : null;
                        let filteredDesignation = this.props.designations.find(designation => designation.id === selectedEmployee.extraInfo.designationId);
                        let fileteredRole = this.props.roles.find(role => role.id === selectedEmployee.extraInfo.roleId);
                        let employeeDesignation = filteredDesignation !== undefined ? filteredDesignation.designation : null;
                        let employeeRole = fileteredRole !== undefined ? fileteredRole.role : null;
                        let employeeEmail = selectedEmployee.emailOfficial;
                        let approvedBy = this.props.employee.fname + ' ' + this.props.employee.lname;


                        let employeeData = [
                            ['Employee Name:', employeeName, 'Designation:', employeeDesignation],
                            ['Employee No:', selectedEmployee.eid, 'Role:', employeeRole],
                            ['Email:', employeeEmail,],

                        ];

                        let employeeContent = {
                            startY: 80,
                            head: null,
                            body: employeeData,
                            tableWidth: 550,
                            theme: 'plain',
                            styles: {
                                cellPadding: 0,
                                minCellHeight: 18,
                                halign: 'left',
                                valign: 'middle',
                                fontSize: 12,
                            },
                            columnStyles: {
                                0: { cellWidth: 100 },
                                1: { cellWidth: 160 },
                                2: { cellWidth: 80 }
                            },

                        };

                        doc.autoTable(employeeContent);
                    } else {
                        let selectedProject = this.props.projects.find(projectItem => parseInt(projectItem.id) === parseInt(this.state.selectedQuarterlyProject));

                        let project = this.state.selectedQuarterlyProject === 0 ? 'All' : this.props.projects.find(projectItem => projectItem.id === this.state.selectedQuarterlyProject)
                        let employeeData = [
                            ['Employee:', 'All', 'Year:', moment(this.state.selectedYear).year(this.state.selectedYear).format("YYYY")],
                            ['Project:', this.state.selectedQuarterlyProject === 0 || this.state.selectedQuarterlyProject === 'All' ? 'All' : selectedProject !== undefined ? selectedProject.project : null, 'Quarter:', this.state.selectedQuarter === 1 ? "Q1" : this.state.selectedQuarter === 2 ? "Q2" : this.state.selectedQuarter === 3 ? "Q3" : "Q4"],
                            ['Status:', this.state.quarterlyStatus === 'New' ? 'Saved' : this.state.quarterlyStatus,],

                        ];

                        let employeeContent = {
                            startY: 80,
                            head: null,
                            body: employeeData,
                            tableWidth: 350,
                            theme: 'plain',
                            styles: {
                                cellPadding: 0,
                                minCellHeight: 18,
                                halign: 'left',
                                valign: 'middle',
                                fontSize: 12,
                            },
                            columnStyles: {
                                0: { cellWidth: 65 },
                                1: { cellWidth: 160 },
                                2: { cellWidth: 50 }
                            },

                        };

                        doc.autoTable(employeeContent);

                    }
                    if (this.state.selectedQuarterlyEmployee !== 'All') {
                        let quarterlyHeaders = [["Quarter", "Project", "Total Hours", "Status"]];
                        let quarterlyData = this.state.timesheetQuarterlyReport.map(reportItem => [moment(this.state.selectedYear).year(this.state.selectedYear).format("YYYY") + ' - ' + reportItem.quarter, reportItem.project, reportItem.totalHours, reportItem.status === "New" ? "Saved" : reportItem.status]);

                        let quarterlyContent = {
                            startY: 160,
                            head: quarterlyHeaders,
                            body: quarterlyData,
                        };

                        doc.setFontSize(20);
                        doc.text(title, marginLeft, 40)
                        doc.autoTable(quarterlyContent);

                    } else {
                        let quarterlyHeaders = [["Quarter", "Employee", "Project", "Total Hours", "Status"]];
                        let quarterlyData = this.state.timesheetQuarterlyReport.map(reportItem => [moment(this.state.selectedYear).year(this.state.selectedYear).format("YYYY") + ' - ' + reportItem.quarter, reportItem.employeeName, reportItem.project, reportItem.totalHours, reportItem.status === "New" ? "Saved" : reportItem.status]);

                        let quarterlyContent = {
                            startY: 160,
                            head: quarterlyHeaders,
                            body: quarterlyData,
                        };
                        doc.setFontSize(20);
                        doc.text(title, marginLeft, 40)
                        doc.autoTable(quarterlyContent);

                    }

                    let selectedQuarter = this.state.selectedQuarter === 1 ? "Q1" : this.state.selectedQuarter === 2 ? "Q2" : this.state.selectedQuarter === 3 ? "Q3" : "Q4";
                    let selectedYear = moment(this.state.selectedYear).year(this.state.selectedYear).format("YYYY");
                    let selectedStatus = this.state.quarterlyStatus === "New" ? 'Saved' : this.state.quarterlyStatus;

                    doc.save("Timesheet" + "-" + selectedYear + "-" + selectedQuarter + "-" + selectedStatus + ".pdf");

                }
            } else if (Type === "Weekly") {
                let filteredManager = this.props.managerEmployees.find(manager => manager.eid === this.state.selectedWeeklyEmployee);
                managerName = filteredManager !== undefined ? filteredManager.fname + ' ' + filteredManager.lname : null;

                let selectedEmployee = this.props.employees.find(employee => employee.eid === this.state.selectedWeeklyEmployee);

                if (this.state.selectedWeeklyEmployee !== 'All' || selectedEmployee !== undefined) {

                    let employeeName = selectedEmployee !== undefined ? selectedEmployee.fname + ' ' + selectedEmployee.lname : null;
                    let filteredDesignation = this.props.designations.find(designation => designation.id === selectedEmployee.extraInfo.designationId);
                    let fileteredRole = this.props.roles.find(role => role.id === selectedEmployee.extraInfo.roleId);
                    let employeeDesignation = filteredDesignation !== undefined ? filteredDesignation.designation : null;
                    let employeeRole = fileteredRole !== undefined ? fileteredRole.role : null;
                    let employeeEmail = selectedEmployee.emailOfficial;
                    let approvedBy = this.props.employee.fname + ' ' + this.props.employee.lname;


                    let employeeData = [
                        ['Employee Name:', employeeName, 'Designation:', employeeDesignation],
                        ['Employee No:', selectedEmployee.eid, 'Role:', employeeRole],
                        ['Email:', employeeEmail,],

                    ];

                    let employeeContent = {
                        startY: 80,
                        head: null,
                        body: employeeData,
                        tableWidth: 550,
                        theme: 'plain',
                        styles: {
                            cellPadding: 0,
                            minCellHeight: 18,
                            halign: 'left',
                            valign: 'middle',
                            fontSize: 12,
                        },
                        columnStyles: {
                            0: { cellWidth: 100 },
                            1: { cellWidth: 160 },
                            2: { cellWidth: 80 }
                        },

                    };

                    doc.autoTable(employeeContent);
                } else {
                    let selectedProject = this.props.projects.find(projectItem => parseInt(projectItem.id) === parseInt(this.state.selectedWeeklyProject));
                    let employeeData = [
                        ['Employee:', 'All', 'Start Date:', moment(this.state.weeklySelectedDate).startOf('week').format('YYYY-MM-DD')],
                        ['Project:', this.state.selectedWeeklyProject === 'All' || this.state.selectedWeeklyProject === 0 ? 'All' : selectedProject !== undefined ? selectedProject.project : null, 'End Date:', moment(this.state.weeklySelectedDate).endOf('week').format('YYYY-MM-DD')],
                        ['Status:', this.state.weeklyStatus === 'New' ? 'Saved' : this.state.weeklyStatus,],

                    ];

                    let employeeContent = {
                        startY: 80,
                        head: null,
                        body: employeeData,
                        tableWidth: 450,
                        theme: 'plain',
                        styles: {
                            cellPadding: 0,
                            minCellHeight: 18,
                            halign: 'left',
                            valign: 'middle',
                            fontSize: 12,
                        },
                        columnStyles: {
                            0: { cellWidth: 65 },
                            1: { cellWidth: 160 },
                            2: { cellWidth: 65 }
                        },

                    };

                    doc.autoTable(employeeContent);

                }
                if (this.state.selectedWeeklyEmployee !== 'All') {

                    let weeklyHeaders = [["Week Start Date", "Project", "Total Hours", "Status"]];
                    let weeklyData = this.state.timesheetWeeklyReport.map(reportItem => [moment(reportItem.startDate).format("Do MMM YYYY"), reportItem.project, reportItem.totalHours, reportItem.status === "New" ? "Saved" : reportItem.status]);

                    let weeklyContent = {
                        startY: 160,
                        head: weeklyHeaders,
                        body: weeklyData
                    };
                    doc.setFontSize(20);
                    doc.text(title, marginLeft, 40)
                    doc.autoTable(weeklyContent);

                } else {
                    let weeklyHeaders = [["Week Start Date", "Employee", "Project", "Total Hours", "Status"]];
                    let weeklyData = this.state.timesheetWeeklyReport.map(reportItem => [moment(reportItem.startDate).format("Do MMM YYYY"), reportItem.employeeName, reportItem.project, reportItem.totalHours, reportItem.status === "New" ? "Saved" : reportItem.status]);

                    let weeklyContent = {
                        startY: 160,
                        head: weeklyHeaders,
                        body: weeklyData
                    };

                    doc.setFontSize(20);
                    doc.text(title, marginLeft, 40)
                    doc.autoTable(weeklyContent);

                }

                let selectedWeek = moment(this.state.weeklySelectedDate).format('YYYY-MM-DD');
                doc.save("Timesheet" + "-" + selectedWeek + ".pdf");

            }

        } else {
            this.notify("tr", "warning", "Failed to export PDF!");
        }
    }


    handleCreateInvoice = async (Type) => {
        let showQuarterlyInvoiceErrorMessagesState = this.state.showQuarterlyInvoiceErrorMessages;
        let showMonthlyInvoiceErrorMessagesState = this.state.showMonthlyInvoiceErrorMessages;
        this.setState({
            ...this.state,
            isCreateInvoiceLoading: true,
            showQuarterlyInvoiceErrorMessages: Type === 'Quarterly' ? false : showQuarterlyInvoiceErrorMessagesState,
            showMonthlyInvoiceErrorMessages: Type === 'Monthly' ? false : showMonthlyInvoiceErrorMessagesState
        })
        if (Type === "Quarterly") {
            let quarter = this.state.selectedQuarter;
            let month = moment(this.state.selectedYear).format('M');
            let year = moment(this.state.selectedYear).format('YYYY');
            var pid = this.state.selectedQuarterlyProject === 0 ? 'All' : this.state.selectedQuarterlyProject.toString();
            let result = await this.props.getAdminQuarterlyInvoices(this.props.user.cid, pid, this.state.selectedQuarterlyEmployee, month, quarter, year);
            if (result.success) {
                if (result.data.length > 0) {
                    let displayInvoiceErrorMessage = result.data.find(invoice => invoice.errorMessage !== null);
                    let isInvoiceCreated = result.data.find(dataItem => dataItem.errorMessage == null)
                    if (isInvoiceCreated !== undefined) {
                        this.notify("tr", "success", "Created invoices successfully.");

                    } else {
                        this.notify("tr", "warning", "Failed to create invoice!");
                    }
                    this.setState({
                        ...this.state,
                        shouldDisplayQuarterInvoice: isInvoiceCreated !== undefined ? true : false,
                        showQuarterlyInvoiceErrorMessages: displayInvoiceErrorMessage !== undefined ? true : false
                    });

                } else {
                    this.notify("tr", "success", "No approved timesheet found.");
                }

            } else {
                this.notify("tr", "warning", "Failed to create invoice!");
            }

        } else if (Type === "Monthly") {
            let quarter = moment(this.state.monthlySelectedDate).quarter();
            let month = moment(this.state.monthlySelectedDate).format('M');
            let year = moment(this.state.monthlySelectedDate).format('YYYY');
            var pid = this.state.selectedMonthlyProject === 0 ? 'All' : this.state.selectedMonthlyProject.toString();
            let result = await this.props.getAdminMonthlyInvoices(this.props.user.cid, pid, this.state.selectedMonthlyEmployee, month, quarter, year);
            if (result.success) {
                if (result.data.length > 0) {
                    let displayMonthlyInvoiceErrorMessage = result.data.find(invoice => invoice.errorMessage !== null);
                    let isInvoiceCreated = result.data.find(dataItem => dataItem.errorMessage == null)
                    if (isInvoiceCreated !== undefined) {
                        this.notify("tr", "success", "Created invoices successfully.");

                    } else {
                        this.notify("tr", "warning", "Failed to create invoice!");
                    }
                    this.setState({
                        ...this.state,
                        shouldDisplayMonthlyInvoice: isInvoiceCreated !== undefined ? true : false,
                        showMonthlyInvoiceErrorMessages: displayMonthlyInvoiceErrorMessage !== undefined ? true : false
                    });
                    // this.notify("tr", "success", "Created invoices successfully.");
                } else {
                    this.notify("tr", "success", "No approved timesheet found.");
                }

            } else {
                this.notify("tr", "warning", "Failed to create invoice!");
            }
        }

    }

    handleExportExcel = async (e, Type) => {

        var managerName = null;
        var quarterData = [];
        var monthData = [];
        var weekData = [];
        if (Type === "Monthly") {
            let filteredManager = this.props.managerEmployees.find(manager => manager.eid === this.state.selectedMonthlyEmployee);
            managerName = filteredManager !== undefined ? filteredManager.fname + ' ' + filteredManager.lname : null


            this.state.timesheetMonthlyReport.map((dataItem) => {
                let Employee = {
                    // name: '',
                    month: '',
                    employee: '',
                    project: '',
                    totalHours: 0,
                    status: '',
                    comment: ''
                };
                let year = moment(this.state.monthlySelectedDate).format("YYYY").toString();
                let month = (dataItem.month + ' ' + year).toString();

                // Employee.name = managerName;
                Employee.employee = dataItem.employeeName;
                // Employee.month = moment(this.state.monthlySelectedDate).format('MMMM  YYYY'); // June 10th 2020, 5:21:34 pm
                Employee.month = month;
                // moment(this.state.monthlySelectedDate).format('YYYY');;
                Employee.project = dataItem.project;
                Employee.totalHours = dataItem.totalHours;
                Employee.status = dataItem.status === "New" ? "Saved" : dataItem.status;
                Employee.comment = dataItem.rejectionNote
                monthData.push(Employee);
            });


            let selectedMonth = moment(this.state.monthlySelectedDate).subtract(0, "month").startOf("month").format('MMMM');
            let selectedYear = moment(this.state.monthlySelectedDate).year(this.state.monthlySelectedDate).format("YYYY");
            let selectedStatus = this.state.monthlyStatus === "New" ? 'Saved' : this.state.monthlyStatus;

            let monthlyTimesheetCSV = "Timesheet" + "-" + selectedYear + "-" + selectedMonth + "-" + selectedStatus + ".csv";


            this.setState({
                ...this.state,
                MonthlyExcelData: monthData,
                monthlyTimesheetCSV: monthlyTimesheetCSV
            }, () => {
                return true;
            })



        } else if (Type === "Quarterly") {

            let filteredManager = this.props.managerEmployees.find(manager => manager.eid === this.state.selectedQuarterlyEmployee);
            managerName = filteredManager !== undefined ? filteredManager.fname + ' ' + filteredManager.lname : null

            this.state.timesheetQuarterlyReport.map((quarter) => {
                let Employee = {
                    // name: '',
                    quarter: '',
                    employee: '',
                    project: '',
                    totalHours: 0,
                    status: '',
                    comment: ''
                };

                // Employee.name = managerName;
                Employee.employee = quarter.employeeName;
                Employee.quarter = moment(this.state.selectedYear).format("YYYY") + ' - ' + quarter.quarter;
                Employee.project = quarter.project;
                Employee.totalHours = quarter.totalHours;
                Employee.status = quarter.status === "New" ? "Saved" : quarter.status;
                Employee.comment = quarter.rejectionNote
                quarterData.push(Employee);
            })

            let selectedQuarter = this.state.selectedQuarter === 1 ? "Q1" : this.state.selectedQuarter === 2 ? "Q2" : this.state.selectedQuarter === 3 ? "Q3" : "Q4";
            let selectedYear = moment(this.state.selectedYear).year(this.state.selectedYear).format("YYYY");
            let selectedStatus = this.state.quarterlyStatus === "New" ? 'Saved' : this.state.quarterlyStatus;

            let quarterlyTimesheetCSV = "Timesheet" + "-" + selectedYear + "-" + selectedQuarter + "-" + selectedStatus + ".csv";
            this.setState({
                ...this.state,
                QuarterlyExcelData: quarterData,
                quarterlyTimesheetCSV: quarterlyTimesheetCSV
            }, () => {
                return true;
            })

        } else if (Type === "Weekly") {
            let filteredManager = this.props.managerEmployees.find(manager => manager.eid === this.state.selectedWeeklyEmployee);
            managerName = filteredManager !== undefined ? filteredManager.fname + ' ' + filteredManager.lname : null

            this.state.timesheetWeeklyReport.map((dataItem) => {
                let Employee = {
                    // name: '',
                    employee: '',
                    week: moment(this.state.weeklySelectedDate).format("Do MMM YYYY"),
                    project: '',
                    totalHours: 0,
                    status: '',
                    comment: ''
                };
                // Employee.name = managerName;
                Employee.employee = dataItem.employeeName;
                Employee.project = dataItem.project;
                Employee.totalHours = dataItem.totalHours;
                Employee.status = dataItem.status === "New" ? "Saved" : dataItem.status;
                Employee.comment = dataItem.rejectionNote
                weekData.push(Employee);
            })

            let selectedWeek = moment(this.state.weeklySelectedDate).format('YYYY-MM-DD');
            let weeklyTimesheetCSV = "Timesheet" + "-" + selectedWeek + ".csv";

            this.setState({
                ...this.state,
                WeeklyExcelData: weekData,
                weeklyTimesheetCSV: weeklyTimesheetCSV
            }, () => {
                return true;
            })

        }
    }

    handleSendInvoice = async (id, type, index) => {

        var isLoading = this.state.isLoading.slice();
        var isMonthlyLoading = this.state.isMonthlyLoading.slice();
        if (type === 'Quarterly') {
            isLoading[index] = true;
        } else {
            isMonthlyLoading[index] = true;
        }

        this.setState({
            isLoading: isLoading,
            isMonthlyLoading: isMonthlyLoading
        }, async () => {
            let result = await this.props.sendProjectInvoice(id, this.props.user.eid, type);
            if (result.success) {
                this.notify("tr", "success", "Send invoice successfully.");
                if (type === "Quarterly") {
                    isLoading[index] = false;
                } else {
                    isMonthlyLoading[index] = false;
                }

                this.setState({
                    isLoading: isLoading,
                    isMonthlyLoading: isMonthlyLoading
                });

            } else {
                this.notify("tr", "warning", "Failed to send invoice!")

                if (type === "Quarterly") {
                    isLoading[index] = false;
                } else {
                    isMonthlyLoading[index] = false;
                }
                this.setState({
                    isLoading: isLoading,
                    isMonthlyLoading: isMonthlyLoading
                })
            }
        });


    }

    invoiceMessage = (invoice) => {
        return invoice.errorMessage.map((message, i) => {
            return (
                <li style={{ color: 'black', marginLeft: 15 }}>
                    <span className="invoice-error-description">{message} </span>
                </li>
            )

        })
    }

    qaurterlyErrorMessage = () => {
        return this.props.quarterlyInvoices.map((invoice, index) => {
            return invoice.errorMessage !== null ? (
                <div style={{ display: 'block', marginTop: 8 }}>
                    <span className="invoice-project-name">{invoice.employee + " - " + invoice.project + ' : '} </span>
                    {this.invoiceMessage(invoice)}
                </div>
            ) : (
                null
            )
        })
    }

    monthlyInvoiceMessage = (invoice) => {
        return invoice.errorMessage.map((message, i) => {
            return (
                <li style={{ color: 'black', marginLeft: 15 }}>
                    <span className="invoice-error-description">{message} </span>
                </li>
            )

        })
    }

    monthlyErrorMessage = () => {
        return this.props.monthlyInvoices.map((invoice, index) => {
            return invoice.errorMessage !== null ? (
                <div style={{ display: 'block', marginTop: 8 }}>
                    <span className="invoice-project-name">{invoice.employee + " - " + invoice.project + ' : '} </span>
                    {this.monthlyInvoiceMessage(invoice)}
                </div>
            ) : (
                null
            )
        })
    }


    render() {

        const myCustomNextText = () => {
            return
        };
        const { config } = this.context;
        // var activeEmployeesOptions = this.props.managerEmployees.length > 0 && this.props.managerEmployees.map((employee, index) => {
        //     return (<option key={index} value={employee.eid}>{employee.fname + ' ' + employee.lname}</option>)
        // });
        var activeEmployees = this.props.employees.filter(employee => employee.isActive === "Y");
        var activeEmployeesOptions = activeEmployees.length > 0 && activeEmployees.map((employee, index) => {
            return (<option key={index} value={employee.eid}>{employee.fname + ' ' + employee.lname} </option>)
        });

        var employeesOptions = this.props.employees.length > 0 && this.props.employees.map((employee, index) => {
            return (<option key={index} style={{}} value={employee.eid}>{employee.fname + ' ' + employee.lname}</option>)
        });

        var projectOptions = this.props.projects.length > 0 && this.props.projects.map((project, index) => {
            return (<option key={index} value={project.id}>{project.project}</option>)
        })
        return (
            <>
                <div className="content manager-timesheet-content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>

                    <CardTitle tag="h2" style={{ marginBottom: 5 }}>Timesheet Report </CardTitle>
                    <div className="">
                        <FormGroup>
                            <FormGroup check className="form-check-radio form-check-inline">
                                <Label className="form-check-label">
                                    <Input type="radio" name="exampleRadios1" id="Quarterly" onChange={this.handleReportType} checked={this.state.reportType === "Quarterly"} value="Quarterly" />
                                    Quarterly
                                    <span className="form-check-sign"></span>
                                </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio form-check-inline">
                                <Label className="form-check-label">
                                    <Input type="radio" name="exampleRadios1" id="Monthly" onChange={this.handleReportType} checked={this.state.reportType === "Monthly"} value="Monthly" />
                                    Monthly
                                    <span className="form-check-sign"></span>
                                </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio form-check-inline">
                                <Label className="form-check-label">
                                    <Input type="radio" name="exampleRadios1" id="Weekly" onChange={this.handleReportType} checked={this.state.reportType === "Weekly"} value="Weekly" />
                                    Weekly
                                    <span className="form-check-sign"></span>
                                </Label>
                            </FormGroup>

                        </FormGroup>
                    </div>
                    {this.state.reportType === "Monthly" ? (
                        <div>
                            <Row style={{ display: 'flex', alignItems: 'center' }} >
                                <Col style={{ maxWidth: 150, paddingLeft: 15, paddingRight: 5 }} >
                                    <Label >Select Month </Label>
                                    <FormGroup className={`has-label`}>
                                        <ReactDatetime
                                            // onViewModeChange="months"
                                            dateFormat="YYYY-MM"
                                            viewMode="months"
                                            closeOnSelect={true}
                                            timeFormat={false}
                                            inputProps={{ placeholder: "Start Date", className: "form-control", value: moment(this.state.monthlySelectedDate).format('MMMM') }}
                                            onChange={this.handleMonthlySelectedDate}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col style={{ maxWidth: 200, paddingLeft: 5, paddingRight: 5 }}>
                                    <Label for="employee">Select Employee</Label>
                                    <FormGroup className={`has-label  `}>
                                        <Input type="select" name="selectedMonthlyEmployee" value={this.state.selectedMonthlyEmployee} onChange={(e) => { this.handleSelectEmployee(e) }} id="employee">
                                            <option value="All">All</option>
                                            {employeesOptions}
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col style={{ maxWidth: 200, paddingLeft: 5, paddingRight: 5 }}>
                                    <Label for="project">Select Project</Label>
                                    <FormGroup className={`has-label  `}>
                                        <Input type="select" name="selectedMonthlyProject" value={this.state.selectedMonthlyProject} onChange={(e) => { this.handleSelectProject(e) }} id="project">
                                            <option value="All">All</option>
                                            {projectOptions}
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col style={{ maxWidth: 150, paddingLeft: 5, paddingRight: 5 }}>
                                    <Label for="monthlyStatus">Status</Label>
                                    <FormGroup className={`has-label  `}>
                                        <Input type="select" name="monthlyStatus" value={this.state.monthlyStatus} onChange={this.handleStatus} id="monthlyStatus">
                                            <option value="All">All</option>
                                            <option value="Approved">Approved</option>
                                            <option value="Rejected">Rejected</option>
                                            <option value="New">Saved</option>
                                            <option value="Submitted">Submitted</option>
                                            <option value="Resubmitted">Resubmitted</option>
                                        </Input>
                                        {this.state.clientIdState === "has-danger" ? (
                                            <label className="error">please select client!</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="auto" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <div style={{ marginTop: 14 }}>
                                        <div style={{}}>
                                            <Button onClick={() => this.state.isMonthlyReportLoading === true ? null : this.handleFind()} style={{ width: 70 }} size="sm" color="info">
                                                {this.state.isMonthlyReportLoading === true ?
                                                    <Spinner as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true" /> :
                                                    'Find'}
                                            </Button>
                                            {(this.state.monthlyStatus === "All" ||
                                                this.state.monthlyStatus === "Approved") ? (
                                                <Button onClick={() => this.state.isCreateInvoiceLoading === true ? null : this.handleCreateInvoice('Monthly')}
                                                    size="sm" style={{ width: 134 }}
                                                    color="secondary">
                                                    {this.state.isCreateInvoiceLoading === true ?
                                                        <Spinner as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true" /> :
                                                        'Create Invoice'}
                                                </Button>) : (null)}
                                            {/* <Button onClick={() => this.handleExportPDF('Monthly')} size="sm" color="secondary">Export To PDF</Button> */}
                                            <Button
                                                size="sm"
                                                style={{ marginLeft: 25, marginRight: 25 }}
                                                id="tooltip209892"
                                                onClick={() => this.handleExportPDF('Monthly')}
                                                className={classNames("btn-icon btn-link like", {

                                                })}
                                            >
                                                <FaRegFilePdf className="custom-icon" />
                                            </Button>
                                            <UncontrolledTooltip
                                                delay={0}
                                                target="tooltip209892"
                                                placement="top"
                                            >
                                                Export To PDF
                                            </UncontrolledTooltip>
                                            <UncontrolledTooltip
                                                delay={0}
                                                target="tooltip209893"
                                                placement="top"
                                            >
                                                Export To Excel
                                            </UncontrolledTooltip>
                                            <CSVLink data={this.state.MonthlyExcelData}
                                                headers={monthlyExcelHeaders}
                                                filename={this.state.monthlyTimesheetCSV}
                                                target="_blank"
                                                id="tooltip209893"
                                                onClick={(e) => this.handleExportExcel(e, 'Monthly')}
                                            > <FaRegFileExcel className="custom-excel-icon" /></CSVLink>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {
                                this.state.showMonthlyInvoiceErrorMessages === true ? (
                                    <Card className="monthly-invoice-note" >
                                        <h5 className="invoice-label">Failed to create invoice </h5>
                                        <ul>
                                            {this.monthlyErrorMessage()}
                                        </ul>

                                    </Card>
                                ) : (
                                    null
                                )
                            }
                            <MonthlyReport
                                timesheetMonthlyReport={this.state.timesheetMonthlyReport}
                                monthlySelectedDate={this.state.monthlySelectedDate}
                                isLoading={this.state.isDataMonthlyLoading}
                                // pageSize={this.props.pageSize}                                
                                pageSize={config.paginationSize}
                            />
                            {this.props.monthlyInvoices.length > 0 && this.state.shouldDisplayMonthlyInvoice === true && (this.state.monthlyStatus === "All" || this.state.monthlyStatus === "Approved") ? (
                                <div>
                                    <h4 style={{ paddingLeft: 8, marginBottom: 5 }}>Invoices</h4>
                                    <Table className="invoice-table" id="custom-monthly-invoice-table" borderless>
                                        <thead style={{}}>
                                            <tr>
                                                <th>EMPLOYEE</th>
                                                <th>PROJECT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.monthlyInvoices.map((invoice, index) => {
                                                    return invoice.errorMessage === null ? (
                                                        <tr key={index}><td style={{ minWidth: 150 }}>{invoice.employee} </td>
                                                            <td style={{ minWidth: 150 }}>{invoice.project}</td>
                                                            <td>
                                                                <a className="btn btn-sm btn-info invoice-btn" style={{}} href={invoice.imageUrl} target='_blank' >View</a>
                                                            </td>
                                                            <td>
                                                                {
                                                                    invoice.invoiceSent === "Y" ? (
                                                                        <Button className="btn btn-sm btn-secondary invoice-btn" disabled >
                                                                            Sent
                                                                        </Button>
                                                                    ) : (
                                                                        <Button className="btn btn-sm btn-secondary invoice-btn" onClick={() => this.state.isMonthlyLoading[index] === true ? null : this.handleSendInvoice(invoice.id, 'Monthly', index)}>
                                                                            {this.state.isMonthlyLoading[index] === true ?
                                                                                <Spinner as="span"
                                                                                    animation="grow"
                                                                                    size="sm"
                                                                                    role="status"
                                                                                    aria-hidden="true" /> :
                                                                                'Send'}
                                                                        </Button>
                                                                    )
                                                                }


                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        null
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            ) : (
                                null
                            )}
                        </div>
                    ) : this.state.reportType === "Quarterly" ? (
                        <div>
                            <Row style={{ display: 'flex', alignItems: 'center' }} >
                                <Col style={{ maxWidth: 100, paddingLeft: 15, paddingRight: 5 }}>
                                    <Label >Select Year </Label>
                                    <FormGroup className={`has-label`}>
                                        <ReactDatetime
                                            // onViewModeChange="months"
                                            dateFormat="YYYY"
                                            viewMode="years"
                                            closeOnSelect={true}
                                            timeFormat={false}
                                            inputProps={{ placeholder: "Select Quarter", className: "form-control", value: moment(this.state.selectedYear).format('YYYY') }}
                                            onChange={this.handleSelectedYear}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col style={{ maxWidth: 100, paddingLeft: 5, paddingRight: 5 }}>
                                    <Label for="quarterlyStatus">Select Quarter</Label>
                                    <FormGroup className={`has-label  `}>
                                        <Input type="select" name="selectedQuarter" value={this.state.selectedQuarter} onChange={this.handleSelectedQuarter} id="quarterlyStatus">
                                            <option value={1}>Q1</option>
                                            <option value={2}>Q2</option>
                                            <option value={3}>Q3</option>
                                            <option value={4}>Q4</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col style={{ maxWidth: 200, paddingLeft: 5, paddingRight: 5 }}>
                                    <Label for="quarterlyEmployee">Select Employee</Label>
                                    <FormGroup className={`has-label  `}>
                                        <Input type="select" name="selectedQuarterlyEmployee" value={this.state.selectedQuarterlyEmployee} onChange={(e) => { this.handleSelectEmployee(e) }} id="quarterlyEmployee">
                                            <option value="All">All</option>
                                            {employeesOptions}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col style={{ maxWidth: 200, paddingLeft: 5, paddingRight: 5 }}>
                                    <Label for="quarterlyProject">Select Project</Label>
                                    <FormGroup className={`has-label  `}>
                                        <Input type="select" name="selectedQuarterlyProject" value={this.state.selectedQuarterlyProject} onChange={(e) => { this.handleSelectProject(e) }} id="quarterlyProject">
                                            <option value="All">All</option>
                                            {projectOptions}
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col style={{ maxWidth: 150, paddingLeft: 5, paddingRight: 5 }}>
                                    <Label for="quarterlyStatus">Status</Label>
                                    <FormGroup className={`has-label  `}>
                                        <Input type="select" name="quarterlyStatus" value={this.state.quarterlyStatus} onChange={this.handleStatus} id="quarterlyStatus">
                                            <option value="All">All</option>
                                            <option value="Approved">Approved</option>
                                            <option value="Rejected">Rejected</option>
                                            <option value="New">Saved</option>
                                            <option value="Submitted">Submitted</option>
                                            <option value="Resubmitted">Resubmitted</option>
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col md="auto" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <div style={{ marginTop: 14 }}>
                                        <div style={{}}>
                                            <Button onClick={() => this.state.isQuarterlyReportLoading === true ? null : this.handleQuarterlyFind()} style={{ width: 70 }} size="sm" color="info">
                                                {this.state.isQuarterlyReportLoading === true ?
                                                    <Spinner as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true" /> :
                                                    'Find'}</Button>
                                            {(this.state.quarterlyStatus === "All" ||
                                                this.state.quarterlyStatus === "Approved") ? (
                                                <Button onClick={() => this.state.isCreateInvoiceLoading === true ? null : this.handleCreateInvoice('Quarterly')}
                                                    size="sm" style={{ width: 134 }}
                                                    color="secondary">
                                                    {this.state.isCreateInvoiceLoading === true ?
                                                        <Spinner as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true" /> :
                                                        'Create Invoice'}
                                                </Button>) : (null)}
                                            <Button
                                                size="sm"
                                                style={{ marginLeft: 25, marginRight: 25 }}
                                                id="tooltip209892"
                                                onClick={() => this.handleExportPDF('Quarterly')}
                                                className={classNames("btn-icon btn-link like", {
                                                })}
                                            >
                                                <FaRegFilePdf className="custom-icon" />
                                            </Button>
                                            <UncontrolledTooltip
                                                delay={0}
                                                target="tooltip209892"
                                                placement="top"
                                            >
                                                Export To PDF
                                            </UncontrolledTooltip>
                                            <UncontrolledTooltip
                                                delay={0}
                                                target="tooltip209893"
                                                placement="top"
                                            >
                                                Export To Excel
                                            </UncontrolledTooltip>
                                            <CSVLink data={this.state.QuarterlyExcelData}
                                                headers={quarterHeaders}
                                                filename={this.state.quarterlyTimesheetCSV}
                                                target="_blank"
                                                id="tooltip209893"
                                                onClick={(e) => this.handleExportExcel(e, 'Quarterly')}
                                            >
                                                <FaRegFileExcel className="custom-excel-icon" /></CSVLink>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {
                                this.state.showQuarterlyInvoiceErrorMessages === true ? (
                                    <Card className="invoice-note" >
                                        <h5 className="invoice-label">Failed to create invoice </h5>
                                        <ul>
                                            {this.qaurterlyErrorMessage()}
                                        </ul>

                                    </Card>
                                ) : (
                                    null
                                )
                            }

                            <QuarterlyReport
                                timesheetQuarterlyReport={this.state.timesheetQuarterlyReport}
                                selectedYear={this.state.selectedYear} isLoading={this.state.isDataQuarterlyLoading}
                                // pageSize={this.props.pageSize}                                
                                pageSize={config.paginationSize}
                            />
                            {this.props.quarterlyInvoices.length > 0 && this.state.shouldDisplayQuarterInvoice === true && (this.state.quarterlyStatus === "All" || this.state.quarterlyStatus === "Approved") ? (
                                <div>
                                    <h4 style={{ paddingLeft: 8, marginBottom: 5 }}>Invoices</h4>
                                    <Table className="invoice-table" id="custom-invoice-table" borderless>
                                        <thead style={{}}>
                                            <tr>
                                                <th>EMPLOYEE</th>
                                                <th>PROJECT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.quarterlyInvoices.map((invoice, index) => {
                                                    return invoice.errorMessage === null ? (
                                                        <tr key={index}><td style={{ minWidth: 150 }}>{invoice.employee} </td>
                                                            <td style={{ minWidth: 150 }}>{invoice.project}</td>
                                                            <td>
                                                                <a className="btn btn-sm btn-info invoice-btn" style={{}} href={invoice.imageUrl} target='_blank' >View</a>
                                                            </td>
                                                            <td>
                                                                {
                                                                    invoice.invoiceSent === "Y" ? (
                                                                        <Button className="btn btn-sm btn-secondary invoice-btn" disabled >
                                                                            Sent
                                                                        </Button>
                                                                    ) : (
                                                                        <Button className="btn btn-sm btn-secondary invoice-btn"
                                                                            onClick={() => this.state.isLoading[index] === true ? null : this.handleSendInvoice(invoice.id, 'Quarterly', index)}
                                                                        >
                                                                            {this.state.isLoading[index] === true ?
                                                                                <Spinner as="span"
                                                                                    animation="grow"
                                                                                    size="sm"
                                                                                    role="status"
                                                                                    aria-hidden="true" /> :
                                                                                'Send'}

                                                                        </Button>
                                                                    )
                                                                }


                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        null
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            ) : (
                                null
                            )}

                            {/* 
                                            <QuarterlyInvoices
                                                quarterlyInvoices={this.props.quarterlyInvoices}
                                            // selectedYear={this.state.selectedYear}
                                            /> */}
                        </div>
                    ) : (
                        <div>
                            <Row style={{ display: 'flex', alignItems: 'center' }} >
                                <Col style={{ maxWidth: 150, paddingLeft: 15, paddingRight: 5 }} >
                                    <Label >Select Week </Label>
                                    <FormGroup className={`has-label`}>
                                        <ReactDatetime
                                            // onViewModeChange="months"
                                            dateFormat={dateFormatList[2]}
                                            viewMode="days"
                                            closeOnSelect={true}
                                            timeFormat={false}
                                            inputProps={{ placeholder: "Select Month", className: "form-control", value: moment(this.state.weeklySelectedDate).format(dateFormatList[2]) }}
                                            onChange={this.handleWeeklySelectedDate}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col style={{ maxWidth: 200, paddingLeft: 5, paddingRight: 5 }}>
                                    <Label for="weeklyEmployee">Select Employee</Label>
                                    <FormGroup className={`has-label  `}>
                                        <Input type="select" name="selectedWeeklyEmployee" value={this.state.selectedWeeklyEmployee} onChange={(e) => { this.handleSelectEmployee(e) }} id="weeklyEmployee">
                                            <option value="All">All</option>
                                            {employeesOptions}
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col style={{ maxWidth: 200, paddingLeft: 5, paddingRight: 5 }}>
                                    <Label for="weeklyProject">Select Project</Label>
                                    <FormGroup className={`has-label  `}>
                                        <Input type="select" name="selectedWeeklyProject" value={this.state.selectedWeeklyProject} onChange={(e) => { this.handleSelectProject(e) }} id="weeklyProject">
                                            <option value="All">All</option>
                                            {projectOptions}
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col style={{ maxWidth: 150, paddingLeft: 5, paddingRight: 5 }}>
                                    <Label for="weeklyStatus">Status</Label>
                                    <FormGroup className={`has-label  `}>
                                        <Input type="select" name="weeklyStatus" value={this.state.weeklyStatus} onChange={this.handleStatus} id="weeklyStatus">
                                            <option value="All">All</option>
                                            <option value="Approved">Approved</option>
                                            <option value="Rejected">Rejected</option>
                                            <option value="New">Saved</option>
                                            <option value="Submitted">Submitted</option>
                                            <option value="Resubmitted">Resubmitted</option>
                                        </Input>
                                        {this.state.clientIdState === "has-danger" ? (
                                            <label className="error">please select client!</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <div style={{ marginTop: 14 }}>
                                        <div style={{}}>
                                            <Button onClick={() => this.state.isWeeklyReportLoading === true ? null : this.handleWeeklyFind()} size="sm" style={{ width: 70 }} color="info">
                                                {this.state.isWeeklyReportLoading === true ?
                                                    <Spinner as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true" /> :
                                                    'Find'}
                                            </Button>
                                            {/* <Button onClick={() => this.handleExportPDF('Weekly')} size="sm" color="secondary">Export To PDF</Button> */}
                                            <Button
                                                size="sm"
                                                style={{ marginLeft: 25, marginRight: 25 }}
                                                id="tooltip209892"
                                                onClick={() => this.handleExportPDF('Weekly')}
                                                className={classNames("btn-icon btn-link like", {

                                                })}
                                            >
                                                <FaRegFilePdf className="custom-icon" />
                                            </Button>
                                            <UncontrolledTooltip
                                                delay={0}
                                                target="tooltip209892"
                                                placement="top"
                                            >
                                                Export To PDF
                                            </UncontrolledTooltip>


                                            <UncontrolledTooltip
                                                delay={0}
                                                target="tooltip209895"
                                                placement="top"
                                            >
                                                Export To Excel
                                            </UncontrolledTooltip>
                                            <CSVLink data={this.state.WeeklyExcelData}
                                                headers={WeeklyExcelHeaders}
                                                filename={this.state.weeklyTimesheetCSV}
                                                target="_blank"
                                                id="tooltip209895"
                                                onClick={(e) => this.handleExportExcel(e, 'Weekly')}
                                            >
                                                <FaRegFileExcel className="custom-excel-icon" /></CSVLink>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <WeeklyReport
                                timesheetWeeklyReport={this.state.timesheetWeeklyReport}
                                isLoading={this.state.isDataWeeklyLoading}
                                // pageSize={this.props.pageSize}                                        
                                pageSize={config.paginationSize}
                            />
                        </div>

                    )}

                </div>
            </>
        );
    }
}


export const mapDispatchToProps = dispatch => ({
    getAdminTimesheetQuarterlyReport: bindActionCreators(getAdminTimesheetQuarterlyReport, dispatch),
    getAdminTimesheetMonthlyReport: bindActionCreators(getAdminTimesheetMonthlyReport, dispatch),
    getAdminTimesheetWeeklyReport: bindActionCreators(getAdminTimesheetWeeklyReport, dispatch),
    getAllProjects: bindActionCreators(getAllProjects, dispatch),
    getEmployeeExtraInfo: bindActionCreators(getEmployeeExtraInfo, dispatch),
    getAllEmployees: bindActionCreators(getAllEmployees, dispatch),
    getAllDesignations: bindActionCreators(getAllDesignations, dispatch),
    getAllRoles: bindActionCreators(getAllRoles, dispatch),
    getAllEmployeesByManager: bindActionCreators(getAllEmployeesByManager, dispatch),
    getAdminQuarterlyInvoices: bindActionCreators(getAdminQuarterlyInvoices, dispatch),
    getAdminMonthlyInvoices: bindActionCreators(getAdminMonthlyInvoices, dispatch),
    sendProjectInvoice: bindActionCreators(sendProjectInvoice, dispatch),
    getInitialReportData: bindActionCreators(getInitialReportData, dispatch),
});


export const mapStateToProps = ({ auth, projects, client, companies, timesheet, adminTimesheetReport, employees, designations, roles, managerEmployees, managerInvoices, adminInvoices, adminEmployees }) => {

    let employee = employees.find(e => e.eid === auth.user.eid);

    let pagination = auth.companyConfigs.find(config => config.param === 'pagination_size' && config.isActive === "Y");
    var pageSize = pagination !== undefined ? parseInt(pagination.paramValue) : 10

    return {
        token: auth.token,
        projects: projects,
        clients: client,
        companies: companies,
        // timesheetReport: timesheetReport,
        timesheetMonthlyReport: adminTimesheetReport.timesheetMonthlyReport,
        timesheetWeeklyReport: adminTimesheetReport.timesheetWeeklyReport,
        timesheetQuarterlyReport: adminTimesheetReport.timesheetQuarterlyReport,
        timesheet: timesheet,
        user: auth.user,
        employee: employee,
        designations: designations,
        roles: roles,
        managerEmployees: managerEmployees,
        managerInvoices: managerInvoices,
        employees: employees,
        pageSize: pageSize,
        quarterlyInvoices: adminInvoices.quarterlyInvoices,
        monthlyInvoices: adminInvoices.monthlyInvoices,
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(AdminTimesheetReport);
