import axios from 'axios';

export const getManagerTimesheetWeeklyReportAPI = (cid, eid, managerId, startDate, endDate, status, quarter, month, year) => {
  let weeklyReport = {
    cid: cid,
    eid: eid,
    managerId: managerId,
    endDate: endDate,
    startDate: startDate,
    status: status,
    month: month,
    quarter: quarter,
    year: year
  };
  return axios.post(`/api/v1/get-manager-weekly-report`, weeklyReport)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getManagerTimesheetMonthlyReportAPI = (cid, eid, managerId, startDate, endDate, status, quarter, month, year) => {
  let monthlyReport = {
    cid: cid,
    eid: eid,
    managerId: managerId,
    endDate: endDate,
    startDate: startDate,
    status: status,
    month: month,
    quarter: quarter,
    year: year
  };
  return axios.post(`/api/v1/get-manager-monthly-report`, monthlyReport)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getManagerTimesheetQuarterlyReportAPI = (cid, eid, managerId, startDate, endDate, status, quarter, month, year) => {
  let quarterlyReport = {
    cid: cid,
    eid: eid,
    managerId: managerId,
    endDate: endDate,
    startDate: startDate,
    status: status,
    month: month,
    quarter: quarter,
    year: year
  };
  return axios.post(`/api/v1/get-manager-quarterly-report`, quarterlyReport)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const addUpdateTimesheet = (timesheet) => {

  return axios.post(`/api/v1/add-update-timesheet`, timesheet, {

  })
    .then(function (response) {
      if (response.status == 200) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });

}

export const getAllTimesheetDailyForTids = (tids) => {
  return axios.post(`/api/v1/timesheet-daily-for-tids`, tids).
    then(function (response) {
      return response
    })
    .catch(function (error) {
      return false;
    });
}

export const getWeeklyTimesheetAPI = (cid, date, eid) => {
  return axios.get(`/api/v1/timesheet-for-week`, {
    params: {
      cid: cid,
      date: date,
      eid: eid
    }
  }).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const deleteTimesheetTaskAPI = (timesheetItem, timesheetWeeklyId, cid) => {
  return axios.get(`/api/v1/delete-timesheet-task`, {
    params: {
      cid: cid,
      eid: timesheetItem.eid,
      timesheetDailyId: timesheetItem.id,
      timesheetWeeklyId: timesheetWeeklyId
    }
  }).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const getAllApprovalPendingTimesheetsAPI = (data) => {
  return axios.post(`/api/v1/approval-pending-timesheets`, data).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const approveRejectWeeklyTimesheetAPI = (weeklyTimesheet) => {
  return axios.post(`/api/v1/approve-reject-weekly-timesheet`, weeklyTimesheet)
    .then(function (response) {
      if (response.data) {
        return { data: response.data }
      } else {
        return { data: false }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const getTimesheetWeeklyReportAPI = (cid, eid, startDate, endDate, status, quarter, month, year) => {
  let weeklyReport = {
    cid: cid,
    eid: eid,
    endDate: endDate,
    startDate: startDate,
    status: status,
    month: month,
    quarter: quarter,
    year: year
  };
  return axios.post(`/api/v1/get-employee-weekly-report`, weeklyReport)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getTimesheetMonthlyReportAPI = (cid, eid, startDate, endDate, status, quarter, month, year) => {
  let monthlyReport = {
    cid: cid,
    eid: eid,
    endDate: endDate,
    startDate: startDate,
    status: status,
    month: month,
    quarter: quarter,
    year: year
  };
  return axios.post(`/api/v1/get-employee-monthly-report`, monthlyReport)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}
export const getTimesheetQuarterlyReportAPI = (cid, eid, startDate, endDate, status, quarter, month, year) => {
  let quarterlyReport = {
    cid: cid,
    eid: eid,
    endDate: endDate,
    startDate: startDate,
    status: status,
    month: month,
    quarter: quarter,
    year: year
  };
  return axios.post(`/api/v1/get-employee-quarterly-report`, quarterlyReport)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getAdminTimesheetQuarterlyReportAPI = (cid, eid, startDate, endDate, status, quarter, month, year, pid) => {
  let quarterlyReport = {
    cid: cid,
    eid: eid,
    startDate: startDate,
    endDate: endDate,
    status: status,
    quarter: quarter,
    month: 0,
    year: year,
    pid: pid
  };

  return axios.post(`/api/v1/get-admin-quarterly-report`, quarterlyReport)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getAdminTimesheetMonthlyReportAPI = (cid, eid, startDate, endDate, status, quarter, month, year, pid) => {
  let monthlyReport = {
    cid: cid,
    eid: eid,
    startDate: startDate,
    endDate: endDate,
    status: status,
    quarter: quarter,
    month: month,
    year: year,
    pid: pid
  };

  return axios.post(`/api/v1/get-admin-monthly-report`, monthlyReport)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getAdminTimesheetWeeklyReportAPI = (cid, eid, startDate, endDate, status, quarter, month, year, pid) => {
  let weeklyReport = {
    cid: cid,
    eid: eid,
    startDate: startDate,
    endDate: endDate,
    status: status,
    quarter: quarter,
    month: month,
    year: year,
    pid: pid
  };

  return axios.post(`/api/v1/get-admin-weekly-report`, weeklyReport)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}



export const getAdminProjectMarginReportAPI = (cid) => {
  return axios.get(`/api/v1/admin-project-margin-report`, {
    params: {
      cid: cid,
    }
  }).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const getManagerProjectMarginReportAPI = (cid, managerId) => {
  return axios.get(`/api/v1/manager-project-margin-report`, {
    params: {
      cid: cid,
      managerId: managerId
    }
  }).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const getManagerContractorsReportAPI = (cid, eid, startDate, endDate) => {
  return axios.get(`/api/v1/contractors-by-manager`, {
    params: {
      cid: cid,
      eid: eid,
      endDate: endDate,
      startDate: startDate
    }
  }).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}


export const getAdminContractorsReportAPI = (cid, startDate, endDate) => {
  return axios.get(`/api/v1/contractors`, {
    params: {
      cid: cid,
      endDate: endDate,
      startDate: startDate
    }
  }).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}


export const getRejectedTimesheetReportAPI = (data) => {

  return axios.get(`/api/v1/rejected-timesheets`, {
    params: { ...data }
  })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}