import moment from 'moment';
import React from "react";
import { Bar, Line } from "react-chartjs-2";
import { Card, CardBody, CardFooter, CardHeader, UncontrolledTooltip, CardTitle, Col, FormGroup, Label, Row, Table, Button, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import DashboardCount from "../../components/common/DashboardCount";
import { connect } from "react-redux";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { bindActionCreators } from 'redux';
import { showDashboardDetails } from "../../redux/actions/dashboardActions";
import { getAnnouncments } from "../../redux/actions/announcementActions";
import AnnouncementModal from "./Announcement/AnnouncementModal.js";
import { addUpdateAnnouncement } from "../../services/dashboard-service.js";
import classNames from "classnames";
class AdminDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedYear:moment(new Date()).format('YYYY'),
            bigChartData: "data1",
            name: 'Employee Name',
            role: 'Employee Designation',
            empPendingTimesheet: 0,
            empApprovedTimesheet: 0,
            allPendingTimesheet: 0,
            rejectedTimesheets: 0,
            leaveBalance: 0,
            approvedEmpLeaves: 0,
            approvalPendingEmpLeaveCount:0,
            pendingLeaveApplications: 0,
            leaveApplications: 0,
            totalEmp: 0,
            activeEmp: 0,
            inactiveEmp: 0,
            birthdaysOfWeek: [],
            leaveCount: [1, 2, 3, 2, 2, 0, 0, 0, 3, 1, 1, 1],
            leaveType: ["CL", "SL", "LOP", "WFH", "PL", "OO", "Maternity", "COFF"],
            countByType: [5, 1, 0, 18, 12, 1, 0, 3],
            headCount: [15, 18, 20, 18, 15, 13, 13, 12, 24, 28, 21, 29],
            isOpen: false,
            formtype: 'Add',
            announcement: {},
            alert: null,
            allApprovedTimesheet: 0,
            allPendingForApprovalTimesheet: 0,
            years:[]
        };
    }
    setBgChartData = name => {
        this.setState({
            bigChartData: name
        });
    };
    async UNSAFE_componentWillMount() {

        this.setState({
            userImage: this.props.userImage == "" || null ? null : JSON.parse(this.props.userImage)
        })
        //  this.props.getAnnouncments(this.props.user.cid, moment().format('YYYY-MM-DD'));
    }

    handleSelectedYear = (e) => {
            this.setState({
                ...this.state,
                selectedYear: e.target.value
            });
        let eid = this.props.user.eid;
        let cid = this.props.user.cid;
        let year  = e.target.value;
        let newYear = parseInt(year);
        //await this.props.showDashboardDetails(cid, eid); 
        this.getDashboardData(cid, eid,newYear);
    }
    

    getDashboardData = async (cid, eid, year) => {
        await this.props.showDashboardDetails(cid, eid, year);
        //temp variables

        let fullname = 'Employee Name';
        let role = 'Employee designation ';
        let empPendingTimesheet = 0;
        let empApprovedTimesheet = 0;
        let allPendingTimesheet = 0;
        let rejectedTimesheets = 0;
        let leaveBalance = 0;
        let approvalPendingEmpLeaveCount = 0;
        let approvedEmpLeaves = 0;
        let pendingLeaveApplications = 0;
        let leaveApplications = 0;
        let totalEmp = 0;
        let activeEmp = 0;
        let inactiveEmp = 0;
        let leaveCount = [];
        let leaveType = [];
        let countByType = [];
        let headCount = [];
        let allApprovedTimesheet = 0;
        let allPendingForApprovalTimesheet = 0;

        if (this.props.dashboard.name && this.props.dashboard.name.length > 0) {

            fullname = this.props.dashboard.name[0].fname + ' ' + this.props.dashboard.name[0].lname;
            role = this.props.dashboard.name[0].role;
        }
        if (this.props.dashboard.approvalPendingTimesheetCount && this.props.dashboard.approvalPendingTimesheetCount.length > 0) {
            empPendingTimesheet = this.props.dashboard.approvalPendingTimesheetCount[0].count;
        }
        if (this.props.dashboard.approvalPendingTimesheetCount && this.props.dashboard.approvalPendingTimesheetCount.length > 0) {
            empPendingTimesheet = this.props.dashboard.approvalPendingTimesheetCount[0].count;
        }
        if (this.props.dashboard.approvedTimesheetCount && this.props.dashboard.approvedTimesheetCount.length > 0) {
            empApprovedTimesheet = this.props.dashboard.approvedTimesheetCount[0].count;
        }
        if (this.props.dashboard.pendingTimesheetCountsForManager && this.props.dashboard.pendingTimesheetCountsForManager.length > 0) {
            allPendingTimesheet = this.props.dashboard.pendingTimesheetCountsForManager[0].count;
        }

        if (this.props.dashboard.rejectedTimesheetCountForManager && this.props.dashboard.rejectedTimesheetCountForManager.length > 0) {
            rejectedTimesheets = this.props.dashboard.rejectedTimesheetCountForManager[0].count;
        }
        if (this.props.dashboard.empLeaveCount && this.props.dashboard.empLeaveCount.length > 0) {
            let obj = this.props.dashboard.empLeaveCount[0];
            const keys = Object.keys(obj);
            leaveBalance = keys[0];
        }
        if (this.props.dashboard.approvedEmpLeaveCOunt && this.props.dashboard.approvedEmpLeaveCOunt.length > 0) {
            approvedEmpLeaves = this.props.dashboard.approvedEmpLeaveCOunt[0].count;
        }
        if (this.props.dashboard.approvalPendingEmpLeaveCount && this.props.dashboard.approvalPendingEmpLeaveCount.length > 0) {
            approvalPendingEmpLeaveCount = this.props.dashboard.approvalPendingEmpLeaveCount[0].count;
        }
        if (this.props.dashboard.allPendingForApprovalLeaveCount && this.props.dashboard.allPendingForApprovalLeaveCount.length > 0) {
            pendingLeaveApplications = this.props.dashboard.allPendingForApprovalLeaveCount[0].count;
        }

        if (this.props.dashboard.allLeaveCOunt && this.props.dashboard.allLeaveCOunt.length > 0) {
            leaveApplications = this.props.dashboard.allLeaveCOunt[0].count;
        }
        if (this.props.dashboard.employeesCount && this.props.dashboard.employeesCount.length > 0) {
            totalEmp = this.props.dashboard.employeesCount[0].allEmployees;
            activeEmp = this.props.dashboard.employeesCount[0].activeEmployees;
            inactiveEmp = this.props.dashboard.employeesCount[0].inactiveEmployees;
        }
        if (this.props.dashboard.leaveCountList && this.props.dashboard.leaveCountList.length > 0) {
            this.props.dashboard.leaveCountList.map((prop, index) => {
                if (prop.month === index + 1) {
                    let count = prop.count;
                    leaveCount.push(count);
                }
            })
        }

        if (this.props.dashboard.leaveByleaveTypeCountList && this.props.dashboard.leaveByleaveTypeCountList.length > 0) {
            this.props.dashboard.leaveByleaveTypeCountList.map((prop, index) => {
                let type = prop.type;
                let count = prop.leaveCount;
                leaveType.push(type);
                countByType.push(count);
            })
        }

        if (this.props.dashboard.headCountOfLastYear && this.props.dashboard.headCountOfLastYear.length > 0) {
            this.props.dashboard.headCountOfLastYear.map((prop, index) => {
                if (prop.month === index + 1) {
                    let count = prop.count;
                    headCount.push(count);
                }
            })
        }

        if (this.props.dashboard.allApprovedTimesheetCount && this.props.dashboard.allApprovedTimesheetCount.length > 0) {
            allApprovedTimesheet = this.props.dashboard.allApprovedTimesheetCount[0].count;
        }
        if (this.props.dashboard.allapprovalPendingTimesheetCount && this.props.dashboard.allapprovalPendingTimesheetCount.length > 0) {
            allPendingForApprovalTimesheet = this.props.dashboard.allapprovalPendingTimesheetCount[0].count;
        }


        this.setState({
            name: fullname,
            role: role,
            empPendingTimesheet: empPendingTimesheet,
            empApprovedTimesheet: empApprovedTimesheet,
            allPendingTimesheet,
            rejectedTimesheets,
            leaveBalance,
            approvedEmpLeaves,
            approvalPendingEmpLeaveCount,
            pendingLeaveApplications,
            leaveApplications,
            leaveCount,
            leaveType,
            countByType,
            headCount,
            totalEmp,
            activeEmp,
            inactiveEmp,
            allApprovedTimesheet,
            allPendingForApprovalTimesheet
        })
    }

    notify = (place, color, message) => {
        // var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };

    showNotifications = (color, message) => {
        this.notify("tr", color, message);
    };

    successAlert = (status) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Success!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    btnSize="sm"
                >
                    Announcement has been {status} successfully.
                </ReactBSAlert>
            )
        });
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    addUpdateAnnouncement = async (announcement, formType) => {
        this.toggle();
        let status = '';
        let message = '';
        if (formType === "add") {
            status = "added";
            message = "Error occured while adding announcement";
        } else {
            status = "updated";
            message = "Error occured while updating announcement"
        }
        let result = await addUpdateAnnouncement(announcement);
        if (result.success) {
            this.successAlert(status);
            this.props.getAnnouncments(this.props.user.cid, moment().format('YYYY-MM-DD'))
        } else {
            this.showNotifications(2, message);
        }

    }

    toggle = () => {

        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    };

    handleAddClick = () => {

        this.setState({
            isOpen: true,
            formtype: 'add',
        })
    }

    handleEdit = (announcement) => {

        this.setState({
            isOpen: true,
            formtype: 'update',
            announcement: announcement
        })
    }

    async componentDidMount() {
        let eid = this.props.user.eid;
        let cid = this.props.user.cid;
        let year  = moment(new Date).format('YYYY');
        let newYear = parseInt(year);
        //await this.props.showDashboardDetails(cid, eid); 
        this.getDashboardData(cid, eid,newYear);
        await this.props.getAnnouncments(cid, moment().format('YYYY-MM-DD'))
        const currentYear = moment().year(); // Get current year
        const yearList = [];

        for (let i = currentYear + 0; i >= currentYear - 3; i--) { // Descending order
            yearList.push({ key: i.toString(), value: i.toString() }); // Key-Value pair
        }

        this.setState({
            years:[...yearList]
        })
    }
    render() {
        let dashboard = this.props.dashboard;
        let announcements = this.props.announcements;
        const { isOpen } = this.state;
        let role = this.props.user.roles[0];    //used this variable to hide or show components on role of the emp.
        let lastyear = moment().format('YYYY') - 1; //used for  showing last year in the charts
        let currentyear = moment().format('YYYY'); //used for  showing current year in the charts
        //used this var for chart to show leaves in each month in previous year.
        let chartExample1 = {
            data: canvas => {
                let ctx = canvas.getContext("2d");

                let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

                gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
                gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
                gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

                return {
                    labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
                        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
                    datasets: [
                        {
                            label: "leaves",
                            fill: true,
                            backgroundColor: gradientStroke,
                            hoverBackgroundColor: gradientStroke,
                            borderColor: "#d048b6",
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            data: this.state.leaveCount
                        }
                    ]
                };
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    backgroundColor: "#f5f5f5",
                    titleFontColor: "#333",
                    bodyFontColor: "#666",
                    bodySpacing: 4,
                    xPadding: 12,
                    mode: "nearest",
                    intersect: 0,
                    position: "nearest"
                },
                responsive: true,
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                color: "rgba(225,78,202,0.1)",
                                zeroLineColor: "transparent"
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 5,
                                padding: 20,
                                fontColor: "#9e9e9e"
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                color: "rgba(225,78,202,0.1)",
                                zeroLineColor: "transparent"
                            },
                            ticks: {
                                padding: 20,
                                fontColor: "#9e9e9e"
                            }
                        }
                    ]
                }
            }
        };

        //used this var for chart to show leaves taken by each leave type in previous year.
        const chartExample2 = {
            data: canvas => {
                let ctx = canvas.getContext("2d");
                var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

                gradientStroke.addColorStop(1, "rgba(72,72,176,0.4)");
                gradientStroke.addColorStop(0.8, "rgba(72,72,176,0.2)");
                gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors
                return {
                    labels: this.state.leaveType,
                    datasets: [
                        {
                            label: "Data",
                            fill: true,
                            backgroundColor: gradientStroke,
                            borderColor: "#ba54f5",
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#be55ed",
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#be55ed",
                            //pointHoverBorderColor:'rgba(35,46,55,1)',
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            pointRadius: 4,
                            data: this.state.countByType
                        }
                    ]
                };
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    backgroundColor: "#f5f5f5",
                    titleFontColor: "#333",
                    bodyFontColor: "#666",
                    bodySpacing: 4,
                    xPadding: 12,
                    mode: "nearest",
                    intersect: 0,
                    position: "nearest"
                },
                responsive: true,
                scales: {
                    yAxes: [
                        {
                            barPercentage: 1.6,
                            gridLines: {
                                drawBorder: false,
                                color: "rgba(186,84,245,0.1)",
                                zeroLineColor: "transparent"
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 20,
                                padding: 20,
                                fontColor: "#9e9e9e"
                            }
                        }
                    ],
                    xAxes: [
                        {
                            barPercentage: 1.6,
                            gridLines: {
                                drawBorder: false,
                                color: "rgba(186,84,245,0.1)",
                                zeroLineColor: "transparent"
                            },
                            ticks: {
                                padding: 20,
                                fontColor: "#9e9e9e"
                            }
                        }
                    ]
                }
            }
        };

        //last year employee head count

        const chartExample11 = {
            data: canvas => {
                let ctx = canvas.getContext("2d");
                var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

                gradientStroke.addColorStop(1, "rgba(72,72,176,0.4)");
                gradientStroke.addColorStop(0.8, "rgba(72,72,176,0.2)");
                gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors
                return {
                    labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
                    datasets: [
                        {
                            label: "Data",
                            fill: true,
                            backgroundColor: gradientStroke,
                            borderColor: "#ba54f5",
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: "#be55ed",
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: "#be55ed",
                            //pointHoverBorderColor:'rgba(35,46,55,1)',
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            pointRadius: 4,
                            data: this.state.headCount
                        }
                    ]
                };
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    backgroundColor: "#f5f5f5",
                    titleFontColor: "#333",
                    bodyFontColor: "#666",
                    bodySpacing: 4,
                    xPadding: 12,
                    mode: "nearest",
                    intersect: 0,
                    position: "nearest"
                },
                responsive: true,
                scales: {
                    yAxes: [
                        {
                            barPercentage: 1.6,
                            gridLines: {
                                drawBorder: false,
                                color: "rgba(186,84,245,0.1)",
                                zeroLineColor: "transparent"
                            },
                            ticks: {
                                suggestedMin: 10,
                                suggestedMax: 50,
                                padding: 20,
                                fontColor: "#9e9e9e"
                            }
                        }
                    ],
                    xAxes: [
                        {
                            barPercentage: 1.6,
                            gridLines: {
                                drawBorder: false,
                                color: "rgba(186,84,245,0.1)",
                                zeroLineColor: "transparent"
                            },
                            ticks: {
                                padding: 20,
                                fontColor: "#9e9e9e"
                            }
                        }
                    ]
                }
            }
        };

        let years = this.state.years.map((year, index) => {
            return (<option key={index} value={year.key}>{year.value}</option>)
        });

        return (
            <>
                <NotificationAlert ref="notificationAlert" />
                {this.state.alert}
                {this.state.isOpen === true ? <AnnouncementModal isOpen={this.state.isOpen} userData={this.props.user} addUpdateAnnouncement={this.addUpdateAnnouncement} toggle={this.toggle} showNotifications={this.showNotifications} announcement={this.state.announcement} formtype={this.state.formtype} /> : <React.Fragment />}
                <div className="content">
                    <Row>
                        <Col xs="12">
                            <Card style={{ padding: '10px 20px',marginTop: '5px'}}>
                                <Row>
                                    <Col xs="3" sm="1" style={{  fontSize: '24px',fontWeight: 'bold'}}>
                                        {this.state.userImage == null ?
                                            <div className="desktop-icon" >
                                                <i className="tim-icons icon-single-02" />
                                            </div>
                                            :
                                            <div className="photo">
                                                <img alt="..." className="dasboard-image" src={this.state.userImage} />
                                            </div>
                                        }
                                    </Col>
                                    <Col xs="9" sm="9" style={{  fontSize: '24px',fontWeight: 'bold'}}>
                                        <div>{this.state.name}</div>
                                        <div className="desktopname-span">{this.state.role}</div>
                                    </Col>
                                     <Col xs="2" sm="2" style={{alignItems: 'center', width: '100%'}}>
                                            <FormGroup className={`has-label`}>
                                            <Label >Select Year </Label>
                                            <Input type="select" name="year" 
                                            defaultValue={!this.state.selectedYear ? moment(new Date()).format('YYYY-MM-DD') : this.state.selectedYear} 
                                            onChange={this.handleSelectedYear} 
                                            value={this.state.selectedYear}
                                            id="year"
                                            >
                                                    {years}
                                            </Input>
                                            </FormGroup>
                                    </Col>
                                </Row>

                            </Card>
                        </Col>
                        <Col sm="8">
                            <Row>
                                {role === "Company Administrator" ? (<Col xl="4" md="6">
                                    <Card className="card-chart char-card">
                                        <CardHeader>
                                            <h2 className="card-category" style={{ fontSize: 18, color: "black" }}>Employees</h2>
                                            <CardTitle tag="h4">
                                                <i className="tim-icons icon-badge text-primary" /> {this.state.totalEmp}
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xs="6" className="count-padding-right">
                                                    <DashboardCount title="Active" count={this.state.activeEmp} />
                                                </Col>
                                                <Col xs="6" className="count-padding-left">
                                                    <DashboardCount title="Inactive" count={this.state.inactiveEmp} />
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>) : null}


                                {/* <Col xl="4" md="6">
                                    <Card className="card-chart char-card">
                                        <CardHeader>
                                            <h2 className="card-category" style={{ fontSize: 18, color: "black" }}>Candidates</h2>
                                            <CardTitle tag="h4">
                                                <i className="tim-icons icon-bullet-list-67 text-primary" /> 40 shortlisted
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xs="6" className="count-padding-right">
                                                    <DashboardCount title="Interviewed" count="20" />
                                                </Col>
                                                <Col xs="6" className="count-padding-left">
                                                    <DashboardCount title="Selected" count="4" />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col> */}
                                {/* </Row> */}

                                {/* <Row> */}

                                {/* Leave module */}
                                <Col xl={role === "Company Administrator" ? "4" : "6"} md={role === "Company Administrator" ? "6" : "8"}>
                                    <Card className="card-chart char-card">
                                        <CardHeader>
                                            <h2 className="card-category" style={{ fontSize: 18, color: "black" }}>Leaves Status</h2>
                                            <CardTitle tag="h4">
                                                <i className="tim-icons icon-map-big text-primary" /> {this.state.selectedYear}
                                            </CardTitle>
                                        </CardHeader>

                                        <CardBody>
                                            {role === "Company Administrator" ? (
                                                <Row>
                                                    <Col xs="6" className="count-padding-right">
                                                        <DashboardCount title="Applied Leaves" count={this.state.leaveApplications} />
                                                    </Col>
                                                    <Col xs="6" className="count-padding-left">
                                                        <DashboardCount title="Pending Approval" count={this.state.pendingLeaveApplications} />
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Row>
                                                    <Col xs="4" className="count-padding-right">
                                                        <DashboardCount title="Leave Balance" count={this.state.leaveBalance} />
                                                    </Col>
                                                    <Col xs="4" >
                                                        <DashboardCount title="Pending For Approval" count={this.state.approvalPendingEmpLeaveCount} />
                                                    </Col>
                                                    <Col xs="4" className="count-padding-left">
                                                        <DashboardCount title="Approved Leaves" count={this.state.approvedEmpLeaves} />
                                                    </Col>                                                    
                                                </Row>
                                            )}


                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col xl="4" md="6">
                                    <Card className="card-chart char-card " >
                                        <CardHeader>
                                            <h2 className="card-category" style={{ fontSize: 18, color: "black" }}>Timesheets</h2>
                                            <CardTitle tag="h4">
                                                <i className="tim-icons icon-paper text-primary" /> {this.state.selectedYear}
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            {role === "Manager" ? (
                                                <Row>
                                                    <Col xs="6" className="count-padding-right">
                                                        <DashboardCount title="Pending Approval" count={this.state.allPendingTimesheet} />
                                                    </Col>
                                                    <Col xs="6" className="count-padding-left">
                                                        <DashboardCount title="Rejected Approval" count={this.state.rejectedTimesheets} />
                                                    </Col>
                                                </Row>
                                            ) : role === "Company Administrator" ?
                                                (
                                                    <Row>
                                                        <Col xs="6" className="count-padding-right">
                                                            <DashboardCount title="Pending" count={this.state.allPendingForApprovalTimesheet} />
                                                        </Col>
                                                        <Col xs="6" className="count-padding-left">
                                                            <DashboardCount title="Approved" count={this.state.allApprovedTimesheet} />
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col xs="6" className="count-padding-right">
                                                            <DashboardCount title="Pending" count={this.state.empPendingTimesheet} />
                                                        </Col>
                                                        <Col xs="6" className="count-padding-left">
                                                            <DashboardCount title="Approved" count={this.state.empApprovedTimesheet} />
                                                        </Col>
                                                    </Row>
                                                )}

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>


                        <Col sm="4">
                            <Card className="card-chart">
                                <CardHeader>
                                    <h2 className="card-category" style={{ fontSize: 18, color: "black" }}>Events and Announcements </h2>
                                    <CardTitle tag="h4">
                                        <i className="tim-icons icon-paper text-success" /> Current Week
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="12">
                                            <Card className="birthday-card card-stats styled-card">

                                                <CardBody>
                                                    <Row>

                                                        <Col md="12">
                                                            <CardTitle tag="h5">
                                                                <i className="tim-icons icon-gift-2 text-info" />{" "}
                                                                <strong>Birthdays</strong>
                                                            </CardTitle>
                                                            <Row style={{ paddingLeft: 20 }}>
                                                                {this.props.dashboard.birthdaysOfWeek && this.props.dashboard.birthdaysOfWeek.length > 0 ? (<Col>
                                                                    {this.props.dashboard.birthdaysOfWeek.map((bday, index) => {
                                                                        const date = moment(bday.birthdate).format('MMM DD')
                                                                        return (
                                                                            <Row>
                                                                                <Col xs="7" className="">
                                                                                    <CardTitle tag="h6">{bday.firstname} {bday.lastname}</CardTitle>
                                                                                </Col>
                                                                                <Col xs="5">
                                                                                    <p className="card-category">{date}</p>
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    })}
                                                                </Col>) : (
                                                                    <Col xs="12"> There are no birthdays this week</Col>
                                                                )}


                                                                {/* <Col xs="7" className="">
                                                                    <CardTitle tag="h6">Elizabeth Olsen</CardTitle>
                                                                </Col>
                                                                <Col xs="5">
                                                                    <p className="card-category">April 23</p>
                                                                </Col> */}
                                                            </Row>
                                                        </Col>


                                                        {/* <Col md="12">
                                                           
                                                            <CardTitle tag="h5">
                                                                <i className="tim-icons icon-heart-2 text-danger" />{" "}
                                                                <strong>Anniversaries</strong>
                                                            </CardTitle>
                                                            <Row style={{ paddingLeft: 20 }}>
                                                                <Col xs="7" className="">
                                                                    <CardTitle tag="h6">Tony Stark</CardTitle>
                                                                </Col>
                                                                <Col xs="5">
                                                                    <p className="card-category">April 3</p>
                                                                </Col>

                                                                <Col xs="7" className="">
                                                                    <CardTitle tag="h6">Jon Snow</CardTitle>
                                                                </Col>
                                                                <Col xs="5">
                                                                    <p className="card-category">April 23</p>
                                                                </Col>
                                                            </Row>

                                                            

                                                        </Col> */}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>



                                        <Col md="12" style={{ marginTop: 10 }}>

                                            <Card className="birthday-card card-stats styled-card">
                                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-19px' }}>
                                                    <CardTitle tag="h5" style={{ marginTop: '10px' }} >
                                                        <i className="tim-icons icon-volume-98 text-info" />{" "}
                                                        <strong>Announcements</strong>
                                                    </CardTitle>
                                                    {
                                                        this.props.user.roles[0] === 'Company Administrator' ? (
                                                            <div style={{ display: 'flex' }}>
                                                                {/* <Button  size="sm" className="btn-announcement" onClick={this.handeOpenModal}>N</Button> */}
                                                                <Button
                                                                    id="tooltip2091"
                                                                    onClick={this.handleAddClick}
                                                                    color="warning"
                                                                    size="sm"
                                                                    className={classNames("btn-icon btn-link btn-neutral like btn-announcement")}
                                                                >
                                                                    <i className="tim-icons icon-simple-add" />
                                                                </Button>
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    target="tooltip2091"
                                                                    placement="left"
                                                                >
                                                                    Add new
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        ) : null
                                                    }
                                                </CardHeader>
                                                <Row>
                                                    <Col xs="12" className="">
                                                        {
                                                            announcements && announcements.length > 0 ? (
                                                                announcements.map((announcement, index) => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            <CardTitle tag="h7" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-7px' }}>
                                                                                <strong style={{ marginTop: '10px' }}>{announcement.title}</strong>
                                                                                {
                                                                                    this.props.user.roles[0] === 'Company Administrator' ? (
                                                                                        <div style={{ display: 'flex' }}>
                                                                                            {/* <Button  size="sm" className="btn-announcement" onClick={this.handeOpenModal}>N</Button> */}
                                                                                            <Button
                                                                                                id="tooltip209881"
                                                                                                onClick={() => {
                                                                                                    let obj = this.props.announcements.find(o => o.id === announcement.id);
                                                                                                    this.handleEdit(obj)
                                                                                                }}
                                                                                                color="warning"
                                                                                                size="sm"
                                                                                                className={classNames("btn-icon btn-link btn-neutral like ")}
                                                                                            >
                                                                                                <i className="tim-icons icon-pencil" />
                                                                                            </Button>
                                                                                            <UncontrolledTooltip
                                                                                                delay={0}
                                                                                                target="tooltip209881"
                                                                                                placement="left"
                                                                                            >
                                                                                                Edit
                                                                                            </UncontrolledTooltip>
                                                                                        </div>
                                                                                    ) : null
                                                                                }

                                                                            </CardTitle>
                                                                            <p className="card-category" style={{ paddingLeft: 10, marginTop: '7px' }}>
                                                                                {announcement.description}
                                                                            </p>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            ) : (
                                                                <p className="card-category" style={{ paddingLeft: 10 }}>
                                                                    No Announcements
                                                                </p>
                                                            )
                                                        }


                                                    </Col>
                                                </Row>

                                            </Card>
                                        </Col>
                                    </Row>
                                </CardBody>

                            </Card>
                        </Col>


                        {/* <Col md="12">
                            <Row>
                                <Col xl="4" md="4">
                                    <Card className="styled-card">
                                        <CardTitle tag="h4">
                                            <i className="tim-icons icon-single-copy-04 text-info" />{" "}
                                            Confirmation due
                                        </CardTitle>
                                        <Row style={{ paddingLeft: 5 }}>
                                            <Col xs="8" className="">
                                                <CardTitle tag="h5">Sanjay Bharadwaj</CardTitle>
                                            </Col>
                                            <Col xs="4">
                                                <p className="card-category">April 3</p>
                                            </Col>

                                            <Col xs="8" className="">
                                                <CardTitle tag="h5">Elizabeth Olsen</CardTitle>
                                            </Col>
                                            <Col xs="4">
                                                <p className="card-category">April 23</p>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xl="4" md="4">
                                    <Card className="styled-card">
                                        <CardTitle tag="h4">
                                            <i className="tim-icons icon-cloud-download-93 text-info" />{" "}
                                                Resigned Employees
                                        </CardTitle>
                                        <Row style={{ paddingLeft: 5 }}>
                                            <Col xs="8" className="">
                                                <CardTitle tag="h5">Sanjay Bharadwaj</CardTitle>
                                            </Col>
                                            <Col xs="4">
                                                <p className="card-category">April 3</p>
                                            </Col>

                                            <Col xs="8" className="">
                                                <CardTitle tag="h5">Elizabeth Olsen</CardTitle>
                                            </Col>
                                            <Col xs="4">
                                                <p className="card-category">April 23</p>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xl="4" md="4" >
                                    <Card className="styled-card">
                                        <CardTitle tag="h4">
                                            <i className="tim-icons icon-badge text-info" />{" "}
                                            New Employees
                                        </CardTitle>
                                        <Row style={{ paddingLeft: 20 }}>
                                            <Col xs="8" className="">
                                                <CardTitle tag="h5">This Month</CardTitle>
                                            </Col>
                                            <Col xs="4">
                                                <p className="card-category">0</p>
                                            </Col>

                                            <Col xs="8" className="">
                                                <CardTitle tag="h5">This year</CardTitle>
                                            </Col>
                                            <Col xs="4">
                                                <p className="card-category">2</p>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                        </Col> */}

                        <Col lg="6">
                            <Card className="card-chart">
                                <CardHeader>
                                    <CardTitle tag="h3">
                                        <i className="tim-icons icon-delivery-fast text-info" />{" "}
                                        Leaves By Month ({this.state.selectedYear})
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Bar
                                            data={chartExample1.data}
                                            options={chartExample1.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="6">
                            <Card className="card-chart">
                                <CardHeader>
                                    <CardTitle tag="h3">
                                        <i className="tim-icons icon-delivery-fast text-info" />{" "}
                                        Leaves taken by Leave Types ({this.state.selectedYear})
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart-area">
                                        <Line
                                            data={chartExample2.data}
                                            options={chartExample2.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {
                            role === "Company Administrator" ? (
                                <Col md="12">
                                    <Card className="card-chart">
                                        <CardHeader>
                                            <h2 className="card-category">Employee Head Count</h2>
                                            <CardTitle tag="h3">
                                                <i className="tim-icons icon-badge text-success" /> Year {this.state.selectedYear}
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="chart-area">
                                                <Line
                                                    data={chartExample11.data}
                                                    options={chartExample11.options}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ) : null
                        }




                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    dashboard: state.dashboard,
    userImage: state.auth.user.userImage,
    announcements: state.announcements

});

const mapDispatchToProps = (dispatch) => {
    return {
        getAnnouncments: bindActionCreators(getAnnouncments, dispatch),
        showDashboardDetails: bindActionCreators(showDashboardDetails, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
