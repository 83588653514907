import { getAllProjectTypesAPI } from '../../services/project-service';

export const FETCH_ALL_PROJECT_TYPES = 'FETCH_ALL_PROJECT_TYPES';

export const getAllProjectTypes = (cid) => {
    return async (dispatch) => {
        const response = await getAllProjectTypesAPI(cid);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_PROJECT_TYPES,
                projectTypes: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}