
import React from "react";
import moment from 'moment';
import ReactDatetime from "react-datetime";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form, FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import SortingTable from "../../../../components/SortingTable/SortingTable";
import { calculateEmployeePayroll } from "../../../../services/employees-service";
import { REQUIRD_MESSAGE, dateFormatList } from "../../../../variables/constants";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salaryEffectiveDateState: "",
      remarkState: "",
      ctcState: "",
      employee: {}

    };
  }
  UNSAFE_componentWillMount() {
    this.setState({
      employee: {
        ...this.state.employee,
        ...this.props.employee
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.employee != nextProps.employee) {
      this.setState({
        employee: {
          ...this.props.employee,
          ...nextProps.employee
        }
      });
    }

  }

  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    let employee = { ...this.state.employee };
    if (type === "number") {
      employee[event.target.name] = parseInt(event.target.value);
    } else {
      employee[event.target.name] = event.target.value;
    }
    if (stateName === "remark") {
      this.setState({ [stateName + "State"]: "has-success" });
    } else {
      if (event.target.value.length > 0) {
        this.setState({ [stateName + "State"]: "has-success" });
      } else {
        this.setState({ [stateName + "State"]: "has-danger" });
      }
    }
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ employee: employee });
        } else {
          this.setState({ employee: employee });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ employee: employee });
        } else {
          this.setState({ employee: employee });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
          this.setState({ employee: employee });
        } else {
          this.setState({ employee: employee });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value }, () => {
      if (stateName === "ctc") {
        this.handleHourCalculate();
      }
    });
  };
  handleSelectDate = (e, stateName) => {
    this.setState({
      employee: {
        ...this.state.employee,
        salaryEffectiveDate: moment(e).format('YYYY-MM-DD')
      }
    })
    if (e != null) {
      this.setState({ [stateName + "State"]: "has-success" });
    } else {
      this.setState({ [stateName + "State"]: "has-danger" });
    }
  }

  handleCalculate = async () => {
    const result = await calculateEmployeePayroll(this.props.basicInfo.cid, this.state.employee.ctc, this.props.basicInfo.eid, this.props.basicInfo.id);
    this.setState({
      ...this.state,
      employee: {
        ...result,
        salaryEffectiveDate: this.state.employee.salaryEffectiveDate,
        ctc: this.state.employee.ctc,
        id: this.props.basicInfo.id,
        eid: this.props.basicInfo.eid,
        cid: this.props.basicInfo.cid,
      }
    })
  }
  handleHourCalculate = () => {
    let hourRate = this.state.employee.ctc / 2080;
    this.setState({
      ...this.state,
      employee: {
        salaryEffectiveDate: this.state.employee.salaryEffectiveDate,
        usHourlyRate: hourRate.toFixed(2),
        ctc: this.state.employee.ctc,
        id: this.props.basicInfo.id,
        eid: this.props.basicInfo.eid,
        cid: this.props.basicInfo.cid,
      }
    })
  }
  isValidated = () => {
    let { salaryEffectiveDate, ctc } = this.state.employee;
    if (salaryEffectiveDate && salaryEffectiveDate != null && ctc && ctc != 0) {
      this.props.submitPayrollInfo(this.state.employee);
      return true;

    } else if (salaryEffectiveDate && salaryEffectiveDate != null && (!ctc || ctc === 0)) {
      this.setState({ ctcState: "has-danger" });
    } else {
      // if (!salaryEffectiveDate || salaryEffectiveDate == null) {
      //   this.setState({ salaryEffectiveDateState: "has-danger" });
      // }
      // if (!ctc || ctc == 0) {
      //   this.setState({ ctcState: "has-danger" });
      // }
      this.props.submitPayrollInfo(this.state.employee);
      return true
    }
  };

  handleCancel = () => {
    let passProps = {
      pathname: 'employeemaster',
    }
    this.props.history.push(passProps);
  }

  render() {
    let baseLocation = this.props.companyLocations.find(location => location.id === this.props.extraInfo.baseLocation);
    let role = this.props.roles.find(role => role.id === this.props.extraInfo.roleId);
    let designation = this.props.designations.find(designation => designation.id === this.props.extraInfo.designationId);
    let reportingManager = this.props.employees.find(employee => employee.eid === this.props.extraInfo.reportingManager);
    return (
      <><div >
        <Card style={{ marginBottom: '-20px' }}>
          <Row className="justify-content-center" >
            <CardBody style={{ marginLeft: '140px', marginRight: '140px', borderRadius: '5px' }}>
              <Row style={{ marginTop: 15, border: 'solid', borderWidth: '2px' }}>
                <Col sm="4" style={{ paddingTop: '7px' }}>
                  <div>
                    <div style={{ display: 'flex' }}>
                      <h6>Date of Joining : </h6><h6>{this.props.basicInfo && this.props.basicInfo.doj ? this.props.basicInfo.doj : this.props.employeeData && this.props.employeeData.doj ? moment(this.props.employeeData.doj).format(dateFormatList[2]) : 'N/A'}</h6>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <h6>Experience : </h6><h6>{this.props.basicInfo && this.props.basicInfo.experienceMonth && this.props.basicInfo.experienceYear ? this.props.basicInfo.experienceYear + "Year" + "  " +
                        this.props.basicInfo.experienceMonth + "Month" : this.props.employeeData && this.props.employeeData.experienceMonth && this.props.employeeData.experienceYear ? this.props.employeeData.experienceYear + "Year" + "  " +
                          this.props.employeeData.experienceMonth + "Month" : 'N/A'}</h6>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <h6>Last Revised On :</h6><h6>{'N/A'}</h6>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <h6>Last Revised Status :</h6> <h6>{'N/A'}</h6>
                    </div>
                  </div>
                </Col>
                <Col sm="2" lg="4" style={{ paddingTop: '7px' }}>
                </Col>
                <Col sm="4" style={{ paddingTop: '7px' }}>
                  <div>
                    <div style={{ display: 'flex' }}>
                      <h6>Designation :</h6><h6>{designation && designation.designation ? designation.designation : 'N/A'}</h6>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <h6>Role :</h6><h6>{role && role.role ? role.role : 'N/A'}</h6>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <h6>Reporting Manager :</h6><h6>{reportingManager && reportingManager.fname ? reportingManager.fname + " " + reportingManager.lname : 'N/A'}</h6>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <h6>Last Revised By :</h6><h6>{'N/A'}</h6>
                    </div>
                  </div>
                </Col>
              </Row>
              {baseLocation && baseLocation.branchLocation === "India" ?
                <div>
                  <SortingTable
                    thead={[
                      { text: "Component" },
                      { className: "text-left", text: "Amount" }
                    ]}
                    tbody={[
                      {
                        data: [
                          { text: "Basic" },
                          { className: "text-left", text: this.state.employee.basicAmount }
                        ]
                      },
                      {
                        data: [
                          { text: "HRA" },
                          { className: "text-left", text: this.state.employee.hraAmount }
                        ]
                      },
                      {
                        data: [
                          { text: "TA" },
                          { className: "text-left", text: this.state.employee.taAmount }
                        ]
                      },
                      {
                        data: [
                          { text: "DA" },
                          { className: "text-left", text: this.state.employee.daAmount }
                        ]
                      },
                      {
                        data: [
                          { text: "Conveyance" },
                          { className: "text-left", text: this.state.employee.conveyanceAmount }
                        ]
                      },
                      {
                        data: [
                          { text: "Medical Allowance" },
                          { className: "text-left", text: this.state.employee.medicalAllowanceAmount }
                        ]
                      },
                      {
                        data: [
                          { text: "Special Allowance" },
                          { className: "text-left", text: this.state.employee.specialAllowanceAmount }
                        ]
                      },
                      {
                        data: [
                          { text: "Education Allowance" },
                          { className: "text-left", text: this.state.employee.educationAllowanceAmount }
                        ]
                      },
                      {
                        data: [
                          { text: "EPF" },
                          { className: "text-left", text: this.state.employee.epfAmount }
                        ]
                      },
                      {
                        data: [
                          { text: "Insurance" },
                          { className: "text-left", text: this.state.employee.insuranceAmount }
                        ]
                      },
                      {
                        data: [
                          { text: "PF" },
                          { className: "text-left", text: this.state.employee.pfAmount }
                        ]
                      },
                      // {
                      //   data: [
                      //     { text: "Monthly Salary" },
                      //     { className: "text-left", text: "50,000" }
                      //   ]
                      // },
                      {
                        data: [
                          { text: "" },
                          { className: "text-left", text: "" }
                        ]
                      }
                    ]}
                  />
                  <Form className="custom-form custom-employee-form" id="LoginValidation">
                    <Row>
                      <Col md="12" lg="5">
                        <Label>Effective Date *</Label>
                        <FormGroup className={`has-label ${this.state.salaryEffectiveDateState}`}>
                          <ReactDatetime
                            timeFormat={false}
                            name="salaryEffectiveDate"
                            inputProps={{ placeholder: "Effective Date", className: "form-control", value: this.state.employee.salaryEffectiveDate }}
                            value={this.state.employee.salaryEffectiveDate}
                            onChange={e => this.handleSelectDate(e, "salaryEffectiveDate", "length", 1)}
                            closeOnSelect={true}
                          />
                          {this.state.salaryEffectiveDateState === "has-danger" ? (
                            <label className="error hide-has-danger">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6" lg="5" style={{}}>
                        <Label>CTC</Label>
                        <FormGroup className={`has-label ${this.state.ctcState}`}>
                          <Input
                            name="ctc"
                            placeholder="CTC..."
                            type="number"
                            value={this.state.employee.ctc}
                            maxLength="20"
                            onChange={e => this.change(e, "ctc", "number", 1)}
                            onFocus={e => this.setState({ ctcFocus: true })}
                            onBlur={e => this.setState({ ctcFocus: false })}
                          />
                          {this.state.ctcState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="2" style={{}}>
                        <Label></Label>
                        <div style={{ display: 'flex' }}>
                          <Button size="sm" color="info" className="btn-wd btn-info" onClick={this.handleCalculate}>Process</Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>

                </div> :
                <div style={{ marginTop: 10 }}>
                  <Form className="custom-form custom-employee-form" id="LoginValidation">
                    <Row>

                      <Col md="6" lg="5" style={{}}>
                        <Label>CTC</Label>
                        <FormGroup className={`has-label ${this.state.ctcState}`}>
                          <Input
                            name="ctc"
                            placeholder="CTC..."
                            type="number"
                            value={this.state.employee.ctc}
                            maxLength="20"
                            onChange={e => this.change(e, "ctc", "number", 1)}
                            onFocus={e => this.setState({ ctcFocus: true })}
                            onBlur={e => this.setState({ ctcFocus: false })}
                          />
                          {this.state.ctcState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6" lg="7" style={{}}>
                        <Label>Hourly Rate </Label>
                        <FormGroup className={`has-label ${this.state.usHourlyRateState}`}>
                          <Input
                            name="usHourlyRate"
                            placeholder="Hourly Rate..."
                            type="number"
                            value={this.state.employee.usHourlyRate}
                            maxLength="20"
                            disabled={true}
                            // onChange={e => this.change(e, "usHourlyRate", "number", 1)}
                            onFocus={e => this.setState({ usHourlyRateFocus: true })}
                            onBlur={e => this.setState({ usHourlyRateFocus: false })}
                          />
                          {this.state.usHourlyRateState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      {/* <Col md="12" lg="3" style={{}}>
                        <Label></Label>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button size="sm" color="info" className="btn-wd btn-info" onClick={this.handleHourCalculate}>Process</Button>
                        </div>
                      </Col> */}
                    </Row>
                  </Form>
                </div>}
            </CardBody>
          </Row>
        </Card>
        <Form className="custom-form custom-employee-form" id="LoginValidation">
          <Row style={{ marginTop: '5px', marginLeft: '125px', marginRight: '125px' }}>
            {baseLocation && baseLocation.branchLocation !== "India" ?
              <Col md="12" lg="5" >
                <Label>Effective Date</Label>
                <FormGroup className={`has-label ${this.state.salaryEffectiveDateState}`}>
                  <ReactDatetime
                    timeFormat={false}
                    name="salaryEffectiveDate"
                    inputProps={{
                      placeholder: "Effective Date", className: "form-control",
                      value: this.state.employee.salaryEffectiveDate && this.state.employee.salaryEffectiveDate !== "" ? moment(this.state.employee.salaryEffectiveDate).format(dateFormatList[2]) : this.state.employee.salaryEffectiveDate
                    }}
                    onChange={e => this.handleSelectDate(e, "salaryEffectiveDate", "length", 1)}
                    closeOnSelect={true}
                  />
                  {this.state.salaryEffectiveDateState === "has-danger" ? (
                    <label className="error hide-has-danger">{REQUIRD_MESSAGE}</label>
                  ) : null}
                </FormGroup>
              </Col>
              : <React.Fragment />
            }
            <Col md="12" lg="7" style={{}}>
              <Label>Remark</Label>
              <FormGroup className={`has-label ${this.state.remarkState}`}>
                <Input
                  name="remark"
                  placeholder="Remark..."
                  type="textarea"
                  value={this.state.employee.remark}
                  maxLength="150"
                  onChange={e => this.change(e, "remark", "length", 1)}
                  onFocus={e => this.setState({ remarkFocus: true })}
                  onBlur={e => this.setState({ remarkFocus: false })}
                />
                {this.state.remarkState === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
        <Button onClick={() => { this.handleCancel() }} className="btn-cancel" size="sm" color="info">Cancel</Button>
      </>
    );
  }
}

export default Wizard;