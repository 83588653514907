import moment from 'moment';
import React, { Component } from "react";
import ReactDatetime from "react-datetime";
import Switch from "react-switch";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { REQUIRD_MESSAGE } from "../../../variables/constants";
import ImageUpload from "../../../components/CustomUpload/ImageUpload";
import {getAllCurrencies} from "../../../redux/actions/currenciesAction";

class CompanyModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'Add',
            company: {
                id: 0,
                name: '',
                isActive: 'Y',
                dateRegistered: '',
                accountType: "",
                currencyId: -1,
                domainurl: "",
                hostedurl: "",
                logoPath: "",
                slug:""
            },
            companyNameState:'',
            dateRegisteredState:'',
            domainurlState:'',
            accountTypeState:'',
            slugState:'',
            hostedurl:'',
            logoPathState:'',
            slugState:'',
            currencyIdState:'',
            file: null,
            imagePreviewUrl: ""
        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }



    UNSAFE_componentWillMount() {
        this.props.getAllCurrencies();
        if (this.props.formType === "Update") {
            this.setState({
                formType: this.props.formType,
                company: { ...this.props.company }
            });
        } else {
            this.setState({
                formType: this.props.formType,
            });
        }
    }

    verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    handleSubmit = async () => {
        let { name, id, dateRegistered,accountType, domainurl,hostedurl,logoPath,slug,currencyId } = this.state.company;
        if (name === "") {
            this.setState({ companyNameState: "has-danger" });
        }
        if(dateRegistered === ""){
            this.setState({ dateRegisteredState: "has-danger" });
        }
        if(accountType  === ""){
            this.setState({ accountTypeState: "has-danger" });
        }
        if(domainurl === ""){
            this.setState({ domainurlState: "has-danger" });
        }
        if(hostedurl === ""){
            this.setState({ hostedurlState: "has-danger" });
        }
        if(logoPath === ""){
            this.setState({ logoPathState: "has-danger" });
        }
        if(slug === ""){
            this.setState({ slugState: "has-danger" });
        }
        if(currencyId === -1){
            this.setState({ currencyIdState: "has-danger" });
        }
        if(name != "" && dateRegistered != ""){
            let formType = this.props.formType;
            this.props.onSubmit(this.state.company, formType);
        }
    }

    handleSelectStatus = (e) => {
        if(e.target.value === '') {
            this.setState({ [e.target.name + "State"]: "has-danger" });
        }else{
          this.setState({ [e.target.name + "State"]: "has-success" });
        }

        let company = this.state.company;
        if(e.target.name === 'currencyId'){
            company[e.target.name] = parseInt(e.target.value);
        }else{
            company[e.target.name] = e.target.value;
        }
        this.setState({
            company: { ...company }
        })
    }
    handleIsActive = (e) => {
        if (e === true) {
            this.setState({
                company: {
                    ...this.state.company,
                    isActive: 'Y'

                }
            });
        }
        else {
            this.setState({
                company: {
                    ...this.state.company,
                    isActive: 'N'

                }
            });
        }
    }

    onChangeTextHandler = (e) => {
        let company = this.state.company;

        company[e.target.name] = e.target.value;
        if(e.target.value === '') {
            this.setState({ [e.target.name + "State"]: "has-danger" });
        }else{
          this.setState({ [e.target.name + "State"]: "has-success" });
        }
        this.setState({
            company: company
        });

    }
    handleStartDate = (date) => {

        this.setState({
            dateRegisteredState: moment(date).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
            company: {
                ...this.state.company,
                dateRegistered: moment(date).format('YYYY-MM-DD')
            }

        });
    }
    handleImageChange=(e)=>{
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result,
            company: {
                ...this.state.company,
            logoPath:file.name
            }
          });
        };
        reader.readAsDataURL(file);
      }

      handleClick=(refs)=>{
        refs.fileInput.click();
      }
      handleRemove=(refs)=>{
        this.setState({
          file: null,
          imagePreviewUrl:""
        });
        refs.fileInput.value = null;
      }

    render() {
        let { companyNameState, dateRegisteredState ,hostedurlState, domainurlState,slugState, accountTypeState,currencyIdState,logoPathState} = this.state;
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="custom-modal " style={{
        width: "100%",
        maxWidth: 700
      }} >
                    <ModalHeader toggle={this.props.toggle}><h4>
                        {this.props.formType === "Update" ? "Update Company" : "Add New Company"}
                    </h4></ModalHeader>
                    <ModalBody>
                    <div className="content raise-ticket">
                    <Col className="mr-auto ml-auto" md="12">
                        <Form className="custom-form">
                        <Row className="justify-content-center">                         
                                <Col sm="5">
                                    <FormGroup className={`has-label ${companyNameState}`}>
                                    <Label>Company Name</Label>
                                        <Input
                                            name="name"
                                            type="text"
                                            placeholder="Company name"
                                            value={this.state.company.name}
                                            onChange={this.onChangeTextHandler}
                                        />
                                        {companyNameState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <FormGroup className={`has-label ${dateRegisteredState} `}>
                                    <Label >Date of register</Label>
                                        <ReactDatetime
                                            timeFormat={false}
                                            inputProps={{ placeholder: "Register Date", className: "form-control", value: this.state.company.dateRegistered }}
                                            onChange={this.handleStartDate}
                                            closeOnSelect={true}
                                        />
                                        {this.state.dateRegisteredState === "has-danger" ? (
                                            <label className="error hide-has-danger">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                <FormGroup className={`has-label ${accountTypeState} `}>
                                        <Label>Account Type</Label>
                                        <Input type="select" name="accountType" defaultValue={this.state.company.accountType} onChange={this.handleSelectStatus} id="exampleSelect">
                                            <option value="" disabled>select status</option>
                                            <option value="New">New</option>
                                            <option value="Paid">Paid</option>
                                            <option value="Free">Free</option>
                                            <option value="Discounted">Discounted</option>
                                            <option value="Demo">Demo</option>
                                        </Input>
                                        {accountTypeState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                <FormGroup className={`has-label ${currencyIdState} `}>
                                        <Label>Select Currency</Label>
                                        <Input type="select" name="currencyId" defaultValue={this.state.company.currencyId} onChange={this.handleSelectStatus} id="exampleSelect">
                                            <option value={-1} disabled>Select Currency</option>
                                          {this.props.currencies.map((currency)=>{
                                            return (<option value={currency.id}>{currency.currency}</option>)
                                          })}
                                        </Input>
                                        {currencyIdState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <FormGroup className={`has-label ${domainurlState}`}>
                                    <Label>Domain Url</Label>
                                        <Input
                                            name="domainurl"
                                            type="text"
                                            placeholder="Domain Url"
                                            value={this.state.company.domainurl}
                                            onChange={this.onChangeTextHandler}
                                        />
                                        {domainurlState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <FormGroup className={`has-label ${hostedurlState}`}>
                                    <Label>Hosted Url</Label>
                                        <Input
                                            name="hostedurl"
                                            type="text"
                                            placeholder="Hosted Url"
                                            value={this.state.company.hostedurl}
                                            onChange={this.onChangeTextHandler}
                                        />
                                        {hostedurlState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <FormGroup className={`has-label ${slugState}`}>
                                    <Label>Company Slogan</Label>
                                        <Input
                                            name="slug"
                                            type="text"
                                            placeholder="Company Slogan"
                                            value={this.state.company.slug}
                                            onChange={this.onChangeTextHandler}
                                        />
                                        {slugState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="5">                                              
                                <FormGroup >
                                <div style={{display:'flex', justifyContent:'space-between',alignItems: 'center', marginTop:'10px'}}>
                                <div>
                                <Label >isActive</Label>
                                </div>
                                <div style={{marginRight: '100px'}}>
                                        <Switch
                                            onChange={(e) => { this.handleIsActive(e) }}
                                            name="isActive"
                                            id="isActive"
                                            checked={this.state.company.isActive === 'Y' ? true : false}
                                            handleDiameter={30}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={20}
                                            width={48} />
                                            </div>
                                            </div>
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <FormGroup className={`has-label ${logoPathState} `}>
                                    <Label >Select Logo</Label>
                                        <ImageUpload
                                            addBtnColor="default"
                                            changeBtnColor="default"
                                            handleImageChange={this.handleImageChange}
                                            handleClick={this.handleClick}
                                            handleRemove={this.handleRemove}
                                            imagePreviewUrl={this.state.imagePreviewUrl}
                                            file={this.state.file}
                                        />
                                         {logoPathState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                               
                            </Row>
                        </Form>      
                        </Col>  
                        </div>     
                        <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                        <Button size="sm" color="info" onClick={this.handleSubmit}>{this.props.formType}</Button>{' '}
                        {this.props.formType === 'Update' ? <Button
                            size="sm"
                            color="danger"
                            onClick={() =>{this.props.deleteCompany(this.state.company.id)} }
                        >
                            Delete
                        </Button>: <React.Fragment />}
                        <Button size="sm" color="secondary" onClick={this.props.close}>Cancel</Button>
                        </div>           
                    </ModalBody>
                </Modal>
            </div>
        );
    }

}

export const mapDispatchToProps = dispatch => ({
    getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch)
});


export const mapStateToProps = ({ currencies }) => ({
    currencies: currencies
});
export default connect(mapStateToProps, mapDispatchToProps)(CompanyModal);