import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button
} from "reactstrap";
import CompanyLocationModal from "./CompanyLocationModal";
import NotificationAlert from "react-notification-alert";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAllCountries } from "../../../redux/actions/countryAction";
import { getCompanyLocations, addUpdateCompanyLocation } from "../../../redux/actions/companyLocationAction";
import Pagination from "../../../components/common/pagination";
import { AppContext } from '../../../hoc/AppContext';
class CompanyLocationMaster extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            formType: 'Add',
            comapnyLocation: {
                id: 0,
                cid: 0,
                branchName: '',
                branchLocation: '',
                address: '',
                city: '',
                state: '',
                countryId: 0,
                isMainBranch: "N",
                isActive: "Y",
                isDelete: "N"
            },
        };
    }

    UNSAFE_componentWillMount() {
        this.props.getAllCountries();
        this.props.getCompanyLocations(this.props.user.cid);
    }


    notify = (message) => {

        var options = {};
        options = {
            place: "tr",
            message: (
                <div>
                    <div>
                        {message.message}
                    </div>
                </div>
            ),
            type: message.type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };

    showProjectModal = (comapnyLocation) => {
        this.setState({
            formType: 'Update',
            showModal: true,
            comapnyLocation: comapnyLocation
        });
    }

    toggleShowModal = () => {

        this.setState({
            formType: 'Add',
            showModal: !this.state.showModal,
            comapnyLocation: {},

        });
    }

    onClose = () => {

        this.setState({
            showModal: false,
        });
    }

    handleSubmit = async (comapnyLocation, formType) => {
        if (formType === "Add") {
            const response = await this.props.addUpdateCompanyLocation(comapnyLocation);
            if (response.success) {
                let message = {
                    message: "Company location added successfully.",
                    type: "success",
                };
                this.notify(message);
                this.setState({
                    formType: 'Add',
                    showModal: false,
                });
            } else {
                let message = {
                    message: 'Error occurred while adding company location!',
                    type: "warning",
                };
                this.notify(message);
            }
        } else {
            const response = await this.props.addUpdateCompanyLocation(comapnyLocation);
            if (response.success) {
                let message = {
                    message: "Company location updated successfully.",
                    type: "success",
                };
                this.notify(message);
                this.setState({
                    formType: 'Add',
                    showModal: false,
                });
            } else {
                let message = {
                    message: 'Error occurred while updating company location!',
                    type: "warning",
                };
                this.notify(message);
            }
        }
    }


    handleNewProject = () => {
        this.setState({
            formType: 'Add',
            showModal: true,
            comapnyLocation: {
                ...this.state.comapnyLocation,
                cid: this.props.user.cid
            }
        });
    }


    render() {
        const getCountry = (id) => {
            let country = this.props.countries.find(o => o.id === id);
            if (country) {
                return country.country;
            } else {
                return null;
            }
        }

        const { config } = this.context;

        const data = this.props.companyLocations.map((prop, key) => {
            return {
                id: prop.id,
                cid: prop.cid,
                branchName: prop.branchName,
                branchLocation: prop.branchLocation,
                address: prop.address,
                city: prop.city,
                state: prop.state,
                countryId: prop.countryId,
                countryName: getCountry(prop.countryId),
                actions: (
                    <div className="actions-right">
                        <Button
                            onClick={() => { }}
                            size="md"
                            style={{ cursor: 'context-menu' }}
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            {prop.isActive === 'Y' ? <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span> :
                                <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
                            }
                        </Button>{" "}
                    </div>
                ),
                edit: (
                    // we've added some custom button actions
                    <div className="actions-right">
                        <Button
                            onClick={() => {
                                let obj = this.props.companyLocations.find(o => o.id === prop.id);
                                this.showProjectModal(obj)
                            }}
                            // color="warning"
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-pencil" />
                        </Button>
                    </div>
                ),
                details: (
                    // we've added some custom button actions
                    <div className="">
                        <Button
                            onClick={() => {
                                let obj = this.props.companyLocations.find(o => o.id === prop.id);

                                let { id,
                                    cid,
                                    branchName,
                                    branchLocation,
                                    address,
                                    city,
                                    state,
                                    countryId } = obj;
                                let projectItem = { id, cid, branchName, branchLocation, address, city, state, countryId }
                                this.showProjectModal(projectItem)
                            }}
                            // color=""
                            size="sm"
                        >
                            View
                            </Button>
                    </div>
                )
            };
        })
        return (

            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    {this.state.showModal && <CompanyLocationModal countries={this.props.countries} modal={this.state.showModal} clients={this.props.clients} toggle={this.toggleShowModal} onSubmit={this.handleSubmit} formType={this.state.formType} comapnyLocation={this.state.comapnyLocation} close={this.onClose} />}
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CardTitle tag="h2">Company Location Master</CardTitle>
                                    <div style={{}}>
                                        <Button onClick={this.handleNewProject} size="sm" color="info">New</Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={data}
                                        // filterable
                                        // nextText={myCustomNextText}
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                width: 50,
                                            },
                                            {
                                                Header: "Branch Name",
                                                accessor: "branchName",
                                                sortable: false,
                                                filterable: false,
                                                width: 140,
                                            },
                                            {
                                                Header: "Branch Location",
                                                accessor: "branchLocation",
                                                sortable: false,
                                                filterable: false,
                                                width: 140,
                                            },
                                            {
                                                Header: "Country",
                                                accessor: "countryName",
                                                sortable: false,
                                                filterable: false,
                                                width: 130,
                                            },
                                            {
                                                Header: "State",
                                                accessor: "state",
                                                sortable: false,
                                                filterable: false,
                                                width: 130,
                                            },
                                            {
                                                Header: "City",
                                                accessor: "city",
                                                sortable: false,
                                                filterable: false,
                                                width: 130,
                                            },
                                            {
                                                Header: "",
                                                accessor: "edit",
                                                width: 50,
                                                sortable: false,
                                                filterable: false
                                            }
                                            // {
                                            //     Header: "View Details",
                                            //     accessor: "details",
                                            //     sortable: false,
                                            //     filterable: false
                                            // },

                                        ]}
                                        NoDataComponent={() => null}
                                        // pageSize={this.props.companyLocations.length <= 10 ? this.props.companyLocations.length : 10}
                                        pageSize={this.props.companyLocations.length <= config.paginationSize ? this.props.companyLocations.length : config.paginationSize}
                                        className="-striped -highlight project-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export const mapDispatchToProps = dispatch => ({
    getAllCountries: bindActionCreators(getAllCountries, dispatch),
    getCompanyLocations: bindActionCreators(getCompanyLocations, dispatch),
    addUpdateCompanyLocation: bindActionCreators(addUpdateCompanyLocation, dispatch)
});


export const mapStateToProps = ({ countries, auth, companyLocations }) => ({
    countries: countries,
    user: auth.user,
    companyLocations: companyLocations
});
export default connect(mapStateToProps, mapDispatchToProps)(CompanyLocationMaster);
