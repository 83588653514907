
import React from "react";
import ReactWizard from "react-bootstrap-wizard";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Project from '../ProjectMaster/AddUpdateSteps/Project'
import { fetchClients } from "../../../redux/actions/clientActions";
import { bindActionCreators } from "redux";
import { addUpdateProject, addUpdateProjectEmployees } from "../../../services/project-service";
import { getAllProjects, addUpdateProjectAction } from "../../../redux/actions/projectActions";
import { getProjectAllContacts } from "../../../redux/actions/projectContactActions";
import { getAllCountries } from "../../../redux/actions/countryAction";

import { NEW_PROJECT_SUCCESS_MESSAGE, ADD_UPDATE_PROJECT_EMPLOYEES_SUCCESS, ADD_UPDATE_PROJECT_EMPLOYEES_ERROR } from "variables/constants";
import { NEW_PROJECT_ERROR_MESSAGE } from "variables/constants";
import { UPDATE_PROJECT_ERROR_MESSAGE } from "variables/constants";
import _ from "lodash";
import Resources from "./AddUpdateSteps/Resources";
import { getAllEmployees } from "../../../redux/actions/employeeAction";
import ProjectContactManagement from "./AddUpdateSteps/Contact";
import { addUpdateProjectContacts } from "../../../services/project-service";
import { getAllEmployeesWithRole } from "../../../redux/actions/employeeRoleAction";
import { getProjectAllEmployees, deleteProjectEmployee, addUpdateProjectEmployee } from "../../../redux/actions/projectEmployeesAction";
import { fetchAllConfigsByCompanyId } from "../../../redux/actions/authActions";
import { uploadFile } from "services/index";
import { getEmployeesWithManagerRole } from "../../../redux/actions/managersAction";
import { getAllCurrencies } from "../../../redux/actions/currenciesAction";
import { getAllProjectTypes } from "../../../redux/actions/projectTypesAction";

class AddUpdateProjectStepper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalDemo: false,
            selectedOption: null,
            project: {},
            pid: 0,
            cid: 1,
            isLoading: false,
            uploadProgress: 0,
            isResourceLoading: true,
            isResourceAPISuccess: false,

        };
    }
    async UNSAFE_componentWillMount() {

        this.setState({
            ...this.state,
            project: { ...this.props.project },
            cid: this.props.user.cid
        })

    }

    async componentDidMount() {
        if (this.props.projects.length === 0) {
            await this.props.getAllProjects(this.props.user.cid);
        }
        if (this.props.employeesWithRole.length === 0) {

            await this.props.getAllEmployeesWithRole(this.props.user.cid);
        }

        let response = await this.props.getProjectAllEmployees(this.state.project.id, this.props.user.cid);

        if (response.success) {
            this.setState({
                ...this.state,
                isResourceLoading: false,
                isResourceAPISuccess: true
            })
        } else {
            let message = {
                message: "Failed to fetch project resources!",
                type: "warning",
            };
            this.handleMessage(message);
            this.setState({
                ...this.state,
                // isResourceLoading: false,
                isResourceAPISuccess: false
            })
        }
        if (this.props.projectTypes.length === 0) {
            await this.props.getAllProjectTypes(this.props.user.cid);
        }

        if (this.props.project !== undefined) {
            await this.props.getProjectAllContacts(this.state.project.id, this.props.user.cid)
        }
        if (this.props.employees.length === 0) {
            await this.props.getAllEmployees(this.props.user.cid);
        }
        if (this.props.clients.length === 0) {
            await this.props.getAllClients(this.props.user.cid);
        }
        if (this.props.currencies.length === 0) {

            await this.props.getAllCurrencies();
        }
        if (this.props.countries.length === 0) {
            await this.props.getAllCountries();
        }
        if (this.props.companyConfigs.length === 0) {
            await this.props.fetchAllConfigsByCompanyId(this.props.user.cid);
        }
        if (this.props.managers.length === 0) {
            await this.props.getEmployeesWithManagerRole(this.props.user.cid);
        }





    }

    onUploadProgress = (progressEvent) => {
        var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
        );
        this.setState({ uploadProgress: percentCompleted });
    };

    handleSubmitProject = async (project, selectedFile) => {
        let message;

        if (this.props.location.state.formType === "Add") {
            if (this.props.project.id === 0) {
                let result = await addUpdateProject(project);


                if (result.success) {
                    var updatedData = result.data;
                    project = { ...updatedData }
                    this.setState({
                        ...this.state,
                        pid: updatedData.id,
                        project: { ...project }
                    });
                    if (selectedFile !== null) {

                        let filePath = `${this.props.user.cid}/DOCS/${project.id}/PO`;
                        const data = new FormData()
                        data.append('filePath', filePath);
                        data.append('files', selectedFile);

                        const fileResponse = await uploadFile(data, this.onUploadProgress);

                        let docData = JSON.stringify(fileResponse.data);

                        this.setState({
                            ...this.state,
                            project: {
                                ...this.state.project,
                                poFile: docData
                                // poFile: fileResponse.data[0]
                            }
                        }, async () => {
                            var response = await addUpdateProject(this.state.project);
                            await this.props.addUpdateProjectAction(this.state.project)
                            await this.props.getProjectAllContacts(this.state.project.id, this.props.user.cid)

                            if (response.success) {
                                message = {
                                    message: NEW_PROJECT_SUCCESS_MESSAGE,
                                    type: "success",
                                };
                                this.handleMessage(message);
                                let passProps = {
                                    state: {
                                        formType: 'Update',
                                        newProject: updatedData,
                                    }
                                }
                                this.props.history.push(passProps);
                            } else {
                                message = {
                                    message: NEW_PROJECT_ERROR_MESSAGE,
                                    type: "warning",
                                };
                                this.handleMessage(message);
                            }
                        });
                    } else {

                        await this.props.addUpdateProjectAction(updatedData)
                        await this.props.getProjectAllContacts(updatedData.id, this.props.user.cid)
                        message = {
                            message: NEW_PROJECT_SUCCESS_MESSAGE,
                            type: "success",
                        };
                        this.handleMessage(message);
                        let passProps = {
                            state: {
                                formType: 'Update',
                                newProject: updatedData,
                            }
                        }
                        this.props.history.push(passProps);
                    }
                } else {
                    message = {
                        message: NEW_PROJECT_ERROR_MESSAGE,
                        type: "warning",
                    };
                    this.handleMessage(message);
                }
            }
        } else {
            let result = await addUpdateProject(project);
            if (result.success) {
                let data = result.data;
                await this.props.addUpdateProjectAction(data)
                message = {
                    message: "Updated " + project.project + " project successfully.",
                    type: "success",
                };
                this.setState({
                    ...this.state,
                    project: {
                        ...data,
                    }
                }, () => {
                    this.handleMessage(message);
                    let passProps = {
                        state: {
                            formType: 'Update',
                            newProject: data,
                        }
                    }
                    this.props.history.push(passProps);
                }
                )

            } else {
                message = {
                    message: UPDATE_PROJECT_ERROR_MESSAGE,
                    type:
                        "warning",
                };
                this.handleMessage(message);

            }
        }
    };

    handleSubmitResources = async (resources) => {
        let message;
        let isSameObject = _.isEqual(this.props.projectEmployees, resources);
        if (!isSameObject) {
            let result = await addUpdateProjectEmployees(resources);
            if (result.success) {
                await this.props.addUpdateProjectEmployee(result.data)
                message = {
                    message: ADD_UPDATE_PROJECT_EMPLOYEES_SUCCESS,
                    type: "success",
                };
                this.handleMessage(message);
            } else {
                message = {
                    message: ADD_UPDATE_PROJECT_EMPLOYEES_ERROR,
                    type: "warning",
                };
                this.handleMessage(message);

            }
        }
    };

    submitBilling = async (resources) => {

    };

    handleMessage = (message) => {
        let options = {};
        options = {
            place: "tr",
            message: message.message,
            type: message.type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    }
    handleProjectContact = async (contact, formType) => {
        let message;
        if (formType === "Add") {
            let result = await addUpdateProjectContacts(contact);
            if (result.success) {
                await this.props.getProjectAllContacts(this.state.project.id, this.props.user.cid);

                message = {
                    message: "Added contact successfully.",
                    type:
                        "success",
                };
                this.handleMessage(message);
            } else {
                message = {
                    message: "Failed to add contact!",
                    type:
                        "warning",
                };
                this.handleMessage(message);
            }
        } else if (formType === "Update") {
            let result = await addUpdateProjectContacts(contact);
            if (result.success) {
                await this.props.getProjectAllContacts(this.state.project.id, this.props.user.cid);
                message = {
                    message: "Updated contact successfully.",
                    type:
                        "success",
                };
                this.handleMessage(message);
            } else {
                message = {
                    message: "Failed to update contact!",
                    type:
                        "warning",
                };
                this.handleMessage(message);
            }

        } else {
            this.props.history.push('projectmaster');
        }
    }

    handleDeleteResource = async (eid, pid) => {
        var message;
        let result = await this.props.deleteProjectEmployee(eid, pid, this.props.user.cid);
        if (result.success) {
            // await this.props.getProjectAllContacts(this.state.project.id);
            message = {
                message: "Deleted resource successfully.",
                type: "success",
            };
            this.handleMessage(message);
        } else {
            message = {
                message: "Failed to delete resource!",
                type: "warning",
            };
            this.handleMessage(message);
        }
    }

    handleProjectDocument = async (project) => {
        let isSameObject = _.isEqual(this.state.project, project);
        if (!isSameObject) {
            let message;

            this.setState({
                isLoading: true
            });
            if (this.state.isLoading === false) {


                let result = await addUpdateProject(project);

                if (result.success) {
                    let data = result.data;
                    await this.props.addUpdateProjectAction(data)
                    message = {
                        message: "Updated " + project.project + " project successfully.",
                        type: "success",
                    };
                    this.handleMessage(message);
                    setTimeout(() => {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.props.history.push('projectmaster');
                        })


                    }, 3000);

                } else {
                    message = {
                        message: UPDATE_PROJECT_ERROR_MESSAGE,
                        type: "warning",
                    };
                    this.handleMessage(message);
                    setTimeout(() => {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.props.history.push('projectmaster');
                        });
                    }, 3000);
                }
            }
        } else {
            this.props.history.push('projectmaster');

        }
    }

    render() {
        if (!this.props.project || this.props.project === undefined) {
            let user = this.props.user;
            if (user && user.roles) {
                if (user.roles[0] === 'Manager') {
                    return <Redirect to="/manager/projectmaster" />
                } else if (user.roles[0] === "Employee") {
                    return <Redirect to="/employee/projectmaster" />
                } else {
                    return <Redirect to="/admin/projectmaster" />
                }
            }
        }
        const steps = [
            {
                stepName: "Project",
                stepIcon: "tim-icons icon-single-02",
                component: Project,
                stepProps: {
                    project: this.state.project,
                    clients: this.props.clients,
                    pid: this.props.pid,
                    submitProject: this.handleSubmitProject,
                    handleMessage: this.handleMessage,
                    // formType: this.props.location.state.formType,
                    formType: this.state.project.id === 0 ? "Add" : "Update",
                    user: this.props.user,
                    employees: this.props.employees,
                    currencies: this.props.currencies,
                    projectTypes: this.props.projectTypes,
                    companyConfigs: this.props.companyConfigs,
                    projects: this.props.projects,
                    file: this.state.project.poFile !== undefined ? this.state.project.poFile : null,
                    managers: this.props.managers,
                    history: this.props.history
                }
            },
            {
                stepName: "Resources ",
                stepIcon: "tim-icons icon-settings-gear-63",
                component: Resources,
                stepProps: {
                    project: this.state.project,
                    employees: this.props.employees,
                    employeesWithRole: this.props.employeesWithRole,
                    submitResources: this.handleSubmitResources,
                    handleMessage: this.handleMessage,
                    clients: this.props.clients,
                    user: this.props.user,
                    formType: this.props.location.state.formType,
                    resources: this.props.projectEmployees,
                    deleteResource: this.handleDeleteResource,
                    currencies: this.props.currencies,
                    isResourceLoading: this.state.isResourceLoading,
                    isResourceAPISuccess: this.state.isResourceAPISuccess,
                    history: this.props.history
                }
            },
            {
                stepName: "Contacts",
                stepIcon: "tim-icons icon-settings-gear-63",
                component: ProjectContactManagement,
                stepProps: {
                    project: this.state.project,
                    employees: this.props.employees,
                    countries: this.props.countries,
                    projectContacts: this.props.projectContacts,
                    user: this.props.user,
                    addUpdateProjectContact: this.handleProjectContact,
                    handleMessage: this.handleMessage,
                    history: this.props.history
                }
            },

        ];

        return (
            <>
                <div className="content">
                    <div className="rna-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col xs={12} md={12}>

                            <div className="custom-wizard">
                                <ReactWizard
                                    steps={steps}
                                    navSteps
                                    validate
                                    title=""
                                    formType={this.props.location.state.formType}
                                    project={this.props.location.state.project}
                                    headerTextLeft
                                    finishButtonClasses="btn-wd btn-info"
                                    nextButtonClasses="btn-wd btn-info"
                                    previousButtonClasses="btn-wd"
                                    progressbar
                                    color="blue"
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


export const mapDispatchToProps = dispatch => ({
    getAllClients: bindActionCreators(fetchClients, dispatch),
    getAllProjects: bindActionCreators(getAllProjects, dispatch),
    getAllEmployees: bindActionCreators(getAllEmployees, dispatch),
    getProjectAllContacts: bindActionCreators(getProjectAllContacts, dispatch),
    getAllCountries: bindActionCreators(getAllCountries, dispatch),
    addUpdateProjectAction: bindActionCreators(addUpdateProjectAction, dispatch),
    getAllEmployeesWithRole: bindActionCreators(getAllEmployeesWithRole, dispatch),
    getProjectAllEmployees: bindActionCreators(getProjectAllEmployees, dispatch),
    deleteProjectEmployee: bindActionCreators(deleteProjectEmployee, dispatch),
    addUpdateProjectEmployee: bindActionCreators(addUpdateProjectEmployee, dispatch),
    fetchAllConfigsByCompanyId: bindActionCreators(fetchAllConfigsByCompanyId, dispatch),
    getEmployeesWithManagerRole: bindActionCreators(getEmployeesWithManagerRole, dispatch),
    getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch),
    getAllProjectTypes: bindActionCreators(getAllProjectTypes, dispatch),
});

export const mapStateToProps = ({ projects, client, employees, auth, companies, projectContacts, countries, employeesWithRole, projectEmployees, currencies, projectTypes, managers }, ownProps) => {
    let project = projects.find(o => o.id === ownProps.location.state.pid);
    var activeClients = client && client.filter(clientItem => clientItem.isActive === "Y");


    return {
        project: project ? project : ownProps.location.state.newProject,
        clients: client.length > 0 ? activeClients : [],
        employees: employees,
        user: auth.user,
        projects: projects,
        companies: companies,
        projectContacts: projectContacts,
        countries: countries,
        employeesWithRole: employeesWithRole,
        projectEmployees: projectEmployees,
        currencies: currencies,
        projectTypes: projectTypes,
        companyConfigs: auth.companyConfigs,
        managers: managers
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateProjectStepper);
