
import React from "react";
import { Col, Modal, ModalFooter, Button, Row, CardBody, Card } from "reactstrap";
import ReactTable from "react-table";
import { AppContext } from '../../hoc/AppContext';
import Pagination from "../../components/common/pagination";
class EmployeeTimesheetModal extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      timeSheet: []

    };
  }
  async UNSAFE_componentWillMount() {
    const response = await this.props.getAllTimesheetDailyForTids(this.props.tids);
    if (response.data) {
      this.setState({
        timeSheet: response.data
      });
    } else {
      let message = {
        message: "Error occurred while fetching daily timesheet.",
        type: "warning",
      };
      this.props.handleMessage(message);
    }

  }


  render() {
    const { config } = this.context;
    const reportDetails = this.state.timeSheet.map((prop, key) => {
      let project = this.props.projects.find(obj => obj.id === prop.pid);
      return {
        workDate: prop.workDate,
        project: project && project.project ? project.project : 'N/A',
        task: prop.task,
        totalHours: prop.totalHours
      };
    })
    return (
      <>
        <Modal isOpen={this.props.modal} toggle={this.props.toggleEmployeeModal} className="modal-lg" >
          <div className="content">
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <ReactTable
                      PaginationComponent={Pagination}
                      data={reportDetails}
                      // filterable
                      // nextText={myCustomNextText}
                      resizable={true}
                      columns={[
                        {
                          Header: "Date",
                          accessor: "workDate",
                          width: 100
                        },
                        {
                          Header: "Project Name",
                          accessor: "project",
                          width: 180
                        },
                        {
                          Header: "Description",
                          accessor: "task",
                          width: 250
                        },
                        {
                          Header: "Hours",
                          accessor: "totalHours",
                          width: 100,
                          sortable: false
                        },
                      ]}
                      // showPaginationTop
                      // pageSize={this.state.timeSheet.length <= 10 ? this.state.timeSheet.length : 10}
                      pageSize={this.state.timeSheet.length <= config.paginationSize ? this.state.timeSheet.length : config.paginationSize}
                      // showPaginationBottom={false}
                      className="-striped -highlight project-table"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <ModalFooter style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button color="secondary" size="sm" onClick={this.props.handeCloseModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

      </>
    );
  }
}

export default EmployeeTimesheetModal;
