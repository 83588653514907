import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row,Spinner } from 'reactstrap';
import { REQUIRD_MESSAGE } from "../../../variables/constants";
import { containsOnlyCharacters } from "../../../components/common/validation";;

class CompanyLocationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'Add',
            isActive: true,
            comapnyLocation: {
                id: 0,
                cid: 1,
                branchName: '',
                branchLocation: '',
                address: '',
                city: '',
                state: '',
                countryId: null
            },
            clientOptions: [],
            branchNameState: '',
            branchLocationState: '',
            endDateState: '',
            statusState: '',
            clientIdState: '',
            companyState: '',
            countryIdState: '',
            isLoading: false
        };
    }



    UNSAFE_componentWillMount() {
        this.setState({
            formType: this.props.formType,
            comapnyLocation: this.props.comapnyLocation
        });
    }




    verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };


    handleSubmit = async () => {
        let { branchName, branchLocation, address, city, state, countryId } = this.state.comapnyLocation;
        if (branchName !== "" && branchLocation !== "" && address !== "" && city !== "" && state !== "" && countryId !== 0 &&
        this.state.branchNameState !== "has-danger" && this.state.branchLocationState !== "has-danger" && this.state.cityState !== "has-danger" &&
        this.state.stateState !== "has-danger" && this.state.countryIdState !== "has-danger" ) {
            let formType = this.props.formType;
            if (this.state.isLoading === false) {
                this.setState({
                    isLoading: true
                })
            this.props.onSubmit(this.state.comapnyLocation, formType);
            setTimeout(() => {
                this.setState({
                    isLoading: false
                })
            }, 3000);
        }
        } else {
            if (branchName === "") {
                this.setState({ branchNameState: "has-danger" });
            }
            if (branchLocation === "") {
                this.setState({ branchLocationState: "has-danger" });
            }
            if (address === "") {
                this.setState({ addressState: "has-danger" });
            }
            if (city === "") {
                this.setState({ cityState: "has-danger" });
            }
            if (state === "") {
                this.setState({ stateState: "has-danger" });
            }
            if (countryId === 0) {
                this.setState({ countryIdState: "has-danger" });
            }
        }
    }

    onChangeTextHandler = (event) => {
        if(event.target.name === "address"){
            let comapnyLocation = this.state.comapnyLocation;
            comapnyLocation[event.target.name] = event.target.value;
            this.setState({
                comapnyLocation: comapnyLocation
            });
        }else{
            if (containsOnlyCharacters(event.target.value)) {
                let comapnyLocation = {...this.state.comapnyLocation};
                comapnyLocation[event.target.name] = event.target.value.trim();
                this.setState({
                    comapnyLocation: comapnyLocation,
                  [event.target.name + "State"]: "has-success"
                });
              } else {
                let comapnyLocation = {...this.state.comapnyLocation};
                comapnyLocation[event.target.name] = event.target.value
                this.setState({
                    comapnyLocation: comapnyLocation,
                  [event.target.name + "State"]: "has-danger"
                });
              }
        }
                

    }


    handleSelect = (e) => {
        switch (e.target.name) {
            case "countryId":
                this.setState({ countryIdState: "has-success" });
                break;
            default:
                break;
        }

        let comapnyLocation = this.state.comapnyLocation;
        comapnyLocation[e.target.name] = parseInt(e.target.value);
        this.setState({
            comapnyLocation: { ...comapnyLocation }
        })
    }

    render() {

        let { clientIdState } = this.state;
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="custom-modal "  >
                    <ModalHeader toggle={this.props.toggle}><h4>
                        {this.props.formType === "Update" ? "Update Company Location" : "Add Company Location"}
                    </h4></ModalHeader>
                    <ModalBody>
                        <Form className="custom-form" id="LoginValidation">
                            <Row>
                                <Label md="3">Branch Name*</Label>
                                <Col md="9">
                                    <FormGroup className={`has-label ${this.state.branchNameState}`}>

                                        <Input
                                            name="branchName"
                                            type="text"
                                            placeholder="Branch name"
                                            value={this.state.comapnyLocation.branchName}
                                            onChange={this.onChangeTextHandler}
                                        />
                                        {this.state.branchNameState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Label md="3">Branch Location*</Label>
                                <Col md="9">
                                    <FormGroup className={`has-label ${this.state.branchLocationState}`}>

                                        <Input
                                            name="branchLocation"
                                            type="text"
                                            placeholder="Branch location"
                                            value={this.state.comapnyLocation.branchLocation}
                                            onChange={this.onChangeTextHandler}
                                        />
                                        {this.state.branchLocationState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Label md="3">Address *</Label>
                                <Col md="9">
                                    <FormGroup className={`has-label ${this.state.addressState}`}>

                                        <Input
                                            name="address"
                                            type="text"
                                            placeholder="Address"
                                            value={this.state.comapnyLocation.address}
                                            onChange={this.onChangeTextHandler}
                                        />
                                        {this.state.addressState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Label md="3">City *</Label>
                                <Col md="9">
                                    <FormGroup className={`has-label ${this.state.cityState}`}>

                                        <Input
                                            name="city"
                                            type="text"
                                            placeholder="City"
                                            value={this.state.comapnyLocation.city}
                                            onChange={this.onChangeTextHandler}
                                        />
                                        {this.state.cityState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Label md="3">State *</Label>
                                <Col md="9">
                                    <FormGroup className={`has-label ${this.state.stateState}`}>

                                        <Input
                                            name="state"
                                            type="text"
                                            placeholder="State"
                                            value={this.state.comapnyLocation.state}
                                            onChange={this.onChangeTextHandler}
                                        />
                                        {this.state.stateState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Label Label md="3" for="exampleSelect">Country *</Label>
                                <Col md="9">
                                    <FormGroup className={`has-label ${this.state.countryIdState} `}>
                                        <Input type="select" name="countryId"
                                            defaultValue={!this.state.comapnyLocation.countryId || this.state.comapnyLocation.countryId == null ? "" : this.state.comapnyLocation.countryId}
                                            value={!this.state.comapnyLocation.countryId || this.state.comapnyLocation.countryId == null ? "" : this.state.comapnyLocation.countryId}
                                            onChange={this.handleSelect}
                                            id="exampleSelect">
                                            <option value="" disabled>Select country</option>
                                            {this.props.countries.map((country, index) => {
                                                return (<option key={index} value={country.id}>{country.country}</option>)
                                            })
                                            }
                                        </Input>

                                        {this.state.countryIdState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" size="sm" onClick={this.handleSubmit}>
                        {this.state.isLoading ?
                        <Spinner as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true" />
                            : this.props.formType}</Button>{' '}
                        <Button color="secondary" size="sm" onClick={this.props.close}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

}

export default CompanyLocationModal;