import { GET_ALL_EMPLOYEES_LEAVE } from '../actions/leaveManagementActions';


const INITIAL_STATE = []

const employeesleaveReducer = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case GET_ALL_EMPLOYEES_LEAVE:
            newState = action.employeesLeave;
            return newState;

        default: return state;
  }
};

export default employeesleaveReducer;
