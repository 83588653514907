import React from "react";
import { Button, Col, FormGroup, Input, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import Switch from "react-switch";
import { REQUIRD_MESSAGE } from "../../../variables/constants";
import NotificationAlert from "react-notification-alert";

class ClientForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientName: "",
            email: "",
            mobile: "",
            officephone: "",
            officeext: "",
            homephone: "",
            homeext: "",
            address1: "",
            address2: "",
            city: "",
            statename: "",
            country: 1,
            zipcode: "",
            fax: "",
            isActive: true,
            clientnameState: "",
            officeextState: "has-success",
            homeextState: "has-success",
            address1State: "",
            cityState: "",
            statenameState: "",
            countryState: "",
            emailState: "",
            mobileState: "",
            zipcodeState: "",
            formName: "Add Client",
            metadata: {
                email: "email",
                phone: "mobile",
                homephone: "home phone no.",
                officephone: "office phone no.",
                homeext: "home phone ext.",
                officeext: "office phone ext.",
                clientName: "clientname",
                city: "City",
                statename: "State",
                country: "country",
                address1: "Address line 1",
                address2: "Address line 2",
                fax: "fax"
            },
            isLoading: false
        };
    }

    notify = (place, color, message) => {
        // var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };


    showNotifications = (color, message) => {
        this.notify("tr", color, message);
    }


    // function that returns true if value is email, false otherwise
    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            if (value.startsWith(' ')) {
                return false;
            }
            return true;
        }
        return false;
    };
    // function that verifies if a string has a given length or not
    verifyLength = (value, length, stateName) => {
        if (stateName === "mobile") {
            if (value.length < length) {
                return false;
            } else {
                if (value.length > length) {
                    return false;
                }
                return true;
            }
        }
        if (stateName === "zipcode") {
            if (value.length > length) {
                return false;
            }
            return true;
        }
        if (stateName === "officeext") {
            if (value.length > length) {
                return false;
            }
            return true;
        }
        if (stateName === "homeext") {
            if (value.length > length) {
                return false;
            }
            return true;

        }
        if (stateName === 'clientname') {
            var lengthClientRex = /^[a-zA-Z\s,'-]*$/;
            if (lengthClientRex.test(value)) {
                if (value.startsWith(' ') || value === '') {
                    return false;
                }
                return true;
            }
            return false;
        }
        if (stateName === "address1") {

            if (value.startsWith(' ') || value === '') {
                return false;
            }
            return true;
        }
        if (stateName === "city") {
            var lengthCityRex = /^[a-zA-Z\s,'-]*$/;
            if (lengthCityRex.test(value)) {
                if (value.startsWith(' ') || value === '') {
                    return false;
                }
                return true;
            }
            return false;
        }
        if (stateName === "statename") {
            var lengthRex = /^[a-zA-Z\s,-]*$/;
            if (lengthRex.test(value)) {
                if (value.startsWith(' ') || value === '') {
                    return false;
                }
                return true;
            }
            return false;
        }




        return true;

    };



    change = (event, stateName, type, stateNameEqualTo, maxValue) => {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "length":

                if (this.verifyLength(event.target.value, stateNameEqualTo, stateName)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    };

    handleIsActive = (e) => {

        this.setState({
            isActive: e
        })
    }


    isValidated = async () => {
        let checkClientExists = false;
        let isUpdatedClientAlreadyExist = false;
        let emailUnique = false;
        let isUpdatedEmailExists = false;
        if (this.props.formtype === "update" && this.state.clientname !== "" && this.state.email !== "") {
            let alreadyExist = this.props.clients.filter(clitem => clitem.name.toUpperCase() === this.state.clientname.trim().toUpperCase());
            let emailExists = this.props.clients.filter(clitem => clitem.emailId.toUpperCase() === this.state.email.trim().toUpperCase());
            isUpdatedClientAlreadyExist = alreadyExist.length > 1 ? true : false;
            isUpdatedEmailExists = emailExists.length > 1 ? true : false;
            let clientCount = 0;
            let emailClientCount = 0;
            this.props.clients && this.props.clients.map(clitem => {

                if (clitem.name.toUpperCase() === this.state.clientname.trim().toUpperCase() && clitem.id !== this.props.client.id) {
                   
                    clientCount = clientCount + 1;
                }
            });
            this.props.clients && this.props.clients.map(cli => {
                if (cli.emailId.toUpperCase() === this.state.email.trim().toUpperCase() && cli.id !== this.props.client.id) {
                    //
                    emailClientCount = emailClientCount + 1;
                }
            })

            if (clientCount >= 1) {
                isUpdatedClientAlreadyExist = true;
            }
            if (emailClientCount >= 1) {
                isUpdatedEmailExists = true;
            }

        } else if (this.state.clientname !== "" && this.state.email !== "") {
            checkClientExists = (this.props.clients.findIndex(clientItem => clientItem.name.toUpperCase() === this.state.clientname.trim().toUpperCase()) > -1 ? true : false)
            //

            emailUnique = (this.props.clients.findIndex(cli => cli.emailId.toUpperCase() === this.state.email.trim().toUpperCase()) > -1 ? true : false)
            //
        } else {
            //
        }

        if (
            this.state.clientnameState === "has-success" &&
            this.state.emailState === "has-success" &&
            this.state.mobileState === "has-success" &&
            this.state.address1State === "has-success" &&
            this.state.cityState === "has-success" &&
            this.state.statenameState === "has-success" &&
            this.state.officeextState === "has-success" &&
            this.state.homeextState === "has-success" &&
            this.state.zipcodeState === "has-success" &&
            checkClientExists === false &&
            isUpdatedClientAlreadyExist === false &&
            emailUnique === false &&
            isUpdatedClientAlreadyExist === false
        ) {
            let isActive = true; let id = 0
            this.state.isActive === true ? isActive = "Y" : isActive = "N";
            this.props.formtype === 'update' ? id = this.props.client.id : id = 0;
            let clientDetails = {
                id: id,
                cid: this.props.cid,
                name: this.state.clientname,
                emailId: this.state.email,
                mobile: this.state.mobile,
                phoneOffice: this.state.officephone,
                officeExtension: this.state.officeext,
                phoneHome: this.state.homephone,
                homeExtension: this.state.homeext,
                address1: this.state.address1,
                address2: this.state.address2,
                city: this.state.city,
                state: this.state.statename,
                country: this.state.country,
                zipcode: this.state.zipcode,
                paymentTerm: 0,
                fax: this.state.fax,
                isActive: isActive,
            }
            // 
            this.props.addUpdateClientDetails(clientDetails, this.props.formtype);
            return true;
        } else {
            if (this.state.clientnameState !== "has-success" || checkClientExists === true || isUpdatedClientAlreadyExist === true) {
                this.setState({ clientnameState: "has-danger" });
            }
            if (this.state.emailState !== "has-success" || emailUnique === true || isUpdatedEmailExists === true) {
                this.setState({ emailState: "has-danger" });
            }
            if (this.state.mobileState !== "has-success") {
                this.setState({ mobileState: "has-danger" });
            }
            if (this.state.zipcodeState !== "has-success") {
                this.setState({ zipcodeState: "has-danger" });
            }
            if (this.state.cityState !== "has-success") {
                this.setState({ cityState: "has-danger" });
            }
            if (this.state.address1State !== "has-success") {
                this.setState({ address1State: "has-danger" });
            }
            if (this.state.statenameState !== "has-success") {
                this.setState({ statenameState: "has-danger" });
            }
            if (this.state.countryState !== "has-success") {
                this.setState({ countryState: "has-danger" });
            }
            if (this.state.homeextState !== "has-success") {
                this.setState({ homeextState: "has-danger" })
            }
            if (this.state.officeextState !== "has-success") {
                this.setState({ officeextState: "has-danger" })
            }
            //this.showNotifications(3, "Required field cannot be empty!");
            this.setState({
                isLoading: false
            })
            return true;

        }
    };

    handleSelect = (e) => {
        this.setState({
            country: e.target.value
        })
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true
        })
        await this.isValidated();
    }

    keyDown = (event) => {

        if (event.key === '.' || event.key === '+' || event.key === '-' || event.key === 'e') {
            event.preventDefault();
        }
    }




    async UNSAFE_componentWillMount() {

        if (this.props.formtype === "update") {
            let isActive = this.props.client.isActive === "Y" ? true : false
            this.setState({
                clientname: this.props.client.name,
                mobile: this.props.client.mobile,
                email: this.props.client.emailId,
                address1: this.props.client.address1,
                address2: this.props.client.address2,
                city: this.props.client.city,
                zipcode: this.props.client.zipcode,
                statename: this.props.client.state,
                country: this.props.client.country,
                homeext: this.props.client.homeExtension,
                homephone: this.props.client.phoneHome,
                officeext: this.props.client.officeExtension,
                officephone: this.props.client.phoneOffice,
                isActive: isActive,
                fax: this.props.client.fax,
                formName: "Edit Client",
                clientnameState: "has-success",
                emailState: "has-success",
                mobileState: "has-success",
                address1State: "has-success",
                cityState: "has-success",
                countryState: "has-success",
                statenameState: "has-success",
                zipcodeState: "has-success"

            })
        }
    }

    render() {
        //
        let metadata = this.state.metadata;

        let countriesOptions = this.props.countries.map((country, index) => {
            return (<option key={index} value={country.id}>{country.country}</option>)
        });
        return (
            <>
                <div className="react-notification-alert-container">
                    <NotificationAlert ref="notificationAlert" />
                </div>
                <div className="content raise-ticket">
                    <Col className="mr-auto ml-auto" md="12">

                        <div className="text-header text-center">{this.state.formName}</div>
                        <form onSubmit={this.handleSubmit} className="ticket-form">
                            <Row className="justify-content-center">
                                <Col className="" sm="10">
                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Client Name
                                        </label>
                                        <Input
                                            name={"clientname"}
                                            placeholder={metadata.clientName + " (required)"}
                                            type="text"
                                            onChange={e => this.change(e, "clientname", "length", 0)}
                                            onFocus={e => this.setState({ clientFocus: true })}
                                            onBlur={e => this.setState({ clientFocus: false })}
                                            value={this.state.clientname}
                                        />
                                        {this.state.clientnameState === "has-danger" ? (
                                            <label className="error client_error">Please enter valid and unique Client name</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col sm="5">

                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Client's Email
                                        </label>
                                        <Input
                                            name={"email"}
                                            style={{ paddingLeft: 20 }}
                                            placeholder={metadata.email + " (required)"}
                                            type="email"
                                            onChange={e => this.change(e, "email", "length", 0)}
                                            onFocus={e => this.setState({ emailFocus: true })}
                                            onBlur={e => this.setState({ emailFocus: false })}
                                            value={this.state.email}
                                        />
                                        {this.state.emailState === "has-danger" ? (
                                            <label className="error client_error">Please enter a valid and unique email id</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Mobile
                                        </label>
                                        <Input
                                            name={"mobile"}
                                            placeholder={metadata.phone + " (required)"}
                                            type="number"
                                            onChange={e => this.change(e, "mobile", "length", 10)}
                                            onKeyDown={e => this.keyDown(e)}
                                            onFocus={e => this.setState({ mobileFocus: true })}
                                            onBlur={e => this.setState({ mobileFocus: false })}
                                            value={this.state.mobile}
                                        />
                                        {this.state.mobileState === "has-danger" ? (
                                            <label className="error client_error">Please enter valid 10 digit phone no.</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col sm="5">

                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Office Phone
                                        </label>
                                        <Input
                                            name={"officephone"}
                                            style={{ paddingLeft: 20 }}
                                            placeholder={metadata.officephone}
                                            onKeyDown={e => this.keyDown(e)}
                                            type="number"
                                            onChange={e => this.change(e, "officephone", "length", 0)}
                                            onFocus={e => this.setState({ officephoneFocus: true })}
                                            onBlur={e => this.setState({ officephoneFocus: false })}
                                            value={this.state.officephone}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Office Extension
                                        </label>
                                        <Input
                                            name={"officeext"}
                                            placeholder={metadata.officeext}
                                            type="number"
                                            onKeyDown={e => this.keyDown(e)}
                                            onChange={e => this.change(e, "officeext", "length", 5)}
                                            onFocus={e => this.setState({ officeextFocus: true })}
                                            onBlur={e => this.setState({ officeextFocus: false })}
                                            value={this.state.officeext}
                                        />
                                        {this.state.officeextState === "has-danger" ? (
                                            <label className="error client_error">Extension should not be greater than 5 digits</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col sm="5">

                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Home Phone
                                        </label>
                                        <Input
                                            name={"homephone"}
                                            style={{ paddingLeft: 20 }}
                                            placeholder={metadata.homephone}
                                            type="number"
                                            onKeyDown={e => this.keyDown(e)}
                                            onChange={e => this.change(e, "homephone", "length", 0)}
                                            onFocus={e => this.setState({ homephoneFocus: true })}
                                            onBlur={e => this.setState({ homephoneFocus: false })}
                                            value={this.state.homephone}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Home Extension
                                        </label>
                                        <Input
                                            name={"homeext"}
                                            placeholder={metadata.homeext}
                                            type="number"
                                            onKeyDown={e => this.keyDown(e)}
                                            onChange={e => this.change(e, "homeext", "length", 5)}
                                            onFocus={e => this.setState({ homeextFocus: true })}
                                            onBlur={e => this.setState({ homeextFocus: false })}
                                            value={this.state.homeext}
                                        />
                                        {this.state.homeextState === "has-danger" ? (
                                            <label className="error client_error">Extension should not be greater than 5 digits</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col className="" sm="10">
                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Address Line 1
                                        </label>
                                        <Input
                                            name={"address1"}
                                            placeholder={metadata.address1 + " (required)"}
                                            type="text"
                                            onChange={e => this.change(e, "address1", "length", 0)}
                                            onFocus={e => this.setState({ address1Focus: true })}
                                            onBlur={e => this.setState({ address1Focus: false })}
                                            value={this.state.address1}
                                        />
                                        {this.state.address1State === "has-danger" ? (
                                            <label className="error client_error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col className="" sm="10">
                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Address Line 2
                                        </label>
                                        <Input
                                            name={"address2"}
                                            placeholder={metadata.address2}
                                            type="text"
                                            onChange={e => this.change(e, "address2", "length", 0)}
                                            onFocus={e => this.setState({ address2Focus: true })}
                                            onBlur={e => this.setState({ address2Focus: false })}
                                            value={this.state.address2}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm="5">

                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            City
                                        </label>
                                        <Input
                                            name={"city"}
                                            style={{ paddingLeft: 20 }}
                                            placeholder={metadata.city + " (required)"}
                                            type="text"
                                            onChange={e => this.change(e, "city", "length", 0)}
                                            onFocus={e => this.setState({ cityFocus: true })}
                                            onBlur={e => this.setState({ cityFocus: false })}
                                            value={this.state.city}
                                        />
                                        {this.state.cityState === "has-danger" ? (
                                            <label className="error client_error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            State
                                        </label>
                                        <Input
                                            name={"statename"}
                                            placeholder={metadata.statename + " (required)"}
                                            type="text"
                                            onChange={e => this.change(e, "statename", "length", 0)}
                                            onFocus={e => this.setState({ statenameFocus: true })}
                                            onBlur={e => this.setState({ statenameFocus: false })}
                                            value={this.state.statename}
                                        />
                                        {this.state.statenameState === "has-danger" ? (
                                            <label className="error client_error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col sm="5">

                                    <FormGroup className="form-group">
                                        <label htmlFor="country">
                                            Country
                                        </label>
                                        <Input type="select" name="country" defaultValue={this.state.country} onChange={this.handleSelect} id="exampleSelect">
                                            {countriesOptions}
                                        </Input>
                                        {/* <Input
                                                        name="country"
                                                        style={{ paddingLeft: 20 }}
                                                        placeholder=""
                                                        type="text"
                                                        value={country}
                                                        onChange={this.onChangeTextHandler}
                                                    /> */}
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Zip Code
                                        </label>
                                        <Input
                                            name={"zipcode"}
                                            placeholder={"zipcode (required)"}
                                            type="number"
                                            onKeyDown={e => this.keyDown(e)}
                                            onChange={e => this.change(e, "zipcode", "length", 6)}
                                            onFocus={e => this.setState({ zipFocus: true })}
                                            onBlur={e => this.setState({ zipFocus: false })}
                                            value={this.state.zipcode}
                                        />
                                        {this.state.zipcodeState === "has-danger" ? (
                                            <label className="error client_error">Please enter valid zipcode</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Fax
                                        </label>
                                        <Input
                                            name={"fax"}
                                            placeholder={metadata.fax}
                                            type="number"
                                            onKeyDown={e => this.keyDown(e)}
                                            onChange={e => this.change(e, "fax", "length", 0)}
                                            onFocus={e => this.setState({ faxFocus: true })}
                                            onBlur={e => this.setState({ faxFocus: false })}
                                            value={this.state.fax}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col className="" sm="5">
                                    <FormGroup>
                                        <label id="tooltip209850">
                                            <span style={{ display: 'block', marginBottom: 10, marginTop: 4 }} htmlFor="isActive"> Active </span>
                                            <Switch
                                                onChange={(e) => { this.handleIsActive(e) }}
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                            />
                                        </label>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target="tooltip209850"
                                            placement="right"
                                        >
                                            {this.state.isActive === true ? "Active" : "Inactive"}
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                </Col>


                            </Row>
                            <Row className="justify-content-center mt-3">
                                {
                                    this.state.isLoading ? (
                                        <Button size="sm" disabled='true' color="info" className="btn-submit btn-ticket-submit">
                                            <Spinner size="sm" />
                                        </Button>

                                    ) : (
                                        <Button type="submit" size="sm" color="info" className="btn-submit btn-ticket-submit">Submit</Button>
                                    )
                                }
                            </Row>
                        </form>
                    </Col></div>

            </>
        );
    }
}


export default ClientForm