import React from "react";

const NoData = (props) => { 
    if (props.loading) {
        return null;
      }
      return <div className="rt-noData">No data found</div>
}

export default NoData;

