import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {LOGOUT_SUCCESS} from './actions/authActions';
let store;

const appReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }
  return rootReducer(state, action);
};
store = createStore(
    appReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;