import { getAllApprovalPendingTimesheetsAPI, approveRejectWeeklyTimesheetAPI } from "../../services/timesheets-service";

export const FETCH_ALL_APPROVAL_PENDING_TIMESHEET = 'FETCH_ALL_APPROVAL_PENDING_TIMESHEET';
export const APPROVE_REJECT_WEEKLY_TIMESHEET = 'APPROVE_REJECT_WEEKLY_TIMESHEET';
export const getAllApprovalPendingTimesheets = (data) => {
    return async (dispatch) => {
        const response = await getAllApprovalPendingTimesheetsAPI(data);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_APPROVAL_PENDING_TIMESHEET,
                timesheetApprovalPending: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

export const approveRejectWeeklyTimesheet = (weeklyTimesheet) => {
    return async (dispatch) => {
        const response = await approveRejectWeeklyTimesheetAPI(weeklyTimesheet);
        if (response.data === true) {
            dispatch({
                type: APPROVE_REJECT_WEEKLY_TIMESHEET,
                payload: {
                    id: weeklyTimesheet.id,
                    weeklyTimesheet: response.data
                }
            });
            return { success: true, data: response.data };
        }
        else {
            return { success: false };
        }
    }
}