import moment from 'moment';
import React, { Component } from "react";
import ReactDatetime from "react-datetime";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row, Spinner, Input, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";
import Pagination from "../../../components/common/pagination";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
import { AppContext } from '../../../hoc/AppContext';
import { getAllEmployees } from "../../../redux/actions/employeeAction";
import { fetchEmployeesExpenseReport } from "../../../redux/actions/adminExpenseReportAction";
import classNames from "classnames";
import ViewClaimItemsModal from "../../../views/ExpenseManager/ApproveRejectExpense/ViewClaimItemsModal";
import { dateFormatList } from 'variables/constants';



class ExpenseReport extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),
            isReportLoading: false,
            isDataLoading: false,
            status: 'All',
            selectedEmployee: 'All',
            isOpenClaimModal: false,
            claim: null,
            alert: null,
        };
    }
    async UNSAFE_componentWillMount() {
        this.setState({
            ...this.state,
            isDataLoading: true
        }, async () => {
            // await this.props.getAllClaimCategories();
            let response = await this.props.getAllEmployees(this.props.user.cid);
            let { startDate, endDate, selectedEmployee, status } = this.state;
            let data = {
                cid: this.props.user && this.props.user.cid,
                eid: selectedEmployee === "All" ? "" : selectedEmployee,
                status: status === "All" ? "" : status,
                startDate, endDate
            }
            let result = await this.props.fetchEmployeesExpenseReport(data);

            if (response) {
                this.setState({
                    ...this.state,
                    isDataLoading: false,
                });
            }

        });

    }

    toggleClaimItemsModal = (claim) => {
        this.setState({
            isOpenClaimModal: !this.state.isOpenClaimModal,
            claim: claim
        });
    }

    onClose = () => {
        this.setState({
            isOpenClaimModal: false,
        });
    }

    notify = (place, type, message) => {

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 2
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
        setTimeout(() => {
            this.setState({
                ...this.state,
                isReportLoading: false,
                isDataLoading: false
            })
        }, 2000);
    };


    handleStartDate = (date) => {
        this.setState({
            ...this.state,
            startDate: moment(date).format('YYYY-MM-DD')
        });
    }
    handleEndDate = (date) => {
        this.setState({
            ...this.state,
            endDate: moment(date).format('YYYY-MM-DD')
        });
    }

    handleFind = async () => {
        this.setState({
            ...this.state,
            isReportLoading: true,
            isDataLoading: true
        }, async () => {

            let { startDate, endDate, selectedEmployee, status } = this.state;
            let data = {
                cid: this.props.user && this.props.user.cid,
                eid: selectedEmployee === "All" ? "" : selectedEmployee,
                status: status === "All" ? "" : status,
                startDate, endDate
            }
            let result = await this.props.fetchEmployeesExpenseReport(data);
            if (result.success) {

                this.setState({
                    ...this.state,
                    isDataLoading: false
                });
                if (result.data && result.data.length > 0) {
                    this.notify("tr", "success", "Fetched expense report successfully.");
                } else {
                    this.setState({
                        ...this.state,
                        isDataLoading: false
                    });
                    this.notify("tr", "success", "No report found.");
                }

            } else {
                this.setState({
                    ...this.state,
                    isDataLoading: false
                });
                this.notify("tr", "warning", "Failed to fetch expense report!");
            }
        })

    }

    handleSelect = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,

        });
    }

    render() {

        const myCustomNextText = () => {
            return
        };

        const { config } = this.context;
        const data = this.props.adminContractorsReport && this.props.adminContractorsReport.map((contractor, index) => {
            return {
                contractorName: contractor.contractorName,
                billingRate: (
                    <div className="actions-right custom-action-right custom-total-hours">
                        {contractor.billingRate}
                    </div>
                ),
                vendorName: contractor.vendorName,
                project: contractor.project,
                allocationPercent: (
                    <div className="actions-right custom-action-right custom-total-hours">
                        {contractor.allocationPercent}
                    </div>
                ),
            };
        })

        var employeesOptions = this.props.employees.length > 0 && this.props.employees.map((employee, index) => {
            return (<option key={index} style={{}} value={employee.eid}>{employee.fname + ' ' + employee.lname}</option>)
        });

        const expenseData = this.props.expenseReports.map((exp, key) => {

            return {
                index: key,
                claimNumber: exp.claimNumber.toUpperCase(),
                dateSubmitted: moment(exp.dateSubmitted).format(dateFormatList[2]),
                empName: (<div>
                    <span id={"tooltip207191" + key}>{exp.empName}</span>
                    <UncontrolledTooltip
                        delay={0}
                        target={"tooltip207191" + key}
                        placement="right"
                    >
                        {exp.empName}
                    </UncontrolledTooltip>

                </div>
                ),
                shortDesc: (<div>
                    <span id={"tooltip203491" + key}>{exp.shortDesc}</span>
                    <UncontrolledTooltip
                        delay={0}
                        target={"tooltip203491" + key}
                        placement="right"
                    >
                        {exp.shortDesc}
                    </UncontrolledTooltip>

                </div>
                ),
                amount: exp.amount.toFixed(2),
                claimStatus: exp.claimStatus === "Draft" ? "Saved" : exp.claimStatus,
                noOfItems: exp.claimItems.length,
                view: (
                    <div className="actions-left">
                        <Button
                            onClick={() => {
                                this.toggleClaimItemsModal(exp);
                            }}
                            size="sm"
                            id="tooltip209543"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-zoom-split" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209543"
                            placement="left"
                        >
                            View
                        </UncontrolledTooltip>
                    </div>
                ),
            };
        });

        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{}}>
                                    <Row style={{ display: 'flex', alignItems: 'center' }} >
                                        <Col style={{ maxWidth: 150, paddingLeft: 15, paddingRight: 5 }} >
                                            <Label >Select Start Date </Label>
                                            <FormGroup className={`has-label`}>
                                                <ReactDatetime
                                                    // onViewModeChange="months"
                                                    dateFormat="YYYY-MM-DD"
                                                    viewMode="days"
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: "Select Start Date", className: "form-control", value: moment(this.state.startDate).format(dateFormatList[2]) }}
                                                    onChange={this.handleStartDate}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col style={{ maxWidth: 150, paddingLeft: 15, paddingRight: 5 }} >
                                            <Label >Select End Date </Label>
                                            <FormGroup className={`has-label`}>
                                                <ReactDatetime
                                                    // onViewModeChange="months"
                                                    dateFormat="YYYY-MM-DD"
                                                    viewMode="days"
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: "Select End Date", className: "form-control", value: moment(this.state.endDate).format(dateFormatList[2]) }}
                                                    onChange={this.handleEndDate}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <Label for="status">Employee</Label>
                                            <FormGroup className={`has-label  `}>
                                                <Input type="select" name="selectedEmployee" value={this.state.selectedEmployee} onChange={this.handleSelect} id="status">
                                                    <option value="All">All</option>
                                                    {employeesOptions}
                                                </Input>
                                                {this.state.clientIdState === "has-danger" ? (
                                                    <label className="error">please select employee!</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <Label for="status">Status</Label>
                                            <FormGroup className={`has-label  `}>
                                                <Input type="select" name="status" value={this.state.status} onChange={this.handleSelect} id="status">
                                                    <option value="All">All</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Rejected">Rejected</option>
                                                    <option value="Draft">Saved</option>
                                                    <option value="Submitted">Submitted</option>
                                                    <option value="Paid">Paid</option>
                                                    <option value="Pending">Pending</option>
                                                </Input>
                                                {this.state.clientIdState === "has-danger" ? (
                                                    <label className="error">please select status!</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="auto" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                            <div style={{ marginTop: 14 }}>
                                                <div style={{}}>
                                                    <Button onClick={() => this.state.isReportLoading === true ? null : this.handleFind()} style={{ width: 70 }} size="sm" color="info">
                                                        {this.state.isReportLoading === true ?
                                                            <Spinner as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true" /> :
                                                            'Find'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {this.state.isOpenClaimModal &&
                                        <ViewClaimItemsModal modal={this.state.isOpenClaimModal} claim={this.state.claim}
                                            toggle={this.toggleClaimItemsModal} close={this.onClose} />
                                    }
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={expenseData}
                                        resizable={false}
                                        columns={[

                                            {
                                                Header: "Claim No.",
                                                accessor: "claimNumber",
                                                sortable: false,
                                                filterable: false,
                                                width: 120,
                                            },
                                            {
                                                Header: "Submitted Date",
                                                accessor: "dateSubmitted",
                                                width: 130,
                                            },
                                            {
                                                Header: "Description",
                                                accessor: "shortDesc",
                                                width: 220,
                                            },
                                            {
                                                Header: "Employee",
                                                accessor: "empName",
                                                width: 180,
                                            },
                                            {
                                                Header: "Amount",
                                                accessor: "amount",
                                                width: 70,
                                            },
                                            {
                                                Header: "Status",
                                                accessor: "claimStatus",
                                                width: 95,
                                            },
                                            {
                                                Header: "Items",
                                                accessor: "noOfItems",
                                                width: 52,
                                            }, {
                                                Header: "",
                                                accessor: "view",
                                                width: 160,
                                            },
                                        ]}
                                        LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                                        NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                                        pageSize={this.props.expenseReports.length <= config.paginationSize ? this.props.expenseReports.length : config.paginationSize}
                                        className="-striped -highlight project-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export const mapDispatchToProps = dispatch => ({
    getAllEmployees: bindActionCreators(getAllEmployees, dispatch),
    fetchEmployeesExpenseReport: bindActionCreators(fetchEmployeesExpenseReport, dispatch)
});

export const mapStateToProps = ({ auth, employees, contractorsReport, expenseReport }) => {

    return {
        token: auth.user.token,
        employees: employees,
        user: auth.user,
        adminContractorsReport: contractorsReport.adminContractorsReport === undefined ? [] : contractorsReport.adminContractorsReport,
        expenseReports: expenseReport && expenseReport.expenseReport
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseReport);
