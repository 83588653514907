// export const API_REQUEST = "http://3.110.1.210:8081";
export const CHANGE_PASSWORD_ERROR = 'Error occurred while changing password!';
export const CHANGE_PASSWORD_SUCCESS = 'Your password is changed successfully.';
export const NEW_PASSWORD_ERROR = 'The new password and confirmation password do not match!';
export const REQUIRD_MESSAGE = 'This field is required!';
export const NEW_PROJECT_SUCCESS_MESSAGE = 'Project added successfully.';
export const NEW_PROJECT_ERROR_MESSAGE = 'Failed to add new project!';
export const UPDATE_PROJECT_ERROR_MESSAGE = 'Failed to update project!';
export const PROJECT_NAME_REQUIRD = 'Please enter project name!';
export const PROJECT_START_DATE_REQUIRD = 'Please select start date!';
export const PROJECT_CLIENT_REQUIRD = 'Please select client!';
export const PROJECT_COMPANY_REQUIRD = 'Please select company!';
export const PROJECT_COMPANY_STATUS_REQUIRD = 'Please select project status!';
export const PROJECT_END_DATE_REQUIRD = 'Please select end date!';
export const ADD_EMPLOYEE_BASIC_INFO_SUCCESS = 'Employee details added successfully.';
export const ADD_EMPLOYEE_BASIC_INFO_ERROR = 'Error occurred while adding employee details!';
export const UPDATE_EMPLOYEE_BASIC_INFO_SUCCESS = 'Employee details updated successfully.';
export const UPDATE_EMPLOYEE_BASIC_INFO_ERROR = 'Error occurred while updating employee details!';
export const UPDATE_EMPLOYEE_EXTRA_INFO_SUCCESS = 'Employee position details updated successfully.';
export const UPDATE_EMPLOYEE_EXTRA_INFO_ERROR = 'Error occurred while updating employee position details!';
export const ADD_EMPLOYEE_EXTRA_INFO_SUCCESS = 'Employee position details updated successfully.';
export const ADD_EMPLOYEE_EXTRA_INFO_ERROR = 'Error occurred while updating employee position details!';
export const ADD_EMPLOYEE_PAYROLL_INFO_SUCCESS = 'Employee payroll information added successfully.';
export const ADD_EMPLOYEE_PAYROLL_INFO_ERROR = 'Error occurred while adding payroll information!';
export const ADD_UPDATE_PROJECT_EMPLOYEES_SUCCESS = 'Updated project resources successfully.';
export const ADD_UPDATE_PROJECT_EMPLOYEES_ERROR = 'Failed to update project resources!';
export const FILE_MAX_SIZE_MB = 5;
export const CLAIM_ITEMS_MAX_LIMIT = 5;
export const CLAIM_ITEM_ATTACHMENTS_MAX_LIMIT = 2;
export const PAGINATION_SIZE = 10;
export const PASSWORD_REQUIREMENT_TEXT = `Password should contain 8 to 16 characters and must combine uppercase and lowercase letters, numbers, and special characters(i.e. @#$%^&).`;
export const dateFormatList = [
    "DD-MM-YYYY",
    "DD/MM/YYYY",
    "DD-MMM-YYYY",
    "DDMMYYYY",
    "YYYY-MM-DD",
    "YYYY-MM-DD hh:mm a"
];
export const MAX_CHARACTER_SIZE = '(Maximum 500 characters)';
export const INNOVATION = `Driving creativity and continuous improvement to develop new ideas, solutions, and technologies that add value and enhance efficiency.
`;
export const COLLABORATION = `Seamlessly connect, communicate, and work together to achieve shared goals with efficiency and creativity.`;
export const INTEGRITY = `Upholding honesty, transparency, and ethical principles to build trust and ensure accountability in all actions.`;
export const COMPLIANCE = `Adhering to legal, regulatory, and industry standards to ensure security, transparency, and ethical operations.`;
