import axios from 'axios';

export const fetchEmployeeAppraisalListAPI = (payload) => {
    const modifiedData = {
        cid:payload.cid,
        eid:payload.eid,
        // size: payload.pageSize,
        // page: payload.pageNo - 1
    };
    return axios
        .get(`/api/v1/employee/employee-appraisal`, { params: { ...modifiedData } })
        .then(function (response) {
            
            if (response.data) {
                return { success: true, data: response.data };
            } else {
                return { success: false };
            }
        })
        .catch(function (error) {
            return { success: false };
        });
}

export const addUpdateEmployeeAppraisalAPI = (employee) => {
    return axios.post(`/api/v1/employee-appraisal`, employee)
      .then(function (response) {
        if (response.data) {
          return { success: true, data: response.data };
        }
      })
      .catch(function (error) {
        return { success: false };
      });
  }

  export const fetchEmployeeAppraisalByAppraisalIdAPI = (empAppraisalId ) => {
   
    return axios
        .get(`/api/v1/employee/${empAppraisalId}/employee-appraisal`, { params: { empAppraisalId  } })
        .then(function (response) {
            
            if (response.data) {
                return { success: true, data: response.data };
            } else {
                return { success: false };
            }
        })
        .catch(function (error) {
            return { success: false };
        });
}

export const fetchEmployeesAppraisalListAPI = (payload) => {
    const modifiedData = {
        cid:payload.cid,
        managarId :payload.managarId,
        // size: payload.pageSize,
        // page: payload.pageNo - 1
    };
    return axios
        .get(`/api/v1/manager/employee-appraisal`, { params: { ...modifiedData } })
        .then(function (response) {
            
            if (response.data) {
                return { success: true, data: response.data };
            } else {
                return { success: false };
            }
        })
        .catch(function (error) {
            return { success: false };
        });
}

export const fetchManagerEmployeesAppraisalByAppraisalIdAPI = (empAppraisalId ) => {
   
    return axios
        .get(`/api/v1/manager/${empAppraisalId}/employee-appraisal`, { params: { empAppraisalId : empAppraisalId  } })
        .then(function (response) {
            
            if (response.data) {
                return { success: true, data: response.data };
            } else {
                return { success: false };
            }
        })
        .catch(function (error) {
            return { success: false };
        });
}


export const updateManagerEmployeeAppraisalAPI = (employeeAppraisal) => {
    return axios.post(`/api/v1/manager/employee-appraisal`, employeeAppraisal)
      .then(function (response) {
        if (response.data) {
          return { success: true, data: response.data };
        }
      })
      .catch(function (error) {
        return { success: false };
      });
  }

  export const fetchHREmployeeAppraisalByAppraisalIdAPI = (empAppraisalId ) => {
   
    return axios
        .get(`/api/v1/HR/${empAppraisalId}/employee-appraisal`, { params: { empAppraisalId  } })
        .then(function (response) {
            
            if (response.data) {
                return { success: true, data: response.data };
            } else {
                return { success: false };
            }
        })
        .catch(function (error) {
            return { success: false };
        });
}


export const fetchHREmployeeAppraisalListAPI = (payload) => {
    const modifiedData = {
        cid:payload.cid,
        eid:payload.eid,
        size: payload.pageSize,
        page: payload.pageNo - 1
    };
    return axios
        .get(`/api/v1/HR/employee-appraisal`, { params: { ...modifiedData } })
        .then(function (response) {
            
            if (response.data) {
                return { success: true, data: response.data };
            } else {
                return { success: false };
            }
        })
        .catch(function (error) {
            return { success: false };
        });
}

export const fetchEmployeeAppraisalByUserIdAPI = (payload ) => {
   
    return axios
        .get(`/api/v1/employee/${payload.eid}/${payload.cid}/employee-appraisal`)
        .then(function (response) {
            
            if (response.data) {
                return { success: true, data: response.data };
            } else {
                return { success: false };
            }
        })
        .catch(function (error) {
            return { success: false };
        });
}

export const fetchAllEmployeesAppraisalAPI = (cid ) => {
   
    return axios
        .get(`/api/v1/HR/employees/appraisal`, { params: { cid  } })
        .then(function (response) {
            
            if (response.data) {
                return { success: true, data: response.data };
            } else {
                return { success: false };
            }
        })
        .catch(function (error) {
            return { success: false };
        });
}


export const fetchEmployeesAppraisalInfoAPI = (empAppraisalId  ) => {
   
    return axios
        .get(`/api/v1/HR/employees/appraisal/excel`, { params: { empAppraisalId   } })
        .then(function (response) {
            
            if (response.data) {
                return { success: true, data: response.data };
            } else {
                return { success: false };
            }
        })
        .catch(function (error) {
            return { success: false };
        });
}

















