import axios from 'axios';

const setJWTToken = (token) => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
}

axios.interceptors.request.use(req => {
    return req;
});

axios.interceptors.response.use((response) => {
    return response;
}, async(error) => {
    if (error.response && error.response.data) {
        if (error.response.status === 401){
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('companyConfigs');
            sessionStorage.removeItem("USESSIONID")
            setJWTToken(null);
            window.location.href = window.location.origin + '/auth/login';
        }
    }
    return Promise.reject(error.message);
});
export default setJWTToken;