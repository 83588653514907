import { FETCH_ALL_PROJECTS, ADD_UPDATE_PROJECT, } from "../actions/projectActions";
const projectReducer = function (state = [], action) {
    // let newState;
    let index;
    switch (action.type) {
        case FETCH_ALL_PROJECTS:
            return action.projects;

        case ADD_UPDATE_PROJECT:
            let newState = [...state];
            let index = newState.findIndex(project => project.id === action.project.id);
            if (index !== -1) {
                newState[index] = action.project;
                return newState;
            } else {
                newState.push(action.project);
                return newState;

            }

        default:
            return state;
    }
};

export default projectReducer;