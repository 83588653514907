import { getProjectAllContactsAPI } from '../../services/project-service';

export const FETCH__PROJECT_ALL_CONTACTS = 'FETCH__PROJECT_ALL_CONTACTS';

export const getProjectAllContacts = (pid, cid) => {
    return async (dispatch) => {
        const response = await getProjectAllContactsAPI(pid, cid);
        if (response.data) {
            dispatch({
                type: FETCH__PROJECT_ALL_CONTACTS,
                contacts: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}