import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import ReactTable from "react-table";
import ReactDatetime from "react-datetime";
import {  Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { getLeavesHistoryDetails } from "redux/actions/manageLeaveActions";
import { getAllEmployees } from "../../redux/actions/employeeAction";
import Pagination from "../../components/common/pagination";
import LoadingIndicator from "../../components/common/LoadingIndicator";
import NoData from "../../components/common/NoData";
import { AppContext } from '../../hoc/AppContext';
import moment from "moment";
import { dateFormatList } from "variables/constants";
import { fetchAllEmployeesLeave } from "redux/actions/leaveManagementActions";
import { CSVLink } from "react-csv";
import { FaRegFileExcel } from "react-icons/fa";


const excelHeaders = [
    { label: 'EMP. NO.', key: 'eid' },
    { label: 'EMPLOYEE NAME', key: 'empName' },
    { label: 'START DATE', key: 'startDate' },
    { label: 'END DATE', key: 'endDate' },
    { label: 'REASON', key: 'leaveReason' },
    { label: 'DAYS', key: 'noOfDays' },
    { label: 'STATUS', key: 'leaveStatus' }
];

class LeaveHistoryDetails extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            isDataLoading: false,
            data:[],
            eid:null,
            status:null,
            leaveReportCSV: 'Employees-Leave-Report.csv',
            startDate: moment().startOf('year').format('YYYY-MM-DD'),
            endDate:moment().endOf('year').format('YYYY-MM-DD'),
        };
    }

    getLeaveDetails = async () => {
        this.setState({
              isDataLoading: true
          }) 
        if(this.props.role === "Manager"){
            await this.props.getLeavesHistoryDetails(this.props.cid, this.props.user.eid);
            this.setState({
                isDataLoading: false
            })
            this.setState({
                data: this.props.leavesHistory
            })
          }else{
            await this.props.fetchAllEmployeesLeave(
            this.props.cid, 
            this.state.eid, 
            this.state.status,
            this.state.startDate,
            this.state.endDate
            );
              this.setState({
                  isDataLoading: false
              })
            this.setState({
                data: this.props.employeesLeave
            })
        }
    }


    async UNSAFE_componentWillMount() {
        this.getLeaveDetails();
        if(this.props.role !== "Manager"){
        await this.props.getAllEmployees(this.props.user.cid);
        }
    }

    handleSelect = (e) => {
        if(e.target.value === "All"){
            this.setState({
                ...this.state,
                [e.target.name]: null,
            });
        }else{
            this.setState({
                ...this.state,
                [e.target.name]: e.target.value,
            });
        }       
    }

    handleFind = async () =>{
        this.setState({
            isDataLoading: true
        }) 
        await this.props.fetchAllEmployeesLeave(
            this.props.cid, 
            this.state.eid, 
            this.state.status,
            this.state.startDate,
            this.state.endDate
        );
        this.setState({
            isDataLoading: false
        })
        this.setState({
          data: this.props.employeesLeave
        })
    }

    handleExportExcel = async (e, Type) => {
    
            
    }

    handleStartDate = (date) => {
            this.setState({
                ...this.state,
                startDate: moment(date).format('YYYY-MM-DD')
            });
        }
    handleEndDate = (date) => {
            this.setState({
                ...this.state,
                endDate: moment(date).format('YYYY-MM-DD')
            });
    }

    render() {
        const { config } = this.context;
        var employeesOptions = this.props.employees.length > 0 && this.props.employees.map((employee, index) => {
            return (<option key={index} style={{}} value={employee.eid}>{employee.fname + ' ' + employee.lname}</option>)
        });
        
        return (
            <>
                <NotificationAlert ref="notificationAlert" />
                <div className="content">
                    {this.state.alert}
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center'}}>
                                <CardTitle tag="h2">Leaves Report</CardTitle>
                                {this.props.role !== "Manager" ?  <Row style={{ display: 'flex', alignItems: 'center' }} > 
                                <Col style={{ maxWidth: 150, paddingLeft: 15, paddingRight: 5 }} >
                                    <Label >Select Start Date </Label>
                                    <FormGroup className={`has-label`}>
                                    <ReactDatetime
                                        dateFormat="YYYY-MM-DD"
                                        viewMode="days"
                                        closeOnSelect={true}
                                        timeFormat={false}
                                        inputProps={{ placeholder: "Select Start Date", className: "form-control", value: moment(this.state.startDate).format(dateFormatList[2]) }}
                                        onChange={this.handleStartDate}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col style={{ maxWidth: 150, paddingLeft: 15, paddingRight: 5 }} >
                                    <Label >Select End Date </Label>
                                    <FormGroup className={`has-label`}>
                                    <ReactDatetime
                                        dateFormat="YYYY-MM-DD"
                                        viewMode="days"
                                        closeOnSelect={true}
                                        timeFormat={false}
                                        inputProps={{ placeholder: "Select End Date", className: "form-control", value: moment(this.state.endDate).format(dateFormatList[2]) }}
                                        onChange={this.handleEndDate}
                                    />
                                    </FormGroup>
                                </Col>                          
                                <Col style={{ maxWidth: 200, paddingLeft: 5, paddingRight: 5 }}>
                                    <Label for="eid">Select Employee</Label>
                                    <FormGroup className={`has-label  `}>
                                        <Input type="select" name="eid" value={this.state.eid} onChange={(e) => { this.handleSelect(e) }} id="eid">
                                            <option value='All'>All</option>
                                            {employeesOptions}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col style={{ maxWidth: 150, paddingLeft: 5, paddingRight: 5 }}>
                                    <Label for="status">Status</Label>
                                    <FormGroup className={`has-label  `}>
                                        <Input type="select" name="status" value={this.state.status} onChange={(e) => {this.handleSelect(e)}} id="status">
                                            <option value='All'>All</option>
                                            <option value="Draft">Draft</option>
                                            <option value="Submitted">Submitted</option>
                                            <option value="Approved">Approved</option>
                                            <option value="Rejected">Rejected</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="auto" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <div style={{ marginTop: 14 }}>
                                        <div style={{}}>
                                        <Button onClick={() => this.handleFind()} style={{ width: 70 }} size="sm" color="info">
                                                {this.state.isDataLoading === true ?
                                                    <Spinner as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true" /> :
                                                    'Find'}</Button>                                            
                                            <UncontrolledTooltip
                                                delay={0}
                                                target="tooltip209893"
                                                placement="top"
                                            >
                                                Export To Excel
                                            </UncontrolledTooltip>
                                            <CSVLink data={this.state.data}
                                                headers={excelHeaders}
                                                filename={this.state.leaveReportCSV}
                                                target="_blank"
                                                id="tooltip209893"
                                                onClick={(e) => this.handleExportExcel(e)}
                                            >
                                                <FaRegFileExcel className="custom-excel-icon" /></CSVLink>
                                        </div>
                                    </div>
                                </Col>
                            </Row> : <div />}
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={this.state.data}
                                        //filterable
                                        // nextText={myCustomNextText}
                                        resizable={false}
                                        columns={[

                                            {
                                                Header: "Emp. No.",
                                                accessor: "eid",
                                                width: 60
                                            },
                                            {
                                                Header: "Emp. Name",
                                                accessor: "empName",
                                                width: 100
                                            },
                                            {
                                                Header: "Start Date",
                                                accessor: "startDate",
                                                width: 130,                                             
                                                Cell:({ value }) => <span>{moment(value).format(dateFormatList[2])}</span>
                                            },
                                            {
                                                Header: "End Date ",
                                                accessor: "endDate",
                                                width: 130,
                                                Cell:({ value }) => <span>{moment(value).format(dateFormatList[2])}</span>
                                            },
                                            {
                                                Header: "Reason",
                                                accessor: "leaveReason",
                                                width: 340,
                                                resizable: true
                                            },
                                            {
                                                Header: "Days",
                                                accessor: "noOfDays",
                                                width: 90
                                            },
                                            {
                                                Header: "Status",
                                                accessor: "leaveStatus",
                                                width: 90
                                            }
                                        ]}
                                        LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                                        NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                                        pageSize={this.state.data.length <= config.paginationSize ? this.state.data.length : config.paginationSize}
                                        className="-striped -highlight project-table leave-table expense-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    leavesHistory: state.leavesHistory,
    cid: state.auth.user.cid,
    user: state.auth.user,
    role:state.auth.user.roles[0],
    employees: state.employees,
    employeesLeave:state.employeesLeave
});

const mapDispatchToProps = (dispatch) => {
    return {
        getLeavesHistoryDetails: bindActionCreators(getLeavesHistoryDetails, dispatch),
        getAllEmployees:bindActionCreators(getAllEmployees, dispatch),
        fetchAllEmployeesLeave:bindActionCreators(fetchAllEmployeesLeave, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaveHistoryDetails);
