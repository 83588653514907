/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import { Button } from 'reactstrap';

import store from '../redux/store';
import { signoutUser } from "../redux/actions/authActions";

const whiteColor = "#FFF";
const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "inherit", 
        backgroundColor: "#3c4858",
        textAlign: "center",
        color: whiteColor,
        padding: "0 15px",
        width: "100%",
    },
    icon: {
        color: '#fd77a4',
        fontSize: "5em",
        marginTop: "50px",
        marginBottom: "25px",
    },

    title: {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontSize: "2.25rem",
        marginTop: "0",
        marginBottom: "8px",
        color: whiteColor,
    },
    description: {
        fontSize: "1.125rem",
        marginTop: "0",
        marginBottom: "8px",
        color: whiteColor,
    }
}

export default function ErrorPage(props) {
    const logoutUser=()=>{
        store.dispatch(signoutUser());
        var base_url = window.location.origin;
        window.location.href = base_url + '/auth/login';
    }
    return (
        <div className={props.className ? props.className : "wrapper wrapper-full-page"} >
            <div style={styles.container}>
                <i className="tim-icons icon-alert-circle-exc" style={styles.icon} />
                <h2 style={styles.title}>{props.title}</h2>
                <h4 style={styles.description}>
                    {props.description}
                </h4>
                <Button color="primary" onClick={props.resetError}>Try again</Button>
                <Button color="warning" onClick={()=>logoutUser()}>Logout</Button>
            </div>
        </div>
    );
}
