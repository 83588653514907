import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import RolesModal from "./rolesModal";
import NotificationAlert from "react-notification-alert";
import { getAllRoles } from "../../../redux/actions/rolesAction";
import { addUpdateRole } from "services/roles-service";
import Pagination from "../../../components/common/pagination";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
import { AppContext } from '../../../hoc/AppContext';
class RolesMaster extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      role: {},
      formType: 'add',
      data: [],
      alert: null

    };
  }

  handeOpenModal = () => {
    this.setState({
      isOpen: true,
      formType: 'add',
      role: {}
    })
  };

  toggle = () => {
    //
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };



  //notificationAlert = React.createRef();

  notify = (place, color, message) => {
    // var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  };

  successAlert = (status) => {
    let options = {};
    options = {
      place: "tr",
      message: "Role " + status + " successfully.",
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 2
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  showNotifications = (color, message) => {
    this.notify("tr", color, message);
  }



  showRoleModal = (role) => {
    this.setState({
      formType: 'update',
      isOpen: true,
      role: { ...role }
    });
  }

  addUpdateRole = async (role, formtype) => {
    let status = '';
    if (formtype === 'add') {
      status = 'added';
    } else {
      status = 'updated'
    }
    this.toggle();

    let result = await addUpdateRole(role);

    if (result.success) {
      this.setState({
        isOpen: false
      })
      this.successAlert(status);
      this.getAllRoles(1);
    } else {
      this.showNotifications(3, "Failed to add Role!");
    }
  }

  getAllRoles = async () => {
    this.setState({
      isDataLoading: true
    })
    const response = await this.props.getAllRoles(this.props.cid);
    if (response) {
      this.setState({
        isDataLoading: false
      })
    }
    let data = this.props.roles.map((prop, key) => {
      let isActive = true;
      prop.isActive === "Y" ? isActive = 'active' : isActive = 'inactive'
      return {
        id: key + 1,
        roleId: prop.id,
        cid: prop.cid,
        rolesname: prop.role,
        isActive: isActive,
        test: (
          <div className="actions-right">
            <Button
              onClick={() => { }}
              size="md"
              style={{ cursor: 'context-menu' }}
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              {prop.isActive === 'Y' ? <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span> :
                <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
              }
            </Button>{" "}
          </div>
        ),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              id="tooltip209814"
              onClick={() => {
                let obj = this.state.data.find(o => o.id === key + 1);
                this.showRoleModal(obj)
              }}
              // color="warning"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              <i className="tim-icons icon-pencil" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="tooltip209814"
              placement="left"
            >
              Edit
            </UncontrolledTooltip>
          </div>
        )
      };
    })

    this.setState({
      data: data
    })
  }

  UNSAFE_componentWillMount() {
    this.getAllRoles();

  }

  render() {

    const { isOpen } = this.state;
    const { config } = this.context;
    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        {isOpen && <RolesModal cid={this.props.cid} roles={this.props.roles} role={this.state.role} addUpdateRole={this.addUpdateRole} toggle={this.toggle} isOpen={isOpen} formtype={this.state.formType} showNotifications={this.showNotifications} />}
        <div className="content">
          {this.state.alert}
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CardTitle tag="h2">Roles Master Details </CardTitle>
                  <div style={{ display: 'flex' }}>
                    <Button color="info" size="sm" onClick={this.handeOpenModal}>New</Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    PaginationComponent={Pagination}
                    data={this.state.data}
                    resizable={false}
                    columns={[
                      {
                        Header: "",
                        accessor: "test",
                        sortable: false,
                        filterable: false,
                        width: 80
                      },
                      {
                        Header: "Role No.",
                        accessor: "id",
                        sortable: false,
                        filterable: false,
                        width: 120
                      },
                      {
                        Header: "Role Name",
                        accessor: "rolesname",
                        width: 400
                      },
                      /*  {
                         Header: "isActive",
                         accessor: "isActive",
                         width: 300
                       }, */
                      {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                    NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                    pageSize={this.props.roles.length <= config.paginationSize ? this.props.roles.length : config.paginationSize}
                    className="-striped -highlight project-table leave-table expense-table"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {

  return {
    roles: state.roles,
    cid: state.auth.user.cid,

  }

};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllRoles: bindActionCreators(getAllRoles, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RolesMaster);
