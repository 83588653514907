import { FETCH_ALL_PROJECT_TYPES } from "redux/actions/projectTypesAction";
const projectTypesReducer = function (state = [], action) {
    switch (action.type) {
        case FETCH_ALL_PROJECT_TYPES:
            return action.projectTypes;
        default:
            return state;
    }
};

export default projectTypesReducer;