import axios from 'axios';

export const fetchHolidayListAPI = (cid,year) => {
    return axios.get(`/api/v1/holidays`, { 
        params: {
            cid:cid,
            year:year
        }
    })
    .then(function (response) {
      if(response.data){
        return {success: true, data:response.data }
      }
      })
      .catch(function (error) {
        return {success: false, };
      });
}

export const getHolidaysByLocationAPI = (cid,locationId) => {
    return axios.get(`/api/v1/holidays-by-countryId-and-companyId`, { 
        params: {
            cid:cid,
            companyLocationId:locationId
        }
    })
    .then(function (response) {
      if(response.data){
        return {success: true, data:response.data }
      }
      })
      .catch(function (error) {
        return {success: false, };
      });
}

export const addUpdateHoliday = (holidayDTO) => {
    return axios.post(`/api/v1/add-update-holiday`, holidayDTO)
      .then(function (response) {
        if (response.status === 200) {
          return { success: true, data: response.data };
        }
      })
      .catch(function (error) {
        return { success: false };
      });
}

export const deleteHoliday = (holidayId) => {
  return axios.get(`/api/v1/delete-holiday`, { 
      params: {
          holidayId
      }
  })
  .then(function (response) {
    if(response.data){
      return {success: true, data:response.data }
    }
    })
    .catch(function (error) {
      return {success: false, };
    });
}