import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Spinner
} from "reactstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import NotificationAlert from "react-notification-alert";
import { Redirect } from "react-router-dom";
import { signinUser, fetchAllConfigsByCompanyId } from "../redux/actions/authActions";
import { REQUIRD_MESSAGE } from "../variables/constants";
import { verifyLengthUserPass } from "../components/common/validation";
import { getCompanyBasicInfo } from "../redux/actions/appConfig";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isActiveUser: false,
      isLoading: false,
      usernameState: "",
      passwordState: "",
    }
  }
  // componentDidMount() {
  //   document.body.classList.toggle("login-page");
  // }
  UNSAFE_componentWillMount() {
    let hostedUrl = window.location.hostname;
    // let hostedUrl = '65.0.79.133';

    /* if (hostedUrl == 'localhost') {
      hostedUrl = "ess.peramalservices.com";
      this.props.getCompanyLogo(hostedUrl);
    } else { */
    this.props.getCompanyBasicInfo(hostedUrl);
    // }
  }
  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // function that verifies if value contains only numbers
  verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "length":
        if (event.target.value != "") {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };
  handleLogin = async () => {

    if (this.state.username != "" && this.state.password != "") {
      let hostedUrl = window.location.hostname;
      // let hostedUrl = '65.0.79.133';      
      if (this.state.isLoading === false) {
        this.setState({
          isLoading: true
        })
        /* if (hostedUrl == 'localhost') {
          hostedUrl = "ess.peramalservices.com";
          const response = await this.props.signinUser(hostedUrl, this.state.username, window.btoa(this.state.password));
          if (response.success) {
            let result = await this.props.fetchAllConfigsByCompanyId(this.props.user.cid);

            this.setState({
              isLoading: false
            })
          } else {
            let options = {};
            options = {
              place: "tr",
              message: "Incorrect username and password!",
              type: "warning",
              icon: "tim-icons icon-bell-55",
              autoDismiss: 2
            };
            if(this.refs.notificationAlert){
              this.refs.notificationAlert.notificationAlert(options);
            }
            setTimeout(() => {
              this.setState({
                isLoading: false
              })
            }, 3000);
          }
        } */
        // else {
        const response = await this.props.signinUser(hostedUrl, this.state.username, window.btoa(this.state.password));
        if (response.success) {
          let result = await this.props.fetchAllConfigsByCompanyId(this.props.user.cid);

          this.setState({
            isLoading: false
          })
        } else {
          let message = null;
          if (response.error) {
            message = response.error.includes('Unauthorized user') ? 'Unauthorized  user!' : response.error.includes('User not found') ? 'User not found!' : 'Incorrect username and password!';
          }
          let options = {};
          options = {
            place: "tr",
            message: message === null ? 'Incorrect username and password!' : message,
            type: "warning",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 2
          };
          if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
          }
          setTimeout(() => {
            this.setState({
              isLoading: false
            })
          }, 3000);
        }
        // }
      }
    }
    else {
      if (this.state.username == "") {
        this.setState({ usernameState: "has-danger" });
      }
      if (this.state.password == "") {
        this.setState({ passwordState: "has-danger" });
      }
    }
  }
  render() {

    if (this.props.token && this.props.user && this.props.user.roles) {

      if (this.props.user.roles[0] === "Manager") {
        return <Redirect to="/manager/enterTimesheet" />
      } else if (this.props.user.roles[0] === 'Employee') {
        return <Redirect to="/employee/enterTimesheet" />
      }
      else if (this.props.user.roles[0] === "Human Resource") {
        return <Redirect to="/humanresource/appraisal-list" />
      }
      else {
        return <Redirect to="/admin/enterTimesheet" />
      }
    }
    let logo = this.props.companyLogo ? this.props.companyLogo : null;
    return (
      <>
        <div className="content custom-login-content" >
          <div className="rna-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6" style={{ marginTop: '50px' }}>
              <Form className="form">
                <Card className="card-login card-white" >
                  <CardHeader style={{ margin: 'auto' }}>
                    {/* <img
                      alt="..."
                      src={require("assets/img/card-primary.png")}
                    /> */}
                    {this.props.companyLogo ?
                      <img
                        style={{ maxWidth: '200px', height: 'auto', marginBottom: '20px' }}
                        alt="..."
                        src={logo}
                      />
                      : <div style={{ height: '125px' }}>

                      </div>
                    }
                    <CardTitle style={{ textAlign: 'center' }} tag="h1">ESS</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={classnames(this.state.usernameState, {
                        "input-group-focus": this.state.usernameFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Username"
                        type="text"
                        name="username"
                        value={this.state.username}
                        onChange={e => this.change(e, "username", "length")}
                        onFocus={e => this.setState({ usernameFocus: true })}
                        onBlur={e => this.setState({ usernameFocus: false })}
                      />
                    </InputGroup>
                    {this.state.usernameState === "has-danger" ? (
                      <label className="error">{REQUIRD_MESSAGE}</label>
                    ) : null}
                    <InputGroup
                      className={classnames(this.state.passwordState, {
                        "input-group-focus": this.state.passwordFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="password"
                        placeholder="Password"
                        type="password"
                        autoComplete="on"
                        value={this.state.password}
                        onChange={e => this.change(e, "password", "length")}
                        onFocus={e => this.setState({ passwordFocus: true })}
                        onBlur={e => this.setState({ passwordFocus: false })}
                      />
                    </InputGroup>
                    {this.state.passwordState === "has-danger" ? (
                      <label className="error">{REQUIRD_MESSAGE}</label>
                    ) : null}
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="mb-3"
                      color="primary"
                      type="submit"
                      onClick={e => {
                        e.preventDefault();
                        this.handleLogin();
                      }}
                      size="lg"
                    >{this.state.isLoading ?
                      <Spinner as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true" />

                      : 'Sign In'
                      }
                    </Button>
                    {/* <div className="pull-left">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          Create Account
                        </a>
                      </h6>
                    </div> */}
                    <div className="pull-right">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            this.props.history.push('/auth/forgot-password');
                          }}
                        >
                          Reset Password?
                        </a>
                      </h6>
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}
export const mapDispatchToProps = dispatch => ({
  signinUser: bindActionCreators(signinUser, dispatch),
  getCompanyBasicInfo: bindActionCreators(getCompanyBasicInfo, dispatch),
  fetchAllConfigsByCompanyId: bindActionCreators(fetchAllConfigsByCompanyId, dispatch),
});

export const mapStateToProps = ({ auth, appConfig }) => ({
  token: auth.token,
  companyLogo: appConfig.logo && appConfig.logo,
  user: auth.user,
  companyConfigs: auth.companyConfigs,

});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
