import React, { createContext, useState, useReducer, useEffect } from 'react';
import moment from 'moment';
import {
    CLAIM_ITEMS_MAX_LIMIT,
    CLAIM_ITEM_ATTACHMENTS_MAX_LIMIT,
    FILE_MAX_SIZE_MB,
    PAGINATION_SIZE
} from '../variables/constants';

const AppContext = createContext(undefined);
function AppProvider({ children }) {

    const [config, setConfig] = useState({
        dateFormat: 'MMM DD,YYYY',
        maxFileSizeMB: FILE_MAX_SIZE_MB,
        claim: {
            claimItemsMaxLimit: CLAIM_ITEMS_MAX_LIMIT,
            claimItemAttachmentsMaxLimit: CLAIM_ITEM_ATTACHMENTS_MAX_LIMIT,
        },
        paginationSize: PAGINATION_SIZE
    });



    const getFormattedDate = (date) => {
        return date
            ? moment(date).format(config.dateFormat)
            : moment().format(config.dateFormat);
    };
    return (
        <AppContext.Provider value={{ config, setConfig, getFormattedDate }}>
            {children}
        </AppContext.Provider>
    );
}
export { AppProvider, AppContext };