import { FETCH_ALL_COMPANIES ,ADD_UPDATE_COMPANY, DELETE_COMPANY} from "../actions/companyActions";
const companyReducer = function (state = [], action) {
    let newState;
    let index;
    let id;
    switch (action.type) {
        case FETCH_ALL_COMPANIES:
            return action.companies;
        case ADD_UPDATE_COMPANY:
            newState = [...state];
            index = newState.findIndex(newCompany =>newCompany.id === action.payload.id);
            if (index > -1) {
                newState.splice(index, 1, action.payload.company);
            }
            else{
                newState = [...state];
                newState.push(action.payload.company);
                return newState;
            }
            return newState;
        case DELETE_COMPANY:
            newState = [...state];
            id = action.payload.id;
            index = newState.findIndex(company =>company.id === id);
            if (index > -1) {
                newState.splice(index, 1);
            };
            return newState;
        default:
            return state;
    }
};

export default companyReducer;