import React from "react";
import classNames from "classnames";
import NotificationAlert from "react-notification-alert";
import ReactTable from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import Pagination from "../../../../components/common/pagination";
import CompanyLocationModal from "../CompanyLocationModal";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      cid: '',
      data: [],
      countries: [],
      company: {},
      companyLocation: {
        id: 0,
        cid: 1,
        branch: '',
        location: '',
        address: '',
        city: '',
        stateName: '',
        country: 0,
        isActive: 'Y',
        isDelete: 'N',
        isMainBranch: 'N'
      },
      locations: []
    };
  }

  showDepartmentModal = (selectedLocation) => {
    this.setState({
      formType: 'Update',
      showModal: true,
      companyLocation: { ...selectedLocation }
    });
  };

  toggleShowModal = () => {

    this.setState({
      formType: 'Add',
      showModal: !this.state.showModal,
      companyLocation: {},

    });
  };

  onClose = () => {

    this.setState({
      showModal: false,
    });
  };

  handleSubmit = async (companyLocation) => {
    this.onClose();

    let result = await this.props.submitExtraInfo(companyLocation);


    //let locations= this.state.locations;
    //locations.push(companyLocation);
    if (result.success) {
      //
      let message = {
        message: 'Company location has been added successfully.',
        type: "success",
      };
      this.handleMessage(message);
      await this.props.getCompanyLocations(companyLocation.cid);

    } else {
      let message = {
        message: 'Error while adding the company location!',
        type: "warning",
      };
      this.handleMessage(message);
    }
  }

  handleMessage = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message.message,
      type: message.type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  isValidated = async () => {
    if (this.props.companyLocations && this.props.companyLocations.length > 0) {
      await this.props.getCompanyContacts(this.props.company.id);
      return true;
    }
    else {
      let message = {
        message: 'Kindly enter atleast one company location',
        type: "warning",
      };
      this.handleMessage(message);
      await this.props.getCompanyContacts(this.props.company.id);
      return false;

    }
  }


  UNSAFE_componentWillMount() {
    let locations = [];
    this.props.company.locations ? locations = this.props.company.locations : locations = []
    this.setState({
      company: {
        ...this.state.company,
        ...this.props.company,
        //cid:this.props.cid,
        countries: this.props.countries,
      },
    });
    this.getTableData();

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      company: {
        ...nextProps.company,
        ...nextProps.company,
        cid: nextProps.cid,
        countries: nextProps.countries
      }
    });
    //this.getTableData();
  }

  getTableData = () => {

    this.setState({
      ...this.state,
      locations: this.props.companyLocations.map((prop, key) => {
        return {
          id: prop.id,
          cid: prop.id,
          branch: prop.branchName,
          location: prop.branchLocation,
          address: prop.address,
          city: prop.city,
          stateName: prop.state,
          country: prop.country,
          isActive: prop.isActive,
          isDelete: prop.isDelete,
          isMainBranch: prop.isMainBranch,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* <Button
                            onClick={() => {
                                let obj = this.state.locations.find(o => o.id === prop[0]);

                                let { id,
                                    cid,
                                    branch,
                                    location,
                                    address,
                                    city,
                                    stateName,
                                    country,
                                } = obj;
                                let selectedLocation = { id, cid, branch, location,address,city,stateName,country }
                                this.showDepartmentModal(selectedLocation);
                            }}
                            // color="warning"
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-pencil" />
                        </Button> */}
              {/* <FormGroup check>
                          <Label check>
                            <Input  defaultValue="" type="checkbox" />
                            <span className="form-check-sign">
                              <span className="check" />
                            </span>
                          </Label>
                        </FormGroup> */}
            </div>
          )
        };
      })
    });
  }

  handleCancel = () => {
    let passProps = {
      pathname: 'companymaster',
    }
    this.props.history.push(passProps);
  }

  render() {
    //this.getTableData()

    let locations = this.props.companyLocations.map((prop, key) => {
      return {
        id: prop.id,
        cid: prop.cid,
        branch: prop.branchName,
        location: prop.branchLocation,
        address: prop.address,
        city: prop.city,
        stateName: prop.state,
        country: prop.countryId,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              onClick={() => {
                let obj = this.props.companyLocations.find(o => o.id === prop.id);

                let { id,
                  cid,
                  branchName,
                  branchLocation,
                  address,
                  city,
                  state,
                  countryId,
                  isActive,
                  isDelete,
                  isMainBranch
                } = obj;
                let selectedLocation = { id, cid, branchName, branchLocation, address, city, state, countryId, isActive, isDelete, isMainBranch }
                this.showDepartmentModal(selectedLocation);
              }}
              // color="warning"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              <i className="tim-icons icon-pencil" />
            </Button>

          </div>
        ),
        delete: (
          <div className="">
            <Button
              onClick={() => this.handleDelete(prop.id)}
              color="warning"
              id="tooltip209771"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              <i className="tim-icons icon-trash-simple" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="tooltip209771"
              placement="left"
            >
              Delete
            </UncontrolledTooltip>
          </div>
        ),
        activeBlob: (
          <div className="actions-right">
            <Button
              onClick={() => { }}
              color="info"
              size="md"
              style={{ cursor: 'context-menu' }}
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              {prop.isActive === 'Y' ? <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span> :
                <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
              }
            </Button>
          </div>
        )
      };
    })
    return (
      <>
        <div className="content">

          <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          {this.state.showModal && <CompanyLocationModal cid={this.props.company.id} modal={this.state.showModal} countries={this.props.countries} toggle={this.toggleShowModal} onSubmit={this.handleSubmit} formType={this.state.formType} companyLocation={this.state.companyLocation} close={this.onClose} />}
          <Row style={{ marginTop: '40px' }}>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CardTitle tag="h2">Locations</CardTitle>
                  <div style={{}}>
                    <Button size="sm" onClick={this.toggleShowModal} color="info">New</Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    PaginationComponent={Pagination}
                    data={locations}
                    // filterable
                    // nextText={myCustomNextText}
                    resizable={false}
                    columns={[
                      {
                        Header: "",
                        accessor: "activeBlob",
                        width: 40,
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Branch",
                        accessor: "branch",
                        width: 120
                      },
                      {
                        Header: "Location",
                        accessor: "location",
                        width: 120
                      },
                      {
                        Header: "Address",
                        accessor: "address",
                        width: 250
                      },
                      {
                        Header: "City",
                        accessor: "city",
                        width: 120
                      },
                      {
                        Header: "State",
                        accessor: "stateName",
                        width: 100
                      },
                      // {
                      //   Header: "Country",
                      //   accessor: "country"
                      // },
                      {
                        Header: "",
                        accessor: "actions",
                        width: 30,
                        sortable: false,
                        filterable: false
                      },
                      // {
                      //   Header:"",
                      //   accessor: "delete",
                      //   width: 30,
                      //   sortable: false,
                      //   filterable: false,
                      // }
                      // {
                      //     Header: "Company",
                      //     accessor: "company"
                      // },


                    ]}
                    NoDataComponent={() => null}
                    pageSize={locations.length <= 5 ? locations.length : 5}
                    className="-striped -highlight project-table leave-table"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Button onClick={() => { this.handleCancel() }} className="btn-cancel" size="sm" color="info">Cancel</Button>
      </>
    );
  }
}

export default Wizard;