import axios from 'axios';

export const getCompanyBasicInfoAPI = (hostedUrl) => {
  return axios
    .get(`/api/auth/company-basic-info`, {
      params: {
        hostedUrl: hostedUrl,
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}


export const addCrashLogDB = (crashInfo) => {
  return axios
    .post('/api/v1/add-crash-log', crashInfo)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
