import moment from 'moment';
import React, { Component } from "react";
import ReactDatetime from "react-datetime";
import ReactTable from "react-table";
import { Button, Card, CardBody, CardTitle, CardHeader, Col, FormGroup, Label, Row } from "reactstrap";
import Pagination from "../../components/common/pagination";
import { dateFormatList } from 'variables/constants';

const dataTable = [
    [1, 101, "Johm Joe", "Mehko Chef App", 38, "2020-03-03"],
    [2, 102, "Steve Smith", "Mehko Eater App", 12, "2020-003-13"],
    [3, 103, "Sam Curran", "Singularity", 14, "2020-03-15"],
    [4, 104, "David Warner", "Education Brainiac", 15, "2020-03-18"],
    [5, 105, "Sam Billings", "Business Brainiac", 8, "2020-03-23"]
];

class TimesheetHourlyReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf('isoweek').format('YYYY-MM-DD'),
            endDate: moment().endOf('isoweek').format('YYYY-MM-DD'),
            status: 'All',
            data: dataTable.map((prop, key) => {
                return {
                    empid: prop[0],
                    employeeNumber: prop[1],
                    employeeName: prop[2],
                    project: prop[3],
                    totalHours: prop[4],
                    workDate: prop[5]
                };
            })
        };
    }

    handleStartDate = (date) => {
        this.setState({
            ...this.state,
            startDate: moment(date).format('YYYY-MM-DD')
        });
    }


    handleEndDate = (date) => {
        this.setState({
            ...this.state,
            endDate: moment(date).format('YYYY-MM-DD')

        });
    }







    handleFind = () => {
        let formatedData = dataTable.map((prop, key) => {
            return {
                empid: prop[0],
                employeeNumber: prop[1],
                employeeName: prop[2],
                project: prop[3],
                totalHours: prop[4],
                workDate: prop[5]
            };
        });

        let filteredData = formatedData.filter(dataItem => moment(dataItem.workDate).isBetween(this.state.startDate, this.state.endDate, null, []));
        this.setState({
            ...this.state,
            data: filteredData
        })
    }



    render() {

        const myCustomNextText = () => {
            return
        };
        return (
            <>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{}}>
                                    <CardTitle tag="h2" style={{ marginBottom: 20 }}>Timesheet Hourly Details</CardTitle>

                                    <Row >
                                        <Col md="2" >
                                            <Label >Start Date </Label>
                                            <FormGroup className={`has-label`}>
                                                <ReactDatetime
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: "Start Date", className: "form-control", value: moment(this.state.startDate).format(dateFormatList[2]) }}
                                                    onChange={this.handleStartDate}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" >
                                            <Label >End Date </Label>
                                            <FormGroup className={`has-label`}>
                                                <ReactDatetime
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: "End Date", className: "form-control", value: moment(this.state.endDate).format(dateFormatList[2]) }}
                                                    onChange={this.handleEndDate}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 8 }}>
                                            <Button size="sm" onClick={this.handleFind} color="info">Find</Button>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 8, marginLeft: 13 }}>
                                            <Button size="sm" color="secondary">Export To PDF</Button>
                                        </div>
                                    </Row>


                                </CardHeader>
                                <CardBody>
                                    <Col xs={12} md={9}>
                                        <ReactTable
                                            PaginationComponent={Pagination}
                                            data={this.state.data}
                                            // filterable
                                            // nextText={myCustomNextText}
                                            resizable={false}
                                            columns={[
                                                {
                                                    Header: "Employee ID",
                                                    accessor: "empid",
                                                    width: 100,
                                                },
                                                {
                                                    Header: "Employee Number",
                                                    accessor: "employeeNumber",
                                                    width: 140,
                                                },
                                                {
                                                    Header: "Employee Name",
                                                    accessor: "employeeName"
                                                },
                                                {
                                                    Header: "Project",
                                                    accessor: 'project'
                                                },
                                                {
                                                    Header: "Total Hours",
                                                    accessor: "totalHours",
                                                    width: 100,
                                                }
                                            ]}
                                            NoDataComponent={() => null}
                                            pageSize={dataTable.length <= 10 ? dataTable.length : 10}
                                            className="-striped -highlight project-table"
                                        />
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default TimesheetHourlyReport;
