import uuidv1 from 'uuid/v1';
import { refreshTokenAPI, signinUserAPI, signoutUserAPI } from "../../services/auth-service";
import { getAllInitialCompanyConfigsAPI } from "../../services/company-service";

export const AUTH_LOADING = 'AUTH_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const GET_ALL_COMPANY_CONFIGS = 'GET_ALL_COMPANY_CONFIGS';

export const signinUser = (hostedUrl, username, password) => {
    return async (dispatch) => {
        const response = await signinUserAPI(hostedUrl, username, password);
        if (response.data) {
            let result = response.data;
            createSession();
            dispatch({
                type: LOGIN_SUCCESS,
                data: result
            });
            return { success: true, result };
        }
        else {
            return { success: false, error: response.error };
        }
    }
}

const createSession=()=>{
    const USESSIONID = uuidv1();
    sessionStorage.setItem("USESSIONID", USESSIONID);
    const channel = new BroadcastChannel("ESS");
    const obj = {
        action: "NEW_SESSION_CREATED",
        payload: {
            sessionId: USESSIONID
        }
    }
    channel.postMessage(obj);
}

export const authRefresh = () => async (dispatch, getState) => {
    const { auth } = getState();
    if (auth.user) {
        const response = await refreshTokenAPI({
            cid: auth.user.cid,
            username: auth.user.username,
            refreshToken: auth.user.refreshToken
        });
        if(response.success){
            const data = {
                ...auth.user,
                jwttoken: response.data.jwttoken,
                refreshToken: response.data.refreshToken
            };
            dispatch({
                type: AUTH_REFRESH_SUCCESS,
                data
            });
        }else{
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('companyConfigs');
            dispatch({
                type: LOGOUT_SUCCESS
            });
        }
    }else{
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('companyConfigs');
        dispatch({
            type: LOGOUT_SUCCESS
        });
    }
}

export const signoutUser = () => async (dispatch, getState) => {
    const { auth } = getState();
    if (auth.user) {
        const data = {
            cid: auth.user.cid,
            username: auth.user.username,
            jwtToken: auth.user.jwttoken,
            refreshToken: auth.user.refreshToken
        };
        const response = await signoutUserAPI(data);
    }
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('companyConfigs');
    dispatch({
        type: LOGOUT_SUCCESS
    });
}

export const fetchAllConfigsByCompanyId = (cid) => {
    return async (dispatch) => {
        const response = await getAllInitialCompanyConfigsAPI(cid);
        if (response.data) {
            dispatch({
                type: GET_ALL_COMPANY_CONFIGS,
                companyConfigs: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

export const resetReduxStore = () => (dispatch) => {
    dispatch({
        type: LOGOUT_SUCCESS
    });
}