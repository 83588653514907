import React, { Component } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';



class TimesheetReportModal extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }


    handleSubmit = async () => {
        this.props.close();
    }

    renderData = () => {
        const timesheetDailyList = [...this.props.timesheet.timesheetDailyList];
        return timesheetDailyList.map((timesheet, index) => {

            let project = this.props.projects.filter(project => project.id === timesheet.projectId);
            return (
                <tr>
                    <td>{index + 1}</td>
                    <td>{timesheet.workDate}</td>
                    <td className="">{project.length > 0 ? project[0].project : null}</td>
                    <td>{timesheet.task}</td>
                    <td className="text-center">{timesheet.totalHours}</td>
                </tr>
            )
        })
    }



    render() {

        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="timesheet-report-modal modal-xl">
                    <ModalHeader toggle={this.props.toggle}><h4>
                        Weekly Timesheet Report Details
                    </h4></ModalHeader>
                    <ModalBody>
                        <Form className="custom-form" id="LoginValidation">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th style={{ width: "15%" }}>Work Date</th>
                                        <th>Project</th>
                                        <th >Task</th>
                                        <th className="text-center" style={{ width: 100 }} >Total Hours</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderData()}
                                </tbody>
                            </Table>

                        </Form>
                    </ModalBody>
                    <ModalFooter style={{ display: 'block' }}>
                        <Button style={{ float: 'right' }} size="sm" color="secondary" onClick={this.props.close}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

}

export default TimesheetReportModal;