import { GET_VENDORS } from "../actions/vendorActions";

const vendorReducer = function (state = [], action) {
    switch (action.type) {
        case GET_VENDORS:
            return action.vendors;
        default:
            return state;
    }
};

export default vendorReducer;