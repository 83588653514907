import { getAllCompaniesAPI , addUpdateCompanyAPI, deleteCompanyAPI} from '../../services/company-service';
export const FETCH_ALL_COMPANIES = 'FETCH_ALL_COMPANIES';
export const ADD_UPDATE_COMPANY = 'ADD_UPDATE_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';

export const getAllCompanies = () => {
    return async (dispatch) => {
        const response = await getAllCompaniesAPI();
        if (response.data) {
            dispatch({
                type: FETCH_ALL_COMPANIES,
                companies: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

export const addUpdateCompany = (company) =>  {
    return async (dispatch) => {
        const response = await addUpdateCompanyAPI(company);
            if (response.data){
                dispatch({
                    type: ADD_UPDATE_COMPANY,
                    payload: {
                      id:company.id,
                      company: response.data
                  }
                  });
                return { success: true, data:response.data };
            }
            else{
                return { success: false};
            }    
        }
  }

  export const deleteCompany = (id) =>  {
    return async (dispatch) => {
        const response = await deleteCompanyAPI(id);
            if (response.data){
                dispatch({
                    type: DELETE_COMPANY,
                    payload: {
                      id:id
                  }
                  });
                return { success: true };
            }
            else{
                return { success: false};
            }    
        }
  }
