import React from "react";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from 'reactstrap';
import { containsSpecialCharacter, verifyEmail, containsOnlyCharacters, allowOnlyOneBlankSpace } from '../../../../../components/common/validation'
import Switch from "react-switch";

class ProjectContactModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyState: '',
            isActive: true,
            projectState: 'has-success',
            companyState: 'has-success',
            contactNameState: 'has-success',
            contactEmailState: 'has-success',
            contactMobileState: 'has-success',
            contactOfficePhoneState: 'has-success',
            cityState: 'has-success',
            stateState: 'has-success',
            contact: {
                address1: "",
                address2: "",
                cid: this.props.user.cid,
                city: "",
                contactEmail: "",
                contactExt: "",
                contactInfo: "",
                contactMobile: "",
                contactName: "",
                contactOfficePhone: "",
                country: 1,
                id: 0,
                isActive: "Y",
                pid: 0,
                state: ""
            },
            isLoading: false
        };
    }


    UNSAFE_componentWillMount() {
        if (this.props.formType === "Add") {
            // let user = localStorage.getItem('user');
            this.setState({
                ...this.state,
                contact: {
                    ...this.state.contact,
                    cid: this.props.user.cid,
                    pid: this.props.project.id,
                    country: this.props.countries.length > 0 ? this.props.countries[0].id : null
                }
            });
        }
        if (this.props.formType === "Update") {
            let isActive = this.props.contact.isActive === "Y" ? true : false;
            this.setState({
                ...this.state,
                isActive: isActive,
                contact: { ...this.props.contact }
            });
        }
    }



    verifyLength = (value, length) => {
        if (value.length <= length) {
            return false;
        }
        return true;
    };

    handleIsActive = (e) => {
        this.setState({
            ...this.state,
            isActive: e,
            contact: {
                ...this.state.contact,
                isActive: e === true ? "Y" : "N",
            }
        });
    }





    onChangeTextHandler = (e) => {
        var result = false;
        var contact = { ...this.state.contact };
        contact[e.target.name] = e.target.value;

        switch (e.target.name) {

            case "contactName":
                result = containsSpecialCharacter(e.target.value);
                var onlyCharacters = containsOnlyCharacters(e.target.value);
                this.setState({
                    ...this.state,
                    contactNameState: contact.contactName === "" || onlyCharacters === false ? "has-danger" : "has-success",
                    contact: { ...contact },
                });
                return true;
                break;
            case "contactEmail":

                result = verifyEmail(e.target.value);
                this.setState({
                    ...this.state,
                    contactEmailState: e.target.value == "" ? "has-success" : result === false ? "has-danger" : "has-success",
                    contact: { ...contact },
                });
                return true;
                break;
            case 'city':
                result = containsSpecialCharacter(e.target.value);
                this.setState({
                    ...this.state,
                    cityState: contact.city == "" ? "has-success" : result === true ? "has-danger" : "has-success",
                    contact: { ...contact },
                });
                return true;
                break;
            case 'state':
                result = containsSpecialCharacter(e.target.value);
                this.setState({
                    ...this.state,
                    stateState: contact.state == "" ? "has-success" : result === true ? "has-danger" : "has-success",
                    contact: { ...contact },
                });
                return true;
                break;

            default:
                break;
        }

        this.setState({
            ...this.state,
            // contactNameState: contact.contactNameState === "" ? "has-danger" : "has-success",
            contact: { ...contact }
        })
    }

    onChangeNumber(e, stateName) {
        let contact = { ...this.state.contact };
        contact[e.target.name] = e.target.value.replace(/\D/, '');
        var validState = (contact[e.target.name].trim() !== "" && contact[e.target.name].length < 10) === true || isNaN(contact[e.target.name]) === true ? false : true;

        if (validState === true) {
            this.setState({
                ...this.state,
                contact: contact,
                [stateName + "State"]: "has-success"
            });
        } else {
            this.setState({
                ...this.state,
                contact: contact,
                [stateName + "State"]: "has-danger"
            });
        }
    }
    onChangeOfficeExt = (e) => {
        let contact = { ...this.state.contact };
        contact[e.target.name] = e.target.value.replace(/\D/, '');

        this.setState({
            ...this.state,
            contact: contact,
        });
    }

    handleSelect = (e) => {
        switch (e.target.name) {

            case "pid":
                this.setState({ pidState: "has-success" });
                break;
            case "cid":
                this.setState({ companyState: "has-success" });
                break;
            default:
                break;
        }

        let contact = this.state.contact;

        contact[e.target.name] = parseInt(e.target.value);

        this.setState({
            ...this.state,
            contact: { ...contact }
        });
    }

    handleSubmit = () => {
        let { contact, projectState, companyState, } = this.state;
        let result = containsSpecialCharacter(contact.contactName);
        contact.contactName = allowOnlyOneBlankSpace(contact.contactName);
        var isValidContactName = containsOnlyCharacters(contact.contactName);
        var validContactMobile = (contact.contactMobile !== "" && contact.contactMobile.length < 10) === true || isNaN(contact.contactMobile) === true ? false : true;
        var validContactOfficePhone = (contact.contactOfficePhone !== "" && contact.contactOfficePhone.length < 10) === true ? false : true;
        var invalidCity = false;
        var invalidContactState = false;

        if (contact.city !== "") {
            if (!contact.city.replace(/\s/g, '').length || containsSpecialCharacter(contact.city)) {
                invalidCity = true;
            }
        }
        if (contact.state !== "") {
            if (!contact.state.replace(/\s/g, '').length || containsSpecialCharacter(contact.state)) {
                invalidContactState = true;
            }
        }

        if (contact.pid === null) {
            this.setState({ projectState: "has-danger" });
        }
        if (contact.cid === null) {
            this.setState({ companyState: "has-danger" });
        }
        if (contact.contactName.trim() === '' || (!isValidContactName)) {
            this.setState({ contactNameState: "has-danger" });
        }
        if (validContactMobile === false) {
            this.setState({ contactMobileState: "has-danger" });
        } else {
            this.setState({ contactMobileState: "has-success" });
        }
        if (validContactOfficePhone === false) {
            this.setState({ contactOfficePhoneState: "has-danger" });
        } else {
            this.setState({ contactOfficePhoneState: "has-suceess" });
        }
        var isValidEmail = true;
        if (contact.contactEmail !== "") {
            isValidEmail = verifyEmail(contact.contactEmail);
        }
        if (invalidCity === true) {
            this.setState({ cityState: "has-danger" });
        } else {
            this.setState({ cityState: "has-suceess" });
        }
        if (invalidContactState === true) {
            this.setState({ stateState: "has-danger" });
        } else {
            this.setState({ stateState: "has-success" });

        }

        if (contact.cid !== null && contact.pid !== null && contact.contactName.trim() !== '' && isValidEmail === true && isValidContactName === true && validContactMobile === true && validContactOfficePhone === true && invalidCity === false && invalidContactState === false) {
            contact.city = allowOnlyOneBlankSpace(contact.city);
            contact.state = allowOnlyOneBlankSpace(contact.state);

            this.props.onSubmit(this.state.contact, this.props.formType);
        }
    }





    render() {

        let countriesOptions = this.props.countries.map((country, index) => {
            return (<option key={index} value={country.id}>{country.country}</option>)
        });

        let {
            address1,
            address2,
            cid,
            city,
            contactEmail,
            contactExt,
            contactInfo,
            contactMobile,
            contactName,
            contactOfficePhone,
            country,
            id,
            isActive,
            pid,
            state
        } = this.state.contact;
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="contact-modal modal-lg">
                    <ModalHeader toggle={this.props.toggle}>
                        <p className="contact-custom-modal-title ">
                            {this.props.formType === "Add" ? 'Add Project Contact' : 'Update Project Contact'}
                        </p>
                    </ModalHeader>
                    <ModalBody>
                        <>

                            <div className="content raise-ticket">
                                <Col className="mr-auto ml-auto" md="12">

                                    <form className="ticket-form custom-form">
                                        <Row className="justify-content-center">
                                            <Col className="" md="4">

                                                <FormGroup className={`form-group has-label ${this.state.contactNameState} `}>
                                                    <label>
                                                        Contact Name *
                                                    </label>
                                                    <Input
                                                        name="contactName"
                                                        type="text"
                                                        placeholder=""
                                                        value={contactName}
                                                        onChange={this.onChangeTextHandler}
                                                    />
                                                    {this.state.contactNameState === "has-danger" ? (
                                                        <label className="error">Please enter valid contact name!</label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className={`form-group has-label ${this.state.contactEmailState} `}>
                                                    <label>
                                                        Email
                                        </label>
                                                    <Input
                                                        name={"contactEmail"}
                                                        style={{ paddingLeft: 20 }}
                                                        placeholder=""
                                                        type="email"
                                                        onChange={this.onChangeTextHandler}
                                                        value={contactEmail}
                                                    />
                                                    {this.state.contactEmailState === "has-danger" ? (
                                                        <label className="error">Please enter valid email!</label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>


                                            <Col className="" md="4">
                                                <FormGroup>
                                                    <label id="tooltip209848">
                                                        <span style={{ display: 'block', marginBottom: 10, marginTop: 4 }} htmlFor="isActive"> Active </span>
                                                        <Switch
                                                            onChange={(e) => { this.handleIsActive(e) }}
                                                            name="isActive" id="isActive"
                                                            checked={this.state.isActive}
                                                            handleDiameter={30}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            height={20}
                                                            width={48} />
                                                    </label>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target="tooltip209848"
                                                        placement="right"
                                                    >
                                                        {this.state.isActive === true ? "Active" : "Inactive"}
                                                    </UncontrolledTooltip>
                                                </FormGroup>
                                            </Col>

                                            <Col md="4" >
                                                <FormGroup className={`form-group has-label ${this.state.contactMobileState} `}>
                                                    <label htmlFor="">
                                                        Mobile
                                                </label>
                                                    <Input
                                                        name="contactMobile"
                                                        placeholder=""
                                                        type="text"
                                                        maxLength={15}
                                                        onChange={(e) => { this.onChangeNumber(e, 'contactMobile') }}
                                                        value={contactMobile}
                                                    />
                                                    {this.state.contactMobileState === "has-danger" ? (
                                                        <label className="error">Please enter valid mobile number!</label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup className={`form-group has-label ${this.state.contactOfficePhoneState} `}>
                                                    <label htmlFor="contactOfficePhone">
                                                        Office Phone
                                        </label>
                                                    <Input
                                                        name={"contactOfficePhone"}
                                                        style={{ paddingLeft: 20 }}
                                                        placeholder=""
                                                        type="text"
                                                        maxLength={15}
                                                        value={contactOfficePhone}
                                                        // onChange={this.onChangeTextHandler}
                                                        onChange={(e) => { this.onChangeNumber(e, 'contactOfficePhone') }}
                                                    // onChange={(e) => { this.onChangeNumber(e, 'contactMobile') }}

                                                    />
                                                    {this.state.contactOfficePhoneState === "has-danger" ? (
                                                        <label className="error">Please enter valid contact number!</label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className="form-group">
                                                    <label htmlFor="contactExt">
                                                        Office Ext.
                                                </label>
                                                    <Input
                                                        name="contactExt"
                                                        placeholder=""
                                                        type="text"
                                                        // onChange={e => this.change(e, "contactExt", "length", 0)}
                                                        onFocus={e => this.setState({ contactExtFocus: true })}
                                                        onBlur={e => this.setState({ contactExtFocus: false })}
                                                        onChange={this.onChangeOfficeExt}
                                                        value={contactExt}
                                                        maxLength={6}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col className="" md="12">
                                                <FormGroup className="form-group">
                                                    <label htmlFor="contactInfo">
                                                        Contact Info
                                        </label>
                                                    <Input
                                                        name="contactInfo"
                                                        placeholder=""
                                                        type="textarea"
                                                        onChange={this.onChangeTextHandler}
                                                        value={contactInfo}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="" md="12">
                                                <FormGroup className="form-group">
                                                    <label htmlFor="address1">
                                                        Address Line1
                                        </label>
                                                    <Input
                                                        name="address1"
                                                        placeholder=""
                                                        type="text"

                                                        onChange={this.onChangeTextHandler}
                                                        value={address1}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col className="" md="12">
                                                <FormGroup className="form-group">
                                                    <label htmlFor="address2">
                                                        Address Line 2
                                            </label>
                                                    <Input
                                                        name="address2"
                                                        placeholder=""
                                                        type="text"
                                                        onChange={this.onChangeTextHandler}
                                                        value={address2}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">

                                                <FormGroup className={`form-group has-label ${this.state.cityState} `}>
                                                    <label htmlFor="city">
                                                        City
                                        </label>
                                                    <Input
                                                        name="city"
                                                        style={{ paddingLeft: 20 }}
                                                        placeholder=""
                                                        type="text"
                                                        // onChange={this.onChangeTextHandler}
                                                        onChange={(e) => { this.onChangeTextHandler(e, 'city') }}
                                                        value={city}
                                                    />
                                                    {this.state.cityState === "has-danger" ? (
                                                        <label className="error">Please enter valid city!</label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup className={`form-group has-label ${this.state.stateState} `}>
                                                    <label htmlFor="state">
                                                        State
                                        </label>
                                                    <Input
                                                        name="state"
                                                        placeholder=""
                                                        type="text"
                                                        value={state}
                                                        // onChange={this.onChangeTextHandler}
                                                        onChange={(e) => { this.onChangeTextHandler(e, 'state') }}
                                                    />
                                                    {this.state.stateState === "has-danger" ? (
                                                        <label className="error">Please enter valid state!</label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">

                                                <FormGroup className="form-group">
                                                    <label htmlFor="country">
                                                        Country
                                        </label>
                                                    <Input type="select" name="country" defaultValue={country} onChange={this.handleSelect} id="country">
                                                        {countriesOptions}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col >
                                            </Col>


                                            {/* <Col className=""  md="12">
                                                <FormGroup>
                                                    <label>
                                                        <span style={{ display: 'block', marginBottom: 10, marginTop: 4 }} htmlFor="isActive"> Active </span>
                                                        <Switch
                                                            onChange={(e) => { this.handleIsActive(e) }}
                                                            name="isActive" id="isActive"
                                                            checked={this.state.isActive}
                                                            handleDiameter={30}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            height={20}
                                                            width={48} />
                                                    </label>
                                                </FormGroup>
                                            </Col> */}

                                        </Row>
                                        <Row className="justify-content-center mt-3">
                                            {
                                                this.state.isLoading ? (
                                                    <Button type="submit" color="primary" className="btn-submit btn-ticket-submit">
                                                        <Spinner size="sm" />
                                                    </Button>

                                                ) : (
                                                        <Button color="info" size="sm" onClick={this.handleSubmit} className="btn-submit btn-ticket-submit">Submit</Button>
                                                    )
                                            }
                                        </Row>
                                    </form>
                                </Col></div>

                        </>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}


export default ProjectContactModal
