import React, { Component } from "react";
import Switch from "react-switch";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { REQUIRD_MESSAGE } from "../../../variables/constants";
import { getAllCurrencies } from "../../../redux/actions/currenciesAction";

class CompanyContactModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'Add',
            contact: {
                id: 0,
                cid: 1,
                fname: '',
                lname: '',
                emailOfficial: '',
                emailPersonal: '',
                mobile: '',
                extension: '',
                officePhone: '',
                isActive: 'Y',
                isPrimary: 'Y'
            },
            cid: 0,
            fnameState: '',
            lnameState: '',
            emailPersonalState: '',
            emailOfficialState: '',
            mobileState: '',
        };
    }



    UNSAFE_componentWillMount() {
        if (this.props.formType === "Update") {
            this.setState({
                formType: this.props.formType,
                contact: { ...this.props.contact },
                cid: this.props.cid,
                fnameState: "has-success",
                lnameState: "has-success",
                emailOfficialState: "has-success",
                emailPersonalState: "has-success",
                mobileState: "has-success"
            });
        } else {
            this.setState({
                formType: this.props.formType,
                contact: {
                    ...this.state.contact,
                    cid: this.props.cid
                }
            });
        }
    }


    handleIsActive = (e) => {
        if (e === true) {
            this.setState({
                contact: {
                    ...this.state.contact,
                    isActive: 'Y'

                }
            });
        }
        else {
            this.setState({
                contact: {
                    ...this.state.contact,
                    isActive: 'N'

                }
            });
        }
    }

    handleIsPrimary = (e) => {
        if (e === true) {
            this.setState({
                contact: {
                    ...this.state.contact,
                    isPrimary: 'Y'

                }
            });
        }
        else {
            this.setState({
                contact: {
                    ...this.state.contact,
                    isPrimary: 'N'

                }
            });
        }
    }

    handleSubmit = async () => {
        let checkContactExists = false;
        let isUpdatedContactAlreadyExist = false;
        if (this.props.formType === "Update") {
            let alreadyExist = this.props.contacts.filter(contact => contact.emailOfficial.toUpperCase() === this.state.contact.emailOfficial.trim().toUpperCase())
            isUpdatedContactAlreadyExist = alreadyExist.length > 1 ? true : false;
            let contactCount = 0;

            this.props.contacts && this.props.contacts.map((contact) => {

                if (contact.emailOfficial.toUpperCase() === this.state.contact.emailOfficial.trim().toUpperCase() && contact.id !== this.props.contact.id) {

                    contactCount = contactCount + 1;
                }
            });

            if (contactCount >= 1) {
                isUpdatedContactAlreadyExist = true;
            }

        } else {
            checkContactExists = (this.props.contacts.findIndex(contact => contact.emailOfficial.toUpperCase() === this.state.contact.emailOfficial.trim().toUpperCase()) > -1 ? true : false)

        }
        if (
            this.state.fnameState === "has-success" &&
            this.state.lnameState === "has-success" &&
            this.state.emailOfficialState === "has-success" &&
            this.state.emailPersonalState === "has-success" &&
            this.state.mobileState === "has-success" &&
            checkContactExists === false &&
            isUpdatedContactAlreadyExist === false
        ) {
            let { id,
                cid,
                fname,
                lname,
                mname,
                mobile,
                officePhone,
                emailPersonal,
                emailOfficial,
                extension,
                isActive,
                isPrimary } = this.state.contact

            let contactObj = {
                id,
                cid,
                fname,
                lname,
                mname,
                mobile,
                officePhone,
                emailPersonal,
                emailOfficial,
                extension,
                isActive,
                isPrimary
            }

            // let formType = this.props.formType;
            this.props.onSubmit(contactObj);
        } else {
            if (this.state.fnameState !== "has-success") {
                this.setState({ fnameState: "has-danger" });
            }
            if (this.state.lnameState !== "has-success") {
                this.setState({ lnameState: "has-danger" });
            }
            if (this.state.emailOfficialState !== "has-success" || checkContactExists === true || isUpdatedContactAlreadyExist === true) {
                this.setState({ emailOfficialState: "has-danger" });
            }
            if (this.state.emailPersonalState !== "has-success") {
                this.setState({ emailPersonalState: "has-danger" });
            }
            if (this.state.mobileState !== "has-success") {
                this.setState({ mobileState: "has-danger" });
            }
        }


    }

    handleSelectStatus = (e) => {
        if (e.target.value === '') {
            this.setState({ [e.target.name + "State"]: "has-danger" });
        } else {
            this.setState({ [e.target.name + "State"]: "has-success" });
        }

        let contact = this.state.contact;
        if (e.target.name === 'country') {
            contact[e.target.name] = parseInt(e.target.value);
        } else {
            contact[e.target.name] = e.target.value;
        }
        this.setState({
            contact: { ...contact }
        })
    };

    keyDown = (event) => {

        if (event.key === '.' || event.key === '+' || event.key === '-' || event.key === 'e') {
            event.preventDefault();
        }
    };

    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            if (value.startsWith(' ')) {
                return false;
            }
            return true;
        }
        return false;
    };

    verifyLength = (value, length, stateName) => {
        if (stateName === "mobile") {
            if (value.length < length) {
                return false;
            } else {
                if (value.length > length) {
                    return false;
                }
                return true;
            }
        }
        if (stateName === "fname" || stateName === "lname") {
            var lengthRex = /^[A-Za-z]*$/;
            if (lengthRex.test(value)) {
                if (value.startsWith(' ') || value === '') {
                    return false;
                }
                return true;
            }
            return false;
        }
        if (stateName === "emailPersonal" || stateName === "emailOfficial") {
            var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (emailRex.test(value)) {
                if (value.startsWith(' ') || value === '') {
                    return false;
                }
                return true;
            }
            return false;
        }
        return true;

    };

    change = (event, stateName, type, stateNameEqualTo, maxValue) => {

        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "length":

                if (this.verifyLength(event.target.value, stateNameEqualTo, stateName)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            default:
                break;
        }
        let contact = this.state.contact;

        contact[event.target.name] = event.target.value;
        this.setState({
            contact: contact
        });

    };

    onChangeTextHandler = (e, type, stateName, maxValue) => {

        switch (type) {
            case "email":
                if (this.verifyEmail(e.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "length":

                if (this.verifyLength(e.target.value, maxValue, stateName)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            default:
                break;
        }

        let contact = this.state.contact;

        contact[e.target.name] = e.target.value;
        if (e.target.value === '') {
            this.setState({ [e.target.name + "State"]: "has-danger" });
        } else {
            this.setState({ [e.target.name + "State"]: "has-success" });
        }
        this.setState({
            contact: contact
        });

    }

    render() {
        let { companyNameState, fnameState, lnameState, emailOfficialState, emailPersonalState, mobileState } = this.state;
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="custom-modal " style={{
                    width: "100%",
                    maxWidth: 700
                }} >
                    <ModalHeader toggle={this.props.toggle}><h4>
                        {this.props.formType === "Update" ? "Update Company Contact" : "Add Company Contact"}
                    </h4></ModalHeader>
                    <ModalBody>
                        <div className="content raise-ticket">
                            <Col className="mr-auto ml-auto" md="12">
                                <Form className="custom-form">
                                    <Row className="justify-content-center">     {/* className="justify-content-center"*/}
                                        <Col sm="5">
                                            <FormGroup className={`has-label ${companyNameState}`}>
                                                <Label>First Name*</Label>
                                                <Input
                                                    name="fname"
                                                    type="text"
                                                    placeholder="First name"
                                                    value={this.state.contact.fname}
                                                    onChange={e => { this.change(e, 'fname', 'length', 0) }}
                                                />
                                                {fnameState === "has-danger" ? (
                                                    <label className="error">{REQUIRD_MESSAGE}</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="5">
                                            <FormGroup className={`has-label ${companyNameState}`}>
                                                <Label>Middle Name</Label>
                                                <Input
                                                    name="mname"
                                                    type="text"
                                                    placeholder="Middle name"
                                                    value={this.state.contact.mname}
                                                    onChange={e => { this.change(e, 'mname', 'length', 0) }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="5">
                                            <FormGroup className={`has-label ${companyNameState}`}>
                                                <Label>Last Name*</Label>
                                                <Input
                                                    name="lname"
                                                    type="text"
                                                    placeholder="Last name"
                                                    value={this.state.contact.lname}
                                                    onChange={e => { this.change(e, 'lname', 'length', 0) }}
                                                />
                                                {lnameState === "has-danger" ? (
                                                    <label className="error">{REQUIRD_MESSAGE}</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="5">
                                            <FormGroup className={`has-label ${companyNameState}`}>
                                                <label htmlFor="password">
                                                    Personal Email*
                                                </label>
                                                <Input
                                                    name="emailPersonal"
                                                    style={{ paddingLeft: 20 }}
                                                    placeholder="personal email"
                                                    type="email"
                                                    onChange={e => this.change(e, "emailPersonal", "length", 0)}
                                                    onFocus={e => this.setState({ emailFocus: true })}
                                                    onBlur={e => this.setState({ emailFocus: false })}
                                                    value={this.state.contact.emailPersonal}
                                                />
                                                {emailPersonalState === "has-danger" ? (
                                                    <label className="error">Enter a valid email id</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="5">
                                            <FormGroup className={`has-label ${companyNameState}`}>
                                                <Label>Official Email*</Label>
                                                <Input
                                                    name="emailOfficial"
                                                    type="email"
                                                    placeholder="official email"
                                                    value={this.state.contact.emailOfficial}
                                                    onChange={e => { this.change(e, 'emailOfficial', 'length', 0) }}
                                                />
                                                {emailOfficialState === "has-danger" ? (
                                                    <label className="error">Enter a valid and unique email id</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="5">
                                            <FormGroup className={`has-label ${companyNameState}`}>
                                                <Label>Mobile*</Label>
                                                <Input
                                                    name="mobile"
                                                    type="number"
                                                    placeholder="mobile number"
                                                    value={this.state.contact.mobile}
                                                    onKeyDown={e => this.keyDown(e)}
                                                    onChange={e => { this.change(e, 'mobile', 'length', 10) }}
                                                />
                                                {mobileState === "has-danger" ? (
                                                    <label className="error">Enter a valid 10 digit no.</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>

                                        <Col sm="5">
                                            <FormGroup className={`has-label ${companyNameState}`}>
                                                <Label>Office Extension</Label>
                                                <Input
                                                    name="extension"
                                                    type="number"
                                                    placeholder="office extension"
                                                    value={this.state.contact.extension}
                                                    onKeyDown={e => this.keyDown(e)}
                                                    onChange={e => { this.change(e, 'extension', 'length', 0) }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="5">
                                            <FormGroup className={`has-label ${companyNameState}`}>
                                                <Label>Office Number</Label>
                                                <Input
                                                    name="officePhone"
                                                    type="number"
                                                    placeholder="office Phone"
                                                    value={this.state.contact.officePhone}
                                                    onKeyDown={e => this.keyDown(e)}
                                                    onChange={e => { this.change(e, 'officePhone', 'length', 0) }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="5">

                                            <FormGroup >
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                                                    <div>
                                                        <Label >Active</Label>
                                                    </div>
                                                    <div style={{ marginRight: '100px' }}>
                                                        <Switch
                                                            onChange={(e) => { this.handleIsActive(e) }}
                                                            name="isActive"
                                                            id="isActive"
                                                            checked={this.state.contact.isActive === 'Y' ? true : false}
                                                            handleDiameter={30}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            height={20}
                                                            width={48} />
                                                    </div>
                                                </div>
                                            </FormGroup>

                                        </Col>
                                        <Col sm="5">

                                            <FormGroup >
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                                                    <div>
                                                        <Label >Primary Contact</Label>
                                                    </div>
                                                    <div style={{ marginRight: '38px' }}>
                                                        <Switch
                                                            onChange={(e) => { this.handleIsPrimary(e) }}
                                                            name="isPrimary"
                                                            id="isPrimary"
                                                            checked={this.state.contact.isPrimary === 'Y' ? true : false}
                                                            handleDiameter={30}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            height={20}
                                                            width={48} />
                                                    </div>
                                                </div>
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button size="sm" color="info" onClick={this.handleSubmit}>{this.props.formType}</Button>{' '}
                            {/* {this.props.formType === 'Update' ? <Button
                            size="sm"
                            color="danger"
                            onClick={() =>{this.props.deleteCompany(this.state.company.id)} }
                        >
                            Delete
                        </Button>: <React.Fragment />} */}
                            <Button size="sm" color="secondary" onClick={this.props.close}>Cancel</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

}

export const mapDispatchToProps = dispatch => ({
    getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch)
});


export const mapStateToProps = ({ currencies }) => ({
    currencies: currencies
});
export default connect(mapStateToProps, mapDispatchToProps)(CompanyContactModal);