import moment from "moment";
import React from "react";
import ReactDatetime from "react-datetime";
import { Button, Col, FormGroup, Input, Row, Spinner } from "reactstrap";
import Select from "react-select";
import NotificationAlert from "react-notification-alert";
import { dateFormatList } from "variables/constants";


class LeaveDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: "",
            endDate: "",
            duration: "",
            leaveDays: "",
            leaveType: "",
            reason: "",
            startDateState: "",
            endDateState: "",
            durationState: "",
            leaveDaysState: "",
            leaveTypeState: "",
            reasonState: "",
            isGreaterDate:false,
            isLesserDate:false,
            metadata: {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                duration: "duration",
                leaveDays: "leaveDays",
                leaveType: "leaveType",
                reason: "reason (max 100 characters)",
            },
            isLoading: false
        };
    }

    notify = (place, color, message) => {
        // var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };


    showNotifications = (color, message) => {
        this.notify("tr", color, message);
    }

    // function that returns true if value is email, false otherwise
    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };
    // function that verifies if a string has a given length or not
    verifyLength = (value, stateName, length) => {
        if (stateName === 'reason') {
            //var lengthRex = /^[A-Za-z]*$/;
            var lengthRex = /^[a-zA-Z0-9\s,'-]*$/;
            if (lengthRex.test(value)) {
                if (value.startsWith(' ') || value === '') {
                    return false;
                }
                return true;
            }
            return false;
        }
        if (value.length <= length) {
            return false;
        }
        return true;
    };


    change = (event, stateName, type, stateNameEqualTo, maxValue) => {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "length":

                if (this.verifyLength(event.target.value, stateName, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    };

    selectValue = (e) => {

        let finalvalue = "";
        let value = e.target.value;

        if (this.state.leaveDays !== 0 && this.state.leaveDays > 1) {
            finalvalue = "F"
        } else {
            if (value === "H") {
                this.setState({
                    leaveDays: 0.5
                })
            }
            else {
                this.setState({
                    leaveDays: 1
                })
            }
            finalvalue = value
        }



        this.setState({ duration: finalvalue });
    };

    handleSelect = (e) => {
        this.setState({
            leaveType: e.target.value
        })
    }

    handleStartDate = (date) => {
        let start = new Date(date);
        let end = new Date(this.state.endDate);
        if(moment(start).format('YYYY-MM-DD') > moment(end).format('YYYY-MM-DD')){
            this.setState({
                isGreaterDate:true,
                startDate: moment(date).format('YYYY-MM-DD')
            });
        }else{
        this.setState({
            startDateState: moment(date).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
            startDate: moment(date).format('YYYY-MM-DD'),
            isGreaterDate:false,
            isLesserDate:false,
        });
    }
    }

    isWeekendOrHoliday = (date) => {
        const day = date.getDay(); // 0 = Sunday, 6 = Saturday
        const formattedDate = moment(date).format("YYYY-MM-DD"); // Convert to 'YYYY-MM-DD'
        return day === 0 || day === 6 || this.props.holidayList.includes(formattedDate);
      };
    
    handleEndDate = (date) => {
        let diff = 0
        let start = new Date(this.state.startDate);
        let end = new Date(date);
        if(moment(start).format('YYYY-MM-DD') > moment(end).format('YYYY-MM-DD')){
            this.setState({
                isLesserDate:true,
                endDate: moment(date).format('YYYY-MM-DD')
            });
        }else if (this.state.startDate !== "") {
            // let date1 = new Date(date);
            // let date2 = new Date(this.state.startDate);

            // if (moment(date2).format('YYYY-MM-DD') <= moment(date1).format('YYYY-MM-DD')) {
            //     if (moment(date2).format('YYYY-MM-DD') !== moment(date1).format('YYYY-MM-DD')) {
            //         const diffTime = Math.abs(date2 - date1);
            //         diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            //         diff = diff + 1

            //     }
            //     else { diff = 1 }

            //     this.setState({
            //         endDateState: moment(date).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
            //         endDate: moment(date).format('YYYY-MM-DD'),
            //         leaveDays: diff
            //     });
            //     this.setState({
            //         duration: "F"
            //     })
            // }
            // else {
            //     this.showNotifications(3, "Improper input. Please check the seleced value!");
            // }

            let date1 = new Date(date); // Convert input date to Date object
            let date2 = new Date(this.state.startDate); // Convert startDate to Date object
        
            // Ensure end date is on or after start date
            if (moment(date2).format("YYYY-MM-DD") <= moment(date1).format("YYYY-MM-DD")) {
              let diff = 1; // Initialize diff to 1 (to include the start date itself)
        
              // If start and end dates are not the same
              if (moment(date2).format("YYYY-MM-DD") !== moment(date1).format("YYYY-MM-DD")) {
                let currentDate = new Date(date2); // Start looping from the start date
                let count = 0; // Counter for valid leave days
        
                while (currentDate <= date1) {
                  if (!this.isWeekendOrHoliday(currentDate)) {
                    count++; // Count the valid leave day
                  }
                  currentDate.setDate(currentDate.getDate() + 1); // Move to next day
                }        
                diff = count + 1; // Set final valid leave days count
              }
        
              // Update the state with calculated leave days and validation status
              this.setState({
                endDateState: moment(date).format("YYYY-MM-DD") === "" ? "has-danger" : "has-success",
                endDate: moment(date).format("YYYY-MM-DD"),
                leaveDays: diff,
                isLesserDate:false,
                isGreaterDate:false,
                duration: "F",
              });
            }
        }
    }


    isValidated = async () => {
        let checkLeaveExists = false;
        let isUpdatedLeaveAlreadyExist = false;
        if (this.state.isGreaterDate === true || this.state.isLesserDate === true) {
              this.setState({
                isLoading: false
            })
        }else{
        if (this.props.formtype === "update") {
            // let alreadyExist = this.props.clients.filter(clitem => clitem.name.toUpperCase() === this.state.clientname.trim().toUpperCase())
            // isUpdatedClientAlreadyExist = alreadyExist.length > 1 ? true : false;
            // let clientCount = 0;

            //  let result = this.props.leaves && this.props.leaves.map(async (leave) => {

            //     if ( leave.id !== this.props.leave.leaveId) {
            //         let result = await this.props.checkLeaveExists(this.props.userData.cid, this.props.userData.eid, this.state.endDate, this.state.startDate);
            //        
            //         if(result.data){
            //             isUpdatedLeaveAlreadyExist = true;
            //         }
            //     }
            // });

            if (moment(this.props.leave.startDate).format('YYYY-MM-DD') !== this.state.startDate ||
                moment(this.props.leave.endDate).format('YYYY-MM-DD') !== this.state.endDate) {
                let result = await this.props.checkLeaveExists(this.props.userData.cid, this.props.userData.eid, this.state.endDate, this.state.startDate);

                if (result.data) {
                    isUpdatedLeaveAlreadyExist = true;
                }
            }
            //
            // if (clientCount >= 1) {
            //     isUpdatedClientAlreadyExist = true;
            // }

        } else {
            let response = await this.props.checkLeaveExists(this.props.userData.cid, this.props.userData.eid, this.state.endDate, this.state.startDate);

            if (response.data) {
                checkLeaveExists = true;
            }
        }

        //
        if (checkLeaveExists === false && isUpdatedLeaveAlreadyExist === false) {
            if (
                this.state.startDateState === "has-success" &&
                this.state.endDateState === "has-success" &&
                //this.state.durationState === "has-success" &&
                //this.state.leaveDaysState=== "has-success"  &&
                this.state.reasonState === "has-success"
            ) {
                let id = 0;
                this.props.formtype === 'update' ? id = this.props.leave.id : id = 0;
                let leaveDetails = {
                    cid: this.props.userData.cid,
                    duration: this.state.duration,
                    eid: this.props.userData.eid,
                    endDate: this.state.endDate,
                    id: id,
                    isDelete: "N",
                    leaveDate: "string",
                    leaveReason: this.state.reason,
                    leaveStatus: "Draft",
                    leaveType: this.state.leaveType,
                    noOfDays: this.state.leaveDays,
                    rejectionDate: "2020-04-10T04:40:10.365Z",
                    rejectionReason: "string",
                    startDate: this.state.startDate,
                }
                this.props.addUpdateLeave(leaveDetails, this.props.formtype);

                this.setState({
                    isLoading: false
                })
                return true;
            } else {
                if (this.state.startDateState !== "has-success") {
                    this.setState({ startDateState: "has-danger" });
                }
                if (this.state.endDateState !== "has-success") {
                    this.setState({ endDateState: "has-danger" });
                }
                if (this.state.reasonState !== "has-success") {
                    this.setState({ reasonState: "has-danger" });
                }
                this.setState({
                    isLoading: false
                })
                return true;
            }
        } else {
            this.props.handleLeaveAlreadyExisits();
        }
    }
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true
        })
        await this.isValidated();
    }




    async UNSAFE_componentWillMount() {
        if (this.props.formtype === "update") {
            let duration = ""

            //this.props.duration === "F" ? duration = 'Full day' : duration = 'Half day'
            this.setState({
                startDate: moment(this.props.leave.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.props.leave.endDate).format('YYYY-MM-DD'),
                duration: this.props.leave.duration,
                leaveDays: this.props.leave.noOfDays,
                leaveType: this.props.leave.leaveType,
                reason: this.props.leave.leaveReason,
                startDateState: "has-success",
                endDateState: "has-success",
                durationState: "has-success",
                leaveDaysState: "has-success",
                leaveTypeState: "has-success",
                reasonState: "has-success",

            })
        } else {
            this.setState({
                startDate: "",
                endDate: "",
                duration: "",
                leaveDays: "",
                leaveType: "",
                reason: "",
            })
        }
    }

    render() {
        let metadata = this.state.metadata;
        let leaveTypes = this.props.leaveTypes.map((leaveType, index) => {
            return (<option key={index} value={leaveType.id}>{leaveType.type}</option>)
        });

        const durations = [
            { value: "F", label: " Full day " },
            { value: "H", label: " Half day " }
        ];
        let durationValues = durations.map((duration, index) => {
            return (<option key={index} value={duration.value}>{duration.label}</option>)
        })

        var lastMonth = moment().subtract(1, 'months').date(1);
        var valid = function (current) {
            return moment(moment(current).format('YYYY-MM-DD')).isSameOrAfter(moment(lastMonth).format('YYYY-MM-DD'));
        };
        var disableLeaves = (current) => {
            if (!current || !this.props.holidayList) return true; // Ensure date and holiday list exist

            const formattedDate = current.format("YYYY-MM-DD");
            const isHoliday = this.props.holidayList.some(date => date === formattedDate);
            const isWeekend = current.day() === 0 || current.day() === 6; // 0 = Sunday, 6 = Saturday
        
        
            return !(isHoliday || isWeekend); // Disable if it's a holiday OR a weekend
        };
        return (
            <>
                <NotificationAlert ref="notificationAlert" />
                <div className="content raise-ticket">
                    <Col className="mr-auto ml-auto" md="12">

                        <div className="text-header text-center">Leave Application Form</div>
                        {/* <div className="text-center">Current Leave Balance: {this.props.leave.leaveBalance}</div> */}
                        <form onSubmit={this.handleSubmit} className="ticket-form">
                            <Row className="justify-content-center">
                                <Col sm="5">

                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Start Date*
                                        </label>
                                        <ReactDatetime
                                            timeFormat={false}
                                            inputProps={{ placeholder: metadata.startDate, className: "form-control", value: this.state.startDate !== "" ? moment(this.state.startDate).format(dateFormatList[2]) : this.state.startDate }}
                                            onChange={this.handleStartDate}
                                            value={this.state.startDate}
                                            closeOnSelect={true}
                                            isValidDate={disableLeaves}
                                        />
                                        {this.state.startDateState === "has-danger" ? (
                                            <label className="error">This field is required.</label>
                                        ) : this.state.isGreaterDate === true ? (
                                            <p className="error">Start date should not be greater than end date.</p>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            End Date*
                                        </label>
                                        <ReactDatetime
                                            timeFormat={false}
                                            inputProps={{ placeholder: metadata.endDate, className: "form-control", value: this.state.endDate !== "" ? moment(this.state.endDate).format(dateFormatList[2]) : this.state.endDate }}
                                            onChange={this.handleEndDate}
                                            closeOnSelect={true}
                                            isValidDate={disableLeaves}
                                            minDate={this.state.startDate}
                                        />
                                        {this.state.endDateState === "has-danger" ? (
                                            <label className="error client_error">This field is required.</label>
                                        ) : this.state.isLesserDate === true ? (
                                            <p className="error">End date should not be lesser than start date.</p>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col className="" sm="5">

                                    <FormGroup>
                                        <label htmlFor="password">
                                            Duration*
                                        </label>
                                        <Input type="select" name="duration" value={this.state.duration} onChange={e => { this.selectValue(e) }} id="duration">
                                            {durationValues}
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col className="" sm="5">

                                    <FormGroup>
                                        <label htmlFor="password">
                                            Leave Days*
                                        </label>
                                        <Input
                                            name={"leaveDays"}
                                            placeholder={metadata.leaveDays + " (required)"}
                                            disabled={true}
                                            type="text"
                                            onChange={e => this.change(e, "leaveDays", "length", 0)}
                                            onFocus={e => this.setState({ leaveDaysFocus: true })}
                                            onBlur={e => this.setState({ leaveDaysFocus: false })}
                                            value={this.state.leaveDays}
                                        />

                                    </FormGroup>
                                </Col>

                                <Col className="" sm="10">

                                    <FormGroup>
                                        <label htmlFor="password">
                                            Leave Type*
                                        </label>
                                        <Input type="select" name="country" defaultValue={this.state.leaveType} onChange={this.handleSelect} id="exampleSelect">
                                            {leaveTypes}
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col className="subscriber" sm="10">
                                    <FormGroup>
                                        <label htmlFor="password">
                                            Leave Reason*
                                        </label>
                                        <Input
                                            name={"reason"}
                                            placeholder={metadata.reason}
                                            type="textarea"
                                            maxLength="100"
                                            onChange={e => this.change(e, "reason", "length", 0)}
                                            onFocus={e => this.setState({ reasonFocus: true })}
                                            onBlur={e => this.setState({ reasonFocus: false })}
                                            value={this.state.reason}
                                        />
                                        {this.state.reasonState === "has-danger" ? (
                                            <label className="error client_error">This field is required.</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                {
                                    this.props.leave.leaveStatus === "Rejected" ? (
                                        <Col className="subscriber" sm="10">
                                            <div>Rejection Reason:</div>
                                            <p>
                                                {this.props.leave.rejectionReason}
                                            </p>
                                        </Col>
                                    ) : null
                                }

                            </Row>

                            <Row className="justify-content-center mt-3">
                                {
                                    this.state.isLoading ? (
                                        <Button disabled="true" color="info" className="btn-submit btn-ticket-submit">
                                            <Spinner size="sm" />
                                        </Button>

                                    ) : (
                                        <div>
                                            {/*  <Button size="sm" type="submit" color="info" className="btn-submit btn-ticket-submit">Submit</Button> */}
                                            <Button size="sm" type="submit" color="info" className="btn-submit btn-ticket-submit">Save</Button>

                                        </div>

                                    )
                                }
                            </Row>
                        </form>
                    </Col></div>

            </>
        );
    }
}


export default LeaveDetailsForm
