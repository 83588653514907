import { FETCH_MANAGER_TIMESHEET_WEEKLY_REPORT, FETCH_MANAGER_TIMESHEET_QUARTERLY_REPORT, FETCH_MANAGER_TIMESHEET_MONTHLY_REPORT, FETCH_INITIAL_MANAGER_REPORT_DATA, FETCH_MANAGER_PROJECT_MARGIN_REPORT } from "../actions/managerTimesheetReportAction";
// import {  } from "../actions/timesheetReportAction";
const INITIAL_STATE = {
    timesheetWeeklyReport: [],
    timesheetMonthlyReport: [],
    timesheetQuarterlyReport: [],
    timesheetManagerProjectMarginReport: []
}
const managerTimesheetReportReducer = function (state = INITIAL_STATE, action) {
    let newState;

    switch (action.type) {
        case FETCH_MANAGER_TIMESHEET_WEEKLY_REPORT:
            newState = { ...state }
            newState.timesheetWeeklyReport = action.timesheetWeeklyReport;
            return newState;

        case FETCH_MANAGER_TIMESHEET_MONTHLY_REPORT:
            newState = { ...state };
            newState.timesheetMonthlyReport = [...action.timesheetMonthlyReport];
            return newState;

        case FETCH_MANAGER_TIMESHEET_QUARTERLY_REPORT:
            newState = { ...state }
            newState.timesheetQuarterlyReport = [...action.timesheetQuarterlyReport];
            return newState;
        case FETCH_INITIAL_MANAGER_REPORT_DATA:
            newState = {
                timesheetWeeklyReport: [],
                timesheetMonthlyReport: [],
                timesheetQuarterlyReport: []
            }
            return newState;

        case FETCH_MANAGER_PROJECT_MARGIN_REPORT:
            newState = { ...state }
            newState.timesheetManagerProjectMarginReport = [...action.managerProjectMarginReport];
            return newState;

        default:
            return state;
    }
};

export default managerTimesheetReportReducer;