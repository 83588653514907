import {getAllNotificationsAPI} from "../../services/notification-service";

export const FETCH_ALL_NOTIFICATIONS = 'FETCH_ALL_NOTIFICATIONS';

export const getAllNotifications = (cid,eid) =>  {
    return async (dispatch) => {
        const response = await getAllNotificationsAPI(cid,eid);
            if (response.data){
                dispatch({
                    type: FETCH_ALL_NOTIFICATIONS,
                    notifications: response.data
                  });
                return { success: true };
            }
            else{
                return { success: false};
            }    
        }
}