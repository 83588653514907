import axios from 'axios';

export const getAllEmployeesByManagerAPI = (eid, cid) => {
  return axios.get(`/api/v1/employees-by-manager`, {
    params: {
      eid: eid,
      cid: cid
    }
  })
  .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const getManagerQuarterlyInvoicesAPI = (cid, eid, managerId, month, quarter, year) => {
  let quarterlyInvoiceObject = {
    cid: cid,
    eid: eid,
    managerId: managerId,
    month: 0,
    quarter: quarter,
    year: parseInt(year)
  };
  return axios.post(`/api/v1/quarterly-invoice`, quarterlyInvoiceObject)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getManagerMonthlyInvoicesAPI = (cid, eid, managerId, month, quarter, year) => {
  let monthlyInvoiceObject = {
    cid: cid,
    eid: eid,
    managerId: managerId,
    month: parseInt(month),
    quarter: 0,
    year: parseInt(year)
  };
  return axios.post(`/api/v1/monthly-invoice`, monthlyInvoiceObject)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const sendProjectInvoiceAPI = (cid, invoiceId, managerId) => {

  return axios.get(`/api/v1/sent-invoices`, {
    params: {
      cid: cid,
      id: invoiceId,
      managerId: managerId
    }
  })
  .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const getEmployeesWithManagerRoleAPI = (cid) => {
  return axios.get(`/api/v1/employees-with-role-manager`, {
    params: {
      cid: cid
    }
  })
  .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}