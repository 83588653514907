
import React from "react";
import NotificationAlert from "react-notification-alert";
import ReactTable from "react-table";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Row
} from "reactstrap";
import Pagination from "../../../../components/common/pagination";
class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {},
      configurations: [],
      config: {
        id: 0,
        cid: 0,
        displayText: '',
        param: '',
        paramValue: '',
      },
      paginationState: '',
      holidayCountryState: '',
      timezoneState: '',
      multiRowsState: '',
      reminderState: '',
      gracePeriodState: ''
    };
  }
  async UNSAFE_componentWillMount() {

    //if(this.props.formType === 'Add')
    await this.props.getAllInitialCompanyConfigs(this.props.cid);
    this.setState({
      configurations: [...this.props.configs]
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

  }

  handleSelect = (e, prop) => {

    let configurations = [...this.state.configurations]
    const index = configurations.findIndex((config) => config.param === prop.param)
    if (index > -1) {
      configurations[index] = { ...configurations[index], paramValue: e.target.value, cid: this.props.cid };
      // let that = this;
      this.setState({
        configurations
      })
    }
    // else{
    //   configurations.push({...prop,paramValue:e.target.value,cid:this.props.cid})
    //   this.setState({
    //     configurations
    //   })
    // }

    //
    // let configuration = {...prop,
    //   [e.target.name]:e.target.value,
    //   cid:this.props.cid};
    //  
    //   this.setState({
    //     config:configuration
    //   })
  }

  onChangeTextHandler = (e, prop) => {
    let configuration = {
      ...prop,
      [e.target.name]: e.target.value,
      cid: this.props.cid
    }

    this.setState({
      config: configuration
    })
  }
  submitConfigToArray = () => {
    let configurations = this.state.configurations;
    let config = this.state.config;

    configurations.push(config);

    this.setState({ configurations: configurations })
  }



  isValidated = async () => {
    const config = this.state.configurations.filter((config) => config.added === true)
    let configurations = []
    this.state.configurations.map((config, key) => {
      config = { ...config, cid: this.props.cid }
      configurations.push(config)
    })


    this.props.submitConfigInfo(configurations);
    return true;

  };
  getItems = (param) => {
    let items = [];
    let countries = [];
    let currencies = [];
    this.props.companyLocations && this.props.companyLocations.length > 0 ? (
      countries = this.props.companyLocations.map((location, index) => {
        return (
          { id: location.id, country: location.branchName + '-' + location.branchLocation }
        )
      })
    ) : (
      countries = [
        { id: 1, country: 'India' },
        { id: 3, country: 'US' },
        { id: 4, country: 'UK' }
      ]
    );

    this.props.currencies && this.props.currencies.length > 0 ? (
      currencies = this.props.currencies.map((currency, index) => {
        return (
          { id: currency.id, currency: currency.currency + '-' + currency.code }
        )
      })
    ) : (
      currencies = [
        { id: 1, currency: 'US Dollar' },
        { id: 2, currency: 'UK Pound' },
        { id: 3, currency: 'INR' },
        { id: 4, currency: 'Euro' }
      ]
    );

    let timezones = [
      { id: 'IST', zone: 'IST' },
      { id: 'JST', zone: 'JST' },
      { id: 'EST', zone: 'EST' },
      { id: 'AEST', zone: 'AEST' },
      { id: 'GMT', zone: 'GMT' },
      { id: 'AST', zone: 'AST' },
      { id: 'PST', zone: 'PST' },
    ];

    let payroll = [
      { id: 'true', value: 'true' },
      { id: 'false', value: 'false' }
    ];

    let multirows = [
      { id: 'yes', value: 'yes' },
      { id: 'no', value: 'no' }
    ];

    let dateFormats = [
      { id: 'DD/MM/YYYY', format: "DD/MM/YYYY" },
      { id: 'MM/DD/YYYY', format: 'MM/DD/YYYY' },
      { id: 'YYYY/MM/DD', format: 'YYYY/MM/DD' },
    ]
    let weekdays = [
      { id: 'sunday', day: 'sunday' },
      { id: 'monday', day: 'monday' },
      { id: 'tuesday', day: 'tuesday' },
      { id: 'wednesday', day: 'wednesday' },
      { id: 'thursday', day: 'thursday' },
      { id: 'friday', day: 'friday' },
      { id: 'saturday', day: 'saturday' },
    ];
    switch (param) {
      case 'time_zone':
        timezones.map(tz => {
          items.push({ label: tz.zone, value: tz.id });
        });
        break;
      case 'timesheet_reminder_day':
        weekdays.map(tz => {
          items.push({ label: tz.day, value: tz.id });
        });
        break;
      case 'timesheet_multi_rows':
        multirows.map(tz => {
          items.push({ label: tz.value, value: tz.id });
        })
        break;
      case 'holidays_to_follow':
        countries.map(tz => {
          items.push({ label: tz.country, value: tz.id });
        })
        break;
      case 'date_format':
        dateFormats.map(tz => {
          items.push({ label: tz.format, value: tz.id })
        });
        break;
      case 'payroll_enabled':
        payroll.map(tz => {
          items.push({ label: tz.value, value: tz.id })
        });
        break;
      case 'currency':
        currencies.map(tz => {
          items.push({ label: tz.currency, value: tz.id })
        });
        break;
      default:
        break;
    }
    return items;
  }

  renderDropdown = (prop) => {
    const items = this.getItems(prop.param);
    return (
      <FormGroup >
        <Input type="select" name="paramValue" value={prop.paramValue} onChange={(e) => { this.handleSelect(e, prop) }} id={prop.param}>
          <option value={-1} disabled>Select {prop.param}</option>
          {items.map((item) => {
            return (<option value={item.value}>{item.label}</option>)
          })}
        </Input>
      </FormGroup>
    )
  };


  handleCancel = () => {
    let passProps = {
      pathname: 'companymaster',
    }
    this.props.history.push(passProps);
  }

  render() {


    let configs = this.state.configurations.map((prop, key) => {
      return {
        id: prop.id,
        cid: prop.cid,
        displayText: prop.displayText,
        param: prop.param,
        paramValue: prop.paramValue,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {prop.inputType === "textbox" ? (
              <FormGroup>
                <Input
                  name="paramValue"
                  type="text"
                  placeholder={prop.param}
                  value={prop.paramValue}
                  onChange={(e) => { this.handleSelect(e, prop) }}
                />

              </FormGroup>
            ) : (
              this.renderDropdown(prop)
            )

            }
            {/* <FormGroup check>
                          <Label check>
                            <Input  defaultValue="" type="checkbox" />
                            <span className="form-check-sign">
                              <span className="check" />
                            </span>
                          </Label>
                        </FormGroup> */}
          </div>
        )
      };
    })

    return (
      <>
        <div className="content">

          <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          <Row>
            <Col xs={12} md={10}>
              <Card>

                <CardBody>
                  <ReactTable
                    PaginationComponent={Pagination}
                    data={configs}
                    // filterable
                    // nextText={myCustomNextText}
                    resizable={false}
                    columns={[

                      {
                        Header: "Sr.No",
                        accessor: "id",
                        width: 70,
                      },
                      {
                        Header: "Parameter",
                        accessor: "displayText",

                      },
                      {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        className: "margin-to-table-field",
                        width: 400
                      },



                    ]}
                    NoDataComponent={() => null}
                    pageSize={this.props.configs.length <= 5 ? this.props.configs.length : 5}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Button onClick={() => { this.handleCancel() }} className="btn-cancel" size="sm" color="info">Cancel</Button>
      </>
    );
  }
}

export default Wizard;