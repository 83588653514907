import { getAdminTimesheetQuarterlyReportAPI, getAdminTimesheetMonthlyReportAPI, getAdminTimesheetWeeklyReportAPI, getAdminProjectMarginReportAPI } from "../../services/timesheets-service";


export const FETCH_ADMIN_TIMESHEET_WEEKLY_REPORT = 'FETCH_ADMIN_TIMESHEET_WEEKLY_REPORT';
export const FETCH_ADMIN_TIMESHEET_MONTHLY_REPORT = 'FETCH_ADMIN_TIMESHEET_MONTHLY_REPORT';
export const FETCH_ADMIN_TIMESHEET_QUARTERLY_REPORT = 'FETCH_ADMIN_TIMESHEET_QUARTERLY_REPORT';
export const FETCH_INITIAL_ADMIN_REPORT_DATA = 'FETCH_INITIAL_ADMIN_REPORT_DATA';
export const FETCH_ADMIN_PROJECT_MARGIN_REPORT = 'FETCH_ADMIN_PROJECT_MARGIN_REPORT';

let token = localStorage.getItem('token');

export const getAdminTimesheetWeeklyReport = (cid, eid, startDate, endDate, status, quarter, month, year, pid) => {
    return async (dispatch) => {
        const response = await getAdminTimesheetWeeklyReportAPI(cid, eid, startDate, endDate, status, quarter, month, year, pid);
        if (response.data) {
            dispatch({
                type: FETCH_ADMIN_TIMESHEET_WEEKLY_REPORT,
                timesheetWeeklyReport: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}


export const getAdminTimesheetMonthlyReport = (cid, eid, startDate, endDate, status, quarter, month, year, pid) => {
    return async (dispatch) => {
        const response = await getAdminTimesheetMonthlyReportAPI(cid, eid, startDate, endDate, status, quarter, month, year, pid);
        if (response.data) {
            dispatch({
                type: FETCH_ADMIN_TIMESHEET_MONTHLY_REPORT,
                timesheetMonthlyReport: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }


    }
}

export const getAdminTimesheetQuarterlyReport = (cid, eid, startDate, endDate, status, quarter, month, year, pid) => {

    return async (dispatch) => {
        const response = await getAdminTimesheetQuarterlyReportAPI(cid, eid, startDate, endDate, status, quarter, month, year, pid);
        if (response.data) {
            dispatch({
                type: FETCH_ADMIN_TIMESHEET_QUARTERLY_REPORT,
                timesheetQuarterlyReport: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }


    }
}

export const getInitialReportData = () => {

    return async (dispatch) => {
        dispatch({
            type: FETCH_INITIAL_ADMIN_REPORT_DATA,
            timesheetReport: []
        });
        return { success: true };

    }
}

export const getAdminProjectMarginReport = (cid) => {

    return async (dispatch) => {
        const response = await getAdminProjectMarginReportAPI(cid);

        if (response.data) {
            dispatch({
                type: FETCH_ADMIN_PROJECT_MARGIN_REPORT,
                adminProjectMarginReport: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}