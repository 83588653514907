import {getAllEmployeesAPI, deleteEmployeeAPI} from "../../services/employees-service";

export const FETCH_ALL_EMPLOYEES = 'FETCH_ALL_EMPLOYEES';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';

export const getAllEmployees = (cid) =>  {
    return async (dispatch) => {
        const response = await getAllEmployeesAPI(cid);
            if (response.data){
                dispatch({
                    type: FETCH_ALL_EMPLOYEES,
                    employees: response.data
                  });
                return { success: true };
            }
            else{
                return { success: false};
            }    
        }
}


export const deleteEmployee = (cid,eid) =>  {
  return async (dispatch) => {
      const response = await deleteEmployeeAPI(cid,eid);
          if (response.data){
              dispatch({
                  type: DELETE_EMPLOYEE,
                  payload: {
                    eid:eid
                }
                });
              return { success: true };
          }
          else{
              return { success: false};
          }    
      }
}