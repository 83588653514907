import { FETCH_ALL_ROLES } from "../actions/rolesAction";
const rolesReducer = function (state = [], action) {
    switch (action.type) {
        case FETCH_ALL_ROLES:
            return action.roles;
        default:
            return state;
    }
};

export default rolesReducer;