import { FETCH_ALL_MANAGER_EMPLOYEES } from "../actions/managerEmployeesAction";
const managerEmployeesReducers = function (state = [], action) {
    switch (action.type) {
        case FETCH_ALL_MANAGER_EMPLOYEES:
            return action.employees;
        default:
            return state;
    }
};

export default managerEmployeesReducers;