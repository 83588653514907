import { FETCH_ALL_MANAGERS , } from '../actions/managersAction';

const managerReducer = function (state = [], action) {

    switch (action.type) {
        case FETCH_ALL_MANAGERS:
            return action.managers;
            default:
            return state;
    }
};

export default managerReducer;
