import { getEmployeeAllProjectsAPI } from '../../services/employees-service';

export const FETCH_ALL_EMPLOYEE_PROJECTS = 'FETCH_ALL_EMPLOYEE_PROJECTS';

export const getEmployeeAllProjects = (cid, eid) => {
    return async (dispatch) => {
        const response = await getEmployeeAllProjectsAPI(cid, eid);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_EMPLOYEE_PROJECTS,
                projects: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

