
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../assets/css/category-modal.css';
import HolidayForm from './holidayForm';

class HolidayModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };

  }
  UNSAFE_componentWillMount() {
  }

  render() {
    return (
      <Modal className="custom-modal" isOpen={this.props.isOpen} toggle={this.props.toggle} style={{
        width: "100%",
        maxWidth: 700
      }}>
            <ModalHeader style={{ padding: 20 }} toggle={this.props.toggle}>Holiday Details</ModalHeader>
            <ModalBody>
                <HolidayForm cid={this.props.cid} holiday={this.props.holiday} countries={this.props.countries} addUpdateHoliday={this.props.addUpdateHoliday} toggle={this.props.toggle} showNotifications={this.props.showNotifications} formtype={this.props.formtype} />
            </ModalBody>
      </Modal>
    );
  }
}

export default HolidayModal;