import { FETCH_ALL_CLAIM_CATEGORIES } from "../actions/claimCategoriesAction";
const claimCategoriesReducer = function (state = [], action) {
    switch (action.type) {
        case FETCH_ALL_CLAIM_CATEGORIES:
            return action.claimCategories;
        default:
            return state;
    }
};

export default claimCategoriesReducer;