
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../assets/css/category-modal.css';
import VendorForm from './vendorForm';



class VendorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /* category: {
        name: '',
        description: '',
        categoryId: ''
      }, */
      isLoading: false
    };

  }
  UNSAFE_componentWillMount() {
  }

  render() {

    return (
      <Modal className="custom-modal" isOpen={this.props.isOpen} toggle={this.props.toggle} style={{
        width: "100%",
        maxWidth: 700
      }}>
        <ModalHeader style={{ padding: 20 }} toggle={this.props.toggle}><strong>Vendor Details</strong></ModalHeader>
        <ModalBody>
          <VendorForm cid={this.props.cid} vendors={this.props.vendors} countries={this.props.countries} addUpdateVendorDetails={this.props.addUpdateVendorDetails} toggle={this.props.toggle} showNotifications={this.props.showNotifications} vendor={this.props.vendor} formtype={this.props.formtype} />
        </ModalBody>
      </Modal>
    );
  }
}

export default VendorModal