import  { GET_LEAVES_TYPES } from '../actions/leaveTypeActions';


const INITIAL_STATE = []

const leaveTypeReducer = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case GET_LEAVES_TYPES:
            newState = action.leavesTypes;
            return newState;

        default: return state;
  }
};

export default leaveTypeReducer; 