
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Modal, ModalFooter, Button, Row, Table } from "reactstrap";
import { getEmployeeExtraInfo } from "../../../redux/actions/employeeExtraInfoAction";
import { getEmployeePayroll } from "../../../redux/actions/employeePayrollInfoAction";
import { getAllRoles } from "../../../redux/actions/rolesAction";
import { getAllDesignations } from "../../../redux/actions/designationAction";
import { getEmployeesWithManagerRole } from "../../../redux/actions/managersAction";
import _ from 'lodash'
import { dateFormatList } from "variables/constants";
import moment from "moment";

class EmployeeDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employee: {},
      role: null,
      designation: null,
      reportingManager: null
    };
  }
  UNSAFE_componentWillMount() {

    if (this.props.employee) {
      this.props.getEmployeeExtraInfo(this.props.cid, this.props.employee.eid);
      this.props.getEmployeePayroll(this.props.cid, this.props.employee.eid);
      if (!this.props.roles.length) {
        this.props.getAllRoles(this.props.cid);
      }
      if (!this.props.designations.length) {
        this.props.getAllDesignations(this.props.cid);
      }
      if (!this.props.managers.length) {
        this.props.getEmployeesWithManagerRole(this.props.cid);
      }
      let roleId = this.props.employee.extraInfo && this.props.employee.extraInfo.roleId ? this.props.employee.extraInfo.roleId : 0;
      let designationId = this.props.employee.extraInfo && this.props.employee.extraInfo.designationId ? this.props.employee.extraInfo.designationId : 0;
      let reportingManagerId = this.props.employee.extraInfo && this.props.employee.extraInfo.reportingManager ? this.props.employee.extraInfo.reportingManager : 0;
      let role = this.props.roles.find(role => role.id === roleId);
      let designation = this.props.designations.find(designation => designation.id === designationId);
      let reportingManager = this.props.managers.find(employee => employee.eid === reportingManagerId);
      this.setState({
        role: role,
        designation: designation,
        reportingManager: reportingManager
      })
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {

    if (!_.isEqual(this.props.employee, nextProps.employee)) {

      this.props.getEmployeeExtraInfo(this.props.cid, this.props.employee.eid);
      this.props.getEmployeePayroll(this.props.cid, this.props.employee.eid);
      let roleId = this.props.employee.extraInfo && this.props.employee.extraInfo.roleId ? this.props.employee.extraInfo.roleId : 0;
      let designationId = this.props.employee.extraInfo && this.props.employee.extraInfo.designationId ? this.props.employee.extraInfo.designationId : 0;
      let reportingManagerId = this.props.employee.extraInfo && this.props.employee.extraInfo.reportingManager ? this.props.employee.extraInfo.reportingManager : 0;
      let role = this.props.roles.find(role => role.id === roleId);
      let designation = this.props.designations.find(designation => designation.id === designationId);
      let reportingManager = this.props.managers.find(employee => employee.eid === reportingManagerId);
      this.setState({
        role: role,
        designation: designation,
        reportingManager: reportingManager
      })
    }
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.modal} toggle={this.props.toggleEmployeeModal} className="modal-lg" >
          <div className="content">
            <div className="text-header text-center" style={{ marginRight: '50px' }}>Employee Details</div>
            <Table borderless className="" id="custom-view-table">
              <thead>
                <tr>
                  <th style={{ width: 160 }}></th>
                  <th style={{ width: 240 }}></th>
                  <th style={{ width: 170 }}></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td >Employee Id :</td>
                  <td>{this.props.employee.eid}</td>
                  <td>Name :</td>
                  <td>{this.props.employee.fname + " " + this.props.employee.lname}</td>
                </tr>
                <tr>
                  <td>Address :</td>
                  <td>{this.props.employee.addressPer}</td>
                  <td>Date of birth :</td>
                  <td>{this.props.employee.dob}</td>
                </tr>
                <tr>
                  <td>Date of joining :</td>
                  <td>{this.props.employee.doj !== "" ? moment(this.props.employee.doj).format(dateFormatList[2]) : this.props.employee.doj}</td>
                  <td>Experience :</td>
                  <td>{this.props.employee.experienceYear + " Year, " + this.props.employee.experienceMonth + " Month"}</td>
                </tr>
                <tr>
                  <td>Employment Type :</td>
                  <td>{this.props.employee && this.props.employee.extraInfo ? this.props.employee.extraInfo.employmentType : 'NA'}</td>
                  <td>Mobile No. :</td>
                  <td>{this.props.employee.phone1}</td>
                </tr>
                <tr>
                  <td>Email :</td>
                  <td>{this.props.employee.emailOfficial}</td>
                  <td>Gender :</td>
                  <td>{this.props.employee.gender === "M" ? 'Male' : 'Female'}</td>
                </tr>
                <tr>
                  <td>CTC :</td>
                  <td>{this.props.employee && this.props.employee.payRollInfo ? this.props.employee.payRollInfo.ctc : 'NA'}</td>
                  <td>Salary Effective Date :</td>
                  <td>{this.props.employee && this.props.employee.payRollInfo ? this.props.employee.payRollInfo.salaryEffectiveDate : 'NA'}</td>
                </tr>
                <tr>
                  <td>Role :</td>
                  <td>{this.state.role && this.state.role.role ? this.state.role.role : 'N/A'}</td>
                  <td>Designation :</td>
                  <td>{this.state.designation && this.state.designation.designation ? this.state.designation.designation : 'N/A'}</td>
                </tr>
                <tr>
                  <td>Active  :</td>
                  <td>{this.props.employee.isActive === "Y" ? 'True' : 'False'}</td>
                  <td>Reporting Manager :</td>
                  <td>{this.state.reportingManager && this.state.reportingManager.fname ? this.state.reportingManager.fname + " " + this.state.reportingManager.lname : 'N/A'}</td>
                </tr>
              </tbody>
            </Table>
            {/* <Row style={{ marginTop: '20px' }}>
            <Col sm="2">  
            </Col>

              <Col sm="5">
                <div >
                  <div style={{ display: 'flex' }}>
                    <h6>Mobile No. :</h6><h6>{" " + this.props.employee.phone1}</h6>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h6>Email :</h6><h6>{" " + this.props.employee.emailOfficial}</h6>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h6>Gender : </h6><h6>{this.props.employee.gender === "M" ? 'Male' : 'Female'}</h6>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h6>CTC : </h6><h6>{this.props.employee && this.props.employee.payRollInfo ? this.props.employee.payRollInfo.ctc : 'NA'}</h6>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h6>Salary Effective Date : </h6><h6>{ this.props.employee && this.props.employee.payRollInfo ? this.props.employee.payRollInfo.salaryEffectiveDate : 'NA'}</h6>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h6>Role :</h6><h6>{this.state.role && this.state.role.role ? this.state.role.role : 'N/A'}</h6>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h6>Designation :</h6><h6>{this.state.designation && this.state.designation.designation ? this.state.designation.designation : 'N/A'}</h6>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h6>Reporting Manager :</h6><h6>{this.state.reportingManager && this.state.reportingManager.fname ? this.state.reportingManager.fname+" "+this.state.reportingManager.lname : 'N/A'}</h6>
                  </div>
                </div>
              </Col>
              <Col sm="1">
              </Col>
            </Row> */}
            <ModalFooter style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Button size="sm" color="secondary" onClick={this.props.handeCloseModal}>Cancel</Button>
            </ModalFooter>
          </div>
        </Modal>

      </>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  getEmployeeExtraInfo: bindActionCreators(getEmployeeExtraInfo, dispatch),
  getEmployeePayroll: bindActionCreators(getEmployeePayroll, dispatch),
  getAllRoles: bindActionCreators(getAllRoles, dispatch),
  getAllDesignations: bindActionCreators(getAllDesignations, dispatch),
  getEmployeesWithManagerRole: bindActionCreators(getEmployeesWithManagerRole, dispatch)
});

export const mapStateToProps = ({ employees, roles, designations, managers, auth }, ownProps) => {
  let employee = employees.find(o => o.id === ownProps.empId);
  return {
    employees: employees,
    roles: roles,
    designations: designations,
    employee: employee ? employee : null,
    managers: managers,
    cid: auth.user.cid
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetailsModal);
