import moment from "moment";
import React from "react";
import { Button, Col, FormGroup, Input, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import ReactDatetime from "react-datetime";
import Switch from "react-switch";
import Select from "react-select";
import { REQUIRD_MESSAGE, dateFormatList } from "../../variables/constants";
import NotificationAlert from "react-notification-alert";

class HolidayForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            occassion: "",
            date: "",
            dateState: "",
            day: "",
            country: -1,
            formname: "Add Holiday",
            multipleSelect: null,
            isActive: true,
            occassionState: "",
            locationState: "",
            metadata: {
                occassion: "occassion",
                date: "holiday date"
            },
            isLoading: false
        };
    }


    notify = (place, color, message) => {
        // var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };


    showNotifications = (color, message) => {
        this.notify("tr", color, message);
    }

    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };
    // function that verifies if a string has a given length or not
    verifyLength = (value, length) => {
        if (value.length <= length) {
            return false;
        }
        return true;
    };

    handleSelect = (e) => {
        this.setState({
            day: e.target.value
        })
    }

    handleCountrySelect = (e) => {

        this.setState({
            country: e.target.value,
            locationState: "has-success"
        })
    }

    handleDateChange = (date) => {
        this.setState({
            dateState: "has-success",
            date: moment(date).format('YYYY-MM-DD'),
            day: moment(date).format('dddd')
        });
    }

    handleIsActive = (e) => {

        this.setState({
            isActive: e
        })
    }

    change = (event, stateName, type, stateNameEqualTo, maxValue) => {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "length":

                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    };


    isValidated = async () => {


        if (
            this.state.occassionState === "has-success" &&
            this.state.locationState === "has-success" && this.state.dateState === "has-success"
        ) {

            let id = 0; let isActive = "Y";
            this.state.isActive === true ? isActive = "Y" : isActive = "N"
            this.props.formtype === 'update' ? id = this.props.holiday.id : id = 0;
            let holidayDetails = {};
            if (this.props.formtype === "add") {
                holidayDetails = {
                    id: id,
                    cid: this.props.cid,
                    holiday: this.state.occassion,
                    holidayDate: this.state.date,
                    day: this.state.day,
                    companyLocationId: this.state.country,
                    isActive: isActive

                }
            } else {
                holidayDetails = {
                    cid: this.props.cid,
                    companyLocationId: this.state.country,
                    day: this.state.day,
                    holiday: this.state.occassion,
                    holidayDate: this.state.date,
                    id: this.props.holiday.id,
                    isActive: isActive
                }
            }

            this.props.addUpdateHoliday(holidayDetails, this.props.formtype);
            return true;
        } else {
            if (this.state.locationState !== "has-success") {
                this.setState({ locationState: "has-danger" });
            }
            if (this.state.occassionState !== "has-success") {
                this.setState({ occassionState: "has-danger" })
            }
            if (this.state.dateState !== "has-success") {
                this.setState({ dateState: "has-danger" })
            }
            //this.showNotifications(3, "Required field cannot be empty!");
            this.setState({ isLoading: false })
            return true;
        }
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true
        })
        if (this.state.country > -1) {
            this.setState({
                locationState: "has-success"
            })
        } else {
            this.setState({
                locationState: "has-danger"
            })
        }
        /*  let locations=[];
         if(this.props.formtype === 'add'){
            //
            this.state.multipleSelect.map((select,index) =>{
             let obj = {0:select.value};
             locations.push(obj);
            })
           
         } */
        await this.isValidated();
        this.setState({
            isLoading: false
        })
    }


    UNSAFE_componentWillMount() {
        if (this.props.formtype === "update") {
            let isActive = true
            this.props.holiday.isActive === "Y" ? isActive = true : isActive = false
            this.setState({
                occassion: this.props.holiday.holiday,
                date: moment(this.props.holiday.holidayDate).format("YYYY-MM-DD"),
                day: this.props.holiday.day,
                country: this.props.holiday.companyLocationId,
                occassionState: "has-success",
                locationState: "has-success",
                dateState: "has-success",
                isActive: isActive,
                formname: 'Update Holiday'
            })
        }
    }
    render() {
        let metadata = this.state.metadata;

        let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let weekdays = days.map((day, index) => {
            return (<option key={index} value={day}>{day}</option>)
        });

        let countriesOptions = this.props.countries.map((country, index) => {
            return (<option key={index} value={country.id}>{country.branchName}</option>)
        });
        return (
            <>
                <NotificationAlert ref="notificationAlert" />
                <div className="content raise-ticket">
                    <Col className="mr-auto ml-auto" md="12">

                        <div className="text-header text-center">{this.state.formname}</div>
                        <form onSubmit={this.handleSubmit} className="ticket-form">
                            <Row className="justify-content-center">
                                <Col className="" sm="10">
                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Occasion
                                        </label>
                                        <Input
                                            name={"occassion"}
                                            placeholder={metadata.occassion + " (required)"}
                                            type="text"
                                            onChange={e => this.change(e, "occassion", "length", 0)}
                                            onFocus={e => this.setState({ roleFocus: true })}
                                            onBlur={e => this.setState({ roleFocus: false })}
                                            value={this.state.occassion}
                                        />
                                        {this.state.occassionState === "has-danger" ? (
                                            <label className="error client_error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col sm="5">

                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Holiday Date*
                                        </label>
                                        <ReactDatetime
                                            timeFormat={false}
                                            inputProps={{ placeholder: metadata.date + " (required)", className: "form-control", value: this.state.date !== "" ? moment(this.state.date).format(dateFormatList[2]) : this.state.date }}
                                            onChange={this.handleDateChange}
                                            closeOnSelect={true}
                                        />
                                        {this.state.dateState === "has-danger" ? (
                                            <label className="error">This field is required.</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                {/* <Col className="" sm="5">

                                    <FormGroup>
                                        <label htmlFor="password">
                                            Day*
                                        </label>
                                        <Input type="select" name="day" defaultValue={this.state.day} onChange={this.handleSelect} id="exampleSelect">
                                            {weekdays}
                                        </Input>
                                    </FormGroup>
                                </Col> */}
                                <Col sm="5">

                                    <FormGroup className="form-group">
                                        <label htmlFor="country">
                                            Company Location
                                        </label>
                                        <Input type="select" name="country" defaultValue={this.state.country} onChange={this.handleCountrySelect} id="exampleSelect">
                                            <option value={-1} disabled>Select location</option>
                                            {countriesOptions}
                                        </Input>
                                        {this.state.locationState === "has-danger" ? (
                                            <label className="error client_error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                {/* <Col lg="5" md="6" sm="3">
                                    <FormGroup>
                                        
                                        <label htmlFor="country">
                                            Company Location
                                        </label>
                                        <Select
                                            className="react-select info"
                                            classNamePrefix="react-select"
                                            placeholder="Choose City"
                                            name="multipleSelect"
                                            closeMenuOnSelect={false}
                                            isMulti
                                            value={this.state.multipleSelect}
                                            onChange={value =>
                                            this.setState({ multipleSelect: value },() =>{
                                               
                                            })
                                            }
                                            options={ this.props.countries.map((country, index) => {
                                                return ( {value:country.id, label:country.branchName})
                                            })}
                                        />
                                    </FormGroup>
                                </Col> */}

                                <Col className="" sm="10">
                                    <FormGroup>
                                        <label id="tooltip209848">
                                            <span style={{ display: 'block', marginBottom: 10, marginTop: 4 }} htmlFor="isActive"> Active </span>
                                            <Switch
                                                onChange={(e) => { this.handleIsActive(e) }}
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                            />
                                        </label>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target="tooltip209848"
                                            placement="right"
                                        >
                                            {this.state.isActive === true ? "Active" : "Inactive"}
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row className="justify-content-center mt-3">
                                {
                                    this.state.isLoading ? (
                                        <Button disabled="true" size="sm" color="info" className="btn-submit btn-ticket-submit">
                                            <Spinner size="sm" />
                                        </Button>

                                    ) : (
                                        <Button size="sm" type="submit" color="info" className="btn-submit btn-ticket-submit">Submit</Button>
                                    )
                                }
                            </Row>
                        </form>
                    </Col></div>

            </>
        );
    }
}


export default HolidayForm
