import AdminDashboard from "views/Dashboards/AdminDashboard.js";
import ProjectMaster from "views/Masters/ProjectMaster/index";
import EmployeeMaster from "views/Masters/EmployeeMaster/index";
import ClientMaster from "views/Masters/ClientMaster/index.js";
import Login from "views/Login";
import TimesheetReport from "views/Timesheet/timesheetReport";
import LeaveDetails from "views/LeaveManagement/LeaveDetails/index.js";
import TimesheetReportDashboard from "views/ReportDashboard/TimesheetReportDashboard";
import TimesheetHourlyReport from "views/ReportDashboard/timesheetHourlyReport";
import ApproveRejectTimesheet from "views/Timesheet/ApproveRejectTimesheet";
import ManageLeave from "views/LeaveManagement/ManageLeave/index.js";
import HolidayList from "views/HolidayController/holidayList.js";
import LeaveReportDashboard from "views/ReportDashboard/leaveReport";
import EnterTimesheet from "views/Timesheet/enterTimesheet";
import DesignationMaster from "views/Masters/DesignationMaster";
import DepartmentMaster from "views/Masters/DepartmentMaster";
import CountryMaster from "views/Masters/CountryMaster/index"
import CompanyMaster from "views/Masters/CompanyMaster";
import RolesMaster from "views/Masters/RolesMaster/index.js";
import ResetPasswordLink from "views/ResetPassword/ResetPasswordLink";
import LeaveTypeMaster from "views/LeaveManagement/LeaveTypeMaster";
import ResetPassword from "views/ResetPassword/ResetPassword";
import AddUpdateStepper from "views/Masters/EmployeeMaster/AddUpdateStepper";
import AddUpdateProjectStepper from "views/Masters/ProjectMaster/AddUpdateProjectStepper";
import ModuleMaster from "views/Masters/ModuleMaster/index";
import ExpenseManager from "views/ExpenseManager/index";
import RolesModuleAccess from "views/Masters/RolesModuleAccess/index";
import AddEditCompanyStepper from "views/Masters/CompanyMaster/AddEditCompanyStepper";
import ApproveRejectExpense from "views/ExpenseManager/ApproveRejectExpense/index";
import CreateExpense from "views/ExpenseManager/CreateExpense";
import ViewTimesheetReport from "views/Timesheet/viewTimesheetReport"
import UserProfile from "views/UserProfile";
import projectMarginReport from "views/ProjectReport/ProjectMarginReport";
import ContractorAllocationReport from "views/ContractorReport/ContractorAllocationReport";
import VendorMaster from "views/Masters/VendorMaster/index.js";
import CompanyLocationMaster from "views/Masters/CompanyLocationMaster/index";
import ExpenseReport from "views/ExpenseManager/ExpenseReport/index";
import RejectedTimesheet from "views/Timesheet/RejectedTimesheet";
import LeaveHistoryDetails from "views/LeaveManagement/leaveHistoryDetails";
import EmployeeAppraisal from "views/Appraisal/EmployeeAppraisal";
import ManagerAppraisal from "views/Appraisal/ManagerAppraisal";
import HRAppraisal from "views/Appraisal/HRAppraisal";
import EmployeeAppraisalList from "views/Appraisal/EmployeeAppraisalList";
import HRAppraisalList from "views/Appraisal/HRAppraisalList";
import ManagerAppraisalList from "views/Appraisal/ManagerAppraisalList";
export const authRoutes = [
  {
    path: "/login",
    name: "Login",
    redirect: true,
    component: Login,
    layout: "/auth",
    dbName: 'Login',
  },
  {
    path: "/forgot-password",
    name: "Reset Password",
    redirect: true,
    component: ResetPasswordLink,
    layout: "/auth",
    dbName: 'Forgot Password',
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    redirect: true,
    component: ResetPassword,
    layout: "/auth",
    dbName: 'Reset Password',
  },
];

export const employeeRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: AdminDashboard,
    layout: "/employee",
    dbName: 'Dashboard',
  },
  {
    collapse: true,
    name: "Expense Manager",
    icon: "tim-icons icon-book-bookmark",
    state: "expenseCollapse",
    dbName: 'Expense Manager',
    views: [
      {
        path: "/approverejectexpense",
        name: "Approve Reject Expense",
        mini: "EM",
        component: ApproveRejectExpense,
        layout: "/employee",
        dbName: 'Approve Reject Expenses',
      },
      {
        path: "/expensemanager",
        name: "Expense Manager",
        mini: "EM",
        component: ExpenseManager,
        layout: "/employee",
        dbName: 'Expense Manager',
      }
    ]
  },
  {
    path: "/holidaylist",
    name: "Holiday List",
    mini: "HL",
    icon: "tim-icons icon-map-big",
    component: HolidayList,
    layout: "/employee",
    dbName: 'Holiday List',
  },
  {
    collapse: true,
    name: "Leave Management",
    icon: "tim-icons icon-credit-card",
    state: "leaveCollapse",
    dbName: 'Leave Management',
    views: [
      {
        path: "/leavedetails",
        name: "Leave Details",
        mini: "LD",
        component: LeaveDetails,
        layout: "/employee",
        dbName: 'Leave Details',
      },
      {
        path: "/manageleave",
        name: "Manage Leaves",
        mini: "ML",
        component: ManageLeave,
        layout: "/employee",
        dbName: 'Manage Leaves',
      },
    ]
  },
  {
    collapse: true,
    name: "Masters",
    icon: "tim-icons icon-badge",
    state: "reportCollapse",
    dbName: 'Masters',
    views: [
      {
        path: "/clientmaster",
        name: "Client Master",
        mini: "CM",
        component: ClientMaster,
        layout: "/employee",
        dbName: 'Client Master',
      },
      {
        path: "/companymaster",
        name: "Company Master",
        mini: "PM",
        component: CompanyMaster,
        layout: '/employee',
        dbName: 'Company Master',
      },
      {
        path: "/companylocationmaster",
        name: "Company Location Master",
        mini: "CLM",
        component: CompanyLocationMaster,
        layout: '/employee',
        dbName: 'Comapny Location Masters',
      },
      {
        path: "/countrymaster",
        name: "Country Master",
        mini: "PM",
        component: CountryMaster,
        layout: '/employee',
        dbName: 'Country Master'
      },
      {
        path: "/departmentmaster",
        name: "Department Master",
        mini: "DM",
        component: DepartmentMaster,
        layout: "/employee",
        dbName: 'Department Master'
      },
      {
        path: "/designationmaster",
        name: "Designation Master",
        mini: "DM",
        component: DesignationMaster,
        layout: "/employee",
        dbName: 'Designation Master'
      },
      {
        path: "/employeemaster",
        name: "Employee Master",
        mini: "EM",
        component: EmployeeMaster,
        layout: "/employee",
        dbName: 'Employee Master'
      },
      {
        path: "/vendormaster",
        name: "Vendor Master",
        mini: "VM",
        component: VendorMaster,
        layout: '/employee',
        dbName: 'Vendor Master',
      },
      {
        path: "/leavetypemaster",
        name: "Leave Type Master",
        mini: "LT",
        component: LeaveTypeMaster,
        layout: '/employee',
        dbName: 'Leave Type Master'
      },
      {
        path: "/modulemaster",
        name: "Module Master",
        mini: "MM",
        component: ModuleMaster,
        layout: '/employee',
        dbName: 'Module Master'
      },
      {
        path: "/projectmaster",
        name: "Project Master",
        mini: "PM",
        component: ProjectMaster,
        layout: '/employee',
        dbName: 'Project Master',
      },
      {
        path: "/rolesmaster",
        name: "Roles Master",
        mini: "RM",
        component: RolesMaster,
        layout: '/employee',
        dbName: 'Roles Master',
      },
      {
        path: "/rolemoduleaccess",
        name: "Roles Module Access",
        mini: "RAM",
        component: RolesModuleAccess,
        layout: '/employee',
        dbName: 'Roles Module Access',
      },
    ]
  },
  {
    collapse: true,
    name: "Report Dashboard",
    icon: "tim-icons icon-single-copy-04",
    state: "reportDashboard",
    dbName: 'Report Dashboard',
    views: [
      {
        path: "/leaveReportDashboard",
        name: "Leave Report",
        mini: "LR",
        component: LeaveReportDashboard,
        layout: "/employee",
        dbName: 'Leave Report',
      },
      {
        path: "/TimesheetHourlyReport",
        name: "Timesheet Hourly",
        mini: "TRD",
        component: TimesheetHourlyReport,
        layout: "/employee",
        dbName: 'Timesheet Hourly',
      },
      {
        path: "/timesheetReportDashboard",
        name: "Timesheet Report",
        mini: "TRD",
        component: TimesheetReportDashboard,
        layout: "/employee",
        dbName: 'Timesheet Report',
      }
    ]
  },
  {
    collapse: true,
    name: "Timesheet",
    icon: "tim-icons icon-calendar-60",
    state: "timesheetCollapse",
    dbName: 'Timesheet',
    views: [
      {
        path: "/enterTimesheet",
        name: "Enter Timesheet",
        mini: "ET",
        component: EnterTimesheet,
        layout: "/employee",
        dbName: 'Enter Timesheet',
      },
      {
        path: "/viewTimesheet",
        name: "View Timesheet",
        mini: "VT",
        component: TimesheetReport,
        layout: "/employee",
        dbName: 'View Timesheet',
      },
      {
        path: "/approveRejectTimesheet",
        name: "Approve and Reject",
        mini: "AR",
        component: ApproveRejectTimesheet,
        layout: "/employee",
        dbName: 'Approve Reject Timesheet',
      },
      {
        path: "/rejectedTimesheet",
        name: "Rejected Timesheet",
        mini: "AR",
        component: RejectedTimesheet,
        layout: "/employee",
        dbName: 'Rejected Timesheet',
      }
    ]
  },
  {
    collapse: true,
    name: "ESS Report",
    icon: "tim-icons icon-notes",
    state: "manageTimesheetCollapse",
    dbName: 'Timesheet Report',
    views: [

      {
        path: "/viewTimesheetReport",
        name: "Timesheet Detail report",
        mini: "MTR",
        component: ViewTimesheetReport,
        layout: "/employee",
        dbName: 'Employees Timesheet Report',
      },
      {
        path: "/projectMarginReport",
        name: "Project Margin Report",
        mini: "PMR",
        component: projectMarginReport,
        layout: "/employee",
        dbName: 'Project Margin Report',
      },
      {
        path: "/contractorAllocationReport",
        name: "Contractor Report",
        mini: "CR",
        component: ContractorAllocationReport,
        layout: "/employee",
        dbName: 'Contractor Allocation Report',
      },
    ]
  },
  {
    collapse: true,
    name: "Appraisal",
    icon: "tim-icons icon-calendar-60",
    state: "appraisalCollapse",
    dbName: 'Appraisal',
    views: [
      {
        path: "/employee-appraisal",
        name: "Appraisal Form",
        mini: "EA",
        component: EmployeeAppraisal,
        layout: "/employee",
        dbName: 'Appraisal Form',
      }
    ]
  },


];

export const managerRoutes = [

  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: AdminDashboard,
    layout: "/manager",
    dbName: 'Dashboard',
  },
  {
    collapse: true,
    name: "Expense Manager",
    icon: "tim-icons icon-book-bookmark",
    state: "expenseCollapse",
    dbName: 'Expense Manager',
    views: [
      {
        path: "/approverejectexpense",
        name: "Approve Reject Expense",
        mini: "EM",
        component: ApproveRejectExpense,
        layout: "/manager",
        dbName: 'Approve Reject Expenses',
      },
      {
        path: "/expensemanager",
        name: "Expense Manager",
        mini: "EM",
        component: ExpenseManager,
        layout: "/manager",
        dbName: 'Expense Manager',
      }
    ]
  },
  {
    path: "/holidaylist",
    name: "Holiday List",
    mini: "HL",
    icon: "tim-icons icon-map-big",
    component: HolidayList,
    layout: "/manager",
    dbName: 'Holiday List',
  },
  {
    collapse: true,
    name: "Leave Management",
    icon: "tim-icons icon-credit-card",
    state: "leaveCollapse",
    dbName: 'Leave Management',
    views: [
      {
        path: "/leavedetails",
        name: "Leave Details",
        mini: "LD",
        component: LeaveDetails,
        layout: "/manager",
        dbName: 'Leave Details',
      },
      {
        path: "/manageleave",
        name: "Manage Leaves",
        mini: "ML",
        component: ManageLeave,
        layout: "/manager",
        dbName: 'Manage Leaves',
      },
      {
        path: "/leaveReportDashboard",
        name: "Leaves Report",
        mini: "LR",
        component: LeaveHistoryDetails,
        layout: "/manager",
        dbName: 'Leave Report',
      }
    ]
  },
  {
    collapse: true,
    name: "Masters",
    icon: "tim-icons icon-badge",
    state: "reportCollapse",
    dbName: 'Masters',
    views: [
      {
        path: "/clientmaster",
        name: "Client Master",
        mini: "CM",
        component: ClientMaster,
        layout: "/manager",
        dbName: 'Client Master',
      },
      {
        path: "/companymaster",
        name: "Company Master",
        mini: "PM",
        component: CompanyMaster,
        layout: '/manager',
        dbName: 'Company Master',
      },
      {
        path: "/companylocationmaster",
        name: "Company Location Master",
        mini: "CLM",
        component: CompanyLocationMaster,
        layout: '/manager',
        dbName: 'Comapny Location Masters',
      },
      {
        path: "/countrymaster",
        name: "Country Master",
        mini: "PM",
        component: CountryMaster,
        layout: '/manager',
        dbName: 'Country Master'
      },
      {
        path: "/departmentmaster",
        name: "Department Master",
        mini: "DM",
        component: DepartmentMaster,
        layout: "/manager",
        dbName: 'Department Master'
      },
      {
        path: "/designationmaster",
        name: "Designation Master",
        mini: "DM",
        component: DesignationMaster,
        layout: "/manager",
        dbName: 'Designation Master'
      },
      {
        path: "/employeemaster",
        name: "Employee Master",
        mini: "EM",
        component: EmployeeMaster,
        layout: "/manager",
        dbName: 'Employee Master'
      },
      {
        path: "/vendormaster",
        name: "Vendor Master",
        mini: "VM",
        component: VendorMaster,
        layout: '/manager',
        dbName: 'Vendor Master',
      },
      {
        path: "/leavetypemaster",
        name: "Leave Type Master",
        mini: "LT",
        component: LeaveTypeMaster,
        layout: '/manager',
        dbName: 'Leave Type Master'
      },
      {
        path: "/modulemaster",
        name: "Module Master",
        mini: "MM",
        component: ModuleMaster,
        layout: '/manager',
        dbName: 'Module Master'
      },
      {
        path: "/projectmaster",
        name: "Project Master",
        mini: "PM",
        component: ProjectMaster,
        layout: '/manager',
        dbName: 'Project Master',
      },
      {
        path: "/rolesmaster",
        name: "Roles Master",
        mini: "RM",
        component: RolesMaster,
        layout: '/manager',
        dbName: 'Roles Master',
      },
      {
        path: "/rolemoduleaccess",
        name: "Roles Module Access",
        mini: "RAM",
        component: RolesModuleAccess,
        layout: '/manager',
        dbName: 'Roles Module Access',
      },
    ]
  },
  {
    collapse: true,
    name: "Report Dashboard",
    icon: "tim-icons icon-single-copy-04",
    state: "reportDashboard",
    dbName: 'Report Dashboard',
    views: [
      {
        path: "/leaveReportDashboard",
        name: "Leave Report",
        mini: "LR",
        component: LeaveReportDashboard,
        layout: "/manager",
        dbName: 'Leave Report',
      },
      {
        path: "/TimesheetHourlyReport",
        name: "Timesheet Hourly",
        mini: "TRD",
        component: TimesheetHourlyReport,
        layout: "/manager",
        dbName: 'Timesheet Hourly',
      },
      {
        path: "/timesheetReportDashboard",
        name: "Timesheet Report",
        mini: "TRD",
        component: TimesheetReportDashboard,
        layout: "/manager",
        dbName: 'Timesheet Report',
      },

    ]
  },
  {
    collapse: true,
    name: "Timesheet",
    icon: "tim-icons icon-calendar-60",
    state: "timesheetCollapse",
    dbName: 'Timesheet',
    views: [
      {
        path: "/enterTimesheet",
        name: "Enter Timesheet",
        mini: "ET",
        component: EnterTimesheet,
        layout: "/manager",
        dbName: 'Enter Timesheet',
      },
      {
        path: "/viewTimesheet",
        name: "View Timesheet",
        mini: "VT",
        component: TimesheetReport,
        layout: "/manager",
        dbName: 'View Timesheet',
      },
      {
        path: "/approveRejectTimesheet",
        name: "Approve and Reject",
        mini: "AR",
        component: ApproveRejectTimesheet,
        layout: "/manager",
        dbName: 'Approve Reject Timesheet',
      }    
    ]
  },
  {
    collapse: true,
    name: "ESS Report",
    icon: "tim-icons icon-notes",
    state: "manageTimesheetCollapse",
    dbName: 'Timesheet Report',
    views: [

      {
        path: "/viewTimesheetReport",
        name: "Timesheet Detail report",
        mini: "MTR",
        component: ViewTimesheetReport,
        layout: "/manager",
        dbName: 'Employees Timesheet Report',
      },
      {
        path: "/projectMarginReport",
        name: "Project Margin Report",
        mini: "PMR",
        component: projectMarginReport,
        layout: "/manager",
        dbName: 'Project Margin Report',
      },
      {
        path: "/contractorAllocationReport",
        name: "Contractor Report",
        mini: "CR",
        component: ContractorAllocationReport,
        layout: "/manager",
        dbName: 'Contractor Allocation Report',
      },
    ]
  },
  {
    collapse: true,
    name: "Appraisal",
    icon: "tim-icons icon-calendar-60",
    state: "appraisalCollapse",
    dbName: 'Appraisal',
    views: [
      {
        path: "/employee-appraisal",
        name: "Appraisal Form",
        mini: "EA",
        component: EmployeeAppraisal,
        layout: "/manager",
        dbName: 'Appraisal Form',
      },
      {
        path: "/appraisal-list",
        name: "Appraisal List",
        mini: "AL",
        component: ManagerAppraisalList,
        layout: "/manager",
        dbName: 'Appraisal List',
      }  
    ]
  },
];
export const HRRoutes = [
  {
    collapse: true,
    name: "Appraisal",
    icon: "tim-icons icon-calendar-60",
    state: "appraisalCollapse",
    dbName: 'Appraisal',
    views: [
      {
        path: "/appraisal-list",
        name: "Appraisal List",
        mini: "ET",
        component: HRAppraisalList,
        layout: "/humanresource",
        dbName: 'Appraisal List',
      }
    ]
  }
];
export const redirectRoutes = [
  {
    path: "/add-update-employee",
    name: "Add/Update Employee",
    redirect: true,
    component: AddUpdateStepper,
    layout: "/admin",
  },
  {
    path: "/add-update-project",
    name: "Add/Update Project",
    redirect: true,
    component: AddUpdateProjectStepper,
    layout: "/admin"
  },
  {
    path: "/add-update-company",
    name: "Add/Update Company",
    redirect: true,
    component: AddEditCompanyStepper,
    layout: "/admin"
  },
  {
    path: "/create-expense",
    name: "Add/Update Expense",
    redirect: true,
    component: CreateExpense,
    layout: "/admin"
  },
  {
    path: "/user-profile",
    name: "User Profile",
    redirect: true,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/manager-appraisal",
    name: "",
    redirect: true,
    component: ManagerAppraisal,
    layout: "/manager"
  }
];

export const sidebarRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: AdminDashboard,
    layout: "/admin",
    dbName: 'Dashboard',
  },
  {
    collapse: true,
    name: "Expense Manager",
    icon: "tim-icons icon-book-bookmark",
    state: "expenseCollapse",
    dbName: 'Expense Manager',
    views: [
      {
        path: "/approverejectexpense",
        name: "Approve Reject Expense",
        mini: "EM",
        component: ApproveRejectExpense,
        layout: "/admin",
        dbName: 'Approve Reject Expenses',
      },
      {
        path: "/expensemanager",
        name: "Expense Manager",
        mini: "EM",
        component: ExpenseManager,
        layout: "/admin",
        dbName: 'Expense Manager',
      }
    ]
  },
  {
    path: "/holidaylist",
    name: "Holiday List",
    mini: "HL",
    icon: "tim-icons icon-map-big",
    component: HolidayList,
    layout: "/admin",
    dbName: 'Holiday List',
  },
  {
    collapse: true,
    name: "Leave Management",
    icon: "tim-icons icon-credit-card",
    state: "leaveCollapse",
    dbName: 'Leave Management',
    views: [
      {
        path: "/leavedetails",
        name: "Leave Details",
        mini: "LD",
        component: LeaveDetails,
        layout: "/admin",
        dbName: 'Leave Details',
      },
      {
        path: "/manageleave",
        name: "Manage Leaves",
        mini: "ML",
        component: ManageLeave,
        layout: "/admin",
        dbName: 'Manage Leaves',
      },
      {
        path: "/leaveReportDashboard",
        name: "Leaves Report",
        mini: "LR",
        component: LeaveHistoryDetails,
        layout: "/admin",
        dbName: 'Leave Report',
      }
    ]
  },
  {
    collapse: true,
    name: "Masters",
    icon: "tim-icons icon-badge",
    state: "reportCollapse",
    dbName: 'Masters',
    views: [
      {
        path: "/clientmaster",
        name: "Client Master",
        mini: "CM",
        component: ClientMaster,
        layout: "/admin",
        dbName: 'Client Master',
      },
      {
        path: "/companymaster",
        name: "Company Master",
        mini: "PM",
        component: CompanyMaster,
        layout: '/admin',
        dbName: 'Company Master',
      },
      {
        path: "/companylocationmaster",
        name: "Company Location Master",
        mini: "CLM",
        component: CompanyLocationMaster,
        layout: '/admin',
        dbName: 'Comapny Location Masters',
      },
      {
        path: "/countrymaster",
        name: "Country Master",
        mini: "PM",
        component: CountryMaster,
        layout: '/admin',
        dbName: 'Country Master'
      },
      {
        path: "/departmentmaster",
        name: "Department Master",
        mini: "DM",
        component: DepartmentMaster,
        layout: "/admin",
        dbName: 'Department Master'
      },
      {
        path: "/designationmaster",
        name: "Designation Master",
        mini: "DM",
        component: DesignationMaster,
        layout: "/admin",
        dbName: 'Designation Master'
      },
      {
        path: "/employeemaster",
        name: "Employee Master",
        mini: "EM",
        component: EmployeeMaster,
        layout: "/admin",
        dbName: 'Employee Master'
      },
      {
        path: "/vendormaster",
        name: "Vendor Master",
        mini: "VM",
        component: VendorMaster,
        layout: '/admin',
        dbName: 'Vendor Master',
      },
      {
        path: "/leavetypemaster",
        name: "Leave Type Master",
        mini: "LT",
        component: LeaveTypeMaster,
        layout: '/admin',
        dbName: 'Leave Type Master'
      },
      {
        path: "/modulemaster",
        name: "Module Master",
        mini: "MM",
        component: ModuleMaster,
        layout: '/admin',
        dbName: 'Module Master'
      },
      {
        path: "/projectmaster",
        name: "Project Master",
        mini: "PM",
        component: ProjectMaster,
        layout: '/admin',
        dbName: 'Project Master',
      },
      {
        path: "/rolesmaster",
        name: "Roles Master",
        mini: "RM",
        component: RolesMaster,
        layout: '/admin',
        dbName: 'Roles Master',
      },
      {
        path: "/rolemoduleaccess",
        name: "Roles Module Access",
        mini: "RAM",
        component: RolesModuleAccess,
        layout: '/admin',
        dbName: 'Roles Module Access',
      },
    ]
  },
  {
    collapse: true,
    name: "Report Dashboard",
    icon: "tim-icons icon-single-copy-04",
    state: "reportDashboard",
    dbName: 'Report Dashboard',
    views: [
      {
        path: "/leaveReportDashboard",
        name: "Leave Report",
        mini: "LR",
        component: LeaveReportDashboard,
        layout: "/admin",
        dbName: 'Leave Report',
      },
      {
        path: "/TimesheetHourlyReport",
        name: "Timesheet Hourly",
        mini: "TRD",
        component: TimesheetHourlyReport,
        layout: "/admin",
        dbName: 'Timesheet Hourly',
      },
      {
        path: "/timesheetReportDashboard",
        name: "Timesheet Report",
        mini: "TRD",
        component: TimesheetReportDashboard,
        layout: "/admin",
        dbName: 'Timesheet Report',
      }
    ]
  },
  {
    collapse: true,
    name: "Timesheet",
    icon: "tim-icons icon-calendar-60",
    state: "timesheetCollapse",
    dbName: 'Timesheet',
    views: [
      {
        path: "/enterTimesheet",
        name: "Enter Timesheet",
        mini: "ET",
        component: EnterTimesheet,
        layout: "/admin",
        dbName: 'Enter Timesheet',
      },
      {
        path: "/viewTimesheet",
        name: "View Timesheet",
        mini: "VT",
        component: TimesheetReport,
        layout: "/admin",
        dbName: 'View Timesheet',
      },
      {
        path: "/approveRejectTimesheet",
        name: "Approve and Reject",
        mini: "AR",
        component: ApproveRejectTimesheet,
        layout: "/admin",
        dbName: 'Approve Reject Timesheet',
      }     
    ]
  },
  {
    collapse: true,
    name: "ESS Report",
    icon: "tim-icons icon-notes",
    state: "manageTimesheetCollapse",
    dbName: 'Timesheet Report',
    views: [

      {
        path: "/viewTimesheetReport",
        name: "Timesheet Detail report",
        mini: "MTR",
        component: ViewTimesheetReport,
        layout: "/admin",
        dbName: 'Employees Timesheet Report',
      },
      {
        path: "/projectMarginReport",
        name: "Project Margin Report",
        mini: "PMR",
        component: projectMarginReport,
        layout: "/admin",
        dbName: 'Project Margin Report',
      },
      {
        path: "/contractorAllocationReport",
        name: "Contractor Report",
        mini: "CR",
        component: ContractorAllocationReport,
        layout: "/admin",
        dbName: 'Contractor Allocation Report',
      },
      {
        path: "/expensereport",
        name: "Expense Report",
        mini: "ER",
        component: ExpenseReport,
        layout: "/admin",
        dbName: 'Expense Report',
      },
    ]
  },


];

export const routes = [
  ...authRoutes,
  ...redirectRoutes,
  ...sidebarRoutes,
  ...employeeRoutes,
  ...managerRoutes,
  ...HRRoutes
];
