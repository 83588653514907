import { addUpdateEmployeeAppraisalAPI, fetchEmployeeAppraisalByAppraisalIdAPI, fetchEmployeeAppraisalByUserIdAPI, fetchEmployeeAppraisalListAPI } from "../../services/appraisal-service";

export const FETCH_EMPLOYEE_APPRAISAL_LIST = 'FETCH_EMPLOYEE_APPRAISAL_LIST';
export const FETCH_EMPLOYEE_APPRAISAL_LIST_SUCCESS = 'FETCH_EMPLOYEE_APPRAISAL_LIST_SUCCESS';
export const FETCH_EMPLOYEE_APPRAISAL_LIST_ERROR = 'FETCH_EMPLOYEE_APPRAISAL_LIST_ERROR';
export const SET_EMPLOYEE_APPRAISAL_LIST_PAGINATION = "SET_EMPLOYEE_APPRAISAL_LIST_PAGINATION";

export const ADD_UPDATE_EMPLOYEE_APPRAISAL = "ADD_UPDATE_EMPLOYEE_APPRAISAL";
export const ADD_UPDATE_EMPLOYEE_APPRAISAL_SUCCESS = "ADD_UPDATE_EMPLOYEE_APPRAISAL_SUCCESS";
export const ADD_UPDATE_EMPLOYEE_APPRAISAL_ERROR = "ADD_UPDATE_EMPLOYEE_APPRAISAL_ERROR";

export const FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID = 'FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID';
export const FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_SUCCESS = 'FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_SUCCESS';
export const FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_ERROR = 'FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_ERROR';

export const FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID = 'FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID';
export const FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID_SUCCESS = 'FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID_SUCCESS';
export const FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID_ERROR = 'FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID_ERROR';



export const fetchEmployeeAllAppraisalList = (payload) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_EMPLOYEE_APPRAISAL_LIST,
        });
        const response = await fetchEmployeeAppraisalListAPI(payload);
        
        if (response.data) {
         
            dispatch({
                type: FETCH_EMPLOYEE_APPRAISAL_LIST_SUCCESS,
                employeeAppraisalList: response.data.items
            });
         
        }
        else {
            dispatch({
                type: FETCH_EMPLOYEE_APPRAISAL_LIST_ERROR,
            });
            return { success: false };
        }
    }
}


export const addUpdateEmployeeAppraisal = (payload) => {
    return async (dispatch) => {
        dispatch({ type: ADD_UPDATE_EMPLOYEE_APPRAISAL });

        const response = await addUpdateEmployeeAppraisalAPI(payload);
        
        if (response.data) {
            dispatch({
                type: ADD_UPDATE_EMPLOYEE_APPRAISAL_SUCCESS,
                employeeAppraisal: response.data
            });
            return { success: true, result: response.data };
        } else {
            dispatch({ type: ADD_UPDATE_EMPLOYEE_APPRAISAL_ERROR });
            return { success: false };
        }
    }
}

export const fetchEmployeeAppraisalByAppraisalId = (payload) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID });

        const response = await fetchEmployeeAppraisalByAppraisalIdAPI(payload);
        
        if (response.data) {
            dispatch({
                type: FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_SUCCESS,
                employeeAppraisal: response.data
            });
        }
        else {
            dispatch({ type: FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_ERROR });
            return { success: false };
        }
    }
}

export const fetchEmployeeAppraisalByUserId = (payload) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID });

        const response = await fetchEmployeeAppraisalByUserIdAPI(payload);
        
        if (response.data) {
            dispatch({
                type: FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID_SUCCESS,
                employeeAppraisal: response.data
            });
        }
        else {
            dispatch({ type: FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID_ERROR });
            return { success: false };
        }
    }
}

