import classNames from "classnames";
import jsPDF from "jspdf";
import "jspdf-autotable";
import _ from "lodash";
import moment from 'moment';
import React, { Component } from "react";
import ReactDatetime from "react-datetime";
import { FaRegFilePdf } from "react-icons/fa";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";
import { AppContext } from '../../hoc/AppContext';
import { getTimesheetMonthlyReport, getTimesheetQuarterlyReport, getTimesheetWeeklyReport } from '../../redux/actions/timesheetReportAction';
import TimesheetMonthlyReport from "./timesheetMonthlyReport";
import TimesheetQuarterlyReport from "./timesheetQuarterlyReport";
import TimesheetWeeklyReport from './timesheetWeeklyReport';
import { dateFormatList } from "variables/constants";


const quarter = moment().quarter();
const month = moment().format('M');
const year = moment().format('YYYY');

class TimesheetReport extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            monthlySelectedDate: moment().startOf('month').format('YYYY-MM-DD'),
            monthlyStatus: 'All',
            timesheetMonthlyReport: [],
            timesheetWeeklyReport: [],
            timesheetQuarterlyReport: [],
            reportType: "Quarterly",
            weeklySelectedDate: moment().startOf('week').format('YYYY-MM-DD'),
            weeklyStatus: 'All',
            selectedYear: moment().format('YYYY-MM-DD'),
            quarterlyStatus: 'All',
            selectedQuarter: moment().quarter(),
            isQuarterlyReportLoading: false,
            isMonthlyReportLoading: false,
            isWeeklyReportLoading: false,
        };
    }

    async UNSAFE_componentWillMount() {

        let quarterlyResponse = await this.props.getTimesheetQuarterlyReport(this.props.user.cid, this.props.user.eid, moment(this.state.selectedYear).startOf('month').format('YYYY-MM-DD'), moment(this.state.selectedYear).endOf('month').format('YYYY-MM-DD'), this.state.quarterlyStatus, quarter, month, year);
        if (quarterlyResponse.success) {
            this.setState({
                ...this.state,
                timesheetQuarterlyReport: this.props.timesheetQuarterlyReport
            })
        }

    }
    async UNSAFE_componentWillReceiveProps(nextProps) {

        if (!_.isEqual(this.props, nextProps)) {
            this.setState({
                ...this.state,
                timesheetMonthlyReport: this.props.timesheetMonthlyReport !== nextProps.timesheetMonthlyReport ? nextProps.timesheetMonthlyReport : this.props.timesheetMonthlyReport,
                timesheetQuarterlyReport: this.props.timesheetQuarterlyReport !== nextProps.timesheetQuarterlyReport ? nextProps.timesheetQuarterlyReport : this.props.timesheetQuarterlyReport,
                timesheetWeeklyReport: this.props.timesheetWeeklyReport !== nextProps.timesheetWeeklyReport ? nextProps.timesheetWeeklyReport : this.props.timesheetWeeklyReport
            })
        }

    }


    handleReportType = (e) => {
        const { user, } = this.props;
        const { cid, eid } = user;

        this.setState({
            ...this.state,
            reportType: e.target.value
        }, async () => {
            if (this.state.reportType === 'Quarterly') {
                await this.props.getTimesheetQuarterlyReport(cid, eid, moment(this.state.selectedYear).startOf('month').format('YYYY-MM-DD'), moment(this.state.selectedYear).endOf('month').format('YYYY-MM-DD'), this.state.quarterlyStatus, quarter, month, year);
            } else if (this.state.reportType === 'Monthly') {
                await this.props.getTimesheetMonthlyReport(cid, eid, moment(this.state.monthlySelectedDate).startOf('month').format('YYYY-MM-DD'), moment(this.state.monthlySelectedDate).endOf('month').format('YYYY-MM-DD'), this.state.monthlyStatus, quarter, month, year);
            } else if (this.state.reportType === 'Weekly') {
                await this.props.getTimesheetWeeklyReport(cid, eid, moment(this.state.weeklySelectedDate).startOf('week').format('YYYY-MM-DD'), moment(this.state.weeklySelectedDate).endOf('week').format('YYYY-MM-DD'), this.state.weeklyStatus, quarter, month, year);
            }
        });
    }

    handleMonthlySelectedDate = (date) => {
        this.setState({
            ...this.state,
            monthlySelectedDate: moment(date).startOf('month').format('YYYY-MM-DD')
        });
    }


    handleWeeklySelectedDate = (date) => {
        this.setState({
            ...this.state,
            // weeklySelectedDate: moment(date).startOf('week').format('YYYY-MM-DD')
            weeklySelectedDate: moment(date).format('YYYY-MM-DD')

        });
    }
    handleSelectedYear = (date) => {
        this.setState({
            ...this.state,
            selectedYear: moment(date).format('YYYY-MM-DD')
        });
    }


    handleStatus = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,

        });
    }

    handleSelectedQuarter = (e) => {

        this.setState({
            ...this.state,
            [e.target.name]: parseInt(e.target.value),
        });
    }




    notify = (place, type, message) => {

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 2
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        } setTimeout(() => {
            this.setState({
                isQuarterlyReportLoading: false,
                isMonthlyReportLoading: false,
                isWeeklyReportLoading: false
                // isSubmitLoading: false
            })
        }, 2000);
    };



    handleFind = async () => {
        this.setState({
            ...this.state,
            isMonthlyReportLoading: true
        })
        var quarter = moment(this.state.monthlySelectedDate).quarter();
        var month = moment(this.state.monthlySelectedDate).format('M');
        var year = moment(this.state.monthlySelectedDate).format('YYYY');

        let result = await this.props.getTimesheetMonthlyReport(this.props.user.cid, this.props.user.eid, moment(this.state.monthlySelectedDate).startOf('month').format('YYYY-MM-DD'), moment(this.state.monthlySelectedDate).endOf('month').format('YYYY-MM-DD'), this.state.monthlyStatus, quarter, month, year);
        if (result.success) {
            if (this.props.timesheetMonthlyReport.length > 0) {
                this.notify("tr", "success", "Fetched timesheet report successfully.");
            } else {
                this.notify("tr", "success", "No data found.");
            }
        } else {
            this.setState({
                ...this.state,
                timesheetMonthlyReport: []
            }, () => {
                this.notify("tr", "warning", "Failed to fetch timesheet report!");
            });
        }
    }

    handleWeeklyFind = async () => {
        this.setState({
            isWeeklyReportLoading: true
        });
        var quarter = moment(this.state.weeklySelectedDate).quarter();
        var month = moment(this.state.weeklySelectedDate).format('M');
        var year = moment(this.state.weeklySelectedDate).format('YYYY');

        let result = await this.props.getTimesheetWeeklyReport(this.props.user.cid, this.props.user.eid, moment(this.state.weeklySelectedDate).startOf('week').format('YYYY-MM-DD'), moment(this.state.weeklySelectedDate).endOf('week').format('YYYY-MM-DD'), this.state.weeklyStatus, quarter, month, year);
        if (result.success) {
            if (this.props.timesheetWeeklyReport.length > 0) {
                this.notify("tr", "success", "Fetched timesheet report successfully.");
            } else {
                this.notify("tr", "success", "No data found.");
            }
        } else {
            this.setState({
                ...this.state,
                timesheetWeeklyReport: []
            }, () => {
                this.notify("tr", "warning", "Failed to fetch timesheet report!");
            });
        }
    }

    handleQuarterlyFind = async () => {
        this.setState({
            ...this.state,
            isQuarterlyReportLoading: true
        })
        var quarter = this.state.selectedQuarter;
        var month = moment(this.state.selectedYear).format('M');
        var year = moment(this.state.selectedYear).format('YYYY');
        let result = await this.props.getTimesheetQuarterlyReport(this.props.user.cid, this.props.user.eid, moment(this.state.selectedYear).startOf('month').format('YYYY-MM-DD'), moment(this.state.selectedYear).endOf('month').format('YYYY-MM-DD'), this.state.quarterlyStatus, quarter, month, year);
        // let result = await this.props.getTimesheetMonthlyReport(this.props.user.cid, this.props.user.eid, moment(this.state.monthlySelectedDate).startOf('month').format('YYYY-MM-DD'), moment(this.state.monthlySelectedDate).endOf('month').format('YYYY-MM-DD'), this.state.monthlyStatus, quarter, month, year);

        if (result.success) {
            if (this.props.timesheetQuarterlyReport.length > 0) {
                this.notify("tr", "success", "Fetched timesheet report successfully.");
            } else {
                this.notify("tr", "success", "No data found.");
            }
        } else {
            this.setState({
                ...this.state,
                timesheetQuarterlyReport: []
            }, () => {
                this.notify("tr", "warning", "Failed to fetch timesheet report!");
            });
        }
    }

    handleExportPDF = (Type) => {

        if (this.props.employeeInfo) {
            const { empName, eid, designation, officiaEmail, personalEmail, role } = this.props.employeeInfo
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape

            const marginLeft = 40;

            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(15);

            const employeeData = [
                ['Employee Name:', empName, 'Designation:', designation],
                ['Employee No:', eid, 'Role:', role],
                ['Email:', officiaEmail],

            ];

            const title = Type === "Monthly" ? "Timesheet Monthy Report" : Type === "Weekly" ? "Timesheet Weekly Report" : "Timesheet Quarterly Report";

            var employeeContent = {
                startY: 80,
                head: null,
                body: employeeData,
                tableWidth: 550,
                theme: 'plain',
                styles: {
                    cellPadding: 0,
                    minCellHeight: 18,
                    halign: 'left',
                    valign: 'middle',
                    fontSize: 11,
                },
                columnStyles: {
                    0: { cellWidth: 100 },
                    1: { cellWidth: 200 },
                    2: { cellWidth: 70 }
                },

            };
            doc.setFontSize(20);
            doc.text(title, marginLeft, 40);

            doc.autoTable(employeeContent);

            if (Type === 'Monthly') {
                const monthlyHeaders = [["Month", "Project", "Total Hours", "Status",]];

                const monthlyData = this.state.timesheetMonthlyReport.map(reportItem => [moment(this.state.monthlySelectedDate).month(reportItem.month).format("MMM YYYY"), reportItem.project, reportItem.totalHours, reportItem.status === "New" ? "Saved" : reportItem.status]);
                var monthlyContent = {
                    startY: 160,
                    head: monthlyHeaders,
                    body: monthlyData
                };
                // doc.autoTable(employeeContent);
                doc.autoTable(monthlyContent);

                let selectedMonth = moment(this.state.monthlySelectedDate).subtract(0, "month").startOf("month").format('MMMM');
                let selectedYear = moment(this.state.monthlySelectedDate).year(this.state.monthlySelectedDate).format("YYYY");
                let selectedStatus = this.state.monthlyStatus === "New" ? 'Saved' : this.state.monthlyStatus;

                doc.save(`Timesheet-${selectedYear}-${selectedMonth}-${selectedStatus}.pdf`);

            } else if (Type === 'Weekly') {
                const weeklyHeaders = [["Week Start Date", "Project", "Total Hours", "Status"]];
                const weeklyData = this.state.timesheetWeeklyReport.map(reportItem => [moment(reportItem.startDate).format("Do MMM YYYY"), reportItem.project, reportItem.totalHours, reportItem.status === "New" ? "Saved" : reportItem.status]);

                var weeklyContent = {
                    startY: 160,
                    head: weeklyHeaders,
                    body: weeklyData
                };

                doc.autoTable(weeklyContent);

                let selectedWeek = moment(this.state.weeklySelectedDate).format('YYYY-MM-DD');
                doc.save(`Timesheet-${selectedWeek}.pdf`);

            } else {
                const quarterlyHeaders = [["Quarter", "Project", "Total Hours", "Status"]];
                const quarterlyData = this.state.timesheetQuarterlyReport.map(reportItem => [moment(this.state.selectedYear).year(this.state.selectedYear).format("YYYY") + ' - ' + reportItem.quarter, reportItem.project, reportItem.totalHours, reportItem.status === "New" ? "Saved" : reportItem.status]);

                var quarterlyContent = {
                    startY: 160,
                    head: quarterlyHeaders,
                    body: quarterlyData
                };

                doc.autoTable(quarterlyContent);

                let selectedQuarter = this.state.selectedQuarter === 1 ? "Q1" : this.state.selectedQuarter === 2 ? "Q2" : this.state.selectedQuarter === 3 ? "Q3" : "Q4";
                let selectedYear = moment(this.state.selectedYear).year(this.state.selectedYear).format("YYYY");
                let selectedStatus = this.state.quarterlyStatus === "New" ? 'Saved' : this.state.quarterlyStatus;

                doc.save(`Timesheet-${selectedYear}-${selectedQuarter}-${selectedStatus}.pdf`);
            }

        }
        else {
            this.notify("tr", "warning", "Failed to export PDF!");
        }
    }



    render() {
        const { config } = this.context;

        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    {/* {this.state.showModal && <TimesheetReportModal modal={this.state.showModal} timesheet={this.props.timesheet} toggle={this.toggleShowModal} projects={this.props.projects} onSubmit={this.handleSubmit} close={this.onClose} />} */}

                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{}}>
                                    <CardTitle tag="h2" style={{ marginBottom: 5 }}>Timesheet Report </CardTitle>
                                    <div className="">
                                        <FormGroup>
                                            <FormGroup check className="form-check-radio form-check-inline">
                                                <Label className="form-check-label">
                                                    <Input type="radio" name="exampleRadios1" id="Quarterly" onChange={this.handleReportType} checked={this.state.reportType === "Quarterly"} value="Quarterly" />
                                                    Quarterly
                                                    <span className="form-check-sign"></span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check className="form-check-radio form-check-inline">
                                                <Label className="form-check-label">
                                                    <Input type="radio" name="exampleRadios1" id="Monthly" onChange={this.handleReportType} checked={this.state.reportType === "Monthly"} value="Monthly" />
                                                    Monthly
                                                    <span className="form-check-sign"></span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check className="form-check-radio form-check-inline">
                                                <Label className="form-check-label">
                                                    <Input type="radio" name="exampleRadios1" id="Weekly" onChange={this.handleReportType} checked={this.state.reportType === "Weekly"} value="Weekly" />
                                                    Weekly
                                                    <span className="form-check-sign"></span>
                                                </Label>
                                            </FormGroup>

                                        </FormGroup>
                                    </div>
                                    {this.state.reportType === "Monthly" ? (
                                        <div>
                                            <Row style={{ display: 'flex', alignItems: 'center' }} >
                                                <Col md="2" >
                                                    <Label >Select Month </Label>
                                                    <FormGroup className={`has-label`}>
                                                        <ReactDatetime
                                                            // onViewModeChange="months"
                                                            dateFormat="YYYY-MM"
                                                            viewMode="months"
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            inputProps={{ placeholder: "Start Date", className: "form-control", value: moment(this.state.monthlySelectedDate).format('MMMM') }}
                                                            onChange={this.handleMonthlySelectedDate}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="2">
                                                    <Label for="status">Status</Label>
                                                    <FormGroup className={`has-label  `}>
                                                        <Input type="select" name="monthlyStatus" value={this.state.monthlyStatus} onChange={this.handleStatus} id="status">
                                                            <option value="All">All</option>
                                                            <option value="Approved">Approved</option>
                                                            <option value="Rejected">Rejected</option>
                                                            <option value="New">Saved</option>
                                                            <option value="Submitted">Submitted</option>
                                                            <option value="Resubmitted">Resubmitted</option>
                                                        </Input>
                                                        {this.state.clientIdState === "has-danger" ? (
                                                            <label className="error">please select client!</label>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <div style={{ marginTop: 14 }}>
                                                        <div style={{}}>
                                                            <Button onClick={() => this.state.isMonthlyReportLoading === true ? null : this.handleFind()} style={{ width: 70 }} size="sm" color="info">
                                                                {this.state.isMonthlyReportLoading === true ?
                                                                    <Spinner as="span"
                                                                        animation="grow"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true" /> :
                                                                    'Find'}
                                                            </Button>
                                                            {/* <Button onClick={() => this.handleExportPDF('Monthly')} size="sm" color="secondary">Export To PDF</Button> */}
                                                            <Button
                                                                size="sm"
                                                                style={{ marginLeft: 25, marginRight: 25 }}
                                                                id="tooltip209896"
                                                                onClick={() => this.handleExportPDF('Monthly')}
                                                                className={classNames("btn-icon btn-link like", {

                                                                })}
                                                            >
                                                                <FaRegFilePdf className="custom-icon" />
                                                            </Button>
                                                            <UncontrolledTooltip
                                                                delay={0}
                                                                target="tooltip209896"
                                                                placement="top"
                                                            >
                                                                Export To PDF
                                                            </UncontrolledTooltip>
                                                        </div>
                                                        {/* <div style={{ display: 'inline-flex', }}>
                                                                      <Button onClick={this.handleExportPDF} size="sm" color="secondary">Export To PDF</Button>
                                                                  </div> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <TimesheetMonthlyReport
                                                timesheetMonthlyReport={this.state.timesheetMonthlyReport}
                                                monthlySelectedDate={this.state.monthlySelectedDate}
                                                pageSize={config.paginationSize}
                                            />
                                        </div>
                                    ) : this.state.reportType === "Quarterly" ? (
                                        <div>
                                            <Row style={{ display: 'flex', alignItems: 'center' }} >
                                                <Col md="2" >
                                                    <Label >Select Year </Label>
                                                    <FormGroup className={`has-label`}>
                                                        <ReactDatetime
                                                            // onViewModeChange="months"
                                                            dateFormat="YYYY"
                                                            viewMode="years"
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            inputProps={{ placeholder: "Select Quarter", className: "form-control", value: moment(this.state.selectedYear).format('YYYY') }}
                                                            onChange={this.handleSelectedYear}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="2">
                                                    <Label for="quarter">Select Quarter</Label>
                                                    <FormGroup className={`has-label  `}>
                                                        <Input type="select" name="selectedQuarter" value={this.state.selectedQuarter} onChange={this.handleSelectedQuarter} id="quarter">
                                                            <option value={1}>Q1</option>
                                                            <option value={2}>Q2</option>
                                                            <option value={3}>Q3</option>
                                                            <option value={4}>Q4</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="2">
                                                    <Label for="quarterStatus">Status</Label>
                                                    <FormGroup className={`has-label  `}>
                                                        <Input type="select" name="quarterlyStatus" value={this.state.quarterlyStatus} onChange={this.handleStatus} id="quarterStatus">
                                                            <option value="All">All</option>
                                                            <option value="Approved">Approved</option>
                                                            <option value="Rejected">Rejected</option>
                                                            <option value="New">Saved</option>
                                                            <option value="Submitted">Submitted</option>
                                                            <option value="Resubmitted">Resubmitted</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <div style={{ marginTop: 14 }}>
                                                        <div style={{}}>
                                                            <Button onClick={() => this.state.isQuarterlyReportLoading === true ? null : this.handleQuarterlyFind()} style={{ width: 70 }} size="sm" color="info">
                                                                {this.state.isQuarterlyReportLoading === true ?
                                                                    <Spinner as="span"
                                                                        animation="grow"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true" /> :
                                                                    'Find'}</Button>
                                                            {/* <Button onClick={() => this.handleExportPDF('Quarterly')} size="sm" color="secondary">Export To PDF</Button> */}
                                                            <Button
                                                                size="sm"
                                                                style={{ marginLeft: 25, marginRight: 25 }}
                                                                id="tooltip209897"
                                                                onClick={() => this.handleExportPDF('Quarterly')}
                                                                className={classNames("btn-icon btn-link like", {

                                                                })}
                                                            >
                                                                <FaRegFilePdf className="custom-icon" />
                                                            </Button>
                                                            <UncontrolledTooltip
                                                                delay={0}
                                                                target="tooltip209897"
                                                                placement="top"
                                                            >
                                                                Export To PDF
                                                            </UncontrolledTooltip>
                                                        </div>
                                                        {/* <div style={{ display: 'inline-flex', }}>
                                                                  <Button onClick={this.handleExportPDF} size="sm" color="secondary">Export To PDF</Button>
                                                              </div> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <TimesheetQuarterlyReport
                                                timesheetQuarterlyReport={this.state.timesheetQuarterlyReport}
                                                selectedYear={this.state.selectedYear}
                                                pageSize={config.paginationSize}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <Row style={{ display: 'flex', alignItems: 'center' }} >
                                                <Col md="2" >
                                                    <Label >Select Week </Label>
                                                    <FormGroup className={`has-label`}>
                                                        <ReactDatetime
                                                            // onViewModeChange="months"
                                                            dateFormat={dateFormatList[2]}
                                                            viewMode="days"
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            inputProps={{ placeholder: "Select Month", className: "form-control", value: moment(this.state.weeklySelectedDate).format(dateFormatList[2]) }}
                                                            onChange={this.handleWeeklySelectedDate}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="2">
                                                    <Label for="weeklyStatus">Status</Label>
                                                    <FormGroup className={`has-label  `}>
                                                        <Input type="select" name="weeklyStatus" value={this.state.weeklyStatus} onChange={this.handleStatus} id="weeklyStatus">
                                                            <option value="All">All</option>
                                                            <option value="Approved">Approved</option>
                                                            <option value="Rejected">Rejected</option>
                                                            <option value="New">Saved</option>
                                                            <option value="Submitted">Submitted</option>
                                                            <option value="Resubmitted">Resubmitted</option>
                                                        </Input>
                                                        {this.state.clientIdState === "has-danger" ? (
                                                            <label className="error">please select client!</label>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <div style={{ marginTop: 14 }}>
                                                        <div style={{}}>
                                                            <Button onClick={() => this.state.isWeeklyReportLoading === true ? null : this.handleWeeklyFind()} size="sm" style={{ width: 70 }} color="info">
                                                                {this.state.isWeeklyReportLoading === true ?
                                                                    <Spinner as="span"
                                                                        animation="grow"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true" /> :
                                                                    'Find'}
                                                            </Button>
                                                            {/* <Button onClick={() => this.handleExportPDF('Weekly')} size="sm" color="secondary">Export To PDF</Button> */}
                                                            <Button
                                                                size="sm"
                                                                style={{ marginLeft: 25, marginRight: 25 }}
                                                                id="tooltip209898"
                                                                onClick={() => this.handleExportPDF('Weekly')}
                                                                className={classNames("btn-icon btn-link like", {

                                                                })}
                                                            >
                                                                <FaRegFilePdf className="custom-icon" />
                                                            </Button>
                                                            <UncontrolledTooltip
                                                                delay={0}
                                                                target="tooltip209898"
                                                                placement="top"
                                                            >
                                                                Export To PDF
                                                            </UncontrolledTooltip>
                                                        </div>
                                                        {/* <div style={{ display: 'inline-flex', }}>
                                                                  <Button onClick={this.handleExportPDF} size="sm" color="secondary">Export To PDF</Button>
                                                              </div> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <TimesheetWeeklyReport
                                                timesheetWeeklyReport={this.state.timesheetWeeklyReport}
                                                pageSize={config.paginationSize}
                                            />
                                        </div>

                                    )}
                                </CardHeader>
                                <CardBody>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


export const mapDispatchToProps = dispatch => ({
    getTimesheetMonthlyReport: bindActionCreators(getTimesheetMonthlyReport, dispatch),
    getTimesheetWeeklyReport: bindActionCreators(getTimesheetWeeklyReport, dispatch),
    getTimesheetQuarterlyReport: bindActionCreators(getTimesheetQuarterlyReport, dispatch)
});


export const mapStateToProps = ({ auth, timesheet, timesheetReport }) => {

    return {
        token: auth.token,
        timesheetMonthlyReport: timesheetReport.timesheetMonthlyReport,
        timesheetWeeklyReport: timesheetReport.timesheetWeeklyReport,
        timesheetQuarterlyReport: timesheetReport.timesheetQuarterlyReport,
        employeeInfo: timesheetReport.employeeInfo,
        timesheet: timesheet,
        user: auth.user,

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(TimesheetReport);
