import classNames from "classnames";
import React from "react";
import moment from 'moment';
import classnames from "classnames";
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
  Form,
  FormGroup,
  Card,
  CardHeader,
  CardTitle,
  Button,
  CardBody,

} from "reactstrap";
import ReactTable from "react-table";
import NotificationAlert from "react-notification-alert";
import Pagination from "../../../../components/common/pagination";
import { REQUIRD_MESSAGE } from "../../../../variables/constants";
import CompanyContactModal from "../CompanyContactModal";

const dataTable = [
  [1, 1, "Branch 1", "Worli", "Street 1", "Mumbai", "Maharashtra", "India", "Y"],
  [2, 1, "Branch 2", "Abington", "Street 1", "London", "London", "UK", "Y"],
  [3, 1, "Branch 3", "Downtown", "Downtown street 1", "Los Angeles", "California", "US", "Y"]
];
class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      alert: null,
      cid: '',
      data: [],
      countries: [],
      company: {},
      contact: {
        id: 0,
        cid: 0,
        fname: '',
        mname: '',
        lname: '',
        mobile: '',
        officePhone: '',
        emailPersonal: '',
        emailOfficial: '',
        extension: '',
        isActive: '',
        isPrimary: ''
      },
      locations: []
    };
  }

  showDepartmentModal = (selectedLocation) => {
    this.setState({
      formType: 'Update',
      showModal: true,
      companyLocation: { ...selectedLocation }
    });
  };

  toggleShowModal = () => {

    this.setState({
      formType: 'Add',
      showModal: !this.state.showModal,
      companyLocation: {},

    });
  };

  onClose = () => {

    this.setState({
      showModal: false,
    });
  };



  handleSubmit = async (contact) => {

    let result = await this.props.submitContactInfo(contact);

    //let locations= this.state.locations;
    //locations.push(companyLocation);
    this.onClose();
    if (result.success) {
      //
      let message = {
        message: 'Company contact has been added successfully.',
        type: "success",
      };
      this.handleMessage(message);
      await this.props.getCompanyContacts(contact.cid);

    } else {
      let options = {};
      options = {
        place: "tr",
        message: 'Error occurred while adding company contact.',
        type: "warning",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 3
      };
      if (this.refs.notificationAlert) {
        this.refs.notificationAlert.notificationAlert(options);
      }
    }
  }

  handleMessage = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message.message,
      type: message.type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  isValidated = () => {
    if (this.props.contacts && this.props.contacts.length > 0) {
      if (this.props.companyLocations && this.props.companyLocations.length > 0) {
        this.props.getAllInitialCompanyConfigs(this.props.cid);
        return true;
      } else {
        // let message = {
        //   message: 'Kindly enter atleast one location in Company locations step',
        //   type: "warning",
        // };
        // this.handleMessage(message);
        this.basicAlert('Kindly enter atleast one location in Company locations step')
        return false;
      }
    }
    else {
      // let message = {
      //   message: 'Kindly enter atleast one company contact',
      //   type: "warning",
      // };
      // this.handleMessage(message);
      this.basicAlert('Kindly enter atleast one company contact')
      return false;
    }
  }


  UNSAFE_componentWillMount() {
    let locations = [];
    this.props.company.locations ? locations = this.props.company.locations : locations = []
    this.setState({
      company: {
        ...this.state.company,
        ...this.props.company,
        //cid:this.props.cid,
        countries: this.props.countries,
      },
    });
    //this.getTableData();

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      company: {
        ...nextProps.company,
        ...nextProps.company,
        cid: nextProps.cid,
        countries: nextProps.countries
      }
    });
    //this.getTableData();
  }

  basicAlert = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 2
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  showDepartmentModal = (contact) => {
    this.setState({
      formType: 'Update',
      showModal: true,
      contact: { ...contact }
    });
  };

  handleCancel = () => {
    let passProps = {
      pathname: 'companymaster',
    }
    this.props.history.push(passProps);
  }

  render() {
    //this.getTableData()
    let contacts = this.props.contacts.map((prop, key) => {
      return {
        id: prop.id,
        cid: prop.cid,
        fname: prop.fname,
        mname: prop.mname,
        lname: prop.lname,
        mobile: prop.mobile,
        officePhone: prop.officePhone,
        emailPersonal: prop.emailPersonal,
        emailOfficial: prop.emailOfficial,
        fullname: prop.fname + ' ' + prop.lname,
        extension: prop.extension,
        isActive: prop.isActive === "Y" ? true : false,
        isPrimary: prop.isPrimary === "Y" ? true : false,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              onClick={() => {
                let obj = this.props.contacts.find(o => o.id === prop.id);

                let { id,
                  cid,
                  fname,
                  lname,
                  mname,
                  mobile,
                  officePhone,
                  emailPersonal,
                  emailOfficial,
                  extension,
                  isActive,
                  isPrimary
                } = obj;
                let contact = {
                  id,
                  cid,
                  fname,
                  lname,
                  mname,
                  mobile,
                  officePhone,
                  emailPersonal,
                  emailOfficial,
                  extension,
                  isActive,
                  isPrimary
                }
                this.showDepartmentModal(contact);
              }}
              // color="warning"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              <i className="tim-icons icon-pencil" />
            </Button>
            {/* <FormGroup check>
                        <Label check>
                          <Input  defaultValue="" type="checkbox" />
                          <span className="form-check-sign">
                            <span className="check" />
                          </span>
                        </Label>
                      </FormGroup> */}
          </div>
        )
      };
    })
    return (
      <>
        <div className="content">

          <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
            {this.state.alert}
          </div>
          {this.state.showModal && <CompanyContactModal cid={this.props.company.id} contacts={this.props.contacts} modal={this.state.showModal} countries={this.props.countries} toggle={this.toggleShowModal} onSubmit={this.handleSubmit} formType={this.state.formType} contact={this.state.contact} close={this.onClose} />}
          <Row style={{ marginTop: '40px' }}>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CardTitle tag="h2">Contacts</CardTitle>
                  <div style={{}}>
                    <Button size="sm" onClick={this.toggleShowModal} color="info">New</Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    PaginationComponent={Pagination}
                    data={contacts}
                    // filterable
                    // nextText={myCustomNextText}
                    resizable={false}
                    columns={[
                      {
                        Header: "",
                        accessor: "actions",
                        width: 70,
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: "Name",
                        accessor: "fullname"
                      },
                      {
                        Header: "Official Email",
                        accessor: "emailOfficial"
                      },
                      {
                        Header: "Personal Email",
                        accessor: "emailPersonal"
                      },
                      {
                        Header: "Mobile No",
                        accessor: "mobile"
                      },

                      {
                        Header: "isActive",
                        accessor: "isActive"
                      },

                      // {
                      //     Header: "Company",
                      //     accessor: "company"
                      // },


                    ]}
                    NoDataComponent={() => null}
                    pageSize={contacts.length <= 5 ? contacts.length : 5}
                    className="-striped -highlight project-table"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Button onClick={() => { this.handleCancel() }} className="btn-cancel" size="sm" color="info">Cancel</Button>
      </>
    );
  }
}

export default Wizard;