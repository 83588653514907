import axios from 'axios';

export const showDashboardDetailsFromDb = (cid, eid, year) => {
  return axios.get(`/api/v1/employee-dashboard-counts`, {
    params: { cid: cid, eid: eid, year:year }
  }).then(function (response) {
    if (response.status == 200) {
      return { success: true, data: response.data };
    }
  })
    .catch(function (error) {
      return { success: false };
    });
}

export const getAnnouncementsFromDb = (cid, date) => {
  return axios.get(`/api/v1/announcements`, {
    params: { cid: cid, date: date }
  }).then(function (response) {
    if (response.status == 200) {
      return { success: true, data: response.data };
    }
  })
    .catch(function (error) {
      return { success: false };
    });
}

export const addUpdateAnnouncement = (announcementsDTO) => {
  return axios
    .post(`/api/v1/add-update-announcement`, announcementsDTO)
    .then(function (response) {
      if (response.status == 200) {
        return { success: true };
      }
    })
    .catch(function (error) {


      return { success: false };
    });
}