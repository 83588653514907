import { GET_ANNOUNCEMENTS } from '../actions/announcementActions.js';


const INITIAL_STATE = []

const announcementReducer = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case GET_ANNOUNCEMENTS:
            newState = action.announcements;
            return newState;

        default: return state;
  }
};

export default announcementReducer;