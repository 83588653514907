import _ from 'lodash';

import { LOGIN_SUCCESS, LOGOUT_SUCCESS, GET_ALL_COMPANY_CONFIGS, AUTH_REFRESH_SUCCESS } from '../actions/authActions';
import setJWTToken from "../../securityUtils/axiosConfig";

export const saveAuthData = (data) => {
  const user = {
    ...data
  }
  setJWTToken(data.jwttoken);
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('token', user.jwttoken);
};

export const saveCompnayConfigs = (companyConfigs) => {
  localStorage.setItem('companyConfigs', JSON.stringify([...companyConfigs]));
};

const getUser = () => {
  const userString = localStorage.getItem('user');
  if (userString && userString !== "") {
    const user = JSON.parse(userString);
    const moduleAccess = getUserModuleAccess(user.userModuleAccess);
    setJWTToken(user.jwttoken);
    return { user, moduleAccess };
  } else {
    setJWTToken(null);
    return { user: null, moduleAccess: null };
  }
}

const getUserModuleAccess = (userModuleAccess) => {
  const moduleAccess = [];
  userModuleAccess && userModuleAccess.forEach(module => {
    let access = null;
    if (module.access && module.access !== "") {
      access = JSON.parse(module.access);
    }
    moduleAccess.push({
      ...module,
      access
    });
  });
  let finalModuleAccess = [];
  if (moduleAccess.length > 0) {
    const mainModules = _.filter(moduleAccess, { parentId: 0 });
    mainModules.forEach((mainModule) => {
      const subModules = _.filter(moduleAccess, module => parseInt(mainModule.moduleId) === parseInt(module.parentId));
      finalModuleAccess.push({
        ...mainModule,
        subModules
      });
    });
  }
  return finalModuleAccess;
}

const getInitialState = () => {
  const { user, moduleAccess } = getUser();
  return {
    loading: true,
    user: user,
    moduleAccess: moduleAccess,
    token: localStorage.getItem('token'),
    companyConfigs: JSON.parse(localStorage.getItem('companyConfigs'))
  };
}

const authReducer = (state = getInitialState(), action) => {

  const { type, data } = action;
  let newState = { ...state };
  switch (type) {
    case LOGIN_SUCCESS:
      saveAuthData(data);
      const { user, moduleAccess } = getUser();
      newState = {
        ...state,
        loading: false,
        user: user,
        moduleAccess: moduleAccess,
        token: localStorage.getItem('token'),
      };
      break;
    case AUTH_REFRESH_SUCCESS:
      saveAuthData(data);
      newState = {
        ...state,
        loading: false,
        user: { ...state.user, ...data },
        token: localStorage.getItem('token'),
      };
      break;
    case LOGOUT_SUCCESS:
      sessionStorage.removeItem("USESSIONID")
      setJWTToken(null);
      newState = {
        ...state,
        loading: false,
        user: null,
        token: null,
        companyConfigs: null
      };
      break;
    case GET_ALL_COMPANY_CONFIGS:
      saveCompnayConfigs(action.companyConfigs)
      newState = {
        ...state,
        companyConfigs: JSON.parse(localStorage.getItem('companyConfigs')),
      }
      break;
    default:
  }
  return newState;
};

export default authReducer;
