import axios from 'axios';

export const changePassword = (cid, currentPassword, eid, newPassword) => {
  return axios.get(`/api/auth/change-password`, {
    params: {
      cid: cid,
      currentPassword: currentPassword,
      eid: eid,
      newPassword: newPassword
    }
  })
  .then(function (response) {
      if (response.data === true) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      if (error.response) {
        return { success: false, data: error.response.data.message };
      }
      else {
        return { success: false, data: 'Error occurred while changing password.' };
      }

    });
}

export const resetPasswordEmailLink = (emailOfficial, hostedUrl) => {
  return axios.get(`/api/auth/send-password-reset-link`, {
    params: {
      emailOfficial: emailOfficial,
      hostedurl: hostedUrl
    }
  })
  .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return error;
    });
}

export const resetPassword = (password, token) => {
  return axios.get(`/api/auth/reset-password`, {
    params: {
      password: password,
      token: token,
      otp: 0
    }
  })
  .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false }
      }
    })
    .catch(function (error) {
      return false;
    });
}

export const checkEmailAlreadyExists = (cid, emailOfficial) => {
  return axios.get(`/api/v1/check-email-official-exists`, {
    params: {
      cid: cid,
      emailOfficial: emailOfficial
    }
  })
  .then(function (response) {
    if (response.data) {
      return response.data
    } else {
      return false;
    }
  })
    .catch(function (error) {
      return false;
    });
}

export const checkEmpIdAlreadyExists = (cid, empid) => {
  return axios.get(`/api/v1/check-empId-exists`, {
    params: {
      cid: cid,
      empid: empid
    }
  })
  .then(function (response) {
    if (response.data) {
      return response.data
    } else {
      return false;
    }
  })
    .catch(function (error) {
      return false;
    });
}


export const uploadFile = (formData, onUploadProgress) => {
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => onUploadProgress(progressEvent),
  };
  return axios
    .post(`/api/v1/upload-file`, formData, options)
    .then(function (response) {
      return { success: true, data: response.data };
    })
    .catch(function (error) {
      return { success: false, data: null };
    });
};

export const deleteFile = (url) => {

  return axios.get(`/api/v1/delete-file`, {
    params: {
      url: url
    }
  })
  .then(function (response) {
    if (response) {
      return true
    } else {
      return false;
    }
  })
    .catch(function (error) {
      return false;
    });
}