import moment from 'moment';
import React, { Component } from "react";
import ReactDatetime from "react-datetime";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";
import Pagination from "../../components/common/pagination";
import { getManagerContractorsReport } from "../../redux/actions/contractorReportAction";
import LoadingIndicator from "../../components/common/LoadingIndicator";
import NoData from "../../components/common/NoData";
import { AppContext } from '../../hoc/AppContext';
import { dateFormatList } from 'variables/constants';


class ManagerContractorAllocationReport extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),
            isReportLoading: false,
            isDataLoading: false
        };
    }
    async UNSAFE_componentWillMount() {
        this.setState({
            ...this.state,
            isDataLoading: true
        }, async () => {
            let response = await this.props.getManagerContractorsReport(this.props.user.cid, this.props.user.eid, this.state.startDate, this.state.endDate);

            if (response) {
                this.setState({
                    ...this.state,
                    isDataLoading: false,
                });
            }

        });

    }


    notify = (place, type, message) => {

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 2
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
        setTimeout(() => {
            this.setState({
                ...this.state,
                isReportLoading: false,
                isDataLoading: false
            })
        }, 2000);
    };


    handleStartDate = (date) => {
        this.setState({
            ...this.state,
            startDate: moment(date).format('YYYY-MM-DD')
        });
    }
    handleEndDate = (date) => {
        this.setState({
            ...this.state,
            endDate: moment(date).format('YYYY-MM-DD')
        });
    }

    handleFind = async () => {
        this.setState({
            ...this.state,
            isReportLoading: true,
            isDataLoading: true
        }, async () => {
            let result = await this.props.getManagerContractorsReport(this.props.user.cid, this.props.user.eid, this.state.startDate, this.state.endDate);
            if (result.success) {

                this.setState({
                    ...this.state,
                    isDataLoading: false
                });
                if (result.data.length > 0) {
                    this.notify("tr", "success", "Fetched contractors report successfully.");
                } else {
                    this.setState({
                        ...this.state,
                        isDataLoading: false
                    });
                    this.notify("tr", "success", "No contractors found.");
                }

            } else {
                this.setState({
                    ...this.state,
                    isDataLoading: false
                });
                this.notify("tr", "warning", "Failed to fetch contractors report!");
            }
        })

    }

    render() {

        const myCustomNextText = () => {
            return
        };

        const { config } = this.context;
        const data = this.props.managerContractorsReport && this.props.managerContractorsReport.map((contractor, index) => {
            return {
                contractorName: contractor.contractorName,
                billingRate: (
                    <div className="actions-right custom-action-right custom-total-hours">
                        {contractor.billingRate}
                    </div>
                ),
                vendorName: contractor.vendorName,
                project: contractor.project,
                allocationPercent: (
                    <div className="actions-right custom-action-right custom-total-hours">
                        {contractor.allocationPercent}
                    </div>
                )
            };
        })


        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{}}>
                                    <Row style={{ display: 'flex', alignItems: 'center' }} >
                                        <Col style={{ maxWidth: 150, paddingLeft: 15, paddingRight: 5 }} >
                                            <Label >Select Start Date </Label>
                                            <FormGroup className={`has-label`}>
                                                <ReactDatetime
                                                    // onViewModeChange="months"
                                                    dateFormat="YYYY-MM-DD"
                                                    viewMode="days"
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: "Select Start Date", className: "form-control", value: moment(this.state.startDate).format(dateFormatList[2]) }}
                                                    onChange={this.handleStartDate}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col style={{ maxWidth: 150, paddingLeft: 15, paddingRight: 5 }} >
                                            <Label >Select End Date </Label>
                                            <FormGroup className={`has-label`}>
                                                <ReactDatetime
                                                    // onViewModeChange="months"
                                                    dateFormat="YYYY-MM-DD"
                                                    viewMode="days"
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: "Select End Date", className: "form-control", value: moment(this.state.endDate).format(dateFormatList[2]) }}
                                                    onChange={this.handleEndDate}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="auto" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                            <div style={{ marginTop: 14 }}>
                                                <div style={{}}>
                                                    <Button onClick={() => this.state.isReportLoading === true ? null : this.handleFind()} style={{ width: 70 }} size="sm" color="info">
                                                        {this.state.isReportLoading === true ?
                                                            <Spinner as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true" /> :
                                                            'Find'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={data}
                                        // filterable
                                        // nextText={myCustomNextText}
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "Contractor",
                                                accessor: "contractorName",
                                                minWidth: 120,
                                            },
                                            {
                                                Header: "Billing Rate/HR",
                                                accessor: "billingRate",
                                                headerClassName: 'header-right'
                                            },
                                            {
                                                Header: "Vendor",
                                                accessor: "vendorName",
                                                width: 200,
                                            },
                                            {
                                                Header: "Project",
                                                accessor: "project",
                                                width: 200,
                                            },
                                            {
                                                Header: "Percent Allocation",
                                                accessor: "allocationPercent",
                                                headerClassName: 'header-right'
                                            },


                                        ]}
                                        LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                                        NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                                        pageSize={this.props.managerContractorsReport.length <= config.paginationSize ? this.props.managerContractorsReport.length : config.paginationSize}
                                        className="-striped -highlight project-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export const mapDispatchToProps = dispatch => ({
    getManagerContractorsReport: bindActionCreators(getManagerContractorsReport, dispatch),
});

export const mapStateToProps = ({ auth, employees, contractorsReport }) => {

    return {
        token: auth.user.token,
        employees: employees,
        user: auth.user,
        managerContractorsReport: contractorsReport.managerContractorsReport === undefined ? [] : contractorsReport.managerContractorsReport,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagerContractorAllocationReport);
