import { FETCH_MANAGER_CONTRACTORS_REPORT, FETCH_ADMIN_CONTRACTORS_REPORT } from "../actions/contractorReportAction";
const INITIAL_STATE = {
    adminContractorsReport: [],
    managerContractorsReport: []
}
const contractorReportReducer = function (state = INITIAL_STATE, action) {
    let newState;

    switch (action.type) {
        case FETCH_MANAGER_CONTRACTORS_REPORT:
            newState = { ...state }
            newState.managerContractorsReport = action.managerContractorsReport;
            return newState;

        case FETCH_ADMIN_CONTRACTORS_REPORT:
            newState = { ...state }
            newState.adminContractorsReport = action.adminContractorsReport;
            return newState;

        default:
            return state;
    }
};

export default contractorReportReducer;