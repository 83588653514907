import { FETCH_ALL_EMPLOYEES_APPRAISAL, FETCH_ALL_EMPLOYEES_APPRAISAL_ERROR, FETCH_ALL_EMPLOYEES_APPRAISAL_SUCCESS, FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_SUCCESS, FETCH_EMPLOYEE_APPRAISAL_LIST, FETCH_EMPLOYEE_APPRAISAL_LIST_ERROR, FETCH_EMPLOYEE_APPRAISAL_LIST_SUCCESS, SET_EMPLOYEE_APPRAISAL_LIST_PAGINATION } from "../actions/hrAppraisalActions";

const INIT_STATE = {
    employeeAppraisalList: [],
    employeeAppraisal: null,
    loading: false,
    pagination: {
        currentPage: 1,
        pageSize: 10,
        totalPages: 0,
        totalItems: 0,
    }
}

const hrAppraisalReducer = function (state = INIT_STATE, action) {
    switch (action.type) {

        case FETCH_EMPLOYEE_APPRAISAL_LIST:
        case FETCH_ALL_EMPLOYEES_APPRAISAL: {
            return { ...state, loading: true };
        }
        case FETCH_EMPLOYEE_APPRAISAL_LIST_SUCCESS:
        case FETCH_ALL_EMPLOYEES_APPRAISAL_SUCCESS: {            
            return { ...state, employeeAppraisalList: [...action.employeeAppraisalList], loading: false };
        }
        case FETCH_EMPLOYEE_APPRAISAL_LIST_ERROR:
        case FETCH_ALL_EMPLOYEES_APPRAISAL_ERROR:
            return { ...state, loading: false, employeeAppraisalList: [] };

        case SET_EMPLOYEE_APPRAISAL_LIST_PAGINATION: {
            return { ...state, error: '', message: '', pagination: { ...action.pagination } };
        }

        case FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_SUCCESS: {
            return { ...state, employeeAppraisal: action.employeeAppraisal, loading: false };
        }


        default:
            return state;
    }

};

export default hrAppraisalReducer;