import { fetchEmployeeLeavesAPI, getAllEmployeesLeave } from "../../services/leaves-service";

export const GET_LEAVES = 'GET_LEAVES';

export const GET_ALL_EMPLOYEES_LEAVE = 'GET_ALL_EMPLOYEES_LEAVE';

export const fetchEmployeeLeaves = (cid, eid) => {
  return async (dispatch) => {
    const response = await fetchEmployeeLeavesAPI(cid, eid);
    if (response.data) {
      let result = response.data;
      dispatch({
        type: GET_LEAVES,
        leaves: result
      });
      return { success: true, result };
    }
    else {
      return { success: false };
    }
  }
}

export const fetchAllEmployeesLeave = (cid, eid, status,startDate, endDate) => {
  return async (dispatch) => {
    const response = await getAllEmployeesLeave(cid, eid, status, startDate, endDate);
    let result = [];
    if (response.data) {      
      result = [...response.data];
      dispatch({
        type: GET_ALL_EMPLOYEES_LEAVE,
        employeesLeave: result
      });
      return { success: true, result };
    }
    else {
      return { success: false, result};
    }
  }
}

