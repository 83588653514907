import ErrorBoundary from 'hoc/ErrorBoundary';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import { addCrashLogDB } from 'services/app-config-service';

const errorHandler = async (error, stackTrace) => {
    // send error logs to developer team
    const { auth } = store.getState();
    const crashInfo = {
        cid: 0,
        eid: null,
        userEmail: null,
        userDescription: null,
        error: String(error),
        stacktrace: String(stackTrace),
        platform: "Webapp",
    };
    if (auth.user) {
        crashInfo.cid = auth.user.cid;
        crashInfo.eid = auth.user.eid;
        await addCrashLogDB(crashInfo);
    }
};

ReactDOM.render(
    <ErrorBoundary onError={errorHandler}>
        <Provider store={store}>
            <App />
        </Provider>
    </ErrorBoundary >, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
