import { combineReducers } from 'redux';
import appConfig from "./appConfig";
import authReducer from "./authReducer";
import employeeReducer from "./employeeReducer";
import clientReducer from "./clientReducer";
import rolesReducer from "./rolesReducer";
import projectReducer from './projectReducer';
import companyReducer from './companyReducer';
import projectContactsReducer from './projectContactReducer';
import countryReducer from './countryReducer';
import leaveReducer from './leaveReducer';
import holidayReducer from './holidayReducer';
import timesheetReducer from './timesheetReducer';
import timesheetReportReducer from './timesheetReportReducer';
import notificationsReducer from "./notificationsReducer";
import designationReducer from './DesignationReducer';
import leaveTypeRedcer from './leaveTypeReducer';
import manageLeaves from "./manageLeavesReducer";
import moduleReducer from "./moduleReducer";
import currenciesReducer from "./currenciesReducer";
import claimsReducer from "./claimsReducer";
import employeeRoleReducer from './employeeRoleReducer';
import employeeProjectsReducer from './employeeProjectsReducer';
import projectEmployeesReducer from './projectEmployeesReducer';
import claimCategoriesReducer from "./claimCategoriesReducer";
import timesheetApprovalPendingReducer from "./timesheetApprovalPendingReducer";
import dashboardReducer from "./dashboardReducer";
import roleModuleAccessReducer from "./roleModuleAccessReducer";
import companyLocationReducer from "./companyLocationsReducer";
import companyContactReducer from "./companyContactsReducer";
import companyConfigReducer from "./companyConfigInitialReducer";
import projectTypesReducer from './projectTypesReducer';
import approveRejectClaimsReducer from "./approveRejectClaimsReducer";
import departmentReducer from "./departmentReducer";
import holidayByLocationReducer from "./holidaysByLocationsReducer";
import activeleaveTypeReducer from "./leaveTypesActiveReducer";
import managerReducer from "./managerReducer";
import managerTimesheetReportReducer from "./managerTimesheetReportReducer";
import managerEmployeesReducers from "./managerEmployeesReducer";
import managerInvoicesReducer from "./managerInvoicesReducer"
import adminTimesheetReportReducer from './adminTimesheetReportReducer';
import adminInvoicesReducer from './adminInvoicesReducer';
import adminEmployeesReducer from './adminEmployeesReducer';
import announcementReducer from './announcementsReducer';
import employeeBasicInfoReducer from "./employeeBasicInfoReducer";
import employeeVendorReducer from "./employeeVendorReducer";
import vendorReducer from './vendorReducer';
import contractorReportReducer from './contractorReportReducer';
import adminExpenseReportReducer from './adminExpenseReducer';
import  leavesHistoryDetailsReducer from './leavesHistoryDetailsReducer';
import employeesleaveReducer from './employeesleaveReducer';

import employeeAppraisalReducer from './employeeAppraisalReducer';
import managerAppraisalReducer from './managerAppraisalReducer';
import hrAppraisalReducer from './hrAppraisalReducer';
const rootReducer = combineReducers({
    appConfig: appConfig,
    auth: authReducer,
    employees: employeeReducer,
    projects: projectReducer,
    client: clientReducer,
    roles: rolesReducer,
    companies: companyReducer,
    projectContacts: projectContactsReducer,
    countries: countryReducer,
    leaves: leaveReducer,
    leaveTypes: leaveTypeRedcer,
    holidays: holidayReducer,
    timesheet: timesheetReducer,
    timesheetReport: timesheetReportReducer,
    notifications: notificationsReducer,
    designations: designationReducer,
    newLeaves: manageLeaves,
    modules: moduleReducer,
    currencies: currenciesReducer,
    claims: claimsReducer,
    employeesWithRole: employeeRoleReducer,
    employeeProjects: employeeProjectsReducer,
    projectEmployees: projectEmployeesReducer,
    claimCategories: claimCategoriesReducer,
    timesheetApprovalPending: timesheetApprovalPendingReducer,
    dashboard: dashboardReducer,
    roleModuleAccess: roleModuleAccessReducer,
    companyLocations: companyLocationReducer,
    contacts: companyContactReducer,
    configs: companyConfigReducer,
    roleModuleAccess: roleModuleAccessReducer,
    projectTypes: projectTypesReducer,
    claimsForApprove: approveRejectClaimsReducer,
    departments: departmentReducer,
    holidaysByLocation: holidayByLocationReducer,
    activeLeaveTypes: activeleaveTypeReducer,
    managers: managerReducer,
    managerTimesheetReport: managerTimesheetReportReducer,
    managerEmployees: managerEmployeesReducers,
    managerInvoices: managerInvoicesReducer,
    adminTimesheetReport: adminTimesheetReportReducer,
    adminInvoices: adminInvoicesReducer,
    adminEmployees: adminEmployeesReducer,
    announcements: announcementReducer,
    contractorsReport: contractorReportReducer,
    employeeBasicInfo: employeeBasicInfoReducer,
    employeeVendors: employeeVendorReducer,
    vendors: vendorReducer,
    expenseReport: adminExpenseReportReducer,
    leavesHistory: leavesHistoryDetailsReducer,
    employeesLeave:employeesleaveReducer,
    employeeAppraisal: employeeAppraisalReducer,
    mamangerAppraisal: managerAppraisalReducer,
    hrAppraisal:hrAppraisalReducer

});

export default rootReducer;