import React, { useContext, useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Button, Input, UncontrolledTooltip } from "reactstrap";
import ReactTable from "react-table";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { fetchAllEmployeesAppraisal } from "redux/actions/hrAppraisalActions";
import { AppContext } from "hoc/AppContext";
import LoadingIndicator from "components/common/LoadingIndicator";
import NoData from "components/common/NoData";
import Pagination from "components/common/pagination";
import { FaDownload } from 'react-icons/fa'; // Import the download icon
import * as XLSX from 'xlsx';
import { fetchEmployeesAppraisalInfoAPI } from "services/appraisal-service";


export const HRAppraisalList = (props) => {
    const { user } = useSelector(({ auth }) => auth);
    const dispatch = useDispatch();
    const { employeeAppraisalList, loading } = useSelector(({ hrAppraisal }) => hrAppraisal);

    useEffect(() => {
        if (user) {
            dispatch(fetchAllEmployeesAppraisal(user.cid))
        }
    }, [])    

    const onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: e => {
            
                let passProps = {
                    pathname: 'hr-appraisal',
                    state: {
                        empAppraisalId: rowInfo.original.empAppraisalId,
                    }
                }
                // props.history.push(passProps);             
            }
        }
    }

    // Convert form data to Excel
    const handleDownloadAppraisal = async (employee) => {
        // let data={
        //     accomplishments:'lorem',
        //     valuesCompanyPrioritized:'lorem',
        //     valuesCompanyPrioritizedNextYear:'',
        //     topDevelopmentAreas:'ipusm',
        //     topDevelopmentAreasNextYear:'lorem',
        //     topDevelopmentAreasNextYearManagerRemark:'lorem',
        //     attendance:'90%',
        //     rating:'Meets Expectation'
        // }
       
        const result = await fetchEmployeesAppraisalInfoAPI(employee.empAppraisalId);
        if (result.success) {
           
            const {
                name,
                empAppraisalId,
                cid,
                eid,
                year,
                accomplishments,
                valuesCompanyPrioritized,
                topDevelopmentAreas,
                topDevelopmentAreasNextYear,
                status,
                empAppManagerStatus,
                accomplishmentsManagerRemark,
                valuesCompanyPrioritizedManagerRemark,
                topDevelopmentAreasManagerRemark,
                topDevelopmentAreasNextYearManagerRemark,
                valuesCompanyPrioritizedNextYearManagerRemark,
                valuesCompanyPrioritizedNextYear,
                attendance,
                rating,
                billable,
                nonBillable,
                employeeAverageRating,
                managerAverageRating } = result.data;            


            const dataItems = [

                { title: 'Name', value: name },
                { title: 'Employee ID', value: eid },

                { title: `List the three key accomplishments you've achieved this year along with details?`, value: accomplishments },
                { title: 'Remark', value: accomplishmentsManagerRemark },
                { title: `List each of Peramal's core values (Innovation, Collaboration, Integrity, etc.) have been the most significant to you over the past year along with details?`, value: valuesCompanyPrioritized },
                { title: 'Remark', value: valuesCompanyPrioritizedManagerRemark },

                { title: `Please list your top three areas of development from the past year along with details`, value: valuesCompanyPrioritizedNextYear },
                { title: 'Remark', value: valuesCompanyPrioritizedNextYearManagerRemark },
                { title: `Please list your top three areas of focus for development in the coming year along with details.`, value: topDevelopmentAreas },
                { title: 'Remark', value: topDevelopmentAreasManagerRemark },

                { title: `Which of Peramal's core values (such as Innovation, Collaboration, Integrity, etc.) do you anticipate prioritizing the most in the coming year along with details`, value: topDevelopmentAreasNextYear },
                { title: 'Remark', value: topDevelopmentAreasNextYearManagerRemark },
                { title: "Billable", value: billable },
                { title: "Non Billable", value: nonBillable },
                { title: 'Attendance', value: attendance },
                { title: 'Employee Average Rating', value: employeeAverageRating+'/5' },
                { title: 'Manager Average Rating', value: managerAverageRating+'/5' },
                { title: 'Rating', value: rating }               
            ];

            // Prepare data in a format for Excel (columns: Title, Value)
            const formattedData = dataItems.map(item => ({
                'Employee Info': item.title,
                '': item.value,
            }));

            // Create a worksheet from the formatted data
            const worksheet = XLSX.utils.json_to_sheet(formattedData);

            // Create a new workbook and append the worksheet to it
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Form Data');

            // Write the Excel file to disk
            XLSX.writeFile(workbook, `${name} Appraisal ${year}.xlsx`);
        }
    };

  
    const config = useContext(AppContext);
  
    return (<div className="content">
        <Card>
            <Row>
                <Col xs={12} md={12}>
                    <CardHeader>
                        <CardTitle tag="h2" style={{ marginBottom: 50 }}>Appraisal List</CardTitle>
                        <ReactTable
                            PaginationComponent={Pagination}
                            data={employeeAppraisalList}
                            resizable={true}
                            columns={[
                                {
                                    Header: "Name",
                                    Cell: ({ row }) => {
                                        return `${row._original.fName} ${row._original.lName}`;
                                    }
                                },
                                {
                                    Header: "Employee Status",
                                    Cell: ({ row }) => {                                      
                                        return `${row._original.empAppStatus === "Submitted" || row._original.empAppStatus === "Approved" ? "Submitted" : "Not Submitted"}`;
                                    }
                                },
                                {
                                    Header: "Manager Status",
                                    Cell: ({ row }) => {                                       
                                        return `${row._original.empAppManagerStatus === "Approved" ? "Approved" : "Not Approved"}`;
                                    }
                                },
                                {
                                    Header: "Download",
                                    Cell: ({ row }) => {
                                        // Check conditions for empAppStatus and empAppManagerStatus
                                        if (row._original.empAppStatus == "Approved" && row._original.empAppManagerStatus == "Approved") {
                                            return <FaDownload size={15} color="black" onClick={() => { handleDownloadAppraisal(row._original) }} />;
                                        }
                                        return ''; // Return an empty string when conditions are not met
                                        // return `${row._original.empAppStatus !== "Submitted" && row._original.empAppManagerStatus !== "Approved" ? <FaDownload size={15} color="black" /> : ''}`
                                    }
                                    // accessor: "managerStatus"                                  
                                }
                            ]}
                            LoadingComponent={() => (<LoadingIndicator loading={loading} />)}
                            NoDataComponent={() => (<NoData loading={loading} />)}
                            // pageSize={config.paginationSize}
                            // pageSize={config.paginationSize}
                            // pageSize={10}
                            pageSize={employeeAppraisalList.length <= config.paginationSize ? employeeAppraisalList.length : config.paginationSize}
                            showPaginationBottom={true}
                            className="-striped -highlight project-table leave-table expense-table"
                        // getTrProps={onRowClick}
                        />
                    </CardHeader>
                </Col>
            </Row>
        </Card>
    </div>)
}
export default HRAppraisalList;