import {getEmployeeExtraInfoAPI, addUpdateEmployeeExtraInfoAPI} from "../../services/employees-service";

export const FETCH_EMPLOYEE_EXTRA_INFO = 'FETCH_EMPLOYEE_EXTRA_INFO';
export const ADD_UPDATE_EXTRA_INFO = 'ADD_UPDATE_EXTRA_INFO';

export const getEmployeeExtraInfo = (cid, eid) =>  {
    return async (dispatch) => {
        const response = await getEmployeeExtraInfoAPI(cid, eid);
            if (response.data){
                dispatch({
                    type: FETCH_EMPLOYEE_EXTRA_INFO,
                    payload: {
                      id:eid,
                      extraInfo: response.data
                  }
                  });
                return { success: true,data:response.data};
            }
            else{
                return { success: false};
            }    
        }
}

export const addUpdateEmployeeExtraInfo = (employee) =>  {
  return async (dispatch) => {
      const response = await addUpdateEmployeeExtraInfoAPI(employee);
          if(response.data){
              dispatch({
                  type: ADD_UPDATE_EXTRA_INFO,
                  payload: {
                    eid:employee.eid,
                    extraInfo: response.data
                }
                });
              return { success: true, data:response.data };
          }
          else{
              return { success: false};
          }    
      }
}