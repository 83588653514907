import React, { Component } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';



class ProjectModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedClient: null
        };
    }

    render() {
        let { project, clients } = this.props;
        let selectedClient = this.props.clients.find(client => client.id === project.clientId);
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="custom-modal modal-lg project-modal"  >
                    <ModalHeader toggle={this.props.toggle}><h4>
                        Project Details
                    </h4></ModalHeader>
                    <ModalBody>

                        <div>
                            <Table className="project-details-table" id="" borderless>
                                <thead style={{}}>
                                    <tr>
                                        <th style={{ width: '20%' }}></th>
                                        <th style={{ width: '30%' }}></th>
                                        <th style={{ width: '20%' }}></th>
                                        <th style={{ width: '25%' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h5 className="project-details-label">Project:</h5>
                                        </td>
                                        <td>
                                            <h5> {project.project}</h5>
                                        </td>
                                        <td>
                                            <h5 className="project-details-label" style={{ paddingRight: 5 }}>Client: </h5>
                                        </td>
                                        <td>
                                            <h5>{selectedClient !== undefined ? selectedClient.name : null}</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 className="project-details-label" style={{ paddingRight: 5 }}>Status:</h5>
                                        </td>
                                        <td>
                                            <h5>{project.status} </h5>
                                        </td>

                                        <td>
                                            <h5 className="project-details-label" style={{ paddingRight: 5 }}>Active:</h5>
                                        </td>
                                        <td>
                                            <h5> {project.isActive === "Y" ? "True" : "False"}</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 className="project-details-label" style={{ paddingRight: 5 }}>Start Date:</h5>
                                        </td>
                                        <td>
                                            <h5> {project.startDate}</h5>
                                        </td>

                                        <td>
                                            <h5 className="project-details-label" style={{ paddingRight: 5 }}>End Date:</h5>
                                        </td>
                                        <td>
                                            <h5> {project.endDate}</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 className="project-details-label" style={{ paddingRight: 5 }}>Estimated Hours:</h5>
                                        </td>
                                        <td>
                                            <h5>{project.hoursEstimated === null ? 0 : project.hoursEstimated} </h5>
                                        </td>

                                        <td>
                                            <h5 className="project-details-label" style={{ paddingRight: 5 }}>Percent Completed:</h5>
                                        </td>
                                        <td>
                                            <h5>{project.percentCompleted === null ? 0 : project.percentCompleted} </h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="description-column" colSpan="1">
                                            <h5 className="project-details-label" style={{ paddingRight: 5 }}>Description:</h5>
                                        </td>
                                        <td colSpan="3">
                                            <h5>{project.description === null ? null : project.description} </h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                    </ModalBody>
                    <ModalFooter style={{ display: 'block', float: 'right' }}>
                        <Button color="secondary" size="sm" style={{ float: 'right' }} onClick={this.props.close}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

}

export default ProjectModal;