import React, { useContext, useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Button, Input, UncontrolledTooltip, Pagination } from "reactstrap";
import ReactTable from "react-table";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
// import { fetchEmployeeAllAppraisalList } from "../../redux/actions/employeeAppraisalActions";
import LoadingIndicator from "components/common/LoadingIndicator";
import { AppContext } from "hoc/AppContext";
import NoData from "components/common/NoData";
import { fetchEmployeesAppraisalList } from "redux/actions/managerAppraisalActions";

export const ManagerAppraisalList = (props) => {
    const { user } = useSelector(({ auth }) => auth);
    const { employeeAppraisalList, loading, pagination } = useSelector(({ mamangerAppraisal }) => mamangerAppraisal);

    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            const { eid, cid } = user;
            let payload = {
                'cid': cid,
                'managarId': eid,
                'pageNo': 1,
                'pageSize': 10
            }
            dispatch(fetchEmployeesAppraisalList(payload))
        }
    }, [])

    const onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: e => {

                if (rowInfo.original.empAppraisalId !== 0 && rowInfo.original.empAppStatus !== "New") {
                    let passProps = {
                        pathname: 'manager-appraisal',
                        state: {
                            empAppraisalId: rowInfo.original.empAppraisalId,
                        }
                    }

                    props.history.push(passProps);
                }

            }
        }
    }

    const config = useContext(AppContext); // Access the context value

    return (<div className="content">
        <Card>
            <Row>
                <Col xs={12} md={12}>
                    <CardHeader>
                        <CardTitle tag="h2" style={{ marginBottom: 50 }}>Appraisal List</CardTitle>
                        <ReactTable
                            PaginationComponent={Pagination}
                            data={employeeAppraisalList}
                            resizable={true}
                            columns={[
                                {
                                    Header: "Name",
                                    Cell: ({ row }) => {
                                        return `${row._original.fname} ${row._original.lname}`;
                                    }
                                },
                                {
                                    Header: "Status",
                                    // accessor: "empAppStatus",
                                    Cell: ({ row }) => {
                                        return `${row._original.empAppStatus === "New" ? "Not Submitted" : row._original.empAppStatus}`;
                                    }
                                }
                            ]}
                            LoadingComponent={() => (<LoadingIndicator loading={loading} />)}
                            NoDataComponent={() => (<NoData loading={loading} />)}
                            pageSize={employeeAppraisalList.length <= config.paginationSize ? employeeAppraisalList.length : config.paginationSize}
                            showPaginationBottom={true}
                            className="-striped -highlight project-table leave-table expense-table"
                            getTrProps={onRowClick}
                        />
                    </CardHeader>
                </Col>
            </Row>
        </Card>
    </div>)
}
export default ManagerAppraisalList;