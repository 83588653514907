import axios from 'axios';

export const getAllemployeeVendorsAPI = (cid) => {
    return axios
      .get(`/api/v1/vendors`, {
        params: { 
            cid: cid 
          }
      })
      .then(function (response) {
        if (response.data){
            return { success: true, data:response.data };
        }else{
            return { success: false};
        }
      })
      .catch(function (error) {  
        return { success: false };
    });
}