import { fetchHolidayListAPI } from "../../services/holiday-service";

export const GET_ALL_HOLIDAYS = 'GET_ALL_HOLIDAYS';

export const fetchHolidayList = (companyId, year) => {
  return async (dispatch) => {
    const response = await fetchHolidayListAPI(companyId, year);
    if (response.data) {
      let result = response.data;
      dispatch({
        type: GET_ALL_HOLIDAYS,
        holidays: result
      });
      return { success: true, result };
    }
    else {
      return { success: false };
    }
  }
}

