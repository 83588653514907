
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../assets/css/category-modal.css';
import ClientForm from './clientForm';



class ClientModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /* category: {
        name: '',
        description: '',
        categoryId: ''
      }, */
      isLoading: false
    };

  }
  UNSAFE_componentWillMount() {
  }

  render() {

    return (
      <Modal className="custom-modal" isOpen={this.props.isOpen} toggle={this.props.toggle} style={{
        width: "100%",
        maxWidth: 700
      }}>
        <ModalHeader style={{ padding: 20 }} toggle={this.props.toggle}><strong>Client Details</strong></ModalHeader>
        <ModalBody>
          <ClientForm cid={this.props.cid} clients={this.props.clients} countries={this.props.countries} addUpdateClientDetails={this.props.addUpdateClientDetails} toggle={this.props.toggle} showNotifications={this.props.showNotifications} client={this.props.client} formtype={this.props.formtype} />
        </ModalBody>
      </Modal>
    );
  }
}

export default ClientModal