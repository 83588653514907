import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, Col, Row, UncontrolledTooltip, Button } from "reactstrap";
import Pagination from "../../components/common/pagination";
import { bindActionCreators } from "redux";
import { getManagerProjectMarginReport } from "../../redux/actions/managerTimesheetReportAction";
import AdminProjectMarginReportModal from "./AdminProjectMarginReportModal";
import classNames from "classnames";
import LoadingIndicator from "../../components/common/LoadingIndicator";
import NoData from "../../components/common/NoData";
import { AppContext } from '../../hoc/AppContext';
// import "react-table/react-table.css";


class ManagerProjectMarginReportModal extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            resources: [],
            project: '',
            isDataLoading: false
        };
    }
    async UNSAFE_componentWillMount() {
        this.setState({
            ...this.state,
            isDataLoading: true
        }, async () => {
            let response = await this.props.getManagerProjectMarginReport(this.props.user.cid, this.props.user.eid);
            if (response) {
                this.setState({
                    ...this.state,
                    isDataLoading: false
                });
            }

        });

    }

    showProjectModal = (resources, project) => {
        this.setState({
            ...this.state,
            showModal: true,
            resources: [...resources],
            project: project
        });
    }

    toggleShowModal = () => {

        this.setState({
            ...this.state,
            showModal: !this.state.showModal,
            resources: [],
            project: ''

        });
    }

    onClose = () => {

        this.setState({
            ...this.state,
            showModal: false,
        });
    }


    notify = (place, type, message) => {

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };




    render() {

        const myCustomNextText = () => {
            return
        };

        const { config } = this.context;

        const data = this.props.managerProjectMarginReport && this.props.managerProjectMarginReport.map((project, key) => {
            return {
                project: (
                    <div className="">
                        {project.project}
                    </div>
                ),
                projectTotalBillingRate: (
                    <div className="actions-right custom-action-right custom-total-hours">
                        {project.projectTotalBillingRate}
                    </div>
                ),
                projectTotalCost: (
                    <div className="actions-right custom-action-right custom-total-hours">
                        {project.projectTotalCost}
                    </div>
                ),
                projectMargin: (
                    <div className="actions-right custom-action-right custom-total-hours">
                        {project.projectMargin}
                    </div>
                ),
                resources: (
                    // we've added some custom button actions
                    <div className="actions-center custom-actions-center">
                        <Button
                            id="tooltip209945"
                            onClick={() => {
                                this.showProjectModal(project.resources, project.project)
                            }}
                            // color=""
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-zoom-split" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209945"
                            placement="left"
                        >
                            More
                </UncontrolledTooltip>
                    </div>
                )
            };
        });

        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    {this.state.showModal && <AdminProjectMarginReportModal modal={this.state.showModal} toggle={this.toggleShowModal} resources={this.state.resources} project={this.state.project} close={this.onClose} />}
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{}}>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={data}
                                        // filterable
                                        // nextText={myCustomNextText}
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "Project",
                                                accessor: "project",
                                                minWidth: 150,
                                            },
                                            {
                                                Header: "Billing Total",
                                                accessor: "projectTotalBillingRate",
                                                headerClassName: 'header-right'
                                            },
                                            {
                                                Header: "Cost Total",
                                                accessor: "projectTotalCost",
                                                headerClassName: 'header-right'
                                            },
                                            {
                                                Header: "Margin",
                                                accessor: "projectMargin",
                                                headerClassName: 'header-right'
                                            },
                                            {
                                                Header: "More",
                                                accessor: "resources",
                                                width: 100,
                                                headerClassName: 'text-center'
                                            },
                                        ]}

                                        LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                                        NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                                        pageSize={this.props.managerProjectMarginReport.length <= config.paginationSize ? this.props.managerProjectMarginReport.length : config.paginationSize}
                                        className="-striped -highlight project-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export const mapDispatchToProps = dispatch => ({
    getManagerProjectMarginReport: bindActionCreators(getManagerProjectMarginReport, dispatch),
});

export const mapStateToProps = ({ auth, employees, managerTimesheetReport }) => {

    return {
        token: auth.user.token,
        employees: employees,
        user: auth.user,
        managerProjectMarginReport: managerTimesheetReport.timesheetManagerProjectMarginReport !== undefined ? managerTimesheetReport.timesheetManagerProjectMarginReport : []

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagerProjectMarginReportModal);
