import { GET_DASHBOARD_DETAILS } from "../actions/dashboardActions";

const INITIAL_STATE = [];

const dashbaordReducer = (state = INITIAL_STATE,action) => {
    let newState;
    switch (action.type) {
        case GET_DASHBOARD_DETAILS:
            newState = action.dashboard;
            return newState;

        default: return state;
    }
}

export default dashbaordReducer;