import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Spinner
} from "reactstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import NotificationAlert from "react-notification-alert";
import { resetPasswordEmailLink } from "../../services/index";
import { REQUIRD_MESSAGE } from "../../variables/constants";
import { verifyEmail, verifyLengthEid } from "../../components/common/validation";
import { getCompanyBasicInfo } from "../../redux/actions/appConfig";

class ResetPasswordLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailOfficial: '',
      eid: '',
      isLoading: false,
      emailOfficialState: "",
      eidState: ""
    }
  }

  UNSAFE_componentWillMount() {
    let hostedUrl = window.location.hostname;
    // let hostedUrl = '65.0.79.133';    
    // if (hostedUrl == 'localhost') {
    //   hostedUrl = "ess.peramalservices.com";
    //   this.props.getCompanyLogo(hostedUrl);
    // } else {
    this.props.getCompanyBasicInfo(hostedUrl);
    // }
  }
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
        if (verifyEmail(event.target.value)) {
          let value = event.target.value.trim();
          this.setState({
            stateName: value,
            [stateName + "State"]: "has-success"
          });
        } else {
          let value = event.target.value;
          this.setState({
            stateName: value,
            [stateName + "State"]: "has-danger"
          });
        }
        break;
      // case "length":
      //   if (verifyLengthEid(event.target.value, stateNameEqualTo)) {
      //     let value = event.target.value.trim();
      //     this.setState({
      //       stateName: value,
      //       [stateName + "State"]: "has-success"
      //     });
      //   } else {
      //     let value = event.target.value;
      //     this.setState({
      //       stateName: value,
      //       [stateName + "State"]: "has-danger"
      //     });
      //   }
      //   break;
      case "number":
        if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };
  handleForgot = () => {
    this.props.history.push('/reset-password');
  }
  handleResetPassword = async () => {
    let hostedUrl = window.location.hostname;
    // let hostedUrl = '65.0.79.133';
    if (this.state.emailOfficial !== "") {
      if (this.state.isLoading === false) {
        this.setState({ isLoading: true });
        if (this.state.emailOfficialState === "has-danger" ) {
          let options = {};
          options = {
            place: "tr",
            message: 'Please enter valid information',
            type: "warning",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 2
          };
          if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
          }
          setTimeout(() => {
            this.setState({
              isLoading: false
            })
          }, 3000);
        } else {
          // if (hostedUrl == 'localhost') {
          //   hostedUrl = "ess.peramalservices.com";
          //   const response = await resetPasswordEmailLink(this.state.emailOfficial, this.state.eid, hostedUrl);
          //   if (response.success) {
          //     let options = {};
          //     options = {
          //       place: "tr",
          //       message: "Email verification link sent successfully.",
          //       type: "success",
          //       icon: "tim-icons icon-bell-55",
          //       autoDismiss: 2
          //     };
          //     if (this.refs.notificationAlert) {
          //       this.refs.notificationAlert.notificationAlert(options);
          //     }
          //     setTimeout(() => {
          //       this.setState({
          //         isLoading: false
          //       })
          //       this.props.history.push('/auth/login');
          //     }, 3000);
          //   } else {
          //     let options = {};
          //     options = {
          //       place: "tr",
          //       message: "Incorrect email and employee ID!",
          //       type: "warning",
          //       icon: "tim-icons icon-bell-55",
          //       autoDismiss: 2
          //     };
          //     if (this.refs.notificationAlert) {
          //       this.refs.notificationAlert.notificationAlert(options);
          //     }
          //     setTimeout(() => {
          //       this.setState({
          //         isLoading: false
          //       })
          //     }, 3000);
          //   }
          // } else {
          const response = await resetPasswordEmailLink(this.state.emailOfficial,hostedUrl);
          if (response.success) {
            let options = {};
            options = {
              place: "tr",
              message: "Email verification link sent successfully.",
              type: "success",
              icon: "tim-icons icon-bell-55",
              autoDismiss: 2
            };
            if (this.refs.notificationAlert) {
              this.refs.notificationAlert.notificationAlert(options);
            }
            setTimeout(() => {
              this.setState({
                isLoading: false
              })
              this.props.history.push('/auth/login');
            }, 3000);
          } else {
            let options = {};
            options = {
              place: "tr",
              message: "Incorrect email and employee ID!",
              type: "warning",
              icon: "tim-icons icon-bell-55",
              autoDismiss: 2
            };
            if (this.refs.notificationAlert) {
              this.refs.notificationAlert.notificationAlert(options);
            }
            setTimeout(() => {
              this.setState({
                isLoading: false
              })
            }, 3000);
          }
          // }
        }
      }
    } else {
      if (this.state.emailOfficial === "") {
        this.setState({ emailOfficialState: "has-danger" });
      }
    }
  }
  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.handleResetPassword();
    }
  }
  render() {
    let logo = this.props.companyLogo ? this.props.companyLogo : null;
    return (
      <>
        <div className="content custom-login-content">
          <div className="rna-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6" style={{ marginTop: '50px' }}>
              <Form className="form">
                <Card className="card-login card-white">
                  <CardHeader style={{ margin: 'auto' }}>
                    {/* <img
                      alt="..."
                      src={require("assets/img/card-primary.png")}
                    /> */}
                    {this.props.companyLogo ?
                      <img
                        style={{}}
                        alt="..."
                        src={logo}
                      />
                      : <div style={{ height: '125px' }}>

                      </div>
                    }
                    <CardTitle style={{ textAlign: 'center' }} tag="h1">ESS</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={classnames(this.state.emailOfficialState, {
                        "input-group-focus": this.state.emailOfficialFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Your E-mail"
                        type="email"
                        name="emailOfficial"
                        value={this.state.emailOfficial}
                        onChange={e => this.change(e, "emailOfficial", "email")}
                        onFocus={e => this.setState({ emailOfficialFocus: true })}
                        onBlur={e => this.setState({ emailOfficialFocus: false })}
                        onKeyPress={this.keyPressed}
                      />
                    </InputGroup>
                    {this.state.emailOfficialState === "has-danger" ? (
                      <label className="error">{REQUIRD_MESSAGE}</label>
                    ) : null}
                    {/* <InputGroup
                      className={classnames(this.state.eidState, {
                        "input-group-focus": this.state.eidFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Your Employee Id"
                        type="text"
                        name="eid"
                        value={this.state.eid}
                        onChange={e => this.change(e, "eid", "length")}
                        onFocus={e => this.setState({ empidFocus: true })}
                        onBlur={e => this.setState({ empidFocus: false })}
                        onKeyPress={this.keyPressed}
                      />
                    </InputGroup>
                    {this.state.eidState === "has-danger" ? (
                      <label className="error">{REQUIRD_MESSAGE}</label>
                    ) : null} */}
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="mb-3"
                      color="primary"
                      type="button"
                      onClick={this.handleResetPassword}
                      size="lg"
                    >{this.state.isLoading ?
                      <Spinner as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true" />

                      : 'Send Link'
                      }
                    </Button>
                    <div className="pull-right">
                      <h6>
                        {this.state.isLoading ?
                          <a
                            className="link footer-link"
                            href="#pablo"
                            onClick={e => {
                              e.preventDefault();
                            }}
                          >
                            Sign In
                          </a>
                          :
                          <a
                            className="link footer-link"
                            href="#pablo"
                            onClick={e => {
                              e.preventDefault();
                              this.props.history.push('/auth/login');
                            }}
                          >
                            Sign In
                          </a>
                        }
                      </h6>
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  getCompanyBasicInfo: bindActionCreators(getCompanyBasicInfo, dispatch)
});

export const mapStateToProps = ({ appConfig }) => ({
  companyLogo: appConfig.logo && appConfig.logo,

});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordLink);
