import { GET_ALL_HOLIDAYS } from '../actions/holidayListActions';


const INITIAL_STATE = [];

const holidayReducer = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case GET_ALL_HOLIDAYS:
            newState = action.holidays;
            return newState;

        default: return state;
  }
};

export default holidayReducer;
