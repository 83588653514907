import { fetchLeaveTypesAPI, fetchActiveLeaveTypesAPI } from "../../services/leaves-service";

export const GET_LEAVES_TYPES = 'GET_LEAVES_TYPES';
export const GET_ACTIVE_LEAVE_TYPES = "GET_ACTIVE_LEAVE_TYPES";

export const fetchLeaveTypes = (cid) => {
  return async (dispatch) => {
    const response = await fetchLeaveTypesAPI(cid);
    if (response.data) {
      let result = response.data;
      dispatch({
        type: GET_LEAVES_TYPES,
        leavesTypes: result
      });
      return { success: true, result };
    }
    else {
      return { success: false };
    }
  }
}

export const fetchActiveLeaveTypes = (cid) => {
  return async (dispatch) => {
    const response = await fetchActiveLeaveTypesAPI(cid);
    if (response.data) {
      let result = response.data;
      dispatch({
        type: GET_ACTIVE_LEAVE_TYPES,
        leavesTypes: result
      });
      return { success: true, result };
    }
    else {
      return { success: false };
    }
  }
}


