import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';
import Switch from "react-switch";
import { containsOnlyCharacters } from '../../../components/common/validation';
import { allowOnlyOneBlankSpace } from '../../../components/common/validation';


class DesignationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'Add',
            designation: {
                id: 0,
                cid: 0,
                designation: '',
                isActive: 'Y',
            },
            clientOptions: [],
            designationState: '',
            designationAlreadyExistState: '',
            isActiveState: '',
            companyState: '',
            isActive: true
        };
    }



    UNSAFE_componentWillMount() {
        if (this.props.formType === "Update") {
            let isActive = this.props.designation.isActive === "Y" ? true : false;
            this.setState({
                ...this.state,
                formType: this.props.formType,
                isActive: isActive,
                designation: {
                    ...this.props.designation,
                    cid: this.props.cid
                }
            });
        } else {
            this.setState({
                ...this.state,
                designation: {
                    ...this.state.designation,
                    cid: this.props.cid
                },
                formType: this.props.formType,
            });
        }
    }


    handleSubmit = async () => {
        var { designation, } = { ...this.state.designation };
        var isValid = containsOnlyCharacters(designation);
        designation = await allowOnlyOneBlankSpace(designation);

        var isDesignationAlreadyExist = false;
        var isUpdatedDesignationAlreadyExist = false;
        if (this.props.formType === "Add") {
            isDesignationAlreadyExist = (this.props.designations.findIndex(designationItem => designationItem.designation.toUpperCase() === designation.trim().toUpperCase()) > -1 ? true : false)
        }
        if (this.props.formType === "Update") {
            let alreadyExist = this.props.designations.filter(designationItem => designationItem.designation.toUpperCase() === designation.trim().toUpperCase())
            isUpdatedDesignationAlreadyExist = alreadyExist.length > 1 ? true : false;
            var designationCount = 0;

            this.props.designations && this.props.designations.map((designationItem) => {

                if (designationItem.designation.toUpperCase() === designation.trim().toUpperCase() && designationItem.id !== this.state.designation.id) {
                    designationCount = designationCount + 1;
                }
            });

            if (designationCount >= 1) {
                isUpdatedDesignationAlreadyExist = true;
            }

        }
        if (designation.trim() === "" || isValid === false) {
            this.setState({
                ...this.state,
                designationState: "has-danger",
                designationAlreadyExistState: "has-success"
            });
        } else {
            this.setState({
                ...this.state,
                designationState: "has-success",
                designationAlreadyExistState: ((isDesignationAlreadyExist === true && this.props.formType === 'Add') || (isUpdatedDesignationAlreadyExist === true && this.props.formType === "Update")) ? 'has-danger' : 'has-success',
            });
        }

        if (designation.trim() !== "" && isValid === true && isDesignationAlreadyExist === false && isUpdatedDesignationAlreadyExist === false) {
            this.setState({
                ...this.state,
                designation: {
                    ...this.state.designation,
                    designation: designation
                }
            }, () => {
                this.props.onSubmit(this.state.designation, this.props.formType);

            })
        }
    }

    onChangeTextHandler = (e) => {
        let designation = this.state.designation;

        designation[e.target.name] = e.target.value;
        let isValid = containsOnlyCharacters(designation.designation);
        this.setState({
            designationState: (designation.designation === "" || isValid === false) ? "has-danger" : "has-success",
            designation: designation,
            designationAlreadyExistState: "has-success"
        });

    }



    handleIsActive = (e) => {
        this.setState({
            ...this.state,
            isActive: e,
            designation: {
                ...this.state.designation,
                isActive: e === true ? "Y" : "N",
            }
        });
    }
    render() {


        let { designationState } = this.state;
        let { designation } = this.state.designation;
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="custom-modal designation-modal"  >
                    <ModalHeader toggle={this.props.toggle}>
                        <p className="custom-modal-title">
                            {this.props.formType === "Update" ? "Update designation" : "Add New Designation"}
                        </p></ModalHeader>
                    <ModalBody>
                        <Form className="custom-form" id="LoginValidation">
                            <Row>
                                <Label md="3">Designation *</Label>
                                <Col md="8">
                                    <FormGroup className={`has-label ${designationState}`}>

                                        <Input
                                            name="designation"
                                            type="text"
                                            placeholder="designation name"
                                            value={designation}
                                            onChange={this.onChangeTextHandler}
                                        />
                                        {this.state.designationState === "has-danger" ? (
                                            <label className="error">please enter valid designation name!</label>
                                        ) : null}
                                        {this.state.designationAlreadyExistState === "has-danger" ? (
                                            <label className="error">Designation already exist!</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Label md="3">Active </Label>
                                <Col md="8">
                                    <FormGroup >
                                        <label id="tooltip209846">
                                            <Switch
                                                onChange={(e) => { this.handleIsActive(e) }}
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48} />
                                        </label>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target="tooltip209846"
                                            placement="right"
                                        >
                                            {this.state.isActive === true ? "Active" : "Inactive"}
                                        </UncontrolledTooltip>

                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" size="sm" onClick={this.handleSubmit}>{this.props.formType}</Button>{' '}
                        <Button color="secondary" size="sm" onClick={this.props.close}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

}

export default DesignationModal;