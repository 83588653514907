import React from 'react';
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import '../../../assets/css/category-modal.css';
import AnnouncementForm from "./AnnouncementForm.js";



class AnnouncementModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };

  }
  componentDidMount() {

  }

  render() {
   
    return (
      <Modal className="custom-modal" isOpen={this.props.isOpen} toggle={this.props.toggle} style={{
        width: "100%",
        maxWidth: 700
      }}>
        <ModalHeader style={{ padding: 20, marginBottom: 10 }} toggle={this.props.toggle}><strong>Announcement</strong></ModalHeader>
        <ModalBody>
          <AnnouncementForm userData={this.props.userData} addUpdateAnnouncement={this.props.addUpdateAnnouncement} toggle={this.props.toggle} showNotifications={this.props.showNotifications} announcement={this.props.announcement} formtype={this.props.formtype} />
        </ModalBody>
      </Modal>
    );
  }
}

export default AnnouncementModal