import {getEmployeesWithManagerRoleAPI} from "../../services/managers-service";

export const FETCH_ALL_MANAGERS = 'FETCH_ALL_MANAGERS';

export const getEmployeesWithManagerRole = (cid) =>  {
    return async (dispatch) => {
        const response = await getEmployeesWithManagerRoleAPI(cid);
            if (response.data){
                dispatch({
                    type: FETCH_ALL_MANAGERS,
                    managers: response.data
                  });
                return { success: true };
            }
            else{
                return { success: false};
            }    
        }
}