import { addUpdateEmployeeAppraisalAPI, fetchAllEmployeesAppraisalAPI, fetchEmployeeAppraisalByAppraisalIdAPI, fetchEmployeeAppraisalListAPI, fetchHREmployeeAppraisalByAppraisalIdAPI, fetchHREmployeeAppraisalListAPI } from "../../services/appraisal-service";

export const FETCH_EMPLOYEE_APPRAISAL_LIST = '[HR] FETCH_EMPLOYEE_APPRAISAL_LIST';
export const FETCH_EMPLOYEE_APPRAISAL_LIST_SUCCESS = '[HR] FETCH_EMPLOYEE_APPRAISAL_LIST_SUCCESS';
export const FETCH_EMPLOYEE_APPRAISAL_LIST_ERROR = '[HR] FETCH_EMPLOYEE_APPRAISAL_LIST_ERROR';
export const SET_EMPLOYEE_APPRAISAL_LIST_PAGINATION = "[HR] SET_EMPLOYEE_APPRAISAL_LIST_PAGINATION";

export const FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID = '[HR] FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID';
export const FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_SUCCESS = '[HR] FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_SUCCESS';
export const FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_ERROR = '[HR] FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_ERROR';

export const FETCH_ALL_EMPLOYEES_APPRAISAL="[HR] FETCH_ALL_EMPLOYEES_APPRAISAL";
export const FETCH_ALL_EMPLOYEES_APPRAISAL_SUCCESS="[HR] FETCH_ALL_EMPLOYEES_APPRAISAL_SUCCESS";
export const FETCH_ALL_EMPLOYEES_APPRAISAL_ERROR="[HR] FETCH_ALL_EMPLOYEES_APPRAISAL_ERROR";

export const fetchHREmployeeAllAppraisalList = (payload) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_EMPLOYEE_APPRAISAL_LIST,
        });
        const response = await fetchHREmployeeAppraisalListAPI(payload);
        
        if (response.data) {
            const pagination = {
                currentPage: payload.pageNo,
                pageSize: payload.pageSize,
                totalPages: response.data.totalPages,
                totalItems: response.data.totalItems,
            }
            dispatch({
                type: FETCH_EMPLOYEE_APPRAISAL_LIST_SUCCESS,
                employeeAppraisalList: response.data.items
            });
            dispatch({
                type: SET_EMPLOYEE_APPRAISAL_LIST_PAGINATION,
                pagination: pagination
            });
            // return { success: true, employeeAppraisalList: response.data.items };
        }
        else {
            dispatch({
                type: FETCH_EMPLOYEE_APPRAISAL_LIST_ERROR,
            });
            return { success: false };
        }
    }
}


export const fetchAllEmployeesAppraisal = (payload) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_ALL_EMPLOYEES_APPRAISAL });

        const response = await fetchAllEmployeesAppraisalAPI(payload);
        
        if (response.data) {
            //   let result = response.data;
            dispatch({
                type: FETCH_ALL_EMPLOYEES_APPRAISAL_SUCCESS,
                employeeAppraisalList: response.data
            });
            //   return { success: true, result };
        }
        else {
            dispatch({ type: FETCH_ALL_EMPLOYEES_APPRAISAL_ERROR });

            return { success: false };
        }
    }
}

export const fetchHREmployeeAppraisalByAppraisalId = (payload) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID });

        const response = await fetchHREmployeeAppraisalByAppraisalIdAPI(payload);
        
        if (response.data) {
            //   let result = response.data;
            dispatch({
                type: FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_SUCCESS,
                employeeAppraisal: response.data
            });
            //   return { success: true, result };
        }
        else {
            dispatch({ type: FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_ERROR });

            return { success: false };
        }
    }
}



