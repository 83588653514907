import React from "react";
import moment from 'moment';
import ReactDatetime from "react-datetime";
import Switch from "react-switch";
import {
  Button,
  Col,
  Form, FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import ImageUpload from "../../../../components/CustomUpload/ImageUpload";
import { checkFileSize, checkMimeType, maxSelectFile } from "../../../../components/common/validation";
import { uploadFile } from "../../../../services/index";
import { REQUIRD_MESSAGE, dateFormatList } from "../../../../variables/constants";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formType: 'Add',
      company: {
        id: 0,
        name: '',
        isActive: 'Y',
        dateRegistered: '',
        accountType: "",
        currencyId: -1,
        domainurl: "",
        hostedurl: "",
        logoPath: "",
        slug: "",
        accountName: "",
        bank: "",
        branch: "",
        accountNumber: "",
        routingNumber: "",
        bankAccountType: ""
      },
      nameState: '',
      files: {},
      check: false,
      dateRegisteredState: '',
      domainurlState: '',
      accountTypeState: '',
      routingNumberState: 'has-success',
      slugState: '',
      hostedurlState: '',
      logoPathState: '',
      slugState: '',
      currencyIdState: '',
      file: null,
      imagePreviewUrl: ""

    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  UNSAFE_componentWillMount() {

    //this.props.getAllCurrencies();
    if (this.props.companies.length <= 0) {
      this.props.getAllCompanies();
    }

    if (this.props.formType !== "Add") {
      this.setState({
        company: this.props.company,
        formType: this.props.formType,

      });
      this.setState({
        nameState: 'has-success',
        dateRegisteredState: 'has-success',
        domainurlState: 'has-success',
        accountTypeState: 'has-success',
        hostedurlState: 'has-success',
        currencyIdState: 'has-success'
      })
      if (this.props.company.logoPath != "") {
        let reader = new FileReader();
        let file = {
          lastModified: '',
          lastModifiedDate: new Date(),
          name: this.props.company.logoPath,
          size: 0,
          type: 'image/jpeg',
          webkitRelativePath: ''
        }
        this.setState({
          file: file,
          imagePreviewUrl: this.props.company.logoPath,
        });
      }

    }
  }

  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  handleStartDate = (e, stateName) => {

    if (e > 0) {
      this.setState({ [stateName + "State"]: "has-success" });
    } else {
      this.setState({ [stateName + "State"]: "has-danger" });
    }
    if (stateName == 'doj') {
      this.setState({
        dojName: moment(e).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
        employee: {
          ...this.state.employee,
          doj: moment(e).format('YYYY-MM-DD')
        }

      });
    } else {
      this.setState({
        dobName: moment(e).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
        employee: {
          ...this.state.employee,
          dob: moment(e).format('YYYY-MM-DD')
        }

      });

    }
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  onUploadProgress = (progressEvent) => {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );

    this.setState({ uploadProgress: percentCompleted });
  };

  handleClick = (refs) => {
    refs.fileInput.click();
  }

  handleRemove = (refs) => {
    this.setState({
      file: null,
      imagePreviewUrl: ""
    });
    refs.fileInput.value = null;
  }


  isValidated = () => {
    let { id,
      name,
      isActive,
      dateRegistered,
      accountType,
      currencyId,
      domainurl,
      hostedurl,
      logoPath,
      files,
      slug } = this.state.company;
    let { nameState, dateRegisteredState, accountTypeState, currencyIdState, domainurlState, hostedurlState } = this.state;
    let checkCompanyExists = false;
    let isUpdatedCompanyAlreadyExist = false;
    let hostedUrlExists = false;
    let isUpdatedHostedUrl = false;

    if (this.props.formType !== "Add") {
      let alreadyExist = this.props.companies.filter(comp => comp.name.toUpperCase() === name.trim().toUpperCase());
      let urlExists = this.props.companies.filter(comp => comp.hostedurl.toUpperCase() === hostedurl.trim().toUpperCase());
      isUpdatedCompanyAlreadyExist = alreadyExist.length > 1 ? true : false;
      isUpdatedHostedUrl = urlExists.length > 1 ? true : false;
      let compCount = 0;
      let hostCompCount = 0;

      let result = this.props.companies && this.props.companies.map((comp) => {

        if (comp.name.toUpperCase() === name.trim().toUpperCase() && comp.id !== this.props.company.id) {
          //
          compCount = compCount + 1;
        }
      });

      this.props.companies && this.props.companies.map((comp) => {
        if (comp.hostedurl.toUpperCase() === hostedurl.trim().toUpperCase() && comp.id !== this.props.company.id) {
          //
          hostCompCount = hostCompCount + 1;
        }
      })

      if (hostCompCount >= 1) {
        isUpdatedHostedUrl = true;
      }
      if (compCount >= 1) {
        isUpdatedCompanyAlreadyExist = true;
      }


    } else {
      checkCompanyExists = (this.props.companies.findIndex(comp => comp.name.toUpperCase() === name.trim().toUpperCase() && comp.id !== this.props.company.id) > -1 ? true : false);
      hostedUrlExists = (this.props.companies.findIndex(comp => comp.hostedurl.toUpperCase() === hostedurl.trim().toUpperCase() && comp.id !== this.props.company.id) > -1 ? true : false)

    }



    if (nameState === "has-success" && dateRegisteredState === "has-success" && accountTypeState === "has-success" &&
      currencyIdState === "has-success" && hostedurlState === "has-success" && checkCompanyExists === false &&
      isUpdatedCompanyAlreadyExist === false && hostedUrlExists === false && isUpdatedHostedUrl === false) {
      if (this.props.formType === "Add") {
        this.props.submitBasicInfo(this.state.company, files);
        return true;
      } else {
        let company = {
          ...this.state.company,
          id: this.props.company.id
        }
        this.props.submitBasicInfo(company, logoPath);
        return true;
      }
    } else {

      if (name == "" || checkCompanyExists === true || isUpdatedCompanyAlreadyExist === true) {
        this.setState({ nameState: "has-danger" });
      }
      if (dateRegistered == "") {
        this.setState({ dateRegisteredState: "has-danger" });
      }
      if (accountType == "") {
        this.setState({ accountTypeState: "has-danger" });
      }
      if (currencyId == -1) {
        this.setState({ currencyIdState: "has-danger" });
      }
      if (hostedurl == "" || hostedUrlExists === true || isUpdatedHostedUrl === true) {
        this.setState({ hostedurlState: "has-danger" });
      }
      return false;
    }
    return true;

  };

  handleSelectStatus = (e) => {
    if (e.target.value === '') {
      this.setState({ [e.target.name + "State"]: "has-danger" });
    } else {
      this.setState({ [e.target.name + "State"]: "has-success" });
    }

    let company = this.state.company;
    if (e.target.name === 'currencyId') {
      company[e.target.name] = parseInt(e.target.value);
    } else {
      company[e.target.name] = e.target.value;
    }
    this.setState({
      company: { ...company }
    })
  }
  handleIsActive = (e) => {
    if (e === true) {
      this.setState({
        company: {
          ...this.state.company,
          isActive: 'Y'

        }
      });
    }
    else {
      this.setState({
        company: {
          ...this.state.company,
          isActive: 'N'

        }
      });
    }
  }

  onChangeTextHandler = (e) => {
    //
    var lengthRex = /^[a-zA-Z\s.]*$/;
    let company = this.state.company;

    company[e.target.name] = e.target.value;
    if (e.target.name != "slug" && e.target.name != 'routingNumber') {

      if (lengthRex.test(e.target.value)) {
        if (e.target.value.startsWith(' ')) {
          this.setState({
            [e.target.name + "State"]: "has-danger"
          })
        }
        else (
          this.setState({
            [e.target.name + "State"]: e.target.value === "" ? "has-danger" : "has-success",
            company: company
          })
        )
      } else (
        this.setState({
          [e.target.name + "State"]: "has-danger"
        })
      )
    } else if (e.target.name === "routingNumber") {
      if (e.target.value !== '' && e.target.value.length !== 9) {
        this.setState({
          [e.target.name + "State"]: "has-danger"
        })
      } else {
        this.setState({
          [e.target.name + "State"]: "has-success"
        })
      }
    } else {
      this.setState({
        company: company
      })
    }

  }
  handleStartDate = (date) => {

    this.setState({
      dateRegisteredState: moment(date).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
      company: {
        ...this.state.company,
        dateRegistered: moment(date).format('YYYY-MM-DD')
      }

    });
  }

  keyDown = (event) => {

    if (event.key === '.' || event.key === '+' || event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  };

  handleImageChange = async (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let checkMimeTypeValid = false;
    const types = ['image/png', 'image/jpeg']
    checkMimeTypeValid = checkMimeType(e, types);
    let maxSelectFileValid = false;
    maxSelectFileValid = maxSelectFile(e, 1);
    let checkFileSizeValid = false;
    checkFileSizeValid = checkFileSize(e);
    if (checkMimeTypeValid && maxSelectFileValid && checkFileSizeValid) {
      let files = e.target.files[0];
      if (this.props.formType === "Add") {
        reader.onloadend = () => {
          this.setState({
            file: files,
            imagePreviewUrl: reader.result,
            company: {
              ...this.state.company,
              logoPath: files.name,
              files: files
            }
          });
        }
        reader.readAsDataURL(files);
      }
      else {
        let reader = new FileReader();
        let files = e.target.files[0];
        reader.onloadend = () => {
          this.setState({
            imagePreviewUrl: reader.result,
          });
        };
        reader.readAsDataURL(files);
        let filePath = `${this.props.company.id}/DOCS/LOGO`;
        const data = new FormData()
        data.append('filePath', filePath);
        data.append('files', files);

        const response = await uploadFile(data, this.onUploadProgress);
        if (response.success) {
          let message = {
            message: 'Logo uploded successfully.',
            type: "success",
          }
          this.props.handleMessage(message);
          this.setState({
            file: response.data[0],
            company: {
              ...this.state.company,
              logoPath: response.data[0]
            }
          });
        } else {
          let message = {
            message: 'Error accured while uploading logo!',
            type: "warning",
          }
          this.props.handleMessage(message);
        }
      }

    } else {
      if (checkMimeTypeValid == false) {
        let message = {
          message: 'Logo is not a supported format!',
          type: "warning",
        }
        this.props.handleMessage(message);
      }
      if (maxSelectFileValid == false) {
        let message = {
          message: 'Only 1 Logo picture can be uploaded!',
          type: "warning",
        }
        this.props.handleMessage(message);
      } if (checkFileSizeValid == false) {
        let message = {
          message: 'Logo file size is too large,please pick a file less than 5MB!',
          type: "warning",
        }
        this.props.handleMessage(message);
      }
    }
  }



  handleClick = (refs) => {
    refs.fileInput.click();
  }
  handleRemove = (refs) => {
    this.setState({
      file: null,
      imagePreviewUrl: ""
    });
    refs.fileInput.value = null;
  }

  handleCancel = () => {
    let passProps = {
      pathname: 'companymaster',
    }
    this.props.history.push(passProps);
  }

  render() {
    let accountTypes = [
      { id: 'Savings Account', type: 'Savings Account' },
      { id: 'Recurring Deposit', type: 'Recurring Deposit' },
      { id: 'Current Account', type: 'Current Account' },
      { id: 'Fixed Deposit', type: 'Fixed Deposit' },
    ]
    let { company } = this.props;
    let { nameState, dateRegisteredState, hostedurlState, domainurlState, routingNumberState, slugState, accountTypeState, currencyIdState, logoPathState } = this.state;
    return (
      <><div className="content">

        <Col className="mr-auto ml-auto" md="12">
          <Form className="custom-form">
            <Row className="">   {/*removed classname for middling content className="justify-content-center"*/}
              <Col sm="8">
                <Row>
                  <Col sm="6">
                    <FormGroup className={`has-label ${nameState}`}>
                      <Label>Company Name*</Label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Company name"
                        value={this.state.company.name}
                        onChange={this.onChangeTextHandler}
                      />
                      {nameState === "has-danger" ? (
                        <label className="error">Please enter a valid and unique company name.</label>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col sm="6">
                    <FormGroup className={`has-label ${dateRegisteredState} `}>
                      <Label >Date of register*</Label>
                      <ReactDatetime
                        timeFormat={false}
                        inputProps={{ placeholder: "Register Date", className: "form-control", value: this.state.company.dateRegistered !== "" ? moment(this.state.company.dateRegistered).format(dateFormatList[2]) : this.state.company.dateRegistered }}
                        onChange={this.handleStartDate}
                        closeOnSelect={true}
                      />
                      {this.state.dateRegisteredState === "has-danger" ? (
                        <label className="error hide-has-danger">{REQUIRD_MESSAGE}</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className={`has-label ${accountTypeState} `}>
                      <Label>Account Type*</Label>
                      <Input type="select" name="accountType" defaultValue={this.state.company.accountType} onChange={this.handleSelectStatus} id="exampleSelect">
                        <option value="" disabled>select status</option>
                        <option value="New">New</option>
                        <option value="Paid">Paid</option>
                        <option value="Free">Free</option>
                        <option value="Discounted">Discounted</option>
                        <option value="Demo">Demo</option>
                      </Input>
                      {accountTypeState === "has-danger" ? (
                        <label className="error">{REQUIRD_MESSAGE}</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className={`has-label ${currencyIdState} `}>
                      <Label>Select Currency*</Label>
                      <Input type="select" name="currencyId" defaultValue={this.state.company.currencyId} onChange={this.handleSelectStatus} id="exampleSelect">
                        <option value={-1} disabled>Select Currency</option>
                        {this.props.currencies.map((currency) => {
                          return (<option value={currency.id}>{currency.currency}</option>)
                        })}
                      </Input>
                      {currencyIdState === "has-danger" ? (
                        <label className="error">{REQUIRD_MESSAGE}</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className={`has-label ${domainurlState}`}>
                      <Label>Domain Url</Label>
                      <Input
                        name="domainurl"
                        type="text"
                        placeholder="Domain Url"
                        value={this.state.company.domainurl}
                        onChange={this.onChangeTextHandler}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className={`has-label ${hostedurlState}`}>
                      <Label>Hosted Url*</Label>
                      <Input
                        name="hostedurl"
                        type="text"
                        placeholder="Hosted Url"
                        value={this.state.company.hostedurl}
                        onChange={this.onChangeTextHandler}
                      />
                      {hostedurlState === "has-danger" ? (
                        <label className="error">Please enter a unique and valid url</label>
                      ) : null}
                    </FormGroup>
                  </Col>


                </Row>

              </Col>
              {/* <Col  sm="4" style={{paddingLeft:'50px', paddingRight:'50px'}}>
         <Label >Select Logo</Label>
                    <ImageUpload
                      addBtnColor="default"
                      changeBtnColor="default"
                      component="employee"
                      accept="image/png,image/jpeg"
                      handleImageChange={this.handleImageChange}
                      handleClick={this.handleClick}
                      handleRemove={this.handleRemove}
                      
                      file={this.state.selectedImage}
                    />
         
         </Col> */}

              <Col sm="4" style={{ textAlign: "center" }}>
                <Col sm="7" style={{ textAlign: "center" }}>
                  <FormGroup className={`has-label ${logoPathState} `}>
                    <Label >Select Logo</Label>
                    <ImageUpload
                      addBtnColor="default"
                      changeBtnColor="default"
                      handleImageChange={this.handleImageChange}
                      handleClick={this.handleClick}
                      handleRemove={this.handleRemove}
                      imagePreviewUrl={this.state.imagePreviewUrl === "" ? this.props.image : this.state.imagePreviewUrl}
                      file={this.state.file}
                    />
                  </FormGroup>
                </Col>
              </Col>

            </Row>
            <Row>
              <Col sm="4">
                <FormGroup >
                  <Label>Account Name</Label>
                  <Input
                    name="accountName"
                    type="text"
                    placeholder="Account name"
                    value={this.state.company.accountName}
                    onChange={this.onChangeTextHandler}
                  />

                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup >
                  <Label>Bank Name</Label>
                  <Input
                    name="bank"
                    type="text"
                    placeholder="Bank name"
                    value={this.state.company.bank}
                    onChange={this.onChangeTextHandler}
                  />

                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup >
                  <Label>Branch</Label>
                  <Input
                    name="branch"
                    type="text"
                    placeholder="Branch name"
                    value={this.state.company.branch}
                    onChange={this.onChangeTextHandler}
                  />

                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup >
                  <Label>Account Number</Label>
                  <Input
                    name="accountNumber"
                    type="number"
                    placeholder="Account No."
                    onKeyDown={e => this.keyDown(e)}
                    value={this.state.company.accountNumber}
                    onChange={this.onChangeTextHandler}
                  />

                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup >
                  <Label>Routing Number</Label>
                  <Input
                    name="routingNumber"
                    type="number"
                    placeholder="Routing No."
                    onKeyDown={e => this.keyDown(e)}
                    value={this.state.company.routingNumber}
                    onChange={this.onChangeTextHandler}
                  />
                  {routingNumberState === "has-danger" ? (
                    <label className="error">Please enter valid 9 digit routing number</label>
                  ) : null}
                </FormGroup>
              </Col>

              <Col sm="4">
                <FormGroup >
                  <Label>Bank Account Type</Label>
                  <Input type="select" name="bankAccountType" defaultValue={this.state.company.bankAccountType} onChange={this.handleSelectStatus} id="exampleSelect">
                    <option value={-1} disabled>Select Account Type</option>
                    {accountTypes.map((type) => {
                      return (<option value={type.id}>{type.type}</option>)
                    })}
                  </Input>

                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup className={`has-label ${slugState}`}>
                  <Label>Company Slogan</Label>
                  <Input
                    name="slug"
                    type="text"
                    placeholder="Company Slogan"
                    value={this.state.company.slug}
                    onChange={this.onChangeTextHandler}
                  />
                </FormGroup>
              </Col>
              <Col sm="4">
                <Col sm="10">
                  <FormGroup >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                      <div>
                        <Label >Active</Label>
                      </div>
                      <div style={{ marginRight: '100px' }}>
                        <Switch
                          onChange={(e) => { this.handleIsActive(e) }}
                          name="isActive"
                          id="isActive"
                          checked={this.state.company.isActive === 'Y' ? true : false}
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={48} />
                      </div>
                    </div>
                  </FormGroup>
                </Col>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
        <Button onClick={() => { this.handleCancel() }} className="btn-cancel" size="sm" color="info">Cancel</Button>
      </>
    );
  }
}

export default Wizard;