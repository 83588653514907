import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Pagination from "../../../components/common/pagination";
import { getAllClaimsToApprove, approveRejectEmployeeClaims } from "../../../redux/actions/approveRejectClaimsAction"
import ViewClaimItemsModal from "./ViewClaimItemsModal";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
import { AppContext } from '../../../hoc/AppContext';
import moment from "moment";
import { dateFormatList } from "variables/constants";
class ApproveRejectExpense extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isOpenStepper: false,
      cid: 1,
      eid: '147',
      isOpenEmpDetails: false,
      empId: -1,
      formType: "Add",
      isOpenClaimModal: false,
      claim: null,
      alert: null,
      isLoading: false,
      isDataLoading: false
    };
  }
  async UNSAFE_componentWillMount() {
    this.setState({
      isDataLoading: true
    })
    const response = await this.props.getAllClaimsToApprove(this.props.eid);
    if (response) {
      this.setState({
        isDataLoading: false
      })
    }
    // this.props.getAllClaimCategories();

  }
  handleApprove = async (exp) => {
    let data = {
      cid: exp.cid,
      claimStatus: "Approved",
      eid: exp.eid,
      id: exp.id,
      remark: exp.remark
    }
    if (this.state.isLoading === false) {
      this.setState({
        isLoading: true
      })
      const response = await this.props.approveRejectEmployeeClaims(data);
      if (response.success) {
        let message = {
          message: "Claim approved successfully.",
          type: "success",
        };
        this.handleMessage(message);
        setTimeout(() => {
          this.setState({
            isLoading: false
          })
        }, 3000);
      } else {
        let message = {
          message: "Error occurred while approving claim.",
          type: "warning",
        };
        this.handleMessage(message);
        setTimeout(() => {
          this.setState({
            isLoading: false
          })
        }, 3000);
      }
    }
  }
  handleReject = (exp) => {
    this.setState({
      alert: (
        <ReactBSAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="Add remark"
          onConfirm={e => this.inputConfirmAlert(e, exp)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          btnSize="sm"
        />
      )
    });
  }

  inputConfirmAlert = async (e, exp) => {
    let data = {
      cid: exp.cid,
      claimStatus: "Rejected",
      eid: exp.eid,
      id: exp.id,
      remark: e
    }
    if (this.state.isLoading === false) {
      this.setState({
        isLoading: true
      })
      const response = await this.props.approveRejectEmployeeClaims(data);
      if (response.success) {
        let message = {
          message: "Claim rejected successfully.",
          type: "success",
        };
        this.handleMessage(message);
        setTimeout(() => {
          this.setState({
            isLoading: false
          })
        }, 3000);
        this.hideAlert()
      } else {
        let message = {
          message: "Error occurred while rejecting Claim.",
          type: "warning",
        };
        this.handleMessage(message);
        setTimeout(() => {
          this.setState({
            isLoading: false
          })
        }, 3000);
      }

    }

  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  toggleClaimItemsModal = (claim) => {
    this.setState({
      isOpenClaimModal: !this.state.isOpenClaimModal,
      claim: claim
    });
  }

  onClose = () => {
    this.setState({
      isOpenClaimModal: false,
    });
  }

  handleMessage = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message.message,
      type: message.type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 2
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  }


  render() {
    const { config } = this.context;
    const claimData = this.props.claims.map((exp, key) => {
      return {
        index: key,
        claimNumber: exp.claimNumber.toUpperCase(),
        dateSubmitted: moment(exp.dateSubmitted).format(dateFormatList[2]),
        empName: (<div>
          <span id={"tooltip207191" + key}>{exp.empName}</span>
          <UncontrolledTooltip
            delay={0}
            target={"tooltip207191" + key}
            placement="right"
          >
            {exp.empName}
          </UncontrolledTooltip>

        </div>
        ),
        shortDesc: (<div>
          <span id={"tooltip203491" + key}>{exp.shortDesc}</span>
          <UncontrolledTooltip
            delay={0}
            target={"tooltip203491" + key}
            placement="right"
          >
            {exp.shortDesc}
          </UncontrolledTooltip>

        </div>
        ),
        amount: exp.amount.toFixed(2),
        claimStatus: exp.claimStatus,
        noOfItems: exp.claimItems.length,
        view: (
          <div className="actions-left">
            <Button
              onClick={() => {
                this.toggleClaimItemsModal(exp);
              }}
              size="sm"
              id="tooltip209543"
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              <i className="tim-icons icon-zoom-split" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="tooltip209543"
              placement="left"
            >
              View
            </UncontrolledTooltip>
            <Button
              onClick={() => {
                this.handleApprove(exp);
              }}
              style={{ marginLeft: 25, marginRight: 25 }}
              size="sm"
              id="tooltip209541"
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              <i className="tim-icons icon-check-2" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="tooltip209541"
              placement="left"
            >
              Approve
            </UncontrolledTooltip>
            <Button
              onClick={() => this.handleReject(exp)}
              size="sm"
              id="tooltip209542"
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="tooltip209542"
              placement="left"
            >
              Reject
            </UncontrolledTooltip>
          </div>
        ),
      };
    });
    return (
      <>
        <div className="content">
          <div className="rna-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          <div>
            {this.state.alert}
          </div>
          {this.state.isOpenClaimModal &&
            <ViewClaimItemsModal modal={this.state.isOpenClaimModal} claim={this.state.claim}
              toggle={this.toggleClaimItemsModal} close={this.onClose} />
          }
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CardTitle tag="h2">Approve Reject Expense</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    PaginationComponent={Pagination}
                    data={claimData}
                    resizable={false}
                    columns={[
                      {
                        Header: "Claim No.",
                        accessor: "claimNumber",
                        sortable: false,
                        filterable: false,
                        width: 120,
                      },
                      {
                        Header: "Submitted Date",
                        accessor: "dateSubmitted",
                        width: 130,
                      },
                      {
                        Header: "Description",
                        accessor: "shortDesc",
                        width: 220,
                      },
                      {
                        Header: "Employee",
                        accessor: "empName",
                        width: 180,
                      },
                      {
                        Header: "Amount",
                        accessor: "amount",
                        width: 70,
                      },
                      {
                        Header: "Status",
                        accessor: "claimStatus",
                        width: 95,
                      },
                      {
                        Header: "Items",
                        accessor: "noOfItems",
                        width: 52,
                      }, {
                        Header: "",
                        accessor: "view",
                        width: 160,
                      },
                    ]}
                    LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                    NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                    // pageSize={this.props.claims.length <= 10 ? this.props.claims.length : 10}
                    pageSize={this.props.claims.length <= config.paginationSize ? this.props.claims.length : config.paginationSize}
                    className="-striped -highlight project-table expense-table"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  getAllClaimsToApprove: bindActionCreators(getAllClaimsToApprove, dispatch),
  approveRejectEmployeeClaims: bindActionCreators(approveRejectEmployeeClaims, dispatch)
});

export const mapStateToProps = ({ claimsForApprove, auth }) => ({
  claims: claimsForApprove,
  cid: auth.user.cid,
  eid: auth.user.eid
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveRejectExpense);
