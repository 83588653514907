import { FETCH_COMPANY_BASIC_INFO } from '../actions/appConfig';

const INITIAL_STATE = {
    cid: 0,
    companyName: null,
    logo: null
}
const appConfig = (state = {}, action) => {
    let newState;
    switch (action.type) {
        case FETCH_COMPANY_BASIC_INFO:
            newState = { ...state };
            newState = action.data === null ? { ...INITIAL_STATE } : { ...action.data }
            return newState;
        default:
            return state;
    }
}

export default appConfig;