import { getAllRolesAPI } from '../../services/roles-service';
export const FETCH_ALL_ROLES = 'FETCH_ALL_ROLES';

export const getAllRoles = (cid) => {
    let roles = []
    return async (dispatch) => {
        const response = await getAllRolesAPI(cid);
        if (response.data) {
            roles = response.data
            dispatch({
                type: FETCH_ALL_ROLES,
                roles: roles
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}
