
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../assets/css/category-modal.css';
import LeaveDetailsForm from "./leaveDetailsForm.js";
import moment from 'moment';


class LeaveDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      holidayList: []
    };

  }
  UNSAFE_componentWillMount() {
        let dateArray = []
        this.props.holidays.map(date => {
        dateArray.push(moment(date.holidayDate).format("YYYY-MM-DD")); 
        })
        this.setState({
          holidayList:dateArray
        })
  }

  render() {
    
    return (
      <Modal className="custom-modal" isOpen={this.props.isOpen} toggle={this.props.toggle} style={{
        width: "100%",
        maxWidth: 700
      }}>
        <ModalHeader style={{ padding: 20,marginBottom: 10 }} toggle={this.props.toggle}><strong>Leave Details</strong></ModalHeader>
        <ModalBody>
          <LeaveDetailsForm holidayList={this.state.holidayList} userData={this.props.userData} leaves={this.props.leaves} handleLeaveAlreadyExisits = {this.props.handleLeaveAlreadyExisits} checkLeaveExists={this.props.checkLeaveExists} addUpdateLeave={this.props.addUpdateLeave} leaveTypes={this.props.leaveTypes} toggle={this.props.toggle} showNotifications={this.props.showNotifications} leave={this.props.leave} formtype={this.props.formtype} />
        </ModalBody>
      </Modal>
    );
  }
}

export default LeaveDetailsModal