import  { GET_ACTIVE_LEAVE_TYPES } from '../actions/leaveTypeActions';


const INITIAL_STATE = []

const activeleaveTypeReducer = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case GET_ACTIVE_LEAVE_TYPES:
            newState = action.leavesTypes;
            return newState;

        default: return state;
  }
};

export default activeleaveTypeReducer; 