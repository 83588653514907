import { FETCH_ALL_COMPANY_CONTACTS } from "../actions/companyContactAction";

const companyContactReducer = function (state = [], action) {
    let newState;
    let index;
    let id;
    switch (action.type) {
        case FETCH_ALL_COMPANY_CONTACTS:
            return action.contacts;
        default:
            return state;
    }
};

export default companyContactReducer;