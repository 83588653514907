import React, { Component } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import ReactTable from "react-table";
import Pagination from "../../components/common/pagination";
import { AppContext } from '../../hoc/AppContext';


class AdminProjectMarginReportModal extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        let { resources, project } = this.props;
        const { config } = this.context;
        const data = this.props.resources.map((resource, key) => {
            return {
                empName: resource.empName,
                totalBillingRate: (
                    <div className="actions-right custom-action-right custom-total-hours">
                        {resource.totalBillingRate}
                    </div>
                ),
                totalCost: (
                    <div className="actions-right custom-action-right custom-total-hours">
                        {resource.totalCost}
                    </div>
                ),
                margin: (
                    <div className="actions-right custom-action-right custom-total-hours">
                        {resource.margin}
                    </div>
                ),
                totalHrs: (
                    <div className="actions-right custom-action-right custom-total-hours">
                        {resource.totalHrs}
                    </div>
                ),
                allocationPercent: (
                    <div className="actions-right custom-action-right custom-total-hours">
                        {resource.allocationPercent}
                    </div>
                )
            }
        });
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="custom-modal modal-lg project-modal"  >
                    <ModalHeader toggle={this.props.toggle}><h4>
                        Project Resources Details
                    </h4></ModalHeader>
                    <ModalBody>

                        <div>
                            <h4 className="project-title">Project : <span className="custom-project-name" style={{ fontWeight: 600 }}> {project} </span></h4>
                            <ReactTable
                                PaginationComponent={Pagination}
                                data={data}
                                // filterable
                                // nextText={myCustomNextText}
                                resizable={false}
                                columns={[
                                    {
                                        Header: "Employee",
                                        accessor: "empName",
                                        minWidth: 150
                                    },
                                    {
                                        Header: "Billing ",
                                        accessor: "totalBillingRate",
                                        headerClassName: 'header-right'
                                    },
                                    {
                                        Header: "Cost",
                                        accessor: "totalCost",
                                        headerClassName: 'header-right'
                                    },
                                    {
                                        Header: "Margin",
                                        accessor: "margin",
                                        headerClassName: 'header-right'
                                    },
                                    {
                                        Header: "Logged Hours",
                                        accessor: "totalHrs",
                                        width: 125,
                                        headerClassName: 'header-right'
                                    },
                                    {
                                        Header: "Percent Allocation",
                                        accessor: "allocationPercent",
                                        width: 160,
                                        headerClassName: 'header-right'
                                    },
                                ]}
                                NoDataComponent={() => null}
                                // pageSize={this.props.resources.length <= 10 ? this.props.resources.length : 10}
                                pageSize={this.props.resources.length <= config.paginationSize ? this.props.resources.length : config.paginationSize}
                                className="-striped -highlight project-table"
                            />
                        </div>

                    </ModalBody>
                    <ModalFooter style={{ display: 'block', float: 'right' }}>
                        <Button color="secondary" size="sm" style={{ float: 'right' }} onClick={this.props.close}>OK</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

}

export default AdminProjectMarginReportModal;