import React, { Component } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';



class TimesheetModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let { empid, employeeName, workDay, workDate, project, totalHours, comments, status, rejectionNote } = this.props.report;
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="custom-modal modal-lg timesheet-modal"  >
                    <ModalHeader toggle={this.props.toggle}><h4>
                        Timesheet Report Details
                    </h4></ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="5">
                                <div style={{ display: 'flex' }} >
                                    <h5>Employee Number :</h5> <h5> {empid}</h5>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5>Employee Name : </h5> <h5> {employeeName}</h5>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5>Project :</h5> <h5> {project}</h5>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5>Work Day :</h5> <h5> {workDay}</h5>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5>Work Date :</h5> <h5> {workDate}</h5>
                                </div>

                            </Col>

                            <Col>
                                <div style={{ display: 'flex' }}>
                                    <h5>Total Hours :</h5> <h5> {totalHours}</h5>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5>Comment :</h5><h5>{comments}</h5>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <h5>Status :</h5><h5> {status}</h5>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <h5>Rejection Note :</h5> <h5> {rejectionNote}</h5>
                                </div>
                            </Col>
                        </Row>



                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" size="sm" onClick={this.props.close}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

}

export default TimesheetModal;