import { getCompanyLocationsAPI,addUpdateCompanyLocations } from '../../services/company-service';

export const FETCH_ALL_COMPANIES_LOCATIONS = 'FETCH_ALL_COMPANIES_LOCATIONS';
export const ADD_UPDATE_COMPANY_LOCATIONS = 'ADD_UPDATE_COMPANY_LOCATIONS';

export const getCompanyLocations = (cid) => {
    return async (dispatch) => {
        const response = await getCompanyLocationsAPI(cid);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_COMPANIES_LOCATIONS,
                locations: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

export const addUpdateCompanyLocation = (companyLocation) =>  {
    return async (dispatch) => {
        const response = await addUpdateCompanyLocations(companyLocation);
            if (response.data){
                dispatch({
                    type: ADD_UPDATE_COMPANY_LOCATIONS,
                    payload: {
                      id:companyLocation.id,
                      companyLocation: response.data
                  }
                  });
                return { success: true, data:response.data };
            }
            else{
                return { success: false};
            }    
        }
  }