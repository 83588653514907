import { FETCH_ALL_APPROVAL_PENDING_TIMESHEET, APPROVE_REJECT_WEEKLY_TIMESHEET  } from "../actions/timesheetApprovalPendingAction";
const timesheetApprovalPendingReducer = function (state = [], action) {
    let newState;
    let index;
    let id;
    switch (action.type) {
        case FETCH_ALL_APPROVAL_PENDING_TIMESHEET:
            return action.timesheetApprovalPending;
        case APPROVE_REJECT_WEEKLY_TIMESHEET:
            newState = [...state];
            id = action.payload.id;
            index = newState.findIndex(timesheet =>timesheet.id === id);
            if (index > -1) {
                newState.splice(index, 1);
            };
            return newState;
        default:
            return state;
    }
};

export default timesheetApprovalPendingReducer;