
import { getAllCurrenciesAPI } from '../../services/currencies-service';

export const FETCH_ALL_CURRENCIES = 'FETCH_ALL_CURRENCIES';

export const getAllCurrencies = () => {
    return async (dispatch) => {
        const response = await getAllCurrenciesAPI();
        if (response.data) {
            dispatch({
                type: FETCH_ALL_CURRENCIES,
                currencies: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}