import React, { useContext, useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle, Spinner, Table } from "reactstrap";
import { getRejectedTimesheetReport } from "redux/actions/timesheetReportAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { resetRejectedTimesheetReport } from "redux/actions/timesheetReportAction";
import ReactTable from "react-table";
import LoadingIndicator from "components/common/LoadingIndicator";
import NoData from "components/common/NoData";
import { AppContext } from "hoc/AppContext";
import Pagination from "components/common/pagination";
import { dateFormatList } from "variables/constants";
import moment from "moment";


export const RejectedTimesheet = (props) => {

    useEffect(() => {
        props.getRejectedTimesheetReport({ cid: props.user.cid, eid: props.user.eid })

        return () => {
            props.resetRejectedTimesheetReport();
        }
    }, [])

    const { config } = useContext(AppContext);

    return (
        <div className="content">
            <Card>
                <CardHeader>
                    <CardTitle tag="h2">Rejected Timesheet</CardTitle>
                </CardHeader>
                <CardBody>
                    <ReactTable
                        PaginationComponent={Pagination}
                        data={props.rejectedTimesheet}
                        resizable={true}
                        columns={[
                            {
                                Header: "Start Date",
                                accessor: "startDate",
                                sortable: false,
                                filterable: false,
                                width: 150,
                                resizable: false,
                                Cell: ({ value }) => {
                                    return <p>{moment(value).format(dateFormatList[2])}</p>
                                }
                            },
                            {
                                Header: "End Date",
                                accessor: "endDate",
                                sortable: false,
                                filterable: false,
                                width: 150,
                                resizable: false,
                                Cell: ({ value }) => {
                                    return <p>{moment(value).format(dateFormatList[2])}</p>
                                }
                            },
                            {
                                Header: "Project",
                                accessor: "project",
                                sortable: false,
                                filterable: false,
                                resizable: false
                            },
                            {
                                Header: "Manager",
                                accessor: "manager",
                                resizable: false
                            },
                            {
                                Header: "Total Hours",
                                accessor: "totalHours",
                                resizable: false
                            },
                            {
                                Header: "Rejection Note",
                                accessor: "rejectionNote"
                            }
                        ]}
                        LoadingComponent={() => (<LoadingIndicator loading={props.loading} />)}
                        NoDataComponent={() => (<NoData loading={props.loading} />)}
                        pageSize={props.rejectedTimesheet.length <= config.paginationSize ? props.rejectedTimesheet.length : config.paginationSize}
                        showPaginationBottom={true}
                        className="-striped -highlight project-table leave-table expense-table"
                    />
                </CardBody>
            </Card>
        </div >
    )
}

export const mapDispatchToProps = dispatch => ({
    getRejectedTimesheetReport: bindActionCreators(getRejectedTimesheetReport, dispatch),
    resetRejectedTimesheetReport: bindActionCreators(resetRejectedTimesheetReport, dispatch)
});


export const mapStateToProps = ({ auth, timesheetReport, }) => {
    return {
        rejectedTimesheet: timesheetReport.rejectedTimesheetReport,
        loading: timesheetReport.loading,
        user: auth.user,
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(RejectedTimesheet);