import { FETCH_EMPLOYEES_EXPENSE_REPORT_ADMIN } from "../actions/adminExpenseReportAction";

const INITIAL_STATE = {
    expenseReport: [],
}
const adminExpenseReportReducer = function (state = INITIAL_STATE, action) {
    let newState;
    let index;
    switch (action.type) {
        case FETCH_EMPLOYEES_EXPENSE_REPORT_ADMIN:
            newState = { ...state }
            newState.expenseReport = action.expenseReport && action.expenseReport.length > 0 ? [...action.expenseReport] : [];
            return newState;

        default:
            return state;
    }
};

export default adminExpenseReportReducer;