import axios from 'axios';


export const getAllClaimCategoriesAPI = () => {
  return axios
    .get(`/api/v1/claim-categories`)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getAllClaimsToApproveAPI = (eid, cid) => {
  return axios
    .get(`/api/v1/approval-pending-claims`, {
      params: {
        cid: cid,
        eid: eid
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const approveRejectEmployeeClaimsAPI = (claim) => {
  return axios
    .post(`/api/v1/approve-reject-claim`, claim)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getAllEmployeeClaimsAPI = (cid, eid) => {
  return axios
    .get(`/api/v1/employee-claims`, {
      params: {
        cid: cid,
        eid: eid
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const addUpdateEmployeeClaimAPI = (claim) => {
  return axios
    .post(`/api/v1/add-update-employee-claim`, claim)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const deleteClaimAPI = (claimId, cid) => {
  return axios
    .get(`/api/v1/delete-claim`, {
      params: {
        cid: cid,
        claimId: claimId
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const deleteClaimItemAPI = (claimItemId) => {
  return axios
    .get(`/api/v1/delete-claim-item`, {
      params: {
        claimItemId: claimItemId
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}
