import { FETCH_ALL_COMPANIES_LOCATIONS,ADD_UPDATE_COMPANY_LOCATIONS } from "../actions/companyLocationAction";

const companyLocationReducer = function (state = [], action) {
    let newState;
    let index;
    let id;
    switch (action.type) {
        case FETCH_ALL_COMPANIES_LOCATIONS:
            return action.locations;
        case ADD_UPDATE_COMPANY_LOCATIONS:
            newState = [...state];
            index = newState.findIndex(newCompany =>newCompany.id === action.payload.id);
            if (index > -1) {
                newState.splice(index, 1, action.payload.companyLocation);
            }
            else{
                newState = [...state];
                newState.push(action.payload.companyLocation);
                return newState;
            }
            return newState;       
        default:
            return state;
    }
};

export default companyLocationReducer;