import { getManagerTimesheetWeeklyReportAPI, getManagerTimesheetMonthlyReportAPI, getManagerTimesheetQuarterlyReportAPI, getManagerProjectMarginReportAPI } from '../../services/timesheets-service';

export const FETCH_MANAGER_TIMESHEET_WEEKLY_REPORT = 'FETCH_MANAGER_TIMESHEET_WEEKLY_REPORT';
export const FETCH_MANAGER_TIMESHEET_MONTHLY_REPORT = 'FETCH_MANAGER_TIMESHEET_MONTHLY_REPORT';
export const FETCH_MANAGER_TIMESHEET_QUARTERLY_REPORT = 'FETCH_MANAGER_TIMESHEET_QUARTERLY_REPORT';
export const FETCH_INITIAL_MANAGER_REPORT_DATA = 'FETCH_INITIAL_MANAGER_REPORT_DATA';
export const FETCH_MANAGER_PROJECT_MARGIN_REPORT = 'FETCH_MANAGER_PROJECT_MARGIN_REPORT';
let token = localStorage.getItem('token');

export const getManagerTimesheetWeeklyReport = (cid, eid, managerId, startDate, endDate, status, quarter, month, year) => {
    return async (dispatch) => {
        const response = await getManagerTimesheetWeeklyReportAPI(cid, eid, managerId, startDate, endDate, status, quarter, month, year);
        if (response.data) {
            dispatch({
                type: FETCH_MANAGER_TIMESHEET_WEEKLY_REPORT,
                timesheetWeeklyReport: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}


export const getManagerTimesheetMonthlyReport = (cid, eid, managerId, startDate, endDate, status, quarter, month, year) => {
    return async (dispatch) => {
        const response = await getManagerTimesheetMonthlyReportAPI(cid, eid, managerId, startDate, endDate, status, quarter, month, year);
        if (response.data) {
            dispatch({
                type: FETCH_MANAGER_TIMESHEET_MONTHLY_REPORT,
                timesheetMonthlyReport: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

export const getManagerTimesheetQuarterlyReport = (cid, eid, managerId, startDate, endDate, status, quarter, month, year) => {
    return async (dispatch) => {
        const response = await getManagerTimesheetQuarterlyReportAPI(cid, eid, managerId, startDate, endDate, status, quarter, month, year);
        if (response.data) {
            dispatch({
                type: FETCH_MANAGER_TIMESHEET_QUARTERLY_REPORT,
                timesheetQuarterlyReport: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

export const getInitialReportData = () => {

    return async (dispatch) => {
        dispatch({
            type: FETCH_INITIAL_MANAGER_REPORT_DATA,
            timesheetReport: []
        });
        return { success: true };

    }
}


export const getManagerProjectMarginReport = (cid, managerId) => {

    return async (dispatch) => {
        const response = await getManagerProjectMarginReportAPI(cid, managerId);

        if (response.data) {
            dispatch({
                type: FETCH_MANAGER_PROJECT_MARGIN_REPORT,
                managerProjectMarginReport: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}