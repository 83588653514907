import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import ReactTable from "react-table";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row, FormGroup, Input, Label, } from "reactstrap";
import moment from 'moment';
import ReactDatetime from "react-datetime";
import TimesheetModal from "./timesheetModal";
import Pagination from "../../components/common/pagination";
import { dateFormatList } from "variables/constants";


const dataTable = [
    [101, "John Doe", "Monday", "2020-03-03", "Mehko", 38, "Lorem ipsum.", "Rejected", "Please enter valid hours"],
    [102, "Sam Curran", "Tuesday", "2020-03-11", "SingularityAIX", 8, "ipsum forge", "Apporved", "",],
    [103, "Steve Smith", "Wednesday", "2020-03-15", "ESS", 12, "lorem ipsum", "Saved", "",],
    [104, "David Warner", "Friday", "2020-03-18", "BusinessBrainiac", 12, "lorem ipsum", "Apporved", "",],
    [103, "Steve Smith", "Saturday", "2020-03-23", "EducationBrainiac", 12, "lorem ipsum", "Saved", "",],

];

class TimesheetReportDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            startDate: moment().startOf('isoweek').format('YYYY-MM-DD'),
            endDate: moment().endOf('isoweek').format('YYYY-MM-DD'),
            status: 'All',
            report: {},
            employeeName: 'All',
            empid: 101,
            data: dataTable.map((prop, key) => {
                return {
                    empid: prop[0],
                    employeeName: prop[1],
                    workDay: prop[2],
                    workDate: moment(prop[3]).format(dateFormatList[2]),
                    project: prop[4],
                    totalHours: prop[5],
                    comments: prop[6],
                    status: prop[7],
                    rejectionNote: prop[8],

                    view: (
                        // we've added some custom button actions
                        <div className="">
                            <Button
                                color="default"
                                size="sm"
                                onClick={() => {
                                    let report = this.state.data.find(o => o.empid === prop[0]);


                                    // let { id,
                                    //     project,
                                    //     startDate,
                                    //     endDate,
                                    //     status, clientId, cid } = obj;
                                    // let projectItem = { id, project, startDate, endDate, status, clientId, cid }
                                    this.showTimesheetModal(report)
                                }}
                            >
                                View
                            </Button>
                        </div>
                    )
                };
            })
        };
    }

    showTimesheetModal = (report) => {
        this.setState({
            showModal: true,
            report: { ...report }
        });
    }
    toggleShowModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    handleSubmit = () => {
        this.setState({
            showModal: false,
        });
    }

    onClose = () => {
        this.setState({
            showModal: false,
        });
    }

    handleStartDate = (date) => {
        this.setState({
            ...this.state,
            startDate: moment(date).format('YYYY-MM-DD')
        });
    }



    handleEndDate = (date) => {
        this.setState({
            ...this.state,
            endDate: moment(date).format('YYYY-MM-DD')
        });
    }


    componentDidMount() {

    }

    notify = (place, type, message) => {

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };



    handleFind = () => {
        let formatedData = dataTable.map((prop, key) => {
            return {
                empid: prop[0],
                employeeName: prop[1],
                workDay: prop[2],
                workDate: prop[3],
                project: prop[4],
                totalHours: prop[5],
                comments: prop[6],
                status: prop[7],
                rejectionNote: prop[8],
                view: (
                    // we've added some custom button actions
                    <div className="">
                        <Button
                            color="default"
                            size="sm"
                            onClick={() => {
                                let report = this.state.data.find(o => o.empid === prop[0]);


                                // let { id,
                                //     project,
                                //     startDate,
                                //     endDate,
                                //     status, clientId, cid } = obj;
                                // let projectItem = { id, project, startDate, endDate, status, clientId, cid }
                                this.showTimesheetModal(report)
                            }}
                        >
                            View
                        </Button>
                    </div>
                )
            };
        });

        let filteredData = [];
        if (this.state.employeeName !== "All") {
            let EmployeeFilteredData = formatedData.filter(dataItem => dataItem.employeeName === this.state.employeeName);
            filteredData = EmployeeFilteredData.filter(dataItem => this.state.status === "All" ? moment(dataItem.workDate).isBetween(this.state.startDate, this.state.endDate, null, []) : dataItem.status === this.state.status && moment(dataItem.workDate).isBetween(this.state.startDate, this.state.endDate, null, []));
        } else {
            filteredData = formatedData.filter(dataItem => this.state.status === "All" ? moment(dataItem.workDate).isBetween(this.state.startDate, this.state.endDate, null, []) : dataItem.status === this.state.status && moment(dataItem.workDate).isBetween(this.state.startDate, this.state.endDate, null, []));
        }

        this.setState({
            data: filteredData
        })
    }

    handleSelectEmployee = (e) => {
        // let employeeName = e.target.value;
        this.setState({
            ...this.state,
            employeeName: e.target.value
        })

    }

    handleStatus = (e) => {
        // let status = e.target.value;
        this.setState({
            ...this.state,
            status: e.target.value
        })

    }


    render() {

        // const myCustomNextText = () => {
        //     return
        // };
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    {this.state.showModal && <TimesheetModal modal={this.state.showModal} toggle={this.toggleShowModal} report={this.state.report} close={this.onClose} />}

                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{}}>
                                    <CardTitle tag="h2" style={{ marginBottom: 20 }}>Timesheet Report Details</CardTitle>

                                    <Row >
                                        <Col md="2" >
                                            <Label >Start Date </Label>
                                            <FormGroup className={`has-label`}>
                                                <ReactDatetime
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: "Start Date", className: "form-control", value: moment(this.state.startDate).format(dateFormatList[2]) }}
                                                    onChange={this.handleStartDate}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" >
                                            <Label >End Date </Label>
                                            <FormGroup className={`has-label`}>
                                                <ReactDatetime
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: "End Date", className: "form-control", value: moment(this.state.endDate).format(dateFormatList[2]) }}
                                                    onChange={this.handleEndDate}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <Label Label for="exampleSelect">Employee</Label>
                                            <FormGroup className={`has-label  `}>
                                                <Input type="select" name="cid" defaultValue={this.state.employeeName} onChange={this.handleSelectEmployee} id="exampleSelect">
                                                    <option value="All" >All</option>
                                                    <option value="John Doe">John Doe</option>
                                                    <option value="Sam Curran">Sam Curran</option>
                                                    <option value="Steve Smith">Steve Smith</option>
                                                    <option value="Justin Langer">Justin Langer</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <Label Label for="exampleSelect">Status</Label>
                                            <FormGroup className={`has-label  `}>
                                                <Input type="select" name="status" defaultValue={this.state.status} onChange={this.handleStatus} id="exampleSelect">
                                                    <option value="All">All</option>
                                                    <option value="Apporved">Apporved</option>
                                                    <option value="Rejected">Rejected</option>
                                                    <option value="Saved">Saved</option>
                                                    <option value="Submitted">Submitted</option>
                                                </Input>
                                                {this.state.clientIdState === "has-danger" ? (
                                                    <label className="error">please select client!</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>


                                        <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 8, }}>
                                            <Button onClick={this.handleFind} size="sm" color="info">Find</Button>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 8, marginLeft: 13 }}>
                                            <Button size="sm" color="secondary">Export To PDF</Button>
                                        </div>
                                    </Row>


                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={this.state.data}
                                        // filterable
                                        // nextText={myCustomNextText}
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "Employee",
                                                accessor: "employeeName",
                                                width: 150,
                                            },
                                            {
                                                Header: "Work Day",
                                                accessor: "workDay"
                                            },
                                            {
                                                Header: "Work Date",
                                                accessor: "workDate"
                                            },
                                            {
                                                Header: "Project",
                                                accessor: "project"
                                            },
                                            {
                                                Header: "Total Hours",
                                                accessor: "totalHours",
                                                width: 110,
                                            },
                                            {
                                                Header: "Comments",
                                                accessor: "comments"
                                            },
                                            {
                                                Header: "Status",
                                                accessor: "status"
                                            },
                                            // {
                                            //     Header: "View",
                                            //     accessor: "view",
                                            //     sortable: false,
                                            //     filterable: false,
                                            // },
                                        ]}
                                        NoDataComponent={() => null}
                                        pageSize={dataTable.length <= 10 ? dataTable.length : 10}
                                        className="-striped -highlight project-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default TimesheetReportDashboard;
