import axios from 'axios';

export const getAllVendorsAPI = (cid) => {
    return axios
      .get(`/api/v1/vendors`,{
        params: {
            cid:cid
          }})
      .then(function (response) {
        if (response.data) {
          return { success: true, data:response.data };
        }else{
            return { success: false };
        }
      })
      .catch(function (error) {  
        return { success: false };
      });
}

export const addUpdateVendor = (vendorDTO) => {
    return axios.post(`/api/v1/add-update-vendor`, vendorDTO)
      .then(function (response) {
        if (response.status == 200) {
          return { success: true, data: response.data };
        }
      })
      .catch(function (error) {
        return { success: false };
      });
  }