import React, { Component } from "react";
import moment from "moment";
import classNames from "classnames";
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import LeaveDetailsModal from './detailsModal';
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { fetchEmployeeLeaves } from 'redux/actions/leaveManagementActions';
import { fetchActiveLeaveTypes } from "redux/actions/leaveTypeActions";
import { fetchHolidayList } from "redux/actions/holidayListActions";
import { addUpdateLeave, checkLeaveExists, fetchEmployeeLeavesAPI } from "../../../services/leaves-service";
import { getHolidaysByLocation } from "redux/actions/holidaysByLocationAction";
import Pagination from "../../../components/common/pagination";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NoData from "../../../components/common/NoData";
import { AppContext } from '../../../hoc/AppContext';
import { dateFormatList } from "variables/constants";
class LeaveDetails extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      leave: {},
      formType: 'add',
      alert: null,
      isDataLoading: false

    };
  }

  handeOpenModal = () => {
    this.setState({
      isOpen: true,
      formType: 'add',
    })
  };

  toggle = () => {
    //
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  //notificationAlert = React.createRef();

  notify = (place, color, message) => {
    // var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  };


  showNotifications = (color, message) => {
    this.notify("tr", color, message);
  }

  showLeaveModal = (leave) => {
    //
    this.setState({
      formType: 'update',
      isOpen: true,
      leave: { ...leave }
    });
  }

  warningWithConfirmMessage = (leaveDetails, type) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure about submitting application?"
          onConfirm={() => this.addUpdateLeave(leaveDetails, type)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, do it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize="sm"
        >

        </ReactBSAlert>
      )
    });
  };

  warningWithConfirmMessageForDelete = (leaveDetails, type) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure about deleting leave application?"
          onConfirm={() => this.addUpdateLeave(leaveDetails, type)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, do it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize="sm"
        >

        </ReactBSAlert>
      )
    });
  };

  successAlert = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 2
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  basicAlert = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 2
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  };

  handleLeaveAlreadyExisits = () => {
    this.toggle();
    let message = 'Leave has already been applied for selected date '
    this.basicAlert(message);
  }

  submitLeave = async (leave) => {
    let leaveObject = {
      ...leave,
      leaveStatus: "Submitted"
    }

    this.warningWithConfirmMessage(leaveObject, 'submit');
  }

  deleteLeave = (leave) => {
    let deleteObj = {
      ...leave,
      isDelete: "Y"
    }

    //
    this.warningWithConfirmMessageForDelete(deleteObj, 'delete');
  }


  addUpdateLeave = async (leave, leaveType) => {
    if (leaveType === 'submit' || leaveType === 'delete') {
      this.hideAlert();
    } else {
      this.toggle();
    }

    let message = "";
    if (leaveType === 'update') {
      message = "Leave has been updated successfully.";
    } else if (leaveType === 'submit') {
      message = "Leave has been submitted successfully.";
    } else if (leaveType === 'delete') {
      message = "Leave has been deleted successfully.";
    } else {
      message = "Leave has been added successfully.";
    }

    let result = await addUpdateLeave(leave);

    if (result.success) {
      this.successAlert(message);
      this.getLeaves();
    } else {
      this.showNotifications(3, "Failed to perform the operation!");
    }
  }

  getLeaves = async () => {
    this.setState({
      isDataLoading: true
    })
    const response = await this.props.fetchEmployeeLeaves(this.props.userData.cid, this.props.userData.eid);
    if (response) {
      this.setState({
        isDataLoading: false
      })
    }
    let data = this.props.leaves.map((prop, key) => {
      return {
        id: key + 1 + ".",
        leaveId: prop.id,
        companyId: prop.cid,
        empId: prop.eid,
        startDate: moment(prop.startDate).format(dateFormatList[2]),
        endDate: moment(prop.endDate).format(dateFormatList[2]),
        leaveDays: prop.noOfDays,
        leaveType: prop.leaveType,
        leaveStatus: prop.leaveStatus,
        duration: prop.duration,
        reason: prop.leaveReason,
        text: (<div></div>),
        actions: (
          // we've added some custom button actions  
          <div className="actions-right">
            {
              prop.leaveStatus === "Rejected" || prop.leaveStatus === "Draft" ? (
                <div>
                  <Button
                    id="tooltip209811"
                    onClick={() => {
                      this.showLeaveModal(prop)
                    }}
                    // color="warning"
                    size="sm"
                    className={classNames("btn-icon btn-link like", {
                      "btn-neutral": key < 5
                    })}
                  >
                    <i className="tim-icons icon-pencil" />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target="tooltip209811"
                    placement="left"
                  >
                    Edit
                  </UncontrolledTooltip>
                  <Button
                    id="tooltip2011"
                    onClick={() => {
                      this.submitLeave(prop)
                    }}
                    // color="warning"
                    size="sm"
                    className={classNames("btn-icon btn-link like", {
                      "btn-neutral": key < 5
                    })}
                  >
                    <i className="tim-icons icon-check-2" />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target="tooltip2011"
                    placement="left"
                  >
                    Submit
                  </UncontrolledTooltip>

                  <Button
                    id="tooltip20111"
                    onClick={() => {
                      this.deleteLeave(prop)
                    }}
                    // color="warning"
                    size="sm"
                    className={classNames("btn-icon btn-link like", {
                      "btn-neutral": key < 5
                    })}
                  >
                    <i className="tim-icons icon-trash-simple" />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target="tooltip20111"
                    placement="left"
                  >
                    Delete
                  </UncontrolledTooltip>
                </div>
              ) : null
            }




          </div>

        )
      };
    })

    this.setState({
      data: data
    })
  }

  async UNSAFE_componentWillMount() {
    this.getLeaves()
    this.props.fetchActiveLeaveTypes(this.props.userData.cid);
    this.props.fetchHolidayList(this.props.cid, moment().year());
    //await this.props.fetchEmployeeLeaves(0,0);
    this.props.getHolidaysByLocation(this.props.cid, this.props.baseLocation)
  }

  render() {
    const { isOpen } = this.state;
    const { config } = this.context;

    let leaveCount = 0;
    this.props.leaves && this.props.leaves.length > 0 ? leaveCount = this.props.leaves[0].leaveBalance : leaveCount = 0;
    return (
      <>
        {this.state.alert}
        <NotificationAlert ref="notificationAlert" />
        {isOpen && <LeaveDetailsModal holidays={this.props.holidaysByLocation} userData={this.props.userData} handleLeaveAlreadyExisits={this.handleLeaveAlreadyExisits} leaves={this.props.leaves} addUpdateLeave={this.addUpdateLeave} checkLeaveExists={checkLeaveExists} leaveTypes={this.props.activeLeaveTypes} toggle={this.toggle} isOpen={isOpen} leave={this.state.leave} formtype={this.state.formType} showNotifications={this.showNotifications} />}
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CardTitle tag="h2" style={{ margin: "auto 0px" }}>Leave Details  </CardTitle>

                  <div style={{ display: 'flex' }}>
                    <CardTitle tag="h4" style={{ margin: "auto" }}>Leave Balance: <span className="leave-count">{leaveCount}</span>  </CardTitle>
                    <Button size="sm" style={{ marginLeft: "10px" }} color="info" onClick={this.handeOpenModal}>New</Button>
                  </div>
                </CardHeader>
                {/* <CardHeader style={{ display: 'flex', justifyContent: 'center' }}>
                  <CardTitle tag="h4">Leave Balance: <span className="leave-count">{leaveCount}</span>  </CardTitle>
                </CardHeader> */}
                <CardBody>
                  <ReactTable
                    PaginationComponent={Pagination}
                    data={this.state.data}
                    //filterable
                    // nextText={myCustomNextText}
                    resizable={true}
                    columns={[
                      {
                        Header: "No.",
                        accessor: "id",
                        sortable: false,
                        filterable: false,
                        resizable: false,
                        width: 70
                      },
                      {
                        Header: "Start Date",
                        accessor: "startDate",
                        resizable: false,
                        width: 110
                      },
                      {
                        Header: "End Date ",
                        accessor: "endDate",
                        resizable: false,
                        width: 110
                      },
                      {
                        Header: "Duration",
                        accessor: "duration",
                        resizable: false,
                        width: 100
                      },
                      {
                        Header: "Days",
                        accessor: "leaveDays",
                        resizable: false,
                        width: 100
                      },
                      {
                        Header: "Reason",
                        accessor: "reason",
                        sortable: false,
                        filterable: false,
                        resizable: true,
                        width: 250
                      },
                      {
                        Header: "Status",
                        accessor: "leaveStatus",
                        sortable: false,
                        filterable: false,
                        resizable: false
                      },
                      {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        resizable: false
                        // width: 30,

                      },

                    ]}
                    LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                    NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                    pageSize={this.props.leaves.length <= config.paginationSize ? this.props.leaves.length : config.paginationSize}
                    className="-striped -highlight project-table leave-table expense-table"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {

  return {
    leaves: state.leaves,
    activeLeaveTypes: state.activeLeaveTypes,
    userData: state.auth.user,
    holidays: state.holidays,
    cid: state.auth.user.cid,
    baseLocation: state.auth.user.baseLocation,
    holidaysByLocation: state.holidaysByLocation,
  }

};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmployeeLeaves: bindActionCreators(fetchEmployeeLeaves, dispatch),
    fetchActiveLeaveTypes: bindActionCreators(fetchActiveLeaveTypes, dispatch),
    fetchHolidayList: bindActionCreators(fetchHolidayList, dispatch),
    getHolidaysByLocation: bindActionCreators(getHolidaysByLocation, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaveDetails);
