import { getAllInitialCompanyConfigsAPI } from '../../services/company-service';

export const FETCH_ALL_COMPANY_INITIAL_CONFIGS = 'FETCH_ALL_COMPANY_INITIAL_CONFIGS';

export const getAllInitialCompanyConfigs = (cid) => {
    return async (dispatch) => {
        const response = await getAllInitialCompanyConfigsAPI(cid);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_COMPANY_INITIAL_CONFIGS,
                configs: response.data
            });
            return { success: true, companyConfig: response.data };
        }
        else {
            return { success: false };
        }
    }
}