import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import { changePassword } from "../services/index";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Spinner
} from "reactstrap";

import { CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_SUCCESS, NEW_PASSWORD_ERROR, REQUIRD_MESSAGE, PASSWORD_REQUIREMENT_TEXT } from "../variables/constants";
import PasswordStrengthIndicator from "components/PasswordStrengthIndicator";
import zxcvbn from "zxcvbn";

var ps;

const passwordStrengthSettings = {
  colorScheme: {
    levels: ["#ff4033", "#fe940d", "#ffd908", "#cbe11d", "#6ecc3a"],
    noLevel: "lightgrey"
  },
  height: 5,
  alwaysVisible: false
};
const passwordStrengthTexts = ['Very weak', 'Weak', 'Fair', 'Good', 'Strong'];


class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      currentPasswordState: '',
      newPasswordState: '',
      confirmPasswordState: '',
      isLoading: false,
      passwordStrength: -1,
      passwordStrengthText: '',
    };
  }
  UNSAFE_componentWillMount() {
    this.setState({
      isLoading: this.props.isLoading ? this.props.isLoading : false
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.props.isLoading) {
      this.setState({
        isLoading: nextProps.isLoading,
      });
    }
  }

  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    this.setState({ [stateName]: event.target.value },
      () => {
        if (stateName === 'newPassword') {
          this.validatePasswordStrength()
        }

      }
    );
  };
  handleChangePassword = async () => {
    let { currentPassword, newPassword, confirmPassword, passwordStrength } = this.state;
    if (currentPassword != "" && newPassword != "" && confirmPassword != "") {
      if (this.state.isLoading === false) {
        this.setState({ isLoading: true });
        if (currentPassword === newPassword) {
          let message = {
            message: "The current password and new password should not be same!",
            type: "warning",
          }
          this.props.handleMessage(message);
          setTimeout(() => {
            this.setState({
              isLoading: false
            })
          }, 3000);
        } else if (newPassword != confirmPassword) {
          let message = {
            message: NEW_PASSWORD_ERROR,
            type: "warning",
          }
          this.props.handleMessage(message);
          setTimeout(() => {
            this.setState({
              isLoading: false
            })
          }, 3000);
        } else {


          if (passwordStrength >= 2) {

            const result = await changePassword(this.props.user.cid, window.btoa(currentPassword), this.props.user.eid, window.btoa(newPassword));
            if (result.success) {
              let message = {
                message: CHANGE_PASSWORD_SUCCESS,
                type: "success",
              }
              this.props.handleMessage(message);
              setTimeout(() => {
                this.setState({
                  isLoading: false
                })
              }, 3000);
              this.props.handeCloseModal();
              if (this.props.user.changePasswordOnLogin) {
                this.props.signoutUser();
              }
            }
            else {
              let message = {
                message: result.data,
                type: "warning",
              }
              this.props.handleMessage(message);
              setTimeout(() => {
                this.setState({
                  isLoading: false
                })
              }, 3000);
            }
          }
          else {
            // message.warning("The password does not meet the password policy requirements!");
            let message = {
              message: 'The password does not meet the password policy requirements!',
              type: "warning",
            }
            this.props.handleMessage(message);
            setTimeout(() => {
              this.setState({
                isLoading: false
              })
            }, 3000);
          }

        }
      }

    } else {
      if (currentPassword == "") {
        this.setState({ currentPasswordState: "has-danger" });
      }
      if (newPassword == "") {
        this.setState({ newPasswordState: "has-danger" });
      }
      if (confirmPassword == "") {
        this.setState({ confirmPasswordState: "has-danger" });
      }
    }
  }

  handleResetPassword = async () => {
    let { newPassword, confirmPassword } = this.state;
    if (newPassword == "") {
      this.setState({ newPasswordState: "has-danger" });
    }
    else {
      this.setState({ newPasswordState: "has-success" });
    }
    if (confirmPassword == "") {
      this.setState({ confirmPasswordState: "has-danger" });
    }
    else {
      this.setState({ confirmPasswordState: "has-success" });
    }
    if (!newPassword == "" && !confirmPassword == "") {
      if (this.state.isLoading === false) {
        this.setState({ isLoading: true });
        if (newPassword != confirmPassword) {
          let message = {
            message: NEW_PASSWORD_ERROR,
            type: "warning",
          }
          this.props.handleMessage(message);
          setTimeout(() => {
            this.setState({
              isLoading: false
            })
          }, 3000);
        } else {
          let result = await this.props.handleResetPassword(newPassword);
          if (result === true) {
            setTimeout(() => {
              this.setState({
                isLoading: false
              })
            }, 3000);
          }
        }

      }
    }
  }
  keyPressed = (event) => {
    if (event.key === "Enter" && this.props.formType != 'ResetPassword') {
      this.handleChangePassword();
    }
    else if (event.key === "Enter" && this.props.formType === 'ResetPassword') {
      this.handleResetPassword();
    }
  }

  validatePasswordStrength = () => {
    const result = zxcvbn(this.state.newPassword);
    const score = this.state.newPassword.length == 0 ? -1 : result.score;

    // var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");
    //
    // let regexResult = mediumRegex.test(this.state.newPassword);

    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    let regexResult = strongRegex.test(this.state.newPassword);
    this.setState({
      passwordStrength: regexResult === true ? score : score === -1 ? -1 : 1,
      passwordStrengthText: regexResult === true ? passwordStrengthTexts[score] : score === -1 ? '' : passwordStrengthTexts[1],
    });

    // this.setState({
    //   passwordStrength: regexResult === true ? score : 1,
    //   passwordStrengthText: regexResult === true ? passwordStrengthTexts[score] : passwordStrengthTexts[1],
    // });
  };


  render() {
    return (
      <div>
        <Form className="form">
          <Card className="card-login card-white" style={{ marginBottom: '0px' }}>
            {this.props.formType != 'ResetPassword' ? <i class="tim-icons icon-simple-remove" onClick={() => { this.props.handeCloseModal() }} style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: '5px', marginTop: '5px' }}></i>
              : <React.Fragment />
            }
            {this.props.formType != 'ResetPassword' ?
              <CardHeader style={{ padding: '0px 15px 0' }}>
                <img
                  alt="..."
                  src={require("assets/img/card-primary.png")}
                  style={{ maxWidth: '50%' }}
                />
                <CardTitle tag="h1">Change Password</CardTitle>
              </CardHeader> :
              <CardHeader >
                <img
                  alt="..."
                  src={require("assets/img/card-primary.png")}
                  style={{ maxWidth: '50%' }}
                />
                <CardTitle tag="h1">Reset Password</CardTitle>
              </CardHeader>
            }
            <CardBody>
              {this.props.formType != 'ResetPassword' ?
                <InputGroup
                  className={classnames(this.state.currentPasswordState, {
                    "input-group-focus": this.state.currentPasswordFocus
                  })}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="tim-icons icon-lock-circle" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Current Password"
                    type="password"
                    name="currentPassword"
                    value={this.state.currentPassword}
                    onChange={e => this.change(e, "currentPassword", "length")}
                    onFocus={e => this.setState({ currentPasswordFocus: true })}
                    onBlur={e => this.setState({ currentPasswordFocus: false })}
                    onKeyPress={this.keyPressed}
                  />
                </InputGroup>
                : <React.Fragment />
              }
              {this.state.currentPasswordState === "has-danger" ? (
                <label className="error">{REQUIRD_MESSAGE}</label>
              ) : null}

              <InputGroup
                className={classnames(this.state.newPasswordState, {
                  "input-group-focus": this.state.newPasswordFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-lock-circle" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="New Password"
                  type="password"
                  name="newPassword"
                  value={this.state.newPassword}
                  onChange={e => this.change(e, "newPassword", "length")}
                  onFocus={e => this.setState({ usernameFocus: true })}
                  onBlur={e => this.setState({ usernameFocus: false })}
                  onKeyPress={this.keyPressed}
                />
              </InputGroup>
              {this.state.newPasswordState === "has-danger" ? (
                <label className="error">{REQUIRD_MESSAGE}</label>
              ) : null}
              <PasswordStrengthIndicator
                level={this.state.passwordStrength}
                settings={passwordStrengthSettings}
              />
              {
                this.state.passwordStrength > -1 ?
                  <div className="ant-form-explain" style={{ fontSize: "12px", textAlign: "end", color: passwordStrengthSettings.colorScheme.levels[this.state.passwordStrength] }}>{this.state.passwordStrengthText}</div> : <React.Fragment />
              }
              <InputGroup
                className={classnames(this.state.confirmPasswordState, {
                  "input-group-focus": this.state.confirmPasswordFocus
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="tim-icons icon-lock-circle" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  type="password"
                  value={this.state.confirmPassword}
                  onChange={e => this.change(e, "confirmPassword", "length")}
                  onFocus={e => this.setState({ confirmPasswordFocus: true })}
                  onBlur={e => this.setState({ confirmPasswordFocus: false })}
                  onKeyPress={this.keyPressed}
                />
              </InputGroup>
              {this.state.confirmPasswordState === "has-danger" ? (
                <label className="error">{REQUIRD_MESSAGE}</label>
              ) : null}

              <div style={{}}>
                <span style={{ fontSize: "10px", fontStyle: "italic" }}>Password Policy: {PASSWORD_REQUIREMENT_TEXT}</span>
              </div>
            </CardBody>
            <CardFooter>
              <Button
                block
                className="mb-3"
                color="primary"
                type="button"
                onClick={this.props.formType != 'ResetPassword' ? this.handleChangePassword : this.handleResetPassword}
                size="lg"
              >{this.state.isLoading ?
                <Spinner as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true" />

                : this.props.formType != 'ResetPassword' ? 'Change Password' : 'Reset Password'
                }
              </Button>
              {this.props.formType === "Change Password" && <Button
                block
                className="mb-3"
                color="primary"
                type="button"
                onClick={() => { this.props.signoutUser() }}
                size="lg"
              >
                Logout
              </Button>}
              {this.props.formType != 'ResetPassword' ?
                <React.Fragment />
                :
                <div className="pull-right">
                  <h6>
                    <a
                      className="link footer-link"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        this.props.handleSignIn();
                      }}
                    >
                      Sign In
                    </a>
                  </h6>
                </div>}
            </CardFooter>
          </Card>
        </Form>
      </div>
    );
  }
}
export const mapStateToProps = ({ auth }) => {
  return {
    user: auth.user
  }
};

export default connect(mapStateToProps)(ChangePassword);