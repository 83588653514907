import { getWeeklyTimesheetAPI, deleteTimesheetTaskAPI } from '../../services/timesheets-service';

export const FETCH_WEEKLY_TIMESHEET = 'FETCH_WEEKLY_TIMESHEET';
export const DELETE_TASK_TIMESHEET = 'DELETE_TASK_TIMESHEET';
export const ADD_UPDATE_WEEKLY_TIMESHEET = 'ADD_UPDATE_WEEKLY_TIMESHEET';

export const getWeeklyTimesheet = (cid, date, eid) => {
    return async (dispatch) => {
        const response = await getWeeklyTimesheetAPI(cid, date, eid);
        if (response.data) {
            dispatch({
                type: FETCH_WEEKLY_TIMESHEET,
                timesheet: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }

    }
}

export const addUpdateWeeklyTimesheet = (timesheet) => {
    return (dispatch) => {
        dispatch({
            type: ADD_UPDATE_WEEKLY_TIMESHEET,
            timesheet: timesheet
        });
    }
}

export const deleteTimesheetTask = (timesheetItem, timesheetWeeklyId) => {
    return async (dispatch, getState) => {
        const { timesheet, auth } = getState();
        var cid = auth.user.cid;

        const response = await deleteTimesheetTaskAPI(timesheetItem, timesheetWeeklyId, cid);
        if (response.data) {
            dispatch({
                type: DELETE_TASK_TIMESHEET,
                payload: {
                    pid: timesheetItem.pid,
                    tdid: timesheetItem.id,
                    twid: timesheetWeeklyId
                }
            });
            return { success: true };
        }
        else {
            return { success: false };
        }

    }
}