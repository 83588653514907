import { fetchClientsAPI } from "../../services/clients-service";

export const GET_CLIENTS = 'GET_CLIENTS';

export const fetchClients = (companyId) => {
  return async (dispatch) => {
    const response = await fetchClientsAPI(companyId);
    if (response.data) {
      let result = response.data;
      dispatch({
        type: GET_CLIENTS,
        clients: result
      });
      return { success: true, result };
    }
    else {
      return { success: false };
    }
  }
}

