import { getAllEmployeesWithRoleAPI } from "../../services/employees-service";

export const FETCH_ALL_EMPLOYEES_WITH_ROLES = 'FETCH_ALL_EMPLOYEES_WITH_ROLES';

export const getAllEmployeesWithRole = (cid) => {
    return async (dispatch) => {
        const response = await getAllEmployeesWithRoleAPI(cid);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_EMPLOYEES_WITH_ROLES,
                employees: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}