import React, { Component } from "react";
import Switch from "react-switch";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { REQUIRD_MESSAGE } from "../../../variables/constants";
import { getAllCurrencies } from "../../../redux/actions/currenciesAction";

class CompanyLocationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'Add',
            companyLocation: {
                id: 0,
                cid: 1,
                branchName: '',
                branchLocation: '',
                address: '',
                city: '',
                state: '',
                countryId: 0,
                isActive: "Y",
                isDelete: "N",
                isMainBranch: "N"
            },
            cid: 0,
            branchNameState: '',
            branchLocationState: '',
            addressState: '',
            cityState: '',
            stateState: '',
            countryIdState: '',
        };
    }



    UNSAFE_componentWillMount() {
        if (this.props.formType === "Update") {
            this.setState({
                formType: this.props.formType,
                companyLocation: { ...this.props.companyLocation, cid: this.props.cid },
                cid: this.props.cid,
                branchNameState: 'has-success',
                branchLocationState: 'has-success',
                addressState: 'has-success',
                cityState: 'has-success',
                stateState: 'has-success',
                countryIdState: 'has-success',
            });
        } else {
            this.setState({
                formType: this.props.formType,
                companyLocation: { ...this.state.companyLocation, cid: this.props.cid }
            });
        }
    }

    verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    handleSubmit = async () => {
        let { branchNameState, branchLocationState, addressState, cityState, stateState } = this.state;
      
        // if (branchName === ""&& branchNameState === "has-danger") {
        //     this.setState({ branchNameState: "has-danger" });
        // }
        // if(branchLocation === "" && branchLocationState === "has-danger"){
        //     this.setState({ branchLocationState: "has-danger" });
        // }
        // if(address  === "" && addressState === "has-danger"){
        //     this.setState({ addressState: "has-danger" });
        // }
        // if(city === "" && cityState === "has-danger"){
        //     this.setState({ cityState: "has-danger" });
        // }
        // if(state === "" && stateState === "has-danger"){
        //     this.setState({ stateState: "has-danger" });
        // }
        // if(countryId === ""){
        //     this.setState({ countryStateId: "has-danger" });
        // }
        if (branchNameState === "has-success" && branchLocationState === "has-success" && addressState === "has-success"
            && cityState === "has-success" && stateState === "has-success") {
            this.props.onSubmit(this.state.companyLocation);
        } else {
            this.setState({
                branchNameState: "has-danger",
                branchLocationState: "has-danger",
                addressState: "has-danger",
                cityState: "has-danger",
                stateState: "has-danger"
            })
        }
    }

    handleSelectStatus = (e) => {
        if (e.target.value === '') {
            this.setState({ [e.target.name + "State"]: "has-danger" });
        } else {
            this.setState({ [e.target.name + "State"]: "has-success" });
        }

        let companyLocation = this.state.companyLocation;
        if (e.target.name === 'country') {
            companyLocation[e.target.name] = parseInt(e.target.value);
        } else {
            companyLocation[e.target.name] = e.target.value;
        }
        this.setState({
            companyLocation: { ...companyLocation }
        })
    }

    handleIsActive = (e) => {
        if (e === true) {
            this.setState({
                companyLocation: {
                    ...this.state.companyLocation,
                    isActive: 'Y'

                }
            });
        }
        else {
            this.setState({
                companyLocation: {
                    ...this.state.companyLocation,
                    isActive: 'N'

                }
            });
        }
    }

    handleIsPrimary = (e) => {

        if (e === true) {
            this.setState({
                companyLocation: {
                    ...this.state.companyLocation,
                    isMainBranch: 'Y'
                }
            });
        }
        else {
            this.setState({
                companyLocation: {
                    ...this.state.companyLocation,
                    isMainBranch: 'N'
                }
            });
        }
    }

    onChangeTextHandler = (e, stateName) => {

        var lengthRex = /^[a-zA-Z\s,'-]*$/;
        let companyLocation = this.state.companyLocation;
        companyLocation[e.target.name] = e.target.value;
        if (stateName === "branchName" || stateName === "branchLocation" || stateName === "city" || stateName === "state") {
            if (lengthRex.test(e.target.value)) {
                if (e.target.value.startsWith(' ') || e.target.value === '') {
                    this.setState({
                        [e.target.name + "State"]: "has-danger"
                    })
                }
                else (
                    this.setState({
                        [e.target.name + "State"]: e.target.value === "" ? "has-danger" : "has-success",
                        companyLocation: companyLocation
                    })
                )
            } else (
                this.setState({
                    [e.target.name + "State"]: "has-danger"
                })
            )
        } else if (stateName === 'address') {
            this.setState({
                [e.target.name + "State"]: e.target.value === "" ? "has-danger" : "has-success",
                companyLocation: companyLocation
            })
        }
        else {
            this.setState({
                companyLocation: companyLocation
            })
        }

        // let companyLocation = this.state.companyLocation;

        // companyLocation[e.target.name] = e.target.value;
        // if(e.target.value === '') {
        //     this.setState({ [e.target.name + "State"]: "has-danger" });
        // }else{
        //   this.setState({ [e.target.name + "State"]: "has-success" });
        // }
        // this.setState({
        //     companyLocation: companyLocation
        // });

    }

    render() {
        let { companyNameState, branchNameState, branchLocationState, addressState, cityState, stateState, currencyIdState, countryState } = this.state;

        let countries = []
        { this.props.countries ? countries = this.props.countries : countries = [] }
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="custom-modal " style={{
                    width: "100%",
                    maxWidth: 700
                }} >
                    <ModalHeader toggle={this.props.toggle}><h4>
                        {this.props.formType === "Update" ? "Update Company Location" : "Add Company Location"}
                    </h4></ModalHeader>
                    <ModalBody>
                        <div className="content raise-ticket">
                            <Col className="mr-auto ml-auto" md="12">
                                <Form className="custom-form">
                                    <Row className="justify-content-center">     {/* className="justify-content-center"*/}
                                        <Col sm="5">
                                            <FormGroup className={`has-label ${companyNameState}`}>
                                                <Label>Branch Name*</Label>
                                                <Input
                                                    name="branchName"
                                                    type="text"
                                                    placeholder="Branch name"
                                                    value={this.state.companyLocation.branchName}
                                                    onChange={e => { this.onChangeTextHandler(e, "branchName") }}
                                                />
                                                {branchNameState === "has-danger" ? (
                                                    <label className="error">{REQUIRD_MESSAGE}</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="5">
                                            <FormGroup className={`has-label ${companyNameState}`}>
                                                <Label>Location Name*</Label>
                                                <Input
                                                    name="branchLocation"
                                                    type="text"
                                                    placeholder="Location"
                                                    value={this.state.companyLocation.branchLocation}
                                                    onChange={e => { this.onChangeTextHandler(e, "branchLocation") }}
                                                />
                                                {branchLocationState === "has-danger" ? (
                                                    <label className="error">{REQUIRD_MESSAGE}</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="10">
                                            <FormGroup className={`has-label ${companyNameState}`}>
                                                <Label>Address*</Label>
                                                <Input
                                                    name="address"
                                                    type="text"
                                                    placeholder="address"
                                                    value={this.state.companyLocation.address}
                                                    onChange={e => { this.onChangeTextHandler(e, "address") }}
                                                />
                                                {addressState === "has-danger" ? (
                                                    <label className="error">{REQUIRD_MESSAGE}</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="5">
                                            <FormGroup className={`has-label ${companyNameState}`}>
                                                <Label>City Name*</Label>
                                                <Input
                                                    name="city"
                                                    type="text"
                                                    placeholder="city name"
                                                    value={this.state.companyLocation.city}
                                                    onChange={e => { this.onChangeTextHandler(e, "city") }}
                                                />
                                                {cityState === "has-danger" ? (
                                                    <label className="error">{REQUIRD_MESSAGE}</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="5">
                                            <FormGroup className={`has-label ${companyNameState}`}>
                                                <Label>State Name*</Label>
                                                <Input
                                                    name="state"
                                                    type="text"
                                                    placeholder="state name"
                                                    value={this.state.companyLocation.state}
                                                    onChange={e => { this.onChangeTextHandler(e, "state") }}
                                                />
                                                {stateState === "has-danger" ? (
                                                    <label className="error">{REQUIRD_MESSAGE}</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="5">
                                            <FormGroup className={`has-label ${currencyIdState} `}>
                                                <Label>Select Country*</Label>
                                                <Input type="select" name="countryId" defaultValue={this.state.companyLocation.countryId} onChange={this.handleSelectStatus} id="country">
                                                    <option value={-1} disabled>Select Country</option>
                                                    {countries.map((country) => {
                                                        return (<option value={country.id}>{country.country}</option>)
                                                    })}
                                                </Input>
                                                {countryState === "has-danger" ? (
                                                    <label className="error">{REQUIRD_MESSAGE}</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="5">

                                            <FormGroup >
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '15px' }}>
                                                    <div>
                                                        <Label >Active</Label>
                                                    </div>
                                                    <div style={{ marginRight: '100px', marginTop: '10px' }}>
                                                        <Switch
                                                            onChange={(e) => { this.handleIsActive(e) }}
                                                            name="isActive"
                                                            id="isActive"
                                                            checked={this.state.companyLocation.isActive === 'Y' ? true : false}
                                                            handleDiameter={30}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            height={20}
                                                            width={48} />
                                                    </div>
                                                </div>
                                            </FormGroup>

                                        </Col>
                                        <Col sm="5">

                                            <FormGroup >
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '' }}>
                                                    <div>
                                                        <Label >isMainBranch</Label>
                                                    </div>
                                                    <div style={{ marginRight: '50px', marginTop: '10px' }}>
                                                        <Switch
                                                            onChange={(e) => { this.handleIsPrimary(e) }}
                                                            name="isMainBranch"
                                                            id="isMainBranch"
                                                            checked={this.state.companyLocation.isMainBranch === 'Y' ? true : false}
                                                            handleDiameter={30}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            height={20}
                                                            width={48} />
                                                    </div>
                                                </div>
                                            </FormGroup>

                                        </Col>
                                        <Col sm="5">
                                            <React.Fragment />
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button size="sm" color="info" onClick={this.handleSubmit}>{this.props.formType}</Button>{' '}
                            {/* {this.props.formType === 'Update' ? <Button
                            size="sm"
                            color="danger"
                            onClick={() =>{this.props.deleteCompany(this.state.company.id)} }
                        >
                            Delete
                        </Button>: <React.Fragment />} */}
                            <Button size="sm" color="secondary" onClick={this.props.close}>Cancel</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

}

export const mapDispatchToProps = dispatch => ({
    getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch)
});


export const mapStateToProps = ({ currencies }) => ({
    currencies: currencies
});
export default connect(mapStateToProps, mapDispatchToProps)(CompanyLocationModal);