import { GET_EMPLOYEE_BASIC_INFO } from "../actions/employeeBasicInfoAction";
const employeeBasicInfoReducer = function (state = null, action) {
    switch (action.type) {
        case GET_EMPLOYEE_BASIC_INFO:
            return action.employee;
        default:
            return state;
    }
};

export default employeeBasicInfoReducer;