import axios from 'axios';

export const getAllDesignationsAPI = (cid) => {
    return axios.get(`/api/v1/designations`, {
      params: { 
          cid: cid 
        }
    }).then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });  
}

export const addUpdateDesignationAPI = (designation) => {
    return axios.post(`/api/v1/add-update-designations`, designation)
      .then(function (response) {
        if (response.data) {
          return { success: true, data: response.data };
        }
      })
      .catch(function (error) {
        return { success: false };
      });
}