import moment from "moment";
import classNames from "classnames";
import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import ReactTable from "react-table";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row, UncontrolledTooltip } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { getNewLeaves } from "redux/actions/manageLeaveActions";
import { getNewLeavesAPI } from 'services/leaves-service';
import { acceptrejectLeaveApplication } from "../../../services/leaves-service";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import Pagination from "../../../components/common/pagination";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
import { AppContext } from '../../../hoc/AppContext';
import { dateFormatList } from "variables/constants";
class ManageLeave extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            isDataLoading: false
        };
    }


    //notificationAlert = React.createRef();

    notify = (place, color, message) => {
        // var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };


    showNotifications = (color, message) => {
        this.notify("tr", color, message);
    }

    warningWithConfirmMessage = (obj, value, status) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure of approving application?"
                    onConfirm={() => this.successDelete(obj, value, status)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, do it!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize="sm"
                >

                </ReactBSAlert>
            )
        });
    };

    successAlert = (status) => {
        let options = {};
        options = {
            place: "tr",
            message: 'Leave has been ' + status + ' successfully.',
            type: "success",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 2
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    successDelete = (obj, value, status) => {
        let leaveDetails = {
            eid: obj.eid,
            cid: this.props.user.cid,
            noOfDays: obj.noOfDays,
            id: obj.leaveId,
            rejectionReason: "",
            leaveStatus: status
        }

        this.manageLeave(leaveDetails);
        this.hideAlert();
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    inputAlert = (obj, value, status) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    input
                    showCancel
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure of rejecting application?"
                    onConfirm={e => this.inputConfirmAlert(e, obj, value, status)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    btnSize="sm"
                >
                    <label>Please enter rejection reason</label>
                </ReactBSAlert>
            )
        });
    };

    inputConfirmAlert = (e, obj, value, status) => {
        let leaveDetails = {
            eid: obj.eid,
            cid: this.props.user.cid,
            noOfDays: obj.noOfDays,
            id: obj.leaveId,
            rejectionReason: e,
            leaveStatus: status
        }

        this.manageLeave(leaveDetails);
        this.hideAlert();
    };

    getLeaveDetails = async () => {
        /*   this.setState({
              isDataLoading: true
          }) */
        // const response =
        await this.props.getNewLeaves(this.props.cid, this.props.user.eid);
        /*   if (response) {
              this.setState({
                  isDataLoading: false
              })
          } */
        let data = this.props.newLeaves.map((prop, key) => {
            return {
                id: key,
                leaveId: prop.id,
                cid: prop.cid,
                //clientId: prop[0],
                eid: prop.eid,
                startDate: moment(prop.startDate).format(dateFormatList[2]),
                endDate: moment(prop.endDate).format(dateFormatList[2]),
                noOfDays: prop.noOfDays,
                leaveType: prop.leaveType,
                duration: prop.duration,
                leaveReason: prop.leaveReason,
                rejectionReason: "",
                empName: prop && prop.empName? prop.empName :'Employee 0',
                approve: (
                    // we've added some custom button actions
                    <div >
                        <Button
                            id="tooltip209815"
                            onClick={() => {
                                let obj = this.state.data.find(o => o.id === key);
                                this.warningWithConfirmMessage(obj, true, "Approved")
                            }}
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-check-2" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209815"
                            placement="left"
                        >
                            Approve
                        </UncontrolledTooltip>

                    </div>
                ),
                reject: (
                    // we've added some custom button actions
                    <div >
                        <Button
                            id="tooltip209816"
                            onClick={() => {
                                let obj = this.state.data.find(o => o.id === key);
                                this.inputAlert(obj, false, "Rejected")
                            }}
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-simple-remove" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209816"
                            placement="left"
                        >
                            Reject
                        </UncontrolledTooltip>
                    </div>
                )
            };
        })

        this.setState({
            data: data
        })
    }

    manageLeave = async (leaveDetails) => {

        let result = await acceptrejectLeaveApplication(leaveDetails);
        if (result.success) {
            //this.showNotifications(2, "Leave has been " + leaveDetails.status + " successfully.");
            this.successAlert(leaveDetails.leaveStatus);
            let response = await getNewLeavesAPI(this.props.cid, this.props.user.eid);
            if (response.data.length > 0) {

                this.getLeaveDetails();
            }
            else {

                this.setState({
                    data: []
                })
            }

        } else {
            this.showNotifications(3, "Failed to " + leaveDetails.status + " leave application!");
        }
    }

    UNSAFE_componentWillMount() {
        this.getLeaveDetails();
    }

    render() {
        const { config } = this.context;
        return (
            <>
                <NotificationAlert ref="notificationAlert" />
                <div className="content">
                    {this.state.alert}
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CardTitle tag="h2">Manage Leave Applications </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={this.state.data}
                                        //filterable
                                        // nextText={myCustomNextText}
                                        resizable={false}
                                        columns={[

                                            {
                                                Header: "Emp. No.",
                                                accessor: "eid",
                                                width: 80
                                            },
                                            {
                                                Header: "Emp. Name",
                                                accessor: "empName",
                                                width: 200
                                            },
                                            {
                                                Header: "Start Date",
                                                accessor: "startDate",
                                                width: 110
                                            },
                                            {
                                                Header: "End Date ",
                                                accessor: "endDate",
                                                width: 110
                                            },
                                            {
                                                Header: "Reason",
                                                accessor: "leaveReason",
                                                width: 340,
                                                resizable: true
                                            },
                                            {
                                                Header: "Days",
                                                accessor: "noOfDays",
                                                width: 90
                                            },
                                            {
                                                Header: "Approve",
                                                accessor: "approve",
                                                sortable: false,
                                                filterable: false,
                                                width: 70,
                                                className: "align-center-accept"
                                            },
                                            {
                                                Header: "Reject",
                                                accessor: "reject",
                                                sortable: false,
                                                filterable: false,
                                                width: 70,
                                                className: "align-center-reject"
                                            },

                                        ]}
                                        LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                                        NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                                        // pageSize={this.props.newLeaves.length <= 10 ? this.props.newLeaves.length : 10}
                                        pageSize={this.props.newLeaves.length <= config.paginationSize ? this.props.newLeaves.length : config.paginationSize}
                                        className="-striped -highlight project-table leave-table expense-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    newLeaves: state.newLeaves,
    cid: state.auth.user.cid,
    user: state.auth.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        getNewLeaves: bindActionCreators(getNewLeaves, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageLeave);
