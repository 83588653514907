import { FETCH_ALL_MODULES, ADD_UPDATE_MODULE } from '../actions/moduleAction';

const moduleReducer = function (state = [], action) {
    let newState;
    let index;
    let id;
    switch (action.type) {
        case FETCH_ALL_MODULES:
            return action.modules;
        case ADD_UPDATE_MODULE:
            newState = [...state ];
            index = newState.findIndex(module =>module.id === action.payload.id);
            if (index > -1) {
                newState.splice(index, 1, action.payload.module);
            }
            else{
                newState = [...state];
                newState.push(action.payload.module);
                return newState;
            }
        return newState;
        default:
            return state;
    }
};

export default moduleReducer;
