import {getAllCompaniesAPI, addUpdateModuleAPI} from "../../services/modules-service";

export const FETCH_ALL_MODULES = 'FETCH_ALL_MODULES';
export const ADD_UPDATE_MODULE = 'ADD_UPDATE_MODULE';

export const getAllModules = (cid) =>  {
    return async (dispatch) => {
        const response = await getAllCompaniesAPI(cid);
            if (response.data){
                dispatch({
                    type: FETCH_ALL_MODULES,
                    modules: response.data
                  });
                return { success: true };
            }
            else{
                return { success: false};
            }    
        }
}
export const addUpdateModule = (module) =>  {
  return async (dispatch) => {
      const response = await addUpdateModuleAPI(module);
          if (response.data){
              dispatch({
                  type: ADD_UPDATE_MODULE,
                  payload: {
                    id:module.id,
                    module: response.data
                }
                });
              return { success: true, data:response.data };
          }
          else{
              return { success: false};
          }    
      }
}