import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AuthLayout from "layouts/Auth/Auth.js";
import AdminLayout from "layouts/Admin/Admin.js";

// product scss
import "assets/css/nucleo-icons.css";
import "assets/css/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.1.0";
import "assets/demo/demo.css";

import { authRefresh, resetReduxStore } from "./redux/actions/authActions";

import { AppProvider } from './hoc/AppContext';
import LoadingIndicator from "components/common/LoadingIndicator";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.channel = new BroadcastChannel("ESS");
  }

  componentWillMount() {
    const USESSIONID = sessionStorage.getItem("USESSIONID");
    if (USESSIONID !== null && USESSIONID !== "") {
      this.props.authRefresh();
    } else {
      this.props.resetReduxStore();
    }
  }
  componentDidMount() {
    this.channel.addEventListener("message", this.handleMessageEvent);
  }

  handleMessageEvent = (e) => {
    const { action, payload } = e.data;
    const activeSessionId = sessionStorage.getItem("USESSIONID");
    if (action === "NEW_SESSION_CREATED" && activeSessionId !== payload.sessionId) {
      sessionStorage.removeItem('USESSIONID');
      this.props.resetReduxStore();
    }
  }

  componentWillUnmount() {
    this.channel.removeEventListener('message', this.handleMessageEvent);
  }

  render() {
    const hist = createBrowserHistory();
    const { loading } = this.props;
    return (
      <AppProvider>
        {
          loading ?
            <LoadingIndicator /> :
            <Router history={hist}>
              <Switch>
                <Route path="/auth" render={props => <AuthLayout {...props} />} />
                <Route path="/admin" render={props => <AdminLayout {...props} />} />
                <Route path="/employee" render={props => <AdminLayout {...props} />} />
                <Route path="/manager" render={props => <AdminLayout {...props} />} />
                <Route path="/humanresource" render={props => <AdminLayout {...props} />} />
                <Redirect from="/" to="/auth/login" />
              </Switch>
            </Router>
        }
      </AppProvider>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  resetReduxStore: bindActionCreators(resetReduxStore, dispatch),
  authRefresh: bindActionCreators(authRefresh, dispatch),
});

export const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
