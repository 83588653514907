import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from 'reactstrap';
import { REQUIRD_MESSAGE } from "../../../variables/constants";
import { connect } from "react-redux";
import Switch from "react-switch";
import { containsOnlyCharacters, verifyNumber } from "../../../components/common/validation";

class ModuleModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'Add',
            isLoading: false,
            isActive: true,
            module: {
                id: 0,
                cid: 1,
                parentId: 0,
                module: "",
                isActive: 'Y'
            },
            moduleState: '',
            parentIdState: '',
            moduleAlreadyExistState: ''
        };
    }
    UNSAFE_componentWillMount() {
        this.setState({
            module: Object.keys(this.props.module).length !== 0 ? { ...this.props.module } : this.state.module
        });
    }


    handleSubmit = async () => {
        let newModule = {
            ...this.state.module,
            parentId: parseInt(this.state.module.parentId)
        }
        var moduleCount = 0;
        var isModuleCountAlreadyExist = false;


        this.props.modules && this.props.modules.map((module) => {

            if (module.module.toUpperCase() === newModule.module.trim().toUpperCase() && module.id !== newModule.id) {
                moduleCount = moduleCount + 1;
            }
        });
        isModuleCountAlreadyExist = moduleCount >= 1 ? true : false;
        let { module, parentId } = newModule;
        if (module !== "" && parentId !== null && this.state.parentIdState !== "has-danger" && this.state.moduleState !== "has-danger" && isModuleCountAlreadyExist === false) {
            if (this.state.isLoading === false) {
                this.setState({
                    isLoading: true
                })
                if (this.props.onSubmit(newModule, this.props.formType)) {
                    setTimeout(() => {
                        this.setState({
                            isLoading: false
                        })
                    }, 3000);
                } else {
                    setTimeout(() => {
                        this.setState({
                            isLoading: false
                        })
                    }, 3000);
                }
            }
        } else {
            if (module === "") {
                this.setState({ moduleState: "has-danger" });
            }
            if (parentId <= -1) {
                this.setState({ parentIdState: "has-danger" });
            }
            if (isModuleCountAlreadyExist === true) {
                this.setState({ moduleAlreadyExistState: "has-danger" })
            }
        }

    }

    onChangeTextHandler = (event, stateName) => {
        if (stateName == "module") {
            if (containsOnlyCharacters(event.target.value)) {
                let module = { ...this.state.module };
                module[event.target.name] = event.target.value.trim();
                this.setState({
                    module: module,
                    [stateName + "State"]: "has-success"
                });
            } else {
                let module = { ...this.state.module };
                module[event.target.name] = event.target.value
                this.setState({
                    module: module,
                    [stateName + "State"]: "has-danger"
                });
            }
        } else {
            if (verifyNumber(event.target.value)) {
                let module = { ...this.state.module };
                module[event.target.name] = event.target.value.trim();
                this.setState({
                    module: module,
                    [stateName + "State"]: "has-success"
                });
            } else {
                let module = { ...this.state.module };
                module[event.target.name] = event.target.value
                this.setState({
                    module: module,
                    [stateName + "State"]: "has-danger"
                });
            }
        }
    }

    handleIsActive = (e) => {
        if (e === true) {
            this.setState({
                module: {
                    ...this.state.module,
                    isActive: 'Y'

                }
            });
        }
        else {
            this.setState({
                module: {
                    ...this.state.module,
                    isActive: 'N'

                }
            });
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="custom-modal "  >
                    <ModalHeader toggle={this.props.toggle}><h4>
                        {this.props.formType === "Update" ? "Update Module" : "Add New Module"}
                    </h4></ModalHeader>
                    <ModalBody>
                        <Form className="custom-form" id="LoginValidation">
                            <Row>

                                <Col>
                                    <Label>Parent Id *</Label>
                                    <FormGroup className={`has-label ${this.state.parentIdState}`}>
                                        <Input
                                            name="parentId"
                                            type="number"
                                            placeholder="Parent Id"
                                            value={this.state.module.parentId}
                                            onChange={e => this.onChangeTextHandler(e, "parentId")}
                                        />
                                        {this.state.parentIdState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    <Label >Module Name *</Label>
                                    <FormGroup className={`has-label ${this.state.moduleState}${this.state.moduleAlreadyExistState}`}>
                                        <Input
                                            name="module"
                                            type="text"
                                            placeholder="Module name"
                                            value={this.state.module.module}
                                            onChange={e => this.onChangeTextHandler(e, "module")}
                                        />
                                        {this.state.moduleState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                        {this.state.moduleAlreadyExistState === "has-danger" ? (
                                            <label className="error">{"Module is already exist"}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Label md="3">isActive *</Label>
                                <Col md="9" style={{ marginTop: '10px', paddingLeft: '0px' }}>
                                    <FormGroup>
                                        <label id="tooltip209853">

                                            <Switch
                                                onChange={(e) => { this.handleIsActive(e) }}
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.module.isActive === 'Y' ? true : false}
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48} />
                                        </label>
                                    </FormGroup>
                                    <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip209853"
                                        placement="right"
                                    >
                                        {this.state.module.isActive === 'Y' ? "Active" : "Inactive"}
                                    </UncontrolledTooltip>
                                </Col>
                            </Row>

                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" size="sm" onClick={this.handleSubmit}>{this.state.isLoading ?
                            <Spinner as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true" />

                            : this.props.formType
                        }</Button>{' '}
                        <Button color="secondary" size="sm" onClick={this.props.close}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

}

export const mapDispatchToProps = dispatch => ({

});

export const mapStateToProps = ({ modules }, ownProps) => {
    let module = modules.find(o => o.id === ownProps.id);
    return {
        module: module ? module : ownProps.newModule
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleModal);