import { getAllDepartmentsAPI } from '../../services/department-service';

export const FETCH_ALL_DEPARTMENTS = 'FETCH_ALL_DEPARTMENTS';

export const getAllDepartments = (cid) => {
    return async (dispatch) => {
        const response = await getAllDepartmentsAPI(cid);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_DEPARTMENTS,
                departments: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}
