import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import ReactTable from "react-table";
import classNames from "classnames";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row, FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from 'moment';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EmployeeDetailsModal from "./EmployeeTimesheetModal";
import Pagination from "../../components/common/pagination";
import { getAllTimesheetDailyForTids, approveRejectWeeklyTimesheetAPI } from "../../services/timesheets-service";
import { getAllApprovalPendingTimesheets, approveRejectWeeklyTimesheet } from "../../redux/actions/timesheetApprovalPendingAction";
import { getAllProjects } from '../../redux/actions/projectActions';
import LoadingIndicator from "../../components/common/LoadingIndicator";
import NoData from "../../components/common/NoData";
import { AppContext } from '../../hoc/AppContext';
import ReactDatetime from "react-datetime";
import { getAllEmployeesByManager } from "redux/actions/managerEmployeesAction";
import { getAllEmployees } from "redux/actions/employeeAction";
import Select from 'react-select';
import { dateFormatList } from "variables/constants";

const status = [
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
    { value: "Saved", label: "Saved" },
    { value: "Submitted", label: "Submitted" },
    { value: "Resubmitted", label: "Resubmitted" }];

class ApproveRejectTimesheet extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf('isoweek').format('YYYY-MM-DD'),
            endDate: moment().endOf('isoweek').format('YYYY-MM-DD'),
            modal: false,
            status: [],
            timesheetReport: [],
            alert: null,
            tids: [],
            isDataLoading: false,
            timesheetApprovalPending: [],
            isSubmitting: false,
            weeklySelectedDate: moment().startOf('week').format('YYYY-MM-DD'),
            selectedEmployee: [],
            selectedProject: []
        };
    }

    async UNSAFE_componentWillMount() {
        this.setState({
            isDataLoading: true
        })
        const { weeklySelectedDate } = this.state;

        let data = {
            "cid": this.props.cid,
            "employeeList": [],
            "managerId": this.props.eid,
            "projectList": [],
            "statusList": [],
            "weekStartDate": moment(weeklySelectedDate).startOf('week').format('YYYY-MM-DD')
        }
        const response = await this.props.getAllApprovalPendingTimesheets(data);

        if (response) {
            this.setState({
                isDataLoading: false,
                timesheetApprovalPending: this.props.timesheetApprovalPending
            })
        }
        this.props.getAllProjects(this.props.cid);
        if (this.props.role && this.props.role === "Manager") {
            await this.props.getAllEmployeesByManager(this.props.eid);
        } else if (this.props.role && this.props.role === 'Company Administrator') {
            await this.props.getAllEmployees(this.props.cid);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.timesheetApprovalPending !== nextProps.timesheetApprovalPending) {
            this.setState({
                timesheetApprovalPending: nextProps.timesheetApprovalPending
            })
        }
    }

    handleApprove = async (timesheet, index) => {
        let data = {
            timesheet,
            status: 'Approved',
            rejectionNote: '',
            eid: this.props.eid,
            cid: this.props.cid
        }
        let timesheetData = [data];
        if (this.state.timesheetApprovalPending.length === 1 && index === 0) {
            const response = await approveRejectWeeklyTimesheetAPI(timesheetData);
            if (response.data === true) {
                let message = {
                    message: "Timesheet approved successfully.",
                    type: "success",
                };
                this.handleMessage(message);
                await this.handleSearch();
                this.setState({
                    timesheetApprovalPending: []
                })
            } else {
                let message = {
                    message: "Error occurred while approving Timesheet!",
                    type: "warning",
                };
                this.handleMessage(message);
            }
        } else {
            const response = await this.props.approveRejectWeeklyTimesheet(timesheetData);
            if (response.success) {
                let message = {
                    message: "Timesheet approved successfully.",
                    type: "success",
                };
                this.handleMessage(message);
                await this.handleSearch();
            } else {
                let message = {
                    message: "Error occurred while approving Timesheet!",
                    type: "warning",
                };
                this.handleMessage(message);
            }
        }
    }

    handleReject = (timesheet, index) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    input
                    showCancel
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Add rejection note"
                    onConfirm={e => this.inputConfirmAlert(e, timesheet, index)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    btnSize="sm"
                />
            )
        });
    }

    handleMessage = (message) => {
        let options = {};
        options = {
            place: "tr",
            message: message.message,
            type: message.type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    }
    inputConfirmAlert = async (e, timesheet, index) => {
        let data = {
            timesheet,
            status: 'Rejected',
            rejectionNote: e,
            eid: this.props.eid,
            cid: this.props.cid
        }
        let timesheetData = [data];
        if (this.state.timesheetApprovalPending.length === 1 && index === 0) {
            const response = await approveRejectWeeklyTimesheetAPI(timesheetData);
            if (response.data === true) {
                let message = {
                    message: "Timesheet rejected successfully.",
                    type: "success",
                };
                this.handleMessage(message);
                await this.handleSearch();
                this.setState({
                    timesheetApprovalPending: []
                })
                setTimeout(this.inputConfirmAlertNext, 200);
                this.hideAlert();
            } else {
                let message = {
                    message: "Error occurred while rejecting Timesheet!",
                    type: "warning",
                };
                this.handleMessage(message);
                setTimeout(this.inputConfirmAlertNext, 200);
                this.hideAlert();
            }
        } else {
            const response = await this.props.approveRejectWeeklyTimesheet(timesheetData);
            if (response.success) {
                let message = {
                    message: "Timesheet rejected successfully.",
                    type: "success",
                };
                this.handleMessage(message);
                await this.handleSearch();
                setTimeout(this.inputConfirmAlertNext, 200);
                this.hideAlert();
            } else {
                let message = {
                    message: "Error occurred while rejecting Timesheet!",
                    type: "warning",
                };
                this.handleMessage(message);
                setTimeout(this.inputConfirmAlertNext, 200);
                this.hideAlert();
            }
        }
    };

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };

    handleView = (e) => {
        this.setState({
            modal: true
        });
    }


    handleCloseTimesheetModal = () => {
        this.setState({
            modal: false
        });

    }

    handleOpenTimesheetModal = (timeSheet) => {
        let tids = timeSheet.tids.split(',')
        this.setState({
            modal: true,
            tids: tids
        })
    }
    handeApproveAll = async () => {
        const timesheetData = [];
        if (this.state.isSubmitting != true) {
            this.setState({
                isSubmitting: true,
            })
            let allTimeSheets = [...this.state.timesheetApprovalPending]
            allTimeSheets.forEach(timesheet => {
                let data = {
                    timesheet,
                    status: 'Approved',
                    rejectionNote: '',
                    eid: timesheet.eid,
                    cid: timesheet.cid
                }
                timesheetData.push({
                    ...data
                });
            });
            const response = await approveRejectWeeklyTimesheetAPI(timesheetData);
            if (response.data === true) {
                let message = {
                    message: "All Timesheet approved successfully.",
                    type: "success",
                };
                this.handleMessage(message);
                await this.handleSearch();
                this.setState({
                    timesheetApprovalPending: []
                })
                setTimeout(() => {
                    this.setState({
                        isSubmitting: false
                    })
                }, 2000);
            } else {
                let message = {
                    message: "Error occurred while approving Timesheet!",
                    type: "warning",
                };
                this.handleMessage(message);
                setTimeout(() => {
                    this.setState({
                        isSubmitting: false
                    })
                }, 2000);
            }
        }

    }

    handleWeeklySelectedDate = (date) => {
        this.setState({
            ...this.state,
            weeklySelectedDate: moment(date).format('YYYY-MM-DD')
        });
    }

    handleSelectEmployee = (value) => {

        this.setState({
            ...this.state,
            selectedEmployee: value
        });
    }

    handleSelectProject = (value) => {
        this.setState({
            ...this.state,
            selectedProject: value
        });
    }

    handleSearch = () => {
        const { weeklySelectedDate, selectedEmployee, selectedProject, status } = this.state;
        let modifiedSelectedEmployees = [];
        let modifiedSelectedProjects = [];
        let modifiedSelectedStatus = [];
        if (selectedEmployee !== null) {
            selectedEmployee.map(employee => {
                modifiedSelectedEmployees.push(employee.value)
            });
        }

        if (selectedProject !== null) {
            selectedProject.map(project => {
                modifiedSelectedProjects.push(project.label)
            });
        }

        if (this.state.status !== null) {
            this.state.status.map(selectedItem => {
                modifiedSelectedStatus.push(selectedItem.value)
            });
        }

        let data = {
            "cid": this.props.cid,
            "employeeList": [...modifiedSelectedEmployees],
            "managerId": this.props.eid,
            "projectList": [...modifiedSelectedProjects],
            "statusList": [...modifiedSelectedStatus],
            "weekStartDate": moment(weeklySelectedDate).startOf('week').format('YYYY-MM-DD')
        }
        this.props.getAllApprovalPendingTimesheets(data);
    }

    handleStatus = (value) => {
        this.setState({ status: value })
    }

    render() {
        const { config } = this.context;
        const data = this.state.timesheetApprovalPending.map((prop, key) => {
            return {
                id: prop.id,
                employee: prop.fname + " " + prop.lname,
                workDate: moment(prop.startDate).format(dateFormatList[2]) + " to " + moment(prop.endDate).format(dateFormatList[2]),
                project: prop.project,
                totalHours: prop.totalHours,
                status: prop.status,
                approval: (
                    <div className="actions-left">
                        <Button
                            onClick={() => {
                                this.handleOpenTimesheetModal(prop);
                            }}
                            size="sm"
                            id="tooltip209543"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-book-bookmark" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209543"
                            placement="left"
                        >
                            View
                        </UncontrolledTooltip>
                        {prop.status === "Submitted" || prop.status === "Resubmitted" ? (<><Button
                            onClick={() => {
                                this.handleApprove(prop, key);
                            }}
                            style={{ marginLeft: 25, marginRight: 25 }}
                            size="sm"
                            id="tooltip209541"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-check-2" />
                        </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip209541"
                                placement="left"
                            >
                                Approve
                            </UncontrolledTooltip>
                            <Button
                                onClick={() => this.handleReject(prop, key)}
                                size="sm"
                                id="tooltip209542"
                                className={classNames("btn-icon btn-link like", {
                                    "btn-neutral": key < 5
                                })}
                            >
                                <i className="tim-icons icon-simple-remove" />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip209542"
                                placement="left"
                            >
                                Reject
                            </UncontrolledTooltip></>) : (null)}
                    </div>
                )
            };
        });
        var managerEmployees = this.props.managerEmployees.filter(employee => employee.isActive === "Y");

        var managerEmployeesOptions = managerEmployees.length > 0 && managerEmployees.map((employee, index) => {
            return { value: employee.eid, label: employee.fname + ' ' + employee.lname }
        });

        var employeesOptions = this.props.employees.length > 0 && this.props.employees.map((employee, index) => {
            return { value: employee.eid, label: employee.fname + ' ' + employee.lname }
        });

        var projectsOptions = this.props.projects.length > 0 && this.props.projects.map((project, index) => {
            return { value: project.id, label: project.project }
        });

        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    <div>
                        {this.state.alert}
                    </div>
                    {this.state.modal === true ?
                        <EmployeeDetailsModal modal={this.state.modal} handleMessage={this.handleMessage} getAllTimesheetDailyForTids={getAllTimesheetDailyForTids}
                            projects={this.props.projects} tids={this.state.tids} handeCloseModal={this.handleCloseTimesheetModal} toggle={this.toggle} />
                        : <React.Fragment />
                    }
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CardTitle tag="h2">Approve Reject Timesheet</CardTitle>

                                </CardHeader>
                                <Row style={{ display: 'flex', alignItems: 'center', margin: 0 }} >
                                    <Col style={{ maxWidth: 150, paddingLeft: 15, paddingRight: 5 }} >
                                        <Label >Select Week </Label>
                                        <FormGroup className={`has-label`}>
                                            <ReactDatetime
                                                dateFormat={dateFormatList[2]}
                                                viewMode="days"
                                                closeOnSelect={true}
                                                timeFormat={false}
                                                inputProps={{ placeholder: "Select Month", className: "form-control", value: moment(this.state.weeklySelectedDate).format(dateFormatList[2]) }}
                                                onChange={this.handleWeeklySelectedDate}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col style={{ maxWidth: 200, paddingLeft: 5, paddingRight: 5 }}>
                                        <Label for="exampleSelect">Select Employee</Label>
                                        <FormGroup className={`has-label  `}>
                                            <Select
                                                placeholder="Select Employees"
                                                isMulti={true}
                                                defaultValue={0}
                                                value={this.state.selectedEmployee}
                                                onChange={(e) => { this.handleSelectEmployee(e) }}
                                                options={this.props.role === 'Manager' ? managerEmployeesOptions : employeesOptions}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col style={{ maxWidth: 200, paddingLeft: 5, paddingRight: 5 }}>
                                        <Label for="exampleSelect">Select Project</Label>
                                        <FormGroup className={`has-label  `}>
                                            <Select
                                                placeholder="Select Projects"
                                                isMulti={true}
                                                defaultValue={0}
                                                value={this.state.selectedProject}
                                                onChange={(e) => { this.handleSelectProject(e) }}
                                                options={projectsOptions}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col style={{ maxWidth: 200, paddingLeft: 5, paddingRight: 5 }}>
                                        <Label for="exampleSelect">Status</Label>
                                        <FormGroup className={`has-label  `}>
                                            <Select
                                                placeholder="Select Status"
                                                isMulti={true}
                                                defaultValue={0}
                                                value={this.state.status}
                                                onChange={(e) => { this.handleStatus(e) }}
                                                options={status}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col style={{ maxWidth: 100, paddingLeft: 5, paddingRight: 5 }}>
                                        <Button onClick={() => { this.handleSearch() }} style={{ maxWidth: 100, marginTop: 16 }} size="sm" color="info">Search</Button>
                                    </Col>
                                    <Col style={{ maxWidth: 150, paddingLeft: 5, paddingRight: 5 }}>
                                        <Button color="info" style={{ maxWidth: 150, marginTop: 16 }} className="btn-wd btn-info" onClick={this.handeApproveAll}
                                            disabled={this.state.timesheetApprovalPending.length === 0 ? true : false}
                                        >Approve All</Button>
                                    </Col>
                                </Row>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={data}
                                        resizable={true}
                                        columns={[
                                            {
                                                Header: "Employee",
                                                accessor: "employee",
                                                width: 150
                                            },
                                            {
                                                Header: "Project",
                                                accessor: "project",
                                                width: 200
                                            },
                                            {
                                                Header: "Week",
                                                accessor: "workDate",
                                                width: 250
                                            },
                                            {
                                                Header: "Status",
                                                accessor: "status",
                                                width: 200
                                            },
                                            {
                                                Header: "Total Hours",
                                                accessor: "totalHours",
                                                width: 150
                                            },
                                            {
                                                Header: "",
                                                accessor: "approval",
                                                width: 200
                                            }
                                        ]}
                                        LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                                        NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                                        // pageSize={this.props.timesheetApprovalPending.length <= 10 ? this.props.timesheetApprovalPending.length : 10}
                                        pageSize={this.props.timesheetApprovalPending.length <= config.paginationSize ? this.props.timesheetApprovalPending.length : config.paginationSize}
                                        className="-striped -highlight project-table expense-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
} export const mapDispatchToProps = dispatch => ({
    getAllApprovalPendingTimesheets: bindActionCreators(getAllApprovalPendingTimesheets, dispatch),
    approveRejectWeeklyTimesheet: bindActionCreators(approveRejectWeeklyTimesheet, dispatch),
    getAllProjects: bindActionCreators(getAllProjects, dispatch),
    getAllEmployeesByManager: bindActionCreators(getAllEmployeesByManager, dispatch),
    getAllEmployees: bindActionCreators(getAllEmployees, dispatch)
});

export const mapStateToProps = ({ auth, timesheetApprovalPending, projects, managerEmployees, employees }) => ({
    cid: auth.user.cid,
    eid: auth.user.eid,
    timesheetApprovalPending: timesheetApprovalPending,
    projects: projects,
    managerEmployees,
    role: auth.user.roles && auth.user.roles.length >= 1 ? auth.user.roles[0] : [],
    employees,
    auth: auth
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveRejectTimesheet);
