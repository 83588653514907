import { FETCH_ALL_EMPLOYEES, DELETE_EMPLOYEE } from '../actions/employeeAction';
import { FETCH_EMPLOYEE_EXTRA_INFO, ADD_UPDATE_EXTRA_INFO } from '../actions/employeeExtraInfoAction';
import { FETCH_EMPLOYEE_PAYROLL_INFO, ADD_UPDATE_PAYROLL_INFO } from "../actions/employeePayrollInfoAction";
import { ADD_EMPLOYEE_BASIC_INFO, UPDATE_EMPLOYEE_BASIC_INFO } from "../actions/employeeBasicInfoAction";

const employeeReducer = function (state = [], action) {

    let newState;
    let index;
    let eid;

    switch (action.type) {
        case FETCH_ALL_EMPLOYEES:
            return action.employees;
        case FETCH_EMPLOYEE_EXTRA_INFO:
            newState = [...state];
            index = newState.findIndex(emp => emp.eid === action.payload.id);
            if (index > -1) {
                let emp = { ...newState[index] }
                emp.extraInfo = { ...action.payload.extraInfo }
                newState[index] = { ...emp }
            }
            return newState;
        case FETCH_EMPLOYEE_PAYROLL_INFO:
            newState = [...state];
            index = newState.findIndex(emp => emp.eid === action.payload.eid);
            if (index > -1) {
                let emp = { ...newState[index] }
                emp.payRollInfo = { ...action.payload.payRollInfo }
                newState[index] = { ...emp }
            }
            return newState;
        case ADD_EMPLOYEE_BASIC_INFO:
            newState = [...state];
            newState.push(action.employee);
            return newState;
        case ADD_UPDATE_EXTRA_INFO:
            newState = [...state];
            index = newState.findIndex(emp => emp.eid === action.payload.eid);
            if (index > -1) {
                let emp = { ...newState[index] }
                emp.extraInfo = { ...action.payload.extraInfo }
                newState[index] = { ...emp }
            }
            return newState;
        case ADD_UPDATE_PAYROLL_INFO:
            newState = [...state];
            index = newState.findIndex(emp => emp.eid === action.payload.eid);
            if (index > -1) {
                let emp = { ...newState[index] }
                emp.payRollInfo = { ...action.payload.payRollInfo }
                newState[index] = { ...emp }
            }
            return newState;
        case UPDATE_EMPLOYEE_BASIC_INFO:
            newState = [...state];
            index = newState.findIndex(emp => emp.id === action.payload.id);
            if (index > -1) {
                newState.splice(index, 1, action.payload.employee);
            }
            return newState;

        case DELETE_EMPLOYEE:
            newState = [...state];
            eid = action.payload.eid;
            index = newState.findIndex(employee => employee.eid === eid);
            if (index > -1) {
                newState.splice(index, 1);
            };
            return newState;
        default:
            return state;
    }
};

export default employeeReducer;
