
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button, Row, Col } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    };
  }

  render() {
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.props.handleImageChange}  accept={this.props.accept} ref="fileInput" />
        <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
          <img src={this.props.imagePreviewUrl != "" || null ? this.props.imagePreviewUrl : defaultImage} alt="..." />
        </div>
        <div>
          {this.props.imagePreviewUrl != ""? 
          (
            <Row>
            <Col md="6" sm="6">
              <Button
                color={this.props.changeBtnColor}
                style={{ paddingLeft: 20, paddingRight: 20,paddingTop:5, paddingBottom:5}}
                className={this.props.changeBtnClasses}
                onClick={() => this.props.handleClick(this.refs)}
              >
                Change
              </Button>
              </Col>
   
              <Col md="6" sm="6">
              <Button
                style={{ paddingLeft: 20, paddingRight: 20,paddingTop:8, paddingBottom:5}}
                color={this.props.removeBtnColor}
                className={this.props.removeBtnClasses}
                onClick={() => this.props.handleRemove(this.refs)}
              >
                Remove
              </Button>
              </Col>
            </Row>
          ): (
            <Button
              color={this.props.addBtnColor}
              style={{ paddingLeft: 20, paddingRight: 20,paddingTop:5, paddingBottom:5, marginRight:25}}
              className={this.props.addBtnClasses}
              onClick={() => this.props.handleClick(this.refs)}
            >
              {this.props.component === "employee" ?  this.props.avatar ? "Add Profile" : "Select Image" : this.props.avatar ? "Add Logo" : "Select Logo"}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

ImageUpload.defaultProps = {
  avatar: false,
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary"
};

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ])
};

export default ImageUpload;
