import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
    UncontrolledTooltip
} from "reactstrap";
import ModuleModal from "./ModuleModal";
import NotificationAlert from "react-notification-alert";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAllModules, addUpdateModule } from '../../../redux/actions/moduleAction';
import Pagination from "../../../components/common/pagination";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
import { AppContext } from '../../../hoc/AppContext';
class ModuleMaster extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            formType: 'Add',
            id: -1,
            module: {
                id: 0,
                cid: 1,
                parentId: 0,
                module: "",
                isActive: 'Y'
            },
            data: [],
            isDataLoading: false
        };
    }

    async UNSAFE_componentWillMount() {
        this.setState({
            isDataLoading: true
        })
        const response = await this.props.getAllModules(this.props.cid);
        if (response) {
            this.setState({
                isDataLoading: false
            })
        }
    }


    notify = (place, type, message) => {

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };

    showModuleModal = (id) => {
        this.setState({
            formType: 'Update',
            showModal: true,
            id: id
        });
    }

    toggleShowModal = () => {
        this.setState({
            formType: 'Add',
            showModal: !this.state.showModal,
            module: {},
            id: -1

        });
    }

    onClose = () => {
        this.setState({
            showModal: false,
            id: -1
        });
    }

    handleSubmit = async (module, formType) => {

        let result = await this.props.addUpdateModule(module);

        if (result.success) {
            if (formType === 'Add') {
                this.notify("tr", "success", "Module added successfully.");
                this.setState({
                    formType: 'Add',
                    showModal: false,
                    module: {},
                });
                return true;
            } else {
                this.notify("tr", "success", "Module updated successfully.");
                this.setState({
                    formType: 'Add',
                    showModal: false,
                    module: {},
                });
                return true;
            }
        } else {
            if (formType === 'Add') {
                this.notify("tr", "warning", 'Error occurred while adding module!');
                return false;
            } else {
                this.notify("tr", "warning", 'Error occurred while updating module!');
                return false;
            }
        }
    }


    render() {
        const { config } = this.context;
        const data = this.props.modules.map((prop, key) => {
            return {
                id: prop.id,
                cid: prop.cid,
                parentId: prop.parentId,
                module: prop.module,
                isActive: (
                    // we've added some custom button actions
                    <div className="actions-right">
                        <Button
                            onClick={() => { }}
                            color="info"
                            size="md"
                            style={{ cursor: 'context-menu' }}
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            {prop.isActive === 'Y' ? <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span> :
                                <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
                            }
                        </Button>{" "}
                    </div>
                ),
                actions: (
                    // we've added some custom button actions
                    <div className="actions-right">
                        <Button
                            id="tooltip209773"
                            onClick={() => {
                                this.showModuleModal(prop.id)
                            }}
                            // color="warning"
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-pencil" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209773"
                            placement="left"
                        >
                            Edit
                        </UncontrolledTooltip>
                    </div>
                ),
            };
        });
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    {this.state.showModal && <ModuleModal modal={this.state.showModal} toggle={this.toggleShowModal}
                        id={this.state.id} handleMessage={this.notify} modules={this.props.modules}
                        onSubmit={this.handleSubmit} formType={this.state.formType} newModule={this.state.module} close={this.onClose} />}
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CardTitle tag="h2">Module Master</CardTitle>
                                    <div style={{}}>
                                        <Button onClick={this.toggleShowModal} size="sm" color="info">New</Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={data}
                                        resizable={true}
                                        columns={[
                                            {
                                                Header: "",
                                                accessor: "isActive",
                                                width: 70,
                                                sortable: false,
                                                filterable: false,
                                            },
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                width: 110,
                                                sortable: false,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Company ID",
                                                accessor: "cid",
                                                width: 150
                                            },
                                            {
                                                Header: "Parent ID",
                                                accessor: "parentId",
                                                width: 150
                                            },
                                            {
                                                Header: "Module Name",
                                                accessor: "module",
                                                width: 380
                                            },
                                            {
                                                Header: "",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                            },

                                        ]}
                                        LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                                        NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                                        // pageSize={this.props.modules.length <= 10 ? this.props.modules.length : 10}
                                        pageSize={this.props.modules.length <= config.paginationSize ? this.props.modules.length : config.paginationSize}
                                        className="-striped -highlight project-table expense-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export const mapDispatchToProps = dispatch => ({
    getAllModules: bindActionCreators(getAllModules, dispatch),
    addUpdateModule: bindActionCreators(addUpdateModule, dispatch)
});


export const mapStateToProps = ({ modules, auth }) => ({
    modules: modules,
    cid: auth.user.cid,

});
export default connect(mapStateToProps, mapDispatchToProps)(ModuleMaster);
