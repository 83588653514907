import { FETCH_WEEKLY_TIMESHEET, DELETE_TASK_TIMESHEET, ADD_UPDATE_WEEKLY_TIMESHEET } from "../actions/timesheetActions";
const timesheetReducer = function (state = [], action) {
    let newState;
    let index;
    let projectIndex;
    let taskIndex;
    switch (action.type) {
        case FETCH_WEEKLY_TIMESHEET:
            return action.timesheet;

        case DELETE_TASK_TIMESHEET:

            newState = [...state];
            projectIndex = newState.findIndex(
                (ts) => ts.id === action.payload.twid && ts.pid === action.payload.pid,
            );
            if (projectIndex > -1) {
                let projectTS = {...newState[projectIndex]};
                let timesheetDailyList = [...projectTS.timesheetDailyList];
                taskIndex = timesheetDailyList.findIndex(
                  (td) => td.id === action.payload.tdid,
                );
                if (taskIndex > -1) {
                  timesheetDailyList.splice(taskIndex, 1);
                  if (timesheetDailyList.length > 0) {
                    const tidsList = projectTS.tids.split(',');
                    const remainingTidList = tidsList.filter(
                      (tid) => parseInt(tid) !== action.payload.tdid,
                    );
                    const remainingTidsString = remainingTidList.join(',');
                    projectTS.tids = remainingTidsString;
                    projectTS.timesheetDailyList = [...timesheetDailyList];
                    newState[projectIndex] = {...projectTS};
                  } else {
                    newState.splice(projectIndex, 1);
                  }
                }
            }
            return newState;

        case ADD_UPDATE_WEEKLY_TIMESHEET:
            return action.timesheet
        default:
            return state;
    }
};

export default timesheetReducer;