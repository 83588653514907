import logo from "assets/img/react-logo.png";
// import trigonalLogo from "assets/img/trigonal-partners-thumbnail.png";
import Footer from "components/Footer/Footer.js";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React from "react";
import _ from "lodash";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Modal } from "reactstrap";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import { sidebarRoutes, redirectRoutes } from "routes.js";
import ChangePassword from "../../views/ChangePassword";
import { getCompanyBasicInfo } from "../../redux/actions/appConfig";
import { getAuthenticatedUserDetails } from "../../redux/actions/employeeBasicInfoAction";
import PerfectScrollbar from "perfect-scrollbar";
import { AppContext } from '../../hoc/AppContext';
import { getAllInitialCompanyConfigs } from "redux/actions/companyConfigAction";
import { employeeRoutes } from "routes";
import { managerRoutes } from "routes";
import { signoutUser } from "redux/actions/authActions";
import { HRRoutes } from "routes";


class Admin extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      activeColor: "green",
      sidebarMini: true,
      opacity: 0,
      sidebarOpened: false,
      isOpenStepper: false
    };
  }
  UNSAFE_componentWillMount() {
    let hostedUrl = window.location.hostname;
    // let hostedUrl = '65.0.79.133';
      //  let hostedUrl='3.110.1.210';

    // if (hostedUrl == 'localhost') {
    //   hostedUrl = "ess.peramalservices.com";
    //   this.props.getCompanyLogo(hostedUrl);
    // } else {
    if (!this.props.companyLogo) {
      this.props.getCompanyBasicInfo(hostedUrl);
    }

    // }
    if (this.props.auth && this.props.auth.user) {
      this.props.getAuthenticatedUserDetails();
    }
  }

  componentDidMount() {

    if (this.props.auth && this.props.auth.user) {
      this.initAppConfig();
    }

    // if (navigator.platform.indexOf("Win") > -1) {
    //   document.documentElement.classList.add("perfect-scrollbar-on");
    //   document.documentElement.classList.remove("perfect-scrollbar-off");
    //   ps = new PerfectScrollbar(this.refs.mainPanel);
    //   this.refs.mainPanel.addEventListener(
    //     "ps-scroll-y",
    //     this.showNavbarButton
    //   );
    //   let tables = document.querySelectorAll(".table-responsive");
    //   for (let i = 0; i < tables.length; i++) {
    //     ps = new PerfectScrollbar(tables[i]);
    //   }
    // }
    // window.addEventListener("scroll", this.showNavbarButton);
  }

  initAppConfig = async () => {
    const { config, setConfig } = this.context;

    const response = await this.props.getAllInitialCompanyConfigs(this.props.auth.user.cid);
    if (response.success && response.companyConfig) {

      let dateFormat = config.dateFormat;
      let maxFileSizeMB = config.maxFileSizeMB;
      let paginationSize = config.paginationSize
      let claim = {
        ...config.claim,
      };
      const dateConfig = this.getConfiguration(
        response.companyConfig,
        'date_format',
      );
      const maxFileSizeConfig = this.getConfiguration(
        response.companyConfig,
        'max_file_size',
      );
      const claimItemsMaxLimitConfig = this.getConfiguration(
        response.companyConfig,
        'claim_items_max_limit',
      );
      const claimItemAttachmentsMaxLimitConfig = this.getConfiguration(
        response.companyConfig,
        'claim_item_attachments_max_limit',
      );

      const paginationConfig = this.getConfiguration(
        response.companyConfig,
        'pagination_size',
      );
      if (paginationConfig && paginationConfig.paramValue !== '') {
        paginationSize = parseInt(paginationConfig.paramValue, 10);
      }

      if (dateConfig && dateConfig.paramValue !== '') {
        dateFormat = dateConfig.paramValue;
      }
      if (maxFileSizeConfig && maxFileSizeConfig.paramValue !== '') {
        maxFileSizeMB = parseFloat(maxFileSizeConfig.paramValue, 10);
      }
      if (
        claimItemsMaxLimitConfig &&
        claimItemsMaxLimitConfig.paramValue !== ''
      ) {
        claim.claimItemsMaxLimit = parseInt(
          claimItemsMaxLimitConfig.paramValue,
          10,
        );
      }
      if (
        claimItemAttachmentsMaxLimitConfig &&
        claimItemAttachmentsMaxLimitConfig.paramValue !== ''
      ) {
        claim.claimItemAttachmentsMaxLimit = parseInt(
          claimItemAttachmentsMaxLimitConfig.paramValue,
          10,
        );
      }
      setConfig({
        ...config,
        dateFormat,
        maxFileSizeMB,
        claim,
        paginationSize
      });
    }
  };

  getConfiguration = (companyConfig, value) => {
    return companyConfig.find((config) => config.param === value);
  };

  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    //   document.documentElement.className.add("perfect-scrollbar-off");
    //   document.documentElement.classList.remove("perfect-scrollbar-on");
    //   this.refs.mainPanel.removeEventListener(
    //     "ps-scroll-y",
    //     this.showNavbarButton
    //   );
    // }
    // window.removeEventListener("scroll", this.showNavbarButton);
  }
  componentDidUpdate(e) {
    var ps;
    if (e.location.pathname !== e.history.location.pathname) {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      if (this.refs.mainPanel) {
        this.refs.mainPanel.scrollTop = 0;
      }
    }
  }
  showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      this.refs.mainPanel.scrollTop > 50
    ) {
      this.setState({ opacity: 1 });
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      this.refs.mainPanel.scrollTop <= 50
    ) {
      this.setState({ opacity: 0 });
    }
  };
  getRoutes = routes => {
    
    return routes.map((prop, key) => {
      
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else if (prop.layout === "/employee") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else if (prop.layout === "/manager") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else if (prop.layout === "/humanresource") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getActiveRoute = routes => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  openChangePassword = () => {

    this.setState({
      isOpenStepper: true
    })
  }
  handleProfile = () => {
    let passProps = {
      pathname: 'user-profile',
    }
    this.props.history.push(passProps);
  }

  handeCloseModal = () => {
    this.setState({
      isOpenStepper: false
    })
  }
  handleMiniClick = () => {
    // let notifyMessage = "Sidebar mini ";
    // if (document.body.classList.contains("sidebar-mini")) {
    //   this.setState({ sidebarMini: false });
    //   notifyMessage += "deactivated...";
    // } else {
    //   this.setState({ sidebarMini: true });
    //   notifyMessage += "activated...";
    // }
    // let options = {};
    // options = {
    //   place: "tr",
    //   message: notifyMessage,
    //   type: "primary",
    //   icon: "tim-icons icon-bell-55",
    //   autoDismiss: 7
    // };
    // this.refs.notificationAlert.notificationAlert(options);
    document.body.classList.toggle("sidebar-mini");
  };
  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened
    });
    document.documentElement.classList.toggle("nav-open");
  };
  closeSidebar = () => {
    this.setState({
      sidebarOpened: false
    });
    document.documentElement.classList.remove("nav-open");
  };
  handleMessage = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message.message,
      type: message.type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  getAuthorizedRoutes = (sidebarRoutes) => {
    const { moduleAccess } = this.props;
    const role = this.props.auth.user && this.props.auth.user.roles[0];
    let authorizedRoutes = [];
    sidebarRoutes.forEach(route => {
      const module = _.find(moduleAccess, { module: route.dbName });
      if (module && module.access && module.access.view) {
        let authorizedRoute = { ...route };
        if (route.views && route.views.length > 0) {
          let authorizedSubRoutes = [];
          route.views.forEach(childView => {
            const submodule = _.find(module.subModules, { module: childView.dbName });
            if (submodule && submodule.access && submodule.access.view) {
              authorizedSubRoutes.push(childView);
            }
          })
          if (authorizedSubRoutes.length > 0) {
            authorizedRoute.views = [...authorizedSubRoutes];
            authorizedRoutes.push(authorizedRoute);
          } else {
            authorizedRoute.views = [];
            delete authorizedRoute.views;
            authorizedRoute.collapse = false;
            authorizedRoutes.push(authorizedRoute);
          }
        } else {
          authorizedRoutes.push(authorizedRoute);
        }
      }
    });

    for (let i = 0; i < redirectRoutes.length; i++) {
      redirectRoutes[i].layout = role === 'Company Administrator' ? '/admin' :role === 'Human Resource'? '/humanresource' : '/' + role.toLowerCase();
    }
    authorizedRoutes = [...authorizedRoutes, ...redirectRoutes];
    return authorizedRoutes;
  }

  render() {
    // let currentCompany = this.props.companies.find(company => company.id === this.props.user.cid);
    if (!this.props.token || this.props.token == null) {
      return <Redirect to="/auth/login" />
    }
    
    var authorizedRoutes = null;
    if (this.props.token && this.props.auth.user && this.props.auth.user.roles[0] === 'Employee') {
      authorizedRoutes = this.getAuthorizedRoutes(employeeRoutes);
    } else if (this.props.token && this.props.auth.user && this.props.auth.user.roles[0] === 'Manager') {
      authorizedRoutes = this.getAuthorizedRoutes(managerRoutes);
    } else if (this.props.token && this.props.auth.user && this.props.auth.user.roles[0] === "Human Resource") {
      authorizedRoutes = this.getAuthorizedRoutes(HRRoutes);
    } else {
      authorizedRoutes = this.getAuthorizedRoutes(sidebarRoutes);
    }

    let companyLogo = this.props.companyLogo ? this.props.companyLogo : null;
    // const authorizedRoutes = this.getAuthorizedRoutes(sidebarRoutes);
    return (
      <div className="wrapper">
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {this.state.isOpenStepper ?
          <Modal isOpen={this.state.isOpenStepper} className="custom-modal-dialog" >
            <ChangePassword handeCloseModal={this.handeCloseModal} handleMessage={this.handleMessage} />
          </Modal>
          : <React.Fragment />
        }
        {this.props.user.changePasswordOnLogin ?
          <Modal isOpen={this.props.user.changePasswordOnLogin} className="custom-modal-dialog" >
            <ChangePassword handeCloseModal={() => { }} handleMessage={this.handleMessage} formType="Change Password" signoutUser={() => { this.props.signoutUser() }} />
          </Modal>
          : <React.Fragment />
        }
        <div
          className="navbar-minimize-fixed"
          style={{ opacity: this.state.opacity }}
        >
          <button
            className="minimize-sidebar btn btn-link btn-just-icon"
            onClick={this.handleMiniClick}
          >
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
          </button>
        </div>
        <Sidebar
          {...this.props}
          // routes={routes}
          routes={authorizedRoutes}
          activeColor={this.state.activeColor}
          logo={{
            outterLink: "#",
            text: "ESS-Timesheet",
            imgSrc: companyLogo ? companyLogo : logo
          }}
          closeSidebar={this.closeSidebar}
        />
        <div
          className="main-panel"
          ref="mainPanel"
          // style={{maxHeight:'100%',height:'100%',overflow:'auto'}}
          style={{ overflow: 'hidden' }}
        // data={this.state.activeColor}
        >
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            // brandText={this.getActiveRoute(routes)}
            brandText={this.getActiveRoute(authorizedRoutes)}
            sidebarOpened={this.state.sidebarOpened}
            toggleSidebar={this.toggleSidebar}
            openChangePassword={this.openChangePassword}
            handleProfile={this.handleProfile}
          />
          <Switch>
            {/* {this.getRoutes(routes)} */}
            {this.getRoutes(authorizedRoutes)}
          </Switch>
          {// we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
              -1 ? null : (
              <Footer fluid company={!this.props.companyName ? "" : this.props.companyName} />
            )}
        </div>
        {/* <FixedPlugin
          activeColor={this.state.activeColor}
          sidebarMini={this.state.sidebarMini}
          handleActiveClick={this.handleActiveClick}
          handleMiniClick={this.handleMiniClick}
        /> */}
      </div>
    );
  }
}
export const mapDispatchToProps = dispatch => ({
  getCompanyBasicInfo: bindActionCreators(getCompanyBasicInfo, dispatch),
  getAuthenticatedUserDetails: bindActionCreators(getAuthenticatedUserDetails, dispatch),
  getAllInitialCompanyConfigs: bindActionCreators(getAllInitialCompanyConfigs, dispatch),
  signoutUser: bindActionCreators(signoutUser, dispatch),
});

export const mapStateToProps = ({ auth, appConfig, companies }) => ({
  token: auth.token,
  moduleAccess: auth.moduleAccess,
  companyLogo: appConfig.logo && appConfig.logo,
  companyName: appConfig.companyName && appConfig.companyName,
  auth: auth,
  user: auth.user,
  companies: companies
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
