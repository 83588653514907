import { FETCH_ROLE_MODULE_ACCESS ,ADD_UPDATE_ROLE_MODULE_ACCESS} from "../actions/roleModuleAccessAction";
const roleModuleAccessReducer = function (state = [], action) {
    let newState;
    switch (action.type) {
        case FETCH_ROLE_MODULE_ACCESS:
            return action.roleModuleAccess;
        case ADD_UPDATE_ROLE_MODULE_ACCESS:
            newState = [...action.roleModuleAccess ];
            return newState;
            default:
                return state;
        }
    };
    
export default roleModuleAccessReducer;