import { FETCH_ADMIN_MONTHLY_INVOICES, FETCH_ADMIN_QUARTERLY_INVOICES } from "../actions/adminInvoicesAction";
// import {  } from "../actions/timesheetReportAction";
const INITIAL_STATE = {
    monthlyInvoices: [],
    quarterlyInvoices: [],
}
const adminInvoicesReducer = function (state = INITIAL_STATE, action) {
    let newState;
    let index;
    switch (action.type) {
        case FETCH_ADMIN_MONTHLY_INVOICES:
            newState = { ...state }
            newState.monthlyInvoices = [...action.monthlyInvoices];
            return newState;

        case FETCH_ADMIN_QUARTERLY_INVOICES:
            newState = { ...state };
            newState.quarterlyInvoices = [...action.quarterlyInvoices];
            return newState;

        default:
            return state;
    }
};

export default adminInvoicesReducer;