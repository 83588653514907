import Pagination from "components/common/pagination";
import { ADD_UPDATE_EMPLOYEE_APPRAISAL, ADD_UPDATE_EMPLOYEE_APPRAISAL_ERROR, ADD_UPDATE_EMPLOYEE_APPRAISAL_SUCCESS, FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_SUCCESS, FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID, FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID_ERROR, FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID_SUCCESS, FETCH_EMPLOYEE_APPRAISAL_LIST, FETCH_EMPLOYEE_APPRAISAL_LIST_ERROR, FETCH_EMPLOYEE_APPRAISAL_LIST_SUCCESS, SET_EMPLOYEE_APPRAISAL_LIST_PAGINATION } from "../actions/employeeAppraisalActions";

const INIT_STATE = {
    employeeAppraisalList: [],
    employeeAppraisal: null,
    loading: false,
    pagination: {
        currentPage: 1,
        pageSize: 10,
        totalPages: 0,
        totalItems: 0,
    }
}

const employeeAppraisalReducer = function (state = INIT_STATE, action) {
    switch (action.type) {

        case FETCH_EMPLOYEE_APPRAISAL_LIST:
        case FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID: {
            return { ...state, loading: true };
        }
        case FETCH_EMPLOYEE_APPRAISAL_LIST_SUCCESS: {
            return { ...state, employeeAppraisalList: [...action.employeeAppraisalList], loading: false };
        }
        case FETCH_EMPLOYEE_APPRAISAL_LIST_ERROR: {
            return { ...state, loading: false, employeeAppraisalList: [] };
        }

        case ADD_UPDATE_EMPLOYEE_APPRAISAL: {
            return { ...state, loading: false };
        }

        case ADD_UPDATE_EMPLOYEE_APPRAISAL_SUCCESS: {
            let modifiedEmployeeAppraisal=state.employeeAppraisal;
            modifiedEmployeeAppraisal.employeeAppraisal=action.employeeAppraisal
            return { ...state, employeeAppraisal: modifiedEmployeeAppraisal, loading: false };
        }

        case ADD_UPDATE_EMPLOYEE_APPRAISAL_ERROR:
        case FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID_ERROR: {
            return { ...state, loading: false };
        }

        case FETCH_EMPLOYEE_APPRAISAL_BY_APPRAISAL_ID_SUCCESS: {
            return { ...state, employeeAppraisal: action.employeeAppraisal, loading: false };
        }

        case FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID_SUCCESS: {
            return { ...state, employeeAppraisal: action.employeeAppraisal, loading: false };
        }


        default:
            return state;
    }

};

export default employeeAppraisalReducer;