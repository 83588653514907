import { FETCH_ALL_DESIGNATIONS, ADD_UPDATE_DESIGNATION } from "../actions/designationAction";
const designationReducer = function (state = [], action) {

    switch (action.type) {
        case FETCH_ALL_DESIGNATIONS:
            return action.designations;
        case ADD_UPDATE_DESIGNATION:
            let newState = [...state];
            let index = newState.findIndex(designation => designation.id === action.designation.id);
            if (index === -1) {
                newState.push(action.designation);
                return newState;
            } else {
                newState[index] = action.designation;
                return newState;
            }

        default:
            return state;
    }
};

export default designationReducer;