import classNames from "classnames";
import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";
import DesignationModal from "./DesignationModal";
import { getAllDesignations, addUpdateDesignation } from "../../../redux/actions/designationAction";
import Pagination from "../../../components/common/pagination";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
import { AppContext } from '../../../hoc/AppContext';
class DesignationMaster extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            formType: 'Add',
            designation: {
                id: 0,
                cid: this.props.cid,
                designation: '',
                isActive: 'Y'
            },
            data: [],
            isLoading: false,
            isDataLoading: false
        };
    }

    async UNSAFE_componentWillMount() {
        this.setState({
            isDataLoading: true
        })
        await this.props.getAllDesignations(this.props.cid);
        this.setState({
            ...this.state,
            isDataLoading: false,
            data: this.props.designations.map((prop, key) => {
                return {
                    id: prop.id,
                    cid: prop.cid,
                    designation: prop.designation,
                    isActive: prop.isActive,
                    test: (
                        <div className="actions-right">
                            <Button
                                onClick={() => { }}
                                size="md"
                                style={{ cursor: 'context-menu' }}
                                className={classNames("btn-icon btn-link like", {
                                    "btn-neutral": key < 5
                                })}
                            >
                                {prop.isActive === 'Y' ? <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span> :
                                    <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
                                }
                            </Button>{" "}
                        </div>
                    ),
                    status: prop.isActive === "Y" ? "active" : "inactive",

                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <Button
                                id="tooltip209885"
                                onClick={() => {
                                    let obj = this.state.data.find(o => o.id === prop.id);

                                    let { id,
                                        cid,
                                        designation,
                                        isActive,
                                    } = obj;
                                    let SelectedDesignation = { id, cid, designation, isActive }
                                    this.showProjectModal(SelectedDesignation)
                                }}
                                // color="warning"
                                size="sm"
                                className={classNames("btn-icon btn-link like", {
                                    "btn-neutral": key < 5
                                })}
                            >
                                <i className="tim-icons icon-pencil" />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip209885"
                                placement="left"
                            >
                                Edit
                        </UncontrolledTooltip>
                        </div>
                    )
                };
            })
        });

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.state.data !== nextProps.designations) {

            this.setState({
                ...this.state,
                data: nextProps.designations.map((prop, key) => {

                    return {
                        id: prop.id,
                        cid: prop.cid,
                        designation: prop.designation,
                        isActive: prop.isActive,
                        test: (
                            <div className="actions-right">
                                <Button
                                    onClick={() => { }}
                                    size="md"
                                    style={{ cursor: 'context-menu' }}
                                    className={classNames("btn-icon btn-link like", {
                                        "btn-neutral": key < 5
                                    })}
                                >
                                    {prop.isActive === 'Y' ? <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span> :
                                        <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
                                    }
                                </Button>{" "}
                            </div>
                        ),
                        status: prop.isActive === "Y" ? "active" : "inactive",
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                <Button
                                    id="tooltip209885"
                                    onClick={() => {
                                        let obj = this.state.data.find(o => o.id === prop.id);

                                        let { id,
                                            cid,
                                            designation,
                                            isActive,
                                        } = obj;
                                        let SelectedDesignation = { id, cid, designation, isActive }
                                        this.showProjectModal(SelectedDesignation)
                                    }}
                                    // color="warning"
                                    size="sm"
                                    className={classNames("btn-icon btn-link like", {
                                        "btn-neutral": key < 5
                                    })}
                                >
                                    <i className="tim-icons icon-pencil" />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip209885"
                                    placement="left"
                                >
                                    Edit
                        </UncontrolledTooltip>
                            </div>
                        )
                    };
                })
            });
        }
    }

    notify = (place, type, message) => {

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };

    showProjectModal = (designation) => {
        this.setState({
            formType: 'Update',
            showModal: true,
            designation: { ...designation }
        });
    }

    toggleShowModal = () => {

        this.setState({
            formType: 'Add',
            showModal: !this.state.showModal,
            designation: {},

        });
    }

    onClose = () => {

        this.setState({
            showModal: false,
        });
    }

    handleSubmit = async (designation, formType) => {
        this.setState({
            isLoading: true
        })
        if (this.state.isLoading === false) {

            if (formType === "Add") {

                let result = await this.props.addUpdateDesignation(designation);

                if (result.success) {
                    this.notify("tr", "success", "Added new designation successfully.");
                } else {
                    this.notify("tr", "warning", "Failed to add new designation!");
                }

            } else {

                let result = await this.props.addUpdateDesignation(designation);

                if (result.success) {
                    this.notify("tr", "success", "Updated " + result.data.designation + "  successfully.");
                } else {
                    this.notify("tr", "warning", "Failed to update designation!");
                }
            }

        }
        this.setState({
            formType: 'Add',
            showModal: false,
            designation: {},
            isLoading: false
        });
    }


    render() {

        const myCustomNextText = () => {
            return
        };
        const { config } = this.context;
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    {this.state.showModal && <DesignationModal cid={this.props.cid} modal={this.state.showModal} toggle={this.toggleShowModal} onSubmit={this.handleSubmit} formType={this.state.formType} designation={this.state.designation} designations={this.props.designations} close={this.onClose} />}
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CardTitle tag="h2">Designation Master Details</CardTitle>
                                    <div style={{}}>
                                        <Button size="sm" onClick={this.toggleShowModal} color="info">New</Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={this.state.data}
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "",
                                                accessor: "test",
                                                width: 70,
                                                sortable: false,
                                                filterable: false
                                            },
                                            {
                                                Header: "Id",
                                                accessor: "id",
                                                width: 100,
                                                sortable: false,
                                                filterable: false
                                            },
                                            {
                                                Header: "Designation",
                                                accessor: "designation",
                                                width: 350,
                                            },
                                            // {
                                            //     Header: "Company",
                                            //     accessor: "company"
                                            // },
                                            {
                                                Header: "Status ",
                                                accessor: "status",
                                                width: 350,
                                            },
                                            {
                                                Header: "",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false
                                            },

                                        ]}
                                        LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                                        NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                                        pageSize={this.state.data.length <= config.paginationSize ? this.props.designations.length : config.paginationSize}
                                        className="-striped -highlight project-table expense-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export const mapDispatchToProps = dispatch => ({
    getAllDesignations: bindActionCreators(getAllDesignations, dispatch),
    addUpdateDesignation: bindActionCreators(addUpdateDesignation, dispatch)
});


export const mapStateToProps = ({ auth, projects, client, companies, designations, }) => {


    return {
        token: auth.user.token,
        projects: projects,
        clients: client,
        companies: companies,
        designations: designations,
        cid: auth.user.cid,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(DesignationMaster);
