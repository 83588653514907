import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';
import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Button, Card, CardBody, CardHeader, Col, Row, UncontrolledTooltip } from "reactstrap";
import Pagination from "../../../components/common/pagination";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
// import TimesheetReportModal from "./timesheetReportModal";


class MonthlyReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf("month").format('YYYY-MM-DD'),
            status: 'All',
            data: [],
        };
    }



    async UNSAFE_componentWillMount() {

        this.setState({
            ...this.state,
            data: this.props.timesheetMonthlyReport && this.props.timesheetMonthlyReport.map((prop, key) => {
                return {
                    id: prop.id,
                    month: moment(this.props.monthlySelectedDate).month(prop.month).format("MMM YYYY"),
                    employeeId: prop.eid,
                    employeeName: prop.employeeName,
                    project: prop.project,
                    totalHours: (
                        <div className="actions-right custom-action-right custom-total-hours">
                            {prop.totalHours}
                        </div>
                    ),
                    status: prop.status === 'New' ? 'Saved' : prop.status,
                    comment: (
                        <div className="comment">
                            {/* <span> {prop.rejectionNote}</span> */}
                            <span id={"tooltip209891" + key}>{prop.rejectionNote}</span>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip209891" + key}
                                    placement="right"
                                >
                                    {prop.rejectionNote}
                                </UncontrolledTooltip>
                        </div>
                    ),
                    view: (
                        // we've added some custom button actions
                        <div className="">
                            <Button
                                color="default"
                                size="sm"
                            // onClick={async () => {
                            //     let obj = this.state.data.find(o => o.id === prop.id);
                            //     let result = await this.props.getWeeklyTimesheet(1, moment(prop.startDate).startOf('week').format('YYYY-MM-DD'), 147);
                            //     this.showReportModal(this.props.timesheet);
                            // }}
                            >
                                View
                                </Button>
                        </div>
                    )
                };
            })
        });
    }

    async  UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.state.data !== nextProps.timesheetMonthlyReport) {

            this.setState({
                ...this.state,
                data: nextProps.timesheetMonthlyReport.map((prop, key) => {
                    return {
                        id: prop.id,
                        month: moment(this.props.monthlySelectedDate).month(prop.month).format("MMM YYYY"),
                        employeeId: prop.eid,
                        employeeName: prop.employeeName,
                        project: prop.project,
                        totalHours: (
                            <div className="actions-right custom-action-right custom-total-hours">
                                {prop.totalHours}
                            </div>
                        ),
                        status: prop.status === 'New' ? 'Saved' : prop.status,
                        comment: (
                            <div className="comment">
                                {/* <span> {prop.rejectionNote}</span> */}
                                <span id={"tooltip209891" + key}>{prop.rejectionNote}</span>
                                <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltip209891" + key}
                                    placement="right"
                                >
                                    {prop.rejectionNote}
                                </UncontrolledTooltip>
                            </div>
                        ),
                        view: (
                            // we've added some custom button actions
                            <div className="">
                                <Button
                                    color="default"
                                    size="sm"
                                // onClick={async () => {
                                //     let obj = this.state.data.find(o => o.id === prop.id);
                                //     let result = await this.props.getWeeklyTimesheet(1, moment(prop.startDate).startOf('week').format('YYYY-MM-DD'), 147);
                                //     this.showReportModal(this.props.timesheet);
                                // }}
                                >
                                    View
                                </Button>
                            </div>
                        )
                    };
                })
            });
        }
    }


    handleStartDate = (date) => {
        this.setState({
            ...this.state,
            startDate: moment(date).format('YYYY-MM-DD')
        });
    }


    handleEndDate = (date) => {
        var endDate = moment(date).endOf('month');

        this.setState({
            ...this.state,
            endDate: moment(endDate).format('YYYY-MM-DD')

        });
    }


    handleStatus = (e) => {
        let status = e.target.value;
        this.setState({
            ...this.state,
            status: status
        });
    }


    notify = (place, type, message) => {

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };



    handleFind = async () => {

        let result = await this.props.getTimesheetWeeklyReport(this.props.user.cid, this.props.user.eid, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD'), this.state.status);
        if (result.success) {
            this.notify("tr", "success", "Fetched timesheet report successfully.");
        } else {
            this.notify("tr", "warning", "Failed to fetch timesheet report!");
        }
    }

    showReportModal = () => {
        this.setState({
            showModal: true,
        });
    }

    toggleShowModal = () => {

        this.setState({
            showModal: !this.state.showModal,
        });
    }

    onClose = () => {
        this.setState({
            showModal: false,
        });
    }

    handleSubmit = async (project, formType) => {
        // project.id = Math.random();

        this.setState({
            showModal: false,
        });
    }

    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const marginBottom = 20;

        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Timesheet Report";
        const EmployeeName = "Employee: John Doe";

        const headers = [["Week", "Total Hours", "Status", "Rejection Note"]];

        const data = this.state.data.map(elt => [moment(elt.startDate).format("MMMM Do") + '-' + moment(elt.endDate).format("Do"), elt.totalHours, elt.status, elt.rejectionNote]);
        let content = {
            startY: 80,
            head: headers,
            body: data
        };
        doc.setFontSize(20);
        doc.text(title, marginLeft, 40);
        // doc.setFontSize(16);
        // doc.text(EmployeeName, marginLeft, 90);
        doc.autoTable(content);
        doc.save("employee-timesheet-report.pdf")
    }


    render() {

        const myCustomNextText = () => {
            return
        };
        return (
            <>
                <div className="monthly-report-section">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    {/* {this.state.showModal && <TimesheetReportModal modal={this.state.showModal} timesheet={this.props.timesheet} toggle={this.toggleShowModal} projects={this.props.projects} onSubmit={this.handleSubmit} close={this.onClose} />} */}

                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            <Card>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={this.state.data}
                                        // filterable
                                        // nextText={myCustomNextText}
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "Month",
                                                accessor: "month",
                                                width: 100,
                                            },
                                            {
                                                Header: "Employee ID",
                                                accessor: "employeeId"
                                            },
                                            {
                                                Header: "Employee",
                                                accessor: "employeeName",
                                                width: 220
                                            },
                                            {
                                                Header: "Project",
                                                accessor: "project"
                                            },
                                            {
                                                Header: "Total Hours",
                                                accessor: "totalHours",
                                                width: 110,
                                            },
                                            {
                                                Header: "Status",
                                                accessor: "status",
                                                width: 110,
                                            },
                                            {
                                                Header: "Comment",
                                                accessor: "comment"
                                            },

                                        ]}
                                        LoadingComponent={() => (<LoadingIndicator loading={this.props.isLoading} />)}
                                        NoDataComponent={() => (<NoData loading={this.props.isLoading} />)}
                                        pageSize={this.props.timesheetMonthlyReport.length <= this.props.pageSize ? this.props.timesheetMonthlyReport.length : this.props.pageSize}
                                        className="-striped -highlight project-table timesheet-report-table "
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


export const mapDispatchToProps = dispatch => ({

});


export const mapStateToProps = ({ auth, projects, client, companies, timesheet, timesheetReport, }) => ({
    user: auth.user
});


export default connect(mapStateToProps, mapDispatchToProps)(MonthlyReport);
