import { getAllEmployeesByAdminAPI } from "../../services/employees-service";

export const FETCH_ALL_EMPLOYEES_BY_ADMIN = 'FETCH_ALL_EMPLOYEES_BY_ADMIN';

export const getAllEmployeesByAdmin = (cid,searchValue) => {

    return async (dispatch) => {
        const response = await getAllEmployeesByAdminAPI(cid,searchValue);

        if (response.success === true) {
            dispatch({
                type: FETCH_ALL_EMPLOYEES_BY_ADMIN,
                employees: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}