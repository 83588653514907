import { FETCH_TIMESHEET_WEEKLY_REPORT, FETCH_TIMESHEET_QUARTERLY_REPORT, FETCH_TIMESHEET_MONTHLY_REPORT, FETCH_REJECTED_TIMESHEET_REPORT, FETCH_REJECTED_TIMESHEET_REPORT_SUCCESS, FETCH_REJECTED_TIMESHEET_REPORT_ERROR, FETCH_REJECTED_TIMESHEET_REPORT_START, RESET_REJECTED_TIMESHEET_REPORT } from "../actions/timesheetReportAction";

const INITIAL_STATE = {
    timesheetWeeklyReport: [],
    timesheetMonthlyReport: [],
    timesheetQuarterlyReport: [],
    employeeInfo: null,
    rejectedTimesheetReport: [],
    loading: false
}
const timesheetReportReducer = function (state = INITIAL_STATE, action) {
    let newState;

    switch (action.type) {
        case FETCH_TIMESHEET_WEEKLY_REPORT:
            newState = { ...state };
            newState.timesheetWeeklyReport = [...action.timesheetWeeklyReport.employeeTimesheetReportDTO];
            newState.employeeInfo = { ...action.timesheetWeeklyReport };
            return newState;

        case FETCH_TIMESHEET_MONTHLY_REPORT:
            newState = { ...state };
            newState.timesheetMonthlyReport = [...action.timesheetMonthlyReport.employeeTimesheetReportDTO];
            newState.employeeInfo = { ...action.timesheetMonthlyReport };
            return newState;

        case FETCH_TIMESHEET_QUARTERLY_REPORT:
            newState = { ...state };
            newState.timesheetQuarterlyReport = [...action.timesheetQuarterlyReport.employeeTimesheetReportDTO];
            newState.employeeInfo = { ...action.timesheetQuarterlyReport };
            return newState;
        case FETCH_REJECTED_TIMESHEET_REPORT_START:
            return { ...state, loading: true }

        case FETCH_REJECTED_TIMESHEET_REPORT_SUCCESS:
            newState = { ...state };
            newState.rejectedTimesheetReport = [...action.rejectedTimesheetReport];
            newState.loading = false;
            return newState;
        case FETCH_REJECTED_TIMESHEET_REPORT_ERROR:
            return { ...state, loading: false }

        case RESET_REJECTED_TIMESHEET_REPORT:
            return { ...state, rejectedTimesheetReport: [] }
        default:
            return state;
    }
};

export default timesheetReportReducer;