import { FETCH_ALL_NOTIFICATIONS } from '../actions/notificationAction';

const notificationsReducer = function (state = [], action) {

    switch (action.type) {
        case FETCH_ALL_NOTIFICATIONS:
            return action.notifications;             
        default:
            return state;
    }
};

export default notificationsReducer;