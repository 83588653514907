import { GET_CLIENTS } from '../actions/clientActions';


const INITIAL_STATE = []

const clientReducer = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case GET_CLIENTS:
            newState = action.clients;
            return newState;

        default: return state;
  }
};

export default clientReducer;
