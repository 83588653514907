import { FETCH_ALL_HOLIDAYS_BY_LOCATION } from '../actions/holidaysByLocationAction';


const INITIAL_STATE = []

const holidayByLocationReducer = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case FETCH_ALL_HOLIDAYS_BY_LOCATION:
            newState = action.holidays;
            return newState;

        default: return state;
  }
};

export default holidayByLocationReducer;
