import { FETCH_LEAVES_HISTORY } from '../actions/manageLeaveActions';


const INITIAL_STATE = []

const leaveHistoryDetails = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case FETCH_LEAVES_HISTORY:
            newState = action.leavesHistory;
            return newState;

        default: return state;
  }
};

export default leaveHistoryDetails;
