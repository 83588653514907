import { getAllEmployeeClaimsAPI, addUpdateEmployeeClaimAPI, deleteClaimAPI, deleteClaimItemAPI } from "../../services/claims-service";

export const FETCH_ALL_EMPLOYEES_CLAIMS = 'FETCH_ALL_EMPLOYEES_CLAIMS';
export const ADD_UPDATE_EMPLOYEE_CLAIM = 'ADD_UPDATE_EMPLOYEE_CLAIM';
export const APPROVE_REJECT_EMPLOYEE_CLAIM = 'APPROVE_REJECT_EMPLOYEE_CLAIM';
export const DELETE_CLAIM = 'DELETE_CLAIM';
export const getAllEmployeeClaims = (cid, eid) => {
    return async (dispatch) => {
        const response = await getAllEmployeeClaimsAPI(cid, eid);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_EMPLOYEES_CLAIMS,
                claims: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

export const addUpdateEmployeeClaim = (claim) => {
    return async (dispatch) => {
        const response = await addUpdateEmployeeClaimAPI(claim);
        if (response.data) {
            dispatch({
                type: ADD_UPDATE_EMPLOYEE_CLAIM,
                payload: {
                    id: claim.id,
                    claim: response.data
                }
            });
            return { success: true, data: response.data };
        }
        else {
            return { success: false };
        }
    }
}

export const deleteClaim = (claimId) => {
    return async (dispatch, getState) => {
        var { auth } = getState();
        var cid = auth.user.cid;

        const response = await deleteClaimAPI(claimId, cid);
        if (response.data) {
            dispatch({
                type: DELETE_CLAIM,
                payload: {
                    id: claimId
                }
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

export const deleteClaimItem = (claimItemId) => {
    return async (dispatch, getState) => {
        const response = await deleteClaimItemAPI(claimItemId);
        if (response.data) {
            dispatch({
                type: DELETE_CLAIM,
                payload: {
                    claimItemId: claimItemId
                }
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}