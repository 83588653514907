import { getAllProjectsAPI } from '../../services/project-service';

export const FETCH_ALL_PROJECTS = 'FETCH_ALL_PROJECTS';
export const ADD_UPDATE_PROJECT = 'ADD_UPDATE_PROJECT';

export const getAllProjects = (cid) => {
    return async (dispatch) => {
        const response = await getAllProjectsAPI(cid);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_PROJECTS,
                projects: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}


export const addUpdateProjectAction = (project) => {
    return (dispatch) => {
        dispatch({
            type: ADD_UPDATE_PROJECT,
            project: project
        });
    }


}