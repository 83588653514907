import { showDashboardDetailsFromDb } from "../../services/dashboard-service";
export const GET_DASHBOARD_DETAILS = 'GET_DASHBOARD_DETAILS';

export const showDashboardDetails = (cid,eid, year) => {
  return async (dispatch) => {
    const response = await showDashboardDetailsFromDb(cid,eid, year)
    if (response.data) {
      let result = response.data;
      dispatch({
        type: GET_DASHBOARD_DETAILS,
        dashboard: result
      });
      return { success: true, result };
    }
    else {
      return { success: false };
    }
  }
}

