import axios from 'axios';

export const getAllRoleModuleAccessAPI = (cid, roleid) => {
  return axios
    .get(`/api/v1/role-module-access`, {
      params: {
        cid: cid,
        roleid: roleid
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const addUpdateRoleModuleAccessAPI = (roleModuleAccess) => {
  return axios
    .post(`/api/v1/add-update-role-module-acess`, roleModuleAccess)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}
