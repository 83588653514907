import { getProjectAllEmployeesAPI, deleteProjectEmployeeAPI } from "../../services/project-service";

export const FETCH_PROJECT_ALL_EMPLOYEES = 'FETCH_PROJECT_ALL_EMPLOYEES';
export const DELETE_PROJECT_EMPLOYEE = 'DELETE_PROJECT_EMPLOYEE';
export const ADD_UPDATE_PROJECT_EMPLOYEE = 'ADD_UPDATE_PROJECT_EMPLOYEE';
export const FETCH_INITIAL_PROJECT_EMPLOYEES = 'FETCH_INITIAL_PROJECT_EMPLOYEES'
export const getProjectAllEmployees = (pid, cid) => {
    return async (dispatch) => {
        const response = await getProjectAllEmployeesAPI(pid, cid);
        if (response.data) {
            dispatch({
                type: FETCH_PROJECT_ALL_EMPLOYEES,
                projectEmployees: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

export const deleteProjectEmployee = (eid, pid, cid) => {
    return async (dispatch) => {
        const response = await deleteProjectEmployeeAPI(eid, pid, cid);
        if (response.data) {
            dispatch({
                type: DELETE_PROJECT_EMPLOYEE,
                payload: { eid: eid, pid: pid }
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}


export const addUpdateProjectEmployee = (resource) => {
    return (dispatch) => {
        dispatch({
            type: ADD_UPDATE_PROJECT_EMPLOYEE,
            projectEmployees: resource
        });
    }
}

export const getInitialProjectEmployees = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_INITIAL_PROJECT_EMPLOYEES,
            projectEmployees: []
        });
    }
}