import _ from 'lodash';
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";
import ReactStars from 'react-rating-stars-component';
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardHeader, CardTitle, Col, Form, FormGroup, Input, Label, Row, Spinner, Tooltip } from 'reactstrap';
import { addUpdateEmployeeAppraisal, fetchEmployeeAppraisalByUserId } from "redux/actions/employeeAppraisalActions";
import { COLLABORATION, COMPLIANCE, INNOVATION, INTEGRITY, MAX_CHARACTER_SIZE } from "variables/constants";
import "./styles.css";

export const EmployeeAppraisal = (props) => {
    const { user } = useSelector(({ auth }) => auth);
    const { employeeAppraisal: employeeAppraisalData, employeeAppraisalList, loading } = useSelector(({ employeeAppraisal }) => employeeAppraisal);
    const notificationAlertRef = useRef(null);
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        accomplishments: '',
        accomplishmentsState: '',
        valuesCompanyPrioritized: '',
        valuesCompanyPrioritizedState: '',
        valuesCompanyPrioritizedNextYear: '',
        valuesCompanyPrioritizedNextYearState: '',
        topDevelopmentAreas: '',
        topDevelopmentAreasState: '',
        topDevelopmentAreasNextYear: '',
        topDevelopmentAreasNextYearState: '',
        year: moment().format('YYYY'),
        accomplishmentsRating: undefined,
        valuesCompanyPrioritizedRating: undefined,
        topDevelopmentAreasRating: undefined,
        topDevelopmentAreasNextYearRating: undefined,
        valuesCompanyPrioritizedNextYearRating: undefined
    });
    const [saveLoader, setSaveLoader] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false)

    const [innovationTooltipOpen, setInnovationTooltipOpen] = useState(false);
    const [collaborationtooltipOpen, setCollaborationtooltipOpen] = useState(false);
    const [integrityTooltipOpen, setIntegrityTooltipOpen] = useState(false);
    const [complianceTooltipOpen, setcomplianceTooltipOpen] = useState(false);

    const [innovationSecondTooltipOpen, setInnovationSecondTooltipOpen] = useState(false);
    const [collaborationSecontooltipOpen, setCollaborationSecondtooltipOpen] = useState(false);
    const [integritySecondTooltipOpen, setIntegritySecondTooltipOpen] = useState(false);
    const [compliancesecondTooltipOpen, setcomplianceSecondTooltipOpen] = useState(false);

    const [employeeAppraisalForManager, setEmployeeAppraisalForManager] = useState({
        "empAppraisalId": null,
        "cid": 0,
        "eid": null,
        "year": null,
        "accomplishmentsManagerRemark": "",
        "valuesCompanyPrioritizedManagerRemark": "",
        "topDevelopmentAreasManagerRemark": "",
        "topDevelopmentAreasNextYearManagerRemark": "",
        "valuesCompanyPrioritizedNextYearManagerRemark": "",
        "status": null,
        "attendance": "",
        "rating": "",
        "billable": "",
        "nonBillable": "",
        "accomplishmentsManagerRemarkRating": undefined,
        "valuesCompanyPrioritizedManagerRemarkRating": undefined,
        "topDevelopmentAreasManagerRemarkRating": undefined,
        "topDevelopmentAreasNextYearManagerRemarkRating": undefined,
        "valuesCompanyPrioritizedNextYearManagerRemarkRating": undefined,
        "employeeAverageRating": undefined
    })

    useEffect(() => {
        const { eid, cid } = user;
        let data = {
            cid: cid,
            eid: eid
        }
        dispatch(fetchEmployeeAppraisalByUserId(data))
    }, [])

    useEffect(() => {
        if (employeeAppraisalData?.employeeAppraisal) {
            let modifiedFormData = _.cloneDeep(formData);
            modifiedFormData = _.cloneDeep(employeeAppraisalData.employeeAppraisal);
            setFormData(modifiedFormData)
        }
        if (employeeAppraisalData?.employeeAppraisalForManager) {
            let employeeAppraisalForManagerData = _.cloneDeep(employeeAppraisalForManager);
            employeeAppraisalForManagerData = _.cloneDeep(employeeAppraisalData.employeeAppraisalForManager)
            setEmployeeAppraisalForManager(employeeAppraisalForManagerData)
        }

    }, [employeeAppraisalData?.employeeAppraisal]);


    const notify = (message, type) => {
        const options = {
            place: 'tc',
            message: (<div>{message}</div>),
            type: type,
            duration: 5,
            autoDismiss: true
        };

        notificationAlertRef.current.notificationAlert(options);
    };

    const onChangeTextHandler = (e) => {
        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const formValidation = () => {
        const { accomplishments, topDevelopmentAreas, topDevelopmentAreasNextYear, valuesCompanyPrioritized, valuesCompanyPrioritizedNextYear,
            accomplishmentsRating, valuesCompanyPrioritizedRating, topDevelopmentAreasRating, topDevelopmentAreasNextYearRating, valuesCompanyPrioritizedNextYearRating
        } = formData;

        if (topDevelopmentAreasNextYear.trim() === "" ||
            topDevelopmentAreas.trim() === "" ||
            valuesCompanyPrioritizedNextYear.trim() === "" ||
            valuesCompanyPrioritized.trim() === "" ||
            accomplishments.trim() === "") {
            notify('Enter all fields!', 'danger');
            return false;

        } else if (accomplishmentsRating === null || accomplishmentsRating === 0 ||
            valuesCompanyPrioritizedRating === null || valuesCompanyPrioritizedRating === 0 ||
            topDevelopmentAreasNextYearRating === null || topDevelopmentAreasNextYearRating === 0 ||
            valuesCompanyPrioritizedNextYearRating === null || valuesCompanyPrioritizedNextYearRating === 0 ||
            topDevelopmentAreasRating === null || topDevelopmentAreasRating === 0) {
            notify('Rate all fields!', 'danger');
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = async () => {

        let isFormValid = formValidation();

        if (isFormValid) {

            let data = {
                ...formData,
                "cid": user.cid,
                "eid": user.eid,
                "empAppraisalId": employeeAppraisalData.employeeAppraisal?.empAppraisalId ? employeeAppraisalData?.employeeAppraisal.empAppraisalId : 0,
                "status": "Submitted",
            }

            setSubmitLoader(true)
            let result = await dispatch(addUpdateEmployeeAppraisal(data));

            setSubmitLoader(false);
            if (result.success) {
                notify('Submitted appraisal successfully!', 'success');
            } else {
                notify('Failed to submit appraisal!', 'danger');
            }
        }
    }

    const handleSave = async () => {

        const { accomplishmentsRating, valuesCompanyPrioritizedRating, topDevelopmentAreasRating, topDevelopmentAreasNextYearRating, valuesCompanyPrioritizedNextYearRating, employeeAverageRating
        } = formData;

        let data = {
            ...formData,
            "cid": user.cid,
            "eid": user.eid,
            "empAppraisalId": employeeAppraisalData?.employeeAppraisal?.empAppraisalId ? employeeAppraisalData?.employeeAppraisal?.empAppraisalId : 0,
            "status": "New",
            "accomplishmentsRating": accomplishmentsRating === null ? 0 : accomplishmentsRating,
            "valuesCompanyPrioritizedRating": valuesCompanyPrioritizedRating === null ? 0 : valuesCompanyPrioritizedRating,
            "topDevelopmentAreasRating": topDevelopmentAreasRating === null ? 0 : topDevelopmentAreasRating,
            "topDevelopmentAreasNextYearRating": topDevelopmentAreasNextYearRating === null ? 0 : topDevelopmentAreasNextYearRating,
            "valuesCompanyPrioritizedNextYearRating": valuesCompanyPrioritizedNextYearRating === null ? 0 : valuesCompanyPrioritizedNextYearRating
        }

        setSaveLoader(true);
        let result = await dispatch(addUpdateEmployeeAppraisal(data));
        setSaveLoader(false)

        if (result.success) {
            notify('Saved appraisal successfully!', 'success');
        } else {
            notify('Failed to save appraisal!', 'danger');
        }
    }

    const getToolTipComponent = (state, setState, target, content) => {
        return (<Tooltip
            placement="top"
            isOpen={state}
            target={target}
            toggle={() => { setState(!state) }}
        >
            {content}
        </Tooltip>)
    }

    const getTooltip = (target, type, content) => {
        switch (target) {
            case 'Innovation':
                return getToolTipComponent(innovationTooltipOpen, setInnovationTooltipOpen, target, content);
            case 'Collaboration':
                return getToolTipComponent(collaborationtooltipOpen, setCollaborationtooltipOpen, target, content);
            case 'Integrity':
                return getToolTipComponent(integrityTooltipOpen, setIntegrityTooltipOpen, target, content);
            case 'Compliance':
                return getToolTipComponent(complianceTooltipOpen, setcomplianceTooltipOpen, target, content);
            case 'InnovationSecond':
                return getToolTipComponent(innovationSecondTooltipOpen, setInnovationSecondTooltipOpen, target, content);
            case 'CollaborationSecond':
                return getToolTipComponent(collaborationSecontooltipOpen, setCollaborationSecondtooltipOpen, target, content);
            case 'IntegritySecond':
                return getToolTipComponent(integritySecondTooltipOpen, setIntegritySecondTooltipOpen, target, content);
            case 'ComplianceSecond':
                return getToolTipComponent(compliancesecondTooltipOpen, setcomplianceSecondTooltipOpen, target, content);

            default:
                return null;
        }
    }
    const changeRating = (name, value) => {

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const { accomplishments, accomplishmentsState, valuesCompanyPrioritized, valuesCompanyPrioritizedState, valuesCompanyPrioritizedNextYear, valuesCompanyPrioritizedNextYearState,
        topDevelopmentAreas, topDevelopmentAreasState, topDevelopmentAreasNextYear, topDevelopmentAreasNextYearState, status,
        accomplishmentsRating, valuesCompanyPrioritizedRating, topDevelopmentAreasRating, topDevelopmentAreasNextYearRating,
        valuesCompanyPrioritizedNextYearRating, employeeAverageRating
    } = formData;

    const { accomplishmentsManagerRemark, valuesCompanyPrioritizedManagerRemark, valuesCompanyPrioritizedNextYearManagerRemark,
        topDevelopmentAreasManagerRemark, topDevelopmentAreasNextYearManagerRemark, billable, nonBillable, attendance,
        accomplishmentsManagerRemarkRating, valuesCompanyPrioritizedManagerRemarkRating, topDevelopmentAreasManagerRemarkRating,
        topDevelopmentAreasNextYearManagerRemarkRating, valuesCompanyPrioritizedNextYearManagerRemarkRating, managerAverageRating } = employeeAppraisalForManager;
    
    return (<>
        <div className="content">
            <Row>
                <Col xs={12} md={12}>
                    <Card>
                        <div className="rna-container">
                            <NotificationAlert ref={notificationAlertRef} />
                        </div>
                        {loading ? (
                            <div className="spinner-container"><Spinner size="sm" color="light" /></div>
                        ) : (
                            <CardHeader className="appraisal-container">
                                <CardTitle tag="h2" style={{ marginBottom: 25 }}>Appraisal</CardTitle>
                                <Form className="custom-form project-form" id="appraisalForm" >
                                    <Row xs="2">
                                        <Col md="9">
                                            <FormGroup className={`has-label`}>
                                                <Label className="form-label">List the three key accomplishments you've achieved this year along with details?*</Label>
                                                <Input
                                                    name="accomplishments"
                                                    type="textarea"
                                                    placeholder=""
                                                    value={accomplishments}
                                                    onChange={onChangeTextHandler}
                                                    rows={5}
                                                    disabled={status == "New" ? false : true}
                                                    maxLength={500}
                                                />
                                                <span className="subtext">{MAX_CHARACTER_SIZE}</span>
                                                {accomplishmentsState === "has-danger" ? (
                                                    <label className="error"> enter accomplishments!</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>

                                        <Col sm="3" className="rating-container">
                                            {accomplishmentsRating !== undefined &&
                                                <div tabIndex={-1} style={{ pointerEvents: status === 'New' ? 'all' : 'none' }}> <ReactStars
                                                    count={5}
                                                    value={accomplishmentsRating}
                                                    onChange={(value) => {
                                                        changeRating('accomplishmentsRating', value)
                                                    }}
                                                    size={30}
                                                    isHalf={true}
                                                    activeColor="gold"
                                                    isEditable={true}
                                                    name="accomplishmentsRating"
                                                    tabIndex={-1}
                                                    a11y={status === 'New' ? true : false}
                                                /></div>}
                                        </Col>
                                        {status === "Approved" && <>
                                            <Col md="9">
                                                <FormGroup className={`has-label`}>
                                                    <Label className="form-label">Manager's Remark</Label>
                                                    <Input
                                                        name="topDevelopmentAreasNextYearManagerRemark"
                                                        type="textarea"
                                                        placeholder=""
                                                        value={accomplishmentsManagerRemark}
                                                        rows={5}
                                                        style={{ resize: 'both' }}
                                                        maxLength={500}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="3" className="rating-container">
                                                <div style={{ pointerEvents: 'none' }}>
                                                    {accomplishmentsManagerRemarkRating !== undefined && <ReactStars
                                                        count={5}
                                                        value={accomplishmentsManagerRemarkRating}
                                                        size={30}
                                                        isHalf={true}
                                                        activeColor="gold"
                                                        isEditable={false}
                                                        a11y={false}
                                                    />}
                                                </div>
                                            </Col>
                                        </>
                                        }
                                    </Row>

                                    <Row xs="2">
                                        <Col md="9">
                                            <FormGroup className={`has-label`}>
                                                <Label className="form-label">List each of Peramal's core values (such as
                                                    <span id="Innovation"> Innovation,</span>{getTooltip('Innovation', innovationTooltipOpen, INNOVATION)}
                                                    <span id="Collaboration"> Collaboration,</span> {getTooltip('Collaboration', collaborationtooltipOpen, COLLABORATION)}
                                                    <span id="Integrity"> Integrity,</span> {getTooltip('Integrity', integrityTooltipOpen, INTEGRITY)}
                                                    <span id="Compliance"> Compliance)</span> {getTooltip('Compliance', collaborationtooltipOpen, COMPLIANCE)} have been the most significant to you over the past year along with details?*</Label>
                                                <Input
                                                    name="valuesCompanyPrioritized"
                                                    type="textarea"
                                                    placeholder=""
                                                    value={valuesCompanyPrioritized}
                                                    onChange={onChangeTextHandler}
                                                    rows={5}
                                                    disabled={status == "New" ? false : true}
                                                    maxLength={500}
                                                />
                                                <span className="subtext">{MAX_CHARACTER_SIZE}</span>
                                                {valuesCompanyPrioritizedState === "has-danger" ? (
                                                    <label className="error"> Enter valuesCompanyPrioritized!</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3" className="rating-container">
                                            {valuesCompanyPrioritizedRating !== undefined && <div style={{ pointerEvents: status === 'New' ? 'all' : 'none' }}>
                                                <ReactStars
                                                    count={5}
                                                    value={valuesCompanyPrioritizedRating}
                                                    onChange={(value) => {
                                                        changeRating('valuesCompanyPrioritizedRating', value)
                                                    }}
                                                    size={30}
                                                    isHalf={true}
                                                    activeColor="gold"
                                                    name="valuesCompanyPrioritizedRating"
                                                    a11y={status === 'New' ? true : false}
                                                /> </div>}
                                        </Col>
                                        {status === "Approved" && <> <Col md="9">
                                            <FormGroup className={`has-label`}>
                                                <Label className="form-label">Manager's Remark</Label>
                                                <Input
                                                    name="valuesCompanyPrioritizedManagerRemark"
                                                    type="textarea"
                                                    placeholder=""
                                                    value={valuesCompanyPrioritizedManagerRemark}
                                                    rows={5}
                                                    style={{ resize: 'both' }}
                                                    maxLength={500}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                            <Col sm="3" className="rating-container">
                                                <div style={{ pointerEvents: 'none' }}>
                                                    {valuesCompanyPrioritizedManagerRemarkRating !== undefined && <ReactStars
                                                        count={5}
                                                        value={valuesCompanyPrioritizedManagerRemarkRating}
                                                        onChange={changeRating}
                                                        size={30}
                                                        isHalf={true}
                                                        activeColor="gold"
                                                        a11y={false}
                                                    />}
                                                </div>
                                            </Col></>}
                                    </Row>
                                    <Row xs="2">
                                        <Col md="9">
                                            <FormGroup className={`has-label`}>
                                                <Label className="form-label">List your top three areas of development from the past year along with details*</Label>
                                                <Input
                                                    name="valuesCompanyPrioritizedNextYear"
                                                    type="textarea"
                                                    placeholder=""
                                                    value={valuesCompanyPrioritizedNextYear}
                                                    onChange={onChangeTextHandler}
                                                    rows={5}
                                                    disabled={status == "New" ? false : true}
                                                    maxLength={500}
                                                />
                                                <span className="subtext">{MAX_CHARACTER_SIZE}</span>
                                                {valuesCompanyPrioritizedNextYearState === "has-danger" ? (
                                                    <label className="error"> enter future valuesCompanyPrioritized!</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3" className="rating-container">
                                            {valuesCompanyPrioritizedNextYearRating !== undefined && <div style={{ pointerEvents: status === 'New' ? 'all' : 'none' }}>
                                                <ReactStars
                                                    count={5}
                                                    value={valuesCompanyPrioritizedNextYearRating}
                                                    onChange={(value) => {
                                                        changeRating('valuesCompanyPrioritizedNextYearRating', value)
                                                    }}
                                                    size={30}
                                                    isHalf={true}
                                                    activeColor="gold"
                                                    name="valuesCompanyPrioritizedNextYearRating"
                                                    a11y={status === 'New' ? true : false}
                                                /></div>}
                                        </Col>
                                        {status === "Approved" && <>    <Col md="9">
                                            <FormGroup className={`has-label`}>
                                                <Label className="form-label">Manager's Remark</Label>
                                                <Input
                                                    name="valuesCompanyPrioritizedNextYearManagerRemark"
                                                    type="textarea"
                                                    placeholder=""
                                                    value={valuesCompanyPrioritizedNextYearManagerRemark}
                                                    rows={5}
                                                    style={{ resize: 'both' }}
                                                    maxLength={500}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                            <Col sm="3" className="rating-container">
                                                <div style={{ pointerEvents: 'none' }}>
                                                    {valuesCompanyPrioritizedNextYearManagerRemarkRating !== undefined && <ReactStars
                                                        count={5}
                                                        value={valuesCompanyPrioritizedNextYearManagerRemarkRating}
                                                        onChange={changeRating}
                                                        size={30}
                                                        isHalf={true}
                                                        activeColor="gold"
                                                        a11y={false}
                                                    />}
                                                </div>
                                            </Col></>}
                                    </Row>
                                    <Row xs="2">
                                        <Col md="9">
                                            <FormGroup className={`has-label`}>
                                                <Label className="form-label">List your top three areas of focus for development in the coming year along with details *</Label>
                                                <Input
                                                    name="topDevelopmentAreas"
                                                    type="textarea"
                                                    placeholder=""
                                                    value={topDevelopmentAreas}
                                                    onChange={onChangeTextHandler}
                                                    rows={5}
                                                    disabled={status == "New" ? false : true}
                                                    maxLength={500}
                                                />
                                                <span className="subtext">{MAX_CHARACTER_SIZE}</span>
                                                {topDevelopmentAreasState === "has-danger" ? (
                                                    <label className="error"> enter development areas!</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3" className="rating-container">
                                            {topDevelopmentAreasRating !== undefined && <div style={{ pointerEvents: status === 'New' ? 'all' : 'none' }}>
                                                <ReactStars
                                                    count={5}
                                                    value={topDevelopmentAreasRating}
                                                    onChange={(value) => {
                                                        changeRating('topDevelopmentAreasRating', value)
                                                    }}
                                                    size={30}
                                                    isHalf={true}
                                                    activeColor="gold"
                                                    name="topDevelopmentAreasRating"
                                                    a11y={status === 'New' ? true : false}
                                                /></div>}
                                        </Col>
                                        {status === "Approved" && <> <Col md="9">
                                            <FormGroup className={`has-label`}>
                                                <Label className="form-label">Manager's Remark</Label>
                                                <Input
                                                    name="topDevelopmentAreasManagerRemark"
                                                    type="textarea"
                                                    placeholder=""
                                                    value={topDevelopmentAreasManagerRemark}
                                                    rows={5}
                                                    style={{ resize: 'both' }}
                                                    maxLength={500}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                            <Col sm="3" className="rating-container">
                                                <div style={{ pointerEvents: 'none' }}>
                                                    {topDevelopmentAreasManagerRemarkRating !== undefined && <ReactStars
                                                        count={5}
                                                        value={topDevelopmentAreasManagerRemarkRating}
                                                        onChange={changeRating}
                                                        size={30}
                                                        isHalf={true}
                                                        activeColor="gold"
                                                        a11y={false}
                                                    />}
                                                </div>
                                            </Col></>}
                                    </Row>
                                    <Row xs="2">
                                        <Col md="9">
                                            <FormGroup className={`has-label`}>
                                                <Label className="form-label">List each of Peramal's core values (such as
                                                    <span id="InnovationSecond"> Innovation,</span>{getTooltip('InnovationSecond', innovationTooltipOpen, INNOVATION)}
                                                    <span id="CollaborationSecond"> Collaboration,</span>  {getTooltip('CollaborationSecond', collaborationtooltipOpen, COLLABORATION)}
                                                    <span id="IntegritySecond"> Integrity,</span>  {getTooltip('IntegritySecond', integrityTooltipOpen, INTEGRITY)}
                                                    <span id="ComplianceSecond">Compliance)</span> {getTooltip('ComplianceSecond', collaborationtooltipOpen, COMPLIANCE)} do you anticipate prioritizing the most in the coming year along with details*</Label>
                                                <Input
                                                    name="topDevelopmentAreasNextYear"
                                                    type="textarea"
                                                    placeholder=""
                                                    value={topDevelopmentAreasNextYear}
                                                    onChange={onChangeTextHandler}
                                                    rows={5}
                                                    disabled={status == "New" ? false : true}
                                                    maxLength={500}
                                                />
                                                <span className="subtext">{MAX_CHARACTER_SIZE}</span>
                                                {topDevelopmentAreasNextYearState === "has-danger" ? (
                                                    <label className="error"> enter future development area!</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3" className="rating-container">
                                            {topDevelopmentAreasNextYearRating !== undefined && <div style={{ pointerEvents: status === 'New' ? 'all' : 'none' }}>
                                                <ReactStars
                                                    count={5}
                                                    value={topDevelopmentAreasNextYearRating}
                                                    onChange={(value) => {
                                                        changeRating('topDevelopmentAreasNextYearRating', value)
                                                    }}
                                                    size={30}
                                                    isHalf={true}
                                                    activeColor="gold"
                                                    name="topDevelopmentAreasNextYearRating"
                                                    a11y={status === 'New' ? true : false}
                                                /></div>}
                                        </Col>
                                        {status === "Approved" && <> <Col md="9">
                                            <FormGroup className={`has-label`}>
                                                <Label className="form-label">Manager's Remark</Label>
                                                <Input
                                                    name="topDevelopmentAreasNextYearManagerRemark"
                                                    type="textarea"
                                                    placeholder=""
                                                    value={topDevelopmentAreasNextYearManagerRemark}
                                                    rows={5}
                                                    style={{ resize: 'both' }}
                                                    maxLength={500}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                            <Col sm="3" className="rating-container">
                                                <div style={{ pointerEvents: 'none' }}>
                                                    {topDevelopmentAreasNextYearManagerRemarkRating !== undefined && <div style={{ pointerEvents: 'none' }}>
                                                        <ReactStars
                                                            count={5}
                                                            value={topDevelopmentAreasNextYearManagerRemarkRating}
                                                            onChange={changeRating}
                                                            size={30}
                                                            isHalf={true}
                                                            activeColor="gold"
                                                            a11y={false}
                                                        /></div>}
                                                </div>
                                            </Col></>}
                                    </Row>

                                    {status === "Approved" && <Row>
                                        <Col md="4">
                                            <FormGroup className={`has-label`}>
                                                <Label className="form-label">Billable % *</Label>
                                                <Input
                                                    name="billable"
                                                    type="number"
                                                    placeholder=""
                                                    value={billable}
                                                    onChange={onChangeTextHandler}
                                                    rows={5}
                                                    style={{ width: 300 }}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="4">
                                            <FormGroup className={`has-label`}>
                                                <Label className="form-label">Non Billable % *</Label>
                                                <Input
                                                    name="nonBillable"
                                                    type="number"
                                                    placeholder=""
                                                    value={nonBillable}
                                                    onChange={onChangeTextHandler}
                                                    rows={5}
                                                    style={{ width: 300 }}
                                                    disabled
                                                    maxLength={500}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>}
                                    {status === "Approved" && <Row xs="2">
                                        <Col md="4">
                                            <FormGroup className={`has-label`}>
                                                <Label className="form-label">Attendance % *</Label>
                                                <Input
                                                    name="attendance"
                                                    type="number"
                                                    placeholder=""
                                                    value={attendance}
                                                    onChange={onChangeTextHandler}
                                                    rows={5}
                                                    style={{ width: 300 }}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="4">
                                            <FormGroup className={`has-label`}>
                                                <Label className="form-label">Rating*</Label>
                                                <Input type="select" name="accountType" value={employeeAppraisalForManager
                                                    ?.rating} id="exampleSelect" style={{ width: 300 }}
                                                    disabled
                                                >
                                                    <option value={null}>select rating</option>
                                                    <option value="Needs Improvement">Needs Improvement</option>
                                                    <option value="Meets Expectation">Meets Expectation</option>
                                                    <option value="Exceeds Expectation">Exceeds Expectation</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>}
                                    <Row xs="2">
                                        <Col md="4" style={{ margin: '15px 0px' }}>
                                            Employee's Average Rating : <b>{employeeAverageRating === null ? 0 : employeeAverageRating}/5</b>
                                        </Col>
                                        {status === "Approved" &&
                                            <Col md="4" style={{ margin: '15px 0px' }}>
                                                Manager's Average Rating : <b>{managerAverageRating === null ? 0 : managerAverageRating}/5</b>
                                            </Col>}
                                    </Row>
                                </Form>
                                <div style={{ float: "right" }}>
                                    <Button md="2" color="info" size="sm" disabled={status == "New" ? false : true} onClick={handleSave}>
                                        {saveLoader ? (
                                            <Spinner size="sm" color="light" />
                                        ) : (
                                            'Save'
                                        )}</Button>
                                    <Button md="2" color="primary" size="sm" disabled={status == "New" ? false : true} onClick={handleSubmit}>
                                        {submitLoader ? (
                                            <Spinner size="sm" color="light" />
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </div>
                            </CardHeader>
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    </>)
}

export default EmployeeAppraisal;

