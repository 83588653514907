import {getEmployeePayrollAPI, addUpdateEmployeePayrollInfoAPI} from "../../services/employees-service";

export const FETCH_EMPLOYEE_PAYROLL_INFO = 'FETCH_EMPLOYEE_PAYROLL_INFO';
export const ADD_UPDATE_PAYROLL_INFO = 'ADD_UPDATE_PAYROLL_INFO';

export const getEmployeePayroll = (cid, eid) =>  {
    return async (dispatch) => {
        const response = await getEmployeePayrollAPI(cid, eid);
            if (response.data){
                dispatch({
                    type: FETCH_EMPLOYEE_PAYROLL_INFO,
                    payload: {
                      eid:eid,
                      payRollInfo: response.data
                  }
                  });
                return { success: true };
            }
            else{
                return { success: false};
            }    
        }
}

export const updateEmployeePayrollInfo = (employee) =>  {
  return async (dispatch) => {
      let data = {};
      const response = await addUpdateEmployeePayrollInfoAPI(employee);
          if (response.data){
              dispatch({
                  type: ADD_UPDATE_PAYROLL_INFO,
                  payload: {
                    eid:employee.eid,
                    payRollInfo: response.data
                }
                });
              return { success: true,data:response.data };
          }
          else{
              return { success: false};
          }    
      }
}