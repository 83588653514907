import { FETCH_ALL_VENDORS } from "../actions/employeeVendor";
const employeeVendorReducer = function (state = [], action) {
    switch (action.type) {
        case FETCH_ALL_VENDORS:
            return action.employeeVendors;
        default:
            return state;
    }
};

export default employeeVendorReducer;