import React from 'react';
import ErrorPage from '../components/ErrorPage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {

    return { error, hasError: true };
  }

  componentDidCatch(error, info) {
    if (typeof this.props.onError === 'function') {
      this.props.onError.call(this, error, info.componentStack);
    }
  }

  resetError = () => {
    this.setState({ error: null, hasError: false });
  };

  render() {
    return this.state.hasError ? (
      <ErrorPage
        title={"Oops! Something went wrong, Please try again."}
        description={""}
        resetError={this.resetError}
      />
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
