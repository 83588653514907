import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Input
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import EmployeeDetailsModal from "./EmployeeDetailsModal";
import { getAllEmployees, deleteEmployee } from "../../../redux/actions/employeeAction";
import Pagination from "../../../components/common/pagination";
import { getAllRoles } from "../../../redux/actions/rolesAction";
import { getAllDesignations } from "../../../redux/actions/designationAction";
import { getAllDepartments } from "../../../redux/actions/departmentActions";
import { getCompanyLocations } from "../../../redux/actions/companyLocationAction";
import { getEmployeesWithManagerRole } from "../../../redux/actions/managersAction";
import { getAllInitialCompanyConfigs } from "../../../redux/actions/companyConfigAction";
import { getAllEmployeesByAdmin } from "../../../redux/actions/adminEmployeesActions";
import { getAllCurrencies } from "../../../redux/actions/currenciesAction";
import { getAllemployeeVendors } from "../../../redux/actions/employeeVendor";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
import { AppContext } from '../../../hoc/AppContext';
import moment from "moment";
import { dateFormatList } from "variables/constants";
import { CSVLink } from "react-csv";
import { FaRegFileExcel } from "react-icons/fa";

const excelHeaders = [
  { label: 'Company Id.', key: 'cid' },
  { label: 'Employee Id.', key: 'eid' },
  { label: 'Active.', key: 'isActive' },
  { label: 'First Name', key: 'fname' },
  { label: 'Middle Name', key: 'mname' },
  { label: 'Last Name', key: 'lname' },
  { label: 'Date of birth', key: 'dob' },
  { label: 'Gender', key: 'gender' },
  { label: 'Aadhaar No.', key: 'aadhaarNo' },
  { label: 'Pan No.', key: 'panNo' },
  { label: 'Designation', key: 'designation' },
  { label: 'Mobile Number', key: 'phone1' },
  { label: 'Alternate Number', key: 'phone2' },
  { label: 'Email Official ', key: 'emailOfficial' },
  { label: 'Email Personal', key: 'emailPersonal' },
  { label: 'Present Address', key: 'addressCur' },
  { label: 'Permanent Address', key: 'addressPer' },
  { label: 'Zip Code', key: 'zipcode' },
  { label: 'Date of joining', key: 'doj' },
  { label: 'Last Date', key: 'terminationDate' },
  { label: 'Experience', key: 'cid' },
  { label: 'Resume', key: 'resume' },
  { label: 'User Image.', key: 'userImage' }
];


class EmployeeMaster extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isOpenStepper: false,
      isOpenEmpDetails: false,
      empId: -1,
      searchValue:"",
      employee: {
        addressCur: "",
        addressPer: "",
        cid: this.props.cid,
        doj: "",
        emailOfficial: "",
        emailPersonal: "",
        eid: "",
        fname: "",
        gender: "",
        id: 0,
        isActive: "Y",
        isDelete: "N",
        lname: "",
        mname: "",
        password: "",
        phone1: "",
        phone2: "",
        dob: "",
        experienceYear: null,
        experienceMonth: null,
        resume: "",
        userImage: "",
        zipcode: ""
      },
      formType: "Add",
      isLoading: false,
      isDataLoading: false,
      employeeReportCSV: 'Employees-Master-Report.csv',
    };
  }
  async UNSAFE_componentWillMount() {
    this.setState({
      isDataLoading: true
    })
    const response = await this.props.getAllEmployees(this.props.cid);
    if (response) {
      this.setState({
        isDataLoading: false
      })
    }
    this.props.getAllRoles(this.props.cid);
    this.props.getAllDesignations(this.props.cid);
    this.props.getAllDepartments(this.props.cid);
    this.props.getCompanyLocations(this.props.cid);
    this.props.getEmployeesWithManagerRole(this.props.cid);
    this.props.getAllInitialCompanyConfigs(this.props.cid);
    this.props.getAllEmployeesByAdmin(this.props.cid,this.state.searchValue);
    this.props.getAllCurrencies();
    this.props.getAllemployeeVendors(this.props.cid);
  }

   // this method call for set the value of the search value
   handleChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  }

  // this method call search employee in the table
  handeSearch = () => {
    this.props.getAllEmployeesByAdmin(this.props.cid, this.state.searchValue);
  }

  handeOpenModal = () => {
    let passProps = {
      pathname: 'add-update-employee',
      state: {
        newEmployee: this.state.employee,
        formType: 'Add'
      }
    }
    this.props.history.push(passProps);
  };
  addEmployee = (employee) => {
  }

  updateEmployee = (employee) => {
    // var foundIndex = this.state.employeeData.findIndex(x => x.empid == employee.empid);
    // this.state.employeeData[foundIndex] = employee;
  }

  openEmployeeModal = (key) => {
    let passProps = {
      pathname: 'add-update-employee',
      state: {
        empId: key,
        formType: 'Update'
      }
    }
    this.props.history.push(passProps);
  }

  openEmployeeDetails = (key) => {
    this.setState({
      isOpenEmpDetails: true,
      empId: key
    });
  }

  toggleEmployeeDetailsModal = (employee) => {
    this.setState({
      formType: 'Add',
      isOpenEmpDetails: !this.state.isOpenEmpDetails
    });
  }
  handeCloseModal = () => {
    this.setState({
      isOpenEmpDetails: false
    });
  }
  toggleEmployeeModal = (employee) => {
    this.setState({
      formType: 'Add',
      isOpenStepper: !this.state.isOpenStepper
    });
  }

  handleDelete = async (cid, eid) => {
    this.setState({
      isLoading: true
    })
    if (this.state.isLoading === false) {
      const response = await this.props.deleteEmployee(cid, eid);
      if (response.success) {
        let options = {};
        options = {
          place: "tr",
          message: 'Employee deleted successfully',
          type: "success",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3
        };
        if (this.refs.notificationAlert) {
          this.refs.notificationAlert.notificationAlert(options);
        }
        setTimeout(() => {
          this.setState({
            isLoading: false
          })
        }, 3000);
      } else {
        let options = {};
        options = {
          place: "tr",
          message: 'Error occurred while deleting employee.',
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3
        };
        if (this.refs.notificationAlert) {
          this.refs.notificationAlert.notificationAlert(options);
        }
        setTimeout(() => {
          this.setState({
            isLoading: false
          })
        }, 3000);
      }
    }

  }

  handleExportExcel = async (e, Type) => {
    
            
  }

  render() {
    const { config } = this.context;
    const data = this.props.adminEmployees.map((emp, key) => {
      return {
        index: key,
        id: emp.id,
        cid: emp.cid,
        empid: emp.eid,
        fname: emp.fname + " " + emp.lname,
        mname: emp.mname,
        lname: emp.lname,
        addressCur: emp.addressCur,
        addressPer: emp.addressPer,
        gender: emp.gender,
        birthDate: emp.birthDate,
        phone1: emp.phone1,
        phone2: emp.phone2,
        emailPersonal: emp.emailPersonal,
        emailOfficial: emp.emailOfficial,
        doj: emp.doj !== "" ? moment(emp.doj).format(dateFormatList[2]) : emp.doj,
        isActive: emp.isActive,
        dob: emp.dob,
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => { }}
              size="md"
              style={{ cursor: 'context-menu' }}
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              {emp.isActive === 'Y' ? <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span> :
                <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
              }
            </Button>{" "}
          </div>
        ),
        view: (
          // we've added some custom button actions
          <div className="actions-center">
            <Button
              onClick={() => {
                this.openEmployeeModal(emp.id);
              }}
              size="sm"
              id="tooltip209883"
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              <i className="tim-icons icon-pencil" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="tooltip209883"
              placement="left"
            >
              Edit
            </UncontrolledTooltip>
            <Button
              size="sm"
              style={{ marginLeft: 25, marginRight: 25 }}
              id="tooltip209882"
              onClick={() => {
                this.openEmployeeDetails(emp.id)
              }}
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              <i className="tim-icons icon-zoom-split" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="tooltip209882"
              placement="left"
            >
              View
            </UncontrolledTooltip>
            {/* <Button
              size="sm"
              id="tooltip209881"
              onClick={() => { this.handleDelete(emp.cid, emp.eid) }}
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
                })}
            >
                <i className="tim-icons icon-trash-simple" />
              </Button>
              <UncontrolledTooltip
                            delay={0}
                            target="tooltip209881"
                            placement="left"
                        >
                            Delete
                        </UncontrolledTooltip> */}
          </div>
        ),
      };
    });

    const getTrProps = (state, rowInfo) => {
      if (!rowInfo) return {};
  
      const isDisabled = rowInfo.original.isActive !== "Y"; // Example condition
      return {
        style: {
          backgroundColor: isDisabled ? "#00000030" : "white",
        },
      };
    };

    return (
      <>
        <div className="content">
          <div className="rna-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          {this.state.isOpenEmpDetails ?
            <EmployeeDetailsModal modal={this.state.isOpenEmpDetails} empId={this.state.empId} handeCloseModal={this.handeCloseModal} toggleEmployeeDetailsModal={this.toggleEmployeeDetailsModal} /> :

            null}
          <Row>
            <Col xs={12} md={12}>
              <Card>
              <CardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItem:'center' }}>
                  <CardTitle tag="h2">Employee Master Details</CardTitle>
                  <Row style={{justifyContent: 'flex-end'}}>
                  <Col md={6} style={{ marginTop: 4}}>
                  <Input
                  name="searchvalue"
                  placeholder="Employee name"
                  type="text"
                  maxLength="50"
                  value={this.state.searchValue}
                  onChange={this.handleChange}
                />
                </Col>
                <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 8 }}>
                <Button size="sm" color="info" onClick={this.handeSearch}>Search</Button>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 8 }}>
                    <Button size="sm" color="primary" onClick={this.handeOpenModal}>New</Button>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 15 }}>
                <UncontrolledTooltip
                                                delay={0}
                                                target="tooltip209893"
                                                placement="top"
                                            >
                                                Export To Excel
                                            </UncontrolledTooltip>
                                            <CSVLink data={this.props.adminEmployees}
                                                headers={excelHeaders}
                                                filename={this.state.employeeReportCSV}
                                                target="_blank"
                                                id="tooltip209893"
                                                onClick={(e) => this.handleExportExcel(e)}
                                            >
                                                <FaRegFileExcel className="custom-excel-icon" /></CSVLink>
                </div>
                </Row>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    PaginationComponent={Pagination}
                    data={data}
                    getTrProps={getTrProps}
                    resizable={true}
                    columns={[
                      {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        width: 50,
                      },
                      {
                        Header: "Emp No.",
                        accessor: "empid",
                        width: 100,
                      },
                      {
                        Header: "Full Name",
                        accessor: "fname",
                        width: 150,
                      },
                      {
                        Header: "Mobile No",
                        accessor: "phone1",
                        width: 120,
                      },
                      {
                        Header: "Email ID",
                        accessor: "emailOfficial",
                        width: 220,
                        show: true,
                      },
                      {
                        Header: "Join Date",
                        accessor: "doj",
                        width: 130,
                      }, {
                        Header: "",
                        accessor: "view",
                        sortable: false,
                        filterable: false,
                        className: 'text-center',
                        width: 130,
                      },
                    ]}
                    LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                    NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                    // pageSize={this.props.employees.length <= 10 ? this.props.employees.length : 10}
                    pageSize={this.props.employees.length <= config.paginationSize ? this.props.employees.length : config.paginationSize}
                    className="-striped -highlight project-table expense-table"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  getAllEmployees: bindActionCreators(getAllEmployees, dispatch),
  deleteEmployee: bindActionCreators(deleteEmployee, dispatch),
  getAllRoles: bindActionCreators(getAllRoles, dispatch),
  getAllDesignations: bindActionCreators(getAllDesignations, dispatch),
  getAllDepartments: bindActionCreators(getAllDepartments, dispatch),
  getCompanyLocations: bindActionCreators(getCompanyLocations, dispatch),
  getEmployeesWithManagerRole: bindActionCreators(getEmployeesWithManagerRole, dispatch),
  getAllInitialCompanyConfigs: bindActionCreators(getAllInitialCompanyConfigs, dispatch),
  getAllEmployeesByAdmin: bindActionCreators(getAllEmployeesByAdmin, dispatch),
  getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch),
  getAllemployeeVendors: bindActionCreators(getAllemployeeVendors, dispatch)
});

export const mapStateToProps = ({ auth, employees, adminEmployees }) => ({
  cid: auth.user.cid,
  employees: employees,
  adminEmployees: adminEmployees
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeMaster);
