import moment from 'moment';
import React, { Component } from "react";
import ReactTable from "react-table";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, CardTitle } from "reactstrap";
import Pagination from "../../components/common/pagination";

const dataTable = [
    [1, "101", "Johm Joe", 5, 3, 2,],
    [2, "102", "Steve Smith", 5, 3, 2,],
    [3, "103", "Sam Curran", 14, 5, 3,],
    [4, "104", "David Warner", 15, 5, 3],
    [5, "105", "Sam Billings", 8, 5, 3,]
];

class LeaveReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            empid: 'All',
            startDate: moment().startOf('isoweek').format('YYYY-MM-DD'),
            endDate: moment().endOf('isoweek').format('YYYY-MM-DD'),
            data: dataTable.map((prop, key) => {
                return {
                    empid: prop[1],
                    employeeName: prop[2],
                    PTO: prop[3],
                    medicalBalance: prop[4],
                    LWP: prop[5]
                };
            })
        };
    }


    handleEmployeeSelect = (e) => {
        let empid = e.target.value;
        // [e.target.name] = e.target.value;
        this.setState({
            // [e.target.name]: e.target.value,
            ...this.state,
            empid: empid
        });
    }



    handleFind = () => {
        let formatedData = dataTable.map((prop, key) => {
            return {
                empid: prop[1],
                employeeName: prop[2],
                PTO: prop[3],
                medicalBalance: prop[4],
                LWP: prop[5]
            };
        });

        let filteredData = formatedData.filter(dataItem => dataItem.empid === this.state.empid);

        this.setState({
            ...this.state,
            data: this.state.empid === "All" ? formatedData : filteredData
        });
    }



    render() {

        const myCustomNextText = () => {
            return
        };
        return (
            <>
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{}}>
                                    <CardTitle tag="h2" style={{ marginBottom: 20 }}>Leave Report Details</CardTitle>
                                    <Row >
                                        <Col md="2">
                                            <Label Label for="exampleSelect">Employee</Label>
                                            <FormGroup className={`has-label  `}>
                                                <Input type="select" name="empid" defaultValue={this.state.empid} onChange={this.handleEmployeeSelect} id="empid">
                                                    <option value="All">All</option>
                                                    <option value="101">John Doe</option>
                                                    <option value="102">Steve Smith</option>
                                                    <option value="103">Sam Curran</option>
                                                    <option value="104">David Warner</option>
                                                    <option value="105">Sam Billings</option>
                                                </Input>
                                                {this.state.clientIdState === "has-danger" ? (
                                                    <label className="error">please select client!</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>

                                        <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 8 }}>
                                            <Button size="sm" onClick={this.handleFind} color="info">find</Button>
                                        </div>
                                    </Row>


                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={this.state.data}
                                        // filterable
                                        // nextText={myCustomNextText}
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "Employee Name",
                                                accessor: "employeeName"
                                            },
                                            {
                                                Header: "PTO Balance",
                                                accessor: "PTO"
                                            },
                                            {
                                                Header: "Medical Balance",
                                                accessor: "medicalBalance",
                                                // width: 100,
                                            },
                                            {
                                                Header: "LWP Taken",
                                                accessor: "LWP",
                                                // width: 100,
                                            }
                                        ]}
                                        NoDataComponent={() => null}
                                        pageSize={dataTable.length <= 10 ? dataTable.length : 10}
                                        className="-striped -highlight project-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default LeaveReport;
