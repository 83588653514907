import "jspdf-autotable";
import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import AdminProjectMarginReport from "./AdminProjectMarginReport";
import ManagerProjectMarginReport from "./ManagerProjectMarginReport";

class ContractorAllocationReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false
        };
    }

    async UNSAFE_componentWillMount() {

        let isAdmin = this.props.user.roles.find(role => role === "Company Administrator");
        if (isAdmin !== undefined) {
            this.setState({
                isAdmin: true
            })
        } else {
            this.setState({
                isAdmin: false
            })
        }

    }
    async UNSAFE_componentWillReceiveProps(nextProps) {


    }


    render() {


        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>

                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{}}>
                                    {this.state.isAdmin === false ? (
                                        <ManagerProjectMarginReport />
                                    ) : (
                                            <AdminProjectMarginReport />
                                        )}
                                </CardHeader>
                                <CardBody>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


export const mapDispatchToProps = dispatch => ({

});


export const mapStateToProps = ({ auth, projects, client, companies, timesheet, timesheetReport, employees, designations, roles }) => {


    return {
        token: auth.token,
        user: auth.user,
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ContractorAllocationReport);
