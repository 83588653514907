import axios from 'axios';

export const getAllCurrenciesAPI = () => {
    return axios
      .get(`/api/v1/currencies`)
      .then(function (response) {
        if (response.data){
            return { success: true, data:response.data };
        }else{
            return { success: false};
        }
      })
      .catch(function (error) {  
        return { success: false };
    });
}