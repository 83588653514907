import { getTimesheetWeeklyReportAPI, getTimesheetMonthlyReportAPI, getTimesheetQuarterlyReportAPI, getRejectedTimesheetReportAPI } from '../../services/timesheets-service';
export const FETCH_TIMESHEET_WEEKLY_REPORT = 'FETCH_TIMESHEET_WEEKLY_REPORT';
export const FETCH_TIMESHEET_MONTHLY_REPORT = 'FETCH_TIMESHEET_MONTHLY_REPORT';
export const FETCH_TIMESHEET_QUARTERLY_REPORT = 'FETCH_TIMESHEET_QUARTERLY_REPORT';
export const FETCH_REJECTED_TIMESHEET_REPORT = 'FETCH_REJECTED_TIMESHEET_REPORT';
export const FETCH_REJECTED_TIMESHEET_REPORT_START = 'FETCH_REJECTED_TIMESHEET_REPORT_START';
export const FETCH_REJECTED_TIMESHEET_REPORT_SUCCESS = 'FETCH_REJECTED_TIMESHEET_REPORT_SUCCESS';
export const FETCH_REJECTED_TIMESHEET_REPORT_ERROR = 'FETCH_REJECTED_TIMESHEET_REPORT_ERROR';
export const RESET_REJECTED_TIMESHEET_REPORT = 'RESET_REJECTED_TIMESHEET_REPORT';


export const getTimesheetWeeklyReport = (cid, eid, startDate, endDate, status, quarter, month, year) => {
    return async (dispatch) => {
        const response = await getTimesheetWeeklyReportAPI(cid, eid, startDate, endDate, status, quarter, month, year);
        if (response.data) {
            dispatch({
                type: FETCH_TIMESHEET_WEEKLY_REPORT,
                timesheetWeeklyReport: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}


export const getTimesheetMonthlyReport = (cid, eid, startDate, endDate, status, quarter, month, year) => {
    return async (dispatch) => {
        const response = await getTimesheetMonthlyReportAPI(cid, eid, startDate, endDate, status, quarter, month, year);
        if (response.data) {
            dispatch({
                type: FETCH_TIMESHEET_MONTHLY_REPORT,
                timesheetMonthlyReport: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

export const getTimesheetQuarterlyReport = (cid, eid, startDate, endDate, status, quarter, month, year) => {
    return async (dispatch) => {
        const response = await getTimesheetQuarterlyReportAPI(cid, eid, startDate, endDate, status, quarter, month, year);
        if (response.data) {
            dispatch({
                type: FETCH_TIMESHEET_QUARTERLY_REPORT,
                timesheetQuarterlyReport: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

export const getRejectedTimesheetReport = (data) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_REJECTED_TIMESHEET_REPORT_START
        });
        const response = await getRejectedTimesheetReportAPI(data);
        if (response.data) {
            dispatch({
                type: FETCH_REJECTED_TIMESHEET_REPORT_SUCCESS,
                rejectedTimesheetReport: response.data
            });
            return { success: true };
        }
        else {
            dispatch({
                type: FETCH_REJECTED_TIMESHEET_REPORT_ERROR
            });
            return { success: false };
        }
    }
}

export const resetRejectedTimesheetReport = () => {
    return async (dispatch) => {
        dispatch({
            type: RESET_REJECTED_TIMESHEET_REPORT
        });
    }
}