import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';
import Switch from "react-switch";



class DepartmentModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'Add',
            department: {
                id: 0,
                cid: 0,
                department: '',
                isActive: 'Y',
            },
            clientOptions: [],
            departmentState: '',
            isActiveState: '',
            companyState: '',
            isActive: true
        };
    }



    UNSAFE_componentWillMount() {
        if (this.props.formType === "Update") {
            let isActive = this.props.department.isActive === "Y" ? true : false;
            this.setState({
                ...this.state,
                formType: this.props.formType,
                departmentState: "has-success",
                isActive: isActive,
                department: {
                    ...this.props.department,
                    cid: this.props.cid
                }
            });
        } else {
            this.setState({
                ...this.state,
                department: { ...this.state.department, cid: this.props.cid },
                formType: this.props.formType,
            })
        }
    }


    handleSubmit = async () => {
        let { department } = this.state.department;
        let checkDepartmentExist = false;
        let isUpdatedDeptAlreadyExist = false;
        if (this.props.formType === "Update") {
            let alreadyExist = this.props.departments.filter(deptItem => deptItem.department.toUpperCase() === department.trim().toUpperCase())
            isUpdatedDeptAlreadyExist = alreadyExist.length > 1 ? true : false;
            var deptCount = 0;

            this.props.departments && this.props.departments.map((deptItem) => {

                if (deptItem.department.toUpperCase() === department.trim().toUpperCase() && deptItem.id !== this.props.department.id) {
                    deptCount = deptCount + 1;
                }
            });

            if (deptCount >= 1) {
                isUpdatedDeptAlreadyExist = true;
            }

        } else {

            checkDepartmentExist = (this.props.departments.findIndex(deptItem => deptItem.department.toUpperCase() === department.trim().toUpperCase()) > -1 ? true : false);
        }


        if (this.state.departmentState === "has-danger") {
            this.setState({
                ...this.state,
                departmentState: "has-danger"
            });
        } else {
            if (checkDepartmentExist === true || isUpdatedDeptAlreadyExist === true) {
                this.setState({
                    ...this.state,
                    departmentState: "has-danger"
                });
            } else {
                this.props.onSubmit(this.state.department, this.props.formType);
            }
        }
    }

    onChangeTextHandler = (e) => {
        var lengthRex = /^[a-zA-Z\s]*$/;
        let department = this.state.department;
        department[e.target.name] = e.target.value;
        if (lengthRex.test(e.target.value)) {
            if (e.target.value.startsWith(' ')) {
                this.setState({
                    departmentState: "has-danger"
                })
            }
            else (
                this.setState({
                    departmentState: department.department === "" ? "has-danger" : "has-success",
                    department: department
                })
            )
        } else (
            this.setState({
                departmentState: "has-danger"
            })
        )



    }



    handleIsActive = (e) => {
        this.setState({
            ...this.state,
            isActive: e,
            department: {
                ...this.state.department,
                isActive: e === true ? "Y" : "N",
            }
        });
    }
    render() {


        let { departmentState } = this.state;
        let { department, cid, isActive } = this.state.department;
     
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="custom-modal "  >
                    <ModalHeader toggle={this.props.toggle}><h4>
                        {this.props.formType === "Update" ? "Update Department" : "Add New Department"}
                    </h4></ModalHeader>
                    <ModalBody>
                        <Form className="custom-form" id="LoginValidation">
                            <Row>
                                <Label md="3">Department*</Label>
                                <Col md="8">
                                    <FormGroup className={`has-label ${departmentState}`}>

                                        <Input
                                            name="department"
                                            type="text"
                                            placeholder="department name"
                                            value={department}
                                            onChange={this.onChangeTextHandler}
                                        />
                                        {this.state.departmentState === "has-danger" ? (
                                            <label className="error">please enter a valid and unique department name!</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Label md="3">Active </Label>
                                <Col md="8">
                                    <FormGroup>
                                        <label id="tooltip209851">
                                            <Switch
                                                onChange={(e) => { this.handleIsActive(e) }}
                                                name="isActive" id="isActive"
                                                checked={this.state.isActive}
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48} />
                                        </label>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target="tooltip209851"
                                            placement="right"
                                        >
                                            {this.state.isActive === true ? "Active" : "Inactive"}
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" size="sm" onClick={this.handleSubmit}>{this.props.formType}</Button>{' '}
                        <Button color="secondary" size="sm" onClick={this.props.close}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

}

export default DepartmentModal;