import { getCompanyContactsAPI } from '../../services/company-service';

export const FETCH_ALL_COMPANY_CONTACTS = 'FETCH_ALL_COMPANY_CONTACTS';

export const getCompanyContacts = (cid) => {
    return async (dispatch) => {
        const response = await getCompanyContactsAPI(cid);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_COMPANY_CONTACTS,
                contacts: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}