import moment from "moment";
import React from "react";
import ReactDatetime from "react-datetime";
import { Button, Col, FormGroup, Input, Row, Spinner } from "reactstrap";
import Select from "react-select";
import NotificationAlert from "react-notification-alert";


class AnnouncementForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: "",
            endDate: "",
            title: '',
            description: "",
            startDateState: "",
            endDateState: "",
            titleState: "",
            descriptionState: "",
            metadata: {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                title: "title",
                description: "description (max 200 characters)",
            },
            isLoading: false
        };
    }

    notify = (place, color, message) => {
        // var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };


    showNotifications = (color, message) => {
        this.notify("tr", color, message);
    }

    // function that returns true if value is email, false otherwise
    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };
    // function that verifies if a string has a given length or not
    verifyLength = (value, stateName, length) => {
        if (stateName === 'description') {
            //var lengthRex = /^[A-Za-z]*$/;
            var lengthRex = /^[a-zA-Z0-9\s,'.-]*$/;
            if (lengthRex.test(value)) {
                if (value.startsWith(' ') || value === '') {
                    return false;
                }
                return true;
            }
            return false;
        }
        if (stateName === 'title') {
            //var lengthRex = /^[A-Za-z]*$/;
            var lengthRex = /^[a-zA-Z\s,'-]*$/;
            if (lengthRex.test(value)) {
                if (value.startsWith(' ') || value === '') {
                    return false;
                }
                return true;
            }
            return false;
        }
        if (value.length <= length) {
            return false;
        }
        return true;
    };


    change = (event, stateName, type, stateNameEqualTo, maxValue) => {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "length":

                if (this.verifyLength(event.target.value, stateName, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    };

    selectValue = (e) => {

        let finalvalue = "";
        let value = e.target.value;

        if (this.state.leaveDays !== 0 && this.state.leaveDays > 1) {
            finalvalue = "F"
        } else {
            if (value === "H") {
                this.setState({
                    leaveDays: 0.5
                })
            }
            else {
                this.setState({
                    leaveDays: 1
                })
            }
            finalvalue = value
        }


        this.setState({ duration: finalvalue });
    };

    handleSelect = (e) => {
        this.setState({
            leaveType: e.target.value
        })
    }

    handleStartDate = (date) => {
        this.setState({
            startDateState: moment(date).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
            startDate: moment(date).format('YYYY-MM-DD')

        });
    }

    handleEndDate = (date) => {
        let diff = 0
        if (this.state.startDate !== "") {
            let date1 = new Date(date);
            let date2 = new Date(this.state.startDate);

            if (moment(date2).format('YYYY-MM-DD') <= moment(date1).format('YYYY-MM-DD')) {
                if (moment(date2).format('YYYY-MM-DD') !== moment(date1).format('YYYY-MM-DD')) {
                    const diffTime = Math.abs(date2 - date1);
                    diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    diff = diff + 1

                }
                else { diff = 1 }

                this.setState({
                    endDateState: moment(date).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
                    endDate: moment(date).format('YYYY-MM-DD'),
                    leaveDays: diff
                });
                this.setState({
                    duration: "F"
                })
            }
            else {
                this.showNotifications(3, "Improper input. Please check the seleced value!");
            }


        }
    }


    isValidated = async () => {
        if (
            this.state.startDateState === "has-success" &&
            this.state.endDateState === "has-success" &&
            this.state.titleState === "has-success" &&
            this.state.descriptionState === "has-success"
        ) {
            let id = 0;
            this.props.formtype === 'update' ? id = this.props.announcement.id : id = 0;
            let announcement = {
                cid: this.props.userData.cid,
                description: this.state.description,
                title: this.state.title,
                endDate: this.state.endDate,
                startDate: this.state.startDate,
                id: id,
            }

            this.props.addUpdateAnnouncement(announcement, this.props.formtype);
            this.setState({
                isLoading: false
            })
            return true;
        } else {
            if (this.state.startDateState !== "has-success") {
                this.setState({ startDateState: "has-danger" });
            }
            if (this.state.endDateState !== "has-success") {
                this.setState({ endDateState: "has-danger" });
            }
            if (this.state.titleState !== "has-success") {
                this.setState({ titleState: "has-danger" });
            }
            if (this.state.descriptionState !== "has-success") {
                this.setState({ descriptionState: "has-danger" });
            }
            this.setState({
                isLoading: false
            })
            return true;
        }

    };

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true
        })
        await this.isValidated();
    }




    async componentDidMount() {
       
        if (this.props.formtype === "update") {
            this.setState({
                startDate: moment(this.props.announcement.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.props.announcement.endDate).format('YYYY-MM-DD'),
                title: this.props.announcement.title,
                description: this.props.announcement.description,
                startDateState: "has-success",
                endDateState: "has-success",
                titleState: "has-success",
                descriptionState: "has-success"
            })
        } else {
            this.setState({
                startDate: "",
                endDate: "",
                duration: "",
                leaveDays: "",
                leaveType: "",
                reason: "",
            })
        }
    }

    render() {
        let metadata = this.state.metadata;
        return (
            <>
                <NotificationAlert ref="notificationAlert" />
                <div className="content">
                    <Col className="mr-auto ml-auto" md="12">

                        <div className="text-header text-center">Announcement Form</div>
                        <form onSubmit={this.handleSubmit} className="ticket-form">
                            <Row className="justify-content-center">
                                <Col sm="5">

                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Start Date*
                                        </label>
                                        <ReactDatetime
                                            timeFormat={false}
                                            inputProps={{ placeholder: metadata.startDate, className: "form-control", value: this.state.startDate }}
                                            onChange={this.handleStartDate}
                                            value={this.state.startDate}
                                            closeOnSelect={true}
                                        />
                                        {this.state.startDateState === "has-danger" ? (
                                            <label className="error">This field is required.</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col sm="5">
                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            End Date*
                                        </label>
                                        <ReactDatetime
                                            timeFormat={false}
                                            inputProps={{ placeholder: metadata.endDate, className: "form-control", value: this.state.endDate }}
                                            onChange={this.handleEndDate}
                                            closeOnSelect={true}
                                        />
                                        {this.state.endDateState === "has-danger" ? (
                                            <label className="error client_error">This field is required.</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col className="" sm="10">

                                    <FormGroup>
                                        <label htmlFor="password">
                                            Title*
                                        </label>
                                        <Input
                                            name={"title"}
                                            placeholder={metadata.title + " (required)"}
                                            disabled={false}
                                            type="text"
                                            onChange={e => this.change(e, "title", "length", 0)}
                                            onFocus={e => this.setState({ titleFocus: true })}
                                            onBlur={e => this.setState({ titleFocus: false })}
                                            value={this.state.title}
                                        />
                                        {this.state.titleState === "has-danger" ? (
                                            <label className="error client_error">This field is required.</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col className="subscriber" sm="10">
                                    <FormGroup>
                                        <label htmlFor="password">
                                            Description*
                                            </label>
                                        <Input
                                            name={"description"}
                                            placeholder={metadata.description}
                                            type="textarea"
                                            maxLength="200"
                                            onChange={e => this.change(e, "description", "length", 0)}
                                            onFocus={e => this.setState({ descriptionFocus: true })}
                                            onBlur={e => this.setState({ descriptionFocus: false })}
                                            value={this.state.description}
                                        />
                                        {this.state.descriptionState === "has-danger" ? (
                                            <label className="error client_error">This field is required.</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="justify-content-center mt-3">
                                {
                                    this.state.isLoading ? (
                                        <Button disabled="true" color="info" className="btn-submit btn-ticket-submit">
                                            <Spinner size="sm" />
                                        </Button>

                                    ) : (
                                            <Button size="sm" type="submit" color="info" className="btn-submit btn-ticket-submit">Submit</Button>
                                        )
                                }
                            </Row>
                        </form>
                    </Col></div>

            </>
        );
    }
}


export default AnnouncementForm
