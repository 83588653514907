import Pagination from "components/common/pagination";
import { UPDATE_EMPLOYEE_APPRAISAL, UPDATE_EMPLOYEE_APPRAISAL_ERROR, UPDATE_EMPLOYEE_APPRAISAL_SUCCESS, FETCH_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_SUCCESS, FETCH_EMPLOYEES_APPRAISAL_LIST, FETCH_EMPLOYEES_APPRAISAL_LIST_ERROR, FETCH_EMPLOYEES_APPRAISAL_LIST_SUCCESS, SET_EMPLOYEES_APPRAISAL_LIST_PAGINATION, RESET_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_SUCCESS } from "../actions/managerAppraisalActions";
import { FETCH_EMPLOYEE_APPRAISAL_BY_USER_ID_SUCCESS } from "redux/actions/employeeAppraisalActions";

const INIT_STATE = {
    employeeAppraisalList: [],
    employeeAppraisal: null,
    loading: false,
    pagination: {
        currentPage: 1,
        pageSize: 10,
        totalPages: 0,
        totalItems: 0,
    }
}

const managerAppraisalReducer = function (state = INIT_STATE, action) {
    switch (action.type) {

        case FETCH_EMPLOYEES_APPRAISAL_LIST: {
            return { ...state, loading: true };
        }
        case FETCH_EMPLOYEES_APPRAISAL_LIST_SUCCESS: {
            return { ...state, employeeAppraisalList: [...action.employeeAppraisalList], loading: false };
        }
        case FETCH_EMPLOYEES_APPRAISAL_LIST_ERROR:
            return { ...state, loading: false, employeeAppraisalList: [] };

        case SET_EMPLOYEES_APPRAISAL_LIST_PAGINATION: {
            return { ...state, error: '', message: '', pagination: { ...action.pagination } };
        }

        case UPDATE_EMPLOYEE_APPRAISAL: {
            return { ...state, loading: true };
        }

        case UPDATE_EMPLOYEE_APPRAISAL_SUCCESS: {
            return { ...state, employeeAppraisal: action.employeeAppraisal, loading: false };
        }

        case UPDATE_EMPLOYEE_APPRAISAL_ERROR: {
            return { ...state, loading: false };
        }


        case FETCH_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_SUCCESS:
            {
                return { ...state, employeeAppraisal: { ...action.employeeAppraisal }, loading: false };
            }

            case RESET_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_SUCCESS:
            {
                return { ...state, employeeAppraisal: null, loading: false };
            }
        default:
            return state;
    }

};

export default managerAppraisalReducer;