import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import moment from 'moment';
import {
  Card,
  CardBody,
  Input,
  Label,
  Form, FormGroup,
  Row,
  Col,
  Button, CardHeader, CardTitle, Table, Spinner, UncontrolledTooltip
} from "reactstrap";
import _ from 'lodash';
import { Redirect } from "react-router-dom";
import ReactDatetime from "react-datetime";
import NotificationAlert from "react-notification-alert";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Pagination from "../.././components/common/pagination";
import FileUpload from "../../components/CustomUpload/FileUpload";
import { REQUIRD_MESSAGE, dateFormatList } from "../../variables/constants";
import { uploadFile, deleteFile } from "../../services/index";
import { uniqueID } from "../../securityUtils/utils";
import { addUpdateEmployeeClaim, deleteClaimItem } from "../../redux/actions/claimsAction";
import { getAllClaimCategories } from "../../redux/actions/claimCategoriesAction";
import { verifyLength, verifyNumber, containsOnlyCharacters, checkMimeType, checkFileSize } from "../../components/common/validation";
import ViewAttachmentsModal from "./ViewAttachments";
import { getEmployeeAllProjects } from "../../redux/actions/employeeProjectsAction";

class CreateExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileData: [],
      imagePreviewUrl: "",
      employeeClaim: {},
      shortDescState: "",
      amountState: "",
      claimTypeState: "",
      pidState: "",
      claimId: 1,
      claimItems: [],
      formType: "Add",
      isLoading: false,
      uploadPendingAttachments: {},
      uploadProgress: 0,
      isOpenClaimModal: false,
      viewAttachments: [],
      itemIndex: -1
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  async UNSAFE_componentWillMount() {
    let employeeClaim = { ...this.props.employeeClaim };
    let claimItems = [];
    await this.props.getAllClaimCategories();
    employeeClaim.claimItems.map(item => {
      claimItems.push({
        ...item,
        attachmentsUUID: uniqueID()
      })
    })
    employeeClaim.claimItems = [...claimItems]
    this.setState({
      employeeClaim
    })
    if (this.props.employeeProjects.length === 0) {
      this.props.getEmployeeAllProjects(this.props.cid, this.props.eid)
    }
  }
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (verifyLength(event.target.value, stateNameEqualTo)) {
          let employeeClaim = { ...this.state.employeeClaim };
          employeeClaim[event.target.name] = event.target.value.trim();
          this.setState({
            employeeClaim: employeeClaim,
            [stateName + "State"]: "has-success"
          });
        } else {
          let employeeClaim = { ...this.state.employeeClaim };
          employeeClaim[event.target.name] = event.target.value
          this.setState({
            employeeClaim: employeeClaim,
            [stateName + "State"]: "has-danger"
          });
        }
        break;
      case "number":
        if (verifyNumber(event.target.value, stateNameEqualTo)) {
          let employeeClaim = { ...this.state.employeeClaim };
          employeeClaim[event.target.name] = parseInt(event.target.value);
          this.setState({
            employeeClaim: employeeClaim,
            [stateName + "State"]: "has-success"
          });
        } else {
          let employeeClaim = { ...this.state.employeeClaim };
          employeeClaim[event.target.name] = event.target.value;
          this.setState({
            employeeClaim: employeeClaim,
            [stateName + "State"]: "has-danger"
          });
        }
        break;
      case "sentense":
        if (containsOnlyCharacters(event.target.value, stateNameEqualTo)) {
          let employeeClaim = { ...this.state.employeeClaim };
          employeeClaim[event.target.name] = event.target.value;
          this.setState({
            employeeClaim: employeeClaim,
            [stateName + "State"]: "has-success"
          });
        } else {
          let employeeClaim = { ...this.state.employeeClaim };
          employeeClaim[event.target.name] = event.target.value;
          this.setState({
            employeeClaim: employeeClaim,
            [stateName + "State"]: "has-danger"
          });
        }
        break;
      default:
        break;
    }
  };

  handleMessage = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message.message,
      type: message.type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 2
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  handleSubmit = async () => {
    let { shortDesc, remark, pid, amount, claimType } = this.state.employeeClaim;
    let amtData = 0;
    if (this.state.employeeClaim && this.state.employeeClaim.claimItems) {
      for (let i = 0; i < this.state.employeeClaim.claimItems.length; i++) {
        let amt = this.state.employeeClaim.claimItems[i].amount;
        amtData = amtData + amt;
      }
      this.setState({
        employeeClaim: {
          amount: amtData.toFixed(2)
        },
      })
      let claimItems = []
      for (let i = 0; i < this.state.employeeClaim.claimItems.length; i++) {
        const claimData = this.state.employeeClaim.claimItems[i];
        claimData.claimType = this.state.employeeClaim.claimType;
        claimItems.push(claimData);
      }
      this.setState({
        employeeClaim: {
          ...this.state.employeeClaim,
          amount: amtData.toFixed(2),
          claimItems: claimItems,
        }
      }, async () => {
        if (shortDesc == "") {
          this.setState({ shortDescState: "has-danger" });
        }
        if (claimType == "") {
          this.setState({ claimTypeState: "has-danger" });
        }
        if (!pid || pid == 0) {
          this.setState({ pidState: "has-danger" });
        }
        if (shortDesc != "" && pid != 0) {
          if (this.state.isLoading === false) {
            this.setState({
              isLoading: true
            })
            if (this.state.shortDescState === "has-danger" || this.state.amountState === "has-danger" || this.state.pidState === "has-danger") {
              let message = {
                message: 'Please enter valid information',
                type: "warning",
              }
              this.handleMessage(message);
              setTimeout(() => {
                this.setState({
                  isLoading: false
                })
              }, 3000);
            }
            else {
              if (this.props.location.state.formType === "Add") {
                if (this.state.employeeClaim.claimItems != null && this.state.employeeClaim.claimItems.length > 0) {
                  let claimTypeValid = true;
                  let claimCategoryValid = true;
                  let amountValid = true;
                  let dateTransactionValid = true;
                  this.state.employeeClaim.claimItems.map((item) => {
                    claimTypeValid = item.claimType === "" ? false : true;
                    claimCategoryValid = item.claimCategoryId === 0 ? false : true;
                    amountValid = item.amount < 0 ? false : true;
                    dateTransactionValid = item.dateTransaction === "" ? false : true;
                  });
                  if (claimTypeValid && claimCategoryValid && amountValid && dateTransactionValid && this.state.shortDescState) {
                    const response = await this.props.addUpdateEmployeeClaim(this.state.employeeClaim);
                    if (response.success) {
                      let claimData = response.data;
                      this.setState({
                        employeeClaim: claimData
                      })
                      if (Object.keys(this.state.uploadPendingAttachments).length > 0) {
                        for (var x = 0; x < this.state.employeeClaim.claimItems.length; x++) {
                          let filePath = `${claimData.cid}/CLAIMS/${claimData.id}/${claimData.claimItems[x].id}`;
                          let attachments = this.state.uploadPendingAttachments[claimData.claimItems[x].attachmentsUUID];
                          if (attachments && attachments.length > 0) {
                            const data = new FormData()
                            for (let i = 0; i < attachments.length; i++) {
                              data.append('files', attachments[i]);
                            }
                            data.append('filePath', filePath);
                            const fileResponse = await uploadFile(data, this.onUploadProgress);
                            if (fileResponse.success) {
                              const urls = fileResponse.data;
                              const dataObj = this.state.employeeClaim.claimItems[x];
                              dataObj.attachments = JSON.stringify(urls);
                              let remaninguploadPendingAttachments = { ...this.state.uploadPendingAttachments }
                              delete remaninguploadPendingAttachments[claimData.claimItems[x].attachmentsUUID]
                              this.setState({
                                uploadPendingAttachments: { ...remaninguploadPendingAttachments },
                                ...this.state.employeeClaim,
                                claimItems: dataObj
                              })
                            }
                          }
                        }
                        let message = {
                          message: "Claim added successfully.",
                          type: "success",
                        };
                        this.handleMessage(message);
                        setTimeout(() => {
                          this.setState({
                            isLoading: false
                          })
                          let passProps = {
                            pathname: 'expensemanager',
                          }
                          this.props.history.push(passProps);
                        }, 3000);
                      } else {
                        let message = {
                          message: "Claim added successfully.",
                          type: "success",
                        };
                        this.handleMessage(message);
                        setTimeout(() => {
                          this.setState({
                            isLoading: false
                          })
                          let passProps = {
                            pathname: 'expensemanager',
                          }
                          this.props.history.push(passProps);
                        }, 3000);
                      }
                    } else {
                      let message = {
                        message: 'Error occurred while adding claim.',
                        type: "warning",
                      };
                      this.handleMessage(message);
                      setTimeout(() => {
                        this.setState({
                          isLoading: false
                        })
                      }, 3000);
                    }
                  } else {
                    let message = {
                      message: "Please enter valid claim items data!",
                      type: "warning",
                    };
                    this.handleMessage(message);
                    setTimeout(() => {
                      this.setState({
                        isLoading: false
                      })
                    }, 3000);
                  }
                }
              } else {
                let claimTypeValid = true;
                let claimCategoryValid = true;
                let amountValid = true;
                let dateTransactionValid = true;
                this.state.employeeClaim.claimItems.map((item) => {
                  claimTypeValid = item.claimType === "" ? false : true;
                  claimCategoryValid = item.claimCategoryId === 0 ? false : true;
                  amountValid = item.amount < 0 ? false : true;
                  dateTransactionValid = item.dateTransaction === "" ? false : true;
                });
                if (claimTypeValid && claimCategoryValid && amountValid && dateTransactionValid) {
                  let employeeClaimTemp = this.state.employeeClaim;
                  const response = await this.props.addUpdateEmployeeClaim(this.state.employeeClaim);
                  if (response.success) {
                    let claimData = response.data;
                    this.setState({
                      employeeClaim: claimData
                    })
                    if (Object.keys(this.state.uploadPendingAttachments).length > 0) {
                      for (var x = 0; x < this.state.employeeClaim.claimItems.length; x++) {
                        let filePath = `${claimData.cid}/CLAIMS/${claimData.id}/${claimData.claimItems[x].id}`;
                        // let attachments = this.state.uploadPendingAttachments[claimData.claimItems[x].attachmentsUUID];
                        let attachments = this.state.uploadPendingAttachments[employeeClaimTemp.claimItems[x].attachmentsUUID];
                        if (attachments && attachments.length > 0) {
                          const data = new FormData()
                          for (let i = 0; i < attachments.length; i++) {
                            data.append('files', attachments[i]);
                          }
                          data.append('filePath', filePath);
                          const fileResponse = await uploadFile(data, this.onUploadProgress);
                          if (fileResponse.success) {
                            const urls = fileResponse.data;
                            const dataObj = this.state.employeeClaim.claimItems[x];
                            dataObj.attachments = JSON.stringify(urls);
                            let remaninguploadPendingAttachments = { ...this.state.uploadPendingAttachments }
                            delete remaninguploadPendingAttachments[claimData.claimItems[x].attachmentsUUID]
                            this.setState({
                              uploadPendingAttachments: { ...remaninguploadPendingAttachments },
                              ...this.state.employeeClaim,
                              claimItems: dataObj
                            })
                          }
                        }
                      }
                      let message = {
                        message: "Claim updated successfully.",
                        type: "success",
                      };
                      this.handleMessage(message);
                      setTimeout(() => {
                        this.setState({
                          isLoading: false
                        })
                        let passProps = {
                          pathname: 'expensemanager',
                        }
                        this.props.history.push(passProps);
                      }, 3000);
                    } else {
                      let message = {
                        message: "Claim updated successfully.",
                        type: "success",
                      };
                      this.handleMessage(message);
                      setTimeout(() => {
                        this.setState({
                          isLoading: false
                        })
                        let passProps = {
                          pathname: 'expensemanager',
                        }
                        this.props.history.push(passProps);
                      }, 3000);
                    }
                  } else {
                    let message = {
                      message: 'Error occurred while updating claim.',
                      type: "warning",
                    };
                    this.handleMessage(message);
                    setTimeout(() => {
                      this.setState({
                        isLoading: false
                      })
                    }, 3000);
                  }
                } else {
                  let message = {
                    message: "Please enter valid claim items data!",
                    type: "warning",
                  };
                  this.handleMessage(message);
                  setTimeout(() => {
                    this.setState({
                      isLoading: false
                    })
                  }, 3000);
                }
              }
            }
          }
        }
      })
    }
  };
  onUploadProgress = (progressEvent) => {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    this.setState({ uploadProgress: percentCompleted });
  };
  handleNewClaimItem = (e, workDate) => {
    let dataItem = {
      id: 0,
      claimId: this.state.employeeClaim.id,
      eid: this.props.eid,
      cid: this.props.cid,
      claimCategoryId: 0,
      claimType: '',
      currencyId: 2,
      amount: 0,
      dateTransaction: '',
      dateApproved: '',
      dateRejected: '',
      rejectionNote: '',
      attachments: '',
      attachmentsUUID: uniqueID()
    }
    let data = this.state.employeeClaim.claimItems;
    data.push(dataItem);
    this.setState({
      ...this.state.employeeClaim,
      claimItems: data
    });
  }
  toggleClaimItemsModal = (index, attachments) => {
    if (attachments != "" || null || []) {
      this.setState({
        isOpenClaimModal: !this.state.isOpenClaimModal,
        viewAttachments: JSON.parse(attachments),
        itemIndex: index
      });
    } else {
      this.setState({
        isOpenClaimModal: !this.state.isOpenClaimModal,
        viewAttachments: []
      });
    }

  }
  onClose = () => {
    this.setState({
      isOpenClaimModal: false,
    });
  }
  handleDeleteItem = async (index, claim) => {
    if (claim.id > 0) {
      let claimItems = [...this.state.employeeClaim.claimItems];
      claimItems.splice(index, 1);
      const response = await this.props.deleteClaimItem(claim.id);
      if (response.success) {
        let message = {
          message: "Claim item deleted successfully.",
          type: "success",
        };
        this.handleMessage(message);
      } else {
        let message = {
          message: 'Error occurred while deleting claim item.',
          type: "warning",
        };
        this.handleMessage(message);
      }
    } else {
      let claimItems = [...this.state.employeeClaim.claimItems];
      claimItems.splice(index, 1);
      let message = {
        message: "Claim item deleted successfully.",
        type: "success",
      };
      this.handleMessage(message);
      this.setState({
        employeeClaim: {
          ...this.state.employeeClaim,
          claimItems: claimItems
        }
      });
    }
  }

  handleSelectDate = (date, index) => {
    let data = [...this.state.employeeClaim.claimItems];
    let newDate = moment(date).format('YYYY-MM-DD');
    data[index]['dateTransaction'] = newDate;
    this.setState({
      employeeClaim: {
        ...this.state.employeeClaim,
        claimItems: [...data]
      }
    });
  }
  getClaimItemUploadPendingAttachments = (attachmentsUUID) => {
    const claimItemUploadPendingAttachments = this.state.uploadPendingAttachments[attachmentsUUID] ? this.state.uploadPendingAttachments[attachmentsUUID] : [];
    return claimItemUploadPendingAttachments.length;
  }

  renderTableData = (claimCategories) => {
    return this.state.employeeClaim.claimItems.map((claim, index) => {
      const currentClaimItemUploadPendingAttachments = this.getClaimItemUploadPendingAttachments(claim.attachmentsUUID);
      const existingAttachments = claim.attachments && claim.attachments != "" ? JSON.parse(claim.attachments) : [];
      const totalAttachmentsCount = currentClaimItemUploadPendingAttachments + existingAttachments.length;

      return (
        <tr style={{ borderBottom: '0.1px solid gainsboro' }}>
          <td style={{ width: '6%', borderTop: 0 }}>
            {index + 1 + "."}
          </td>
          {/* <td style={{width: '18%',borderTop: 0 }}>
              <FormGroup className={`has-label ${this.state.employeeState} `} style={{ paddingTop: 10, marginBottom: 10 }}>
              <Input
                  name="claimType"
                  placeholder="Claim Type..."
                  type="select"
                  defaultValue={claim.claimType}
                  value={claim.claimType}
                  onChange={e => this.handleOnChange(e, index)}
                  ><option value="" disabled>Select type</option>
                  <option value="Advance">Advance</option>
                  <option value="Reimbursement">Reimbursement</option>  
              </Input>
              </FormGroup>
              </td> */}
          <td style={{ width: '25%', borderTop: 0 }}>
            <FormGroup className={`has-label ${this.state.employeeState} `} style={{ paddingTop: 10, marginBottom: 10 }}>
              <Input
                name="claimCategoryId"
                placeholder="Category..."
                type="select"
                defaultValue={claim.claimCategoryId}
                value={claim.claimCategoryId}
                onChange={e => this.handleOnChange(e, index)}
              ><option value={0} disabled>Select Category</option>
                {this.props.claimCategories.map((categories, index) => {
                  return (<option key={index} value={categories.id}>{categories.category}</option>)
                })}
              </Input>
            </FormGroup>
          </td>
          <td style={{ width: '25%', borderTop: 0 }}  >
            <FormGroup className={`has-label ${this.state.employeeState} `} style={{ paddingTop: 10, marginBottom: 10 }}>
              <Input
                name="amount"
                placeholder="Amount..."
                type="number"
                maxLength="10"
                value={claim.amount}
                onChange={e => this.handleOnChange(e, index)}
              />
            </FormGroup>
          </td>
          <td style={{ width: '25%', borderTop: 0 }}>
            <FormGroup className={`has-label ${this.state.employeeState} `} style={{ paddingTop: 10, marginBottom: 10 }}>
              <ReactDatetime
                timeFormat={false}
                isValidDate={this.state.employeeClaim.claimType == "Reimbursement" ? this.valid : null}
                name="dateTransaction"
                inputProps={{ placeholder: "Transaction Date", className: "form-control", value: claim.dateTransaction !== "" ? moment(claim.dateTransaction).format(dateFormatList[2]) : claim.dateTransaction }}
                value={claim.dateTransaction}
                onChange={(e) => this.handleSelectDate(e, index)}
                closeOnSelect={true}
                className="datePicker"
              />
            </FormGroup>
          </td>
          <td style={{ width: '10%', borderTop: 0 }} >
            <FileUpload
              addBtnColor="default"
              changeBtnColor="default"
              accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
              comp='expense'
              handleImageChange={e => {
                e.preventDefault();
                this.handleImageChange(e, index, claim)
              }}
              handleClick={this.handleClick}
              handleRemove={this.handleRemove}
              imagePreviewUrl={this.state.imagePreviewUrl}
              file={claim.attachments}
              attachmentsCount={totalAttachmentsCount}
            />
          </td>
          {claim.attachments && claim.attachments != "" ?
            <td style={{ width: '10%', borderTop: 0 }} >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                  <Button
                    id="tooltip209891"
                    onClick={e => { this.toggleClaimItemsModal(index, claim.attachments) }}
                    color=""
                    size="sm"
                    className="btn-icon btn-link like"
                  >
                    <i className="tim-icons icon-attach-87" />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target="tooltip209891"
                    placement="top"
                  >
                    View Attachments
                  </UncontrolledTooltip>
                </div>
                <div style={{ flex: 1 }}>
                  <span>{!claim.attachments || claim.attachments == "" ? 0 : JSON.parse(claim.attachments).length}</span>
                </div>
              </div>
            </td>
            :
            <td style={{ width: '10%', borderTop: 0 }} >
              <div id="tooltip209892" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ flex: 1 }}  >
                  <Button
                    id="tooltip209892"
                    color=""
                    size="sm"
                    className="btn-icon btn-link like"
                    disabled
                  >
                    <i className="tim-icons icon-attach-87" />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target="tooltip209892"
                    placement="top"
                  >
                    No Attachments
                  </UncontrolledTooltip>
                </div>
                <div style={{ flex: 1 }}>
                  <span>{!claim.attachments || claim.attachments == "" ? 0 : JSON.parse(claim.attachments).length}</span>
                </div>
              </div>
            </td>
          }
          {this.state.employeeClaim.claimItems.length < 10 ?
            <td style={{ borderTop: 0 }} >
              <Button
                onClick={e => { this.handleNewClaimItem(e) }}
                color=""
                size="sm"
                id="tooltip209552"
                className="btn-icon btn-link like"
              >
                <i className="tim-icons icon-simple-add" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip209552"
                placement="top"
              >
                Add
              </UncontrolledTooltip>
            </td>
            : null}
          {this.state.employeeClaim.claimItems.length > 1 ?
            <td style={{ borderTop: '0.1px solid gainsboro' }} >
              <Button
                onClick={e => { this.handleDeleteItem(index, claim) }}
                color=""
                size="sm"
                id="tooltip20953"
                className="btn-icon btn-link like"
              >
                <i className="tim-icons icon-trash-simple" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip20953"
                placement="top"
              >
                Remove
              </UncontrolledTooltip>
            </td>
            : <div></div>}
        </tr>
      )
    })
  }

  renderTableHeader = () => {
    return (
      <tr style={{ borderBottom: '0.1px solid gainsboro', backgroundColor: '#afadad' }}>
        <th style={{ width: '6%' }}></th>
        <th style={{ width: '25%', color: 'white' }}>Category</th>
        <th style={{ width: '25%', color: 'white' }}>Amount</th>
        <th style={{ width: '25%', color: 'white' }}>Transaction Date</th>
        <th style={{ width: '10%', color: 'white' }}>Attachment</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    )
  }
  handleImageChange = async (e, index, claim) => {
    let checkMimeTypeValid = false;
    const types = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'image/png', 'image/jpeg']
    checkMimeTypeValid = checkMimeType(e, types);
    let checkFileSizeValid = false;
    checkFileSizeValid = checkFileSize(e);
    if (checkMimeTypeValid && checkFileSizeValid) {
      if (this.props.location.state.formType === "Add") {
        if (claim.id > 0) {
          let filePath = `${claim.cid}/CLAIMS/${claim.claimId}/${claim.id}`;
          let files = e.target.files;
          const data = new FormData()
          for (let i = 0; i < files.length; i++) {
            data.append('files', files[i]);
          }
          data.append('filePath', filePath);
          const fileResponse = await uploadFile(data, this.onUploadProgress);
          if (fileResponse.success) {
            const urls = fileResponse.data;
            const dataObj = this.state.employeeClaim.claimItems;
            var fileObj = dataObj[index].attachments ? JSON.parse(dataObj[index].attachments) : [];
            fileObj = [...fileObj, ...urls]
            dataObj[index].attachments = JSON.stringify(fileObj);
            // const remaninguploadPendingAttachments = this.state.uploadPendingAttachments.filter(attachments =>attachments.index != index);
            this.setState({
              employeeClaim: {
                ...this.state.employeeClaim,
                claimItems: dataObj
              },
              // uploadPendingAttachments:[...remaninguploadPendingAttachments]
            })
          }
        } else {
          let files = e.target.files;
          let newUploadPendingAttachments = { ...this.state.uploadPendingAttachments };
          let attachments = newUploadPendingAttachments[claim.attachmentsUUID] ? [...newUploadPendingAttachments[claim.attachmentsUUID], ...files] : [...files];
          newUploadPendingAttachments[claim.attachmentsUUID] = attachments;
          this.setState({
            uploadPendingAttachments: newUploadPendingAttachments
          })
        }
      } else {
        if (claim.id > 0) {
          let filePath = `${claim.cid}/CLAIMS/${claim.claimId}/${claim.id}`;
          let files = e.target.files;
          const data = new FormData()
          for (let i = 0; i < files.length; i++) {
            data.append('files', files[i]);
          }
          data.append('filePath', filePath);
          const fileResponse = await uploadFile(data, this.onUploadProgress);
          if (fileResponse.success) {
            const urls = fileResponse.data;
            const dataObj = this.state.employeeClaim.claimItems;
            var fileObj = dataObj[index].attachments ? JSON.parse(dataObj[index].attachments) : [];
            fileObj = [...fileObj, ...urls]
            dataObj[index].attachments = JSON.stringify(fileObj);
            let remaninguploadPendingAttachments = { ...this.state.uploadPendingAttachments }
            delete remaninguploadPendingAttachments[claim.attachmentsUUID]
            this.setState({
              employeeClaim: {
                ...this.state.employeeClaim,
                claimItems: dataObj
              },
              uploadPendingAttachments: { ...remaninguploadPendingAttachments }
            })
          }
        } else {
          let files = e.target.files;
          let newUploadPendingAttachments = { ...this.state.uploadPendingAttachments };
          let attachments = newUploadPendingAttachments[claim.attachmentsUUID] ? [...newUploadPendingAttachments[claim.attachmentsUUID], ...files] : [...files];
          newUploadPendingAttachments[claim.attachmentsUUID] = attachments;
          this.setState({
            uploadPendingAttachments: newUploadPendingAttachments
          })
        }
      }
    } else {
      if (checkMimeTypeValid == false) {
        let message = {
          message: 'Uploded attachments is not a supported format!',
          type: "warning",
        }
        this.handleMessage(message);
      } if (checkFileSizeValid == false) {
        let message = {
          message: 'Uploded attachments size is too large, please pick a smaller file!',
          type: "warning",
        }
        this.handleMessage(message);
      }
    }

  }
  valid = (current) => {
    var tommorrow = moment().add(0, 'day');
    return moment(current).isBefore(tommorrow);
  };
  delete = async (key, prop, itemIndex) => {
    const response = await deleteFile(prop);
    const claimAttachments = this.state.employeeClaim.claimItems;
    const dObj = claimAttachments[itemIndex].attachments === "" ? [] : JSON.parse(claimAttachments[itemIndex].attachments);
    dObj.splice(key, 1);
    claimAttachments[itemIndex].attachments = JSON.stringify(dObj);
    this.setState({
      viewAttachments: dObj,
      employeeClaim: {
        ...this.state.employeeClaim,
        claimItems: claimAttachments
      }
    })
  }

  handleOnChange = (e, index) => {
    let data = this.state.employeeClaim.claimItems;
    let dObj = data[index];
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'claimCategoryId' || name === 'amount') {
      dObj[name] = parseInt(value);
    } else {
      dObj[name] = value;
    }
    this.setState({
      ...this.state.employeeClaim,
      claimItems: data
    });
  }

  handleClick = (refs) => {
    refs.fileInput.click();
  }


  handleRemove = () => {
    this.setState({
      file: null,
      imagePreviewUrl: ""
    });
  }
  handleCancel = () => {
    let passProps = {
      pathname: 'expensemanager',
    }
    this.props.history.push(passProps);
  }

  render() {
    let claimCategories = this.props.claimCategories.map((categories, index) => {
      return (<option key={index} value={categories.id}>{categories.category}</option>)
    });
    let activeProjects = this.props.employeeProjects.map((project, index) => {
      return (<option key={index} value={project.id} disabled={project.isActive === "N" ? true : false}>{project.project}</option>)
    });

    if (!this.props.employeeClaim) {
      let user = this.props.user;
      if (user && user.roles) {
        if (user.roles[0] === 'Manager') {
          return <Redirect to="/manager/expensemanager" />
        } else if (user.roles[0] === "Employee") {
          return <Redirect to="/employee/expensemanager" />
        } else {
          return <Redirect to="/admin/expensemanager" />
        }
      }
    }
    return (
      <>
        <div className="content">
          <ViewAttachmentsModal modal={this.state.isOpenClaimModal} itemIndex={this.state.itemIndex} attachments={this.state.viewAttachments} delete={this.delete} toggle={this.toggleClaimItemsModal} close={this.onClose} />
          <div className="rna-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          <Form className="custom-form" id="LoginValidation">
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '100px' }}>
                        <Label>Date *</Label>
                        <FormGroup className={`has-label ${this.state.dateSubmittedState}`}>
                          <ReactDatetime
                            timeFormat={false}
                            name="dateSubmitted"
                            inputProps={{ placeholder: "Date of joining", className: "form-control", value: moment(new Date).format(dateFormatList[2]), disabled: true }}
                            value={this.state.employeeClaim.dateSubmitted == "" ?
                              moment(new Date).format('YYYY-MM-DD') : this.state.employeeClaim.dateSubmitted}
                            onChange={this.handleStartDate}
                            closeOnSelect={true}
                          />
                          {this.state.dateSubmittedState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}

                        </FormGroup>
                      </Col>
                      <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '100px' }}>
                        <Label>Amount *</Label>
                        <FormGroup className={`has-label ${this.state.amountState}`}>
                          <Input
                            disabled
                            name="amount"
                            placeholder="Amount..."
                            type="number"
                            pattern="\d*"
                            value={this.state.employeeClaim.amount}
                            onChange={e => this.change(e, "amount", "number", 1)}
                            onFocus={e => this.setState({ amountFocus: true })}
                            onBlur={e => this.setState({ amountFocus: false })}
                          />
                          {this.state.amountState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '100px' }}>
                        <Label>Description *</Label>
                        <FormGroup className={`has-label ${this.state.shortDescState}`}>
                          <Input
                            name="shortDesc"
                            placeholder="Description..."
                            type="text"
                            value={this.state.employeeClaim.shortDesc}
                            onChange={e => this.change(e, "shortDesc", "sentense", 1)}
                            onFocus={e => this.setState({ shortDescFocus: true })}
                            onBlur={e => this.setState({ shortDescFocus: false })}
                            disabled={this.props.formType === "Update" ? true : false}
                          />
                          {this.state.shortDescState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '100px' }}>
                        <Label>Project *</Label>
                        <FormGroup className={`has-label ${this.state.pidState}`}>
                          <Input
                            name="pid"
                            placeholder="Project..."
                            type="select"
                            defaultValue={!this.state.employeeClaim.pid || this.state.employeeClaim.pid == 0 ? "" : this.state.employeeClaim.pid}
                            value={!this.state.employeeClaim.pid || this.state.employeeClaim.pid == 0 ? "" : this.state.employeeClaim.pid}
                            onChange={e => this.change(e, "pid", "number")}
                            onFocus={e => this.setState({ pidFocus: true })}
                            onBlur={e => this.setState({ pidFocus: false })}
                          ><option value="" disabled>Select project</option>
                            {activeProjects}
                          </Input>
                          {this.state.pidState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      {/* <Col sm="6" style={{paddingLeft:'50px', paddingRight:'100px'}}>
        <Label>Remark </Label>
            <FormGroup className={`has-label ${this.state.remarkState}`}>
            <Input
                name="remark"
                placeholder="Remark..."
                type="text"
                value={this.state.employeeClaim.remark}
                onChange={e => this.change(e, "remark", "sentense", 1)}
                onFocus={e => this.setState({ remarkFocus: true })}
                onBlur={e => this.setState({ remarkFocus: false })}
              />
              {this.state.remarkState === "has-danger" ? (
                <label className="error">{REQUIRD_MESSAGE}</label>
              ) : null}
            </FormGroup>
        </Col>  */}
                      <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '100px' }}>
                        <Label>Claim Type *</Label>
                        <FormGroup className={`has-label ${this.state.claimTypeState}`}>
                          <Input
                            name="claimType"
                            placeholder="Claim Type..."
                            type="select"
                            defaultValue={!this.state.employeeClaim.claimType || this.state.employeeClaim.claimType == "" ? "" : this.state.employeeClaim.claimType}
                            value={!this.state.employeeClaim.claimType || this.state.employeeClaim.claimType == "" ? "" : this.state.employeeClaim.claimType}
                            onChange={e => this.change(e, "claimType", "sentense")}
                            onFocus={e => this.setState({ claimTypeFocus: true })}
                            onBlur={e => this.setState({ claimTypeFocus: false })}
                          ><option value="" disabled>Select type</option>
                            <option value="Advance">Advance</option>
                            <option value="Reimbursement">Reimbursement</option>
                          </Input>
                          {this.state.claimTypeState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '100px', marginTop: '10px' }}>
                        {!this.state.employeeClaim.remark || this.state.employeeClaim.remark == "" ? null :
                          <Label style={{ color: 'red' }} >Remark : {this.state.employeeClaim.remark} </Label>
                        }
                      </Col>
                    </Row>
                    <div className="expenses-billing-block">
                      <Card>
                        <CardBody>
                          {!this.state.employeeClaim.claimItems ?
                            <React.Fragment /> :
                            <Table className="custom-timesheet-table">
                              <thead>
                                {this.renderTableHeader()}
                              </thead>
                              <tbody>
                                {this.renderTableData(claimCategories)}
                              </tbody>
                            </Table>
                          }
                        </CardBody>
                      </Card>
                    </div>
                    <Col sm="12" >
                      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '50px' }} >
                        <div >
                          <Button size="sm" color="info" onClick={this.handleSubmit} >{this.state.isLoading ?
                            <Spinner as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true" />

                            : 'Save'
                          }     </Button>
                        </div>
                        <div >
                          <Button size="sm" color="default" onClick={this.handleCancel}>Cancel</Button>
                        </div>
                      </div>
                      <Label></Label>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  addUpdateEmployeeClaim: bindActionCreators(addUpdateEmployeeClaim, dispatch),
  getAllClaimCategories: bindActionCreators(getAllClaimCategories, dispatch),
  deleteClaimItem: bindActionCreators(deleteClaimItem, dispatch),
  getEmployeeAllProjects: bindActionCreators(getEmployeeAllProjects, dispatch)
});

export const mapStateToProps = ({ claims, claimItems, claimCategories, auth, employeeProjects }, ownProps) => {
  let employeeClaim = claims.find(o => o.id === ownProps.location.state.id);
  return {
    employeeClaim: employeeClaim ? employeeClaim : ownProps.location.state.employeeClaim,
    claimItems: claimItems,
    employeeProjects: employeeProjects,
    claimCategories: claimCategories,
    cid: auth.user.cid,
    eid: auth.user.eid,
    user: auth && auth.user
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(CreateExpense);