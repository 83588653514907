import classNames from "classnames";
import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import ReactTable from "react-table";
import { Button, Col, Row, UncontrolledTooltip } from "reactstrap";
import Pagination from "../../../../../components/common/pagination";
import ProjectContactModal from "./projectContactModal";



class ProjectContactManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            formType: 'Add',
            contact: {
                id: 0,
                cid: this.props.user.cid
            },
            data: [],
        };
    }

    async UNSAFE_componentWillMount() {

        this.setState({
            ...this.state,
            data: this.props.projectContacts.map((prop, key) => {
                return {
                    id: prop.id,
                    cid: prop.cid,
                    address1: prop.address1,
                    address2: prop.address2,
                    city: prop.city,
                    contactEmail: prop.contactEmail,
                    contactExt: prop.contactExt,
                    contactInfo: prop.contactInfo,
                    contactMobile: prop.contactMobile,
                    contactName: prop.contactName,
                    contactOfficePhone: prop.contactOfficePhone,
                    country: prop.country,
                    isActive: prop.isActive,
                    pid: prop.pid,
                    state: prop.state,

                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <Button

                                onClick={() => {
                                    let obj = this.state.data.find(o => o.id === prop.id);

                                    let { id,
                                        cid,
                                        address1,
                                        address2,
                                        city,
                                        contactEmail,
                                        contactExt,
                                        contactInfo,
                                        contactMobile,
                                        contactName,
                                        contactOfficePhone,
                                        country,
                                        isActive,
                                        pid,
                                        state,
                                    } = obj;
                                    let contactItem = {
                                        id,
                                        cid,
                                        address1,
                                        address2,
                                        city,
                                        contactEmail,
                                        contactExt,
                                        contactInfo,
                                        contactMobile,
                                        contactName,
                                        contactOfficePhone,
                                        country,
                                        isActive,
                                        pid,
                                        state,
                                    }
                                    this.showProjectModal(contactItem);
                                }}
                                // color="warning"
                                size="sm"
                                className={classNames("btn-icon btn-link btn-neutral like")}
                            >
                                <i id="tooltip209860" className="tim-icons icon-pencil" />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip209860"
                                placement="left"
                            >
                                Edit
                            </UncontrolledTooltip>
                        </div>
                    ),

                }
            })
        });

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.data != nextProps.projectContacts) {
            this.setState({
                ...this.state,
                data: nextProps.projectContacts.map((prop, key) => {
                    return {
                        id: prop.id,
                        cid: prop.cid,
                        address1: prop.address1,
                        address2: prop.address2,
                        city: prop.city,
                        contactEmail: prop.contactEmail,
                        contactExt: prop.contactExt,
                        contactInfo: prop.contactInfo,
                        contactMobile: prop.contactMobile,
                        contactName: prop.contactName,
                        contactOfficePhone: prop.contactOfficePhone,
                        country: prop.country,
                        isActive: prop.isActive,
                        pid: prop.pid,
                        state: prop.state,

                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                <Button

                                    onClick={() => {
                                        let obj = this.state.data.find(o => o.id === prop.id);

                                        let { id,
                                            cid,
                                            address1,
                                            address2,
                                            city,
                                            contactEmail,
                                            contactExt,
                                            contactInfo,
                                            contactMobile,
                                            contactName,
                                            contactOfficePhone,
                                            country,
                                            isActive,
                                            pid,
                                            state,
                                        } = obj;
                                        let contactItem = {
                                            id,
                                            cid,
                                            address1,
                                            address2,
                                            city,
                                            contactEmail,
                                            contactExt,
                                            contactInfo,
                                            contactMobile,
                                            contactName,
                                            contactOfficePhone,
                                            country,
                                            isActive,
                                            pid,
                                            state,
                                        }
                                        this.showProjectModal(contactItem);
                                    }}
                                    // color="warning"
                                    size="sm"
                                    className={classNames("btn-icon btn-link like")}
                                >
                                    <i id="tooltip209860" className="tim-icons icon-pencil" />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip209860"
                                    placement="left"
                                >
                                    Edit
                                </UncontrolledTooltip>
                            </div>
                        ),

                    }
                })
            });
        }
    }

    notify = (place, type, message) => {

        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };

    showProjectModal = (contact) => {
        this.setState({
            formType: "Update",
            showModal: true,
            contact: { ...contact }
        });
    }

    toggleShowModal = () => {
        this.setState({
            formType: "Add",
            showModal: !this.state.showModal,
            contact: {},
        });
    }

    onClose = () => {
        this.setState({
            showModal: false,
        });
    }

    isValidated = async (contact, formType) => {
        let dataTable = [...this.state.data];
        if (formType === "Add") {
            this.props.addUpdateProjectContact(contact, formType);
            this.setState({
                formType: 'Add',
                showModal: false,
                contact: {}
            })

            return true;

        } else {
            this.props.addUpdateProjectContact(contact, formType);
            this.setState({
                formType: 'Add',
                showModal: false,
                contact: {}
            })

            return true;

        }

    }

    handleCancel = () => {
        let passProps = {
            pathname: 'projectmaster',
        }
        this.props.history.push(passProps);
    }

    render() {

        const myCustomNextText = () => {
            return
        };
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    {this.state.showModal && <ProjectContactModal
                        modal={this.state.showModal}
                        toggle={this.toggleShowModal}
                        onSubmit={this.isValidated}
                        contact={this.state.contact}
                        projects={this.props.projects}
                        companies={this.props.companies}
                        countries={this.props.countries}
                        close={this.onClose}
                        formType={this.state.formType}
                        project={this.props.project}
                        user={this.props.user}
                    />}

                    <Row>
                        <Col xs={12} md={12}>
                            {/* <Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                     <div style={{}}>
                                        <Button size="sm" onClick={this.toggleShowModal} color="info">New</Button>
                                    </div> *
                                </CardHeader>
                                <CardBody>
                                </CardBody>
                            </Card> */}
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button size="sm" onClick={this.toggleShowModal} color="info">New</Button>
                            </div>
                            <ReactTable
                                PaginationComponent={Pagination}
                                data={this.state.data}
                                // filterable
                                // nextText={myCustomNextText}
                                resizable={false}
                                columns={[
                                    {
                                        Header: "Actions",
                                        accessor: "actions",
                                        width: 70,
                                        sortable: false,
                                        filterable: false
                                    },
                                    {
                                        Header: "Name",
                                        accessor: "contactName"
                                    },
                                    {
                                        Header: "Email",
                                        accessor: "contactEmail"
                                    },
                                    {
                                        Header: "contact",
                                        accessor: "contactOfficePhone"
                                    }
                                ]}
                                // NoDataComponent={() => null}
                                pageSize={this.props.projectContacts.length <= 10 ? this.props.projectContacts.length : 10}
                                className="-striped -highlight project-contact-table"
                            />


                        </Col>
                    </Row>
                    <Button onClick={() => { this.handleCancel() }} className="btn-cancel" size="sm" color="info">Cancel</Button>
                </div>
            </>
        );
    }
}


export default ProjectContactManagement