import { getCompanyBasicInfoAPI } from "../../services/app-config-service";

export const FETCH_COMPANY_BASIC_INFO = 'FETCH_COMPANY_BASIC_INFO';

export const getCompanyBasicInfo = (hostedUrl) => {
  return async (dispatch) => {
    const response = await getCompanyBasicInfoAPI(hostedUrl);
    if (response.data) {
      dispatch({
        type: FETCH_COMPANY_BASIC_INFO,
        data: response.data
      });
      return { success: true };
    }
    else {
      dispatch({
        type: FETCH_COMPANY_BASIC_INFO,
        data: null
      });
      return { success: false };
    }
  }
}