import { FETCH__PROJECT_ALL_CONTACTS, } from "../actions/projectContactActions";
let intialState = [{
    address1: "lorem",
    address2: "ipsum",
    cid: 1,
    city: "pune",
    contactEmail: "john@yopmail.com",
    contactExt: "lorem",
    contactInfo: "lorem ipsum",
    contactMobile: "8788589556",
    contactName: "John Doe",
    contactOfficePhone: "8788589554",
    country: 1,
    id: 0,
    isActive: "Y",
    projectId: 0,
    state: "Maharashtra"
}]
const projectContactsReducer = function (state = [], action) {
    let newState;
    let index;
    switch (action.type) {
        case FETCH__PROJECT_ALL_CONTACTS:
            return action.contacts


        default:
            return state;
    }
};

export default projectContactsReducer;