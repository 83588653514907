
import React from "react";
import ReactWizard from "react-bootstrap-wizard";

import _ from "lodash";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { bindActionCreators } from "redux";

import {
  ADD_EMPLOYEE_BASIC_INFO_ERROR,
  ADD_EMPLOYEE_BASIC_INFO_SUCCESS,
  ADD_EMPLOYEE_PAYROLL_INFO_ERROR,
  ADD_EMPLOYEE_PAYROLL_INFO_SUCCESS,
  UPDATE_EMPLOYEE_BASIC_INFO_ERROR, UPDATE_EMPLOYEE_BASIC_INFO_SUCCESS,
  UPDATE_EMPLOYEE_EXTRA_INFO_ERROR,
  UPDATE_EMPLOYEE_EXTRA_INFO_SUCCESS
} from "../../../variables/constants";

import { Redirect } from "react-router-dom";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import { getAllEmployeesByAdmin } from "../../../redux/actions/adminEmployeesActions";
import { getAllInitialCompanyConfigs } from "../../../redux/actions/companyConfigAction";
import { getCompanyLocations } from "../../../redux/actions/companyLocationAction";
import { getAllCurrencies } from "../../../redux/actions/currenciesAction";
import { getAllDepartments } from "../../../redux/actions/departmentActions";
import { getAllDesignations } from "../../../redux/actions/designationAction";
import { addNewEmployee, updateEmployeeBasicInfo } from "../../../redux/actions/employeeBasicInfoAction";
import { addUpdateEmployeeExtraInfo, getEmployeeExtraInfo } from "../../../redux/actions/employeeExtraInfoAction";
import { getEmployeePayroll, updateEmployeePayrollInfo } from "../../../redux/actions/employeePayrollInfoAction";
import { getAllemployeeVendors } from "../../../redux/actions/employeeVendor";
import { getEmployeesWithManagerRole } from "../../../redux/actions/managersAction";
import { getAllRoles } from "../../../redux/actions/rolesAction";
import { uploadFile } from "../../../services/index";
import Step1 from "./AddUpdateSteps/Step1";
import Step2 from "./AddUpdateSteps/Step2";
import Step3 from "./AddUpdateSteps/Step3";

class AddUpdateStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalDemo: false,
      selectedOption: null,
      employee: {},
      id: 0,
      eid: 0,
      cid: 0,
      uploadProgress: 0,
      file: null,
      image: "",
      isLoading: false,
      extraInfo: {},
      payRollInfo: {},
      payroll_enabled: false,
      isEmployeeContract: false,
      isDataLoading: false

    };
  }
  async UNSAFE_componentWillMount() {
    this.setState({
      isDataLoading: true
    })
    if (this.props.employee) {
      this.props.getEmployeeExtraInfo(this.props.cid, this.props.employee.eid);
      this.props.getEmployeePayroll(this.props.cid, this.props.employee.eid);
      setTimeout(() => {
        this.setState({
          isDataLoading: false
        })
      }, 1000);
      if (!this.props.employee.extraInfo) {
        const extraInfo = await this.props.getEmployeeExtraInfo(this.props.cid, this.props.employee.eid);
        if (extraInfo.success) {
          this.setState({
            extraInfo: extraInfo.data,
          })
        }
      }
      if (!this.props.roles.length) {
        this.props.getAllRoles(this.props.cid);
      }
      if (!this.props.designations.length) {
        this.props.getAllDesignations(this.props.cid);
      }
      if (!this.props.departments.length) {
        this.props.getAllDepartments(this.props.cid);
      }
      if (!this.props.managers.length) {
        this.props.getEmployeesWithManagerRole(this.props.cid);
      }
      if (!this.props.companyLocations.length) {
        this.props.getCompanyLocations(this.props.cid);
      }
      if (!this.props.configs.length) {
        this.props.getAllInitialCompanyConfigs(this.props.cid);
      }
      if (!this.props.adminEmployees.length) {
        this.props.getAllEmployeesByAdmin(this.props.cid);
      }
      if (!this.props.employeeVendors.length) {
        this.props.getAllemployeeVendors(this.props.cid);
      }
      if (!this.props.currencies.length) {
        this.props.getAllCurrencies();
      }
      if (this.props.configs.length) {
        this.props.configs.filter(config => {
          if (config.param === "payroll_enabled" && config.paramValue == "true") {
            this.setState({
              payroll_enabled: true
            })
          }
        });
      }

    }
  }

  submitBasicInfo = async (employee, selectedFile, selectedImage) => {
    let extraInfo = this.props.employee && this.props.employee.extraInfo ? this.props.employee.extraInfo : {};
    let payRollInfo = this.props.employee && this.props.employee.payRollInfo ? this.props.employee.payRollInfo : {};
    let isSameObject = _.isEqual(this.props.employee, employee);
    if (!isSameObject) {
      if (this.props.location.state.formType === "Add") {
        if (this.props.employee.id == 0) {
          let employeeData = {
            ...employee,
            password: window.btoa(employee.emailOfficial),
            userImage: ""
          }
          const response = await this.props.addNewEmployee(employeeData);
          if (response.success) {
            let empData = response.data;
            this.setState({
              employee: response.data
            })
            let passProps = {
              state: {
                formType: 'Add',
                newEmployee: empData,
              }
            }
            this.props.history.push(passProps);
            if (selectedFile != null || selectedImage != null) {
              let employeeData = {};
              if (selectedFile != null) {
                let filePath = `${empData.cid}/RESUMES/${empData.eid}`;
                const data = new FormData()
                data.append('filePath', filePath);
                data.append('files', selectedFile);
                const fileResponse = await uploadFile(data, this.onUploadProgress);
                employeeData = {
                  ...empData,
                  resume: JSON.stringify(empData.resume)
                }
                this.setState({
                  file: fileResponse.data[0]
                })
              }
              if (selectedImage != null) {
                let filePath = `${empData.cid}/PROFILEPHOTO/${empData.eid}`;
                const data = new FormData()
                data.append('filePath', filePath);
                data.append('files', selectedImage);
                const imageResponse = await uploadFile(data, this.onUploadProgress);
                if (employeeData.resume != "") {
                  employeeData = {
                    ...empData,
                    resume: JSON.stringify(empData.resume),
                    userImage: JSON.stringify(imageResponse.data)
                  }
                } else {
                  employeeData = {
                    ...empData,
                    userImage: JSON.stringify(imageResponse.data)
                  }
                }
                this.setState({
                  image: imageResponse.data[0]
                })
              }
              const result = await this.props.updateEmployeeBasicInfo(employeeData, extraInfo, payRollInfo);
              if (result.success) {
                let message = {
                  message: ADD_EMPLOYEE_BASIC_INFO_SUCCESS,
                  type: "success",
                };
                this.handleMessage(message);
              } else {
                let message = {
                  message: ADD_EMPLOYEE_BASIC_INFO_ERROR,
                  type: "warning",
                };
                this.handleMessage(message);
              }
            } else {
              let message = {
                message: ADD_EMPLOYEE_BASIC_INFO_SUCCESS,
                type: "success",
              };
              this.handleMessage(message);
            }
          } else {
            let message = {
              message: ADD_EMPLOYEE_BASIC_INFO_ERROR,
              type: "warning",
            };
            this.handleMessage(message);
          }
        }
      } else {
        const response = await this.props.updateEmployeeBasicInfo(employee, extraInfo, payRollInfo);
        if (response.success) {
          let message = {
            message: UPDATE_EMPLOYEE_BASIC_INFO_SUCCESS,
            type: "success",
          };
          this.handleMessage(message);
        } else {
          let message = {
            message: UPDATE_EMPLOYEE_BASIC_INFO_ERROR,
            type: "warning",
          };
          this.handleMessage(message);
        }
      }
    }
  };
  onUploadProgress = (progressEvent) => {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    this.setState({ uploadProgress: percentCompleted });
  };
  submitExtraInfo = async (employee) => {
    let empData = {}
    if (!employee.id) {
      empData = {
        ...employee,
        cid: this.props.cid,
        eid: this.state.employee.eid,
        id: this.state.employee.id
      }
    } else {
      empData = employee
    }
    let isSameObject = _.isEqual(this.props.employee.extraInfo, employee);
    if (!isSameObject) {
      if (this.state.isLoading === false) {
        this.setState({
          isLoading: true
        })
        const response = await this.props.addUpdateEmployeeExtraInfo(empData);
        if (this.props.location.state.formType === "Add") {
          if (response.success) {
            if (response.data && response.data.employmentType === "Contract") {
              this.setState({
                extraInfo: response.data
              })
              let message = {
                message: "Employee position details added succesfully.",
                type: "success",
              };
              this.handleMessage(message);
              setTimeout(() => {
                this.setState({
                  isLoading: false
                })
                let passProps = {
                  pathname: 'employeemaster',
                }
                this.props.history.push(passProps);
              }, 3000);

            } else {
              this.setState({
                extraInfo: response.data
              })
              let message = {
                message: "Employee position details added succesfully.",
                type: "success",
              };
              this.handleMessage(message);
              if (!this.state.payroll_enabled) {
                setTimeout(() => {
                  this.setState({
                    isLoading: false
                  })
                  let passProps = {
                    pathname: 'employeemaster',
                  }
                  this.props.history.push(passProps);
                }, 3000);
              } else {
                setTimeout(() => {
                  this.setState({
                    isLoading: false
                  })
                }, 3000);
              }
            }
          } else {
            this.setState({
              extraInfo: empData
            })
            let message = {
              message: "Error occurred while adding employee position details!",
              type: "warning",
            };
            this.handleMessage(message);
            setTimeout(() => {
              this.setState({
                isLoading: false
              })
            }, 3000);
          }
        } else {
          if (response.success) {
            // if (response.data && response.data.employmentType === "Contract") {
            //   this.setState({
            //     extraInfo: response.data
            //   })
            //   let message = {
            //     message: UPDATE_EMPLOYEE_EXTRA_INFO_SUCCESS,
            //     type: "success",
            //   };
            //   this.handleMessage(message);
            //   setTimeout(() => {
            //     this.setState({
            //       isLoading: false
            //     });
            //     let passProps = {
            //       pathname: 'employeemaster',
            //     }
            //     this.props.history.push(passProps);
            // }, 1000);
            // } else {
            this.setState({
              extraInfo: response.data
            })
            let message = {
              message: UPDATE_EMPLOYEE_EXTRA_INFO_SUCCESS,
              type: "success",
            };
            this.handleMessage(message);
            if (!this.state.payroll_enabled) {
              setTimeout(() => {
                this.setState({
                  isLoading: false
                })
                let passProps = {
                  pathname: 'employeemaster',
                }
                this.props.history.push(passProps);
              }, 3000);
            } else {
              setTimeout(() => {
                this.setState({
                  isLoading: false
                })
              }, 3000);
            }
            // }
          } else {
            this.setState({
              extraInfo: empData
            })
            let message = {
              message: UPDATE_EMPLOYEE_EXTRA_INFO_ERROR,
              type: "warning",
            };
            this.handleMessage(message);
            setTimeout(() => {
              this.setState({
                isLoading: false
              })
            }, 3000);
          }

        }
      }
    }
  };

  submitPayrollInfo = async (employee) => {
    if ((Object.keys(employee).length) === 0) {
      let passProps = {
        pathname: 'employeemaster',
      }
      this.props.history.push(passProps);
    }
    let employeeDetails = {
      ...employee,
      salaryDiscussed: JSON.stringify(employee)
    }
    let isSameObject = _.isEqual(this.props.employee.payRollInfo, employee);
    if (!isSameObject) {
      if (this.state.isLoading === false) {
        this.setState({
          isLoading: true
        })
        const response = await this.props.updateEmployeePayrollInfo(employeeDetails);
        if (response.success) {
          this.setState({
            payRollInfo: response.data
          })
          let message = {
            message: ADD_EMPLOYEE_PAYROLL_INFO_SUCCESS,
            type: "success",
          };
          this.handleMessage(message);
          setTimeout(() => {
            this.setState({
              isLoading: false
            })
            let passProps = {
              pathname: 'employeemaster',
            }
            this.props.history.push(passProps);
          }, 3000);

        } else {
          this.setState({
            payRollInfo: employeeDetails
          })
          let message = {
            message: ADD_EMPLOYEE_PAYROLL_INFO_ERROR,
            type: "warning",
          };
          this.handleMessage(message);
          setTimeout(() => {
            this.setState({
              isLoading: false
            })
          }, 3000);
        }
      }
    }
    setTimeout(() => {
      this.setState({
        isLoading: false
      })
      let passProps = {
        pathname: 'employeemaster',
      }
      this.props.history.push(passProps);
    }, 2000);
  };
  changeSteps = () => {

    this.setState({
      isEmployeeContract: true
    })
  }
  handleMessage = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message.message,
      type: message.type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 2
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  render() {
    if (!this.props.employee) {
      let user = this.props.user;
      if (user && user.roles) {
        if (user.roles[0] === 'Manager') {
          return <Redirect to="/manager/employeemaster" />
        } else if (user.roles[0] === "Employee") {
          return <Redirect to="/employee/employeemaster" />
        } else {
          return <Redirect to="/admin/employeemaster" />
        }
      }
    }
    let roles = this.props.roles.filter(role => role.isActive === "Y");
    let designations = this.props.designations.filter(designation => designation.isActive === "Y");
    let departments = this.props.departments.filter(department => department.isActive === "Y");
    let id = this.state.employee && this.state.employee.id ? this.state.employee.id : this.props.employee.id;
    let managers = this.props.managers.filter(manager => manager.isActive === "Y");

    let steps = [];
    if (this.state.payroll_enabled === true) {
      steps = [
        {
          stepName: "Employee Details",
          stepIcon: "tim-icons icon-single-02",
          component: Step1,
          stepProps: {
            employee: this.props.employee,
            empId: this.props.empId,
            file: this.state.file,
            image: this.state.image,
            submitBasicInfo: this.submitBasicInfo,
            handleMessage: this.handleMessage,
            formType: this.props.location.state.formType,
            employees: this.props.employees,
            cid: this.props.cid,
            adminEmployees: this.props.adminEmployees,
            history: this.props.history
          }
        },
        {
          stepName: "Employee Position",
          stepIcon: "tim-icons icon-settings-gear-63",
          component: Step2,
          stepProps: {
            employee: this.props.employee && this.props.employee.extraInfo ? this.props.employee.extraInfo != {} ? this.props.employee.extraInfo : this.state.extraInfo : this.state.extraInfo,
            submitExtraInfo: this.submitExtraInfo,
            roles: roles,
            designations: designations,
            departments: departments,
            companyLocations: this.props.companyLocations,
            managers: managers,
            currencies: this.props.currencies,
            changeSteps: this.changeSteps,
            employeeVendors: this.props.employeeVendors,
            history: this.props.history
          }
        },
        {
          stepName: "Payroll Details",
          stepIcon: "tim-icons icon-settings-gear-63",
          component: Step3,
          stepProps: {
            basicInfo: this.props.employee && this.props.employee.extraInfo ? this.props.employee.extraInfo : this.props.employee,
            extraInfo: this.props.employee && this.props.employee.extraInfo ? this.props.employee.extraInfo != {} ? this.props.employee.extraInfo : this.state.extraInfo : this.state.extraInfo,
            employee: this.props.employee && this.props.employee.payRollInfo ? this.props.employee.payRollInfo != {} ? this.props.employee.payRollInfo : this.state.payRollInfo : this.state.payRollInfo,
            employeeData: this.props.employee,
            submitPayrollInfo: this.submitPayrollInfo,
            companyLocations: this.props.companyLocations,
            roles: this.props.roles,
            designations: this.props.designations,
            employees: this.props.employees,
            history: this.props.history
          }
        }
      ];
    } else {
      steps = [
        {
          stepName: "Employee Details",
          stepIcon: "tim-icons icon-single-02",
          component: Step1,
          stepProps: {
            employee: this.props.employee,
            empId: this.props.empId,
            file: this.state.file,
            image: this.state.image,
            submitBasicInfo: this.submitBasicInfo,
            handleMessage: this.handleMessage,
            formType: this.props.location.state.formType,
            employees: this.props.employees,
            cid: this.props.cid,
            adminEmployees: this.props.adminEmployees,
            history: this.props.history
          }
        },
        {
          stepName: "Employee Position",
          stepIcon: "tim-icons icon-settings-gear-63",
          component: Step2,
          stepProps: {
            employee: this.props.employee && this.props.employee.extraInfo ? this.props.employee.extraInfo != {} ? this.props.employee.extraInfo : this.state.extraInfo : this.state.extraInfo,
            submitExtraInfo: this.submitExtraInfo,
            roles: roles,
            designations: designations,
            departments: departments,
            companyLocations: this.props.companyLocations,
            managers: managers,
            currencies: this.props.currencies,
            changeSteps: this.changeSteps,
            employeeVendors: this.props.employeeVendors,
            history: this.props.history
          }
        }
      ];
    }


    return (
      <>
        {this.state.isDataLoading ?
          <div className="content">
            <LoadingIndicator loading={this.state.isDataLoading} />
          </div>
          :
          <div className="content">
            <div className="rna-container">
              <NotificationAlert ref="notificationAlert" />
            </div>
            <Col xs={12} md={12} className="custom-wizard">
              <ReactWizard
                steps={steps}
                navSteps
                validate
                title=""
                formType={this.props.location.state.formType}
                employee={this.props.location.state.employee}
                headerTextLeft
                finishButtonClasses="btn-wd btn-info"
                nextButtonClasses="btn-wd btn-info"
                previousButtonClasses="btn-wd"
                progressbar={true}
                color="blue"
              />
            </Col>
          </div>
        }
      </>
    );
  }
}


export const mapDispatchToProps = dispatch => ({
  getEmployeeExtraInfo: bindActionCreators(getEmployeeExtraInfo, dispatch),
  getEmployeePayroll: bindActionCreators(getEmployeePayroll, dispatch),
  addNewEmployee: bindActionCreators(addNewEmployee, dispatch),
  addUpdateEmployeeExtraInfo: bindActionCreators(addUpdateEmployeeExtraInfo, dispatch),
  updateEmployeePayrollInfo: bindActionCreators(updateEmployeePayrollInfo, dispatch),
  updateEmployeeBasicInfo: bindActionCreators(updateEmployeeBasicInfo, dispatch),
  getAllRoles: bindActionCreators(getAllRoles, dispatch),
  getAllDesignations: bindActionCreators(getAllDesignations, dispatch),
  getAllDepartments: bindActionCreators(getAllDepartments, dispatch),
  getCompanyLocations: bindActionCreators(getCompanyLocations, dispatch),
  getEmployeesWithManagerRole: bindActionCreators(getEmployeesWithManagerRole, dispatch),
  getAllInitialCompanyConfigs: bindActionCreators(getAllInitialCompanyConfigs, dispatch),
  getAllEmployeesByAdmin: bindActionCreators(getAllEmployeesByAdmin, dispatch),
  getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch),
  getAllemployeeVendors: bindActionCreators(getAllemployeeVendors, dispatch)

});

export const mapStateToProps = ({ employees, roles, designations, auth, departments, employeeVendors, companyLocations, currencies, adminEmployees, managers, configs }, ownProps) => {
  let employee = employees.find(o => o.id === ownProps.location.state.empId);
  return {
    employee: employee ? employee : ownProps.location.state.newEmployee,
    roles: roles,
    designations: designations,
    employees: employees,
    cid: auth.user.cid,
    departments: departments,
    companyLocations: companyLocations,
    managers: managers,
    configs: configs,
    adminEmployees: adminEmployees,
    currencies: currencies,
    employeeVendors: employeeVendors,
    user: auth && auth.user
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateStepper);
