import { getManagerContractorsReportAPI, getAdminContractorsReportAPI } from "../../services/timesheets-service";


export const FETCH_MANAGER_CONTRACTORS_REPORT = 'FETCH_MANAGER_CONTRACTORS_REPORT';
export const FETCH_ADMIN_CONTRACTORS_REPORT = 'FETCH_ADMIN_CONTRACTORS_REPORT';
let token = localStorage.getItem('token');

export const getManagerContractorsReport = (cid, eid, startDate, endDate) => {

    return async (dispatch) => {
        const response = await getManagerContractorsReportAPI(cid, eid, startDate, endDate);

        if (response.data) {
            dispatch({
                type: FETCH_MANAGER_CONTRACTORS_REPORT,
                managerContractorsReport: response.data
            });
            return { success: true, data: response.data };
        }
        else {
            return { success: false };
        }
    }
}



export const getAdminContractorsReport = (cid, startDate, endDate) => {

    return async (dispatch) => {
        const response = await getAdminContractorsReportAPI(cid, startDate, endDate);

        if (response.data) {
            dispatch({
                type: FETCH_ADMIN_CONTRACTORS_REPORT,
                adminContractorsReport: response.data
            });
            return { success: true, data: response.data };

        }
        else {
            return { success: false };
        }
    }
}
