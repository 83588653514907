import { getAllClaimsToApproveAPI, approveRejectEmployeeClaimsAPI } from "../../services/claims-service";

export const FETCH_ALL_EMPLOYEES_CLAIM_TO_APPROVE = 'FETCH_ALL_EMPLOYEES_CLAIM_TO_APPROVE';
export const APPROVE_REJECT_EMPLOYEE_CLAIM = 'APPROVE_REJECT_EMPLOYEE_CLAIM';
export const getAllClaimsToApprove = (eid) => {
    return async (dispatch, getState) => {
        var { auth } = getState();
        var cid = auth.user.cid;

        const response = await getAllClaimsToApproveAPI(eid, cid);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_EMPLOYEES_CLAIM_TO_APPROVE,
                claimsForApprove: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

export const approveRejectEmployeeClaims = (claim) => {
    return async (dispatch) => {
        const response = await approveRejectEmployeeClaimsAPI(claim);
        if (response.data) {
            dispatch({
                type: APPROVE_REJECT_EMPLOYEE_CLAIM,
                payload: {
                    id: claim.id,
                    claim: response.data
                }
            });
            return { success: true, data: response.data };
        }
        else {
            return { success: false };
        }
    }
}