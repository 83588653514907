import React, { useEffect, useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";
import ReactStars from 'react-rating-stars-component';
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardHeader, CardTitle, Col, Form, FormGroup, Input, Label, Row, Spinner, Tooltip } from 'reactstrap';
import { fetchManagerEmployeesAppraisalByAppraisalId, updateManagerEmployeeAppraisal } from "redux/actions/managerAppraisalActions";
import { MAX_CHARACTER_SIZE } from "variables/constants";
import "./styles.css";
import { COMPLIANCE } from "variables/constants";
import { INTEGRITY } from "variables/constants";
import { COLLABORATION } from "variables/constants";
import { INNOVATION } from "variables/constants";
import { resetEmployeeAppraisal } from "redux/actions/managerAppraisalActions";


export const ManagerAppraisal = (props) => {
    const { user } = useSelector(({ auth }) => auth);
    const { employeeAppraisal, loading } = useSelector(({ mamangerAppraisal }) => mamangerAppraisal);
    const notificationAlertRef = useRef(null);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [approveLoader, setApproveLoader] = useState(false);
    const [managerRating, setManagerRating] = useState(0.5);

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        accomplishments: '',
        accomplishmentsState: '',
        accomplishmentsManagerRemark: '',
        accomplishmentsManagerRemarkState: '',
        valuesCompanyPrioritized: '',
        valuesCompanyPrioritizedState: '',
        valuesCompanyPrioritizedManagerRemark: '',
        valuesCompanyPrioritizedManagerRemarkState: '',
        valuesCompanyPrioritizedNextYear: '',
        valuesCompanyPrioritizedNextYearState: '',
        valuesCompanyPrioritizedNextYearManagerRemark: '',
        valuesCompanyFocusNextYearManagerRemarkState: '',
        topDevelopmentAreas: '',
        topDevelopmentAreasState: '',
        topDevelopmentAreasManagerRemark: '',
        topDevelopmentAreasManagerRemarkState: '',
        topDevelopmentAreasNextYear: '',
        topDevelopmentAreasNextYearManagerRemark: '',
        topDevelopmentAreasNextYearManagerRemarkState: '',
        // topDevelopmentAreasManagerRemarkState: '',
        attendance: '',
        attendanceState: '',
        rating: null,
        ratingState: '',
        billable: '',
        nonBillable: '',
        accomplishmentsManagerRemarkRating: undefined,
        valuesCompanyPrioritizedManagerRemarkRating: undefined,
        topDevelopmentAreasManagerRemarkRating: undefined,
        topDevelopmentAreasNextYearManagerRemarkRating: undefined,
        valuesCompanyPrioritizedNextYearManagerRemarkRating: undefined,
        managerAverageRating: 0,
        accomplishmentsRating: undefined,
        valuesCompanyPrioritizedRating: undefined,
        topDevelopmentAreasRating: undefined,
        topDevelopmentAreasNextYearRating: undefined,
        valuesCompanyPrioritizedNextYearRating: undefined,
        employeeAverageRating: 0
    });

    const [innovationTooltipOpen, setInnovationTooltipOpen] = useState(false);
    const [collaborationtooltipOpen, setCollaborationtooltipOpen] = useState(false);
    const [integrityTooltipOpen, setIntegrityTooltipOpen] = useState(false);
    const [complianceTooltipOpen, setcomplianceTooltipOpen] = useState(false);

    const [innovationSecondTooltipOpen, setInnovationSecondTooltipOpen] = useState(false);
    const [collaborationSecontooltipOpen, setCollaborationSecondtooltipOpen] = useState(false);
    const [integritySecondTooltipOpen, setIntegritySecondTooltipOpen] = useState(false);
    const [compliancesecondTooltipOpen, setcomplianceSecondTooltipOpen] = useState(false);

    useEffect(() => {

        if (props.location.state) {
            dispatch(fetchManagerEmployeesAppraisalByAppraisalId(props.location.state.empAppraisalId));
        }
        return () => {
            dispatch(resetEmployeeAppraisal());
        }
    }, [props.location.state])

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            ...employeeAppraisal
        }));
    }, [employeeAppraisal])




    const onChangeTextHandler = (e) => {
        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: value,
            // [name + "State"]: value === "" ? "has-danger" : "has-success"
        }));

    };

    const notify = (message, type) => {
        const options = {
            place: 'tc',  // Position of the notification (tc = top center)
            message: <div>{message}</div>,
            type: type,  // Can be 'info', 'success', 'warning', 'danger'
            duration: 5,  // Duration in seconds
            // icon: "tim-icons icon-bell-55",
            // icon: false,
            autoDismiss: true,
            icon: false
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const formValidation = () => {
        const { accomplishments, accomplishmentsState, accomplishmentsManagerRemark, accomplishmentsManagerRemarkState, valuesCompanyPrioritized, valuesCompanyPrioritizedState, valuesCompanyPrioritizedManagerRemark, valuesCompanyPrioritizedManagerRemarkState,
            valuesCompanyPrioritizedNextYear, valuesCompanyPrioritizedNextYearState, valuesCompanyPrioritizedNextYearManagerRemark, valuesCompanyFocusNextYearManagerRemarkState, topDevelopmentAreas, topDevelopmentAreasState, topDevelopmentAreasManagerRemark, topDevelopmentAreasNextYear, topDevelopmentAreasNextYearState, topDevelopmentAreasManagerRemarkState,
            attendance, attendanceState, rating, ratingState, topDevelopmentAreasNextYearManagerRemark, topDevelopmentAreasNextYearManagerRemarkState,
            accomplishmentsManagerRemarkRating,
            valuesCompanyPrioritizedManagerRemarkRating,
            topDevelopmentAreasManagerRemarkRating,
            topDevelopmentAreasNextYearManagerRemarkRating,
            valuesCompanyPrioritizedNextYearManagerRemarkRating, } = formData;


        if (topDevelopmentAreasNextYearManagerRemark?.trim() === "" ||
            topDevelopmentAreasManagerRemark?.trim() === "" ||
            valuesCompanyPrioritizedNextYearManagerRemark?.trim() === "" ||
            valuesCompanyPrioritizedManagerRemark?.trim() === "" ||
            accomplishmentsManagerRemark?.trim() === "" ||
            attendance?.trim() === "" ||
            attendance === null ||
            rating === null ||
            rating?.trim() === "" ||
            billable?.trim() === "" ||
            nonBillable?.trim() === "") {
            notify('Please enter all fields!', 'danger');
            return false;

        } else if (accomplishmentsManagerRemarkRating === null || accomplishmentsManagerRemarkRating === 0 ||
            valuesCompanyPrioritizedManagerRemarkRating === null || valuesCompanyPrioritizedManagerRemarkRating === 0 ||
            topDevelopmentAreasNextYearManagerRemarkRating === null || topDevelopmentAreasNextYearManagerRemarkRating === 0 ||
            valuesCompanyPrioritizedNextYearManagerRemarkRating === null || valuesCompanyPrioritizedNextYearManagerRemarkRating === 0 ||
            topDevelopmentAreasManagerRemarkRating === null || topDevelopmentAreasManagerRemarkRating === 0) {
            notify('Rate all fields!', 'danger');
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = async () => {
        // this.props.onSubmit(this.state.designation, this.props.formType);

        const { accomplishmentsManagerRemark, valuesCompanyPrioritizedManagerRemark, valuesCompanyPrioritizedNextYearManagerRemark, topDevelopmentAreasManagerRemark, topDevelopmentAreasNextYearManagerRemark,
            attendance, rating, year, billable, nonBillable, valuesCompanyPrioritizedManagerRemarkRating, topDevelopmentAreasManagerRemarkRating, topDevelopmentAreasNextYearManagerRemarkRating,
            valuesCompanyPrioritizedNextYearManagerRemarkRating, accomplishmentsManagerRemarkRating, managerAverageRating
        } = formData;
        const { cid, eid, } = user;
        let isFormValid = formValidation();

        if (isFormValid) {
            let data = {
                "accomplishmentsManagerRemark": accomplishmentsManagerRemark,
                "cid": cid,
                "eid": eid,
                "empAppraisalId": employeeAppraisal && employeeAppraisal.empAppraisalId !== null ? employeeAppraisal.empAppraisalId : 0,
                "topDevelopmentAreasManagerRemark": topDevelopmentAreasManagerRemark,
                "topDevelopmentAreasNextYearManagerRemark": topDevelopmentAreasNextYearManagerRemark,
                "valuesCompanyPrioritizedNextYearManagerRemark": valuesCompanyPrioritizedNextYearManagerRemark,
                "valuesCompanyPrioritizedManagerRemark": valuesCompanyPrioritizedManagerRemark,
                "year": year,
                "rating": rating,
                "attendance": attendance,
                "status": 'Approved',
                "billable": billable,
                "nonBillable": nonBillable,
                "accomplishmentsManagerRemarkRating": accomplishmentsManagerRemarkRating,
                "valuesCompanyPrioritizedManagerRemarkRating": valuesCompanyPrioritizedManagerRemarkRating,
                "topDevelopmentAreasManagerRemarkRating": topDevelopmentAreasManagerRemarkRating,
                "topDevelopmentAreasNextYearManagerRemarkRating": topDevelopmentAreasNextYearManagerRemarkRating,
                "valuesCompanyPrioritizedNextYearManagerRemarkRating": valuesCompanyPrioritizedNextYearManagerRemarkRating,
                "managerAverageRating": managerAverageRating
            }

            let attendanceNumber = Number(attendance);


            if (attendanceNumber < 0 || attendanceNumber > 100) {
                return notify('Please enter valid attendace!', 'danger');
            }


            setApproveLoader(true);
            let result = await dispatch(updateManagerEmployeeAppraisal(data));

            setApproveLoader(false);
            if (result.success) {
                notify('Approved appraisal successfully!', 'success');
            }
        }
    }

    const handleSave = async () => {

        const { accomplishmentsManagerRemark, valuesCompanyPrioritizedManagerRemark, valuesCompanyPrioritizedNextYearManagerRemark, topDevelopmentAreasManagerRemark, topDevelopmentAreasNextYearManagerRemark,
            attendance, rating, year, billable, nonBillable, valuesCompanyPrioritizedManagerRemarkRating, topDevelopmentAreasManagerRemarkRating, topDevelopmentAreasNextYearManagerRemarkRating,
            valuesCompanyPrioritizedNextYearManagerRemarkRating, accomplishmentsManagerRemarkRating, managerAverageRating
        } = formData;
        const { cid, eid, } = user;
        let data = {
            "accomplishmentsManagerRemark": accomplishmentsManagerRemark,
            "cid": cid,
            "eid": eid,
            "empAppraisalId": employeeAppraisal && employeeAppraisal.empAppraisalId !== null ? employeeAppraisal.empAppraisalId : 0,
            // "empAppraisalId": 0,
            "topDevelopmentAreasManagerRemark": topDevelopmentAreasManagerRemark,
            "topDevelopmentAreasNextYearManagerRemark": topDevelopmentAreasNextYearManagerRemark,
            "valuesCompanyPrioritizedNextYearManagerRemark": valuesCompanyPrioritizedNextYearManagerRemark,
            "valuesCompanyPrioritizedManagerRemark": valuesCompanyPrioritizedManagerRemark,
            "year": year,
            "rating": rating,
            "attendance": attendance,
            "status": 'Submitted',
            "billable": billable,
            "nonBillable": nonBillable,
            "managerAverageRating": managerAverageRating,
            "accomplishmentsManagerRemarkRating": accomplishmentsManagerRemarkRating === null ? 0 : accomplishmentsManagerRemarkRating,
            "valuesCompanyPrioritizedManagerRemarkRating": valuesCompanyPrioritizedManagerRemarkRating === null ? 0 : valuesCompanyPrioritizedManagerRemarkRating,
            "topDevelopmentAreasManagerRemarkRating": topDevelopmentAreasManagerRemarkRating === null ? 0 : topDevelopmentAreasManagerRemarkRating,
            "topDevelopmentAreasNextYearManagerRemarkRating": topDevelopmentAreasNextYearManagerRemarkRating === null ? 0 : topDevelopmentAreasNextYearManagerRemarkRating,
            "valuesCompanyPrioritizedNextYearManagerRemarkRating": valuesCompanyPrioritizedNextYearManagerRemarkRating === null ? 0 : valuesCompanyPrioritizedNextYearManagerRemarkRating
        }
        let attendanceNumber = Number(attendance);


        if (attendanceNumber < 0 || attendanceNumber > 100) {
            return notify('Please enter valid attendace!', 'danger');
        }

        setSubmitLoader(true);
        let result = await dispatch(updateManagerEmployeeAppraisal(data));
        setSubmitLoader(false);

        if (result.success) {
            notify('Saved appraisal successfully!', 'success');
        }

        // }
    }

    const handleSelectRating = (e) => {

        if (e.target.value !== null) {
            let modifiedData = { ...formData };
            modifiedData.rating = e.target.value
            setFormData(modifiedData);
        }
    }
    const changeRating = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const getToolTipComponent = (state, setState, target, content) => {
        return (<Tooltip
            placement="top"
            isOpen={state}
            target={target}
            toggle={() => { setState(!state) }}
        >
            {content}
        </Tooltip>)
    }

    const getTooltip = (target, type, content) => {
        switch (target) {
            case 'Innovation':
                return getToolTipComponent(innovationTooltipOpen, setInnovationTooltipOpen, target, content);
            case 'Collaboration':
                return getToolTipComponent(collaborationtooltipOpen, setCollaborationtooltipOpen, target, content);
            case 'Integrity':
                return getToolTipComponent(integrityTooltipOpen, setIntegrityTooltipOpen, target, content);
            case 'Compliance':
                return getToolTipComponent(complianceTooltipOpen, setcomplianceTooltipOpen, target, content);
            case 'InnovationSecond':
                return getToolTipComponent(innovationSecondTooltipOpen, setInnovationSecondTooltipOpen, target, content);
            case 'CollaborationSecond':
                return getToolTipComponent(collaborationSecontooltipOpen, setCollaborationSecondtooltipOpen, target, content);
            case 'IntegritySecond':
                return getToolTipComponent(integritySecondTooltipOpen, setIntegritySecondTooltipOpen, target, content);
            case 'ComplianceSecond':
                return getToolTipComponent(compliancesecondTooltipOpen, setcomplianceSecondTooltipOpen, target, content);

            default:
                return null;
        }
    }

    const { accomplishments, accomplishmentsState, accomplishmentsManagerRemark, accomplishmentsManagerRemarkState, valuesCompanyPrioritized, valuesCompanyPrioritizedState, valuesCompanyPrioritizedManagerRemark, valuesCompanyPrioritizedManagerRemarkState,
        valuesCompanyPrioritizedNextYear, valuesCompanyPrioritizedNextYearState, valuesCompanyPrioritizedNextYearManagerRemark, valuesCompanyFocusNextYearManagerRemarkState, topDevelopmentAreas, topDevelopmentAreasState, topDevelopmentAreasManagerRemark, topDevelopmentAreasNextYear, topDevelopmentAreasNextYearState, topDevelopmentAreasManagerRemarkState,
        attendance, attendanceState, rating, ratingState, topDevelopmentAreasNextYearManagerRemark, topDevelopmentAreasNextYearManagerRemarkState, status, billable, nonBillable,
        accomplishmentsRating, valuesCompanyPrioritizedRating, topDevelopmentAreasRating, topDevelopmentAreasNextYearRating,
        valuesCompanyPrioritizedNextYearRating, employeeAverageRating, accomplishmentsManagerRemarkRating, valuesCompanyPrioritizedManagerRemarkRating, topDevelopmentAreasManagerRemarkRating, topDevelopmentAreasNextYearManagerRemarkRating, valuesCompanyPrioritizedNextYearManagerRemarkRating, managerAverageRating } = formData;

    return (<>
        <div className="content">
            <Row>
                <Col xs={12} md={12}>
                    <Card>
                        <div className="rna-container">
                            <NotificationAlert ref={notificationAlertRef} />
                        </div>

                        <CardHeader className="appraisal-container">
                            <CardTitle tag="h2" style={{ marginBottom: 25 }}>Appraisal</CardTitle>
                            <Form className="custom-form project-form" id="appraisalForm">
                                <Row xs="2">
                                    <Col md="9">
                                        <FormGroup className={`has-label`}>
                                            <Label className="form-label">Please list the three key accomplishments you've achieved this year along with details?*</Label>
                                            <Input
                                                name="accomplishments"
                                                type="textarea"
                                                placeholder=""
                                                value={accomplishments}
                                                onChange={onChangeTextHandler}
                                                rows={5}
                                                disabled
                                            />
                                            {accomplishmentsState === "has-danger" ? (
                                                <label className="error">please enter accomplishments!</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" className="rating-container">
                                        <div style={{ pointerEvents: 'none' }}>
                                            {accomplishmentsRating !== undefined && <ReactStars
                                                count={5}
                                                value={accomplishmentsRating}
                                                onChange={changeRating}
                                                size={30}
                                                isHalf={true} // Enables half-star support
                                                activeColor="gold"
                                                a11y={false}
                                            />}
                                        </div>
                                    </Col>
                                </Row>
                                <Row xs="2">
                                    <Col md="9">
                                        <FormGroup className={`has-label`}>
                                            <Label className="form-label">Remark *</Label>

                                            <Input
                                                name="accomplishmentsManagerRemark"
                                                type="textarea"
                                                placeholder=""
                                                value={accomplishmentsManagerRemark}
                                                onChange={onChangeTextHandler}
                                                rows={5}
                                                style={{ resize: 'both' }}
                                                disabled={status === "Approved" ? true : false}
                                                maxLength={500}
                                            />
                                            <span className="subtext">{MAX_CHARACTER_SIZE}</span>
                                            {accomplishmentsManagerRemarkState === "has-danger" ? (
                                                <label className="error">please enter accomplishments!</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>

                                    <Col sm="3" className="rating-container">
                                        {accomplishmentsManagerRemarkRating !== undefined && <div style={{ pointerEvents: status === 'Submitted' ? 'all' : 'none' }}>
                                            <ReactStars
                                                count={5}
                                                value={accomplishmentsManagerRemarkRating}
                                                onChange={(value) => {
                                                    changeRating('accomplishmentsManagerRemarkRating', value)
                                                }}
                                                size={30}
                                                isHalf={true} // Enables half-star support
                                                activeColor="gold"
                                                isEditable={false}
                                                name="accomplishmentsRating"
                                                // a11y={false}
                                                a11y={status === "Approved" ? false : true}
                                            /></div>}
                                    </Col>
                                </Row>

                                <Row xs="2">
                                    <Col md="9">
                                        <FormGroup className={`has-label`}>
                                            <Label className="form-label">List which of Peramal's core values (such as <span id="Innovation">Innovation,</span>{getTooltip('Innovation', innovationTooltipOpen, INNOVATION)}
                                                <span id="Collaboration"> Collaboration,</span> {getTooltip('Collaboration', collaborationtooltipOpen, COLLABORATION)}
                                                <span id="Integrity"> Integrity,</span> {getTooltip('Integrity', integrityTooltipOpen, INTEGRITY)}
                                                <span id="Compliance"> Compliance)</span> {getTooltip('Compliance', collaborationtooltipOpen, COMPLIANCE)}
                                                have been the most significant to you over the past year along with details?*</Label>
                                            <Input
                                                name="valuesCompanyPrioritized"
                                                type="textarea"
                                                placeholder=""
                                                value={valuesCompanyPrioritized}
                                                onChange={onChangeTextHandler}
                                                rows={5}
                                                disabled
                                            />
                                            {valuesCompanyPrioritizedState === "has-danger" ? (
                                                <label className="error">please enter valuesCompanyPrioritized!</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" className="rating-container">
                                        <div style={{ pointerEvents: 'none' }}>
                                            {valuesCompanyPrioritizedRating !== undefined && <ReactStars
                                                count={5}
                                                value={valuesCompanyPrioritizedRating}
                                                onChange={changeRating}
                                                size={30}
                                                isHalf={true} // Enables half-star support
                                                activeColor="gold"
                                                a11y={false}
                                            />}
                                        </div>
                                    </Col>
                                </Row>
                                <Row xs="2">
                                    <Col md="9">
                                        <FormGroup className={`has-label`}>
                                            <Label className="form-label">Remark *</Label>
                                            <Input
                                                name="valuesCompanyPrioritizedManagerRemark"
                                                type="textarea"
                                                placeholder=""
                                                value={valuesCompanyPrioritizedManagerRemark}
                                                onChange={onChangeTextHandler}
                                                rows={5}
                                                style={{ resize: 'both' }}
                                                disabled={status === "Approved" ? true : false}
                                                maxLength={500}
                                            />
                                            <span className="subtext">{MAX_CHARACTER_SIZE}</span>
                                            {valuesCompanyPrioritizedManagerRemarkState === "has-danger" ? (
                                                <label className="error">please enter accomplishments!</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" className="rating-container">
                                        {valuesCompanyPrioritizedManagerRemarkRating !== undefined && <div style={{ pointerEvents: status === 'Submitted' ? 'all' : 'none' }}>
                                            <ReactStars
                                                count={5}
                                                value={valuesCompanyPrioritizedManagerRemarkRating}
                                                onChange={(value) => {
                                                    changeRating('valuesCompanyPrioritizedManagerRemarkRating', value)
                                                }}
                                                size={30}
                                                isHalf={true} // Enables half-star support
                                                activeColor="gold"
                                                isEditable={false}
                                                name="accomplishmentsRating"
                                                a11y={status === "Approved" ? false : true}
                                            /></div>}
                                    </Col>
                                </Row>
                                <Row xs="2">
                                    <Col md="9">
                                        <FormGroup className={`has-label`}>
                                            <Label className="form-label">Please list your top three areas of development from the past year along with details*</Label>
                                            <Input
                                                name="valuesCompanyPrioritizedNextYear"
                                                type="textarea"
                                                placeholder=""
                                                value={valuesCompanyPrioritizedNextYear}
                                                onChange={onChangeTextHandler}
                                                rows={5}
                                                disabled
                                            />
                                            {valuesCompanyPrioritizedNextYearState === "has-danger" ? (
                                                <label className="error">please enter future valuesCompanyPrioritized!</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" className="rating-container">
                                        <div style={{ pointerEvents: 'none' }}>
                                            {valuesCompanyPrioritizedNextYearRating !== undefined && <ReactStars
                                                count={5}
                                                value={valuesCompanyPrioritizedNextYearRating}
                                                onChange={changeRating}
                                                size={30}
                                                isHalf={true} // Enables half-star support
                                                activeColor="gold"
                                                a11y={false}
                                            />}
                                        </div>
                                    </Col>
                                </Row>
                                <Row xs="2">
                                    <Col md="9">
                                        <FormGroup className={`has-label`}>
                                            <Label className="form-label">Remark *</Label>
                                            <Input
                                                name="valuesCompanyPrioritizedNextYearManagerRemark"
                                                type="textarea"
                                                placeholder=""
                                                value={valuesCompanyPrioritizedNextYearManagerRemark}
                                                onChange={onChangeTextHandler}
                                                rows={5}
                                                style={{ resize: 'both' }}
                                                disabled={status === "Approved" ? true : false}
                                                maxLength={500}
                                            />
                                            <span className="subtext">{MAX_CHARACTER_SIZE}</span>
                                            {valuesCompanyPrioritizedNextYearState === "has-danger" ? (
                                                <label className="error">please enter accomplishments!</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" className="rating-container">

                                        {valuesCompanyPrioritizedNextYearManagerRemarkRating !== undefined && <div style={{ pointerEvents: status === 'Submitted' ? 'all' : 'none' }}>
                                            <ReactStars
                                                count={5}
                                                value={valuesCompanyPrioritizedNextYearManagerRemarkRating}
                                                onChange={(value) => {
                                                    changeRating('valuesCompanyPrioritizedNextYearManagerRemarkRating', value)
                                                }}
                                                size={30}
                                                isHalf={true} // Enables half-star support
                                                activeColor="gold"
                                                isEditable={false}
                                                name="valuesCompanyPrioritizedNextYearManagerRemarkRating"
                                                // ally={status === "Approved" ? false : true}
                                                a11y={status === "Approved" ? false : true}
                                            />
                                        </div>}
                                    </Col>
                                </Row>
                                <Row xs="2">
                                    {/* <Label md="2">Development Areas *</Label> */}
                                    <Col md="9">
                                        <FormGroup className={`has-label`}>
                                            <Label className="form-label">Please list your top three areas of focus for development in the coming year along with details *</Label>
                                            <Input
                                                name="topDevelopmentAreas"
                                                type="textarea"
                                                placeholder=""
                                                value={topDevelopmentAreas}
                                                onChange={onChangeTextHandler}
                                                rows={5}
                                                disabled
                                            />
                                            {topDevelopmentAreasState === "has-danger" ? (
                                                <label className="error">please enter development areas!</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" className="rating-container">
                                        <div style={{ pointerEvents: 'none' }}>
                                            {topDevelopmentAreasRating !== undefined && <ReactStars
                                                count={5}
                                                value={topDevelopmentAreasRating}
                                                onChange={changeRating}
                                                size={30}
                                                isHalf={true} // Enables half-star support
                                                activeColor="gold"
                                                a11y={false}
                                            />}
                                        </div>
                                    </Col>
                                </Row>
                                <Row xs="2">
                                    {/* <Label md="2">Remark *</Label> */}
                                    <Col md="9">
                                        <FormGroup className={`has-label`}>
                                            <Label className="form-label">Remark *</Label>
                                            <Input
                                                name="topDevelopmentAreasManagerRemark"
                                                type="textarea"
                                                placeholder=""
                                                value={topDevelopmentAreasManagerRemark}
                                                onChange={onChangeTextHandler}
                                                rows={5}
                                                style={{ resize: 'both' }}
                                                disabled={status === "Approved" ? true : false}
                                                maxLength={500}
                                            />
                                            <span className="subtext">{MAX_CHARACTER_SIZE}</span>
                                            {topDevelopmentAreasState === "has-danger" ? (
                                                <label className="error">please enter accomplishments!</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" className="rating-container">

                                        {topDevelopmentAreasManagerRemarkRating !== undefined && <div style={{ pointerEvents: status === 'Submitted' ? 'all' : 'none' }}><ReactStars
                                            count={5}
                                            value={topDevelopmentAreasManagerRemarkRating}
                                            onChange={(value) => {
                                                changeRating('topDevelopmentAreasManagerRemarkRating', value)
                                            }}
                                            size={30}
                                            isHalf={true} // Enables half-star support
                                            activeColor="gold"
                                            isEditable={false}
                                            name="topDevelopmentAreasManagerRemarkRating"
                                            a11y={status === "Approved" ? false : true}
                                        /></div>}

                                    </Col>
                                </Row>
                                <Row xs="2">
                                    {/* <Label md="2">Future Development Areas *</Label> */}
                                    <Col md="9">
                                        <FormGroup className={`has-label`}>
                                            <Label className="form-label">Which of Peramal's core values (such as
                                                <span id="InnovationSecond"> Innovation,</span>{getTooltip('InnovationSecond', innovationTooltipOpen, INNOVATION)}
                                                <span id="CollaborationSecond"> Collaboration,</span>  {getTooltip('CollaborationSecond', collaborationtooltipOpen, COLLABORATION)}
                                                <span id="IntegritySecond"> Integrity,</span>  {getTooltip('IntegritySecond', integrityTooltipOpen, INTEGRITY)}
                                                <span id="ComplianceSecond">Compliance)</span> {getTooltip('ComplianceSecond', collaborationtooltipOpen, COMPLIANCE)} do you anticipate prioritizing the most in the coming year along with details*</Label>
                                            <Input
                                                name="topDevelopmentAreasNextYear"
                                                type="textarea"
                                                placeholder=""
                                                value={topDevelopmentAreasNextYear}
                                                onChange={onChangeTextHandler}
                                                rows={5}
                                                disabled
                                            />
                                            {topDevelopmentAreasNextYearState === "has-danger" ? (
                                                <label className="error">please enter future development area!</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" className="rating-container">
                                        <div style={{ pointerEvents: 'none' }}>
                                            {topDevelopmentAreasNextYearRating !== undefined && <ReactStars
                                                count={5}
                                                value={topDevelopmentAreasNextYearRating}
                                                onChange={changeRating}
                                                size={30}
                                                isHalf={true} // Enables half-star support
                                                activeColor="gold"
                                                a11y={false}
                                            />}
                                        </div>
                                    </Col>
                                </Row>
                                <Row xs="2">
                                    <Col md="9">
                                        <FormGroup className={`has-label`}>
                                            <Label className="form-label">Remark *</Label>
                                            <Input
                                                name="topDevelopmentAreasNextYearManagerRemark"
                                                type="textarea"
                                                placeholder=""
                                                value={topDevelopmentAreasNextYearManagerRemark}
                                                onChange={onChangeTextHandler}
                                                rows={5}
                                                style={{ resize: 'both' }}
                                                disabled={status === "Approved" ? true : false}
                                                maxLength={500}
                                            />
                                            <span className="subtext">{MAX_CHARACTER_SIZE}</span>
                                            {topDevelopmentAreasNextYearManagerRemarkState === "has-danger" ? (
                                                <label className="error">please enter accomplishments!</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" className="rating-container">

                                        {topDevelopmentAreasNextYearManagerRemarkRating !== undefined && <div style={{ pointerEvents: status === 'Submitted' ? 'all' : 'none' }}><ReactStars
                                            count={5}
                                            value={topDevelopmentAreasNextYearManagerRemarkRating}
                                            onChange={(value) => {
                                                changeRating('topDevelopmentAreasNextYearManagerRemarkRating', value)
                                            }}
                                            size={30}
                                            isHalf={true} // Enables half-star support
                                            activeColor="gold"
                                            isEditable={false}
                                            name="topDevelopmentAreasNextYearManagerRemarkRating"
                                            a11y={status === "Approved" ? false : true}
                                        /></div>}
                                    </Col>
                                </Row>
                                <Row xs="2">
                                    <Col md="4">
                                        <FormGroup className={`has-label`}>
                                            <Label className="form-label">Billable % *</Label>

                                            <Input
                                                name="billable"
                                                type="number"
                                                placeholder=""
                                                value={billable}
                                                onChange={onChangeTextHandler}
                                                rows={5}
                                                style={{ width: 300 }}
                                                disabled={status === "Approved" ? true : false}
                                            />

                                            {attendanceState === "has-danger" ? (
                                                <label className="error">please enter accomplishments!</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>

                                    <Col md="4">
                                        <FormGroup className={`has-label`}>
                                            <Label className="form-label">Non Billable % *</Label>

                                            <Input
                                                name="nonBillable"
                                                type="number"
                                                placeholder=""
                                                value={nonBillable}
                                                onChange={onChangeTextHandler}
                                                rows={5}
                                                style={{ width: 300 }}
                                                disabled={status === "Approved" ? true : false}
                                                maxLength={500}
                                            />
                                            {attendanceState === "has-danger" ? (
                                                <label className="error">please enter accomplishments!</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row xs="2">
                                    <Col md="4">
                                        <FormGroup className={`has-label`}>
                                            <Label className="form-label">Attendance % *</Label>

                                            <Input
                                                name="attendance"
                                                type="number"
                                                placeholder=""
                                                value={attendance}
                                                onChange={onChangeTextHandler}
                                                rows={5}
                                                style={{ width: 300 }}
                                                disabled={status === "Approved" ? true : false}
                                            />
                                            {attendanceState === "has-danger" ? (
                                                <label className="error">please enter accomplishments!</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>

                                    <Col md="4">
                                        <FormGroup className={`has-label ${ratingState} `}>
                                            <Label className="form-label">Rating*</Label>
                                            <Input type="select" name="accountType" value={rating} onChange={(e) => { handleSelectRating(e) }} id="exampleSelect" style={{ width: 300 }}
                                                disabled={status === "Approved" ? true : false}
                                            >
                                                <option value={null}>select rating</option>
                                                <option value="Needs Improvement">Needs Improvement</option>
                                                <option value="Meets Expectation">Meets Expectation</option>
                                                <option value="Exceeds Expectation">Exceeds Expectation</option>
                                            </Input>
                                            {ratingState === "has-danger" ? (
                                                <label className="error">please select rating</label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row xs="2">
                                    <Col md="4" style={{ margin: '15px 0px' }}>
                                        Employee's Average Rating : <b>{employeeAverageRating}/5</b>
                                    </Col>

                                    <Col md="4" style={{ margin: '15px 0px' }}>
                                        Manager's Average Rating : <b>{managerAverageRating}/5</b>
                                    </Col>
                                </Row>
                            </Form>
                            <div style={{ float: "right" }}>
                                <Button md="2" color="info" size="sm" disabled={status === "Approved" ? true : false} onClick={handleSave}>
                                    {submitLoader ? (
                                        <Spinner size="sm" color="light" />
                                    ) : (
                                        'Save'
                                    )}</Button>
                                <Button md="2" color="primary" size="sm" disabled={status === "Approved" ? true : false} onClick={handleSubmit}>
                                    {approveLoader ? (
                                        <Spinner size="sm" color="light" />
                                    ) : (
                                        'Approve'
                                    )}
                                </Button>
                            </div>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
        </div>
    </>)
}

export default ManagerAppraisal;

