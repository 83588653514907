import { FETCH_ALL_COUNTRIES } from "../actions/countryAction";
const countryReducer = function (state = [], action) {
    switch (action.type) {
        case FETCH_ALL_COUNTRIES:
            return action.countries;
        default:
            return state;
    }
};

export default countryReducer;