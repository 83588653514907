import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  UncontrolledTooltip
} from "reactstrap";
import moment from 'moment';
import { REQUIRD_MESSAGE } from "../variables/constants";
import ReactDatetime from "react-datetime";
import Switch from "react-switch";
import Select from "react-select";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NotificationAlert from "react-notification-alert";
import { uploadFile, deleteFile } from "../services/index";
import FileUpload from "../components/CustomUpload/FileUpload";
import { verifyEmail, verifyLength, verifyNumber, verifySentense, checkMimeType, maxSelectFile, checkFileSize } from "../components/common/validation";
import { getAuthenticatedUserDetails, updateEmployeeBasicInfo } from "../redux/actions/employeeBasicInfoAction";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadProgress: 0,
      cid: 1,
      fname: "",
      lname: "",
      addressCur: "",
      emailPersonal: "",
      gender: "",
      doj: "",
      fnameState: "",
      mnameState: "",
      lnameState: "",
      empidState: "",
      phone1State: "",
      phone2State: "",
      dobState: "",
      emailPersonalState: "",
      emailOfficialState: "",
      addressCurState: "",
      addressPerState: "",
      genderState: "",
      dojState: "",
      employee: {},
      imagePreviewUrl: "",
      resumeFile: null,
      file: null,
      selectedFile: null,
      selectedImage: null,
      isEmailExistState: ""
    };
    this.handlePictureChange = this.handlePictureChange.bind(this);
    this.handleRemoveImage = this.handleRemoveImage.bind(this);
  }
  async componentWillMount() {
    if (this.props.employee === null) {
      const response = await this.props.getAuthenticatedUserDetails();
      if (response.data) {
        let employee = response.data;
        this.setState({
          employee: employee,
          resumeFile: employee.resume == "" || null ? employee.resume : JSON.parse(employee.resume),
          imagePreviewUrl: employee.userImage == "" ? employee.userImage : JSON.parse(employee.userImage)
        });
      }

    } else {
      this.setState({
        employee: this.props.employee,
        resumeFile: this.props.employee.resume == "" || null ? this.props.employee.resume : JSON.parse(this.props.employee.resume),
        imagePreviewUrl: this.props.employee.userImage == "" ? this.props.employee.userImage : JSON.parse(this.props.employee.userImage)
      });
    }

  }

  handleStartDate = (e, stateName) => {
    if (e > 0) {
      this.setState({ [stateName + "State"]: "has-success" });
    } else {
      this.setState({ [stateName + "State"]: "has-danger" });
    }
    if (stateName == 'doj') {
      this.setState({
        dojName: moment(e).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
        employee: {
          ...this.state.employee,
          doj: moment(e).format('YYYY-MM-DD')
        }

      });
    } else {
      this.setState({
        dobName: moment(e).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
        employee: {
          ...this.state.employee,
          dob: moment(e).format('YYYY-MM-DD')
        }

      });

    }
  }
  handleSelectStatus = (e, stateName) => {
    let employee = this.state.employee;
    employee[stateName] = parseInt(e.value);
    this.setState({
      employee: { ...employee }
    })
  }

  //   isEmailExists = async (emailOfficial) => {
  //     const value = await checkEmailAlreadyExists(this.props.cid, emailOfficial);
  //     if (value === true) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  //   isEmpidExists = async (cid, empid) => {
  //     let user = localStorage.getItem('user');
  //     const value = await checkEmpIdAlreadyExists(user.cid, empid);
  //     if (value === true) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  onChangeNumber(e, stateName) {
    let employee = { ...this.state.employee };
    if (e.target.value.length > 0) {
      this.setState({ [stateName + "State"]: "has-success" });
    } else {
      this.setState({ [stateName + "State"]: "has-danger" });
    }
    employee[e.target.name] = e.target.value.replace(/\D/, '');
    this.setState({ employee: employee });
  }
  onUploadProgress = (progressEvent) => {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );

    this.setState({ uploadProgress: percentCompleted });
  };
  handleImageChange = async (e) => {
    e.preventDefault();
    let checkMimeTypeValid = false;
    const types = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
    checkMimeTypeValid = checkMimeType(e, types);
    let maxSelectFileValid = false;
    maxSelectFileValid = maxSelectFile(e, 1);
    let checkFileSizeValid = false;
    checkFileSizeValid = checkFileSize(e);
    if (checkMimeTypeValid && maxSelectFileValid && checkFileSizeValid) {
      if (this.props.formType === "Add") {
        let files = e.target.files[0];
        this.setState({
          selectedFile: files,
          resumeFile: 'newResume',
          employee: {
            ...this.state.employee,
            resume: 'newResume'
          }
        })

      }
      else {
        let files = e.target.files[0];
        let filePath = `${this.state.employee.cid}/RESUMES/${this.state.employee.eid}`;
        const data = new FormData()
        data.append('filePath', filePath);
        data.append('files', files);
        if (this.state.resumeFile != null) {
          const response = await deleteFile(this.state.resumeFile[0]);
        }
        const response = await uploadFile(data, this.onUploadProgress);
        if (response.success) {
          let message = {
            message: 'Employee resume uploded successfully.',
            type: "success",
          }
          this.handleMessage(message);
          this.setState({
            file: response.data[0],
            resumeFile: response.data,
            employee: {
              ...this.state.employee,
              resume: JSON.stringify(response.data)
            }
          });
        } else {
          let message = {
            message: 'Error accured while uploading resume.',
            type: "warning",
          }
          this.handleMessage(message);
        }
      }
    } else {
      if (checkMimeTypeValid == false) {
        let message = {
          message: 'Uploded resume is not a supported format!',
          type: "warning",
        }
        this.handleMessage(message);
      }
      if (maxSelectFileValid == false) {
        let message = {
          message: 'Only 1 file can be uploaded at a time!',
          type: "warning",
        }
        this.handleMessage(message);
      } if (checkFileSizeValid == false) {
        let message = {
          message: 'Uploded resume size is too large, please pick a smaller file!',
          type: "warning",
        }
        this.handleMessage(message);
      }
    }

  }
  handleMessage = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message.message,
      type: message.type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 2
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  }
  handlePictureChange = async (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let checkMimeTypeValid = false;
    const types = ['image/png', 'image/jpeg']
    checkMimeTypeValid = checkMimeType(e, types);
    let maxSelectFileValid = false;
    maxSelectFileValid = maxSelectFile(e, 1);
    let checkFileSizeValid = false;
    checkFileSizeValid = checkFileSize(e);
    if (checkMimeTypeValid && maxSelectFileValid && checkFileSizeValid) {
      let reader = new FileReader();
      let files = e.target.files[0];
      reader.onloadend = () => {
        this.setState({
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(files);
      let filePath = `${this.state.employee.cid}/PROFILEPHOTO/${this.state.employee.eid}`;
      const data = new FormData()
      data.append('filePath', filePath);
      data.append('files', files);
      if (this.state.imagePreviewUrl != "") {
        const response = await deleteFile(this.state.imagePreviewUrl[0]);
      }
      const response = await uploadFile(data, this.onUploadProgress);
      if (response.success) {
        let message = {
          message: 'Profile picture uploded successfully.',
          type: "success",
        }
        this.handleMessage(message);
        this.setState({
          file: response.data,
          imagePreviewUrl: response.data,
          employee: {
            ...this.state.employee,
            userImage: JSON.stringify(response.data)
          }
        });
      } else {
        let message = {
          message: 'Error accured while uploading profile!',
          type: "warning",
        }
        this.handleMessage(message);
      }

    } else {
      if (checkMimeTypeValid == false) {
        let message = {
          message: 'Profile picture is not a supported format!',
          type: "warning",
        }
        this.handleMessage(message);
      }
      if (maxSelectFileValid == false) {
        let message = {
          message: 'Only 1 profile picture can be uploaded at a time!',
          type: "warning",
        }
        this.handleMessage(message);
      } if (checkFileSizeValid == false) {
        let message = {
          message: 'Profile picture size is too large,please pick a smaller file!',
          type: "warning",
        }
        this.props.handleMessage(message);
      }
    }
  }

  handleClick = (refs) => {
    refs.fileInput.click();
  }
  handleRemoveImage = async () => {
    if (this.state.employee && this.state.employee.userImage && this.state.employee.userImage != "" || null) {
      const response = await deleteFile(this.state.imagePreviewUrl[0]);
      this.setState({
        selectedImage: null,
        imagePreviewUrl: "",
        employee: {
          ...this.state.employee,
          userImage: ""
        }
      });
    } else {
      this.setState({
        selectedImage: null,
        imagePreviewUrl: "",
        employee: {
          ...this.state.employee,
          userImage: ""
        }
      });
    }
  }
  handleRemove = async (refs) => {
    if (this.state.employee && this.state.employee.resume && this.state.employee.resume != "" || null) {
      const response = await deleteFile(this.state.resumeFile[0]);
      this.setState({
        file: null,
        selectedFile: null,
        resumeFile: null,
        employee: {
          ...this.state.employee,
          resume: null
        }
      });
    }
    else {
      this.setState({
        file: null,
        selectedFile: null,
        resumeFile: null,
        resumeFile: null,
        employee: {
          ...this.state.employee,
          resume: null
        }
      });
    }
  }

  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
        if (verifyEmail(event.target.value)) {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value.trim();
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-success"
          });
        } else {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value;
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-danger"
          });
        }
        break;
      case "length":
        if (verifyLength(event.target.value, stateNameEqualTo)) {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value.trim();
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-success"
          });
        } else {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-danger"
          });
        }
        break;
      case "number":
        if (verifyNumber(event.target.value, stateNameEqualTo)) {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value.trim();
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-success"
          });
        } else {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value;
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-danger"
          });
        }
        break;
      case "sentense":
        if (verifySentense(event.target.value, stateNameEqualTo)) {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value;
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-success"
          });
        } else {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value;
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-danger"
          });
        }
        break;
      default:
        break;
    }
  };
  handleSave = async () => {
    let {
      fname,
      lname,
      gender,
      emailPersonal,
      emailOfficial,
      phone1,
      dob,
      doj } = this.state.employee;
    if (fname != "" && lname != "" && gender != "" && emailOfficial != "" && phone1 != "" && dob != "" && doj != "") {
      if (this.state.fnameState === "has-danger" || this.state.lnameState === "has-danger" || this.state.dobState === "has-danger" ||
        this.state.genderState === "has-danger" || this.state.phone1State === "has-danger"
        || this.state.emailOfficialState === "has-danger" || this.state.dojState === "has-danger"
      ) {
        let message = {
          message: 'Please enter valid information',
          type: "warning",
        }
        this.props.handleMessage(message);
      }
      else {
        const result = await this.props.updateEmployeeBasicInfo(this.state.employee);
        if (result.success) {
          this.setState({
            employee: result.data
          })
          let message = {
            message: "Your profile updated successfully.",
            type: "success",
          };
          this.handleMessage(message);
        } else {
          let message = {
            message: 'Error occurred while updating profile!',
            type: "warning",
          };
          this.handleMessage(message);
        }
      }
    } else {
      if (fname == "") {
        this.setState({ fnameState: "has-danger" });
      }
      if (lname == "") {
        this.setState({ lnameState: "has-danger" });
      }
      if (gender == "") {
        this.setState({ genderState: "has-danger" });
      }
      if (emailOfficial == "") {
        this.setState({ emailOfficialState: "has-danger" });
      }
      if (phone1 == "") {
        this.setState({ phone1State: "has-danger" });
      }
      if (dob == "") {
        this.setState({ dobState: "has-danger" });
      }
      if (doj == "") {
        this.setState({ dojState: "has-danger" });
      }
    }

  };

  render() {
    return (
      <>
        <div className="content">
          <div className="rna-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Edit Profile</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row className="justify-content-center">
                      {/* <Col sm="4" style={{paddingLeft:'50px', paddingRight:'50px'}}>
         <Label>PSPL</Label>
          <FormGroup className={`has-label ${this.state.seriesState}`}>
              <Input
                name="series"
                placeholder="Series..."
                type="series"
                value={this.state.employee.series}
                maxLength="20"
                onChange={e => this.change(e, "series", "length", 1)}
                onFocus={e => this.setState({ seriesFocus: true })}
                onBlur={e => this.setState({ seriesFocus: false })}
              />
              {this.state.seriesState === "has-danger" ? (
                <label className="error">{REQUIRD_MESSAGE}</label>
              ) : null}
            </FormGroup>
         </Col> */}
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Label>First Name *</Label>
                        <FormGroup className={`has-label ${this.state.fnameState}`}>
                          <Input
                            name="fname"
                            placeholder="First Name..."
                            type="text"
                            value={this.state.employee.fname}
                            maxLength="20"
                            onChange={e => this.change(e, "fname", "length", 1)}
                            onFocus={e => this.setState({ firstnameFocus: true })}
                            onBlur={e => this.setState({ firstnameFocus: false })}
                          />
                          {this.state.fnameState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Label>Middle Name </Label>
                        <FormGroup className={`has-label ${this.state.mnameState}`}>
                          <Input
                            name="mname"
                            placeholder="Middle Name..."
                            type="text"
                            maxLength="10"
                            value={this.state.employee.mname}
                            onChange={e => this.change(e, "mname", "length", 1)}
                            onFocus={e => this.setState({ middlenameFocus: true })}
                            onBlur={e => this.setState({ middlenameFocus: false })}
                          />
                          {this.state.mnameState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Label>Last Name *</Label>
                        <FormGroup className={`has-label ${this.state.lnameState}`}>
                          <Input
                            name="lname"
                            placeholder="Last Name..."
                            type="text"
                            maxLength="20"
                            value={this.state.employee.lname}
                            onChange={e => this.change(e, "lname", "length", 1)}
                            onFocus={e => this.setState({ lastnameFocus: true })}
                            onBlur={e => this.setState({ lastnameFocus: false })}
                          />
                          {this.state.lnameState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Label>Date of birth *</Label>
                        <FormGroup className={`has-label ${this.state.dobState}`}>
                          <ReactDatetime
                            timeFormat={false}
                            name="dob"
                            inputProps={{ placeholder: "Date of birth", className: "form-control", value: this.state.employee.dob !== null ? moment(this.state.employee.dob).format('DD-MMM-YYYY') : this.state.employee.dob }}
                            value={this.state.employee.dob}
                            onChange={e => this.handleStartDate(e, "dob", "length", 1)}
                            closeOnSelect={true}
                          />
                          {this.state.dobState === "has-danger" ? (
                            <label className="error hide-has-danger">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Label>Gender *</Label>
                        <FormGroup className={`has-label ${this.state.genderState}`}>
                          <Input
                            name="gender"
                            placeholder="Gender..."
                            type="select"
                            defaultValue={this.state.employee.gender}
                            value={this.state.employee.gender}
                            onChange={e => this.change(e, "gender", "length")}
                            onFocus={e => this.setState({ genderFocus: true })}
                            onBlur={e => this.setState({ genderFocus: false })}
                          ><option value="" disabled>Select gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                          </Input>
                          {this.state.genderState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Label>Email Personal </Label>
                        <FormGroup className={`has-label ${this.state.emailPersonalState}`}>
                          <Input
                            name="emailPersonal"
                            placeholder="Email Personal..."
                            type="email"
                            maxLength="40"
                            value={this.state.employee.emailPersonal}
                            onChange={e => this.change(e, "emailPersonal", "email")}
                            onFocus={e => this.setState({ emailPersonalFocus: true })}
                            onBlur={e => this.setState({ emailPersonalFocus: false })}
                          />
                          {this.state.emailPersonalState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Label>Mobile Number *</Label>
                        <FormGroup className={`has-label ${this.state.phone1State}`}>
                          <Input
                            name="phone1"
                            placeholder="Mobile Number..."
                            type="text"
                            maxLength="10"
                            value={this.state.employee.phone1}
                            onChange={e => this.onChangeNumber(e, "phone1", "number", 1)}
                            onFocus={e => this.setState({ phone1Focus: true })}
                            onBlur={e => this.setState({ phone1Focus: false })}
                            disabled={this.props.formType === "Update" ? true : false}
                          />
                          {this.state.phone1State === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Label>Alternate Number </Label>
                        <FormGroup className={`has-label ${this.state.phone2State}`}>
                          <Input
                            name="phone2"
                            placeholder="Alternate Number..."
                            type="text"
                            maxLength="10"
                            value={this.state.employee.phone2}
                            onChange={e => this.onChangeNumber(e, "phone2", "number", 1)}
                            onFocus={e => this.setState({ phone2Focus: true })}
                            onBlur={e => this.setState({ phone2Focus: false })}
                          />
                          {this.state.phone2State === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Label>Email Official *</Label>
                        <FormGroup className={`has-label ${this.state.emailOfficialState} ${this.state.isEmailExistState}`}>
                          <Input
                            name="emailOfficial"
                            placeholder="Email Official..."
                            type="text"
                            maxLength="40"
                            value={this.state.employee.emailOfficial}
                            onChange={e => this.change(e, "emailOfficial", "email")}
                            onFocus={e => this.setState({ emailOfficialFocus: true })}
                            onBlur={e => this.setState({ emailOfficialFocus: false })}
                            disabled={true}
                          />
                          {this.state.emailOfficialState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                          {this.state.isEmailExistState === "has-danger" ? (
                            <label className="error">{"Email is already exist"}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Label>Present Address </Label>
                        <FormGroup className={`has-label ${this.state.addressCurState}`}>
                          <Input
                            name="addressCur"
                            placeholder="Present Address"
                            type="text"
                            maxLength="100"
                            value={this.state.employee.addressCur}
                            onChange={e => this.change(e, "addressCur", "sentense")}
                            onFocus={e => this.setState({ addressCurFocus: true })}
                            onBlur={e => this.setState({ addressCurFocus: false })}
                          />
                          {this.state.addressCurState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Label>Permanent Address </Label>
                        <FormGroup className={`has-label ${this.state.addressPerState}`}>
                          <Input
                            name="addressPer"
                            placeholder="Permanent Address..."
                            type="text"
                            maxLength="100"
                            value={this.state.employee.addressPer}
                            onChange={e => this.change(e, "addressPer", "sentense")}
                            onFocus={e => this.setState({ addressPerFocus: true })}
                            onBlur={e => this.setState({ addressPerFocus: false })}
                          />
                          {this.state.addressPerState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>

                      </Col>
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Label>Experience</Label>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                          <div style={{ width: '141px' }}>
                            <Select closeMenuOnSelect={false} placeholder="Year"
                              classNamePrefix="react-select" value={this.state.employee.experienceYear == null ? this.state.employee.experienceYear : { value: parseInt(this.state.employee.experienceYear), label: String(this.state.employee.experienceYear) }} name="experienceYear" className="react-select info"
                              defaultValue={this.state.employee.experienceYear} onChange={e => this.handleSelectStatus(e, "experienceYear")}
                              options={[
                                { value: 1, label: "1" },
                                { value: 2, label: "2" },
                                { value: 3, label: "3" },
                                { value: 4, label: "4" },
                                { value: 5, label: "5" },
                                { value: 6, label: "6 " },
                                { value: 7, label: "7" },
                                { value: 8, label: "8 " },
                                { value: 9, label: "9" },
                                { value: 10, label: "10" },
                                { value: 11, label: "11" },
                                { value: 12, label: "12 " },
                                { value: 13, label: "13" },
                                { value: 14, label: "14" },
                                { value: 15, label: "15" },
                                { value: 16, label: "16" },
                                { value: 17, label: "17" },
                                { value: 18, label: "18" },
                                { value: 19, label: "19" },
                                { value: 20, label: "20" },
                                { value: 21, label: "21" },
                                { value: 22, label: "22" },
                                { value: 23, label: "23" },
                                { value: 24, label: "24" },
                                { value: 25, label: "25" },
                              ]}

                            >
                            </Select>
                          </div>
                          <div style={{ width: '141px' }}>
                            <Select closeMenuOnSelect={false} placeholder="Month" className="react-select info"
                              classNamePrefix="react-select" value={this.state.employee.experienceMonth == null ? this.state.employee.experienceMonth : { value: parseInt(this.state.employee.experienceMonth), label: String(this.state.employee.experienceMonth) }} name="experienceYear" defaultValue={this.state.employee.experienceMonth}
                              onChange={e => this.handleSelectStatus(e, "experienceMonth")}
                              options={[
                                { value: 1, label: "1" },
                                { value: 2, label: "2" },
                                { value: 3, label: "3" },
                                { value: 4, label: "4" },
                                { value: 5, label: "5" },
                                { value: 6, label: "6 " },
                                { value: 7, label: "7" },
                                { value: 8, label: "8 " },
                                { value: 9, label: "9" },
                                { value: 10, label: "10" },
                                { value: 11, label: "11" },
                              ]}

                            >
                            </Select>
                          </div>
                        </div>
                      </Col>
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px', }}>
                        <Label>Date of joining *</Label>
                        <FormGroup className={`has-label ${this.state.dojState}`}>
                          <ReactDatetime
                            timeFormat={false}
                            name="doj"
                            inputProps={{ placeholder: "Date of joining", className: "form-control", value: this.state.employee.dob !== null ? moment(this.state.employee.doj).format('DD-MMM-YYYY') : this.state.employee.doj }}
                            value={this.state.employee.doj}
                            onChange={e => this.handleStartDate(e, "doj", "length", 1)}
                            closeOnSelect={true}
                          />
                          {this.state.dojState === "has-danger" ? (
                            <label className="error">{REQUIRD_MESSAGE}</label>
                          ) : null}
                        </FormGroup>
                        {/* <br />
              <Label >Upload Profile Picture</Label>
              <ImageUpload
                addBtnColor="default"
                changeBtnColor="default"
                component="employee"
                accept="image/png,image/jpeg"
                handleImageChange={this.handlePictureChange}
                handleClick={this.handleClick}
                handleRemove={this.handleRemoveImage}
                imagePreviewUrl={this.state.imagePreviewUrl === "" ? this.props.image : this.state.imagePreviewUrl}
                file={this.state.selectedImage}
              />
              <br />
              <h5 style={{}}>max upload size: 5MB
                            <p>upload png,jpeg images only</p></h5> */}
                      </Col>
                      <Col sm="4" style={{ paddingLeft: '35px', paddingRight: '20px' }} >
                        <FileUpload
                          addBtnColor="default"
                          changeBtnColor="default"
                          handleImageChange={this.handleImageChange}
                          handleClick={this.handleClick}
                          accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                          handleRemove={this.handleRemove}
                          imagePreviewUrl={this.state.imagePreviewUrl}
                          file={this.state.resumeFile === "" ? this.props.file : this.state.resumeFile}
                        />
                        <br />
                        <h5 style={{}}>max upload size: 5MB
                          <p>upload pdf,doc,docx files only</p></h5>
                      </Col>
                      <Col sm="4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button className="btn-fill" color="primary" type="submit" onClick={this.handleSave}>
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" />
                    <div className="block block-two" />
                    <div className="block block-three" />
                    <div className="block block-four" />
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar"
                        src={this.state.imagePreviewUrl != "" || null ? this.state.imagePreviewUrl : require("assets/img/emilyz.jpg")}
                      />
                      <h5 className="title">{this.state.employee.fname + " " + this.state.employee.lname}</h5>
                    </a>
                  </div>
                  <div className="fileinput text-center" style={{ display: 'flex', justifyContent: 'center' }}>
                    <input type="file" onChange={this.handlePictureChange} accept="image/png,image/jpeg"
                      onClick={(event) => { event.target.value = null }} ref="fileInput" />
                    <Button
                      type="file"
                      color="primary"
                      style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 3, paddingBottom: 3 }}
                      className={this.props.changeBtnClasses}
                      onClick={() => this.handleClick(this.refs)}
                    >
                      Change
                    </Button>
                    <Button
                      style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 3, paddingBottom: 3 }}
                      color={this.props.removeBtnColor}
                      className={this.props.removeBtnClasses}
                      onClick={() => this.handleRemoveImage(this.refs)}
                    >
                      Remove
                    </Button>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <div>
              <Button
                color={this.props.changeBtnColor}
                style={{padding:8, marginRight:5}}
                className={this.props.changeBtnClasses}
                onClick={this.handleClick}
              >
                Change
              </Button>
              </div>
   
              <div>
              <Button
                style={{ padding:8, marginLeft:5}}
                color={this.props.removeBtnColor}
                className={this.props.removeBtnClasses}
                onClick={this.handleRemoveImage}
              >
                Remove
              </Button>
              </div> */}
                  </div>
                  <div className="card-description">
                    Do not be scared of the truth because we need to restart the
                    human foundation in truth And I love you like Kanye loves
                    Kanye I love Rick Owens’ bed design but the back is...
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="button-container">
                    <Button className="btn-icon btn-round" color="facebook">
                      <i className="fab fa-facebook" />
                    </Button>
                    <Button className="btn-icon btn-round" color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button className="btn-icon btn-round" color="google">
                      <i className="fab fa-google-plus" />
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  getAuthenticatedUserDetails: bindActionCreators(getAuthenticatedUserDetails, dispatch),
  updateEmployeeBasicInfo: bindActionCreators(updateEmployeeBasicInfo, dispatch)
});

export const mapStateToProps = ({ auth, employeeBasicInfo }) => ({
  auth: auth,
  employee: employeeBasicInfo
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
