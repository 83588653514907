import axios from 'axios';

export const getAllProjectsAPI = (cid) => {
  return axios
    .get(`/api/v1/projects`, {
      params: {
        cid: cid
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getProjectAllContactsAPI = (pid, cid) => {
  return axios
    .get(`/api/v1/project_contacts`, {
      params: {
        pid: pid,
        cid: cid
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getProjectAllEmployeesAPI = (pid, cid) => {
  return axios
    .get(`/api/v1/employees-by-projectId`, {
      params: {
        pid: pid,
        cid: cid
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const deleteProjectEmployeeAPI = (eid, pid, cid) => {
  return axios
    .get(`/api/v1/delete-employee-from-project`, {
      params: {
        eid: eid,
        pid: pid,
        cid: cid
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getAllProjectTypesAPI = (cid) => {
  return axios
    .get(`/api/v1/project-type`, {
      params: {
        cid: cid
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const addUpdateProject = (project) => {

  return axios.post(`/api/v1/add-update-project`, project, {
  }).then(function (response) {


    if (response.status === 200) {
      return { success: true, data: response.data };
    }
  })
    .catch(function (error) {
      return { success: false };
    });
}


export const addUpdateProjectContacts = (projectContact) => {

  return axios.post(`/api/v1/add-update-project-contacts`, projectContact, {
  }).then(function (response) {
    if (response.status === 200) {
      return { success: true };
    }
  })
    .catch(function (error) {
      return { success: false };
    });

}

export const addUpdateProjectEmployees = (resources) => {
  return axios.post(`/api/v1/add-update-employee-project`, resources)
    .then(function (response) {
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}


export const getAllAllocationAPI = (cid, eid) => {
  return axios
    .get(`/api/v1/allocation-percent`, {
      params: {
        cid: cid,
        eid:eid
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

