import axios from 'axios';

export const getAllDepartmentsAPI = (cid) => {
    return axios.get(`/api/v1/departments`, {
      params: { 
          cid: cid 
        }
    }).then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });  
}

export const addUpdateDepartment = (departmentDTO) => {
    return axios.post(`/api/v1/add-update-departments`, departmentDTO)
    .then(function (response) {
      if (response.status == 200) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
  }