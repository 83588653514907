import axios from 'axios';

export const getAllEmployeesAPI = (cid) => {
  return axios.get(`/api/v1/employees`, {
    params: {
      cid: cid,
    }
  }).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const deleteEmployeeAPI = (cid, eid) => {
  return axios.get(`/api/v1/delete-employee`, {
    params: {
      cid: cid,
      eid: eid
    }
  }).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const addNewEmployeeAPI = (employee) => {
  return axios.post(`/api/v1/registerEmployee`, employee)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const updateEmployeeBasicInfoAPI = (employee) => {
  return axios.post(`/api/v1/update-employee-basic-info`, employee)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getEmployeeExtraInfoAPI = (cid, eid) => {
  return axios.get(`/api/v1/employee-extra-info`, {
    params: {
      cid: cid,
      eid: eid
    }
  }).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}


export const addUpdateEmployeeExtraInfoAPI = (employee) => {
  return axios.post(`/api/v1/add-update-employee-extra-info`, employee)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getEmployeePayrollAPI = (cid, eid) => {
  return axios.get(`/api/v1/employee-payroll`, {
    params: {
      cid: cid,
      eid: eid,
    }
  }).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const addUpdateEmployeePayrollInfoAPI = (employee) => {
  return axios.post(`/api/v1/update-employee-payroll-info`, employee)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getEmployeeAllProjectsAPI = (cid, eid) => {
  return axios.get(`/api/v1/employee-projects`, {
    params: {
      cid: cid,
      eid: eid
    }
  }).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const getAllEmployeesWithRoleAPI = (cid) => {
  return axios.get(`/api/v1/employees-role-by-cid`, {
    params: {
      cid: cid
    }
  }).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const calculateEmployeePayroll = (cid, ctc, eid, id) => {
  return axios.get(`/api/v1/calculate-payroll-components`, {
    params: {
      cid: cid,
      ctc: ctc,
      eid: eid,
      id: id
    }
  }).then(function (response) {
    if (response.data) {
      return response.data
    } else {
      return false;
    }
  })
    .catch(function (error) {
      return false;
    });
}

export const getAllEmployeesByAdminAPI = (cid,searchValue) => {
  return axios.get(`/api/v1/get-all-admin-employee`, {
    params: {
      cid: cid,
      searchValue:searchValue
    }
  }).
    then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return { success: false, };
    });
}

export const fetchEmployeeBasicInfoAPI = (cid, eid) => {
  return axios
    .get('/api/v1/employee-basic-info', {
      params: {
        cid:cid,
        eid:eid
      },
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data }
      }
    })
    .catch(function (error) {
      return error;
    });
};