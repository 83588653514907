import axios from 'axios';

export const getAllCompaniesAPI = (cid) => {
  return axios
    .get(`/api/v1/modules`, {
      params: {
        cid: cid
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const addUpdateModuleAPI = (module) => {
  return axios
    .post(`/api/v1/add-update-module`, module)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {


      return { success: false };
    });
}
