
import axios from 'axios';

export const getAdminQuarterlyInvoicesAPI = (cid, pid, eid, month, quarter, year) => {
    let quarterlyInvoiceObject = {
        cid: cid,
        eid: eid,
        pid: pid,
        month: 0,
        quarter: quarter,
        year: parseInt(year)
    };
    return axios.post(`/api/v1/admin-quarterly-invoice`, quarterlyInvoiceObject)
        .then(function (response) {
            if (response.data) {
                return { success: true, data: response.data };
            }
        })
        .catch(function (error) {
            return { success: false };
        });
}

export const getAdminMonthlyInvoicesAPI = (cid, pid, eid, month, quarter, year) => {
    let monthlyInvoiceObject = {
        cid: cid,
        eid: eid,
        pid: pid,
        month: parseInt(month),
        quarter: 0,
        year: parseInt(year)
    };
    return axios.post(`/api/v1/admin-monthly-invoice`, monthlyInvoiceObject)
        .then(function (response) {
            if (response.data) {
                return { success: true, data: response.data };
            }
        })
        .catch(function (error) {
            return { success: false };
        });
}

export const getEmployeesExpenseReportAPI = (data) => {

    return axios.post(`/api/v1/admin/expense-report`, data)
        .then(function (response) {
            if (response.data) {
                return { success: true, data: response.data };
            }
        })
        .catch(function (error) {
            return { success: false };
        });
}