import React from "react";

import { Container, Col } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { resetPassword } from "../../services/index";
import ChangePassword from "../ChangePassword";


class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailOfficial: '',
      empid: null,
      isLoading: false,
      isSuccess: false,
      token: null,
    }
  }

  UNSAFE_componentWillMount() {
    const url_string = window.location.href
    const url = new URL(url_string);
    const token = url.searchParams.get('token');
    if (token === null || token !== "") {
      this.setState({
        token: token
      })
    } else {
      this.props.history.push('/auth/login');
    }
  }

  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // function that verifies if value contains only numbers
  verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };
  handleResetPassword = async (password) => {
    this.setState({
      isLoading: true
    })
    const response = await resetPassword(window.btoa(password), this.state.token);
    if (response.success) {
      let options = {};
      options = {
        place: "tr",
        message: "Password reset successfully.",
        type: "success",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 2
      };
      if (this.refs.notificationAlert) {
        this.refs.notificationAlert.notificationAlert(options);
      } setTimeout(() => {
        this.props.history.push('/auth/login');
      }, 3000);
    } else {
      this.setState({
        isLoading: false
      }, () => {
        let options = {};
        options = {
          place: "tr",
          message: 'Error occurred while resetting password!',
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 2
        };
        if (this.refs.notificationAlert) {
          this.refs.notificationAlert.notificationAlert(options);
        }
      })
    }
  }
  handleMessage = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message.message,
      type: message.type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 2
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  }
  handleSignIn = () => {
    this.setState({
      isSuccess: true
    })
    this.props.history.push('/auth/login');
  }
  render() {
    return (
      <>
        <div className="content custom-login-content" >
          <div className="rna-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6" style={{ marginTop: '100px' }}>
              <ChangePassword formType={'ResetPassword'} handleMessage={this.handleMessage} handleResetPassword={this.handleResetPassword}
                isLoading={this.state.isLoading}
                handleSignIn={this.handleSignIn} />
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

export default ResetPassword;
