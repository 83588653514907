import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader, Badge, Table
} from 'reactstrap';
import ReactTable from "react-table";
import Pagination from "../../../components/common/pagination";
import { AppContext } from '../../../hoc/AppContext';
import moment from "moment";
import { dateFormatList } from "variables/constants";

class ViewClaimItemsModal extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      formType: 'Add',
      country: {
        id: 0,
        country: '',
        countryCode: '',
      },
      claimCategories: null,
      countryCodeState: ''
    };
  }



  UNSAFE_componentWillMount() {

  }

  render() {
    const { config } = this.context;
    // let projects = this.props.employeeProjects.find(obj => obj.id === this.props.claim.pid);
    let claimItems = this.props.claim.claimItems.map((prop, key) => {
      let attachments = prop.attachments !== "" ? JSON.parse(prop.attachments) : 'N/A';

      return {
        id: prop.id,
        cid: prop.cid,
        claimType: prop.claimType,
        claimCategoryId: prop.claimCategory,
        dateTransaction: moment(prop.dateTransaction).format(dateFormatList[2]),
        amount: prop.amount.toFixed(2),
        attachments: attachments === 'N/A' ? 'N/A' :
          (
            attachments.map((obj, index) => {
              return (
                <Badge color="primary">
                  <a style={{ color: 'white' }} href={obj} target="_blank">File  {index + 1}</a></Badge>
              )
            })
          )
      };
    })
    return (
      <div>
        <Modal isOpen={this.props.modal} size="lg" toggle={this.props.toggle} className="custom-modal claim-modal"  >
          <ModalHeader toggle={this.props.toggle}><h4>Claim Details</h4></ModalHeader>
          <ModalBody >
            <Table borderless className="" id="custom-view-table">
              <thead>
                <tr>
                  <th style={{ width: 125 }}></th>
                  <th style={{ width: 240 }}></th>
                  <th style={{ width: 102 }}></th>
                  <th style={{ width: 240 }}></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td >Claim Number :</td>
                  <td>{this.props.claim.claimNumber.toUpperCase()}</td>
                  <td>Project :</td>
                  {/* <td>{projects && projects.project ? projects.project : "N/A"}</td> */}
                  <td>{this.props.claim && this.props.claim.projectName ? this.props.claim.projectName : 'N/A'}</td>
                </tr>
                <tr>
                  <td>Claim Status :</td>
                  <td>{this.props.claim.claimStatus}</td>
                  <td>Amount</td>
                  <td>{this.props.claim.amount}</td>
                </tr>
                <tr>
                  <td valign="top" style={{ verticalAlign: 'top' }}>Short Desc :</td>
                  <td valign="top" style={{ verticalAlign: 'top' }}>{this.props.claim.shortDesc}</td>
                  <td valign="top" style={{ verticalAlign: 'top' }}>Remark :</td>
                  <td valign="top" style={{ verticalAlign: 'top' }}>{this.props.claim.remark}</td>
                </tr>
              </tbody>
            </Table>
            {/* <div style={{display:'flex', justifyContent:'space-around'}}>
                <div>
                  <div style={{ display: 'flex' }}>
                      <h6>Claim Number : </h6><h6>{this.props.claim.claimNumber}</h6>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h6>Claim Status : </h6><h6>{this.props.claim.claimStatus}</h6>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h6>Short Desc :</h6><h6>{this.props.claim.shortDesc}</h6>
                  </div>
                </div>
                <div>
                  <div style={{ display: 'flex' }}>
                  <h6>Project :</h6><h6>{projects && projects.project ? projects.project : "N/A"}</h6>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <h6>Remark :</h6><h6>{this.props.claim.remark}</h6>
                  </div>
                </div>
                </div> */}
            <ReactTable
              PaginationComponent={Pagination}
              data={claimItems}
              // filterable
              // nextText={myCustomNextText}
              resizable={false}
              columns={[
                // {
                //     Header: "Actions",
                //     accessor: "actions",
                //     width: 70,
                //     sortable: false,
                //     filterable: false
                // },
                {
                  Header: "Claim Type",
                  accessor: "claimType"
                },
                {
                  Header: "Claim Category",
                  accessor: "claimCategoryId"
                },
                {
                  Header: "Transaction Date",
                  accessor: "dateTransaction"
                },
                {
                  Header: "Amount",
                  accessor: "amount"
                },
                {
                  Header: "Attachments",
                  accessor: "attachments",
                  className: 'attachment-column'
                }
              ]}
              NoDataComponent={() => null}
              // pageSize={claimItems.length <= 5 ? claimItems.length : 5}
              pageSize={claimItems.length <= config.paginationSize ? claimItems.length : config.paginationSize}
              className="-striped -highlight project-table leave-table claim-modal-table"
            />
          </ModalBody>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px', marginBottom: '10px' }}>
            <Button size="sm" color="secondary" onClick={this.props.close}>Cancel</Button>
          </div>
        </Modal>
      </div>
    );
  }

}

export default ViewClaimItemsModal;