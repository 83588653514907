import { FETCH_ALL_EMPLOYEE_PROJECTS } from "../actions/employeeProjectsAction";
const employeeProjectsReducer = function (state = [], action) {
    // let newState;
    let index;
    switch (action.type) {
        case FETCH_ALL_EMPLOYEE_PROJECTS:
            return action.projects;

        default:
            return state;
    }
};

export default employeeProjectsReducer;