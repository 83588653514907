import { getLeavesHistoryAPI, getNewLeavesAPI } from '../../services/leaves-service';

export const FETCH_NEW_LEAVES = 'FETCH_NEW_LEAVES';
export const FETCH_LEAVES_HISTORY = 'FETCH_LEAVES_HISTORY';

export const getNewLeaves = (cid, eid) => {
    return async (dispatch) => {
        const response = await getNewLeavesAPI(cid, eid);
        if (response.data) {
            dispatch({
                type: FETCH_NEW_LEAVES,
                newLeaves: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}

export const getLeavesHistoryDetails = (cid, eid) => {
    return async (dispatch) => {
        const response = await getLeavesHistoryAPI(cid, eid);
        if (response.data) {
            dispatch({
                type: FETCH_LEAVES_HISTORY,
                leavesHistory: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}
