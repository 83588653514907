import { getAdminQuarterlyInvoicesAPI, getAdminMonthlyInvoicesAPI } from "../../services/admin-service";
import { sendProjectInvoiceAPI } from "../../services/managers-service";

export const FETCH_ADMIN_MONTHLY_INVOICES = 'FETCH_ADMIN_MONTHLY_INVOICES';
export const FETCH_ADMIN_QUARTERLY_INVOICES = 'FETCH_ADMIN_QUARTERLY_INVOICES';

export const getAdminQuarterlyInvoices = (cid, pid, eid, month, quarter, year) => {
    return async (dispatch) => {
        const response = await getAdminQuarterlyInvoicesAPI(cid, pid, eid, month, quarter, year);
        if (response.data) {
            dispatch({
                type: FETCH_ADMIN_QUARTERLY_INVOICES,
                quarterlyInvoices: response.data
            });
            return { success: true, data: response.data };
        }
        else {
            return { success: false };
        }
    }
}

export const getAdminMonthlyInvoices = (cid, pid, eid, month, quarter, year) => {
    return async (dispatch) => {
        const response = await getAdminMonthlyInvoicesAPI(cid, pid, eid, month, quarter, year);

        if (response.data) {
            dispatch({
                type: FETCH_ADMIN_MONTHLY_INVOICES,
                monthlyInvoices: response.data
            });
            return { success: true, data: response.data };
        }
        else {
            return { success: false };
        }
    }
}

export const sendProjectInvoice = (invoiceId, managerId, type) => {
    var invoiceIds = [];
    invoiceIds.push(invoiceId);

    return async (dispatch, getState) => {
        var { adminInvoices, auth } = getState();
        let cid = auth.user.cid;

        const response = await sendProjectInvoiceAPI(cid, invoiceId, managerId);

        if (response.success === true) {
            if (type === "Quarterly") {
                let quarterlyInvoices = [...adminInvoices.quarterlyInvoices];
                let index = quarterlyInvoices.findIndex(invoice => invoice.id === invoiceId);
                if (index > -1) {
                    quarterlyInvoices[index]['invoiceSent'] = "Y";
                    dispatch({
                        type: FETCH_ADMIN_QUARTERLY_INVOICES,
                        quarterlyInvoices: quarterlyInvoices
                    });
                    return { success: true };
                } else {
                    return { success: false }
                }
            } else {
                let monthlyInvoices = [...adminInvoices.monthlyInvoices];
                let index = monthlyInvoices.findIndex(invoice => invoice.id === invoiceId);
                if (index > -1) {
                    monthlyInvoices[index]['invoiceSent'] = "Y";
                    dispatch({
                        type: FETCH_ADMIN_MONTHLY_INVOICES,
                        monthlyInvoices: monthlyInvoices
                    });
                    return { success: true };
                } else {
                    return { success: false }
                }

            }
        }
        else {
            return { success: false };
        }
    }
}