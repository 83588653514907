import { getAllDesignationsAPI, addUpdateDesignationAPI } from '../../services/designation-service';

export const FETCH_ALL_DESIGNATIONS = 'FETCH_ALL_DESIGNATIONS';
export const ADD_UPDATE_DESIGNATION = 'ADD_UPDATE_DESIGNATION';

// let token = localStorage.getItem('token');

export const getAllDesignations = (cid) => {
    return async (dispatch) => {
        const response = await getAllDesignationsAPI(cid);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_DESIGNATIONS,
                designations: response.data
            });
            return { success: true, data: response.data };
        }
        else {
            return { success: false };
        }
    }
}

export const addUpdateDesignation = (designation) => {
    return async (dispatch) => {
        const response = await addUpdateDesignationAPI(designation);
        if (response.data) {
            dispatch({
                type: ADD_UPDATE_DESIGNATION,
                designation: response.data
            });
            return { success: true, data: response.data };
        }
        else {
            return { success: false };
        }
    }
}