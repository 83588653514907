import axios from 'axios';

export const signinUserAPI = (hostedUrl, username, password) => {
  return axios
    .post(`/api/auth/authenticateUser`, { hostedUrl, username, password })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {

      return { success: false, error: getErrorMessage(error) };
    });
}

export const refreshTokenAPI = (user) => {
  return axios
    .post(`/api/auth/refresh-token`, user)
    .then(function (response) {
      if (response.data){
          return { success: true, data:response.data };
      }else{
          return { success: false};
      }
    })
    .catch(function (error) {  
      return { success: false };
  });
}

export const signoutUserAPI = (user) => {
  return axios
    .post(`/api/auth/logoutUser`, user)
    .then(function (response) {
      if (response.data){
          return { success: true, data:response.data };
      }else{
          return { success: false};
      }
    })
    .catch(function (error) {  
      return { success: false };
  });
}

export const getErrorMessage = (error) => {
  let errorMessage = "Something went wrong!";
  if (error && error.response && error.response.data) {
    if (error.response.headers['content-type'] === "application/json") {
      if (typeof error.response.data === "string") {
        return error.response.data;
      } else if (typeof error.response.data === "object") {
        return error.response.data.message;
      } else {
        return errorMessage;
      }
    } else {
      return errorMessage;
    }
  } else {
    return errorMessage;
  }
}