import {getAllRoleModuleAccessAPI, addUpdateRoleModuleAccessAPI} from "../../services/role-module-access-service";

export const FETCH_ROLE_MODULE_ACCESS = 'FETCH_ROLE_MODULE_ACCESS';
export const ADD_UPDATE_ROLE_MODULE_ACCESS = 'ADD_UPDATE_ROLE_MODULE_ACCESS';

export const getAllRoleModuleAccess = (cid,roleid) =>  {
    return async (dispatch) => {
        const response = await getAllRoleModuleAccessAPI(cid,roleid);
          let data = response.data;
          let roleAccess = [];
          for (let i = 0; i < data.length; i++) {
              const dataObj = data[i];
              if(dataObj.access == null){
                dataObj.roleId = roleid;
                dataObj.access = {
                    "view":false,
                    "add":false,
                    "edit":false,
                    "delete":false,
                    "manage":false
                }
              }
              else{
                dataObj.roleId = roleid;
                dataObj.access = JSON.parse(dataObj.access)
              }
              roleAccess.push(dataObj)
          }
            if (response.data){
                dispatch({
                    type: FETCH_ROLE_MODULE_ACCESS,
                    roleModuleAccess: data
                  });
                return { success: true,data:data};
            }
            else{
                return { success: false};
            }    
        }
}

export const addUpdateRoleModuleAccess = (roleModuleAccess) =>  {
    return async (dispatch) => {
        const response = await addUpdateRoleModuleAccessAPI(roleModuleAccess);
            if (response.data){
              let data = response.data;
              let roleAccess = [];
              for (let i = 0; i < data.length; i++) {
                const dataObj = data[i];
                if(dataObj.access != null){
                dataObj.access = JSON.parse(dataObj.access)
                roleAccess.push(dataObj)
              }
            }
                dispatch({
                    type: ADD_UPDATE_ROLE_MODULE_ACCESS,
                      roleModuleAccess: data
                  });
                return { success: true, data:data };
            }
            else{
                return { success: false};
            }    
        }
}