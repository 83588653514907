import React from "react";
import { Button, Col, FormGroup, Input, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import Switch from "react-switch";
import { REQUIRD_MESSAGE } from "../../../variables/constants";
import NotificationAlert from "react-notification-alert";

class LeaveTypeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "",
            formname: "Add Leave Type",
            isActive: true,
            typeState: "",
            metadata: {
                type: "leave type",
            },
            isLoading: false
        };
    }

    verifyEmail = value => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };
    // function that verifies if a string has a given length or not
    verifyLength = (value, stateName, length) => {
        if (stateName === 'type') {
            //var lengthRex = /^[A-Za-z]*$/;
            var lengthRex = /^[a-zA-Z\s,'-]*$/;
            if (lengthRex.test(value)) {
                if (value.startsWith(' ') || value === '') {
                    return false;
                }
                return true;
            }
            return false;
        }
        return true;
    };

    notify = (place, color, message) => {
        // var color = Math.floor(Math.random() * 5 + 1);
        var type;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7
        };
        if (this.refs.notificationAlert) {
            this.refs.notificationAlert.notificationAlert(options);
        }
    };


    showNotifications = (color, message) => {
        this.notify("tr", color, message);
    }



    change = (event, stateName, type, stateNameEqualTo, maxValue) => {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            case "length":

                if (this.verifyLength(event.target.value, stateName, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "has-success" });
                } else {
                    this.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    };

    handleIsActive = (e) => {

        this.setState({
            isActive: e
        })
    }


    isValidated = async () => {
        //
        let checkLeaveTypeExist = false;
        let isUpdatedLeaveTypeAlreadyExist = false;
        if (this.props.formtype === "update") {
            let alreadyExist = this.props.leaveTypes.filter(leave => leave.type.toUpperCase() === this.state.type.trim().toUpperCase())
            isUpdatedLeaveTypeAlreadyExist = alreadyExist.length > 1 ? true : false;
            var typeCount = 0;

            let result = this.props.leaveTypes && this.props.leaveTypes.map((type) => {

                if (type.type.toUpperCase() === this.state.type.trim().toUpperCase() && type.id !== this.props.leavetype.id) {
                    typeCount = typeCount + 1;
                }
            });

            if (typeCount >= 1) {
                isUpdatedLeaveTypeAlreadyExist = true;
            }

        } else {

            checkLeaveTypeExist = (this.props.leaveTypes.findIndex(type => type.type.toUpperCase() === this.state.type.trim().toUpperCase()) > -1 ? true : false);
        }
        if (
            this.state.typeState === "has-success" &&
            checkLeaveTypeExist === false &&
            isUpdatedLeaveTypeAlreadyExist === false
        ) {
            let isActive = true; let id = 0
            this.state.isActive === true ? isActive = "Y" : isActive = "N";
            this.props.formtype === 'update' ? id = this.props.leavetype.id : id = 0;
            let typeDetails = {
                cid: this.props.cid,
                id: id,
                isActive: isActive,
                type: this.state.type,
            }
            // 
            this.props.addUpdateLeaveType(typeDetails, this.props.formtype);
            return true;
        } else {
            if (this.state.typeState !== "has-success" || checkLeaveTypeExist === true || isUpdatedLeaveTypeAlreadyExist === true) {
                this.setState({ typeState: "has-danger" });
            }

            // this.showNotifications(3, "Required field cannot be empty!");
            this.setState({ isLoading: false })
            return true;
        }
    };

    handleSelect = (e) => {
        this.setState({
            country: e.target.value
        })
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true
        })
        await this.isValidated();
    }




    async UNSAFE_componentWillMount() {
        if (this.props.formtype === "update") {
            let isActive = true;
            let typeState = "";
            this.props.leavetype.isActive === "Y" ? isActive = true : isActive = false
            if (this.props.leavetype.type.startsWith(' ') || this.props.leavetype.type === '') {
                typeState = 'has-danger'
            } else {
                typeState = "has-success"
            }
            this.setState({
                type: this.props.leavetype.type,
                isActive: isActive,
                typeState: typeState,
                formname: 'Update Leave Type'
            })
        }
    }

    render() {
        let metadata = this.state.metadata;

        return (
            <>
                <NotificationAlert ref="notificationAlert" />
                <div className="content raise-ticket">
                    <Col className="mr-auto ml-auto" md="12">

                        <div className="text-header text-center">{this.state.formname}</div>
                        <form onSubmit={this.handleSubmit} className="ticket-form">
                            <Row className="justify-content-center">
                                <Col className="" sm="10">
                                    <FormGroup className="form-group">
                                        <label htmlFor="password">
                                            Leave Type
                                        </label>
                                        <Input
                                            name={"type"}
                                            placeholder={metadata.type + " (required)"}
                                            type="text"
                                            onChange={e => this.change(e, "type", "length", 0)}
                                            onFocus={e => this.setState({ typeFocus: true })}
                                            onBlur={e => this.setState({ typeFocus: false })}
                                            value={this.state.type}
                                        />
                                        {this.state.typeState === "has-danger" ? (
                                            <label className="error client_error">Please enter valid and unique input</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col className="" sm="10">
                                    <FormGroup>
                                        <label id="tooltip209849">
                                            <span style={{ display: 'block', marginBottom: 10, marginTop: 4 }} htmlFor="isActive"> Active </span>
                                            <Switch
                                                onChange={(e) => { this.handleIsActive(e) }}
                                                name="isActive"
                                                id="isActive"
                                                checked={this.state.isActive}
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48} />
                                        </label>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target="tooltip209849"
                                            placement="right"
                                        >
                                            {this.state.isActive === true ? "Active" : "Inactive"}
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row className="justify-content-center mt-3">
                                {
                                    this.state.isLoading ? (
                                        <Button disabled="true" type="submit" color="info" className="btn-submit btn-ticket-submit">
                                            <Spinner size="sm" />
                                        </Button>

                                    ) : (
                                        <Button type="submit" size="sm" color="info" className="btn-submit btn-ticket-submit">Submit</Button>
                                    )
                                }
                            </Row>
                        </form>
                    </Col></div>

            </>
        );
    }
}


export default LeaveTypeForm