import React, { Component } from "react";
import { Button,
Modal, 
ModalBody, 
ModalHeader, Badge } from 'reactstrap';
import ReactTable from "react-table";
import Pagination from "../../components/common/pagination";


class ViewAttachmentsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'Add',
            country: {
                id: 0,
                country: '',
                countryCode: '',
            },
            claimCategories:null,
            countryCodeState: '',
            attachments:[],
            itemIndex:-1
        };
    }



    UNSAFE_componentWillMount() {
          this.setState({
            attachments:this.props.attachments,
            itemIndex:this.props.itemIndex
          })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
      if(this.props.attachments !== nextProps.attachments){
        this.setState({
          attachments:nextProps.attachments,
          itemIndex:nextProps.itemIndex
        })
      }
     }

    handleDelete = (key,prop) =>{
      this.props.delete(key,prop,this.state.itemIndex);
    }

    render() {
        let claimItems = this.state.attachments.map((prop, key) => {            
            return {
              id: prop.id,
              attachments:(
                  <a style={{color:'#45b3e3'}} href={prop} target="_blank">{prop}</a>
              ),
              delete:(
                <Badge color="danger" style={{marginTop:'2px'}} onClick={()=>this.handleDelete(key,prop)}>Delete</Badge>
              )
            };
          });
        return (
            <div>
                <Modal isOpen={this.props.modal} size="lg" toggle={this.props.toggle} className="custom-modal claim-modal"  >
                    <ModalHeader toggle={this.props.toggle}><h4>View Attachments</h4></ModalHeader>
                    <ModalBody >
                <ReactTable
                  PaginationComponent={Pagination}
                  data={claimItems}
                  // filterable
                  // nextText={myCustomNextText}
                  resizable={false}
                  columns={[
                    {
                      Header: "Attachment",
                      accessor: "attachments",
                      sortable: false,
                      filterable: false,
                      width:650
                    },
                    {
                      Header: "Delete",
                      accessor: "delete",
                      sortable: false,
                      filterable: false,
                      width:70
                    }
                  ]}
                  NoDataComponent={() => null}
                  pageSize={claimItems.length <= 5 ? claimItems.length : 5}
                  className="-striped -highlight project-table leave-table"
                />
                    </ModalBody>
                    <div style={{display:'flex', justifyContent:'flex-end', marginRight:'20px', marginBottom:'10px'}}>
                        <Button size="sm" color="secondary" onClick={this.props.close}>Cancel</Button>
                    </div>
                </Modal>
            </div>
        );
    }

}

export default ViewAttachmentsModal;