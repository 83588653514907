import moment from 'moment';
import React, { Component } from "react";
import ReactDatetime from "react-datetime";
import Switch from "react-switch";
import { Col, Form, FormGroup, Input, Label, Row, UncontrolledTooltip, Button } from 'reactstrap';
import FileUpload from "../../../../components/CustomUpload/FileUpload";
import { uploadFile, deleteFile } from "../../../../services/index";
import { PROJECT_START_DATE_REQUIRD, dateFormatList } from "../../../../variables/constants";
import _ from "lodash";



class Project extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'Add',
            isActive: true,
            imagePreviewUrl: "",
            uploadProgress: 0,
            project: {
                id: 0,
                project: '',
                startDate: moment().format('YYYY-MM-DD'),
                endDate: "",
                status: 'In Planning',
                clientId: 0,
                cid: this.props.user.cid,
                isActive: 'Y',
                projectOwner: 0,
                actualEndDate: "",
                description: "",
                projectType: 0,
                hoursEstimated: 0,
                hoursActual: 0,
                currencyId: 0,
                percentCompleted: 0,
                poNumber: null,
                poFile: null,
                managerId: 0
            },
            clientOptions: [],
            projectState: '',
            startDateState: '',
            endDateState: '',
            statusState: '',
            clientIdState: '',
            companyState: '',
            parentProjectState: '',
            currencyState: '',
            hoursEstimatedState: '',
            projectTypeState: '',
            actualEndDateState: '',
            percentCompletedState: '',
            poFile: null,
            selectedFile: null,
            projectAlreadyExistState: '',
            isFileChanged: false,
            managerIdState: '',
            projectOwnerState: '',

        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }



    UNSAFE_componentWillMount() {
        var selectedProjectOwner = this.props.managers.find(manager => manager.eid === this.props.project.projectOwner)
        var selectedProjectCurrency = this.props.currencies.find(currency => currency.id === this.props.project.currencyId)
        var selectedProjectType = this.props.projectTypes.find(projectType => projectType.id === this.props.project.projectType)

        if (this.props.project.poFile !== null && this.props.project.poFile !== undefined && this.props.project.poFile !== "") {

            var url = JSON.parse(this.props.project.poFile);
            var poFile = url[0];

        }
        if (this.props.formType === "Update") {
            let isActive = this.props.project.isActive === "Y" ? true : false;

            this.setState({
                ...this.state,
                formType: this.props.formType,
                isActive: isActive,
                project: {
                    ...this.props.project,
                    poFile: this.props.project.poFile !== null && this.props.project.poFile !== '' ? poFile : null,
                    cid: this.props.user.cid,
                    endDate: this.props.project.endDate === null ? '' : this.props.project.endDate,
                    actualEndDate: this.props.project.actualEndDate === null ? '' : this.props.project.actualEndDate,
                    description: this.props.project.description === null ? '' : this.props.project.description,
                    projectOwner: selectedProjectOwner !== undefined ? this.props.project.projectOwner : 0,
                    currencyId: selectedProjectCurrency !== undefined ? this.props.project.currencyId : 0,
                    projectType: selectedProjectType !== undefined ? this.props.project.projectType : 0,
                    managerId: this.props.project.managerId === null ? 0 : this.props.project.managerId
                }
            });
        } else {
            this.setState({
                ...this.state,
                formType: this.props.formType,
                project: {
                    ...this.state.project,
                    cid: this.props.user.cid,
                }
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        var selectedProjectOwner = nextProps.managers.find(manager => manager.eid === nextProps.project.projectOwner)
        var selectedProjectCurrency = nextProps.currencies.find(currency => currency.id === nextProps.project.currencyId)
        var selectedProjectType = nextProps.projectTypes.find(projectType => projectType.id === nextProps.project.projectType)

        let isSameObject = _.isEqual(this.props.project, nextProps.project);

        if (nextProps.project.poFile !== null && nextProps.project.poFile !== undefined && nextProps.project.poFile !== '') {

            var url = JSON.parse(nextProps.project.poFile);
            var poFile = url[0];

        }
        if (isSameObject === false) {
            if (this.props.formType === 'Add') {
                this.setState({
                    ...this.state,
                    project: {
                        ...nextProps.project,
                        description: nextProps.project.description === null ? '' : nextProps.project.description,
                        poFile: nextProps.project.poFile !== null && nextProps.project.poFile !== '' ? poFile : null,
                        projectOwner: selectedProjectOwner !== undefined ? nextProps.project.projectOwner : 0,
                        currencyId: selectedProjectCurrency !== undefined ? nextProps.project.currencyId : 0,
                        projectType: selectedProjectType !== undefined ? nextProps.project.projectType : 0,
                        managerId: nextProps.project.managerId === null ? 0 : nextProps.project.managerId
                    }
                });
            }
        }

        if (nextProps.formType === 'Update') {
            let isSameObject = _.isEqual(this.state.project, nextProps.project);
            if (isSameObject === false) {
                this.setState({
                    ...this.state,
                    project: {
                        ...nextProps.project,
                        description: nextProps.project.description === null ? '' : nextProps.project.description,
                        poFile: nextProps.project.poFile !== null && nextProps.project.poFile !== '' ? poFile : null,
                        projectOwner: selectedProjectOwner !== undefined ? nextProps.project.projectOwner : 0,
                        currencyId: selectedProjectCurrency !== undefined ? nextProps.project.currencyId : 0,
                        projectType: selectedProjectType !== undefined ? nextProps.project.projectType : 0,
                        managerId: nextProps.project.managerId === null ? 0 : nextProps.project.managerId
                    }
                });
            }
        }
    }

    handleStartDate = (date) => {

        this.setState({
            startDateState: moment(date).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
            project: {
                ...this.state.project,
                startDate: moment(date).format('YYYY-MM-DD')
            }

        });
    }

    handleEndDate = (date) => {
        this.setState({
            endDateState: moment(date).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
            project: {
                ...this.state.project,
                endDate: moment(date).format('YYYY-MM-DD')
            }
        });
    }
    handleActualEndDate = (date) => {
        this.setState({
            actualEndDateState: moment(date).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
            project: {
                ...this.state.project,
                actualEndDate: moment(date).format('YYYY-MM-DD')
            }
        });
    }


    handleIsActive = (e) => {
        let project = { ...this.state.project };
        project.isActive = e === true ? "Y" : "N";
        this.setState({
            ...this.state,
            isActive: e,
            project: project
        });
    }

    onChangeTextHandler = (e) => {
        let project = this.state.project;
        let projectText = e.target.value;
        project[e.target.name] = projectText;

        var regex = /^[A-Za-z0-9 ]+$/
        if (e.target.name === "project") {
            // var numberRex = new RegExp("/^[A-Za-z0-9 ]+$/");
            // var numberRex = new RegExp(/^-?\d*(\.\d+)?$/);
            // if (specials.test(address)) { /* bad address */ }
            var isValid = regex.test(e.target.value);

            if (isValid) {
                this.setState({
                    projectState: e.target.value.length === "" ? "has-danger" : "has-success",
                    project: project
                });
            } else {
                this.setState({
                    ...this.state,
                    projectState: "has-danger",
                    // project: project
                });
            }


        } else {
            this.setState({
                projectState: e.target.value.length === "" ? "has-danger" : "has-success",
                project: project
            });

        }


    }
    handleSelectStatus = (e) => {
        switch (e.target.name) {
            case "status":
                this.setState({ statusState: "has-success" });
                break;

            default:
                break;
        }

        let project = this.state.project;
        project[e.target.name] = e.target.value;
        this.setState({
            ...this.state,
            statusState: e.target.value == "" ? "has-danger" : "has-success",
            project: project
        })
    }
    handleSelectProjectOwner = (e) => {

        let project = this.state.project;
        project[e.target.name] = e.target.value === "0" ? null : e.target.value;
        this.setState({
            ...this.state,
            projectOwnerState: e.target.value === "0" ? "has-danger" : "has-success",
            project: { ...project }
        })
    }


    handleSelect = (e) => {
        switch (e.target.name) {
            case "clientId":
                this.setState({ clientIdState: "has-success" });
                break;
            case "cid":
                this.setState({ companyState: "has-success" });
                break;
            default:
                break;
        }

        let project = this.state.project;
        project[e.target.name] = parseInt(e.target.value);
        this.setState({
            project: { ...project }
        })
    }

    handleSelectManager = (e) => {
        let project = this.state.project;
        project[e.target.name] = parseInt(e.target.value) === 0 ? 0 : e.target.value;
        this.setState({
            ...this.state,
            project: { ...project }
        })
    }
    handleCurrency = (e) => {

        this.setState({
            ...this.state,
            currencyState: e.target.value === 0 ? "has-danger" : "has-success",
            project: {
                ...this.state.project,
                currencyId: parseInt(e.target.value)
            }
        });
    }

    verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    verifyNumber = value => {
        var numberRex = new RegExp("^[0-9]+$");
        // var numberRex = new RegExp(/^-?\d*(\.\d+)?$/);

        if (numberRex.test(value)) {
            return true;
        }
        return false;
    };



    change = (event, stateName, type, stateNameEqualTo, maxValue) => {
        let project = { ...this.state.project };
        project[event.target.name] = event.target.value;
        if (event.target.value.length > 0) {
            this.setState({ [stateName + "State"]: "has-success" });
        } else {
            this.setState({ [stateName + "State"]: "has-danger" });
        }
        this.setState({ project: project });
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ project: project });
                } else {
                    this.setState({ project: project });
                }
                break;
            case "number":
                if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
                    this.setState({ project: project });
                } else {
                    this.setState({ project: project });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    };

    isValidated = () => {
        let { project,
            startDate,
            endDate,
            status,
            clientId,
            cid,
            isActive, projectType, projectOwner, percentCompleted, hoursEstimated, hoursActual } = this.state.project;
        var regex = /^[A-Za-z0-9 ]+$/;
        var isValid = regex.test(project);
        var isProjectAlreadyExist = false;

        // var companyCurrency = this.props.companyConfigs.find(config => config.param === 'currency');
        // if (companyCurrency !== undefined) {
        //     var defaultCompanyCurrency = this.props.currencies.find(currency => currency.code === companyCurrency.paramValue);
        // }


        var empidSeries = this.props.companyConfigs.find(config => config.param === 'empid_series');


        var today = new Date();
        var date = "" + today.getFullYear() + (today.getMonth() + 1) + today.getDate();
        var time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
        var dateTime = date + '-' + time;
        var poNumber = empidSeries === undefined ? dateTime : empidSeries.paramValue + '-' + dateTime;

        var projectData = JSON.parse(JSON.stringify(this.state.project));
        projectData.project = this.state.project.project.replace(/  +/g, ' ');
        projectData.project = projectData.project.trim();
        // projectData.projectOwner = projectData.projectOwner === "" ? this.props.employees[0].eid : this.state.project.projectOwner;
        projectData.projectOwner = parseInt(this.state.project.projectOwner) === 0 ? null : this.state.project.projectOwner;
        projectData.endDate = projectData.endDate === "" ? null : this.state.project.endDate;
        projectData.actualEndDate = projectData.actualEndDate === "" ? null : this.state.project.actualEndDate;
        projectData.projectType = this.state.project.projectType;
        // projectData.currencyId = projectData.currencyId === 0 ? defaultCompanyCurrency.id : this.state.project.currencyId;
        projectData.poNumber = projectData.poNumber === null ? poNumber !== undefined ? poNumber : null : this.state.project.poNumber;
        projectData.managerId = projectData.managerId === 0 ? null : this.state.project.managerId;
        projectData.description = this.state.project.description === "" ? null : this.state.project.description;
        projectData.isActive = this.state.isActive === true ? "Y" : "N";

        var projectCount = 0;

        let result = this.props.projects && this.props.projects.map((projectItem) => {

            if (projectItem.project.toUpperCase() === projectData.project.trim().toUpperCase() && projectItem.id !== projectData.id) {
                projectCount = projectCount + 1;
            }
        });
        isProjectAlreadyExist = projectCount >= 1 ? true : false;

        this.setState({
            ...this.state,
            projectAlreadyExistState: isProjectAlreadyExist === true ? 'has-danger' : 'has-success'
        })


        if (isValid === true) {


            if (projectData.project.trim() !== "" && projectData.projectOwner !== null && projectData.currencyId !== 0 && projectData.projectType !== 0 && startDate !== "" && hoursActual >= 0 && hoursEstimated >= 0 && hoursEstimated <= 999999 && percentCompleted >= 0 && isProjectAlreadyExist === false && percentCompleted <= 100 || percentCompleted === NaN) {
                if (this.props.formType === "Add") {
                    this.props.submitProject(projectData, this.state.selectedFile);
                    return true;

                } else {
                    if (this.props.file !== null && this.state.isFileChanged === false) {
                        projectData.poFile = this.props.file;
                        this.props.submitProject(projectData);
                        return true;
                    } else {
                        var docData = [];
                        if (this.state.project.poFile !== null) {
                            docData.push(this.state.project.poFile);
                            let poFile = JSON.stringify(docData);
                            projectData.poFile = poFile;
                            this.props.submitProject(projectData);
                            return true;
                        } else {
                            this.props.submitProject(projectData);
                            return true;
                        }
                    }
                }
            } else {
                if (project.trim() == "" || isValid === false) {
                    this.setState({ projectState: "has-danger" });
                }
                if (startDate == "") {
                    this.setState({ startDateState: "has-danger" });
                }
                if (projectData.projectType == 0) {
                    this.setState({ projectTypeState: "has-danger" });
                }
                if (percentCompleted < 0 || percentCompleted > 100) {
                    this.setState({ percentCompletedState: "has-danger" });
                } if (hoursActual < 0) {
                    this.setState({ hoursActualState: "has-danger" })
                }
                if (hoursEstimated < 0 || hoursEstimated > 999999) {
                    this.setState({ hoursEstimatedState: "has-danger" })
                }
                if (isProjectAlreadyExist === true) {
                    this.setState({ projectAlreadyExistState: "has-danger" })
                }
                if (projectData.projectOwner === null) {
                    this.setState({ projectOwnerState: "has-danger" });
                }
                if (projectData.currencyId === 0) {
                    this.setState({ currencyState: "has-danger" });
                }
                return false;
            }
        } else {
            if (isValid === false) {
                this.setState({ projectState: "has-danger" });
            }
            if (project.trim() == "" || isValid === false) {
                this.setState({ projectState: "has-danger" });
            }
            if (startDate == "") {
                this.setState({ startDateState: "has-danger" });
            }
            if (projectData.projectType == 0) {
                this.setState({ projectTypeState: "has-danger" });
            }
            if (percentCompleted < 0 || percentCompleted > 100) {
                this.setState({ percentCompletedState: "has-danger" });
            } if (hoursActual < 0) {
                this.setState({ hoursActualState: "has-danger" })
            }
            if (hoursEstimated < 0 || hoursEstimated > 999999) {
                this.setState({ hoursEstimatedState: "has-danger" })
            }
            if (isProjectAlreadyExist === true) {
                this.setState({ projectAlreadyExistState: "has-danger" })
            }
            if (projectData.projectOwner === null) {
                this.setState({ projectOwnerState: "has-danger" });
            }
            if (projectData.currencyId === 0) {
                this.setState({ currencyState: "has-danger" });
            }
            return false;

        }

    };
    handleProjectType = (e) => {
        this.setState({
            ...this.state,
            projectTypeState: e.target.value === 0 ? "has-danger" : "has-success",
            project: {
                ...this.state.project,
                projectType: parseInt(e.target.value)
            }
        });

    }
    handleChange = (e) => {

        let project = this.state.project;
        project[e.target.name] = parseFloat(e.target.value);
        if (e.target.name === "percentCompleted") {
            if (parseFloat(e.target.value) >= 0 && parseFloat(e.target.value) <= 100) {
                this.setState({
                    ...this.state,
                    percentCompletedState: "has-success",
                    project: project
                });
            } else {
                this.setState({ percentCompletedState: "has-danger" });
                return false;
            }

        } else if (e.target.name === "hoursActual") {
            if (parseFloat(e.target.value) >= 0) {
                this.setState({
                    ...this.state,
                    hoursActualState: "has-success",
                    project: project
                });
            } else {
                this.setState({ hoursActualState: "has-danger" });
                return false;
            }
        } else if (e.target.name === "hoursEstimated") {


            if (parseFloat(e.target.value) >= 0 && parseFloat(e.target.value) <= 999999) {
                this.setState({
                    ...this.state,
                    hoursEstimatedState: "has-success",
                    project: project
                });
            } else {
                this.setState({ hoursEstimatedState: "has-danger" });
                return false;
            }
        }
    }


    verifyNumber = value => {
        var numberRex = new RegExp("^[0-9]+$");
        if (numberRex.test(value)) {
            return true;
        }
        return false;
    };
    handleImageChange = async (e) => {
        var ext;
        e.preventDefault();

        var maxFileSizeConfig = this.props.companyConfigs.find(config => config.param === 'max_file_size');
        var maxFileSize = maxFileSizeConfig !== undefined ? parseInt(maxFileSizeConfig.paramValue * 1048576) : 5242880;

        if (this.props.formType === "Add") {
            let files = e.target.files[0];
            // let files = e.target.files[0];
            let filePath = `${this.props.user.cid}/DOCS/${this.props.project.id}/PO`;
            // let filePath = `${this.props.user.cid}/DOCS/${this.props.project.clientId}/PO`;
            // let filePath = `${this.state.employee.cid}/RESUMES/${this.state.employee.eid}`;       

            const data = new FormData()
            data.append('filePath', filePath);
            data.append('files', files);

            ext = files.name.split('.').pop();
            if (ext !== null) {
                if (ext == "pdf" || ext == "docx" || ext == "doc") {
                    if (files.size <= maxFileSize) {
                        this.setState({
                            ...this.state,
                            selectedFile: files
                        });

                    }
                    else {
                        let message = {
                            message: 'Cannot upload document size more than' + maxFileSizeConfig.paramValue + 'MB!',
                            type: "warning",
                        }
                        this.props.handleMessage(message);
                    }
                } else {
                    let message = {
                        message: 'Please upload pdf,docx,doc files only!',
                        type: "warning",
                    }
                    this.props.handleMessage(message);
                }
            }
        }
        else {
            let files = e.target.files[0];
            let filePath = `${this.props.user.cid}/DOCS/${this.state.project.id}/PO`;
            // let filePath = `${this.state.employee.cid}/RESUMES/${this.state.employee.eid}`;       

            const data = new FormData()
            data.append('filePath', filePath);
            data.append('files', files);


            ext = files !== undefined ? files.name.split('.').pop() : null;
            if (ext !== null) {


                if (ext == "pdf" || ext == "docx" || ext == "doc") {
                    if (files.size <= maxFileSize) {
                        const response = await uploadFile(data, this.onUploadProgress);
                        if (response.success) {
                            let message = {
                                message: 'Document uploded successfully.',
                                type: "success",
                            }

                            if (this.state.project.poFile !== null) {
                                let response = await deleteFile(this.state.project.poFile);
                            }

                            this.props.handleMessage(message);
                            this.setState({
                                ...this.state,
                                project: {
                                    ...this.state.project,
                                    poFile: response.data[0]
                                },
                                isFileChanged: true

                            });
                        } else {
                            let message = {
                                message: 'Error accured while uploading document!',
                                type: "warning",
                            }
                            this.props.handleMessage(message);
                        }
                    }
                    else {
                        let message = {
                            message: 'Cannot upload document size more than ' + maxFileSizeConfig.paramValue + 'MB!',
                            type: "warning",
                        }
                        this.props.handleMessage(message);
                    }
                } else {
                    let message = {
                        message: 'Please upload pdf,docx,doc files only!',
                        type: "warning",
                    }
                    this.props.handleMessage(message);
                }
            }


        }
    }

    handleClick = (refs) => {
        refs.fileInput.click();
    }
    handleRemove = async (refs) => {

        if (this.props.formType === "Add") {

            this.setState({
                ...this.state,
                selectedFile: null,
                project: {
                    ...this.state.project,
                    poFile: null,
                },
                isFileChanged: true
            });
        } else {
            if (this.state.project.poFile !== null) {

                let response = await deleteFile(this.state.project.poFile);
                // if (response === true) {}
                this.setState({
                    ...this.state,
                    selectedFile: null,
                    project: {
                        ...this.state.project,
                        poFile: null,
                    },
                    isFileChanged: true
                });

            } else {

                this.setState({
                    ...this.state,
                    selectedFile: null,
                    project: {
                        ...this.state.project,
                        poFile: null,
                    },
                    isFileChanged: true
                });
            }
        }
        // refs.fileInput.value = null;
    }

    onUploadProgress = (progressEvent) => {
        var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
        );

        this.setState({ uploadProgress: percentCompleted });
    };

    handleCancel = () => {
        let passProps = {
            pathname: 'projectmaster',
        }
        this.props.history.push(passProps);
    }

    render() {

        let clientOptions = this.props.clients.map((client, index) => {
            return (<option key={client.id} value={client.id}>{client.name}</option>)
        });
        let employeesOptions = this.props.employees.map((employee, index) => {
            return (<option key={employee.eid} value={employee.eid}>{employee.fname + ' ' + employee.lname}</option>)
        });

        let currenciesOptions = this.props.currencies.map((currency, index) => {
            return (<option key={currency.id} value={currency.id}>{currency.code}</option>)
        });

        let projectTypesOptions = this.props.projectTypes.map((project, index) => {
            return (<option key={project.id} value={project.id}>{project.type}</option>)

        })

        let managersOptions = this.props.managers.length > 0 && this.props.managers.map((manager, index) => {
            return (<option key={manager.eid} value={manager.eid} >{manager.fname + ' ' + manager.lname}</option>)
        })

        var maxFileSizeConfig = this.props.companyConfigs.find(config => config.param === 'max_file_size');

        let { projectState, startDateState, statusState, endDateState, companyState, clientIdState } = this.state;
        return (
            <div>
                <Form className="custom-form project-form" id="LoginValidation">
                    <Row xs="2">
                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>

                            <FormGroup className={`has-label ${projectState} ${this.state.projectAlreadyExistState}`}>
                                <Label>Project *</Label>
                                <Input
                                    name="project"
                                    type="text"
                                    placeholder="Project Name"
                                    value={this.state.project.project}
                                    onChange={this.onChangeTextHandler}
                                    // onChange={e => this.change(e, "project", "length", 1)}
                                    onFocus={e => this.setState({ projectFocus: true })}
                                    onBlur={e => this.setState({ projectFocus: false })}
                                />
                                {this.state.projectState === "has-danger" ? (
                                    <label className="error">Please enter valid project</label>
                                ) : null}
                                {
                                    this.state.projectAlreadyExistState === "has-danger" ? (
                                        <label className="error">Project already exist</label>
                                    ) : null
                                }
                            </FormGroup>
                        </Col>


                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <FormGroup className={`has-label ${this.state.projectOwnerState}`}>
                                <Label for="projectOwner" >Project Owner *</Label>
                                <Input type="select"
                                    name="projectOwner"
                                    // defaultValue={this.state.project.projectOwner}
                                    value={this.state.project.projectOwner}
                                    onChange={this.handleSelectProjectOwner}
                                    id="projectOwner"
                                >
                                    <option value={0} >Select Project Owner</option>
                                    {managersOptions}
                                </Input>
                                {this.state.projectOwnerState === "has-danger" ? (
                                    <label className="error">Please select project owner</label>
                                ) : null}
                            </FormGroup>
                        </Col>

                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <FormGroup className={`has-label ${statusState} `}>
                                <Label for="status">Status</Label>
                                <Input type="select"
                                    name="status"
                                    // defaultValue={this.state.project.status}
                                    value={this.state.project.status}
                                    onChange={this.handleSelectStatus}
                                    id="status"
                                    // onChange={e => this.change(e, "status", "length")}
                                    onFocus={e => this.setState({ statusFocus: true })}
                                    onBlur={e => this.setState({ statusFocus: false })}>
                                    {/* <option value="" disabled>Select Status</option> */}
                                    <option value="In Planning">In Planning</option>
                                    <option value="Closed">Closed</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="UAT">UAT</option>
                                </Input>
                                {/* {this.state.statusState === "has-danger" ? (
                                    <label className="error">{PROJECT_COMPANY_STATUS_REQUIRD}</label>
                                ) : null} */}
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row xs="2">
                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>

                            <FormGroup className={`has-label ${clientIdState} `}>
                                <Label for="client">Client</Label>
                                <Input
                                    type="select"
                                    name="clientId"
                                    // defaultValue={this.state.project.clientId}
                                    value={this.state.project.clientId}
                                    onChange={this.handleSelect}
                                    id="client"
                                    // onChange={e => this.change(e, "clientId", "length")}
                                    onFocus={e => this.setState({ clientIdFocus: true })}
                                    onBlur={e => this.setState({ clientIdFocus: false })}>
                                    <option value={0} >Select Client</option>
                                    {clientOptions}
                                </Input>

                                {/* {this.state.clientIdState === "has-danger" ? (
                                    <label className="error">{PROJECT_CLIENT_REQUIRD}</label>
                                ) : null} */}
                            </FormGroup>

                        </Col>

                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <FormGroup className={`has-label ${this.state.currencyState} `}>
                                <Label for="currency">Currency *</Label>
                                <Input
                                    type="select"
                                    name="currencyId"
                                    value={this.state.project.currencyId}
                                    // defaultValue={this.state.project.currencyId}
                                    onChange={this.handleCurrency}
                                    id="currency"
                                >
                                    <option value={0} >Select Currency</option>
                                    {currenciesOptions}
                                </Input>
                                {this.state.currencyState === "has-danger" ? (
                                    <label className="error">Please select currency</label>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <Label>Active </Label>
                            <FormGroup>
                                <label id="tooltip209847">
                                    <Switch
                                        // onChange={(e) => { this.handleIsActive(e) }}
                                        onChange={this.handleIsActive}
                                        name="isActive" id="isActive"
                                        checked={this.state.isActive}
                                        handleDiameter={30}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48} />
                                </label>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip209847"
                                    placement="right"
                                >
                                    {this.state.isActive === true ? "Active" : "Inactive"}
                                </UncontrolledTooltip>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <FormGroup className={`has-label ${this.state.projectTypeState} `}>
                                <Label for="projectType">Project Type *</Label>
                                <Input type="select"
                                    name="projectType"
                                    // defaultValue={this.state.project.projectType}
                                    value={this.state.project.projectType}
                                    onChange={this.handleProjectType}
                                    id="projectType"
                                // onChange={e => this.change(e, "status", "length")}
                                // onFocus={e => this.setState({ statusFocus: true })}
                                // onBlur={e => this.setState({ statusFocus: false })}
                                >
                                    <option value={0} >Select Project Type</option>
                                    {projectTypesOptions}
                                </Input>
                                {this.state.projectTypeState === "has-danger" ? (
                                    <label className="error">Please select project type</label>
                                ) : null}
                            </FormGroup>

                        </Col>
                        <Col sm={8} style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <FormGroup className={`has-label`}>
                                <Label>Description</Label>
                                <Input
                                    name="description"
                                    type="textarea"
                                    placeholder=""
                                    value={this.state.project.description}
                                    // onChange={(e) => this.setState({ description: e.target.value })}
                                    onChange={this.onChangeTextHandler}
                                    rows={4}
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row xs="2">
                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <FormGroup className={`has-label ${startDateState} `}>
                                <Label >Start Date *</Label>
                                <ReactDatetime
                                    timeFormat={false}
                                    inputProps={{ placeholder: "Start Date", className: "form-control", value: moment(this.state.project.startDate).format(dateFormatList[2]) }}
                                    onChange={this.handleStartDate}
                                    closeOnSelect={true}
                                />
                                {this.state.startDateState === "has-danger" ? (
                                    <label className="error hide-has-danger">{PROJECT_START_DATE_REQUIRD}</label>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <FormGroup className={`has-label ${endDateState} `}>
                                <Label >End Date</Label>
                                <ReactDatetime
                                    timeFormat={false}
                                    inputProps={{ placeholder: "End Date", className: "form-control" }}
                                    onChange={this.handleEndDate}
                                    closeOnSelect={true}
                                    defaultValue={this.state.project.endDate !== "" ? moment(this.state.project.endDate).format(dateFormatList[2]) : ''}
                                />
                                {/* {this.state.endDateState === "has-danger" ? (
                                    <label className="error hide-has-danger">{PROJECT_END_DATE_REQUIRD}</label>
                                ) : null} */}
                            </FormGroup>
                        </Col>
                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <FormGroup className={`has-label`}>
                                <Label >Actual End Date</Label>
                                <ReactDatetime
                                    timeFormat={false}
                                    inputProps={{ placeholder: "Actual End Date", className: "form-control", }}
                                    onChange={this.handleActualEndDate}
                                    closeOnSelect={true}
                                    defaultValue={this.state.project.actualEndDate !== "" ? moment(this.state.project.actualEndDate).format(dateFormatList[2]) : ''}
                                />
                                {/* {this.state.actualEndDateState === "has-danger" ? (
                                    <label className="error hide-has-danger">{PROJECT_END_DATE_REQUIRD}</label>
                                ) : null} */}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row xs="2">
                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <FormGroup className={`has-label ${this.state.hoursEstimatedState}`}>
                                <Label>Estimated Hours</Label>
                                <Input
                                    name="hoursEstimated"
                                    type="number"
                                    placeholder=""
                                    value={this.state.project.hoursEstimated}
                                    min={0}
                                    max={900000}
                                    // onInput="validity.valid||(value='');"
                                    onChange={e => this.handleChange(e)}
                                />
                                {this.state.hoursEstimatedState === "has-danger" ? (
                                    <label className="error">Please enter valid estimated hours!</label>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <FormGroup className={`has-label ${this.state.hoursActualState}`}>
                                <Label>Actual Hours</Label>
                                <Input
                                    name="hoursActual"
                                    type="number"
                                    placeholder=""
                                    min={0}
                                    // oninput="validity.valid||(value='');"
                                    value={this.state.project.hoursActual}
                                    onChange={e => this.handleChange(e)}
                                    step="any"
                                />
                                {this.state.hoursActualState === "has-danger" ? (
                                    <label className="error">Please enter valid actual hours!</label>
                                ) : null}
                            </FormGroup>
                        </Col>
                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <FormGroup className={`has-label ${this.state.percentCompletedState}`}>
                                <Label>Percent Completed</Label>
                                <Input
                                    name="percentCompleted"
                                    type="number"
                                    placeholder=""
                                    min={0}
                                    max={100}
                                    // oninput="validity.valid||(value='');"
                                    value={this.state.project.percentCompleted}
                                    onChange={e => this.handleChange(e)}

                                />
                                {this.state.percentCompletedState === "has-danger" ? (
                                    <label className="error">Please enter valid percent completed!</label>
                                ) : null}
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row xs="2">
                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <FormGroup className={`has-label`}>
                                <Label for="managerId">Manager</Label>
                                <Input
                                    type="select"
                                    name="managerId"
                                    // defaultValue={this.state.project.managerId}
                                    value={this.state.project.managerId}
                                    onChange={this.handleSelectManager}
                                    id="managerSelect"
                                    // onChange={e => this.change(e, "clientId", "length")}
                                    onFocus={e => this.setState({ managerIdFocus: true })}
                                    onBlur={e => this.setState({ managerIdFocus: false })}>
                                    <option value={0}>Select Manager</option>
                                    {managersOptions}
                                </Input>

                                {/* {this.state.clientIdState === "has-danger" ? (
                                    <label className="error">{PROJECT_CLIENT_REQUIRD}</label>
                                ) : null} */}
                            </FormGroup>

                        </Col>
                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                            <FileUpload
                                addBtnColor="default"
                                changeBtnColor="default"
                                handleImageChange={this.handleImageChange}
                                handleClick={this.handleClick}
                                handleRemove={this.handleRemove}
                                imagePreviewUrl={this.state.imagePreviewUrl}
                                // file={this.props.file != null ? this.props.file : this.state.document}
                                // file={this.state.project.poFile != null ? null : this.state.project.poFile}
                                file={this.state.project.poFile}
                                comp="projectDocument"
                                className="projectDocument"
                                selectedFile={this.state.selectedFile === null ? null : this.state.selectedFile.name !== undefined ? this.state.selectedFile.name : null}
                            />
                            <br />
                            <h5 style={{}}>max upload size: {maxFileSizeConfig !== undefined ? maxFileSizeConfig.paramValue + 'MB' : '5MB'}
                                <p>upload pdf,doc files only</p></h5>
                            {/* <h6 style={{  }}></h6> */}
                        </Col>

                        <Col style={{ paddingLeft: '50px', paddingRight: '50px' }}>

                        </Col>
                    </Row>
                </Form>
                <Button onClick={() => { this.handleCancel() }} className="btn-cancel" size="sm" color="info">Cancel</Button>
            </div>
        );
    }

}

export default Project;