import { FETCH_ALL_COMPANY_INITIAL_CONFIGS } from "../actions/companyConfigAction";

const companyConfigReducer = function (state = [], action) {
    let newState;
    let index;
    let id;
    switch (action.type) {
        case FETCH_ALL_COMPANY_INITIAL_CONFIGS:
            return action.configs;
        default:
            return state;
    }
};

export default companyConfigReducer;