import { FETCH_ALL_DEPARTMENTS } from "../actions/departmentActions";

const departmentReducer = function (state = [], action) {

    switch (action.type) {
        case FETCH_ALL_DEPARTMENTS:
            return action.departments;
        default:
            return state;
    }
};

export default departmentReducer;



// case ADD_UPDATE_DESIGNATION:
        //     let newState = [...state];
        //     let index = newState.findIndex(designation => designation.id === action.designation.id);
        //     if (index === -1) {
        //         newState.push(action.designation);
        //         return newState;
        //     } else {
        //         newState[index] = action.designation;
        //         return newState;
        //     }