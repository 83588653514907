import axios from 'axios';

export const fetchClientsAPI = (cid) => {
  return axios
    .get(`/api/v1/clients`, { params: { cid: cid } })
    .then(function (response) {

      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const addUpdateClient = (client) => {
  return axios
    .post(`/api/v1/add-update-client`, client)
    .then(function (response) {

      if (response.status == 200) {
        return { success: true };
      }
    })
    .catch(function (error) {

      return { success: false };
    });
}