import classNames from "classnames";
import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";
import { getAllDepartments } from "redux/actions/departmentActions";
import { addUpdateDepartment } from '../../../services/department-service';
import Pagination from "../../../components/common/pagination";
import DepartmentModal from "./DepartmentModal";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
import { AppContext } from '../../../hoc/AppContext';

class DepartmentMaster extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      formType: 'Add',
      department: {
        id: 0,
        cid: this.props.cid,
        department: {},
        isActive: 'Y'
      },
      data: [],
      alert: null,
      isDataLoading: false,
    };
  }

  UNSAFE_componentWillMount() {

    this.getDepartments();
  }

  getDepartments = async () => {
    this.setState({
      isDataLoading: true
    })
    await this.props.getAllDepartments(this.props.cid);

    this.setState({
      ...this.state,
      isDataLoading: false,
      data: this.props.departments.map((prop, key) => {
        return {
          SrNo: key+1,
          id: prop.id,
          deptId: prop.id,
          cid: prop.cid,
          department: prop.department,
          isActive: prop.isActive === "Y" ? 'active' : 'inactive',
          test: (
            <div className="actions-right">
              <Button
                onClick={() => { }}
                size="md"
                style={{ cursor: 'context-menu' }}
                className={classNames("btn-icon btn-link like", {
                  "btn-neutral": key < 5
                })}
              >
                {prop.isActive === 'Y' ? <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span> :
                  <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
                }
              </Button>{" "}
            </div>
          ),
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <Button
                id="tooltip209812"
                onClick={() => {
                  let obj = this.props.departments.find(o => o.id === prop.id);

                  let { id,
                    cid,
                    department,
                    isActive,
                  } = obj;
                  let SelectedDesignation = { id, cid, department, isActive }
                  this.showDepartmentModal(SelectedDesignation)
                }}
                // color="warning"
                size="sm"
                className={classNames("btn-icon btn-link like", {
                  "btn-neutral": key < 5
                })}
              >
                <i className="tim-icons icon-pencil" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip209812"
                placement="left"
              >
                Edit
                        </UncontrolledTooltip>
            </div>
          )
        };
      })
    });
  }

  notify = (place, color, message) => {
    // var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  };

  showDepartmentModal = (department) => {
    this.setState({
      formType: 'Update',
      showModal: true,
      department: { ...department }
    });
  }

  toggleShowModal = () => {

    this.setState({
      formType: 'Add',
      showModal: !this.state.showModal,
      department: {},

    });
  }

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  successAlert = (status) => {
    let options = {};
    options = {
      place: "tr",
      message: "Department " + status + " successfully",
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 2
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  };

  onClose = () => {

    this.setState({
      showModal: false,
    });
  }


  showNotifications = (color, message) => {
    this.notify("tr", color, message);
  };

  handleSubmit = async (department, formType) => {
    let status = '';
    if (formType === 'Add') {
      status = 'added'
    } else {
      status = 'updated'
    }
    this.toggleShowModal();
    let response = await addUpdateDepartment(department);
    if (response.success) {

      this.successAlert(status);
      this.getDepartments();
    } else {
      this.showNotifications(3, "Error occured while adding department");
    }

    this.setState({
      formType: 'Add',
      department: {},
    });
  }


  render() {
    const { config } = this.context;

    return (
      <>
        <div className="content">
          {this.state.alert}
          <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          {this.state.showModal && <DepartmentModal cid={this.props.cid} departments={this.props.departments} modal={this.state.showModal} toggle={this.toggleShowModal} onSubmit={this.handleSubmit} formType={this.state.formType} department={this.state.department} close={this.onClose} />}
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CardTitle tag="h2">Department Master Details</CardTitle>
                  <div style={{}}>
                    <Button size="sm" onClick={this.toggleShowModal} color="info">New</Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    PaginationComponent={Pagination}
                    data={this.state.data}
                    resizable={false}
                    columns={[
                      {
                        Header: "",
                        accessor: "test",
                        width: 70,
                      },
                      {
                        Header: "ID",
                        accessor: "SrNo",
                        width: 100,
                      },
                      {
                        Header: "Department",
                        accessor: "department",
                        width: 350,
                      },
                      // {
                      //     Header: "Company",
                      //     accessor: "company"
                      // },
                      {
                        Header: "Status ",
                        accessor: "isActive",
                        width: 350,
                      },
                      {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false
                      },

                    ]}
                    LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                    NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                    pageSize={this.props.departments.length <= config.paginationSize ? this.props.departments.length : config.paginationSize}
                    className="-striped -highlight project-table expense-table"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  getAllDepartments: bindActionCreators(getAllDepartments, dispatch)
});


export const mapStateToProps = ({ auth, departments, }) => {

  return {
    token: auth.user.token,
    cid: auth.user.cid,
    departments: departments,
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(DepartmentMaster);
