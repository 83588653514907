import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { REQUIRD_MESSAGE } from "../../../variables/constants";


class CountryModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'Add',
            country: {
                id: 0,
                country: '',
                countryCode: '',
            },
            countryNameState: '',
            countryCodeState: ''
        };
    }



    UNSAFE_componentWillMount() {
        if (this.props.formType === "Update") {
            this.setState({
                formType: this.props.formType,
                country: { ...this.props.country }
            });
        } else {
            this.setState({
                formType: this.props.formType,
            });
        }
    }

    verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    handleSubmit = async () => {
        let { country } = this.state.country;
        if (country === "") {
            this.setState({ countryState: "has-danger" });
        }
        else {
            let formType = this.props.formType;
            this.props.onSubmit(this.state.country, formType);
        }
    }

    onChangeTextHandler = (e) => {
        let country = this.state.country;

        country[e.target.name] = e.target.value;
        this.setState({
            countryState: country.country === "" ? "has-danger" : "has-success",
            country: country
        });

    }


    render() {

        let { countryState, countryCodeState } = this.state;
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="custom-modal "  >
                    <ModalHeader toggle={this.props.toggle}><h4>
                        {this.props.formType === "Update" ? "Update Country" : "Add New Country"}
                    </h4></ModalHeader>
                    <ModalBody>
                        <Form className="custom-form" id="LoginValidation">
                            <Row>
                                <Label md="3">Country Name</Label>
                                <Col md="8">
                                    <FormGroup className={`has-label ${countryState}`}>

                                        <Input
                                            name="country"
                                            type="text"
                                            placeholder="Country name"
                                            value={this.state.country.country}
                                            onChange={this.onChangeTextHandler}
                                        />
                                        {countryState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Label md="3">Country Code</Label>
                                <Col md="8">
                                    <FormGroup className={`has-label ${countryCodeState}`}>
                                        <Input
                                            name="countryCode"
                                            type="text"
                                            placeholder="Country code"
                                            value={this.state.country.countryCode}
                                            onChange={this.onChangeTextHandler}
                                        />
                                        {this.state.countryCodeState === "has-danger" ? (
                                            <label className="error">{REQUIRD_MESSAGE}</label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" size="sm" onClick={this.handleSubmit}>{this.props.formType}</Button>{' '}
                        <Button size="sm" color="secondary" onClick={this.props.close}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

}

export default CountryModal;