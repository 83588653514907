
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../assets//css/category-modal.css';
import LeaveTypeForm from './leavetypeform';



class LeaveTypeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };

  }
  UNSAFE_componentWillMount() {
  }

  render() {
    return (
      <Modal className="custom-modal" isOpen={this.props.isOpen} toggle={this.props.toggle} style={{
        width: "100%",
        maxWidth: 700
      }}>
        <ModalHeader style={{ padding: 20 }} toggle={this.props.toggle}><strong>Leave Type Details</strong></ModalHeader>
        <ModalBody>
          <LeaveTypeForm cid = { this.props.cid} leaveTypes={this.props.leaveTypes} leavetype={this.props.leavetype} addUpdateLeaveType={this.props.addUpdateLeaveType} toggle={this.props.toggle} showNotifications={this.props.showNotifications} formtype={this.props.formtype} />
        </ModalBody>
      </Modal>
    );
  }
}

export default LeaveTypeModal