
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../assets//css/category-modal.css';
import RoleForm from './rolesForm';



class RolesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };

  }
  UNSAFE_componentWillMount() {
  }

  render() {
    return (
      <Modal className="custom-modal" isOpen={this.props.isOpen} toggle={this.props.toggle} style={{
        width: "100%",
        maxWidth: 700
      }}>
        <ModalHeader style={{ padding: 20 }} toggle={this.props.toggle}>Role Details</ModalHeader>
        <ModalBody>
          <RoleForm cid={this.props.cid} roles={this.props.roles} role={this.props.role} addUpdateRole={this.props.addUpdateRole} toggle={this.props.toggle} showNotifications={this.props.showNotifications} client={this.props.client} formtype={this.props.formtype} />
        </ModalBody>
      </Modal>
    );
  }
}

export default RolesModal