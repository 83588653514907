import { FETCH_ADMIN_TIMESHEET_WEEKLY_REPORT, FETCH_ADMIN_TIMESHEET_QUARTERLY_REPORT, FETCH_ADMIN_TIMESHEET_MONTHLY_REPORT, FETCH_INITIAL_ADMIN_REPORT_DATA, FETCH_ADMIN_PROJECT_MARGIN_REPORT } from "../actions/adminTimesheetReportAction";
// import {  } from "../actions/timesheetReportAction";
const INITIAL_STATE = {
    timesheetWeeklyReport: [],
    timesheetMonthlyReport: [],
    timesheetQuarterlyReport: [],
    timesheetAdminProjectMarginReport: []
}
const adminTimesheetReportReducer = function (state = INITIAL_STATE, action) {
    let newState;

    switch (action.type) {
        case FETCH_ADMIN_TIMESHEET_WEEKLY_REPORT:
            newState = { ...state }
            newState.timesheetWeeklyReport = action.timesheetWeeklyReport;
            return newState;

        case FETCH_ADMIN_TIMESHEET_MONTHLY_REPORT:
            newState = { ...state };
            newState.timesheetMonthlyReport = [...action.timesheetMonthlyReport];
            return newState;

        case FETCH_ADMIN_TIMESHEET_QUARTERLY_REPORT:
            newState = { ...state }
            newState.timesheetQuarterlyReport = [...action.timesheetQuarterlyReport];
            return newState;
        case FETCH_INITIAL_ADMIN_REPORT_DATA:
            newState = {
                timesheetWeeklyReport: [],
                timesheetMonthlyReport: [],
                timesheetQuarterlyReport: []
            }
            return newState;
        case FETCH_ADMIN_PROJECT_MARGIN_REPORT:
            newState = { ...state }
            newState.timesheetAdminProjectMarginReport = [...action.adminProjectMarginReport];
            return newState;


        default:
            return state;
    }
};

export default adminTimesheetReportReducer;