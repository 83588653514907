import axios from 'axios';

export const getAllNotificationsAPI = (cid, eid) => {
    return axios
      .get(`/api/v1/notifications`,{
        params: {
            cid:cid,
            eid:eid
          }})
      .then(function (response) {
        if (response.data) {
          return { success: true, data:response.data };
        }else{
            return { success: false };
        }
      })
      .catch(function (error) {  
        return { success: false };
      });
}

export const readNotification = (notifications) => {
    return axios.post(`/api/v1/read-notifications`,notifications).
    then(function (response) {
        return response.data
      })
      .catch(function (error) {
        return false;
      });
}