import { addUpdateEmployeeAppraisalAPI, fetchEmployeeAppraisalByAppraisalIdAPI, fetchEmployeeAppraisalListAPI, fetchEmployeesAppraisalListAPI, fetchManagerEmployeesAppraisalByAppraisalIdAPI, updateManagerEmployeeAppraisalAPI } from "../../services/appraisal-service";

export const FETCH_EMPLOYEES_APPRAISAL_LIST = '[MANAGER] FETCH_EMPLOYEES_APPRAISAL_LIST';
export const FETCH_EMPLOYEES_APPRAISAL_LIST_SUCCESS = '[MANAGER] FETCH_EMPLOYEES_APPRAISAL_LIST_SUCCESS';
export const FETCH_EMPLOYEES_APPRAISAL_LIST_ERROR = '[MANAGER] FETCH_EMPLOYEE_APPRAISAL_LIST_ERROR';
export const SET_EMPLOYEES_APPRAISAL_LIST_PAGINATION = "[MANAGER] SET_EMPLOYEE_APPRAISAL_LIST_PAGINATION";

export const UPDATE_EMPLOYEE_APPRAISAL = "[MANAGER] UPDATE_EMPLOYEE_APPRAISAL";
export const UPDATE_EMPLOYEE_APPRAISAL_SUCCESS = "[MANAGER] UPDATE_EMPLOYEE_APPRAISAL_SUCCESS";
export const UPDATE_EMPLOYEE_APPRAISAL_ERROR = "[MANAGER] UPDATE_EMPLOYEE_APPRAISAL_ERROR";

export const FETCH_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID = '[MANAGER] FETCH_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID';
export const FETCH_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_START = '[MANAGER] FETCH_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_START';
export const FETCH_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_SUCCESS = '[MANAGER] FETCH_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_SUCCESS';
export const FETCH_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_ERROR = '[MANAGER] FETCH_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_ERROR';

export const RESET_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_SUCCESS = '[MANAGER] RESET_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_SUCCESS';

export const fetchEmployeesAppraisalList = (payload) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_EMPLOYEES_APPRAISAL_LIST,
        });
        const response = await fetchEmployeesAppraisalListAPI(payload);
        
        if (response.data) {
            // const pagination = {
            //     currentPage: payload.pageNo,
            //     pageSize: payload.pageSize,
            //     totalPages: response.data.totalPages,
            //     totalItems: response.data.totalItems,
            // }
            dispatch({
                type: FETCH_EMPLOYEES_APPRAISAL_LIST_SUCCESS,
                employeeAppraisalList: response.data.items
            });
            // dispatch({
            //     type: SET_EMPLOYEES_APPRAISAL_LIST_PAGINATION,
            //     pagination: pagination
            // });
            // return { success: true, employeeAppraisalList: response.data.items };
        }
        else {
            dispatch({
                type: FETCH_EMPLOYEES_APPRAISAL_LIST_ERROR,
            });
            return { success: false };
        }
    }
}


export const updateManagerEmployeeAppraisal = (payload) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_EMPLOYEE_APPRAISAL });

        const response = await updateManagerEmployeeAppraisalAPI(payload);
        
        if (response.data) {
            //   let result = response.data;
            dispatch({
                type: UPDATE_EMPLOYEE_APPRAISAL_SUCCESS,
                employeeAppraisal: response.data
            });
              return { success: true, result:response.data };
        }
        else {
            dispatch({ type: UPDATE_EMPLOYEE_APPRAISAL_ERROR });

            return { success: false };
        }
    }
}

export const fetchManagerEmployeesAppraisalByAppraisalId = (payload) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID });
        const response = await fetchManagerEmployeesAppraisalByAppraisalIdAPI(payload);
        
        if (response.data) {
            //   let result = response.data;
            dispatch({
                type: FETCH_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_SUCCESS,
                employeeAppraisal: response.data
            });
            //   return { success: true, result };
        }
        else {
            dispatch({ type: FETCH_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_ERROR });
            return { success: false };
        }
    }
}

export const resetEmployeeAppraisal = (payload) => {
    return async (dispatch) => {
    dispatch({
        type: RESET_EMPLOYEES_APPRAISAL_BY_APPRAISAL_ID_SUCCESS
    });
}
}