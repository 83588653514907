import React from "react";
import moment from 'moment';
import ReactDatetime from "react-datetime";
import Select from "react-select";
import Switch from "react-switch";
import {
  Badge,
  Button,
  Col,
  Form, FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import FileUpload from "../../../../components/CustomUpload/FileUpload";
import ImageUpload from "../../../../components/CustomUpload/ImageUpload";
import { checkFileSize, checkMimeType, maxSelectFile, verifyEmail, verifyLength, verifyNumber, verifySentense } from "../../../../components/common/validation";
import { checkEmailAlreadyExists, checkEmpIdAlreadyExists, deleteFile, uploadFile } from "../../../../services/index";
import { REQUIRD_MESSAGE, dateFormatList } from "../../../../variables/constants";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadProgress: 0,
      cid: 1,
      fname: "",
      lname: "",
      addressCur: "",
      emailPersonal: "",
      gender: "",
      doj: "",
      fnameState: "",
      mnameState: "",
      lnameState: "",
      empidState: "",
      phone1State: "",
      phone2State: "",
      dobState: "",
      emailPersonalState: "",
      emailOfficialState: "",
      addressCurState: "",
      addressPerState: "",
      genderState: "",
      dojState: "",
      terminationDate:"",
      employee: {},
      imagePreviewUrl: "",
      resumeFile: null,
      file: null,
      selectedFile: null,
      selectedImage: null,
      isEmailExistState: "",
      aadhaarNo:"",
      aadhaarNoState:"",
      panNo:"",
      panNoState:"",
      terminationDateState:"",
      terminationDate: moment(),
      isEmailExist: false,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handlePictureChange = this.handlePictureChange.bind(this);
    this.handleRemoveImage = this.handleRemoveImage.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.setState({
      employee: this.props.employee,
      resumeFile: this.props.employee.resume == "" ? this.props.employee.resume : JSON.parse(this.props.employee.resume),
      imagePreviewUrl: this.props.employee.userImage == "" ? this.props.employee.userImage : JSON.parse(this.props.employee.userImage)
    });
  }
  handleIsActive = (e) => {
    if (e === true) {
      this.setState({
        employee: {
          ...this.state.employee,
          isActive: 'Y'

        }
      });
    }
    else {
      this.setState({
        employee: {
          ...this.state.employee,
          isActive: 'N'

        }
      });
    }
  }
  handleStartDate = (e, stateName) => {
    if (e > 0) {
      this.setState({ [stateName + "State"]: "has-success" });
    } else {
      this.setState({ [stateName + "State"]: "has-danger" });
    }
    if (stateName == 'doj') {
      this.setState({
        dojName: moment(e).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
        employee: {
          ...this.state.employee,
          doj: moment(e).format('YYYY-MM-DD')
        }
      });
    } else if(stateName ==='terminationDate'){
      this.setState({
        dobName: moment(e).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
        employee: {
          ...this.state.employee,
          terminationDate: moment(e).format('YYYY-MM-DD')
        }
      });
    }else {
      this.setState({
        dobName: moment(e).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
        employee: {
          ...this.state.employee,
          dob: moment(e).format('YYYY-MM-DD')
        }

      });

    }
  }
  handleSelectStatus = (e, stateName) => {
    let employee = this.state.employee;
    employee[stateName] = parseInt(e.value);
    this.setState({
      employee: { ...employee }
    })
  }
  handleClearTerminationDate  = (e, stateName) => {
    let employee = this.state.employee;
    this.setState({
      employee: { ...employee,
      terminationDate:""
       }
    })
  }
  isEmailExists = async (emailOfficial) => {
    const value = await checkEmailAlreadyExists(this.props.cid, emailOfficial);
    if (value === true) {
      return true;
    } else {
      return false;
    }
  }
  isEmpidExists = async (cid, empid) => {
    let user = localStorage.getItem('user');
    const value = await checkEmpIdAlreadyExists(user.cid, empid);
    if (value === true) {
      return true;
    } else {
      return false;
    }
  }
  onChangeNumber(e, stateName) {
    let employee = { ...this.state.employee };
    if (e.target.value.length > 0) {
      this.setState({ [stateName + "State"]: "has-success" });
    } else {
      this.setState({ [stateName + "State"]: "has-danger" });
    }
    employee[e.target.name] = e.target.value.replace(/\D/, '');
    this.setState({ employee: employee });
  }
  onUploadProgress = (progressEvent) => {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );

    this.setState({ uploadProgress: percentCompleted });
  };
  handleImageChange = async (e) => {
    e.preventDefault();
    let checkMimeTypeValid = false;
    const types = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
    checkMimeTypeValid = checkMimeType(e, types);
    let maxSelectFileValid = false;
    maxSelectFileValid = maxSelectFile(e, 1);
    let checkFileSizeValid = false;
    checkFileSizeValid = checkFileSize(e);
    if (checkMimeTypeValid && maxSelectFileValid && checkFileSizeValid) {
      if (this.props.formType === "Add") {
        let files = e.target.files[0];
        this.setState({
          selectedFile: files,
          resumeFile: 'newResume',
          employee: {
            ...this.state.employee,
            resume: 'newResume'
          }
        })

      }
      else {
        let files = e.target.files[0];
        let filePath = `${this.state.employee.cid}/RESUMES/${this.state.employee.eid}`;
        const data = new FormData()
        data.append('filePath', filePath);
        data.append('files', files);
        if (this.state.resumeFile != null) {
          const response = await deleteFile(this.state.resumeFile[0]);
        }
        const response = await uploadFile(data, this.onUploadProgress);
        if (response.success) {
          let message = {
            message: 'Employee resume uploded successfully.',
            type: "success",
          }
          this.props.handleMessage(message);
          this.setState({
            file: response.data[0],
            resumeFile: response.data,
            employee: {
              ...this.state.employee,
              resume: JSON.stringify(response.data)
            }
          });
        } else {
          let message = {
            message: 'Error accured while uploading resume.',
            type: "warning",
          }
          this.props.handleMessage(message);
        }
      }
    } else {
      if (checkMimeTypeValid == false) {
        let message = {
          message: 'Uploded resume is not a supported format!',
          type: "warning",
        }
        this.props.handleMessage(message);
      }
      if (maxSelectFileValid == false) {
        let message = {
          message: 'Only 1 file can be uploaded at a time!',
          type: "warning",
        }
        this.props.handleMessage(message);
      } if (checkFileSizeValid == false) {
        let message = {
          message: 'Uploded resume size is too large, please pick a smaller file!',
          type: "warning",
        }
        this.props.handleMessage(message);
      }
    }

  }
  handlePictureChange = async (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let checkMimeTypeValid = false;
    const types = ['image/png', 'image/jpeg']
    checkMimeTypeValid = checkMimeType(e, types);
    let maxSelectFileValid = false;
    maxSelectFileValid = maxSelectFile(e, 1);
    let checkFileSizeValid = false;
    checkFileSizeValid = checkFileSize(e);
    if (checkMimeTypeValid && maxSelectFileValid && checkFileSizeValid) {
      let files = e.target.files[0];
      if (this.props.formType === "Add") {
        reader.onloadend = () => {
          this.setState({
            selectedImage: files,
            imagePreviewUrl: reader.result
          })
        }
        reader.readAsDataURL(files);
      }
      else {
        let reader = new FileReader();
        let files = e.target.files[0];
        reader.onloadend = () => {
          this.setState({
            imagePreviewUrl: reader.result,
          });
        };
        reader.readAsDataURL(files);
        let filePath = `${this.state.employee.cid}/PROFILEPHOTO/${this.state.employee.eid}`;
        const data = new FormData()
        data.append('filePath', filePath);
        data.append('files', files);
        if (this.state.imagePreviewUrl != "") {
          const response = await deleteFile(this.state.imagePreviewUrl[0]);
        }
        const response = await uploadFile(data, this.onUploadProgress);
        if (response.success) {
          let message = {
            message: 'Profile picture uploded successfully.',
            type: "success",
          }
          this.props.handleMessage(message);
          this.setState({
            file: response.data,
            imagePreviewUrl: response.data,
            employee: {
              ...this.state.employee,
              userImage: JSON.stringify(response.data)
            }
          });
        } else {
          let message = {
            message: 'Error accured while uploading profile!',
            type: "warning",
          }
          this.props.handleMessage(message);
        }
      }

    } else {
      if (checkMimeTypeValid == false) {
        let message = {
          message: 'Profile picture is not a supported format!',
          type: "warning",
        }
        this.props.handleMessage(message);
      }
      if (maxSelectFileValid == false) {
        let message = {
          message: 'Only 1 profile picture can be uploaded at a time!',
          type: "warning",
        }
        this.props.handleMessage(message);
      } if (checkFileSizeValid == false) {
        let message = {
          message: 'Profile picture size is too large,please pick a smaller file!',
          type: "warning",
        }
        this.props.handleMessage(message);
      }
    }
  }

  handleClick = (refs) => {
    refs.fileInput.click();
  }
  handleRemoveImage = async () => {
    if (this.state.employee && this.state.employee.userImage && this.state.employee.userImage != "" || null) {
      const response = await deleteFile(this.state.imagePreviewUrl[0]);
      this.setState({
        selectedImage: null,
        imagePreviewUrl: "",
        employee: {
          ...this.state.employee,
          userImage: ""
        }
      });
    } else {
      this.setState({
        selectedImage: null,
        imagePreviewUrl: "",
        employee: {
          ...this.state.employee,
          userImage: ""
        }
      });
    }


  }
  handleRemove = async (refs) => {
    if (this.state.employee && this.state.employee.resume && this.state.employee.resume != "" || null) {
      const response = await deleteFile(this.state.resumeFile[0]);
      this.setState({
        file: null,
        selectedFile: null,
        resumeFile: null,
        employee: {
          ...this.state.employee,
          resume: null
        }
      });
    }
    else {
      this.setState({
        file: null,
        selectedFile: null,
        resumeFile: null,
        resumeFile: null,
        employee: {
          ...this.state.employee,
          resume: null
        }
      });
    }
  }

  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
        if (verifyEmail(event.target.value)) {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value.trim();
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-success"
          });
        } else {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value;
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-danger"
          });
        }
        break;
      case "length":
        if (verifyLength(event.target.value, stateNameEqualTo)) {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value.trim();
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-success"
          });
        } else {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-danger"
          });
        }
        break;
      case "number":
        if (verifyNumber(event.target.value, stateNameEqualTo)) {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value.trim();
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-success"
          });
        } else {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value;
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-danger"
          });
        }
        break;
      case "sentense":
        if (verifySentense(event.target.value, stateNameEqualTo)) {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value;
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-success"
          });
        } else {
          let employee = { ...this.state.employee };
          employee[event.target.name] = event.target.value;
          this.setState({
            employee: employee,
            [stateName + "State"]: "has-danger"
          });
        }
        break;
      default:
        break;
    }
  };
  isValidated = () => {
    let {
      fname,
      lname,
      gender,
      emailPersonal,
      emailOfficial,
      phone1,
      dob,
      doj } = this.state.employee;
    var emailCount = 0;
    var isEmailExists = false;
    this.props.adminEmployees && this.props.adminEmployees.map((employee) => {
      if (employee.emailOfficial.toUpperCase() === this.state.employee.emailOfficial.trim().toUpperCase() && employee.id !== this.state.employee.id) {
        emailCount = emailCount + 1;
      }
    });
    if (this.props.employee.id === 0) {
      isEmailExists = emailCount >= 1 ? true : false;
    }
    isEmailExists = this.props.formType === "Update" ? false : isEmailExists;
    if (fname != "" && lname != "" && gender != "" && emailOfficial != "" && phone1 != "" && doj != "" && isEmailExists == false) {
      if (this.state.fnameState === "has-danger" || this.state.lnameState === "has-danger" ||
        this.state.genderState === "has-danger" || this.state.phone1State === "has-danger"
        || this.state.emailOfficialState === "has-danger" || this.state.dojState === "has-danger"
      ) {
        let message = {
          message: 'Please enter valid information',
          type: "warning",
        }
        this.props.handleMessage(message);
        return false;
      }
      else {
        if (this.props.formType === "Add") {
          this.props.submitBasicInfo(this.state.employee, this.state.selectedFile, this.state.selectedImage);
          this.setState({
            employee: {
              ...this.state.employee,
              resume: ""
            },
            isEmailExistState: "has-success"
          })
          return true;
        } else {
          this.props.submitBasicInfo(this.state.employee);
          return true;
        }
      }
    } else {
      if (fname == "") {
        this.setState({ fnameState: "has-danger" });
      }
      if (lname == "") {
        this.setState({ lnameState: "has-danger" });
      }
      if (gender == "") {
        this.setState({ genderState: "has-danger" });
      }
      if (emailOfficial == "") {
        this.setState({ emailOfficialState: "has-danger" });
      }
      if (phone1 == "") {
        this.setState({ phone1State: "has-danger" });
      }
      if (doj == "") {
        this.setState({ dojState: "has-danger" });
      }
      if (isEmailExists == true) {
        this.setState({ isEmailExistState: "has-danger" });
      }
      return false;
    }

  };

  handleCancel = () => {
    let passProps = {
      pathname: 'employeemaster',
    }
    this.props.history.push(passProps);
  }

  render() {
    return (
      <><div className="content"><Col className="mr-auto ml-auto" md="12">
        <Form className="custom-form custom-employee-form" id="LoginValidation">
          <Row className="justify-content-center">
            {/* <Col sm="4" style={{paddingLeft:'50px', paddingRight:'50px'}}>
         <Label>PSPL</Label>
          <FormGroup className={`has-label ${this.state.seriesState}`}>
              <Input
                name="series"
                placeholder="Series..."
                type="series"
                value={this.state.employee.series}
                maxLength="20"
                onChange={e => this.change(e, "series", "length", 1)}
                onFocus={e => this.setState({ seriesFocus: true })}
                onBlur={e => this.setState({ seriesFocus: false })}
              />
              {this.state.seriesState === "has-danger" ? (
                <label className="error">{REQUIRD_MESSAGE}</label>
              ) : null}
            </FormGroup>
         </Col> */}
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
              <Label>First Name *</Label>
              <FormGroup className={`has-label ${this.state.fnameState}`}>
                <Input
                  name="fname"
                  placeholder="First Name..."
                  type="text"
                  value={this.state.employee.fname}
                  maxLength="20"
                  onChange={e => this.change(e, "fname", "length", 1)}
                  onFocus={e => this.setState({ firstnameFocus: true })}
                  onBlur={e => this.setState({ firstnameFocus: false })}
                />
                {this.state.fnameState === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
              <Label>Middle Name </Label>
              <FormGroup className={`has-label ${this.state.mnameState}`}>
                <Input
                  name="mname"
                  placeholder="Middle Name..."
                  type="text"
                  maxLength="10"
                  value={this.state.employee.mname}
                  onChange={e => this.change(e, "mname", "length", 1)}
                  onFocus={e => this.setState({ middlenameFocus: true })}
                  onBlur={e => this.setState({ middlenameFocus: false })}
                />
                {this.state.mnameState === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
              <Label>Last Name *</Label>
              <FormGroup className={`has-label ${this.state.lnameState}`}>
                <Input
                  name="lname"
                  placeholder="Last Name..."
                  type="text"
                  maxLength="20"
                  value={this.state.employee.lname}
                  onChange={e => this.change(e, "lname", "length", 1)}
                  onFocus={e => this.setState({ lastnameFocus: true })}
                  onBlur={e => this.setState({ lastnameFocus: false })}
                />
                {this.state.lnameState === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
              <Label>Date of birth </Label>
              <FormGroup className={`has-label ${this.state.dobState}`}>
                <ReactDatetime
                  timeFormat={false}
                  name="dob"
                  inputProps={{ placeholder: "Date of birth", className: "form-control", value: this.state.employee.dob !== "" ? moment(this.state.employee.dob).format(dateFormatList[2]) : this.state.employee.dob }}
                  value={this.state.employee.dob}
                  onChange={e => this.handleStartDate(e, "dob", "length", 1)}
                  closeOnSelect={true}
                />
                {this.state.dobState === "has-danger" ? (
                  <label className="error hide-has-danger">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
              <Label>Gender *</Label>
              <FormGroup className={`has-label ${this.state.genderState}`}>
                <Input
                  name="gender"
                  placeholder="Gender..."
                  type="select"
                  defaultValue={this.state.employee.gender}
                  value={this.state.employee.gender}
                  onChange={e => this.change(e, "gender", "length")}
                  onFocus={e => this.setState({ genderFocus: true })}
                  onBlur={e => this.setState({ genderFocus: false })}
                ><option value="" disabled>Select gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </Input>
                {this.state.genderState === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
              <Label>Email Personal </Label>
              <FormGroup className={`has-label ${this.state.emailPersonalState}`}>
                <Input
                  name="emailPersonal"
                  placeholder="Email Personal..."
                  type="email"
                  maxLength="40"
                  value={this.state.employee.emailPersonal}
                  onChange={e => this.change(e, "emailPersonal", "email")}
                  onFocus={e => this.setState({ emailPersonalFocus: true })}
                  onBlur={e => this.setState({ emailPersonalFocus: false })}
                />
                {this.state.emailPersonalState === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
              <Label>Mobile Number *</Label>
              <FormGroup className={`has-label ${this.state.phone1State}`}>
                <Input
                  name="phone1"
                  placeholder="Mobile Number..."
                  type="text"
                  maxLength="10"
                  value={this.state.employee.phone1}
                  onChange={e => this.onChangeNumber(e, "phone1", "number", 1)}
                  onFocus={e => this.setState({ phone1Focus: true })}
                  onBlur={e => this.setState({ phone1Focus: false })}
                // disabled={this.props.formType === "Update"}
                />
                {this.state.phone1State === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
              <Label>Alternate Number </Label>
              <FormGroup className={`has-label ${this.state.phone2State}`}>
                <Input
                  name="phone2"
                  placeholder="Alternate Number..."
                  type="text"
                  maxLength="10"
                  value={this.state.employee.phone2}
                  onChange={e => this.onChangeNumber(e, "phone2", "number", 1)}
                  onFocus={e => this.setState({ phone2Focus: true })}
                  onBlur={e => this.setState({ phone2Focus: false })}
                />
                {this.state.phone2State === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
              <Label>Email Official *</Label>
              <FormGroup className={`has-label ${this.state.emailOfficialState} ${this.state.isEmailExistState}`}>
                <Input
                  name="emailOfficial"
                  placeholder="Email Official..."
                  type="text"
                  maxLength="40"
                  value={this.state.employee.emailOfficial}
                  onChange={e => this.change(e, "emailOfficial", "email")}
                  onFocus={e => this.setState({ emailOfficialFocus: true })}
                  onBlur={e => this.setState({ emailOfficialFocus: false })}
                // disabled={this.props.formType === "Update"
                />
                {this.state.emailOfficialState === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
                {this.state.isEmailExistState === "has-danger" ? (
                  <label className="error">{"Email is already exist"}</label>
                ) : null}
              </FormGroup>
            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
              <Label>Present Address </Label>
              <FormGroup className={`has-label ${this.state.addressCurState}`}>
                <Input
                  name="addressCur"
                  placeholder="Present Address"
                  type="text"
                  maxLength="100"
                  value={this.state.employee.addressCur}
                  onChange={e => this.change(e, "addressCur", "sentense")}
                  onFocus={e => this.setState({ addressCurFocus: true })}
                  onBlur={e => this.setState({ addressCurFocus: false })}
                />
                {this.state.addressCurState === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
              <Label>Permanent Address </Label>
              <FormGroup className={`has-label ${this.state.addressPerState}`}>
                <Input
                  name="addressPer"
                  placeholder="Permanent Address..."
                  type="text"
                  maxLength="100"
                  value={this.state.employee.addressPer}
                  onChange={e => this.change(e, "addressPer", "sentense")}
                  onFocus={e => this.setState({ addressPerFocus: true })}
                  onBlur={e => this.setState({ addressPerFocus: false })}
                />
                {this.state.addressPerState === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>

            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
              <Label>Zip Code </Label>
              <FormGroup className={`has-label ${this.state.zipcodeState}`}>
                <Input
                  name="zipcode"
                  placeholder="Zip Code..."
                  type="text"
                  maxLength="10"
                  value={this.state.employee.zipcode}
                  onChange={e => this.onChangeNumber(e, "zipcode", "length", 1)}
                  onFocus={e => this.setState({ zipcodeFocus: true })}
                  onBlur={e => this.setState({ zipcodeFocus: false })}
                />
                {this.state.zipcodeState === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px', }}>
              <Label>Date of joining *</Label>
              <FormGroup className={`has-label ${this.state.dojState}`}>
                <ReactDatetime
                  timeFormat={false}
                  name="doj"
                  inputProps={{ placeholder: "Date of joining", className: "form-control", value: this.state.employee.doj !== "" ? moment(this.state.employee.doj).format(dateFormatList[2]) : this.state.employee.doj }}
                  value={this.state.employee.doj}
                  onChange={e => this.handleStartDate(e, "doj", "length", 1)}
                  closeOnSelect={true}
                />
                {this.state.dojState === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
              <Label >Upload Profile Picture</Label>
              <ImageUpload
                addBtnColor="default"
                changeBtnColor="default"
                component="employee"
                accept="image/png,image/jpeg"
                handleImageChange={this.handlePictureChange}
                handleClick={this.handleClick}
                handleRemove={this.handleRemoveImage}
                imagePreviewUrl={this.state.imagePreviewUrl === "" ? this.props.image : this.state.imagePreviewUrl}
                file={this.state.selectedImage}
              />
              <br />
              <h5 style={{}}>max upload size: 5MB
                <p>upload png,jpeg images only</p></h5>
            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', paddingRight: '50px' }} >
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
              <div>
              <Label>Last Date</Label>
              <FormGroup className={`has-label ${this.state.terminationDate}`}>
                
                <ReactDatetime
                  timeFormat={false}
                  name="terminationDate"
                  inputProps={{ placeholder: "Last date", className: "form-control", value: this.state.employee.terminationDate !== "" ? moment(this.state.employee.terminationDate).format(dateFormatList[2]) : this.state.employee.terminationDate }}
                  value={this.state.employee.terminationDate}
                  onChange={e => this.handleStartDate(e, "terminationDate", "length", 1)}
                  closeOnSelect={true}                  
                />             
              </FormGroup>
              </div>
              <div>
              <Badge
                    onClick={() => {this.handleClearTerminationDate()}}
                    style={{marginTop:'15px'}}
                    size="sm"
                    color="info"

                  >Clear
                  </Badge>
                  </div>
              </div>
              {/* <Button onClick={() => { this.handleCancel() }} className="btn-cancel" size="xs" color="info">Clear</Button> */}
              <Label>Aadhar Number</Label>
              <FormGroup className={`has-label ${this.state.aadhaarNoState}`}>
                <Input
                  name="aadhaarNo"
                  placeholder="Aadhar Number..."
                  type="text"
                  maxLength="100"
                  value={this.state.employee.aadhaarNo}
                  onChange={e => this.change(e, "aadhaarNo", "sentense")}
                  onFocus={e => this.setState({ aadhaarNoFocus: true })}
                  onBlur={e => this.setState({ aadhaarNoFocus: false })}
                />
                {this.state.aadhaarNoState === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
              <br />
              <div>
                <Label >Active</Label>
              </div>
              <div id="tooltip209852" style={{ marginLeft: '50px' }}>
                <Switch
                  onChange={(e) => { this.handleIsActive(e) }}
                  name="isActive"
                  id="isActive"
                  checked={this.state.employee.isActive === 'Y' ? true : false}
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48} />
                <UncontrolledTooltip
                  delay={0}
                  target="tooltip209852"
                  placement="top"
                >
                  {this.state.employee.isActive === "Y" ? "Active" : "Inactive"}
                </UncontrolledTooltip>
              </div>

             

            </Col>
            <Col sm="4" style={{ paddingLeft: '50px', marginTop: '-2px', paddingRight: '50px' }}>
              <Label>Experience</Label>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginBottom:'10px' }}>
                <div style={{ width: '141px' }}>
                  <Select closeMenuOnSelect={false} placeholder="Year"
                    classNamePrefix="react-select" value={this.state.employee.experienceYear == null ? this.state.employee.experienceYear : { value: parseInt(this.state.employee.experienceYear), label: String(this.state.employee.experienceYear) }} name="experienceYear" className="react-select info"
                    defaultValue={this.state.employee.experienceYear} onChange={e => this.handleSelectStatus(e, "experienceYear")}
                    options={[
                      { value: 1, label: "1" },
                      { value: 2, label: "2" },
                      { value: 3, label: "3" },
                      { value: 4, label: "4" },
                      { value: 5, label: "5" },
                      { value: 6, label: "6 " },
                      { value: 7, label: "7" },
                      { value: 8, label: "8 " },
                      { value: 9, label: "9" },
                      { value: 10, label: "10" },
                      { value: 11, label: "11" },
                      { value: 12, label: "12 " },
                      { value: 13, label: "13" },
                      { value: 14, label: "14" },
                      { value: 15, label: "15" },
                      { value: 16, label: "16" },
                      { value: 17, label: "17" },
                      { value: 18, label: "18" },
                      { value: 19, label: "19" },
                      { value: 20, label: "20" },
                      { value: 21, label: "21" },
                      { value: 22, label: "22" },
                      { value: 23, label: "23" },
                      { value: 24, label: "24" },
                      { value: 25, label: "25" },
                    ]}

                  >
                  </Select>
                </div>
                <div style={{ width: '141px' }}>
                  <Select closeMenuOnSelect={false} placeholder="Month" className="react-select info"
                    classNamePrefix="react-select" value={this.state.employee.experienceMonth == null ? this.state.employee.experienceMonth : { value: parseInt(this.state.employee.experienceMonth), label: String(this.state.employee.experienceMonth) }} name="experienceYear" defaultValue={this.state.employee.experienceMonth}
                    onChange={e => this.handleSelectStatus(e, "experienceMonth")}
                    options={[
                      { value: 1, label: "1" },
                      { value: 2, label: "2" },
                      { value: 3, label: "3" },
                      { value: 4, label: "4" },
                      { value: 5, label: "5" },
                      { value: 6, label: "6 " },
                      { value: 7, label: "7" },
                      { value: 8, label: "8 " },
                      { value: 9, label: "9" },
                      { value: 10, label: "10" },
                      { value: 11, label: "11" },
                    ]}

                  >
                  </Select>
                </div>
              </div>
              <Label>Pan Number</Label>
              <FormGroup className={`has-label ${this.state.panNoState}`}>
                <Input
                  name="panNo"
                  placeholder="Pan Number..."
                  type="text"
                  maxLength="100"
                  value={this.state.employee.panNo}
                  onChange={e => this.change(e, "panNo", "sentense")}
                  onFocus={e => this.setState({ panNoFocus: true })}
                  onBlur={e => this.setState({ panNoFocus: false })}
                />
                {this.state.panNoState === "has-danger" ? (
                  <label className="error">{REQUIRD_MESSAGE}</label>
                ) : null}
              </FormGroup>
              <br />
              <h5 style={{marginBottom:'-20px'}}>max upload size: 5MB
                <p>upload pdf,doc,docx files only</p>
              </h5>
              <FileUpload
                addBtnColor="default"
                changeBtnColor="default"
                handleImageChange={this.handleImageChange}
                handleClick={this.handleClick}
                accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                handleRemove={this.handleRemove}
                imagePreviewUrl={this.state.imagePreviewUrl}
                file={this.state.resumeFile === "" ? this.props.file : this.state.resumeFile}
              />
            </Col>
          </Row>
        </Form>
      </Col>
      </div>
        <Button onClick={() => { this.handleCancel() }} className="btn-cancel" size="sm" color="info">Cancel</Button>
      </>
    );
  }
}

export default Wizard;