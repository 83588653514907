import { FETCH_NEW_LEAVES } from '../actions/manageLeaveActions';


const INITIAL_STATE = []

const manageLeaves = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case FETCH_NEW_LEAVES:
            newState = action.newLeaves;
            return newState;

        default: return state;
  }
};

export default manageLeaves;
