import React from "react";
import ReactWizard from "react-bootstrap-wizard";
import axios from 'axios';

import { Col, Row, Card } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NotificationAlert from "react-notification-alert";
import { getAllCurrencies } from "redux/actions/currenciesAction";
import { getAllCountries } from "redux/actions/countryAction"
import { addUpdateCompany, deleteCompany, getAllCompanies } from "redux/actions/companyActions";
import { getCompanyContacts } from "redux/actions/companyContactAction";
import { getCompanyLocations } from "redux/actions/companyLocationAction";
import { getAllInitialCompanyConfigs } from "redux/actions/companyConfigAction";
import { uploadFile } from "../../../services/index";
import { addUpdateCompanyLocations, addUpdateCompanyContacts, addUpdateCompanyConfig } from "../../../services/company-service";
import Step1 from "./AddUpdateSteps/Step1";
import Step2 from "./AddUpdateSteps/Step2";
import Step3 from "./AddUpdateSteps/Step3";
import Step4 from "./AddUpdateSteps/Step4";
import { Redirect } from "react-router-dom";

class AddEditCompanyStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalDemo: false,
      selectedOption: null,
      company: {},
      image: '',
      id: 0,
      file: null
    };
  }
  async UNSAFE_componentWillMount() {
    await this.props.getAllCurrencies();
    await this.props.getAllCountries();
    // if(this.props.employee){
    //   this.props.getEmployeeExtraInfo(this.props.employee.id);
    //   this.props.getEmployeeBankInfo(this.props.employee.id);
    // }
  }


  handleMessage = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message.message,
      type: message.type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  AddLogoForAddCompany = async (company, selectedFile) => {
    let filePath = `${company.id}/DOCS/LOGO`;
    let files = selectedFile;
    const data = new FormData()
    data.append('filePath', filePath);
    data.append('files', files);

    const fileResponse = await uploadFile(data, this.onUploadProgress);

    let companyData = {
      ...company,
      logoPath: fileResponse.data[0]
    }
    this.setState({
      file: fileResponse.data[0]
    })
    const result = await this.props.addUpdateCompany(companyData);
    if (result.success) {
      let passProps = {
        state: {
          formType: 'Update',
          newCompany: result.data,
        }
      }
      this.props.history.push(passProps);
      this.setState({
        company: result.data,
        cid: result.data.id
      })
      let message = {
        message: 'Company has been added successfully.',
        type: "success",
      };
      this.handleMessage(message);
      await this.props.getCompanyLocations(companyData.id);
    } else {
      let options = {};
      options = {
        place: "tr",
        message: 'Error occurred while adding company.',
        type: "warning",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 3
      };
      if (this.refs.notificationAlert) {
        this.refs.notificationAlert.notificationAlert(options);
      }
    }
  }

  onUploadProgress = (progressEvent) => {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );

    this.setState({ uploadProgress: percentCompleted });
  };

  submitBasicInfo = async (company, selectedFile) => {

    if (this.props.location.state.formType === "Add") {
      const response = await this.props.addUpdateCompany(company);
      if (response.success) {
        //
        let data = response.data;
        this.setState({
          company: data,

        })

        if (selectedFile != null) {
          this.AddLogoForAddCompany(response.data, selectedFile);
        } else {
          let passProps = {
            state: {
              formType: 'Update',
              newCompany: response.data,
            }
          }
          this.props.history.push(passProps);
          let message = {
            message: 'Company has been added successfully.',
            type: "success",
          };
          this.handleMessage(message);
          await this.props.getCompanyLocations(company.id);
          // await this.props.getAllCompanies();

        }


      } else {
        let options = {};
        options = {
          place: "tr",
          message: 'Error occurred while adding company.',
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3
        };
        if (this.refs.notificationAlert) {
          this.refs.notificationAlert.notificationAlert(options);
        }
        await this.props.getCompanyLocations(company.id);
      }
    }
    else {

      const response = await this.props.addUpdateCompany(company);
      if (response.success) {
        let message = {
          message: 'Company updated successfully.',
          type: "success",
        };
        this.handleMessage(message);
        await this.props.getCompanyLocations(company.id);
      } else {
        let message = {
          message: 'Error updating the company!',
          type: "warning",
        };
        this.handleMessage(message);
        await this.props.getCompanyLocations(company.id);
      }
    }
  }

  submitExtraInfo = async (companyLocation) => {

    let response = await addUpdateCompanyLocations(companyLocation);
    await this.props.getCompanyContacts(companyLocation.cid);
    await this.props.getCompanyLocations(companyLocation.cid);
    return response;
  }

  submitConfigInfo = async (configurations) => {

    let result = await addUpdateCompanyConfig(configurations);

    if (result.success) {

      let message = {
        message: 'Company configurations have been added successfully.',
        type: "success",
      };
      await this.handleMessage(message);
      let passProps = {
        pathname: 'companymaster',
      }
      this.props.history.push(passProps);
      return result;
    } else {
      let options = {};
      options = {
        place: "tr",
        message: 'Error occurred while adding company configs.',
        type: "warning",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 3
      };
      if (this.refs.notificationAlert) {
        this.refs.notificationAlert.notificationAlert(options);
      } return result;
    }
  }

  submitContactInfo = async (contact) => {

    let response = await addUpdateCompanyContacts(contact);
    await this.props.getCompanyContacts(contact.cid);
    return response;

  }



  handleMessage = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message.message,
      type: message.type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  render() {
    if (!this.props.company) {
      return <Redirect to="/admin/companymaster" />
    }
    const steps = [
      {
        stepName: "Basic Details",
        stepIcon: "tim-icons icon-bank",
        component: Step1,
        stepProps: {
          company: this.props.company,
          cid: this.props.cid,
          companies: this.props.companies,
          currencies: this.props.currencies,
          submitBasicInfo: this.submitBasicInfo,
          handleMessage: this.handleMessage,
          image: this.state.image,
          getAllCompanies: this.props.getAllCompanies,
          formType: this.props.location.state.formType,
          history: this.props.history
        }
      },
      {
        stepName: "Company Locations",
        stepIcon: "tim-icons icon-map-big",
        component: Step2,
        stepProps: {
          company: this.props.company,
          countries: this.props.countries,
          cid: this.props.company.id,
          submitExtraInfo: this.submitExtraInfo,
          getCompanyLocations: this.props.getCompanyLocations,
          getCompanyContacts: this.props.getCompanyContacts,
          companyLocations: this.props.companyLocations ? this.props.companyLocations : [],
          formType: this.props.location.state.formType,
          history: this.props.history
        }
      },
      {
        stepName: "Company Contacts",
        stepIcon: "tim-icons icon-badge",
        component: Step4,
        stepProps: {
          company: this.props.company,
          cid: this.props.company.id,
          submitContactInfo: this.submitContactInfo,
          getCompanyContacts: this.props.getCompanyContacts,
          contacts: this.props.contacts ? this.props.contacts : [],
          companyLocations: this.props.companyLocations ? this.props.companyLocations : [],
          getAllInitialCompanyConfigs: this.props.getAllInitialCompanyConfigs,
          formType: this.props.location.state.formType,
          history: this.props.history
        }
      },
      {
        stepName: "Company Config",
        stepIcon: "tim-icons icon-settings",
        component: Step3,
        stepProps: {

          formType: this.props.location.state.formType,
          submitConfigInfo: this.submitConfigInfo,
          companyLocations: this.props.companyLocations ? this.props.companyLocations : [],
          currencies: this.props.currencies,
          getAllInitialCompanyConfigs: this.props.getAllInitialCompanyConfigs,
          configs: this.props.configs ? this.props.configs : [],
          cid: this.props.company.id,
          history: this.props.history
        }
      }
    ];

    return (
      <>
        <div className="content">
          <div className="rna-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          <Row>
            <Col xs={12} md={12}>
              <Card className="custom-wizard">
                <ReactWizard
                  steps={steps}
                  navSteps
                  validate
                  title=""
                  formType={this.props.location.state.formType}
                  company={this.props.location.state.company}
                  headerTextLeft
                  finishButtonClasses="btn-wd btn-info"
                  nextButtonClasses="btn-wd btn-info"
                  previousButtonClasses="btn-wd"
                  progressbar
                  color="blue"
                />
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}


export const mapDispatchToProps = dispatch => ({
  getAllCompanies: bindActionCreators(getAllCompanies, dispatch),
  getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch),
  getAllCountries: bindActionCreators(getAllCountries, dispatch),
  addUpdateCompany: bindActionCreators(addUpdateCompany, dispatch),
  getCompanyLocations: bindActionCreators(getCompanyLocations, dispatch),
  getCompanyContacts: bindActionCreators(getCompanyContacts, dispatch),
  getAllInitialCompanyConfigs: bindActionCreators(getAllInitialCompanyConfigs, dispatch)

});

export const mapStateToProps = ({ companies, currencies, countries, companyLocations, contacts, configs }, ownProps) => {
  let company = companies.find(o => o.id === ownProps.location.state.cid);
  return {
    company: company ? company : ownProps.location.state.newCompany,
    currencies: currencies,
    countries: countries,
    contacts: contacts,
    companyLocations: companyLocations,
    configs: configs,
    companies: companies
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCompanyStepper);
