import { FETCH_ALL_EMPLOYEES_CLAIMS,ADD_UPDATE_EMPLOYEE_CLAIM , APPROVE_REJECT_EMPLOYEE_CLAIM, DELETE_CLAIM} from '../actions/claimsAction';

const claimsReducer = function (state = [], action) {
    let newState;
    let index;
    let id;
    switch (action.type) {
        case FETCH_ALL_EMPLOYEES_CLAIMS:
            return action.claims;
        case ADD_UPDATE_EMPLOYEE_CLAIM:
            newState = [...state];
            index = newState.findIndex(newClaim =>newClaim.id === action.payload.id);
            if (index > -1) {
                newState.splice(index, 1, action.payload.claim);
            }
            else{
                newState = [...state];
                newState.push(action.payload.claim);
                return newState;
            }
            return newState;
        case APPROVE_REJECT_EMPLOYEE_CLAIM:
            newState = [...state];
            id = action.payload.id;
            index = newState.findIndex(claim =>claim.id === id);
            if (index > -1) {
                newState.splice(index, 1);
            };
            return newState;
        case DELETE_CLAIM:
            newState = [...state];
            id = action.payload.id;
            index = newState.findIndex(claim =>claim.id === id);
            if (index > -1) {
                newState.splice(index, 1);
            };
            return newState;
        default:
            return state;
    }
};

export default claimsReducer;
