import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Badge,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import moment from 'moment';
import NotificationAlert from "react-notification-alert";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Pagination from "../../components/common/pagination";
import { getAllEmployeeClaims, deleteClaim, addUpdateEmployeeClaim } from "../../redux/actions/claimsAction"
import ViewClaimItemsModal from "./ApproveRejectExpense/ViewClaimItemsModal";
import LoadingIndicator from "../../components/common/LoadingIndicator";
import NoData from "../../components/common/NoData";
import { AppContext } from '../../hoc/AppContext';
import { dateFormatList } from "variables/constants";
class ExpenseManager extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isOpenStepper: false,
      cid: 1,
      eid: '147',
      isOpenEmpDetails: false,
      empId: -1,
      formType: "Add",
      isLoading: false,
      isSubmit: false,
      isOpenClaimModal: false,
      claim: null,
    };
  }
  async UNSAFE_componentWillMount() {
    this.setState({
      isDataLoading: true
    })
    const response = await this.props.getAllEmployeeClaims(this.props.cid, this.props.eid);
    if (response) {
      this.setState({
        isDataLoading: false
      })
    }
  }
  handeOpenModal = () => {
    let passProps = {
      pathname: 'create-expense',
      state: {
        expId: 0,
        formType: 'Add',
        employeeClaim: {
          id: 0,
          claimNumber: "",
          eid: this.props.eid,
          cid: this.props.cid,
          claimType: "",
          dateSubmitted: moment(new Date).format('YYYY-MM-DD'),
          dateApproved: "",
          dateRejected: "",
          shortDesc: "",
          amount: 0,
          financeApprovalDate: null,
          financeApprovalStatus: "Pending",
          managerApprovalDate: null,
          managerApprovalStatus: "Pending",
          claimStatus: "Draft",
          remark: "",
          empName: "",
          pid: 0,
          noOfItems: 0,
          claimItems: [{
            id: 0,
            claimId: 0,
            eid: this.props.eid,
            cid: this.props.cid,
            claimCategoryId: 0,
            claimType: '',
            currencyId: 2,
            amount: 0,
            dateTransaction: '',
            dateApproved: '',
            dateRejected: '',
            rejectionNote: '',
            attachments: ''
          }],
          isDeleted: "N"
        },
      }
    }
    this.props.history.push(passProps);
  };
  handleSubmit = async (claim) => {
    if (this.state.isSubmit === false) {
      this.setState({
        isSubmit: true
      })
      let claimData = { ...claim }
      claimData = {
        ...claim,
        claimStatus: "Submitted",
      }
      const result = await this.props.addUpdateEmployeeClaim(claimData);
      if (result.success) {
        let options = {};
        options = {
          place: "tr",
          message: "Claim submitted successfully.",
          type: "success",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 2
        };
        if (this.refs.notificationAlert) {
          this.refs.notificationAlert.notificationAlert(options);
        }
        setTimeout(() => {
          this.setState({
            isSubmit: false
          })
        }, 3000);
      } else {
        let options = {};
        options = {
          place: "tr",
          message: 'Error occurred while submitting claim.',
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 2
        };
        if (this.refs.notificationAlert) {
          this.refs.notificationAlert.notificationAlert(options);
        }
        setTimeout(() => {
          this.setState({
            isSubmit: false
          })
        }, 3000);
      }
    }
  }
  openEmployeeModal = (key) => {
    let passProps = {
      pathname: 'create-expense',
      state: {
        id: key,
        formType: 'Update',
      }
    }
    this.props.history.push(passProps);
  }


  handleDelete = async (id) => {
    if (this.state.isLoading === false) {
      this.setState({
        isLoading: true
      })
      const response = await this.props.deleteClaim(id);
      if (response.success) {
        let options = {};
        options = {
          place: "tr",
          message: 'Claim deleted successfully.',
          type: "success",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3
        };
        if (this.refs.notificationAlert) {
          this.refs.notificationAlert.notificationAlert(options);
        }
        setTimeout(() => {
          this.setState({
            isLoading: false
          })
        }, 3000);
      } else {
        let options = {};
        options = {
          place: "tr",
          message: 'Error occurred while deleting claim.',
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3
        };
        if (this.refs.notificationAlert) {
          this.refs.notificationAlert.notificationAlert(options);
        }
        setTimeout(() => {
          this.setState({
            isLoading: false
          })
        }, 3000);
      }
    }
  }
  toggleClaimItemsModal = (claim) => {
    this.setState({
      isOpenClaimModal: !this.state.isOpenClaimModal,
      claim: claim
    });
  }

  onClose = () => {
    this.setState({
      isOpenClaimModal: false,
    });
  }

  render() {
    const { config } = this.context;
    const claimData = this.props.claims.map((exp, key) => {
      return {
        index: key,
        claimNumber: exp.claimNumber.toUpperCase(),
        dateSubmitted: moment(exp.dateSubmitted).format(dateFormatList[2]),
        shortDesc: (<div>
          <span id={"tooltip209891" + key}>{exp.shortDesc}</span>
          <UncontrolledTooltip
            delay={0}
            target={"tooltip209891" + key}
            placement="right"
          >
            {exp.shortDesc}
          </UncontrolledTooltip>

        </div>
        ),
        remark: (<div>
          <span id={"tooltip209591" + key}>{exp.remark}</span>
          <UncontrolledTooltip
            delay={0}
            target={"tooltip209591" + key}
            placement="right"
          >
            {exp.remark}
          </UncontrolledTooltip>

        </div>
        ),
        amount: exp.amount.toFixed(2),
        claimStatus: exp.claimStatus,
        noOfItems: exp.claimItems == null ? 0 : exp.claimItems.length,
        // select:(
        //   <div className="actions-right">
        //           <FormGroup check className="pull-left">
        //               <Label check>
        //                 <Input name="optionCheckboxes" type="checkbox" />
        //                 <span className="form-check-sign" />
        //                 Accept the terms and conditions
        //               </Label>
        //             </FormGroup>
        // </div>


        // ),
        view: (
          // we've added some custom button actions
          <div>
            {exp.claimStatus !== "Approved" ?
              <div className="actions-center" >
                {exp.claimStatus !== "Submitted" ?
                  <Button
                    onClick={() => {
                      this.openEmployeeModal(exp.id);
                    }
                    }
                    id="tooltip209511"
                    size="sm"
                    className={classNames("btn-icon btn-link like", {
                      "btn-neutral": key < 5
                    })}
                  >
                    <i className="tim-icons icon-pencil" id="tooltip209511" />
                    <UncontrolledTooltip
                      delay={0}
                      target="tooltip209511"
                      placement="left"
                    >
                      Edit
                    </UncontrolledTooltip>
                  </Button>
                  : <React.Fragment />
                }

                {exp.claimStatus !== "Submitted" ?
                  <Button
                    onClick={() => {
                      this.handleDelete(exp.id);
                    }}
                    style={{ marginLeft: 25, marginRight: 25 }}
                    id="tooltip209522"
                    size="sm"
                    className={classNames("btn-icon btn-link like", {
                      "btn-neutral": key < 5
                    })}
                  >
                    <i className="tim-icons icon-trash-simple" />
                    <UncontrolledTooltip
                      delay={0}
                      target="tooltip209522"
                      placement="left"
                    >
                      Delete
                    </UncontrolledTooltip>
                  </Button>
                  : <React.Fragment />
                }
                {exp.claimStatus !== "Submitted" ?
                  <Badge
                    onClick={() => {
                      this.handleSubmit(exp);
                    }
                    }
                    disabled={exp.claimStatus !== "Approved" ? false : true}
                    style={{ padding: 7, marginRight: 5 }}
                    size="sm"
                    color="info"
                  >Submit
                  </Badge>
                  : <React.Fragment />
                }

              </div>
              :
              <div className="actions-left">
                <Button
                  onClick={() => {
                    this.toggleClaimItemsModal(exp);
                  }}
                  size="sm"
                  id="tooltip209543"
                  className={classNames("btn-icon btn-link like", {
                    "btn-neutral": key < 5
                  })}
                >
                  <i className="tim-icons icon-zoom-split" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="tooltip209543"
                  placement="left"
                >
                  View
                </UncontrolledTooltip>
              </div>
            }
          </div>

        ),
      };
    });
    return (
      <>
        <div className="content">
          <div className="rna-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          {this.state.isOpenClaimModal &&
            <ViewClaimItemsModal modal={this.state.isOpenClaimModal} claim={this.state.claim}
              toggle={this.toggleClaimItemsModal} close={this.onClose} />
          }
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CardTitle tag="h2">Expense Manager</CardTitle>
                  <div style={{ display: 'flex' }}>
                    <Button color="info" className="btn-wd btn-info" onClick={this.handeOpenModal}>Apply New Claim</Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    PaginationComponent={Pagination}
                    data={claimData}
                    resizable={false}
                    columns={[
                      {
                        Header: "Claim No.",
                        accessor: "claimNumber",
                        sortable: false,
                        filterable: false,
                        width: 120,
                      },
                      {
                        Header: "Submitted Date",
                        accessor: "dateSubmitted",
                        width: 130,
                      },
                      {
                        Header: "Description",
                        accessor: "shortDesc",
                        width: 200,
                      },
                      {
                        Header: "Remark",
                        accessor: "remark",
                        width: 150,
                      },
                      {
                        Header: "Amount",
                        accessor: "amount",
                        width: 70,
                      },
                      {
                        Header: "Status",
                        accessor: "claimStatus",
                        width: 95,
                      },
                      {
                        Header: "Items",
                        accessor: "noOfItems",
                        width: 52,
                      }, {
                        Header: "",
                        accessor: "view",
                        width: 170,
                      },
                    ]}
                    LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                    NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                    // pageSize={this.props.claims.length <= 10 ? this.props.claims.length : 10}
                    pageSize={this.props.claims.length <= config.paginationSize ? this.props.claims.length : config.paginationSize}
                    className="-striped -highlight project-table expense-table"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  getAllEmployeeClaims: bindActionCreators(getAllEmployeeClaims, dispatch),
  addUpdateEmployeeClaim: bindActionCreators(addUpdateEmployeeClaim, dispatch),
  deleteClaim: bindActionCreators(deleteClaim, dispatch)
});

export const mapStateToProps = ({ claims, auth }) => ({
  claims: claims,
  cid: auth.user.cid,
  eid: auth.user.eid
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseManager);
