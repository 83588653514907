import axios from 'axios';

export const fetchEmployeeLeavesAPI = (cid,eid) => {
    return axios.get(`/api/v1/get-employee-leaves`, { 
        params: {
            cid:cid,
            eid:eid
        }
    })
    .then(function (response) {
      if(response.data){
        return {success: true, data:response.data }
      }
      })
      .catch(function (error) {
        return {success: false, };
      });
}

export const fetchLeaveTypesAPI = (cid) => {
    return axios.get(`/api/v1/leave-types`, { 
        params: {
            cid:cid
        }
    })
    .then(function (response) {
      if(response.data){
        return {success: true, data:response.data }
      }
      })
      .catch(function (error) {
        return {success: false, };
      });
}

export const fetchActiveLeaveTypesAPI = (cid) => {
    return axios.get(`/api/v1/active-leave-types`, { 
        params: {
            cid:cid
        }
    })
    .then(function (response) {
      if(response.data){
        return {success: true, data:response.data }
      }
      })
      .catch(function (error) {
        return {success: false, };
      });
}

export const getNewLeavesAPI = (cid,eid) => {
    return axios.get(`/api/v1/pending-approval-leaves`, { 
        params: {
            cid:cid,
            eid:eid
        }
    })
    .then(function (response) {
      if(response.data){
        return {success: true, data:response.data }
      }
      })
      .catch(function (error) {
        return {success: false, };
      });
}

export const addUpdateLeave = (leave) => {
    return axios.post(`/api/v1/add-update-employee-leave`, leave)
      .then(function (response) {
        if (response.status === 200) {
          return { success: true, data: response.data };
        }
      })
      .catch(function (error) {
        return { success: false };
      });
}

export const addUpdateLeaveType = (leaveType) => {
    return axios.post(`/api/v1/add-update-leave-type`, leaveType)
      .then(function (response) {
        if (response.status === 200) {
          return { success: true, data: response.data };
        }
      })
      .catch(function (error) {
        return { success: false };
      });
}

export const acceptrejectLeaveApplication = (employeeLeavesDTO) => {
    return axios.post(`/api/v1/accept-reject-employee-leave`, employeeLeavesDTO)
      .then(function (response) {
        if (response.status === 200) {
          return { success: true, data: response.data };
        }
      })
      .catch(function (error) {
        return { success: false };
      });
}

export const checkLeaveExists = (cid, eid, endDate, startDate) => {
    return axios.get(`/api/v1/employee-leaves-exists`, {
      params: { cid: cid, eid, endDate, startDate }
    })
    .then(function (response) {
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
  }

  export const getLeavesHistoryAPI = (cid,eid) => {
    return axios.get(`/api/v1/leaves-history`, { 
        params: {
            cid:cid,
            eid:eid
        }
    })
    .then(function (response) {
      if(response.data){
        return {success: true, data:response.data }
      }
      })
      .catch(function (error) {
        return {success: false, };
      });
}

export const getAllEmployeesLeave = (cid,eid, status,startDate, endDate) => {
  return axios.get(`/api/v1/get-all-employees-leave`, { 
      params: {
          cid:cid,
          eidFilter:eid,
          statusFilter:status,
          startDate:startDate, 
          endDate:endDate,
          month:1
      }
  })
  .then(function (response) {
    if(response.data){
      return {success: true, data:response.data }
    }else{
     return {success: false, data:[] };
    }
    })
    .catch(function (error) {
      return {success: false,data:[] };
    });
}
