import { FETCH_PROJECT_ALL_EMPLOYEES, DELETE_PROJECT_EMPLOYEE, ADD_UPDATE_PROJECT_EMPLOYEE, FETCH_INITIAL_PROJECT_EMPLOYEES } from "../actions/projectEmployeesAction";
const projectEmployeesReducer = function (state = [], action) {
    let newState;
    let index;
    switch (action.type) {

        case FETCH_INITIAL_PROJECT_EMPLOYEES:
            return action.projectEmployees;

        case FETCH_PROJECT_ALL_EMPLOYEES:
            return action.projectEmployees;

        case ADD_UPDATE_PROJECT_EMPLOYEE:
            newState = [...action.projectEmployees];
            return newState;

        case DELETE_PROJECT_EMPLOYEE:
            newState = [...state];
            index = newState.findIndex(dataItem => dataItem.eid === action.payload.eid && dataItem.pid === action.payload.pid);
            if (index > -1) {
                newState.splice(index, 1);
            };
            return newState;


        default:
            return state;
    }
};

export default projectEmployeesReducer;