import moment from 'moment';
import React from "react";
import {
  Button,
  Col,
  Form, FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import { REQUIRD_MESSAGE } from "../../../../variables/constants";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dob: "",
      panNo: "",
      adharNo: "",
      ctc: "",
      dobState: "",
      reportingManagerState: "",
      departmentState: "",
      employmentTypeState: "",
      designationIdState: "",
      hraState: "",
      otherAllowanceState: "",
      daState: "",
      taState: "",
      probationPeriodState: "",
      roleIdState: "",
      designationIdState: "",
      baseLocationState: "",
      hourlyRateState: "",
      vendorIdState: "",
      currencyIdState: "",
      employee: {}

    };
  }
  UNSAFE_componentWillMount() {
    this.setState({
      employee: {
        ...this.state.employee,
        ...this.props.employee
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      employee: {
        ...this.props.employee,
        ...nextProps.employee
      }
    });
  }

  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  handleSelectDate = (date) => {
    if (date > 0) {
      this.setState({ dobState: "has-success" });
    } else {
      this.setState({ dobState: "has-danger" });
    }
    this.setState({
      dob: moment(date).format('YYYY-MM-DD') === "" ? "has-danger" : "has-success",
      employee: {
        ...this.state.employee,
        dob: moment(date).format('YYYY-MM-DD')
      }

    });
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    let employee = { ...this.state.employee };
    if (type === "number") {
      employee[event.target.name] = parseInt(event.target.value);
    } else {
      employee[event.target.name] = event.target.value;
    }
    if (event.target.value.length > 0) {
      this.setState({ [stateName + "State"]: "has-success" });
    } else {
      this.setState({ [stateName + "State"]: "has-danger" });
    }
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ employee: employee });
        } else {
          this.setState({ employee: employee });
        }
        break;
      case "length":
        if (stateName === "employmentType" && event.target.value === "Contract") {
          if (this.verifyLength(event.target.value, stateNameEqualTo)) {
            this.setState({
              employee: employee
            }, () => {
              this.props.changeSteps();
            }
            );
          } else {
            this.setState({ employee: employee });
          }
        } else {
          if (this.verifyLength(event.target.value, stateNameEqualTo)) {
            this.setState({ employee: employee });
          } else {
            this.setState({ employee: employee });
          }
        }

        break;
      case "number":
        if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
          this.setState({ employee: employee });
        } else {
          this.setState({ employee: employee });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };
  onChangeNumber(e, stateName) {
    let employee = { ...this.state.employee };
    if (e.target.value.length > 0) {
      this.setState({ [stateName + "State"]: "has-success" });
    } else {
      this.setState({ [stateName + "State"]: "has-danger" });
    }
    employee[e.target.name] = e.target.value.replace(/\D/, '');
    this.setState({ employee: employee });
  }
  isValidated = () => {
    let { department, employmentType, probationPeriod, reportingManager, roleId, designationId,
      baseLocation, paymentType, hourlyRate, vendorId, currencyId } = this.state.employee;
    if (employmentType == "Probation") {
      if (department && department != 0 && baseLocation && baseLocation != 0 && reportingManager && reportingManager != 0 &&
        designationId && designationId != 0 && employmentType && employmentType != null &&
        designationId && designationId != 0 && roleId && roleId != 0 && paymentType && paymentType != null && probationPeriod && probationPeriod !== 0) {
        this.props.submitExtraInfo(this.state.employee);
        return true;
      }
      else {
        if (!department || department == "") {
          this.setState({ departmentState: "has-danger" });
        }
        if (!designationId || designationId == "") {
          this.setState({ designationIdState: "has-danger" });
        }
        if (!baseLocation || baseLocation == "") {
          this.setState({ baseLocationState: "has-danger" });
        }
        if (!employmentType || employmentType == null) {
          this.setState({ employmentTypeState: "has-danger" });
        }
        if (probationPeriod && probationPeriod !== 0) {
          this.setState({ probationPeriodState: "has-success" });
        } else {
          this.setState({ probationPeriodState: "has-danger" });
        }
        if (!reportingManager || reportingManager == "") {
          this.setState({ reportingManagerState: "has-danger" });
        }
        if (!roleId || roleId == "") {
          this.setState({ roleIdState: "has-danger" });
        }
        if (!designationId || designationId == "") {
          this.setState({ designationIdState: "has-danger" });
        }
        if (!paymentType || paymentType == null) {
          this.setState({ paymentTypeState: "has-danger" });
        }
        return false;
      }
    } else if (employmentType == "Contract") {
      if (department && department !== 0 && baseLocation && baseLocation !== 0 && reportingManager && reportingManager !== 0 &&
        designationId && designationId !== 0 && employmentType && employmentType !== null &&
        designationId && designationId !== 0 && roleId && roleId !== 0 && paymentType && paymentType !== null &&
        hourlyRate && hourlyRate !== 0 && vendorId && vendorId !== 0 && currencyId && currencyId !== 0
      ) {
        this.setState({
          hourlyRateState: "has-success",
          currencyIdState: "has-success",
          vendorIdState: "has-success"
        });
        this.props.submitExtraInfo(this.state.employee);
        return true;
      }
      else {
        if (!department || department == "") {
          this.setState({ departmentState: "has-danger" });
        }
        if (!designationId || designationId == "") {
          this.setState({ designationIdState: "has-danger" });
        }
        if (!baseLocation || baseLocation == "") {
          this.setState({ baseLocationState: "has-danger" });
        }
        if (!employmentType || employmentType == null) {
          this.setState({ employmentTypeState: "has-danger" });
        }
        if (!reportingManager || reportingManager == "") {
          this.setState({ reportingManagerState: "has-danger" });
        }
        if (!roleId || roleId == "") {
          this.setState({ roleIdState: "has-danger" });
        }
        if (!designationId || designationId == "") {
          this.setState({ designationIdState: "has-danger" });
        }
        if (!paymentType || paymentType == null) {
          this.setState({ paymentTypeState: "has-danger" });
        }
        if (hourlyRate && hourlyRate !== 0) {
          this.setState({ hourlyRateState: "has-success" });
        } else {
          this.setState({ hourlyRateState: "has-danger" });
        }
        if (vendorId && vendorId !== 0) {
          this.setState({ vendorIdState: "has-success" });
        } else {
          this.setState({ vendorIdState: "has-danger" });
        }
        if (currencyId && currencyId !== 0) {
          this.setState({ currencyIdState: "has-success" });
        } else {
          this.setState({ currencyIdState: "has-danger" });
        }
        return false;
      }
    } else {
      if (department && department != 0 && baseLocation && baseLocation != 0 && reportingManager && reportingManager != 0 &&
        designationId && designationId != 0 && employmentType && employmentType != null &&
        designationId && designationId != 0 && roleId && roleId != 0 && paymentType && paymentType != null) {
        this.props.submitExtraInfo(this.state.employee);
        return true;
      }
      else {
        if (!department || department == "") {
          this.setState({ departmentState: "has-danger" });
        }
        if (!designationId || designationId == "") {
          this.setState({ designationIdState: "has-danger" });
        }
        if (!baseLocation || baseLocation == "") {
          this.setState({ baseLocationState: "has-danger" });
        }
        if (!employmentType || employmentType == null) {
          this.setState({ employmentTypeState: "has-danger" });
        }
        if (!reportingManager || reportingManager == "") {
          this.setState({ reportingManagerState: "has-danger" });
        }
        if (!roleId || roleId == "") {
          this.setState({ roleIdState: "has-danger" });
        }
        if (!designationId || designationId == "") {
          this.setState({ designationIdState: "has-danger" });
        }
        if (!paymentType || paymentType == null) {
          this.setState({ paymentTypeState: "has-danger" });
        }
        return false;
      }
    }

  };

  handleCancel = () => {
    let passProps = {
      pathname: 'employeemaster',
    }
    this.props.history.push(passProps);
  }

  render() {
    let currency = null;
    if (this.state.employee && this.state.employee.currencyId && this.state.employee.currencyId !== null || "") {
      currency = this.props.currencies.find(o => o.id === this.state.employee.currencyId);
    }
    return (
      <><div className="content">
        <Col className="mr-auto ml-auto" md="12">
          <Form className="custom-form custom-employee-form" id="LoginValidation">
            <Row className="justify-content-center">
              <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <Label>Reporting Manager *</Label>
                <FormGroup className={`has-label ${this.state.reportingManagerState}`}>
                  <Input
                    name="reportingManager"
                    placeholder="Reporting Manager..."
                    type="select"
                    defaultValue={!this.state.employee.reportingManager || this.state.employee.reportingManager == 0 ? "" : this.state.employee.reportingManager}
                    value={!this.state.employee.reportingManager || this.state.employee.reportingManager == 0 ? "" : this.state.employee.reportingManager}
                    onChange={e => this.change(e, "reportingManager", "length")}
                    onFocus={e => this.setState({ reportingManagerFocus: true })}
                    onBlur={e => this.setState({ reportingManagerFocus: false })}
                  ><option value="" disabled>Select reporting manager</option>
                    {this.props.managers.map((manager) => {
                      return (
                        <option value={manager.eid}>{manager.fname + " " + manager.lname}</option>
                      )
                    })
                    }
                  </Input>
                  {this.state.reportingManagerState === "has-danger" ? (
                    <label className="error">{REQUIRD_MESSAGE}</label>
                  ) : null}
                </FormGroup>
              </Col>
              <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <Label>Probation Period </Label>
                <FormGroup className={`has-label ${this.state.probationPeriodState}`}>
                  <Input
                    name="probationPeriod"
                    placeholder="Probation Period..."
                    type="select"
                    defaultValue={!this.state.employee.probationPeriod || this.state.employee.probationPeriod == 0 ? "" : this.state.employee.probationPeriod}
                    value={!this.state.employee.probationPeriod || this.state.employee.probationPeriod == 0 ? "" : this.state.employee.probationPeriod}
                    onChange={e => this.change(e, "probationPeriod", "number")}
                    onFocus={e => this.setState({ probationPeriodFocus: true })}
                    onBlur={e => this.setState({ probationPeriodFocus: false })}
                  ><option value="" disabled>Select probation period</option>
                    <option value={30}>30 Days</option>
                    <option value={60}>60 Days</option>
                    <option value={90}>90 Days</option>
                  </Input>
                  {this.state.probationPeriodState === "has-danger" ? (
                    <label className="error">{REQUIRD_MESSAGE}</label>
                  ) : null}
                </FormGroup>
              </Col>
              <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <Label>Payment Type *</Label>
                <FormGroup className={`has-label ${this.state.paymentTypeState}`}>
                  <Input
                    name="paymentType"
                    placeholder="Payment Type..."
                    type="select"
                    defaultValue={this.state.employee.paymentType == null ? "" : this.state.employee.paymentType}
                    value={this.state.employee.paymentType == null ? "" : this.state.employee.paymentType}
                    onChange={e => this.change(e, "paymentType", "length")}
                    onFocus={e => this.setState({ paymentTypeFocus: true })}
                    onBlur={e => this.setState({ paymentTypeFocus: false })}
                  ><option value="" disabled>Select payment type</option>
                    <option value="BankTransfer">Bank Transfer</option>
                    <option value="Cash">Cash</option>
                    <option value="Cheque">Cheque</option>
                    <option value="DemandDraft">DD</option>
                  </Input>
                  {this.state.paymentTypeState === "has-danger" ? (
                    <label className="error">{REQUIRD_MESSAGE}</label>
                  ) : null}
                </FormGroup>
              </Col>
              <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <Label>Role *</Label>
                <FormGroup className={`has-label ${this.state.roleIdState}`}>
                  <Input
                    name="roleId"
                    placeholder="Role..."
                    type="select"
                    defaultValue={!this.state.employee.roleId || this.state.employee.roleId == 0 ? "" : this.state.employee.roleId}
                    value={!this.state.employee.roleId || this.state.employee.roleId == 0 ? "" : this.state.employee.roleId}
                    onChange={e => this.change(e, "roleId", "number")}
                    onFocus={e => this.setState({ roleIdFocus: true })}
                    onBlur={e => this.setState({ roleIdFocus: false })}
                  ><option value="" disabled>Select role</option>
                    {this.props.roles.map((role) => {
                      return (
                        <option value={role.id}>{role.role}</option>
                      )
                    })
                    }
                  </Input>
                  {this.state.roleIdState === "has-danger" ? (
                    <label className="error">{REQUIRD_MESSAGE}</label>
                  ) : null}
                </FormGroup>
              </Col>
              <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <Label>Designation *</Label>
                <FormGroup className={`has-label ${this.state.designationIdState}`}>
                  <Input
                    name="designationId"
                    placeholder="Designation..."
                    type="select"
                    defaultValue={!this.state.employee.designationId || this.state.employee.designationId == 0 ? "" : this.state.employee.designationId}
                    value={!this.state.employee.designationId || this.state.employee.designationId == 0 ? "" : this.state.employee.designationId}
                    onChange={e => this.change(e, "designationId", "number")}
                    onFocus={e => this.setState({ designationIdFocus: true })}
                    onBlur={e => this.setState({ designationIdFocus: false })}
                  ><option value="" disabled>Select designation</option>
                    {this.props.designations.map((designation) => {
                      return (
                        <option value={designation.id}>{designation.designation}</option>
                      )
                    })
                    }
                  </Input>
                  {this.state.designationIdState === "has-danger" ? (
                    <label className="error">{REQUIRD_MESSAGE}</label>
                  ) : null}
                </FormGroup>
              </Col>
              <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <Label>Department *</Label>
                <FormGroup className={`has-label ${this.state.departmentState}`}>
                  <Input
                    name="department"
                    placeholder="Department..."
                    type="select"
                    defaultValue={!this.state.employee.department || this.state.employee.department == 0 ? "" : this.state.employee.department}
                    value={!this.state.employee.department || this.state.employee.department == 0 ? "" : this.state.employee.department}
                    onChange={e => this.change(e, "department", "number")}
                    onFocus={e => this.setState({ departmentFocus: true })}
                    onBlur={e => this.setState({ departmentFocus: false })}
                  ><option value="" disabled>Select department</option>
                    {this.props.departments.map((department) => {
                      return (
                        <option value={department.id}>{department.department}</option>
                      )
                    })
                    }
                  </Input>
                  {this.state.departmentState === "has-danger" ? (
                    <label className="error">{REQUIRD_MESSAGE}</label>
                  ) : null}
                </FormGroup>
              </Col>
              <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <Label>Employment Type *</Label>
                <FormGroup className={`has-label ${this.state.employmentTypeState}`}>
                  <Input
                    name="employmentType"
                    placeholder="Employment Type..."
                    type="select"
                    defaultValue={this.state.employee.employmentType == null ? "" : this.state.employee.employmentType}
                    value={this.state.employee.employmentType == null ? "" : this.state.employee.employmentType}
                    onChange={e => this.change(e, "employmentType", "length")}
                    onFocus={e => this.setState({ genderFocus: true })}
                    onBlur={e => this.setState({ genderFocus: false })}
                  ><option value="" disabled>Select employee type</option>
                    <option value="Probation">Probation</option>
                    <option value="Contract">Contract</option>
                    <option value="Permanent">Permanent</option>
                  </Input>
                  {this.state.employmentTypeState === "has-danger" ? (
                    <label className="error">{REQUIRD_MESSAGE}</label>
                  ) : null}
                </FormGroup>
              </Col>
              <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <Label>Base Location *</Label>
                <FormGroup className={`has-label ${this.state.baseLocationState}`}>
                  <Input
                    name="baseLocation"
                    placeholder="Base Location..."
                    type="select"
                    defaultValue={!this.state.employee.baseLocation || this.state.employee.baseLocation == null ? "" : this.state.employee.baseLocation}
                    value={!this.state.employee.baseLocation || this.state.employee.baseLocation == null ? "" : this.state.employee.baseLocation}
                    onChange={e => this.change(e, "baseLocation", "number")}
                    onFocus={e => this.setState({ baseLocationFocus: true })}
                    onBlur={e => this.setState({ baseLocationFocus: false })}
                  ><option value="" disabled>Select base location</option>
                    {this.props.companyLocations.map((location) => {
                      return (
                        <option value={location.id}>{location.branchLocation}</option>
                      )
                    })
                    }
                  </Input>
                  {this.state.baseLocationState === "has-danger" ? (
                    <label className="error">{REQUIRD_MESSAGE}</label>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            {this.state.employee && this.state.employee.employmentType === "Contract" ?
              <Row>
                <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                  <Label>Currency *</Label>
                  <FormGroup className={`has-label ${this.state.currencyIdState}`}>
                    <Input
                      name="currencyId"
                      placeholder="Currency..."
                      type="select"
                      defaultValue={!this.state.employee.currencyId || this.state.employee.currencyId == null ? "" : this.state.employee.currencyId}
                      value={!this.state.employee.currencyId || this.state.employee.currencyId == null ? "" : this.state.employee.currencyId}
                      onChange={e => this.change(e, "currencyId", "number")}
                      onFocus={e => this.setState({ currencyIdFocus: true })}
                      onBlur={e => this.setState({ currencyIdFocus: false })}
                    ><option value="" disabled>Select currency</option>
                      {this.props.currencies.map((currency) => {
                        return (
                          <option value={currency.id}>{currency.currency}</option>
                        )
                      })
                      }
                    </Input>
                    {this.state.currencyIdState === "has-danger" ? (
                      <label className="error">{REQUIRD_MESSAGE}</label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                  <Label>Vendor *</Label>
                  <FormGroup className={`has-label ${this.state.vendorIdState}`}>
                    <Input
                      name="vendorId"
                      placeholder="Vendor..."
                      type="select"
                      defaultValue={!this.state.employee.vendorId || this.state.employee.vendorId == null ? "" : this.state.employee.vendorId}
                      value={!this.state.employee.vendorId || this.state.employee.vendorId == null ? "" : this.state.employee.vendorId}
                      onChange={e => this.change(e, "vendorId", "number")}
                      onFocus={e => this.setState({ vendorIdFocus: true })}
                      onBlur={e => this.setState({ vendorIdFocus: false })}
                    ><option value="" disabled>Select vendor</option>
                      {this.props.employeeVendors.map((vendor) => {
                        return (
                          <option value={vendor.id}>{vendor.vendorName}</option>
                        )
                      })
                      }
                    </Input>
                    {this.state.vendorIdState === "has-danger" ? (
                      <label className="error">{REQUIRD_MESSAGE}</label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                  <Label>Hourly rate {currency && currency !== null && currency.code ? currency.code : null}*</Label>
                  <FormGroup className={`has-label ${this.state.hourlyRateState}`}>
                    <Input
                      name="hourlyRate"
                      placeholder="Hourly rate"
                      type="text"
                      maxLength="10"
                      value={this.state.employee.hourlyRate}
                      onChange={e => this.onChangeNumber(e, "hourlyRate", "number", 1)}
                      onFocus={e => this.setState({ hourlyRateFocus: true })}
                      onBlur={e => this.setState({ hourlyRateFocus: false })}
                    />
                    {this.state.hourlyRateState === "has-danger" ? (
                      <label className="error">{REQUIRD_MESSAGE}</label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col sm="6" style={{ paddingLeft: '50px', paddingRight: '50px' }}></Col>
              </Row>
              : <React.Fragment />
            }
          </Form>
        </Col>
      </div>
        <Button onClick={() => { this.handleCancel() }} className="btn-cancel" size="sm" color="info">Cancel</Button>
      </>
    );
  }
}

export default Wizard;