import React from "react";

const PasswordStrengthIndicator = ({ level, settings }) => {
    if (!settings.alwaysVisible && level < 0) {
        return null;
    }

    const indicators = [];

    for (let i = 0; i < 5; i++) {
        const color =
            i <= level
                ? settings.colorScheme.levels[level]
                : settings.colorScheme.noLevel;
        indicators.push(<div key={`indicator-${i}`} style={getIndicatorStyle(color, settings.height)} />);
    }

    return <div style={getWrapperStyle(settings.height)}>{indicators}</div>;
};

function getIndicatorStyle(color, height) {
    return {
        display: "inline-block",
        width: "20%",
        backgroundColor: color,
        height: height + "px",
        borderRadius: "2px"
    };
}
function getWrapperStyle(height) {
    return {
        lineHeight: height + "px"
    };
}

export default PasswordStrengthIndicator;