/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  UncontrolledTooltip
} from "reactstrap";
import { signoutUser } from "../../redux/actions/authActions";
import { getAllNotifications } from "../../redux/actions/notificationAction";
import { getAllEmployees } from "../../redux/actions/employeeAction";
import { readNotification } from "../../services/notification-service";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
    };
  }
  UNSAFE_componentWillMount() {
    this.props.getAllNotifications(this.props.cid, this.props.eid);
    this.props.getAllEmployees(this.props.cid);
    this.setState({
      userImage:this.props.userImage == "" || null ? null : this.props.userImage
    })

  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };
  handleNotification = async (notification) => {
    let notifications = [];
    notifications.push(notification);
    const result = await readNotification(notifications);
    if (result) {
      this.props.getAllNotifications(this.props.cid, this.props.eid);
    }
  }

  render() {
    let employee =  this.props.employees.find(o => o.eid === this.props.eid);
    const result = this.props.notifications.filter(notification => notification.notificationStatus === "Unread");
    return (
      <>
        <Navbar
          className={classNames("navbar-absolute", {
            [this.state.color]:
              this.props.location.pathname.indexOf("full-screen-map") === -1
          })}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize d-inline">
                <Button
                  className="minimize-sidebar btn-just-icon"
                  color="link"
                  id="tooltip209599"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                  <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="tooltip209599"
                  placement="right"
                >
                  Sidebar toggle
                </UncontrolledTooltip>
              </div>
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand>
                {this.props.brandText}
              </NavbarBrand>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navigation"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>
                {/* <InputGroup className="search-bar" tag="li">
                  <Button
                    color="link"
                    data-target="#searchModal"
                    data-toggle="modal"
                    id="search-button"
                    onClick={this.toggleModalSearch}
                  >
                    <i className="tim-icons icon-zoom-split" />
                    <span className="d-lg-none d-md-block">Search</span>
                  </Button>
                </InputGroup> */}
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                  >
                    {this.props.notifications.length ?
                      <div className="notification d-none d-lg-block d-xl-block" style={{ height: 12, width: 12 }}>
                        <p style={{ fontSize: 'xx-small', marginTop: '-1px' }}>{result.length}</p>
                      </div> :
                      <React.Fragment />
                    }
                    <i className="tim-icons icon-sound-wave" />
                    <p className="d-lg-none">Notifications</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul" className="notification-dropdown">
                    {this.props.notifications.map((notify, index) => {
                      return (<NavLink tag="li" key={index} style={{ backgroundColor: notify.notificationStatus == "Unread" ? '#f1f1f1' : null }}>
                        <DropdownItem className="nav-item" onClick={() => this.handleNotification(notify)}>
                          {notify.notification}
                        </DropdownItem>
                      </NavLink>
                      )
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    onClick={e => e.preventDefault()}
                  >{this.state.userImage == null ? 
                    <div className="desktop-icon" style={{padding:'6px'}}>
                    <i className="tim-icons icon-single-02" id="tooltip209411"/>
                    </div>
                    :
                    <div className="photo top-bar-photo" >
                    <img alt="..." className="top-bar-image" id="tooltip209411" src={JSON.parse(this.state.userImage)} />
                  </div>
                  }                 
                    <b className="caret d-none d-lg-block d-xl-block" />
                    <p className="d-lg-none">Log out</p>
                  </DropdownToggle>
                  <UncontrolledTooltip
                  delay={0}
                  color=""
                  target="tooltip209411"
                  placement="bottom"
                >
                  {employee && employee.fname ? employee.fname+" "+employee.lname : "No user"}
                </UncontrolledTooltip>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    {/* <NavLink tag="li">
                      <DropdownItem className="nav-item">Profile</DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">Settings</DropdownItem>
                    </NavLink> */}
                    <NavLink tag="li">
                      <DropdownItem className="nav-item" onClick={() => this.props.handleProfile()}>Profile</DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item" onClick={() => this.props.openChangePassword()}>Change Password</DropdownItem>
                    </NavLink>
                    <DropdownItem divider tag="li" />
                    <NavLink tag="li">
                      <DropdownItem className="nav-item" onClick={() => this.props.signoutUser()}>Log out</DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Modal
          modalClassName="modal-search"
          isOpen={this.state.modalSearch}
          toggle={this.toggleModalSearch}
        >
          <div className="modal-header">
            <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  signoutUser: bindActionCreators(signoutUser, dispatch),
  getAllNotifications: bindActionCreators(getAllNotifications, dispatch),
  getAllEmployees:bindActionCreators(getAllEmployees, dispatch)
});

export const mapStateToProps = ({ notifications,auth, employees }) => ({
  notifications: notifications,
  cid:auth.user.cid,
  eid:auth.user.eid,
  userImage:auth.user.userImage,
  employees:employees
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
