import { GET_LEAVES } from '../actions/leaveManagementActions';


const INITIAL_STATE = []

const leaveReducer = (state = INITIAL_STATE, action) => {
    let newState;
    switch (action.type) {
        case GET_LEAVES:
            newState = action.leaves;
            return newState;

        default: return state;
  }
};

export default leaveReducer;
