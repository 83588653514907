import { getAnnouncementsFromDb } from "services/dashboard-service.js";

export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";

export const getAnnouncments = (cid, date) => {
    return async (dispatch) => {
      const response = await getAnnouncementsFromDb(cid, date);
      if (response.data) {
        let result = response.data;
        dispatch({
          type: GET_ANNOUNCEMENTS,
          announcements: result
        });
        return { success: true, result };
      }
      else {
        return { success: false };
      }
    }
  }