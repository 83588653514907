import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
    UncontrolledTooltip
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CountryModal from "./CountryModal";
import { getAllCountries } from "../../../redux/actions/countryAction";
import Pagination from "../../../components/common/pagination";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";

class CountryMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenCountryModal: false,
            isDataLoading: false,
            formType: 'Add',
            country: {
                country: '',
                id: '',
                countryCode: ''
            },
            data: [
                {
                    country: 'India',
                    id: 1,
                },
                {
                    country: 'America',
                    id: 2,
                },
                {
                    country: 'Shri Lanka',
                    id: 3,
                }
            ]
        };
    }

    async UNSAFE_componentWillMount() {
        this.setState({
            isDataLoading: true
        })
        const response = await this.props.getAllCountries();
        if (response) {
            this.setState({
                isDataLoading: false
            })
        }
    }


    showCountryModal = (country) => {
        this.setState({
            formType: 'Update',
            isOpenCountryModal: true,
            country: country
        });
    }

    toggleCountryModal = () => {
        this.setState({
            formType: 'Add',
            isOpenCountryModal: !this.state.isOpenCountryModal,
        });
    }

    onClose = () => {

        this.setState({
            isOpenCountryModal: false,
        });
    }

    handleSubmit = async (country, formType) => {

        if (formType === "Add") {
            let options = {};
            options = {
                place: "tr",
                message: 'Add country api not integrated.',
                type: "warning",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 3
            };
            if(this.refs.notificationAlert){
                this.refs.notificationAlert.notificationAlert(options);
            }

        } else {
            let options = {};
            options = {
                place: "tr",
                message: 'Update country api not integrated.',
                type: "warning",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 3
            };
            if(this.refs.notificationAlert){
                this.refs.notificationAlert.notificationAlert(options);
            }

        }
        this.setState({
            formType: 'Add',
            isOpenCountryModal: false,
            country: {},
        });
    }


    render() {

        const data = this.props.countries.map((prop, key) => {

            return {
                id: prop.id,
                countryName: prop.country,
                test: (<div></div>),
                actions: (
                    // we've added some custom button actions
                    <div className="actions-right">
                        <Button
                            id="tooltip209774"
                            onClick={() => {
                                let obj = this.props.countries.find(o => o.id === prop.id);
                                let { id,
                                    country } = obj;
                                let countryItem = { id, country }
                                this.showCountryModal(countryItem)
                            }}
                            // color="warning"
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-pencil" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209774"
                            placement="left"
                        >
                            Edit
                        </UncontrolledTooltip>
                    </div>
                )
            };
        })
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    {this.state.isOpenCountryModal && <CountryModal modal={this.state.isOpenCountryModal} toggle={this.toggleCountryModal} onSubmit={this.handleSubmit} formType={this.state.formType} country={this.state.country} close={this.onClose} />}
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CardTitle tag="h2">Country Master Details</CardTitle>
                                    <div style={{ display: 'flex' }}>
                                        <Button onClick={this.toggleCountryModal} size="sm" color="info">New</Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={data}
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "",
                                                accessor: "test",
                                                width: 50,
                                            },
                                            {
                                                Header: "Id",
                                                accessor: "id",
                                                width: 120,
                                            },
                                            {
                                                Header: "Country Name",
                                                accessor: "countryName",
                                                width: 380,
                                            },
                                            {
                                                Header: "Country Code",
                                                accessor: "countryCode",
                                                width: 380,
                                            },
                                            {
                                                Header: "",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false
                                            },
                                        ]}
                                        LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                                        NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                                        pageSize={this.props.countries.length <= 10 ? this.props.countries.length : 10}
                                        className="-striped -highlight project-table expense-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export const mapDispatchToProps = dispatch => ({
    getAllCountries: bindActionCreators(getAllCountries, dispatch)
});


export const mapStateToProps = ({ countries }) => ({
    countries: countries
});
export default connect(mapStateToProps, mapDispatchToProps)(CountryMaster);
