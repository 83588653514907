import { FETCH_ALL_EMPLOYEES_BY_ADMIN } from '../actions/adminEmployeesActions';
import { FETCH_EMPLOYEE_EXTRA_INFO } from '../actions/employeeExtraInfoAction';

const adminEmployeesReducer = function (state = [], action) {

    let newState;
    let index;
    let id;
    let eid;

    switch (action.type) {
        case FETCH_ALL_EMPLOYEES_BY_ADMIN:
            return action.employees;

        case FETCH_EMPLOYEE_EXTRA_INFO:
            newState = [...state];
            index = newState.findIndex(emp => emp.eid === action.payload.id);
            if (index > -1) {
                let emp = { ...newState[index] }
                emp.extraInfo = { ...action.payload.extraInfo }
                newState[index] = { ...emp }
            }
            return newState;

        default:
            return state;
    }
};

export default adminEmployeesReducer;
