import { getAllEmployeesByManagerAPI } from "../../services/managers-service";

export const FETCH_ALL_MANAGER_EMPLOYEES = 'FETCH_ALL_MANAGER_EMPLOYEES';

export const getAllEmployeesByManager = (eid) => {
    return async (dispatch, getState) => {
        var { auth } = getState();
        var cid = auth.user.cid;

        const response = await getAllEmployeesByManagerAPI(eid, cid);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_MANAGER_EMPLOYEES,
                employees: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}


