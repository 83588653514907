import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import LeaveTypeModal from "./leaveTypeModal";
import NotificationAlert from "react-notification-alert";
import { fetchLeaveTypes } from "../../../redux/actions/leaveTypeActions";
import { addUpdateLeaveType } from "../../../services/leaves-service";
import Pagination from "../../../components/common/pagination";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
import { AppContext } from '../../../hoc/AppContext';
class LeaveTypeMaster extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      leavetype: {},
      formType: 'add',
      data: [],
      isDataLoading: false

    };
  }

  handeOpenModal = () => {
    this.setState({
      isOpen: true,
      formType: 'add',
    })
  };

  toggle = () => {
    //
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }), () => {
      //
    });
  };



  //notificationAlert = React.createRef();

  notify = (place, color, message) => {
    // var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    };
  };


  showNotifications = (color, message) => {
    this.notify("tr", color, message);
  }

  showLeaveTypeModal = (leavetype) => {

    this.setState({
      formType: 'update',
      isOpen: true,
      leavetype: { ...leavetype }
    });
  }

  addUpdateLeaveType = async (leavetype, formtype) => {
    this.toggle();
    let message = '';
    let message2 = '';
    //
    let result = await addUpdateLeaveType(leavetype);
    if (formtype === 'add') {
      message = 'Leave type has been Added successfully.';
      message2 = 'Failed to add leave type!'
    } else {
      message = 'Leave type has been updated successfully.'
      message2 = 'Failed to update leave type!'
    }

    if (result.success) {
      this.showNotifications(2, message);
      this.getLeaveTypes();

    } else {
      this.showNotifications(3, message2);
      //this.toggle();
    }
  }

  getLeaveTypes = async () => {
    this.setState({
      isDataLoading: true
    })
    await this.props.fetchLeaveTypes(this.props.cid);
    let data = this.props.leaveTypes.map((prop, key) => {
      let isActive = true;
      prop.isActive === "Y" ? isActive = 'active' : isActive = 'inactive'
      return {
        id: key + 1,
        ltId: prop.id,
        cid: prop.cid,
        leavetype: prop.type,
        isActive: isActive,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              id="tooltip209813"
              onClick={() => {
                let obj = this.props.leaveTypes.find(o => o.id === prop.id);
                this.showLeaveTypeModal(obj)
              }}
              // color="warning"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              <i className="tim-icons icon-pencil" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="tooltip209813"
              placement="left"
            >
              Edit
            </UncontrolledTooltip>
          </div>
        ),
        test: (
          <div className="actions-right">
            <Button
              onClick={() => { }}
              size="md"
              style={{ cursor: 'context-menu' }}
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              {prop.isActive === 'Y' ? <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span> :
                <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
              }
            </Button>{" "}
          </div>
        )
      };
    })

    this.setState({
      data: data,
      isDataLoading: false
    })
  }

  async UNSAFE_componentWillMount() {
    await this.getLeaveTypes(this.props.cid);

  }

  render() {
    const { isOpen } = this.state;
    const { config } = this.context;

    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        {isOpen && <LeaveTypeModal cid={this.props.cid} leaveTypes={this.props.leaveTypes} leavetype={this.state.leavetype} addUpdateLeaveType={this.addUpdateLeaveType} toggle={this.toggle} isOpen={isOpen} formtype={this.state.formType} showNotifications={this.showNotifications} />}
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CardTitle tag="h2">Leave Type Details </CardTitle>
                  <div style={{ display: 'flex' }}>
                    <Button color="info" size="sm" onClick={this.handeOpenModal}>New</Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    PaginationComponent={Pagination}
                    data={this.state.data}
                    resizable={false}
                    columns={[
                      {
                        Header: "",
                        accessor: "test",
                        sortable: false,
                        filterable: false,
                        width: 80
                      }, {
                        Header: "Id",
                        accessor: "id",
                        sortable: false,
                        filterable: false,
                        width: 110
                      },
                      {
                        Header: "Leave Type",
                        accessor: "leavetype",
                        width: 350,
                      },
                      {
                        Header: "isActive",
                        accessor: "isActive",
                        width: 350,
                      },
                      {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                    NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                    pageSize={this.props.leaveTypes.length <= config.paginationSize ? this.props.leaveTypes.length : config.paginationSize}
                    className="-striped -highlight project-table leave-table expense-table"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {

  return {
    leaveTypes: state.leaveTypes,
    cid: state.auth.user.cid,
  }

};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLeaveTypes: bindActionCreators(fetchLeaveTypes, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaveTypeMaster);
