import axios from 'axios';

export const getAllCompaniesAPI = (cid) => {
  return axios
    .get(`/api/v1/companies`)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const addUpdateCompanyAPI = (company) => {
  return axios
    .post(`/api/v1/add-update-company`, company)
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {

      return { success: false };
    });
}

export const deleteCompanyAPI = (id) => {
  return axios
    .get(`/api/v1/delete-company`, {
      params: {
        id: id
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getAllInitialCompanyConfigsAPI = (cid) => {
  return axios
    .get(`/api/v1/company-config`, {
      params: {
        cid: cid
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getCompanyContactsAPI = (cid) => {
  return axios
    .get(`/api/v1/company-contacts`, {
      params: {
        cid: cid
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const getCompanyLocationsAPI = (cid) => {
  return axios
    .get(`/api/v1/company-locations`, {
      params: {
        cid: cid
      }
    })
    .then(function (response) {
      if (response.data) {
        return { success: true, data: response.data };
      } else {
        return { success: false };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const addUpdateCompanyLocations = (companyLocationsDTO) => {
  return axios.post(`/api/v1/add-update-company-location`, companyLocationsDTO)
    .then(function (response) {
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const addUpdateCompanyContacts = (contact) => {
  let companyContactDTO = [];
  companyContactDTO.push(contact);

  return axios.post(`/api/v1/add-update-company-contacts`, companyContactDTO)
    .then(function (response) {
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}

export const addUpdateCompanyConfig = (companyConfigDTO) => {
  return axios.post(`/api/v1/add-update-company-config`, companyConfigDTO)
    .then(function (response) {
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
    })
    .catch(function (error) {
      return { success: false };
    });
}