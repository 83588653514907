import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import VendorModal from "./vendorModal";
import NotificationAlert from "react-notification-alert";
import { getAllVendors } from "../../../redux/actions/vendorActions";
import { getAllCountries } from "../../../redux/actions/countryAction";
import { addUpdateVendor } from "../../../services/vendor-service";
import Pagination from "../../../components/common/pagination";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
import { AppContext } from '../../../hoc/AppContext';
class VendorMaster extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      vendor: {},
      formType: 'add',
      data: [],
      alert: null,
      isDataLoading: false

    };
  }

  handeOpenModal = () => {
    this.setState({
      isOpen: true,
      formType: 'add',
    })
  };

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }))
  };



  //notificationAlert = React.createRef();

  notify = (place, color, message) => {
    // var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  };


  showNotifications = (color, message) => {
    this.notify("tr", color, message);
  };

  successAlert = (status) => {
    let options = {};
    options = {
      place: "tr",
      message: 'Vendor has been ' + status + ' successfully.',
      type: "success",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 2
    };
    if (this.refs.notificationAlert) {
      this.refs.notificationAlert.notificationAlert(options);
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  showClientModal = (client) => {

    this.setState({
      formType: 'update',
      isOpen: true,
      vendor: { ...client }
    });
  }

  addUpdateVendorDetails = async (vendorDetails, formType) => {

    let status = '';
    let message = '';
    this.toggle();
    let result = await addUpdateVendor(vendorDetails);

    if (result.success) {
      //this.showNotifications(2, "Client has been Added successfully.");
      if (formType === 'add') {
        status = 'added';
        message = "Failed to add vendor!"
      } else {
        status = 'updated';
        message = "Failed to update vendor!"
      }
      this.successAlert(status);
      this.getClientDetails();

    } else {
      this.showNotifications(3, "Failed to perform the operation!");
    }
  }

  getClientDetails = async () => {
    this.setState({
      isDataLoading: true
    })
    await this.props.getAllVendors(this.props.cid);
    let data = this.props.vendors.map((prop, key) => {
      return {
        id: key + 1 + ".",
        vendorId: prop.id,
        cid: prop.cid,
        vendorName: prop.vendorName,
        address1: prop.address1,
        address2: prop.address2,
        isActive: (
          <div className="actions-right">
            <Button
              onClick={() => { }}
              size="md"
              style={{ cursor: 'context-menu' }}
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5
              })}
            >
              {prop.isActive === 'Y' ? <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span> :
                <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
              }
            </Button>{" "}
          </div>
        ),
        mobile: prop.contactNo,
        city: prop.city,
        statename: prop.state,
        country: prop.country,
        bankAccountNumber: prop.bankAccountNumber,
        paymentMethod: prop.paymentMethod,
        paymentTerm: prop.paymentTerm,
        chequeAddress: prop.billingAddress,
        test: (<div></div>),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              id="tooltip209881"
              onClick={() => {
                let obj = this.props.vendors.find(o => o.id === prop.id);
                this.showClientModal(prop)
              }}
              color="warning"
              size="sm"
              className={classNames("btn-icon btn-link btn-neutral like", {
                "btn-neutral": key < 5
              })}
            >
              <i className="tim-icons icon-pencil" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="tooltip209881"
              placement="left"
            >
              Edit
            </UncontrolledTooltip>
          </div>
        )
      };
    })

    this.setState({
      data: data,
      isDataLoading: false

    })
  }

  UNSAFE_componentWillMount() {
    this.getClientDetails();
    this.props.getAllCountries();

  }

  render() {

    const { isOpen } = this.state;
    const { config } = this.context;
    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        {isOpen && <VendorModal cid={this.props.cid} countries={this.props.countries} vendors={this.props.vendors} addUpdateVendorDetails={this.addUpdateVendorDetails} toggle={this.toggle} isOpen={isOpen} vendor={this.state.vendor} formtype={this.state.formType} showNotifications={this.showNotifications} />}
        <div className="content">
          {this.state.alert}
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CardTitle tag="h2">Vendor Master Details </CardTitle>
                  <div style={{ display: 'flex' }}>
                    <Button color="info" size="sm" onClick={this.handeOpenModal}>New</Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    PaginationComponent={Pagination}
                    data={this.state.data}
                    //filterable
                    // nextText={myCustomNextText}
                    resizable={true}
                    columns={[
                      {
                        Header: "",
                        accessor: "isActive",
                        sortable: false,
                        filterable: false,
                        className: "align-right",
                        width: 60,
                        resizable: false
                      },
                      {
                        Header: "No.",
                        accessor: "id",
                        sortable: false,
                        filterable: false,
                        className: "align-right",
                        width: 70,
                        resizable: false
                      },
                      {
                        Header: "Vendor Name",
                        accessor: "vendorName",
                        width: 220
                      },
                      {
                        Header: "Contact No",
                        accessor: "mobile",
                        width: 150,
                        resizable: false
                      },
                      {
                        Header: "Client Address",
                        accessor: "city",
                        width: 200
                      },
                      {
                        Header: "Payment Term",
                        accessor: "paymentTerm",
                        width: 120
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        className: "align-center",
                        resizable: false
                      },

                    ]}
                    LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                    NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                    pageSize={this.state.data.length <= config.paginationSize ? this.state.data.length : config.paginationSize}
                    className="-striped -highlight project-table leave-table expense-table"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {

  return {
    vendors: state.vendors,
    countries: state.countries,
    cid: state.auth.user.cid
  }

};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllVendors: bindActionCreators(getAllVendors, dispatch),
    getAllCountries: bindActionCreators(getAllCountries, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorMaster);
