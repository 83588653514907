import { getHolidaysByLocationAPI } from '../../services/holiday-service';

export const FETCH_ALL_HOLIDAYS_BY_LOCATION = 'FETCH_ALL_HOLIDAYS_BY_LOCATION';

export const getHolidaysByLocation = (cid,locationId) => {
    return async (dispatch) => {
        const response = await getHolidaysByLocationAPI(cid,locationId);
        if (response.data) {
            dispatch({
                type: FETCH_ALL_HOLIDAYS_BY_LOCATION,
                holidays: response.data
            });
            return { success: true };
        }
        else {
            return { success: false };
        }
    }
}