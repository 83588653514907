import React, { Component } from "react";
import classNames from "classnames";
import ReactTable from "react-table";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
    UncontrolledTooltip
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CompanyModal from "./CompanyModal";
import { getAllCompanies, addUpdateCompany, deleteCompany } from "../../../redux/actions/companyActions";
import Pagination from "../../../components/common/pagination";
import LoadingIndicator from "../../../components/common/LoadingIndicator";
import NoData from "../../../components/common/NoData";
import moment from "moment";
import { dateFormatList } from "variables/constants";

class CompanyMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenCountryModal: false,
            formType: 'Add',
            company: {
                id: 0,
                name: '',
                isActive: 'Y',
                dateRegistered: '',
                accountType: "New",
                currencyId: -1,
                domainurl: "",
                hostedurl: "",
                logoPath: "",
                slug: "",
                isDataLoading: false
            },
        };
    }

    async UNSAFE_componentWillMount() {
        this.setState({
            isDataLoading: true
        })
        const response = await this.props.getAllCompanies();
        if (response) {
            this.setState({
                isDataLoading: false
            })
        }
    }


    showCompanyDetails = (company) => {
        // this.setState({
        //     formType: 'Update',
        //     isOpenCountryModal: true,
        //     company: company
        // });
        let passProps = {
            pathname: 'add-update-company',
            state: {
                cid: company.id,
                formType: 'Update'
            }
        }
        this.props.history.push(passProps);
    }

    handeOpenModal = () => {

        let passProps = {
            pathname: 'add-update-company',
            state: {
                newCompany: this.state.company,
                formType: 'Add'
            }
        }
        this.props.history.push(passProps);
    };

    toggleCountryModal = () => {
        this.setState({
            formType: 'Add',
            isOpenCountryModal: !this.state.isOpenCountryModal,
        });
    }

    onClose = () => {

        this.setState({
            isOpenCountryModal: false,
        });
    }
    handleDelete = async (id) => {
        const response = await this.props.deleteCompany(id);
        if (response.success) {
            let options = {};
            options = {
                place: "tr",
                message: 'Company deleted successfully.',
                type: "success",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 3
            };
            if (this.refs.notificationAlert) {
                this.refs.notificationAlert.notificationAlert(options);
            }
            this.onClose();
        } else {
            let options = {};
            options = {
                place: "tr",
                message: 'Error occurred while deleting company.',
                type: "success",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 3
            };
            if (this.refs.notificationAlert) {
                this.refs.notificationAlert.notificationAlert(options);
            }
        }
    }

    handleSubmit = async (company, formType) => {
        if (formType === "Add") {
            const response = await this.props.addUpdateCompany(company);
            if (response.success) {
                let options = {};
                options = {
                    place: "tr",
                    message: 'Company added successfully.',
                    type: "success",
                    icon: "tim-icons icon-bell-55",
                    autoDismiss: 3
                };
                if (this.refs.notificationAlert) {
                    this.refs.notificationAlert.notificationAlert(options);
                }
                this.onClose();
            } else {
                let options = {};
                options = {
                    place: "tr",
                    message: 'Error occurred while adding company.',
                    type: "success",
                    icon: "tim-icons icon-bell-55",
                    autoDismiss: 3
                };
                if (this.refs.notificationAlert) {
                    this.refs.notificationAlert.notificationAlert(options);
                }
            }
        } else {
            const response = await this.props.addUpdateCompany(company);
            if (response.success) {
                let options = {};
                options = {
                    place: "tr",
                    message: 'Company updated successfully.',
                    type: "success",
                    icon: "tim-icons icon-bell-55",
                    autoDismiss: 3
                };
                if (this.refs.notificationAlert) {
                    this.refs.notificationAlert.notificationAlert(options);
                }
                this.onClose();
            } else {
                let options = {};
                options = {
                    place: "tr",
                    message: 'Error occurred while updating company.',
                    type: "success",
                    icon: "tim-icons icon-bell-55",
                    autoDismiss: 3
                };
                if (this.refs.notificationAlert) {
                    this.refs.notificationAlert.notificationAlert(options);
                }
            }

        }
        this.setState({
            formType: 'Add',
            isOpenCountryModal: false,
            company: {},
        });
    }


    render() {

        const data = this.props.companies.map((prop, key) => {

            return {
                srNo: key + 1,
                id: prop.id,
                name: prop.name,
                dateRegistered: moment(prop.dateRegistered).format(dateFormatList[2]),
                hostedurl: prop.hostedurl,
                domainurl: prop.domainurl,
                actions: (
                    // we've added some custom button actions
                    <div className="actions-right">
                        <Button
                            onClick={() => { }}
                            color="info"
                            size="md"
                            style={{ cursor: 'context-menu' }}
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            {prop.isActive === 'Y' ? <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block' }}></span> :
                                <span class="dot" style={{ height: '10px', width: '10px', backgroundColor: '#b3b3b3', borderRadius: '50%', display: 'inline-block' }}></span>
                            }
                        </Button>
                    </div>
                ),
                edit: (
                    // we've added some custom button actions
                    <div className="actions-right">
                        <Button
                            id="tooltip209772"
                            onClick={() => {
                                let obj = this.props.companies.find(o => o.id === prop.id);
                                let companyDetail = obj
                                this.showCompanyDetails(companyDetail)
                            }}
                            // color="warning"
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-pencil" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209772"
                            placement="left"
                        >
                            Edit
                        </UncontrolledTooltip>
                    </div>
                ),
                delete: (
                    <div className="">
                        <Button
                            id="tooltip209771"
                            onClick={() => this.handleDelete(prop.id)}
                            color="warning"
                            size="sm"
                            className={classNames("btn-icon btn-link like", {
                                "btn-neutral": key < 5
                            })}
                        >
                            <i className="tim-icons icon-trash-simple" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209771"
                            placement="left"
                        >
                            Delete
                        </UncontrolledTooltip>
                    </div>
                )
            };
        })
        // const myCustomNextText = () => {
        //     return
        // };
        return (
            <>
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                    {this.state.isOpenCountryModal && <CompanyModal modal={this.state.isOpenCountryModal} toggle={this.toggleCountryModal} onSubmit={this.handleSubmit} formType={this.state.formType} company={this.state.company} close={this.onClose} />}
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <CardTitle tag="h2">Company Master Details</CardTitle>
                                    <div style={{ display: 'flex' }}>
                                        <Button size="sm" onClick={e => {
                                            e.preventDefault();
                                            this.handeOpenModal();
                                        }} color="info">New</Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        data={data}
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "",
                                                accessor: "actions",
                                                width: 60,
                                                sortable: false,
                                                filterable: false
                                            },
                                            {
                                                Header: "Id",
                                                accessor: "srNo",
                                                width: 80
                                            },
                                            {
                                                Header: "Company Name",
                                                accessor: "name",
                                                width: 200
                                            },
                                            {
                                                Header: "Register Date",
                                                accessor: "dateRegistered",
                                                width: 140
                                            },
                                            {
                                                Header: "Domain URL",
                                                accessor: "domainurl",
                                                width: 230
                                            },
                                            {
                                                Header: "Hosted URL",
                                                accessor: "hostedurl",
                                                width: 230
                                            },
                                            {
                                                Header: "",
                                                accessor: "edit"
                                            }
                                        ]}
                                        LoadingComponent={() => (<LoadingIndicator loading={this.state.isDataLoading} />)}
                                        NoDataComponent={() => (<NoData loading={this.state.isDataLoading} />)}
                                        pageSize={this.props.companies.length <= 10 ? this.props.companies.length : 10}
                                        className="-striped -highlight project-table leave-table expense-table"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export const mapDispatchToProps = dispatch => ({
    getAllCompanies: bindActionCreators(getAllCompanies, dispatch),
    addUpdateCompany: bindActionCreators(addUpdateCompany, dispatch),
    deleteCompany: bindActionCreators(deleteCompany, dispatch)
});


export const mapStateToProps = ({ companies }) => ({
    companies: companies
});
export default connect(mapStateToProps, mapDispatchToProps)(CompanyMaster);
