import { FETCH_ALL_EMPLOYEES_WITH_ROLES } from "../actions/employeeRoleAction";
const employeeRoleReducer = function (state = [], action) {
    // let newState;
    let index;
    switch (action.type) {
        case FETCH_ALL_EMPLOYEES_WITH_ROLES:
            return action.employees;

        default:
            return state;
    }
};

export default employeeRoleReducer;