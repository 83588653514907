import moment from 'moment';
import React, { Component } from "react";
import { Button, Card, CardBody, CardHeader, FormGroup, Input, Table, CardFooter, Badge, UncontrolledTooltip } from 'reactstrap';
import _ from "lodash";
import LoadingIndicator from "../../../../components/common/LoadingIndicator";
import { getAllAllocationAPI } from "services/project-service";



class Resources extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'Add',
            showModal: false,
            selectedEmployee: null,
            projectEmployee: {
                id: 0,
                cid: this.props.cid,
                pid: 1,
                eid: 1,
                assignedBy: 1,
                assignDate: moment().format('YYYY-MM-DD'),
                resourceCost: 0,
                billingRate: 0,
                employeeAllocationPercent: 0,
                allocationPercent: 100,
                maxHoursBillable: 100,
                dateRelieved: moment().format('YYYY-MM-DD'),
                relievedBy: '',
            },
            employeeState: '',
            resources: [],
            isResourceLoading: true,
            isResourceAPISuccess: false


        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        var resources = JSON.parse(JSON.stringify(nextProps.resources));

        // let resc = _.cloneDeep(nextProps.resources);
        //

        // let isSameObject = _.isEqual(this.state.resources, nextProps.resources);

        // if (isSameObject === false) {
        //     this.setState({
        //         ...this.state,
        //         resources: [...resources]
        //     }, () => {
        //        
        //     });
        // }

        // if (this.state.isResourceLoading === true) {
        //     this.setState({
        //         ...this.state,
        //         isResourceLoading: nextProps.isResourceLoading
        //     }, () => {
        //        
        //        
        //     })
        // }

        // if (this.state.isResourceLoading === true) { }
        this.setState({
            ...this.state,
            isResourceLoading: nextProps.isResourceLoading,
            isResourceAPISuccess: nextProps.isResourceAPISuccess
        },
            () => {

                let isSameObject = _.isEqual(this.state.resources, nextProps.resources);
                // let isSameObject = _.isEqual(this.props.resources, nextProps.resources);
                if (isSameObject === false) {
                    this.setResources(resources)
                }
            }
        )
    }

    setResources = (resources) => {

        this.setState({
            ...this.state,
            resources: [...resources]
        });

    }

    handleSelect = async (e, index) => {
        switch (e.target.name) {
            case "eid":
                this.setState({ employeeState: "has-success" });
                break;
            default:
                break;
        }
        let eid = e.target.value;
        let resources = [...this.state.resources];
        let result = this.state.resources.filter(dataItem => dataItem.eid === e.target.value);
        if (result.length > 0) {
            let message = {
                message: "Please select unique resource!",
                type: "warning",
            };
            this.props.handleMessage(message);
        } else {
            eid = e.target.value;
            resources[index][e.target.name] = eid;
            let result = await this.getPercentAllocation(this.state.resources[index].cid, eid);
            resources[index]['employeeAllocationPercent'] = result;
            this.setState({
                ...this.state,
                resources: [...resources]
            });
        }
    }

    getPercentAllocation = async (cid, eid) => {
        let result = await getAllAllocationAPI(cid, eid);
        if (result.success) {
            if (!result.data)
                return 0;
            else
                return result.data;
        } else
            return 0;
    }
    checkPercentAllocationLimit = async (percent, index) => {

        let result = await getAllAllocationAPI(this.state.resources[index].cid, this.state.resources[index].eid);

        if (result.success) {
            if (!result.data)
                return false;
            else {
                let current = result.data;
                if (parseInt(percent) + parseInt(current) > 100) {
                    //
                    return true;

                } else {
                    //
                    return false;
                }
            }

        } else {
            //
            return false;
        }

    }

    handleSelectAllocationPercentage = async (e, index) => {
        let resources = [...this.state.resources];
        // let calculation = parseInt(resources[index]['employeeAllocationPercent']) + parseInt(e.target.value);
        var calculation = 0;
        var employeeAllocationPercent = parseInt(resources[index]['employeeAllocationPercent']);
        var allocationPercent = parseInt(resources[index]['allocationPercent']);
        var value = parseInt(e.target.value);

        if (Number.isNaN(employeeAllocationPercent)) {

            let result = await getAllAllocationAPI(this.state.resources[index].cid, this.state.resources[index].eid);

            if (result && result.data) {
                employeeAllocationPercent = parseInt(result.data);
            } else {
                // employeeAllocationPercent = allocationPercent;
            }
        }

        if (allocationPercent > value) {
            calculation = employeeAllocationPercent - value;
        } else {
            let diff = value - allocationPercent;
            calculation = employeeAllocationPercent + diff;
        }


        let finalEmployeeAllocationPercent = 0;
        if (allocationPercent < value) {
            finalEmployeeAllocationPercent = employeeAllocationPercent + (value - allocationPercent)
        } else {
            if (parseInt(employeeAllocationPercent) === 0) {
                finalEmployeeAllocationPercent = value;
            } else {
                finalEmployeeAllocationPercent = employeeAllocationPercent - allocationPercent + value;
            }
            calculation = finalEmployeeAllocationPercent;

        }

        if (finalEmployeeAllocationPercent > 100) {
            let message = {
                message: "Total allocation of the resource cannot exceed 100%!",
                type: "warning",
            };
            this.props.handleMessage(message);
        } else {
            resources[index]['allocationPercent'] = value;
            resources[index]['employeeAllocationPercent'] = calculation;
            // let result = await this.checkPercentAllocationLimit(e.target.value, index);
            this.setState({
                ...this.state,
                resources: [...resources]
            })
        }

    }

    renderTableHeader = () => {
        var currentCurrency;
        if (this.props.currencies !== undefined && this.props.project.currencyId !== undefined) {
            currentCurrency = this.props.currencies.find(currency => currency.id === this.props.project.currencyId)
        }


        return (
            <tr>
                <th style={{ width: '30%' }}>Employee</th>
                <th style={{}}>Billing Rate/Hr ({currentCurrency === undefined ? null : currentCurrency.code})</th>
                <th style={{}}>Cost/Hr ({currentCurrency === undefined ? null : currentCurrency.code})</th>
                <th>Max Billable Hours</th>
                <th>Allocation Percent</th>
                <th>Total Allocation(%)</th>
                <th></th>
            </tr>
        )

    }

    handleOnChange = (e, index) => {
        let resources = [...this.state.resources];
        let name = e.target.name;
        let value = parseFloat(e.target.value) < 0 ? 0 : parseFloat(e.target.value);
        resources[index][e.target.name] = value;
        this.setState({
            ...this.state,
            resources: [...resources]
        });
    }

    renderTableData = () => {
        // let employeeOptions = this.props.employeesWithRole.map((employee, index) => {
        //     return (<option key={index} value={employee.eid} style={{ color: employee.isActive === "N" ? 'gray' : '#000' }} disabled={employee.isActive === "N" ? true : false}>{employee.fname + ' ' + employee.lname}</option>)
        // });

        return this.state.resources.map((resource, index) => {
            let employeeIndex = this.props.employeesWithRole.findIndex(employee => employee.eid === resource.eid);
            if (employeeIndex !== -1) {
                let designation = this.props.employeesWithRole[employeeIndex].role;
                let matches = designation.match(/\b(\w)/g); // ['J','S','O','N']
                var acronym = matches.join(''); // JSON                
            }
            return (
                <tr key={index}>
                    <td>
                        <FormGroup className={`has-label ${this.state.employeeState} `} style={{ paddingTop: 10, marginBottom: 10, display: 'inline-flex' }}>
                            <Input type="select" style={{ width: 202 }} name="eid" value={resource.eid} onChange={(e) => this.handleSelect(e, index)} id="resource">
                                <option disabled={resource.isActive === "Y" ? false : true} value={0}>Select Resource</option>
                                {/* {employeeOptions} */}
                                {this.props.employeesWithRole.map((employee, index) => {
                                    return (<option key={employee.eid}
                                        value={employee.eid}
                                        style={{ color: employee.isActive === "N" ? 'gray' : '#000' }}
                                        disabled={resource.isActive === "N" ? true : employee.isActive === "N" ? true : false}
                                    >
                                        {employee.fname + ' ' + employee.lname}
                                    </option>)
                                })}
                            </Input>
                            <Badge style={{ marginLeft: 15, marginTop: 8 }} color="secondary">
                                {employeeIndex !== -1 ? acronym === 'M' ? acronym : null : null}
                            </Badge>
                            {this.state.employeeState === "has-danger" ? (
                                <label className="error">Please select employee</label>
                            ) : null}
                        </FormGroup>
                    </td>
                    <td>
                        <Input
                            name="billingRate"
                            type="number"
                            placeholder="Billing Rate"
                            value={resource.billingRate}
                            min={0}
                            onChange={e => this.handleOnChange(e, index)}
                            disabled={resource.isActive === 'Y' ? false : true}
                        />
                    </td>
                    <td>
                        <Input
                            name="resourceCost"
                            type="number"
                            placeholder="Cost"
                            value={resource.resourceCost}
                            min={0}
                            onChange={e => this.handleOnChange(e, index)}
                            disabled={resource.isActive === 'Y' ? false : true}
                        />
                    </td>
                    <td>
                        <Input
                            name="maxHoursBillable"
                            type="number"
                            placeholder="Max Billable Hours"
                            value={resource.maxHoursBillable}
                            min={0}
                            onChange={e => this.handleOnChange(e, index)}
                            disabled={resource.isActive === 'Y' ? false : true}
                        />
                    </td>

                    <td>
                        <Input
                            type="select"
                            name="allocationPercent"
                            value={resource.allocationPercent}
                            onChange={e => this.handleSelectAllocationPercentage(e, index)}
                            disabled={resource.isActive === 'Y' ? false : true}
                            id="allocationPercent"
                            onFocus={e => this.setState({ allocationPercentFocus: true })}
                            onBlur={e => this.setState({ allocationPercentFocus: false })}>
                            <option value={0}>0</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                            <option value={60}>60</option>
                            <option value={70}>70</option>
                            <option value={80}>80</option>
                            <option value={90}>90</option>
                            <option value={100}>100</option>
                        </Input>
                    </td>
                    <td>
                        <Input
                            name="employeeAllocationPercent"
                            type="number"
                            placeholder="Allocation till date"
                            value={resource.employeeAllocationPercent}
                            min={0}
                            onChange={e => this.handleOnChange(e, index)}
                            disabled={true}
                        />
                    </td>
                    <td>
                        <Button

                            onClick={(e) => this.handleDeleteResource(e, index)}
                            color="warning"
                            // size="sm"
                            className="btn-icon btn-link like"
                            disabled={resource.isActive === 'Y' ? false : true}
                        >
                            <i id="tooltip209861" className="tim-icons icon-trash-simple" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip209861"
                            placement="top"
                        >
                            Delete
                        </UncontrolledTooltip>
                    </td>
                </tr>

            )
        });

    }



    handleAddReosurce = () => {
        // let user = localStorage.getItem('user');
        if (this.state.isResourceLoading === false && this.state.isResourceAPISuccess === true) {

            let dataItem = {
                id: 0,
                cid: this.props.user.cid,
                pid: this.props.project.id,
                eid: 0,
                assignedBy: this.props.user.eid,
                assignedDate: null,
                resourceCost: 0,
                billingRate: 0,
                allocationPercent: 0,
                employeeAllocationPercent: 0,
                maxHoursBillable: 0,
                dateRelieved: null,
                relievedBy: this.props.user.eid,
                isActive: 'Y'
            }

            let resources = [...this.state.resources];
            resources.push(dataItem);

            this.setState({
                ...this.state,
                resources: [...resources]
            })
        } else {
            let message = {
                message: "Failed to load resources, please try again!",
                type: "warning",
            };
            this.props.handleMessage(message);
        }
    }

    handleDeleteResource = (e, index) => {

        let resources = [...this.state.resources];
        let data = this.state.resources[index];
        if (data.id === 0) {
            resources.splice(index, 1);
            this.setState({
                ...this.state,
                resources: [...resources]
            });
        } else {
            this.props.deleteResource(data.eid, data.pid);
        }
    }

    isValidated = () => {

        let result = this.state.resources.filter(dataItem => parseInt(dataItem.eid) === 0);
        if (result.length > 0) {
            let message = {
                message: "Please select resource!",
                type: "warning",
            };
            this.props.handleMessage(message);
        } else {
            let resources = [];
            var validResourceCostData = true;
            var validResourceBillingRateData = true;
            var validResourceBillableHoursData = true;
            this.state.resources.map((resource, index) => {
                validResourceCostData = (resource.resourceCost < 0 || isNaN(resource.resourceCost) === true) ? false : true;
                validResourceBillingRateData = (resource.billingRate < 0 || isNaN(resource.billingRate) === true) ? false : true;
                validResourceBillableHoursData = (resource.maxHoursBillable < 0 || isNaN(resource.maxHoursBillable) === true) ? false : true;
                resource.pid = this.props.project.id;
                resources.push(resource);
            });

            if (this.state.isResourceAPISuccess === false) {
                let message = {
                    message: "Failed to load resources, please try again!",
                    type: "warning",
                };
                this.props.handleMessage(message);
                return false;

            } else if (this.state.isResourceLoading) {
                return false;
            } else if (this.state.isResourceLoading === false && this.state.isResourceAPISuccess === true) {
                if (validResourceCostData && validResourceBillingRateData && validResourceBillableHoursData) {
                    this.props.submitResources(resources);
                    return true;
                } else {
                    let message = {
                        message: "Please enter valid resorce data!",
                        type: "warning",
                    };
                    this.props.handleMessage(message);
                }
            }

        }

    }

    handleCancel = () => {
        let passProps = {
            pathname: 'projectmaster',
        }
        this.props.history.push(passProps);
    }

    render() {
        return (
            <div>
                <div className="billing-block">
                    <div style={{ display: 'flex', marginBottom: 5 }}>

                        {this.props.project && this.props.project.project ? 'Project :' + this.props.project.project : null}


                    </div>
                    <Card className="custom-card">
                        {/* <CardHeader>
                            <h4 style={{ alignSelf: 'center', marginBottom: 0 }}>Resources</h4>
                        </CardHeader> */}
                        <CardBody style={{ padding: 0 }}>
                            <Table className="custom-billing-table striped" striped responsive>
                                <thead style={{ backgroundColor: '#736d6d' }}>
                                    {this.renderTableHeader()}
                                </thead>
                                <tbody>
                                    {this.state.isResourceLoading === true ? (
                                        <tr style={{ backgroundColor: 'transparent' }}>
                                            <td colSpan="5" className="resources-loader-td">
                                                <LoadingIndicator loading={true} />
                                            </td>
                                        </tr>
                                    ) : this.state.resources.length > 0 ? (this.renderTableData()) : (
                                        <tr style={{ backgroundColor: 'transparent' }}>
                                            <td>
                                                <p style={{ margin: 20, display: 'block' }}>No resource added</p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </CardBody>
                        <CardFooter>
                            <Button size="sm" color="info" onClick={this.handleAddReosurce} disabled={this.props.project.isActive === "N" ? true : false} >
                                <i className="tim-icons icon-simple-add custom-resource-icon" /> Add Resource
                            </Button>
                        </CardFooter>
                    </Card>
                </div>
                <Button onClick={() => { this.handleCancel() }} className="btn-cancel" size="sm" color="info">Cancel</Button>
            </div>
        )
    }


}

export default Resources;